import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { InspectionTemplates } from '../states';
import {
  VehicleInspectionTemplate,
  InspectionTemplatePredefinedType,
} from 'model';

const initialState: InspectionTemplates = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {
    predefinedType: [InspectionTemplatePredefinedType.FullInspection],
  },
};

export default createAsyncListActionReducers<
  VehicleInspectionTemplate,
  InspectionTemplates
>('inspection.templates', initialState, {
  mapItemKey: x => x.id,
});
