/**
 * @file: SysBatchJobService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  SysBatchJob,
  SysBatchJobListFilter,
} from 'model';

export class SysBatchJobServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<SysBatchJobListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<SysBatchJob>
      : SysBatchJob[]
  > {
    return await this.api
      .get()
      .url(
        '/sys-batch-jobs',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<SysBatchJob | null> {
    return await this.api.get().url('/sys-batch-jobs/:id', { id }).future();
  }

  async getJobByJobId(jobId: string): Promise<SysBatchJob | null> {
    if (!jobId) {
      throw new Error('missing jobId parameter');
    }
    return await this.api
      .get()
      .url('/sys-batch-jobs/query/jobid', { jobId })
      .future();
  }

  async create(sysBatchJob: Partial<SysBatchJob>): Promise<SysBatchJob> {
    return await this.api
      .post()
      .url('/sys-batch-jobs')
      .data(sysBatchJob)
      .future();
  }

  async update(sysBatchJob: Partial<SysBatchJob>): Promise<SysBatchJob> {
    return await this.api
      .put()
      .url('/sys-batch-jobs/:id', { id: sysBatchJob.id })
      .data(sysBatchJob)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete().url('/sys-batch-jobs/:id', { id }).future();
  }
}
