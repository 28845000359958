/**
 * @file: ServiceEnrollmentTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ServiceEnrollmentType } from 'model';

export const ServiceEnrollmentTypeOptions: Array<
  Option<ServiceEnrollmentType>
> = [
  { value: ServiceEnrollmentType.New, label: 'service_enrollment_type.new' },
  {
    value: ServiceEnrollmentType.Renew,
    label: 'service_enrollment_type.renew',
  },
];

export const ServiceEnrollmentTypeOptionsWithDefault: Array<
  Option<ServiceEnrollmentType | null>
> = [
  { value: null, label: 'service_enrollment_type.__default__' },
  { value: ServiceEnrollmentType.New, label: 'service_enrollment_type.new' },
  {
    value: ServiceEnrollmentType.Renew,
    label: 'service_enrollment_type.renew',
  },
];
