import { TransFunction } from 'app';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { ObjectKeyType } from 'lib/duck/interfaces';
import { ReactCtor } from 'model';
import { Component, MouseEvent, ReactNode } from 'react';
import { getTranslate, Translate } from 'react-localize-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionButtonCls } from 'shared';
import { Column, DataListGroup, DataTable } from 'shared/metronic/components';
import { ConfirmDeleteModal } from '../ConfirmDeleteModal';
import { EntityEditorForm } from '../EntityEditorForm';
import {
  EntityEditorSidebar,
  EntityEditorSidebarProps,
} from '../EntityEditorSidebar';
import { ListToolbar } from '../ListToolbar';
import { Page } from '../Page';
import { Restricted } from '../Restricted';
import { getString } from '../StringLabel';
import {
  ActionButtonInfo,
  CommonEntityListProps,
  EntityListProps,
} from './types';

export function mapStateToPropsHelper<TProps extends CommonEntityListProps>(
  state: AppState,
  props: Omit<TProps, 'trans' | 'translate' | 'dispatch' | 'identity' | '$has'>,
): Partial<TProps> {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    ...props,
  } as TProps;
}

export function mapDispatchToPropsHelper<TProps extends CommonEntityListProps>(
  dispatch: ThunkDispatch<AppState, any, any>,
): Partial<TProps> {
  return { dispatch: dispatch as any } as any;
}

export class EntityList<
  T extends object,
  TFilter,
  TKey extends ObjectKeyType = number,
  TState = any,
> extends Component<EntityListProps<T, TFilter, TKey>, TState> {
  private readonly columns: Array<Column<T>> = [];

  constructor(props: EntityListProps<T, TFilter, TKey>) {
    super(props);
    this.state = props.initialState;
    this.columns = props.columns.map(column => ({
      ...column,
      text:
        typeof column.text === 'string' && column.text
          ? props.trans(column.text)
          : column.text,
    }));
    this.addActionButtons();
  }

  addActionButtons() {
    if (!this.props.actionButtons?.length) {
      return;
    }

    const { canEdit, canDelete, shouldDisableActionButton } = this.props;

    this.columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: Math.max(80, this.props.actionButtons.length * 40),
      hidden: () => !this.props.$has(this.props.fullAccessRight),
      render: (item: T) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.onEdit(item);
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.props.actions.itemsBeingDeleted &&
            this.props.dispatch(this.props.actions.itemsBeingDeleted([item]));
        };
        const onClick = (actionButton: ActionButtonInfo<T>) => {
          return (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            const extra = this.onGetExtraInfo();
            actionButton.onClick && actionButton.onClick(item, extra, this);
          };
        };

        const extra = this.onGetExtraInfo();

        return (
          <div className="m-datatable__action-buttons">
            {this.props.actionButtons!.map(actionButton =>
              (() => {
                if (actionButton === 'edit') {
                  const disabled = canEdit
                    ? !canEdit(item, extra)
                    : shouldDisableActionButton
                      ? shouldDisableActionButton(actionButton, item, extra)
                      : undefined;
                  return (
                    <Restricted
                      rights={this.props.fullAccessRight}
                      key="__edit"
                    >
                      <button
                        className={ActionButtonCls}
                        disabled={disabled}
                        onClick={onEdit}
                      >
                        <i className="la la-edit" />
                      </button>
                    </Restricted>
                  );
                }
                if (actionButton === 'remove') {
                  const disabled = canDelete
                    ? !canDelete(item, extra)
                    : shouldDisableActionButton
                      ? shouldDisableActionButton(actionButton, item, extra)
                      : undefined;
                  return (
                    <Restricted
                      rights={this.props.fullAccessRight}
                      key="__remove"
                    >
                      <button
                        className={ActionButtonCls}
                        disabled={disabled}
                        onClick={onDelete}
                      >
                        <i className="la la-trash" />
                      </button>
                    </Restricted>
                  );
                }

                const shouldDisable =
                  typeof actionButton.disabled === 'boolean'
                    ? actionButton.disabled
                    : typeof actionButton.disabled === 'function'
                      ? actionButton.disabled(item, extra)
                      : shouldDisableActionButton
                        ? shouldDisableActionButton(actionButton, item, extra)
                        : undefined;

                return (
                  <Restricted
                    rights={actionButton.rights || []}
                    key={actionButton.key}
                  >
                    <button
                      className={ActionButtonCls}
                      disabled={shouldDisable}
                      onClick={onClick(actionButton)}
                    >
                      {actionButton.icon ? (
                        <i className={actionButton.icon} />
                      ) : null}
                      {actionButton.text}
                    </button>
                  </Restricted>
                );
              })(),
            )}
          </div>
        );
      },
    });
  }

  render() {
    const {
      trans,
      pageTitle,
      i18nPrefix,
      idProp,
      selModel,
      entities,
      noPaging,
      maxPagerItems,
      compact,
      breadcrumbs,
      toolbarItems,
      toolbar,
      features,
      fullAccessRight,
      readonlyAccessRight,
      treeBuilder,
      onValidate,
      onRender,
      onFilter,
    } = this.props;

    const EntityEditorSidebarType: ReactCtor<
      EntityEditorSidebarProps<T>,
      any
    > = EntityEditorSidebar;
    const items =
      entities.result && onFilter
        ? onFilter(entities.result, this.props)
        : entities.result;

    return (
      <Page
        title={trans(pageTitle)}
        breadcrumbs={breadcrumbs}
        fullAccessRight={fullAccessRight}
        readonlyAccessRight={readonlyAccessRight}
        error={entities.error}
        onAdd={
          !features || features.addEntity !== false ? this.onAdd : undefined
        }
        onRefresh={this.onRefresh}
        compact={compact !== false}
      >
        {toolbar ? (
          toolbar()
        ) : (
          <ListToolbar<TFilter, TKey>
            items={
              toolbarItems
                ? typeof toolbarItems === 'function'
                  ? toolbarItems()
                  : toolbarItems
                : null
            }
            filter={entities.filter || {}}
            selection={entities.selection || []}
            onGetExtraInfo={this.onGetExtraInfo}
            onFilterChange={this.onFilterChange}
          />
        )}
        <DataTable<T, TKey>
          columns={this.columns}
          idProp={idProp || ('id' as any)}
          selModel={selModel}
          data={items}
          treeBuilder={treeBuilder}
          isLoading={entities.isLoading}
          minHeight={400}
          offset={entities.offset}
          limit={entities.limit}
          total={noPaging !== true ? entities.total : 0}
          selection={entities.selection}
          maxPagerItems={maxPagerItems || 5}
          {...this.getListGroupProps()}
          onToggleAllSelection={this.onToggleAllSelection}
          onItemSelect={this.onSelectChange}
          onOffsetChange={this.onOffsetChange}
          onLimitChange={this.onLimitChange}
          onGetExtraInfo={this.onGetExtraInfo}
          onListItemNodeExpand={this.onListItemNodeExpand}
          onListItemNodeCollapse={this.onListItemNodeCollapse}
        />
        <EntityEditorSidebarType
          values={entities}
          title={`${i18nPrefix}.editor`}
          error={entities.createError || entities.lastUpdateError}
          onSave={this.onSave}
          onCancel={this.onCancel}
          onValidate={onValidate}
        >
          {(entity: T | Partial<T>) => this.renderEntityEditor(entity)}
        </EntityEditorSidebarType>
        <ConfirmDeleteModal
          localeSegment={i18nPrefix}
          isOpen={Boolean(
            entities.itemsBeingDeleted && entities.itemsBeingDeleted[0],
          )}
          isDeleting={entities.isDeleting}
          error={entities.lastDeleteError}
          onConfirm={this.onCommitItemBeingDeleted}
          onCancel={this.onCancelBeingDeleted}
        />
        {onRender && onRender(this.props, this)}
      </Page>
    );
  }

  renderEntityEditor(entity: T | Partial<T>): ReactNode | null {
    const {
      editorFormElements,
      editorFormAutocompletion,
      editorFormUseUncontrolled,
    } = this.props;

    if (!editorFormElements) return null;

    return (
      <form className="entity-editor-form m-form">
        <div className="m-portlet__body">
          <div className="m-form__section m-form__section--first">
            <EntityEditorForm
              entity={entity}
              onChange={this.onChange}
              elements={editorFormElements}
              areas={this.props.areas?.result}
              autocomplete={editorFormAutocompletion}
              useUncontrolled={editorFormUseUncontrolled}
              onGetExtraInfo={this.onGetExtraInfo}
            />
          </div>
        </div>
      </form>
    );
  }

  componentDidUpdate(prevProps: EntityListProps<T, TFilter, TKey>) {
    const { dispatch } = this.props;
    if (!prevProps.entities.isLoading && this.props.entities.isLoading) {
      dispatch(showAppLoading());
    } else if (!this.props.entities.isLoading && prevProps.entities.isLoading) {
      dispatch(hideAppLoading());
    }
  }

  onRefresh = () => {
    const { dispatch, actions } = this.props;
    dispatch(actions.invalidate());
  };

  onFilterChange = (filter: Partial<TFilter>) => {
    const { dispatch, actions } = this.props;
    actions.updateFilter && dispatch(actions.updateFilter(filter));
  };

  onAdd = () => {
    const { dispatch, actions, onAdd } = this.props;
    const entity: Partial<T> = {};
    const extra = this.onGetExtraInfo();
    const result = onAdd?.(entity, extra);
    if (result === true) {
      return;
    }
    actions.itemBeingCreated && dispatch(actions.itemBeingCreated(entity));
  };

  onEdit(entity: T) {
    const { dispatch, actions, onEdit } = this.props;
    if (onEdit) {
      const extra = this.onGetExtraInfo();
      entity = onEdit(entity, extra);
    }
    actions.itemBeingUpdated && dispatch(actions.itemBeingUpdated(entity));
  }

  onCommitItemBeingDeleted = () => {
    const { dispatch, actions, onDeleted } = this.props;
    if (actions.commitItemsBeingDeleted) {
      if (onDeleted) {
        dispatch(
          actions.commitItemsBeingDeleted((err: Error | undefined) => {
            const extra = this.onGetExtraInfo();
            onDeleted(err, extra);
          }),
        );
      } else {
        dispatch(actions.commitItemsBeingDeleted());
      }
    }
  };

  onCancelBeingDeleted = () => {
    const { dispatch, actions } = this.props;
    actions.cancelItemsBeingDeleted &&
      dispatch(actions.cancelItemsBeingDeleted());
  };

  onSave = () => {
    const { entities, dispatch, actions } = this.props;
    if (entities.itemBeingCreated) {
      actions.commitItemBeingCreated &&
        dispatch(actions.commitItemBeingCreated());
    } else {
      actions.commitItemBeingUpdated &&
        dispatch(actions.commitItemBeingUpdated());
    }
  };

  onCancel = () => {
    const { entities, dispatch, actions } = this.props;
    if (entities.itemBeingCreated) {
      actions.cancelItemBeingCreated &&
        dispatch(actions.cancelItemBeingCreated());
    } else {
      actions.cancelItemBeingUpdated &&
        dispatch(actions.cancelItemBeingUpdated());
    }
  };

  onChange = (changes: Partial<T>) => {
    const { entities, dispatch, actions } = this.props;
    if (entities.itemBeingCreated) {
      actions.itemBeingCreatedChanged &&
        dispatch(actions.itemBeingCreatedChanged(changes));
    } else {
      actions.itemBeingUpdatedChanged &&
        dispatch(actions.itemBeingUpdatedChanged(changes));
    }
  };

  onToggleAllSelection = () => {
    const { dispatch, actions } = this.props;
    actions.toggleAllSelection && dispatch(actions.toggleAllSelection());
  };

  onSelectChange = (entity: T, selected: boolean) => {
    const { dispatch, actions } = this.props;
    if (selected) {
      actions.itemSelected && dispatch(actions.itemSelected(entity));
    } else {
      actions.itemDeselected && dispatch(actions.itemDeselected(entity));
    }
  };

  onOffsetChange = (offset: number) => {
    const { dispatch, actions } = this.props;
    actions.updateDataOffset && dispatch(actions.updateDataOffset(offset));
  };

  onLimitChange = (limit: number) => {
    const { dispatch, actions } = this.props;
    actions.updateDataLimit && dispatch(actions.updateDataLimit(limit));
  };

  onListItemNodeExpand = (entity: T) => {
    const { dispatch, actions } = this.props;
    dispatch(actions.expandListItemNode(entity));
  };

  onListItemNodeCollapse = (entity: T) => {
    const { dispatch, actions } = this.props;
    dispatch(actions.collapseListItemNode(entity));
  };

  onFormatGroupHeader = (group: DataListGroup<T>, key?: any) => {
    const extra = this.onGetExtraInfo();
    if (this.props.onFormatGroupHeader) {
      return this.props.onFormatGroupHeader(group, key, extra);
    }
    if (
      group.groupValue === null ||
      group.groupValue === undefined ||
      group.groupValue === ''
    ) {
      return getString('@string/unknown_list_group_label');
    }
    return group.groupValue;
  };

  onListGroupExpand = (value: any, key?: any) => {
    const { dispatch, actions } = this.props;
    const extra = this.onGetExtraInfo();
    this.props.onListGroupExpand &&
      this.props.onListGroupExpand(value, key, extra);
    dispatch(actions.expandListGroup(value));
  };

  onListGroupCollapse = (value: any, key?: any) => {
    const { dispatch, actions } = this.props;
    const extra = this.onGetExtraInfo();
    this.props.onListGroupCollapse &&
      this.props.onListGroupCollapse(value, key, extra);
    dispatch(actions.collapseListGroup(value));
  };

  onRenderListGroupActions = (group: DataListGroup<T>, key?: any) => {
    const extra = this.onGetExtraInfo();
    return this.props.onRenderListGroupActions
      ? this.props.onRenderListGroupActions(group, key, extra)
      : undefined;
  };

  getListGroupProps() {
    if (!this.props.groupBy) return {};
    const { entities } = this.props;
    const key = entities.activeGroupKey;
    return {
      groupBy: this.props.groupBy,
      groupListKey: this.props.groupListKey,
      collapseGroupByDefault: entities.collapseGroupByDefault,
      expandedGroups: entities.expandedGroups
        ? entities.expandedGroups.get(key)
        : undefined,
      collapsedGroups: entities.collapsedGroups
        ? entities.collapsedGroups.get(key)
        : undefined,
      onFormatGroupHeader: this.onFormatGroupHeader,
      onListGroupExpand: this.onListGroupExpand,
      onListGroupCollapse: this.onListGroupCollapse,
      onRenderListGroupActions: this.onRenderListGroupActions,
    };
  }

  onGetExtraInfo = () => this.props;
}
