import React, { memo } from 'react';
import { DetailView } from './Detail';
import { LeftSidebar } from './LeftSidebar';

export const Content = memo(() => {
  return (
    <div className="construction-tpl-man__content">
      <LeftSidebar />
      <DetailView />
    </div>
  );
});
