/**
 * @file: OrgUserVisibleStoreService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OrgUserVisibleStoreServiceBase } from './base/OrgUserVisibleStoreService';

export class OrgUserVisibleStoreService extends OrgUserVisibleStoreServiceBase {
  // add custom service implementation here
}
