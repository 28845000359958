/**
 * @file: dashboard.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app';
import { RecentListsConfiguations } from 'app/dashboard/RecentListsConfig';
import {
  getStatAspectConfig,
  StatAspectsConfigurations,
} from 'app/dashboard/StatAspectsConfig';
import { createStandardAction } from 'lib/duck/actions';
import { ActionThunk, StandardAction } from 'lib/duck/interfaces';
import { DatePartitionType, DateRange } from 'model';
import { StatAspect } from '../states';
import { ActionTypes } from '../types';
import { invalidateDashboardCompanionSummaryStats } from './companion-summary-stats';
import { invalidateDashboardSummaryStats } from './summary-stats';

export function updateDashboardDateRange(
  dateRange: DateRange,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();

    if (
      state.dashboard.dateRange.startDate !== dateRange.startDate ||
      state.dashboard.dateRange.endDate !== dateRange.endDate
    ) {
      const action = createStandardAction(
        ActionTypes.UpdateStatsDateRange,
        dateRange,
      );

      dispatch(action);

      dispatch(refreshDashboardStats());
    }
  };
}

export function updateStatsByTimeDatePartitionType(
  statAspect: StatAspect,
  partition: DatePartitionType,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const action = createStandardAction(ActionTypes.UpdateDatePartitionType, {
      partition,
      statAspect,
    });
    dispatch(action);
    const state = getState();
    const config = getStatAspectConfig(state.dashboard.activeStatAspect);
    const stats = config.getStatsByTime(state.dashboard);
    if (!stats.result?.[partition]) {
      dispatch(config.fetchAction(partition));
    }
  };
}

export function dashboardActiveStatAspectChanged(
  aspect: StatAspect,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    dispatch(createStandardAction(ActionTypes.UpdateActiveStatAspect, aspect));
    const state = getState();
    const config = getStatAspectConfig(aspect);
    const stats = config.getStatsByTime(state.dashboard);
    if (!stats.result?.[stats.partition]) {
      dispatch(config.fetchAction(stats.partition));
    }
  };
}

// note: this action method is not used for now
// todo: remove this method later on
export function updateCurrentStatsByTimeDatePartitionType(
  partition: DatePartitionType,
): StandardAction<any> {
  return createStandardAction(ActionTypes.UpdateDatePartitionType, {
    partition,
  });
}

export function refreshDashboardStats(): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(invalidateDashboardSummaryStats());
    dispatch(invalidateDashboardCompanionSummaryStats());
    for (const config of StatAspectsConfigurations) {
      const stats = config.getStatsByTime(state.dashboard);
      if (stats.result) {
        for (const property in stats.result) {
          if (!Object.prototype.hasOwnProperty.call(stats.result, property))
            continue;
          const partition = property as DatePartitionType;
          if (Object.prototype.hasOwnProperty.call(stats.result, partition)) {
            dispatch(config.fetchAction(partition));
          }
        }
      }
    }
  };
}

export function fetchDashboardRecentLists(): ActionThunk<AppState> {
  return dispatch => {
    for (const config of RecentListsConfiguations) {
      if (config.disabled !== true) {
        dispatch(config.fetchAction());
      }
    }
  };
}

export function invalidateDashboardCurrentRecentList(): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    for (const config of RecentListsConfiguations) {
      if (
        config.disabled !== true &&
        config.statAspect === state.dashboard.activeStatAspect
      ) {
        dispatch(config.fetchAction());
        break;
      }
    }
  };
}

export function invalidateDashboardRecentLists(): ActionThunk<AppState> {
  return dispatch => {
    for (const config of RecentListsConfiguations) {
      if (config.disabled !== true) {
        dispatch(config.invalidateAction());
      }
    }
  };
}
