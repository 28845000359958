import {
  BatchJobType,
  BatchJobTypeOptions,
  Organization,
  Store,
  SysBatchJob,
} from 'model';
import { BatchFinishTasksJobData } from 'model/BatchFinishTasksJobData';
import moment from 'moment';
import { ChangeEvent, memo, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { organizationService, storeService } from 'services';
import { getString, Select, StorePicker } from 'shared/components';
import { Button } from 'shared/metronic/components';
import { usePersistFn } from 'utils/usePersistFn';

export const AddBatchJobModal = memo(
  ({
    fromExisting,
    isOpen,
    onCancel,
    onConfirm,
  }: {
    isOpen: boolean;
    fromExisting?: SysBatchJob;
    onCancel: () => void;
    onConfirm: (jobType: BatchJobType, jobData: any) => void;
  }) => {
    const [jobType, setJobType] = useState<BatchJobType | undefined>(
      fromExisting?.jobType ?? BatchJobType.FinishTasks,
    );
    const [batchFinishTasksJobInfo, setBatchFinishTasksJobInfo] =
      useState<BatchFinishTaskJobInfo>({
        beforeDate: moment().add(-1, 'd').format('YYYY-MM-Dd'),
      });

    const disabled =
      jobType == null ||
      (jobType === BatchJobType.FinishTasks &&
        (batchFinishTasksJobInfo?.organization == null ||
          !batchFinishTasksJobInfo.beforeDate));

    const onConfirmPress = usePersistFn(() => {
      if (jobType == null) return;

      const jobData =
        jobType === BatchJobType.FinishTasks
          ? ({
              orgId: batchFinishTasksJobInfo!.organization!.id,
              orgName: batchFinishTasksJobInfo!.organization!.name,
              storeId: batchFinishTasksJobInfo?.store?.id ?? undefined,
              storeName: batchFinishTasksJobInfo?.store?.name ?? undefined,
              dateRange: {
                start: undefined,
                startInclusive: false,
                end: batchFinishTasksJobInfo!.beforeDate!,
                endInclusive: true,
              },
            } as BatchFinishTasksJobData)
          : undefined;
      onConfirm(jobType, jobData);
    });

    return (
      <Modal isOpen={isOpen} modalClassName="add-batch-job-modal">
        <ModalHeader>
          <Translate id={`batch_job.add_job.title`} />
        </ModalHeader>
        <ModalBody>
          <div>
            <p style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
              <Translate id="batch_job.add_job.label.job_type" />
            </p>
            <Select
              valueProp="value"
              labelProp="label"
              placeholder={getString('batch_job.add_job.placeholder.job_type')}
              isClearable
              selectedValue={BatchJobTypeOptions.find(x => x.value === jobType)}
              values={BatchJobTypeOptions}
              onChange={option => {
                setJobType(option.value || undefined);
              }}
              onFormatOptionLabel={option => getString(option.label)}
            />
          </div>
          {jobType === BatchJobType.FinishTasks && (
            <BatchFinishTasksModelContent
              jobInfo={batchFinishTasksJobInfo}
              onChange={value =>
                setBatchFinishTasksJobInfo(info => ({ ...info, ...value }))
              }
              fromExisting={fromExisting}
              key={fromExisting?.jobId}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={onConfirmPress} disabled={disabled}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);

type BatchFinishTaskJobInfo = {
  organization?: Organization | null;
  store?: Store | null;
  beforeDate?: string | null;
};

const BatchFinishTasksModelContent = memo(
  ({
    jobInfo,
    fromExisting,
    onChange,
  }: {
    fromExisting?: SysBatchJob;
    jobInfo?: BatchFinishTaskJobInfo;
    onChange?: (jobInfo: BatchFinishTaskJobInfo) => void;
  }) => {
    const jobId = fromExisting?.jobId;
    useEffect(() => {
      if (!fromExisting) return;

      const jobData = JSON.parse(
        fromExisting.jobData || '{}',
      ) as BatchFinishTasksJobData;

      Promise.all([
        organizationService.get(jobData.orgId),
        jobData.storeId
          ? storeService.get(jobData.storeId)
          : Promise.resolve(undefined),
      ])
        .then(([org, store]) => {
          onChange?.({
            organization: org,
            store: store,
          });
        })
        .catch(err => {
          alert(err.message);
        });
    }, [fromExisting, onChange]);

    const onBeforeDateChange = usePersistFn(
      (e: ChangeEvent<HTMLInputElement>) => {
        console.log('before date: ', e.target.value);
        onChange?.({ ...jobInfo, beforeDate: e.target.value || undefined });
      },
    );

    return (
      <div>
        <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>
          <Translate id="batch_job.add_job.label.target" />
        </p>
        <StorePicker
          key={jobId}
          orgId={jobInfo?.organization?.id}
          storeId={jobInfo?.store?.id}
          className="add-batch-job__store-picker"
          onChange={(_a, _b, org, store) => {
            onChange?.({
              ...jobInfo,
              organization: org,
              store,
            });
          }}
        />
        <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>
          <Translate id="batch_job.add_job.label.before_date" />
        </p>
        <div className="from-group">
          <input
            type="date"
            className="form-control"
            value={jobInfo?.beforeDate ?? ''}
            onChange={onBeforeDateChange}
            placeholder={getString('batch_job.add_job.placeholder.before_date')}
          />
        </div>
      </div>
    );
  },
);
