import React, { MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { CSSProperties } from 'styled-components';
import { formatTime } from 'utils';
import { reportListActions, setReportListActiveReportId } from './duck/actions';
import { AclObjectList, VehicleReport, VehicleReportFilter } from 'model';
import { InspectionOrderTypeOptionsWithDefault } from 'model/EnumOptions';
import { EntityListProps, EntityListComponentClassBuilder, StringLabel, InspectionOrderTypeLabel, AsideRight, Badge } from 'shared/components';
import { ReportDetailView } from './Detail';
import { VehicleReports } from './duck/states';

import './index.scss';

interface Props extends EntityListProps<VehicleReport, VehicleReportFilter, string> {}

const componentClassBuilder =
  new EntityListComponentClassBuilder<VehicleReport, VehicleReportFilter, string, Props>();

export const VehicleInspectionReportList = componentClassBuilder
  .i18nPrefix('reports')
  .accessRights({
    full: AclObjectList.ReportFullAccess,
    readonly: AclObjectList.ReportReadonlyAccess
  })
  .breadcrumbs([
    { text: <Translate id="reports.breadcrumb.it" /> },
    { text: <Translate id="reports.breadcrumb.report_list" /> }
  ])
  .features({ addEntity: false })
  .entities(state => state.reports.reportList)
  .actions(reportListActions)
  .toolbarItems(builder => {
    builder.text({
      prop: 'reportNo',
      // label: 'reports.report_list.toolbar.label.report_no',
      placeholder: 'reports.report_list.toolbar.placeholder.report_no',
      width: 210
    }).select({
      prop: 'orderType',
      width: 150,
      array: true,
      clearable: true,
      // label: 'reports.report_list.toolbar.label.report_type',
      placeholder: 'reports.report_list.toolbar.placeholder.report_type',
      values: InspectionOrderTypeOptionsWithDefault.map(x => ({
        ...x, label: <Translate id={x.label} />
      }))
    }).button({
      text: '@string/btn_search',
      onClick: (props: Props) => {
        const { dispatch } = props;
        dispatch(reportListActions.invalidate(true));
      }
    })
  })
  .columns([{
    prop: 'reportId',
    width: 100,
    align: 'center',
    text: '',
    render: (report: VehicleReport) => {
      return (
        <img
          src={report.vehicleBrandLogo!}
          alt={report.vehicleBrandName!}
          style={{ width: 100 }}
          />
      );
    }
  }, {
    prop: '_id',
    width: 200,
    text: 'col.report_no',
    render: (report: VehicleReport, props: Props) => {
      const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const { dispatch } = props;
        dispatch(setReportListActiveReportId(report._id));
      };
      const style: CSSProperties = {
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      };
      return (
        <div>
          <div style={style}>
            {report.orgName}
          </div>
          <div style={style}>
            {report.storeName}
          </div>
          <div style={{ margin: '5px 0' }}>
            <a
              href="#"
              style={{ textDecoration: 'underline', color: '#222' }}
              onClick={onClick}
              >
              {report._id}
            </a>
          </div>
          <div>
            <InspectionOrderTypeLabel value={report.orderType} />
          </div>
        </div>
      );
    }
  }, {
    prop: 'vehicleName',
    width: 250,
    text: 'col.vehicle_info',
    render: entity => {
      const style: CSSProperties = {
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      };
      return (
        <div>
          <div style={style}>
            <StringLabel value="vehicle_label.name" />
            {entity.vehicleName}
          </div>
          <div style={style}>
            <StringLabel value="vehicle_label.brand" />
            {entity.vehicleBrandName}
          </div>
          <div style={style}>
            <StringLabel value="vehicle_label.mileage" />
            {entity.vehicleMileage! < 1 ? entity.vehicleMileage! * 10000 : entity.vehicleMileage}
            <StringLabel value={entity.vehicleMileage! < 1 ? 'value_unit.km' : 'value_unit.ten_thousands_km'} />
          </div>
        </div>
      );
    }
  }, {
    prop: 'totalSiteCount',
    width: 300,
    text: 'reports.report_list.col.stats',
    render: report => {
      const style: CSSProperties = {
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      };
      const badgeStyle: CSSProperties = {
        lineHeight: '20px',
        fontSize: '0.85rem',
        marginRight: '0.35rem'
      };
      return (
        <div style={style}>
          <Badge color="success" style={badgeStyle}>
            <Translate
              id="reports.detail.label.normal_badge"
              data={{
                count: report.inspection?.stats.normalSiteItemCount
              }}
              />
          </Badge>
          <Badge color="warning" style={badgeStyle}>
            <Translate
              id="reports.detail.label.abnormal_badge"
              data={{
                count: report.inspection?.stats.abnormalSiteItemCount
              }}
              />
          </Badge>
          <Badge color="success" style={badgeStyle}>
            <Translate
              id="reports.detail.label.urgent_badge"
              data={{
                count: report.inspection?.stats.urgentSiteItemCount
              }}
            />
          </Badge>
        </div>
      );
    }
  }, {
    prop: 'createdAt',
    text: 'col.created_at',
    width: 200,
    render: report => {
      const style: CSSProperties = {
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      };
      return (
        <div>
          <div style={style}>
            <StringLabel value="reports.detail.label.ordered_at" />
            {report.orderedAt ? formatTime(report.orderedAt) : '-'}
          </div>
          <div style={style}>
            <StringLabel value="reports.detail.label.created_at" />
            {formatTime(report.createdAt)}
          </div>
        </div>
      );
    }
  }])
  .onRender((props: Props) => {
    const { dispatch } = props;
    const entities = props.entities as VehicleReports;
    const onClose = () => {
      dispatch(setReportListActiveReportId(undefined));
    };
    return (
      <AsideRight
        open={Boolean(entities.activeReportId)}
        onClose={onClose}
        >
        <ul className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand" style={{ marginBottom: 15 }}>
          <li className="nav-item m-tabs__item">
            <a
              className="nav-link m-tabs__link active"
              style={{ fontWeight: 'bold', fontSize: '120%' }}
              >
              <Translate id="reports.detail.title" />
            </a>
          </li>
        </ul>
        {entities.result && entities.activeReportId && <ReportDetailView
          report={entities.result.find(x => x._id === entities.activeReportId)}
          />
        }
      </AsideRight>
    );
  })
  .getClass();