/**
 * @file: order-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { VehicleReceptionOrder, VehicleReceptionOrderListFilter } from 'model';
import { vehicleReceptionOrderService } from 'services';
import { ActionTypes } from '../types';

export const orderListActions = createListAsyncActionCreators<
  AppState,
  VehicleReceptionOrder,
  VehicleReceptionOrderListFilter,
  number
>('orders.order_list', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.orders.orderList.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.orders.orderList.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.orders.orderList.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleReceptionOrderService.list(
      state.orders.orderList.filter,
      state.orders.orderList.sorts?.[0],
      state.orders.orderList.offset,
      state.orders.orderList.limit,
      { count: true, recursive: true },
    );
  },
  async update(order: VehicleReceptionOrder) {
    return await vehicleReceptionOrderService.update(order);
  },
  async delete(order: VehicleReceptionOrder) {
    await vehicleReceptionOrderService.deletePermanently(order.orderNo);
  },
  async deleteMulti(orders: VehicleReceptionOrder[]) {
    if (orders.length === 1) {
      await vehicleReceptionOrderService.deletePermanently(orders[0].orderNo);
      return;
    }
    throw new Error('delete orders more than 1 is not supported. ');
  },
});

export function setOrderListActiveOrderId(orderId: number | undefined) {
  return createStandardAction(ActionTypes.SetActiveOrder, orderId);
}
