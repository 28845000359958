import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { SysBatchJob } from 'model';
import { SysBatchJobs } from '../states';
import { ActionTypes } from '../types';

const initialState: SysBatchJobs = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
  sorts: [{ property: 'id', dir: 'desc' }],
};

const defaultReducer = createAsyncListActionReducers<SysBatchJob, SysBatchJobs>(
  'batch_jobs',
  initialState,
  {
    mapItemKey: x => x.id,
    insertItemCreated(item, items) {
      items.unshift(item);
    },
  },
);

export default function sysBatchJobReducer(
  state: SysBatchJobs,
  action: StandardAction<any>,
) {
  switch (action.type) {
    case ActionTypes.ShowAddBatchJob:
      return {
        ...state,
        isAddJobModalOpen: action.payload.show,
        addJobFromExisting: action.payload.existing,
      };
    default:
      return defaultReducer(state, action);
  }
}
