/**
 * @file: adminUsersAcl.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncObjectMapActionReducers } from 'lib/duck/reducers';
import { AdminUserAccessRights } from 'model';
import { AdminUsersAcl } from '../states';

const initialState: AdminUsersAcl = {
  result: {},
  statusMap: {},
};

export default createAsyncObjectMapActionReducers<
  number,
  AdminUserAccessRights,
  AdminUsersAcl
>('app.settings.admin_users.acl', initialState);
