import { combineReducers } from 'redux';
import categories from './categories';
import sites from './sites';
import items from './items';
import options from './options';
import tools from './tools';
import uiState from './ui-state';
import filter from './filter';
import templates from './templates';
import templateDetail from './template-detail';
import serviceSubjects from './service-subject';
import inspectionSubjects from './inspection-subject';
import deliveryCheckTemplates from './delivery-check-templates';
import deliveryCheckTemplateDetail from './delivery-check-template-detail';
import vehicleSystemModules from './vehicle-system-modules';
import maintenanceServiceSubjects from './maintenance-service-subjects';
import constructionTemplateConfig from './construction-template-config';
import quotationTemplateConfig from './quotation-template-config';

export default combineReducers({
  categories,
  sites,
  items,
  options,
  tools,
  templates,
  filter,
  uiState,
  templateDetail,
  serviceSubjects,
  inspectionSubjects,
  deliveryCheckTemplates,
  deliveryCheckTemplateDetail,
  vehicleSystemModules,
  maintenanceServiceSubjects,
  constructionTemplateConfig,
  quotationTemplateConfig,
});
