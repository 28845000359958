/**
 * @file: ProductBrandService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ProductBrandServiceBase } from './base/ProductBrandService';

export class ProductBrandService extends ProductBrandServiceBase {
  // add custom service implementation here
  async import(fileDataUri: string): Promise<number> {
    return await this.api
      .post()
      .url('/product-brands/import')
      .data({ data: fileDataUri })
      .future();
  }
}
