import React, { Component, HTMLProps, CSSProperties } from 'react';
import classNames from 'classnames';
import { ColorType } from 'model';

interface Props extends HTMLProps<HTMLDivElement> {
  color?: ColorType;
  children?: React.ReactNode;
  icon?: string;
  square?: boolean;
  outline?: boolean;
  outline2x?: boolean;
  dismissible?: boolean;
  iconColor?: string;
  air?: boolean;
  iconStyle?: CSSProperties;
  textStyle?: CSSProperties;
  style?: CSSProperties;
}

export class Alert extends Component<Props> {
  static displayName = 'Alert';
  render() {
    const {
      color,
      icon,
      air,
      square,
      outline,
      outline2x,
      dismissible,
      iconColor,
      iconStyle,
      textStyle,
      children,
      className,
      ...props
    } = this.props;
    const { fontSize: iconFontSize = void 0, ...iconStyles } = iconStyle || {};
    return (
      <div className={classNames('m-alert', 'alert', {
        [`alert-${color}`]: color,
        'm-alert--default': color === 'default',
        'm-alert--icon': icon,
        'm-alert--air': air,
        'm-alert--square': square,
        'm-alert--outline': outline,
        'm-alert--outline-2x': outline2x,
        'alert-dismissible': dismissible
      }, className)}
      {...props}
      >
      {icon ?
        <>
          <div className="m-alert__icon" style={iconStyles}>
            <i className={classNames(icon, {
                [`m--font-${iconColor}`]: iconColor
              })} style={{ fontSize: iconFontSize }} />
          </div>
          <div className="m-alert__text" style={textStyle}>
            {children}
          </div>
        </> : children
      }
      </div>
    );
  }
}