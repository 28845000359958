/**
 * @file: OfficialAccountType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum OfficialAccountType {
  SubscriptionAccount = 0,
  MigratedSubscriptionAccount = 1,
  ServiceAccount = 2,
}

export const OfficialAccountTypeValueSet = new Set([
  OfficialAccountType.SubscriptionAccount,
  OfficialAccountType.MigratedSubscriptionAccount,
  OfficialAccountType.ServiceAccount,
]);
