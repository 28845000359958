/**
 * @file: AdminUserRole.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum AdminUserRole {
  SuperUsers = 'superusers',
  Administrators = 'administrators',
  CustomerSupport = 'customersupport',
  Sales = 'sales',
}

export const AdminUserRoleValueSet = new Set([
  AdminUserRole.SuperUsers,
  AdminUserRole.Administrators,
  AdminUserRole.CustomerSupport,
  AdminUserRole.Sales,
]);
