import { showAddBatchJob, showAppLoading } from 'app/duck/actions';
import { sysBatchJobActions } from 'app/duck/actions/batch-jobs';
import {
  AclObjectList,
  BatchJobStatus,
  BatchJobType,
  SysBatchJob,
  SysBatchJobListFilter,
} from 'model';
import moment from 'moment';
import { Fragment, memo } from 'react';
import { Translate } from 'react-localize-redux';
import { sysBatchJobService } from 'services';
import {
  Checkmark,
  DataExportTaskTargetTypeLabel,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { formatTime } from 'utils';
import { AddBatchJobModal } from './AddJobModal';

import { MessageEventArgs } from 'lib/websocket';
import { BatchFinishTasksJobData } from 'model/BatchFinishTasksJobData';
import './batch-jobs.scss';

interface Props extends EntityListProps<SysBatchJob, SysBatchJobListFilter> {
  isAddJobModalOpen?: boolean;
  addJobFromExisting?: SysBatchJob;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  SysBatchJob,
  SysBatchJobListFilter,
  number,
  Props
>();

export const SysBatchJobList = componentClassBuilder
  .i18nPrefix('batch_job')
  .accessRights({
    full: AclObjectList.SysBatchJobFullAccess,
    readonly: AclObjectList.SysBatchJobReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="batch_job.breadcrumb.it" /> },
    { text: <Translate id="batch_job.breadcrumb.jobs" /> },
  ])
  .mapStateToProps(state => ({
    isAddJobModalOpen: state.sysBatchJobs.isAddJobModalOpen,
    addJobFromExisting: state.sysBatchJobs.addJobFromExisting,
  }))
  .entities(state => state.sysBatchJobs)
  .actions(sysBatchJobActions)
  .columns([
    {
      prop: 'jobId',
      width: 200,
      hidden: true,
      text: 'batch_job.col.id',
    },
    {
      prop: 'jobType',
      width: 100,
      text: 'batch_job.col.job_type',
      render: ({ jobType }) => (
        <span
          style={{
            whiteSpace: 'nowrap',
            display: 'inline-block',
            color: '#000',
            border: '1px solid #777',
            fontSize: '0.85rem',
            padding: '0 0.25rem',
            borderRadius: '0.15rem',
            backgroundColor: '#eee',
          }}
        >
          <Translate id={`batch_job_type.${jobType}`} />
        </span>
      ),
    },
    {
      prop: 'jobData',
      text: 'batch_job.col.detail',
      width: 300,
      render: job => {
        return <JobInfo job={job} />;
      },
    },
    {
      prop: 'status',
      text: 'batch_job.col.status',
      width: 300,
      render: job => {
        return <JobStatusCell job={job} />;
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 120,
      render: job => {
        return formatTime(job.createdAt, 'YYYY-MM-DD HH:mm');
      },
    },
  ])
  .addActionButtons([
    'remove',
    {
      key: 'export',
      rights: [AclObjectList.SysBatchJobFullAccess],
      icon: 'la la-arrow-circle-o-right',
      onClick: (job, props: Props) => {
        props.dispatch(showAddBatchJob(true, job));
      },
    },
  ])
  .componentDidMount((_state, { dispatch }: Props) => {
    window.addEventListener('message', async e => {
      const args = e.data as MessageEventArgs;
      if (args.source !== '@zhichetech/boss') {
        return;
      }
      if (args.type === 'ws' && args.event.type === 'batch_job_status_update') {
        dispatch(async (_, getState) => {
          const state = getState();
          const entity = state.sysBatchJobs.result?.find(
            x => x.jobId === args.event.data?.jobId,
          );
          if (entity != null) {
            const job = await sysBatchJobService.get(entity.id);
            if (job != null) {
              dispatch(sysBatchJobActions.updateSuccess(entity, job));
            }
          }
        });
      }
    });
  })
  .onRender(props => {
    const onConfirm = async (jobType: BatchJobType, jobData: any) => {
      if (!confirm(getString('batch_job.add_job.confirm_msg'))) {
        return;
      }
      const { dispatch } = props;
      const job: Partial<SysBatchJob> = {
        jobData: JSON.stringify(jobData),
        jobType,
      };
      try {
        dispatch(showAddBatchJob(false));
        dispatch(showAppLoading());
        const result = await sysBatchJobService.create(job);
        dispatch(sysBatchJobActions.createSuccess(job, result));
        dispatch(
          showAppLoading({
            message: getString('batch_job.add_job_success'),
            status: 'success',
            timeout: 3000,
          }),
        );
      } catch (e) {
        dispatch(sysBatchJobActions.createFailed(job, e));
        dispatch(
          showAppLoading({
            message: e.message,
            status: 'error',
            timeout: 5000,
          }),
        );
      }
    };
    return (
      <AddBatchJobModal
        fromExisting={props.addJobFromExisting}
        isOpen={props.isAddJobModalOpen ?? false}
        onCancel={() => props.dispatch(showAddBatchJob(false))}
        onConfirm={onConfirm}
      />
    );
  })
  .onAdd((_, props: Props) => {
    props.dispatch(showAddBatchJob(true));
    return true;
  })
  .getClass();

function JobInfo({ job }: { job: SysBatchJob }) {
  if (job.jobType === BatchJobType.FinishTasks) {
    const jobData = JSON.parse(job.jobData || '{}') as BatchFinishTasksJobData;
    if (!jobData.orgId || !jobData.orgName || !jobData.dateRange?.end) {
      return null;
    }
    const { dateRange } = jobData;
    const times =
      dateRange.start === dateRange.end
        ? [dateRange.end]
        : [dateRange.start, dateRange.end];
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <DataExportTaskTargetTypeLabel
            value={jobData.storeId ? 'store' : 'org'}
            style={{
              fontSize: '0.7rem',
              padding: '0 0.3rem',
              minHeight: 0,
              lineHeight: '1.25rem',
            }}
          />
          <span
            style={{ marginLeft: '0.25rem', fontWeight: 'bold', color: '#444' }}
          >
            {jobData.storeName ?? jobData.orgName}
          </span>
        </div>
        <div>
          {times.map((time, i) => (
            <Fragment key={i}>
              {i > 0 ? (
                <span
                  style={{
                    padding: '0 0.5rem',
                  }}
                >
                  ~
                </span>
              ) : null}
              <span
                style={{
                  color: '#888',
                  borderBottom: '1px dotted #888',
                }}
              >
                {formatTime(time)}
              </span>
            </Fragment>
          ))}
        </div>
      </div>
    );
  }
  return null;
}

const JobStatusCell = memo(({ job }: { job: SysBatchJob }) => {
  let statusText: any = '';

  switch (job.status) {
    case BatchJobStatus.Queued: {
      statusText = (
        <span className="text-info">
          <i
            className="fa fa-hourglass-half"
            style={{ marginRight: '0.25rem', fontSize: '1rem' }}
          />
          <Translate id="batch_job_status.queued" />
        </span>
      );
      break;
    }
    case BatchJobStatus.Running: {
      statusText = (
        <span className="text-info">
          <i
            className="fa fa-spinner fa-pulse"
            style={{ marginRight: '0.25rem', fontSize: '1rem' }}
          />
          <Translate id="batch_job_status.running" />
          {' ...'}
        </span>
      );
      break;
    }
    case BatchJobStatus.Error: {
      statusText = (
        <span style={{ color: 'red' }}>
          <Translate id="batch_job_status.error" />: {job.error}
        </span>
      );
      break;
    }
    case BatchJobStatus.Finished: {
      statusText = (
        <span style={{ color: 'green' }}>
          <Checkmark
            value={true}
            style={{ fontSize: '1rem', marginRight: '0.15rem' }}
          />
          <Translate id="batch_job_status.finished" />,{' '}
          {moment
            .duration(moment(job.finishedAt).diff(job.startedAt))
            .format(getString('batch_job.time_elapsed_format'))}
        </span>
      );
    }
  }
  return <div>{statusText}</div>;
});
