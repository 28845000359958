import { Component } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { Alert, Button } from 'shared/metronic/components';
import { getString } from '../StringLabel';

interface Props {
  localeSegment: string;
  isOpen?: boolean;
  isDeleting?: boolean;
  error?: Error | null;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export class ConfirmDeleteModal extends Component<Props> {
  render() {
    const { error, isOpen, localeSegment, isDeleting, onConfirm, onCancel } =
      this.props;
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <Translate id={`${localeSegment}.modal.confirm_delete.title`} />
        </ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error.message}</Alert>}
          <ReactMarkdown>
            {getString(`${localeSegment}.modal.confirm_delete.msg`)}
          </ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel} disabled={isDeleting}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button
            color="primary"
            onClick={onConfirm}
            disabled={isDeleting}
            loader={isDeleting}
          >
            <Translate id="yes_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
