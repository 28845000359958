import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleReport } from 'model';
import { RecentInspectionReports } from '../states';

const initialState: RecentInspectionReports = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'createdAt', dir: 'desc' }],
  offset: 0,
  limit: 15
};

export default createAsyncListActionReducers<VehicleReport, RecentInspectionReports>(
  'dashboard.recent_inspection_reports',
  initialState,
  {
    mapItemKey: x => x._id
  }
);