import { VehicleReceptionOrder } from 'model';
import { Component } from 'react';
import { Translate } from 'react-localize-redux';
import {
  InspectionOrderPriorityLabel,
  InspectionOrderTypeLabel,
  ReceptionOrderStatusLabel,
  ScopedTranslate,
  StoreName,
  StringLabel,
} from 'shared/components';
import { Button } from 'shared/metronic/components';
import { formatTime } from 'utils';

interface Props {
  order: VehicleReceptionOrder | null | undefined;
  onDelete: (order: VehicleReceptionOrder) => void;
}

export class OrderDetailView extends Component<Props> {
  onDelete = () => {
    this.props.onDelete(this.props.order!);
  };

  render() {
    const { order } = this.props;
    if (!order) return null;
    return (
      <ScopedTranslate scope="orders.detail.label">
        {({ trans }) => (
          <div className="order-detail">
            <h3>{trans('!orders.detail.title')}</h3>
            <dl>
              <dt>{trans('org_name')}</dt>
              <dd>
                <StoreName mode="org" orgId={order.orgId} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('store_name')}</dt>
              <dd>
                <StoreName mode="store" orgId={order.storeId} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('order_no')}</dt>
              <dd>{order.orderNo}</dd>
            </dl>
            <dl>
              <dt>{trans('order_type')}</dt>
              <dd>
                <InspectionOrderTypeLabel value={order.orderType} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('vin')}</dt>
              <dd>
                {order.vin}
                {order.vinImgUrl && (
                  <p>
                    <a href={order.vinImgUrl} target="_blank" rel="noreferrer">
                      <img
                        src={order.vinImgUrl}
                        alt={order.vin}
                        style={{ width: 100 }}
                      />
                    </a>
                  </p>
                )}
              </dd>
            </dl>
            <dl>
              <dt>{trans('license_plate_no')}</dt>
              <dd>
                {order.licensePlateNo}
                {order.licensePlateNoImgUrl && (
                  <p>
                    <a
                      href={order.licensePlateNoImgUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={order.licensePlateNoImgUrl}
                        alt={order.licensePlateNo}
                        style={{ width: 100 }}
                      />
                    </a>
                  </p>
                )}
              </dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_name')}</dt>
              <dd>{order.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_brand')}</dt>
              <dd>{order.vehicleBrandName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_model')}</dt>
              <dd>{order.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_mileage')}</dt>
              {order.vehicleMileage! < 1
                ? order.vehicleMileage! * 10000
                : order.vehicleMileage}{' '}
              <StringLabel
                value={
                  order.vehicleMileage! < 1
                    ? 'value_unit.km'
                    : 'value_unit.ten_thousands_km'
                }
              />
            </dl>
            <dl>
              <dt>{trans('customer_name')}</dt>
              <dd>{order.customerName}</dd>
            </dl>
            <dl>
              <dt>{trans('customer_mobile')}</dt>
              <dd>{order.customerMobile}</dd>
            </dl>
            <dl>
              <dt>{trans('org_staff_name')}</dt>
              <dd>{order.serviceAgentName}</dd>
            </dl>
            <dl>
              <dt>{trans('status')}</dt>
              <dd>
                <ReceptionOrderStatusLabel value={order.status} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('priority')}</dt>
              <dd>
                <InspectionOrderPriorityLabel value={order.priority} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('created_at')}</dt>
              <dd>{formatTime(order.createdAt)}</dd>
            </dl>
            {order.startedAt && (
              <dl>
                <dt>{trans('started_at')}</dt>
                <dd>{formatTime(order.startedAt)}</dd>
              </dl>
            )}
            {order.finishedAt && (
              <dl>
                <dt>{trans('finished_at')}</dt>
                <dd>{formatTime(order.finishedAt)}</dd>
              </dl>
            )}
            <dl>
              <dt>{trans('remark')}</dt>
              <dd>{order.remark}</dd>
            </dl>
            {order.elapsedTime && (
              <dl>
                <dt>{trans('elapsed_time')}</dt>
                <dd>
                  {order.elapsedTime}
                  {trans('elapsed_time_unit')}
                </dd>
              </dl>
            )}
            <Button
              block
              color="danger"
              onClick={this.onDelete}
              style={{ marginTop: '2rem' }}
            >
              <Translate id="orders.delete.button" />
            </Button>
          </div>
        )}
      </ScopedTranslate>
    );
  }
}
