/**
 * @file: recent-vehicles.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleInfo } from 'model';
import { vehicleInfoService } from 'services';

const actionCreators = createListAsyncActionCreators<AppState, VehicleInfo>(
  'dashboard.recent_vehicle_infos',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      return await vehicleInfoService.list(
        state.dashboard.recentVehicleInfos.filter || {},
        state.dashboard.recentVehicleInfos.sorts?.[0],
        state.dashboard.recentVehicleInfos.offset,
        state.dashboard.recentVehicleInfos.limit,
        { count: false, recursive: true }
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentVehicleInfos,
  invalidate: invalidateDashboardRecentVehicleInfos
} = actionCreators;