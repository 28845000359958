/**
 * @file: VehicleReceptionOrderTechnicianService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleReceptionOrderTechnician,
  VehicleReceptionOrderTechnicianListFilter,
} from 'model';

export class VehicleReceptionOrderTechnicianServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleReceptionOrderTechnicianListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleReceptionOrderTechnician>
      : VehicleReceptionOrderTechnician[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-reception-order-technicians',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleReceptionOrderTechnician | null> {
    return await this.api
      .get()
      .url('/vehicle-reception-order-technicians/:id', { id })
      .future();
  }

  async create(
    vehicleReceptionOrderTechnician: Partial<VehicleReceptionOrderTechnician>,
  ): Promise<VehicleReceptionOrderTechnician> {
    return await this.api
      .post()
      .url('/vehicle-reception-order-technicians')
      .data(vehicleReceptionOrderTechnician)
      .future();
  }

  async update(
    vehicleReceptionOrderTechnician: Partial<VehicleReceptionOrderTechnician>,
  ): Promise<VehicleReceptionOrderTechnician> {
    return await this.api
      .put()
      .url('/vehicle-reception-order-technicians/:id', {
        id: vehicleReceptionOrderTechnician.id,
      })
      .data(vehicleReceptionOrderTechnician)
      .future();
  }

  async delete(id: number): Promise<void> {
    await this.api
      .delete()
      .url('/vehicle-reception-order-technicians/:id', { id })
      .future();
  }
}
