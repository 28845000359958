/**
 * @file: AuditStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AuditStatus, Option } from 'model';

export const AuditStatusOptions: Array<Option<AuditStatus>> = [
  { value: AuditStatus.Pending, label: 'audit_status.pending' },
  { value: AuditStatus.Accepted, label: 'audit_status.accepted' },
  { value: AuditStatus.Rejected, label: 'audit_status.rejected' },
];

export const AuditStatusOptionsWithDefault: Array<Option<AuditStatus | null>> =
  [
    { value: null, label: 'audit_status.__default__' },
    { value: AuditStatus.Pending, label: 'audit_status.pending' },
    { value: AuditStatus.Accepted, label: 'audit_status.accepted' },
    { value: AuditStatus.Rejected, label: 'audit_status.rejected' },
  ];
