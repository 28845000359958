import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { vehicleSystemModuleActions } from '../duck/actions';
import {
  VehicleSystemModule,
  VehicleSystemModuleListFilter,
  AclObjectList,
} from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  getString,
} from 'shared/components';
import { EntityNode } from 'shared/metronic/components';
import { buildTreeModel } from 'lib/helpers';

interface Props
  extends EntityListProps<VehicleSystemModule, VehicleSystemModuleListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  VehicleSystemModule,
  VehicleSystemModuleListFilter,
  number,
  Props
>();

export const VehicleSystemModuleList = componentClassBuilder
  .i18nPrefix('vehicle_system_module')
  .accessRights({
    full: AclObjectList.VehicleSystemModuleFullAccess,
    readonly: AclObjectList.VehicleSystemModuleReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.vehicle_system_modules" /> },
  ])
  .entities(state => state.inspection.vehicleSystemModules)
  .actions(vehicleSystemModuleActions)
  .treeBuilder((items: VehicleSystemModule[], props: Props) => {
    return buildTreeModel(items, {
      itemKey: x => x.id,
      parentItemKey: x => x.parentModId,
      createNode(vehicleSystemModule) {
        const expanded = Boolean(
          !props.entities.collapsedItemIds ||
            !props.entities.collapsedItemIds.has(vehicleSystemModule.id),
        );
        return {
          entity: vehicleSystemModule,
          selected: false,
          expanded,
        } as EntityNode<VehicleSystemModule>;
      },
      appendChildNode(node, childNode) {
        if (!node.children) node.children = [];
        node.children.push(childNode);
      },
    });
  })
  .editor(builder =>
    builder
      .text({
        prop: 'name',
        label: 'vehicle_system_module.editor.label.name',
        placeholder: 'vehicle_system_module.editor.placeholder.name',
      })
      .reactSelect({
        prop: 'parentModId',
        labelProp: 'name',
        valueProp: 'id',
        label: 'vehicle_system_module.editor.label.parent_mod',
        placeholder: 'vehicle_system_module.editor.placeholder.parent_mod',
        isOptionDisabled: (option: VehicleSystemModule, props: Props) => {
          if (props.entities.itemBeingUpdated) {
            return option.id === props.entities.itemBeingUpdated.id;
          }
          return false;
        },
        values: ({ extra }) => {
          const props = extra as Props;
          return props.entities.result?.filter(x => !x.parentModId);
        },
      })
      .image({
        prop: 'iconUrl',
        label: 'vehicle_system_module.editor.label.icon',
        filePicker: {
          realm: 'system/assets/icons',
          accept: 'image/png, image/jpeg, image/svg+xml',
        },
      })
      .textArea({
        prop: 'description',
        label: 'vehicle_system_module.editor.label.description',
        placeholder: 'vehicle_system_module.editor.placeholder.description',
        rows: 10,
      }),
  )
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'name',
        label: 'vehicle_system_module.toolbar.label.name',
        placeholder: 'vehicle_system_module.toolbar.placeholder.name',
        width: 250,
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(vehicleSystemModuleActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'name',
      width: 200,
      text: 'col.name',
      render: ({ name, iconUrl }) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'nowrap',
              marginLeft: 5,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${iconUrl || 'img/default-image.png'})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                width: 32,
                height: 32,
              }}
            />
            <div
              style={{
                fontWeight: 'bold',
                marginLeft: 8,
                fontSize: '1.3rem',
              }}
            >
              {name}
            </div>
          </div>
        );
      },
    },
    {
      prop: 'description',
      width: 400,
      text: 'maintenance_service_subject.col.description',
      render: ({ description }) => description?.substring(0, 200),
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons(['edit', 'remove'])
  .validate(entity => {
    const name = entity.name?.trim();

    if (!name) {
      throw new Error(
        getString('vehicle_system_module.editor.error.name_required'),
      );
    }
  })
  .getClass();
