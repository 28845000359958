/**
 * @file: autoPartNames.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators, createStandardAction } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { AutoPartName } from 'model';
import { autoPartNameService } from 'services';
import { ActionTypes } from '../types';

export const autoPartNameActions = createListAsyncActionCreators<AppState, AutoPartName>(
  'app.settings.auto_part_names',
  {
    getItemBeingCreated: (state: AppState) => state.settings.autoPartNames.itemBeingCreated,
    getItemBeingUpdated: (state: AppState) => state.settings.autoPartNames.itemBeingUpdated,
    getItemsBeingDeleted: (state: AppState) => state.settings.autoPartNames.itemsBeingDeleted,

    fetchHandler: (state: AppState) => {
      return autoPartNameService.list(
        state.settings.autoPartNames.filter,
        state.settings.autoPartNames.sorts?.[0],
        state.settings.autoPartNames.offset,
        state.settings.autoPartNames.limit,
        { count: true, recursive: false }
      );
    },
    create: (entity: AutoPartName) => {
      return autoPartNameService.create(entity);
    },
    update: (entity: AutoPartName) => {
      return autoPartNameService.update(entity);
    },
    delete: (entity: AutoPartName) => {
      return autoPartNameService.delete(entity.id);
    },
    deleteMulti: (entities: AutoPartName[]) => {
      if (!entities.length) return Promise.resolve();
      if (entities.length === 1) {
        return autoPartNameService.delete(entities[0].id);
      }
      return Promise.reject(
        new Error('delete multi auto part names is not supported for now. ')
        );
    }
  }
);

export function importAutoPartNames() {
  return createStandardAction(ActionTypes.ImportAutoPartNames);
}

export function importAutoPartNamesSuccess(count: number) {
  return createStandardAction(ActionTypes.ImportAutoPartNamesSuccess, count);
}

export function importAutoPartNamesFailed(error: Error) {
  return createStandardAction(ActionTypes.ImportAutoPartNamesFailed, error);
}