/**
 * @file: org-members.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { OrgMember, OrgMemberListFilter } from 'model';
import { orgMemberService } from 'services';
import { ActionTypes } from '../types';
import { OrgUserPrincipal } from 'app/customers/duck/states';

export const orgMemberActions = createListAsyncActionCreators<
  AppState,
  OrgMember,
  OrgMemberListFilter,
  number
>('customers.org_members', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,

  async fetchHandler(state: AppState) {
    return await orgMemberService.list(
      state.customers.orgMembers.filter,
      state.customers.orgMembers.sorts?.[0],
      state.customers.orgMembers.offset,
      state.customers.orgMembers.limit,
      { count: true, recursive: true },
    );
  },
});

export function setCurrentMemberQrcodeAuth(user: OrgUserPrincipal | undefined) {
  return createStandardAction(ActionTypes.OrgMemberQrcodeAuth, user);
}
