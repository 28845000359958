import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { config } from 'config';
import {
  AclObjectList,
  Organization,
  OrganizationListFilter,
  ScheduledApplyWeixinTemplateSettingsJobInfo,
} from 'model';
import { CSSProperties, MouseEvent, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { organizationService, systemService } from 'services';
import {
  AsideRight,
  Checkmark,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  QrcodeDownloader,
  StringLabel,
} from 'shared/components';
import { AgentPicker } from 'shared/components/AgentPicker';
import { formatDate } from 'utils';
import { usePersistFn } from 'utils/usePersistFn';
import {
  downloadQrcodeForOrg,
  organizationActions,
  storeActions,
} from '../duck/actions';
import './index.scss';

interface Props extends EntityListProps<Organization, OrganizationListFilter> {
  activeOrgForQrcodeDownloader: Organization | null | undefined;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  Organization,
  OrganizationListFilter,
  number,
  Props
>();

export const OrganizationList = componentClassBuilder
  .i18nPrefix('org')
  .accessRights({
    full: AclObjectList.OrganizationFullAccess,
    readonly: AclObjectList.OrganizationReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="customer.breadcrumb.it" /> },
    { text: <Translate id="customer.breadcrumb.organizations" /> },
  ])
  .mapStateToProps(state => ({
    activeOrgForQrcodeDownloader:
      state.customers.organizations.activeOrgForQrcodeDownloader,
  }))
  .entities(state => state.customers.organizations)
  .actions(organizationActions)
  .requireAreas()
  .editor(builder =>
    builder
      .agent({
        prop: 'agentId',
        label: 'org.editor.label.agent',
        helpText: 'org.editor.help_text.agent',
      })
      .text({
        prop: 'name',
        label: 'org.editor.label.name',
        placeholder: 'org.editor.placeholder.name',
      })
      .text({
        prop: 'cid',
        label: 'org.editor.label.cid',
        placeholder: 'org.editor.placeholder.cid',
        helpText: 'org.editor.help_text.cid',
      })
      .text({
        prop: 'shortName',
        label: 'org.editor.label.short_name',
        placeholder: 'org.editor.placeholder.short_name',
        helpText: 'org.editor.help_text.short_name',
      })
      .text({
        prop: 'brandName',
        label: 'org.editor.label.brand_name',
        placeholder: 'org.editor.placeholder.brand_name',
      })
      .checkbox({
        prop: 'isChainBrand',
        label: 'org.editor.label.is_chain_brand',
      })
      .image({
        prop: 'logoImgUrl',
        label: 'org.editor.label.logo',
        filePicker: {
          cover: false,
          realm: 'org/logos',
          accept: 'image/png, image/jpeg, image/gif',
        },
      })
      .image({
        prop: 'iconImgUrl',
        label: 'org.editor.label.icon',
        filePicker: {
          cover: false,
          mediaSize: 200,
          realm: 'org/logos',
          accept: 'image/png, image/jpeg',
        },
        helpText: 'org.editor.help_text.icon',
      })
      .area({
        label: 'org.editor.label.city',
      })
      .text({
        prop: 'address',
        label: 'org.editor.label.address',
        placeholder: 'org.editor.placeholder.address',
      })
      .text({
        prop: 'wechatMpAccount',
        label: 'org.editor.label.wechat_mp_account',
        placeholder: 'org.editor.placeholder.wechat_mp_account',
      })
      .text({
        prop: 'contactName',
        label: 'org.editor.label.contact.name',
        placeholder: 'org.editor.placeholder.contact.name',
      })
      .text({
        prop: 'contactPhone',
        label: 'org.editor.label.contact.phone',
        placeholder: 'org.editor.placeholder.contact.phone',
      })
      .text({
        prop: 'contactMobile',
        label: 'org.editor.label.contact.mobile',
        placeholder: 'org.editor.placeholder.contact.mobile',
      })
      .text({
        prop: 'contactFax',
        label: 'org.editor.label.contact.fax',
        placeholder: 'org.editor.placeholder.contact.fax',
      })
      .text({
        prop: 'contactEmail',
        label: 'org.editor.label.contact.email',
        placeholder: 'org.editor.placeholder.contact.email',
      })
      .textArea({
        prop: 'introduction',
        label: 'org.editor.label.introduction',
        placeholder: 'org.editor.placeholder.introduction',
        rows: 6,
      })
      .textArea({
        prop: 'description',
        label: 'org.editor.label.description',
        placeholder: 'org.editor.placeholder.description',
      })
      .text({
        prop: 'weworkApiScheme',
        label: 'org.editor.label.wework_api_scheme',
        placeholder: 'org.editor.placeholder.wework_api_scheme',
      })
      .text({
        prop: 'weworkApiCorpId',
        label: 'org.editor.label.wework_api_corp_id',
        placeholder: 'org.editor.placeholder.wework_api_corp_id',
      })
      .text({
        prop: 'weworkApiAgentId',
        label: 'org.editor.label.wework_api_agent_id',
        placeholder: 'org.editor.placeholder.wework_api_agent_id',
      })
      .checkbox({
        prop: 'enabled',
        label: 'org.editor.label.enabled',
      }),
  )
  .toolbarItems(builder => {
    builder
      .custom({
        prop: 'agentId',
        render: (filter, onChange) => {
          return (
            <AgentPicker
              agentId={filter.agentId}
              onChange={agentId =>
                onChange(props => {
                  props.agentId = agentId;
                })
              }
              style={{ width: 250 }}
            />
          );
        },
      })
      .select({
        prop: 'enabled',
        placeholder: 'org.toolbar.placeholder.service_status',
        clearable: true,
        width: 150,
        values: [
          {
            label: '@string/org.toolbar.service_status.enabled',
            value: true,
          },
          {
            label: '@string/org.toolbar.service_status.disabled',
            value: false,
          },
        ],
        onGetOptionLabel(option) {
          return getString(option.label);
        },
      })
      .select({
        prop: 'isChainBrand',
        placeholder: 'org.toolbar.placeholder.org_type',
        clearable: true,
        width: 150,
        values: [
          {
            label: '@string/org.toolbar.org_type.chain',
            value: true,
          },
          {
            label: '@string/org.toolbar.org_type.non_chain',
            value: false,
          },
        ],
        onGetOptionLabel(option) {
          return getString(option.label);
        },
      })
      .text({
        prop: 'cid',
        placeholder: 'org.toolbar.placeholder.cid',
        width: 150,
      })
      .text({
        prop: 'name',
        placeholder: 'org.toolbar.placeholder.name',
        width: 150,
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(organizationActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'logoImgUrl',
      width: 140,
      text: 'org.col.organization',
      render: ({ logoImgUrl }) => (
        <img
          className="org-info__logo"
          style={{ width: 140 }}
          src={logoImgUrl || '/img/org-placeholder.png'}
        />
      ),
    },
    {
      prop: 'name',
      width: 250,
      text: 'col.name',
      render: (org, props: Props) => {
        const onNavigateToStoresClick = (e: MouseEvent) => {
          e.preventDefault();
          props.history.push('/business-customers/stores');
          props.dispatch(
            storeActions.updateFilter({
              agentId: undefined,
              orgId: org.id,
              editionId: undefined,
              isTrialEdition: undefined,
              serviceStatus: undefined,
              isExpiring: undefined,
            }),
          );
        };

        const onOpenStorefrontClick = (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();

          props.dispatch(showAppLoading());
          const win = window.open()!;
          win.document.title = 'Please wait... ';
          systemService
            .getOtcLoginCode(org.id)
            .then(code => {
              const urlTemplate: string =
                (window as any).__storeFrontUrl__ ?? config.storeFrontUrl;
              const url = new URL(urlTemplate.replace('%cid%', org.cid));
              url.pathname = '/otc-login';
              url.searchParams.set('code', code);
              win.location.href = url.toString();
            })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .catch(e => {
              alert(e.message);
            })
            .finally(() => {
              props.dispatch(hideAppLoading());
            });
        };

        const renderInfo = (label: string, value: any) =>
          value == null ? null : (
            <dl>
              <dt>
                <Translate id={`org.org_info.label.${label}`} />
              </dt>
              <dd
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                {value}
              </dd>
            </dl>
          );
        return (
          <div className="org-info__detail">
            <p style={{ marginBottom: '0.35rem' }}>
              <a
                href="#"
                target="_blank"
                style={{ fontWeight: 'bold' }}
                onClick={onOpenStorefrontClick}
              >
                {org.name}
              </a>
            </p>
            <dl style={{ marginBottom: '0.5rem' }}>
              <dd
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Checkmark
                  value={org.enabled}
                  style={{ fontSize: '0.9rem' }}
                  showFalseIcon
                />
                <span
                  className={`text-${org.enabled ? 'success' : 'danger'}`}
                  style={{ marginLeft: '0.25rem' }}
                >
                  <Translate
                    id={`org.org_info.label.service.${
                      org.enabled ? 'enabled' : 'disabled'
                    }`}
                  />
                </span>
                {org.isChainBrand ? (
                  <span
                    style={{
                      border: '1px solid #36a3f7',
                      color: '#36a3f7',
                      display: 'inline-block',
                      borderRadius: '0.15rem',
                      padding: '0 0.25rem',
                      marginLeft: '0.35rem',
                      fontSize: '0.7rem',
                    }}
                  >
                    <Translate id="org.org_info.label.chain_brand" />
                  </span>
                ) : null}
              </dd>
            </dl>
            {renderInfo('short_name', org.shortName)}
            {renderInfo('brand_name', org.brandName)}
            {renderInfo(
              'store_count',
              <a href="#" onClick={onNavigateToStoresClick}>
                <Translate
                  id="org.org_info.value.store_count"
                  data={{ count: org.storeCount }}
                />
              </a>,
            )}
            {renderInfo('wechat_mp_account', org.wechatMpAccount)}
            {renderInfo(
              'app_id',
              <>
                {org.wechatMpAppid}
                <WxTemplatesScheduleInfo orgId={org.id} />
              </>,
            )}
          </div>
        );
      },
    },
    {
      prop: 'address',
      width: 300,
      text: 'org.col.addr_contact',
      render: (entity, props: Props) => {
        if (!entity.provinceId) return '-';
        if (!props.areas?.result || props.areas.isLoading) {
          return '...';
        }
        const province = props.areas.result.getNodeById(entity.provinceId);
        const city = props.areas.result.getNodeById(entity.cityId!);
        const district = props.areas.result.getNodeById(entity.districtId!);
        const parts: string[] = [];
        if (province.children.length > 1) {
          parts.push(province.area.name);
        }
        parts.push(city.area.name, district.area.name);

        const renderInfo = (label: string, value: any) =>
          value == null ? null : (
            <dl>
              <dt>
                <Translate id={`org.org_info.label.${label}`} />
              </dt>
              <dd>{value}</dd>
            </dl>
          );

        return (
          <div className="org-info__detail">
            {renderInfo('city', parts.join('/'))}
            {renderInfo('address', entity.address)}
            <ContactInfo entity={entity} />
          </div>
        );
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 100,
      align: 'center',
      render: ({ createdAt }) => formatDate(createdAt),
    },
  ])
  .addActionButtons([
    'edit',
    'remove',
    {
      key: 'qrcode',
      icon: 'la la-qrcode',
      tooltip: getString('org.qrcode_downloader.btn_tooltip.org'),
      onClick: (item, props: Props) => {
        props.dispatch(downloadQrcodeForOrg(item));
      },
    },
  ])
  .validate(entity => {
    const cid = entity.cid?.trim();
    const name = entity.name?.trim();
    const shortName = entity.shortName?.trim();
    const brandName = entity.brandName?.trim();

    if (!cid) {
      throw new Error(getString('org.editor.error.cid_required'));
    }
    if (!/^[a-z0-9]/.test(cid)) {
      throw new Error(
        getString('org.editor.error.invalid_cid_format_no_leading_letter'),
      );
    }
    if (!/^[a-z0-9]{1,16}$/.test(cid)) {
      throw new Error(getString('org.editor.error.invalid_cid_format'));
    }
    if (!name) {
      throw new Error(getString('org.editor.error.name_required'));
    }
    if (!shortName) {
      throw new Error(getString('org.editor.error.short_name_required'));
    }
    if (!brandName) {
      throw new Error(getString('org.editor.error.brand_name_required'));
    }
    if (!entity.provinceId || !entity.cityId || !entity.districtId) {
      throw new Error(getString('org.editor.error.city_required'));
    }
  })
  .onRender(props => {
    const org = props.activeOrgForQrcodeDownloader;
    return (
      <AsideRight
        open={Boolean(org)}
        onClose={() => {
          props.dispatch(downloadQrcodeForOrg(null));
        }}
      >
        {org ? <QrcodeDownloader orgId={org.id} /> : null}
      </AsideRight>
    );
  })
  .getClass();

function ContactInfo({ entity }: { entity: Organization }) {
  const style: CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };
  return (
    <>
      {entity.contactName && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.name" />
          </dt>
          <dd>{entity.contactName}</dd>
        </dl>
      )}
      {entity.contactPhone && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.phone" />
          </dt>
          <dd>{entity.contactPhone}</dd>
        </dl>
      )}
      {entity.contactMobile && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.mobile" />
          </dt>
          <dd>{entity.contactMobile}</dd>
        </dl>
      )}
      {entity.contactFax && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.fax" />
          </dt>
          <dd>{entity.contactFax}</dd>
        </dl>
      )}
      {entity.contactEmail && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.email" />
          </dt>
          <dd>{entity.contactEmail}</dd>
        </dl>
      )}
    </>
  );
}

function WxTemplatesScheduleInfo({ orgId }: { orgId: number }) {
  const dispatch = useDispatch();

  const [jobInfo, setJobInfo] = useState<
    ScheduledApplyWeixinTemplateSettingsJobInfo | null | undefined
  >();

  const onClick = usePersistFn(async (e: MouseEvent) => {
    e.preventDefault();
    if (jobInfo) {
      if (confirm(getString('org.wx_tpl_schedule.confirm_cancel'))) {
        await organizationService.unscheduleAutoApplyWeixinOpenTemplateSettings(
          orgId,
        );
        setJobInfo(null);
        dispatch(
          showAppLoading({
            message: getString('org.wx_tpl_schedule.cancel_success'),
            status: 'warning',
            timeout: 1500,
          }),
        );
      }
    } else {
      const time = prompt(getString('org.wx_tpl_schedule.prompt'), '08:00:00');
      if (!time) return;
      const match = /^(\d{2}):(\d{2}):(\d{2})$/.exec(time);
      if (!match) {
        alert(getString('org.wx_tpl_schedule.format_error'));
        return;
      }
      const res =
        await organizationService.scheduleAutoApplyWeixinOpenTemplateSettings(
          orgId,
          time,
        );
      setJobInfo(res);
      dispatch(
        showAppLoading({
          message: getString('org.wx_tpl_schedule.schedule_success'),
          status: 'success',
          timeout: 1500,
        }),
      );
    }
  });

  useEffect(() => {
    organizationService
      .getAutoApplyWeixinOpenTemplateSettingsScheduledStatus(orgId)
      .then(res => {
        setJobInfo(res ?? null);
      })
      .catch(err => {
        console.error(err);
      });
  }, [orgId]);

  if (jobInfo === undefined) {
    return null;
  }

  const tooltip = getString(
    `org.wx_tpl_schedule.tip.${jobInfo ? 'scheduled' : 'unscheduled'}`,
    { time: jobInfo?.time ?? '' },
  );

  return (
    <a
      href="#"
      onClick={onClick}
      data-tooltip={tooltip}
      title={tooltip}
      style={{
        color: jobInfo ? 'var(--red)' : '#ccc',
        marginLeft: '0.2rem',
      }}
    >
      <i
        className="fa fa-clock"
        style={{
          fontSize: '0.9rem',
        }}
      />
    </a>
  );
}
