/**
 * @file: configurations.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { SystemConfigurationItem } from 'model';

const actionCreators = createListAsyncActionCreators<
  AppState,
  SystemConfigurationItem
>('app.settings.configurations', {
  fetchHandler() {
    throw new Error('Not implemented');
  },
  update(_item: SystemConfigurationItem) {
    throw new Error('Not implemented');
  },
  getItemBeingUpdated: (state: AppState) =>
    state.settings.configurations.itemBeingUpdated,
});

export const {
  fetch: fetchConfigurationItems,
  requestUpdate: requestUpdateConfigurationItem,
  invalidate: invalidateConfigurationItems,
  itemBeingUpdated: configurationItemBeingUpdated,
  commitItemBeingUpdated: commitConfigurationItemBeingUpdated,
  cancelItemBeingUpdated: cancelConfigurationItemBeingUpdated,
  itemBeingUpdatedChanged: configurationItemBeingUpdatedChanged,
} = actionCreators;
