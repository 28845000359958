import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { ProductAgent } from 'model';
import { ProductAgents } from '../states';
import { ActionTypes } from '../types';

const initialState: ProductAgents = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<
  ProductAgent,
  ProductAgents
>('customers.product_agents', initialState, {
  mapItemKey: x => x.id,
});

export default function productAgentsReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ProductAgents = initialState,
  action: StandardAction<any>,
): ProductAgents {
  switch (action.type) {
    case ActionTypes.SetActiveProductAgentForTxHistory: {
      return Object.assign({}, state, {
        activeAgentIdForTxHistory: action.payload,
      });
    }
    default: {
      return defaultReducer(state, action);
    }
  }
}
