/**
 * @file: ProductAgentService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  IdType,
  ListResult,
  ProductAgentAccount,
  ProductAgentAccountTransaction,
} from 'model';
import { ProductAgentServiceBase } from './base/ProductAgentService';

export class ProductAgentService extends ProductAgentServiceBase {
  async getAccountInfo(agentId: IdType): Promise<ProductAgentAccount> {
    return await this.api
      .get()
      .url('/product-agents/:agentId/account', { agentId })
      .future();
  }

  async searchAccountTransactions(
    agentId: IdType,
    offset: number,
    limit: number,
  ): Promise<ListResult<ProductAgentAccountTransaction>> {
    return await this.api
      .get()
      .url('/product-agents/:agentId/account/transactions', {
        agentId,
        offset,
        limit,
      })
      .future();
  }

  async getAccountTransactionInfo(
    agentId: IdType,
    txId: string,
  ): Promise<ProductAgentAccountTransaction | null> {
    return await this.api
      .get()
      .url('/product-agents/:agentId/account/transactions/:txId', {
        agentId,
        txId,
      })
      .future();
  }

  async confirmAccountRechargeTx(
    agentId: IdType,
    txId: string,
  ): Promise<{
    tx: ProductAgentAccountTransaction;
    newTx: ProductAgentAccountTransaction;
  }> {
    return await this.api
      .post()
      .url(
        '/product-agents/:agentId/account/transactions/:txId/confirm-recharge',
        {
          agentId,
          txId,
        },
      )
      .future();
  }
}
