/**
 * @file: task-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { VehicleInspectionTask, VehicleInspectionTaskListFilter } from 'model';
import { vehicleInspectionTaskService } from 'services';
import { ActionTypes } from '../types';

export const taskListActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionTask,
  VehicleInspectionTaskListFilter,
  number
>('tasks.task_list', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.tasks.taskList.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.tasks.taskList.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.tasks.taskList.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleInspectionTaskService.list(
      state.tasks.taskList.filter,
      state.tasks.taskList.sorts?.[0],
      state.tasks.taskList.offset,
      state.tasks.taskList.limit,
      { count: true, recursive: true },
    );
  },
  async update(task: VehicleInspectionTask) {
    return await vehicleInspectionTaskService.update(task);
  },
  async delete(task: VehicleInspectionTask) {
    await vehicleInspectionTaskService.delete(task.id);
  },
  async deleteMulti(tasks: VehicleInspectionTask[]) {
    if (tasks.length === 1) {
      await vehicleInspectionTaskService.delete(tasks[0].id);
      return;
    }
    throw new Error('delete tasks more than 1 is not supported. ');
  },
});

export function setTaskListActiveTaskId(taskId: number | undefined) {
  return createStandardAction(ActionTypes.SetActiveTask, taskId);
}
