/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

const common = require('./strings/common.yaml');
const enums = require('./strings/enums.yaml');
const menu = require('./strings/menu.yaml');
const dashboard = require('./strings/dashboard.yaml');
const login = require('./strings/login.yaml');
const settings = require('./strings/settings.yaml');
const user = require('./strings/user.yaml');
const org = require('./strings/organization.yaml');
const store = require('./strings/store.yaml');
const receptionOrder = require('./strings/reception-order.yaml');
const inspectionTask = require('./strings/inspection-task.yaml');
const inspectionReport = require('./strings/inspection-report.yaml');
const inspectionComment = require('./strings/inspection-comment.yaml');
const customer = require('./strings/customer.yaml');
const orgUser = require('./strings/org-user.yaml');
const orgMember = require('./strings/org-member.yaml');
const orgCustomer = require('./strings/org-customer.yaml');
const inspection = require('./strings/inspection.yaml');
const inspectionSite = require('./strings/inspection-site.yaml');
const inspectionSiteCategory = require('./strings/inspection-site-category.yaml');
const inspectionSiteItem = require('./strings/inspection-site-item.yaml');
const inspectionSiteItemOption = require('./strings/inspection-site-item-option.yaml');
const inspectionTool = require('./strings/inspection-tool.yaml');
const adminUser = require('./strings/admin_user.yaml');
const inspectionTemplates = require('./strings/inspection-template.yaml');
const orders = require('./strings/orders.yaml');
const tasks = require('./strings/tasks.yaml');
const reports = require('./strings/reports.yaml');
const vehicle = require('./strings/vehicle.yaml');
const serviceSubject = require('./strings/service-subject.yaml');
const inspectionSubject = require('./strings/inspection-subject.yaml');
const autoPartName = require('./strings/auto-part-name.yaml');
const quotationPartCatalog = require('./strings/quotation-part-catalog.yaml');
const checkDeliveryTemplate = require('./strings/delivery-check-template.yaml');
const openapi = require('./strings/openapi.yaml');
const openapiUsers = require('./strings/openapi-users.yaml');
const openapiApps = require('./strings/openapi-apps.yaml');
const agent = require('./strings/agent.yaml');
const maintenanceServiceSubjects = require('./strings/maintenance-service-subject.yaml');
const vehicleSystemModule = require('./strings/vehicle-system-module.yaml');
const dataExport = require('./strings/data-export.yaml');
const serviceEdition = require('./strings/service-edition.yaml');
const batchJob = require('./strings/batch-job.yaml');
const constructionTpl = require('./strings/construction-template-config.yaml');
const productAgent = require('./strings/product-agent.yaml');
const productBrand = require('./strings/product-brand.yaml');
const systemConfig = require('./strings/system-config.yaml');
const quotationTpl = require('./strings/quotation-template-config.yaml');

export default {
  ...common,
  ...enums,
  menu,
  dashboard,
  login,
  settings,
  user,
  org,
  agent,
  store,
  product_agent: productAgent,
  product_brand: productBrand,
  admin_user: adminUser,
  inspection_order: receptionOrder,
  inspection_task: inspectionTask,
  inspection_report: inspectionReport,
  inspection_comment: inspectionComment,
  customer,
  org_user: orgUser,
  org_member: orgMember,
  org_customer: orgCustomer,
  inspection,
  inspection_site: inspectionSite,
  inspection_site_category: inspectionSiteCategory,
  inspection_site_item: inspectionSiteItem,
  inspection_site_item_option: inspectionSiteItemOption,
  inspection_tool: inspectionTool,
  inspection_template: inspectionTemplates,
  orders,
  tasks,
  reports,
  vehicle,
  service_subject: serviceSubject,
  inspection_subject: inspectionSubject,
  auto_part_name: autoPartName,
  quotation_part_catalog: quotationPartCatalog,
  delivery_check_template: checkDeliveryTemplate,
  openapi,
  openapi_users: openapiUsers,
  openapi_apps: openapiApps,
  maintenance_service_subject: maintenanceServiceSubjects,
  vehicle_system_module: vehicleSystemModule,
  data_export: dataExport,
  batch_job: batchJob,
  service_edition: serviceEdition,
  construction_tpl: constructionTpl,
  system_config: systemConfig,
  quotation_tpl: quotationTpl,
};
