/**
 * @file: recent-vehicles.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInfo } from 'model';
import { RecentVehicleInfos } from '../states';

const initialState: RecentVehicleInfos = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'createdAt', dir: 'desc' }],
  offset: 0,
  limit: 15
};

export default createAsyncListActionReducers<VehicleInfo, RecentVehicleInfos>(
  'dashboard.recent_vehicle_infos',
  initialState,
  {
    mapItemKey: x => x.id
  }
);