/**
 * @file: TransferBatchCloseReason.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum TransferBatchCloseReason {
  Timeout = 'OVERDUE_CLOSE',
  InvalidScene = 'TRANSFER_SCENE_INVALID',
}

export const TransferBatchCloseReasonValueSet = new Set([
  TransferBatchCloseReason.Timeout,
  TransferBatchCloseReason.InvalidScene,
]);
