import React, { CSSProperties, MouseEvent } from 'react';

export function renderFilePreviewer(options: {
  type: 'image' | 'video';
  url: string;
  width: number;
  height: number;
  posterUrl?: string;
  style?: CSSProperties;
  coverStyle?: CSSProperties;
  onRemoveClick?: (e: MouseEvent) => void;
}) {
  const { type, url, width, height, posterUrl, style, coverStyle } = options;

  if (!url) {
    const defaultImage =
      type === 'image' ? 'default-image' : 'default-video-image';
    return (
      <div
        style={{
          width,
          height,
          backgroundImage: `url(img/${defaultImage}.png)`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          ...style,
          ...coverStyle,
        }}
      />
    );
  }
  if (type === 'image') {
    return (
      <div
        style={{
          width,
          height,
          backgroundImage: `url("${url}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          position: 'relative',
          ...style,
          ...coverStyle,
        }}
      >
        <a
          href="#"
          className="btn-remove"
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            textDecoration: 'none',
          }}
          onClick={options.onRemoveClick}
        >
          <i
            className="fa fa-window-close"
            style={{ fontSize: '1.1rem', color: 'red' }}
          />
        </a>
      </div>
    );
  }

  return (
    <video
      poster={posterUrl}
      controls
      width={width}
      height={height}
      style={{ objectFit: 'cover', ...style }}
      src={url}
      key={url}
    >
      <source src={url} type="video/mp4" />
    </video>
  );
}
