import { BarriersSettings } from 'app/inspection/templates/BarriersSettings';
import { TemplateManagerProps } from 'app/inspection/templates/manager';
import { VehicleInspectionTemplate } from 'model';
import {
  InspectionTemplatePredefinedTypeOptions,
  InspectionTemplateSceneTypeOptions,
} from 'model/EnumOptions';
import { getString, withEntityEditorSidebarBuilder } from 'shared/components';

export const TemplateEditor =
  withEntityEditorSidebarBuilder<VehicleInspectionTemplate>()
    .withI18nPrefix('inspection_template')
    .withForm(builder =>
      builder
        .agent({
          prop: 'agentId',
          label: 'inspection_template.editor.label.agent',
          helpText: 'inspection_template.editor.help_text.agent',
        })
        .text({
          prop: 'name',
          label: 'inspection_template.editor.label.name',
          placeholder: 'inspection_template.editor.placeholder.name',
        })
        .select({
          prop: 'sceneType',
          options: InspectionTemplateSceneTypeOptions,
          label: 'inspection_template.editor.label.scene_type',
          placeholder: 'inspection_template.editor.placeholder.scene_type',
          helpText: 'inspection_template.editor.help_text.scene_type',
        })
        .select({
          prop: 'predefinedType',
          options: InspectionTemplatePredefinedTypeOptions,
          label: 'inspection_template.editor.label.predefined_type',
          placeholder: 'inspection_template.editor.placeholder.predefined_type',
        })
        .multiTextInput({
          prop: 'tags',
          label: 'inspection_template.editor.label.tags',
          placeholder: 'inspection_template.editor.placeholder.tags',
          serialize: values => values.map(x => x.trim()).join(','),
          deserialize: (value: string) =>
            value
              .split(/,/g)
              .map(x => x.trim())
              .filter(x => x),
        })
        .image({
          prop: 'icon',
          label: 'inspection_template.editor.label.icon',
          filePicker: {
            cover: false,
            mediaSize: 200,
            realm: 'template/icons',
            accept: 'image/png, image/jpeg',
          },
        })
        .checkbox({
          prop: 'isSystemDefault',
          label: 'inspection_template.editor.label.is_system_default',
        })
        .checkbox({
          prop: 'disabled',
          inverse: true,
          label: 'inspection_template.editor.label.enabled',
        })
        .checkbox({
          prop: 'isHardwareInspectionEnabled',
          label:
            'inspection_template.editor.label.is_hardware_inspection_enabled',
        })
        .custom({
          label: '',
          key: 'barriers',
          hidden: entity => Boolean(!entity.id || entity.agentId),
          render: ({ props }: { props: TemplateManagerProps }) => {
            if (!props.templates.itemBeingUpdated) return null;
            return (
              <BarriersSettings
                templateId={props.templates.itemBeingUpdated.id}
              />
            );
          },
        })
        .textArea({
          prop: 'description',
          label: 'inspection_template.editor.label.description',
          placeholder: 'inspection_template.editor.placeholder.description',
        })
        .text({
          prop: 'sortOrder',
          type: 'number',
          label: 'inspection_template.editor.label.sort_order',
          placeholder: 'inspection_template.editor.placeholder.sort_order',
        }),
    )
    .withValidator(template => {
      let code: string | undefined = undefined;
      if (!template.name?.trim()) {
        code = 'template_name_required';
      } else if (!template.sceneType) {
        code = 'scene_type_required';
      } else if (!template.predefinedType) {
        code = 'template_type_required';
      }
      if (code) {
        const msg = getString(`inspection_template.editor.error.${code}`);
        throw new Error(msg);
      }
    })
    .getClass();
