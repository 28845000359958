import {
  FormElement,
  FormElementText,
  FormElementTextArea,
  FormElementType,
  FormElementHtml,
  FormElementFile,
  FormElementCheckbox,
  FormElementCheckboxList,
  FormElementSelect,
  FormElementReactSelect,
  FormElementSwitch,
  FormElementCustom,
  FormElementAreaPicker,
  FormElementGroup,
  EntityWithAreaProps,
  TextInputType,
  FormElementMedias,
  FormElementMultiTextInput,
  FormElementServiceDurationPicker,
  FormElementServiceEditionPicker,
  EntityWithServiceEditionProps,
  FormElementProductAgentPicker,
  EntityWithProductAgentBounded,
} from './types';
import { ClassNameType } from 'model';
import {
  EntityWithAgentBounded,
  FormElementAgentPicker,
} from 'shared/components';

type ElemParamType<T extends { type: FormElementType }> = Omit<T, 'type'>;

export class EntityEditorFormBuilder<T extends object> {
  protected autocomplete?: boolean;
  protected useUncontrolled?: boolean;
  protected elements: Array<FormElement<T>> = [];

  uncontrolled(): this {
    this.useUncontrolled = true;
    return this;
  }

  text(
    elem: ElemParamType<FormElementText<T>> & { type?: TextInputType },
  ): this {
    this.elements.push({ type: elem.type || 'text', ...elem });
    return this;
  }

  textArea(elem: ElemParamType<FormElementTextArea<T>>): this {
    this.elements.push({ type: 'textarea', ...elem });
    return this;
  }

  multiTextInput(elem: ElemParamType<FormElementMultiTextInput<T>>): this {
    this.elements.push({ type: 'multi-text-input', ...elem });
    return this;
  }

  html(elem: ElemParamType<FormElementHtml<T>>): this {
    this.elements.push({ type: 'html', ...elem });
    return this;
  }

  image(elem: ElemParamType<FormElementFile<T>>): this {
    this.elements.push({ type: 'image', ...elem });
    return this;
  }

  video(elem: ElemParamType<FormElementFile<T>>): this {
    this.elements.push({ type: 'video', ...elem });
    return this;
  }

  medias(elem: ElemParamType<FormElementMedias<T>>): this {
    this.elements.push({ type: 'medias', ...elem });
    return this;
  }

  checkbox(elem: ElemParamType<FormElementCheckbox<T>>): this {
    this.elements.push({ type: 'checkbox', ...elem });
    return this;
  }

  radioList<U>(elem: ElemParamType<FormElementCheckboxList<T, U>>): this {
    this.elements.push({ type: 'radiolist', ...elem });
    return this;
  }

  checkboxList<U>(elem: ElemParamType<FormElementCheckboxList<T, U>>): this {
    this.elements.push({ type: 'checkboxlist', ...elem });
    return this;
  }

  select<U>(elem: ElemParamType<FormElementSelect<T, U>>): this {
    this.elements.push({ type: 'select', ...elem });
    return this;
  }

  reactSelect<U>(elem: ElemParamType<FormElementReactSelect<T, U>>): this {
    this.elements.push({ type: 'reactselect', ...elem });
    return this;
  }

  switch(elem: ElemParamType<FormElementSwitch<T>>): this {
    this.elements.push({ type: 'switch', ...elem });
    return this;
  }

  area(
    elem: Omit<
      ElemParamType<FormElementAreaPicker<T & EntityWithAreaProps>>,
      'prop'
    >,
  ): this {
    this.elements.push({ type: 'area', ...elem, prop: '__area__' as any });
    return this;
  }

  serviceDuration(
    elem: ElemParamType<FormElementServiceDurationPicker<T>>,
  ): this {
    this.elements.push({
      type: 'service-duration',
      ...elem,
    });
    return this;
  }

  agent(
    elem: ElemParamType<FormElementAgentPicker<T & EntityWithAgentBounded>>,
  ): this {
    this.elements.push({ type: 'agent', ...elem } as any);
    return this;
  }

  productAgent(
    elem: ElemParamType<
      FormElementProductAgentPicker<T & EntityWithProductAgentBounded>
    >,
  ): this {
    this.elements.push({ type: 'product-agent', ...elem } as any);
    return this;
  }

  serviceEdition(
    elem: ElemParamType<
      FormElementServiceEditionPicker<T & EntityWithServiceEditionProps>
    >,
  ): this {
    this.elements.push({ type: 'service-edition', ...elem } as any);
    return this;
  }

  custom(elem: Omit<ElemParamType<FormElementCustom<T>>, 'prop'>): this {
    this.elements.push({ type: 'custom', ...elem, prop: '' as any });
    return this;
  }

  group(
    buildGroup: (builder: EntityEditorFormElementGroupBuilder<T>) => void,
  ): this {
    const builder = new EntityEditorFormElementGroupBuilder<T>();
    buildGroup(builder);
    const group = builder.buildGroup();
    this.elements.push(group);
    return this;
  }

  autocompleteOff(): this {
    this.autocomplete = false;
    return this;
  }

  build() {
    return {
      autocomplete: this.autocomplete,
      useUncontrolled: this.useUncontrolled,
      elements: this.elements,
    };
  }
}

export class EntityEditorFormElementGroupBuilder<
  T extends object,
> extends EntityEditorFormBuilder<T> {
  private label: string = '';
  private className?: ClassNameType;
  private width?: string | number;

  withLabel(label: string): this {
    this.label = label;
    return this;
  }

  withClassName(className: ClassNameType): this {
    this.className = className;
    return this;
  }

  withWidth(width: number | string) {
    this.width = width;
    return this;
  }

  buildGroup(): FormElementGroup<T> {
    return {
      type: 'element-group',
      label: this.label,
      prop: '' as any,
      width: this.width,
      className: this.className,
      elements: this.elements,
    };
  }
}
