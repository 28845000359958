/**
 * @file: OrgUserVisibleStoreService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  OrgUserVisibleStore,
  OrgUserVisibleStoreListFilter,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class OrgUserVisibleStoreServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<OrgUserVisibleStoreListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<OrgUserVisibleStore>
      : OrgUserVisibleStore[]
  > {
    return await this.api
      .get()
      .url(
        '/org-user-visible-stores',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(userId: number): Promise<OrgUserVisibleStore | null> {
    return await this.api
      .get()
      .url('/org-user-visible-stores/:userId', { userId })
      .future();
  }

  async create(
    orgUserVisibleStore: Partial<OrgUserVisibleStore>,
  ): Promise<OrgUserVisibleStore> {
    return await this.api
      .post()
      .url('/org-user-visible-stores')
      .data(orgUserVisibleStore)
      .future();
  }

  async update(
    orgUserVisibleStore: Partial<OrgUserVisibleStore>,
  ): Promise<OrgUserVisibleStore> {
    return await this.api
      .put()
      .url('/org-user-visible-stores/:userId', {
        userId: orgUserVisibleStore.userId,
      })
      .data(orgUserVisibleStore)
      .future();
  }

  async delete(userId: number): Promise<void> {
    return void (await this.api
      .delete()
      .url('/org-user-visible-stores/:userId', { userId })
      .future());
  }
}
