/**
 * @file comparer.ts
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

export type CompareFn<T> = (x: T, y: T) => number;
type PropFn<T, U> = (x: T) => U;

export const comparer: CompareFn<any> = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export function compareByProp<T extends { [name: string]: any }, U>(
  prop: string | PropFn<T, U>,
  desc: boolean = false,
  compareFn?: CompareFn<U>,
): CompareFn<T> {
  const compare = compareFn || comparer;
  return (a: T, b: T) => {
    if (typeof prop === 'function') {
      const [x, y] = [prop(a), prop(b)];
      return desc ? compare(y, x) : compare(x, y);
    } else {
      const [x, y] = [a[prop], b[prop]];
      return desc ? compare(y, x) : compare(x, y);
    }
  };
}
