import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { DataExportTask, DataExportTaskListFilter } from 'model';
import { dataExportTaskService } from 'services';
import { ActionTypes } from '../types';

export const dataExportTaskActions = createListAsyncActionCreators<
  AppState,
  DataExportTask,
  DataExportTaskListFilter,
  number
>('dataExportTasks', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.dataExportTasks.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.dataExportTasks.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.dataExportTasks.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await dataExportTaskService.list(
      state.dataExportTasks.filter,
      state.dataExportTasks.sorts?.[0],
      state.dataExportTasks.offset,
      state.dataExportTasks.limit,
      { count: true, recursive: true },
    );
  },
  async create(dataExportTask: Partial<DataExportTask>) {
    return await dataExportTaskService.create(dataExportTask);
  },
  update() {
    throw new Error('Update data export task is not supported');
  },
  async delete(dataExportTask: DataExportTask) {
    await dataExportTaskService.delete(dataExportTask.id);
  },
  async deleteMulti(orgs: DataExportTask[]) {
    if (orgs.length === 1) {
      await dataExportTaskService.delete(orgs[0].id);
      return;
    }
    throw new Error('delete multi dataExportTasks is not supported. ');
  },
});

export function showAddDataExportJobTask(
  show: boolean,
  existing?: DataExportTask,
) {
  return createStandardAction(ActionTypes.ShowAddDataExportTask, {
    show,
    existing: show ? existing : undefined,
  });
}
