import classNames from 'classnames';
import React, { Component, HTMLProps } from 'react';
import { ColorType } from 'model';

interface Props extends HTMLProps<HTMLDivElement> {
  title: any;
  subTitle?: string;
  icon?: string;
  color?: ColorType;
}

export class PortletHeader extends Component<Props> {
  render() {
    const { title, subTitle, icon, color, children, ...props } = this.props;
    return (
      <div className={classNames('m-portlet__head')} {...props}>
        <div className="m-portlet__head-caption">
          <div className="m-portlet__head-title">
            {icon &&
            <span className="m-portlet__head-icon">
              <i className={icon} />
            </span>}
            {typeof title === 'string' ?
              <h3 className={classNames('m-portlet__head-text', {
                [`m--font-${color}`]: color
              })}>
                {title}
                {subTitle && <small>{subTitle}</small>}
              </h3> :
              <div className="m-portlet__head-text">{title}</div>
            }
          </div>
        </div>
        {children &&
        <div className="m-portlet__head-tools">
          {children}
        </div>}
      </div>
    );
  }
}