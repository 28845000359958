import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { OpenApiApp, OpenApiAppListFilter } from 'model';
import { openApiAppService } from 'services';
import { ActionTypes } from '../types';

export const openApiAppActions = createListAsyncActionCreators<
  AppState,
  OpenApiApp,
  OpenApiAppListFilter,
  number
>('openapi.apps', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) => state.openapi.apps.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) => state.openapi.apps.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.openapi.apps.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await openApiAppService.list(
      state.openapi.apps.filter,
      state.openapi.apps.sorts?.[0],
      state.openapi.apps.offset,
      state.openapi.apps.limit,
      { count: true, recursive: true },
    );
  },
  async create(app: Partial<OpenApiApp>) {
    return await openApiAppService.create(app);
  },
  async update(app: OpenApiApp) {
    return await openApiAppService.update(app);
  },
  async delete(app: OpenApiApp) {
    await openApiAppService.delete(app.id);
  },
  async deleteMulti(orgs: OpenApiApp[]) {
    if (orgs.length === 1) {
      await openApiAppService.delete(orgs[0].id);
      return;
    }
    throw new Error('delete multi openapi apps is not supported. ');
  },
});

export function manageOpenapiAppAuthorizedStores(app: OpenApiApp | null) {
  return createStandardAction(
    ActionTypes.ManageOpenapiAppAuthorizedStores,
    app,
  );
}
