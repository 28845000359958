/**
 * @file: MaintenanceServicePlanDetailStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum MaintenanceServicePlanDetailStatus {
  Pending = 1,
  Confirmed = 2,
  Dropped = 3,
  Done = 4,
}

export const MaintenanceServicePlanDetailStatusValueSet = new Set([
  MaintenanceServicePlanDetailStatus.Pending,
  MaintenanceServicePlanDetailStatus.Confirmed,
  MaintenanceServicePlanDetailStatus.Dropped,
  MaintenanceServicePlanDetailStatus.Done,
]);
