import classNames from 'classnames';
import React, { Component, CSSProperties, MouseEvent, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

export interface MenuItemProps {
  id?: string;
  text: string | ReactNode;
  href?: string;
  icon?: string;
  style?: CSSProperties;
  badge?: string;
  badgeColor?: string;
  badgeWide?: boolean;
  dropdown?: boolean;
  active?: boolean;
  className?: string;
  items?: MenuItemProps[];
  inline?: boolean;
  bullet?: 'line' | 'dot';
  subMenuType?: 'classic' | 'fixed';
  subMenuPlacement?: 'left' | 'right';
  subMenuOffset?: number;
  subMenuStyle?: CSSProperties;
  subMenuWrapper?: boolean;
  subMenuArrowOffset?: number;
  children?: any;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  onChildClick?: (e: MouseEvent<HTMLElement>) => void;
}

interface State {
  isOpen?: boolean;
}

export class MenuItem extends Component<MenuItemProps, State> {
  private readonly elemRef = React.createRef<HTMLLIElement>();

  constructor(props: MenuItemProps) {
    super(props);
    this.state = { isOpen: false };
  }

  render(): ReactNode {
    const { items, children } = this.props;

    if (items?.length || children) {
      return this.renderMenuItemWithSubMenu();
    }

    return this.renderSimpleMenuItem();
  }

  renderSimpleMenuItem() {
    return this.internalRenderMenuItem();
  }

  internalRenderMenuItem(renderContent?: () => any) {
    const {
      text,
      href,
      icon,
      bullet,
      badge,
      badgeColor,
      badgeWide,
      items,
      children,
      inline,
      className,
      style,
    } = this.props;
    const hasSubMenu = items?.length || children;
    if (inline) {
      return (
        <li
          className={classNames('m-menu__item', className)}
          ref={this.elemRef}
          style={style}
        >
          <h3 className="m-menu__heading m-menu__toggle">
            <span className="m-menu__link-text">{text}</span>
            <i className="m-menu__ver-arrow la la-angle-right" />
          </h3>
          {renderContent?.()}
        </li>
      );
    }
    return (
      <li
        ref={this.elemRef}
        className={classNames(
          'm-menu__item',
          {
            'm-menu__item--submenu': hasSubMenu,
            'm-menu__item--rel': this.props.dropdown,
            'm-menu__item--open-dropdown': this.props.dropdown,
            'm-menu__item--hover': this.state.isOpen,
            'm-menu__item--active': this.props.active,
          },
          className,
        )}
        style={style}
      >
        <NavLink
          to={href || '#'}
          className={classNames(
            'm-menu__link',
            hasSubMenu ? 'm-menu__toggle' : '',
          )}
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {icon && <i className={classNames('m-menu__link-icon', icon)} />}
          {bullet && (
            <i className={`m-menu__link-bullet m-menu__link-bullet--${bullet}`}>
              <span />
            </i>
          )}
          {badge !== null && badge !== void 0 ? (
            <span className="m-menu__link-title">
              <span className="m-menu__link-wrap">
                <span className="m-menu__link-text">{text}</span>
                <span className="m-menu__link-badge">
                  <span
                    className={classNames(
                      'm-badge',
                      badgeColor ? `m-badge--${badgeColor}` : '',
                      badgeWide ? 'm-badge--wide' : '',
                    )}
                  >
                    {badge}
                  </span>
                </span>
              </span>
            </span>
          ) : (
            <span className="m-menu__link-text">{text}</span>
          )}
          {hasSubMenu &&
            (this.props.dropdown ? (
              <React.Fragment>
                <i className="m-menu__hor-arrow la la-angle-down" />
                <i className="m-menu__ver-arrow la la-angle-right" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i className="m-menu__hor-arrow la la-angle-right" />
                <i className="m-menu__ver-arrow la la-angle-right" />
              </React.Fragment>
            ))}
        </NavLink>
        {renderContent?.()}
      </li>
    );
  }

  renderMenuItemWithSubMenu(): ReactNode {
    return this.internalRenderMenuItem(() => {
      if (this.props.inline) {
        return <ul className="m-menu__inner">{this.renderSubItems()}</ul>;
      }

      return this.renderSubMenu();
    });
  }

  renderSubItems(): ReactNode {
    const { children, items } = this.props;
    return (
      children || (
        <>
          {(items || []).map((item, i) => (
            <MenuItem
              {...item}
              key={item.id || i}
              onChildClick={this.onClick}
            />
          ))}
        </>
      )
    );
  }

  renderSubMenu() {
    const {
      subMenuPlacement,
      subMenuOffset,
      subMenuStyle,
      subMenuType,
      subMenuWrapper,
      subMenuArrowOffset,
    } = this.props;
    return (
      <div
        className={classNames(
          'm-menu__submenu',
          `m-menu__submenu--${subMenuType || 'classic'}`,
          `m-menu__submenu--${subMenuPlacement || 'left'}`,
        )}
        style={Object.assign({ left: subMenuOffset || void 0 }, subMenuStyle)}
      >
        <span
          className={classNames(
            'm-menu__arrow',
            subMenuArrowOffset ? 'm-menu__arrow--adjust' : '',
          )}
          style={{ left: subMenuArrowOffset || void 0 }}
        />

        {subMenuWrapper ? (
          <div className="m-menu__subnav">
            <ul className="m-menu__content">{this.renderSubItems()}</ul>
          </div>
        ) : (
          <ul className="m-menu__subnav">{this.renderSubItems()}</ul>
        )}
      </div>
    );
  }

  onClick = (e: any) => {
    const { items, children } = this.props;
    const hasSubMenu = items?.length || children;

    if (!this.props.dropdown || !hasSubMenu) {
      this.props.onClick && this.props.onClick(e);
      this.props.onChildClick && this.props.onChildClick(e);
      this.setState({ isOpen: false });
      return;
    }

    this.setState({ isOpen: !this.state.isOpen }, () => {
      if (this.state.isOpen) {
        document.addEventListener('click', this.onDocumentClick);
      } else {
        document.removeEventListener('click', this.onDocumentClick);
      }
    });
  };

  onMouseEnter = () => {
    if (this.props.dropdown) return;
    this.setState({ isOpen: true });
  };

  onMouseLeave = () => {
    if (this.props.dropdown) return;
    this.setState({ isOpen: false });
  };

  onDocumentClick = (e: Event) => {
    const target = e.target as HTMLElement;
    let elem: HTMLElement | null = target;
    while (elem && elem !== this.elemRef.current) {
      elem = elem.parentElement;
    }
    if (!elem) {
      this.setState({ isOpen: false });
      document.removeEventListener('click', this.onDocumentClick);
    }
  };
}
