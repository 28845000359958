import classNames from 'classnames';
import React, { Component, ChangeEvent } from 'react';
import { ColorType } from 'model';

interface Props {
  color?: ColorType;
  on?: boolean;
  disabled?: boolean;
  size?: 'large' | 'small';
  icon?: boolean;
  onChange?: (on: boolean) => void;
}

const sizeMap: any = { large: 'lg', small: 'sm' };

export class Switch extends Component<Props> {
  static defaultProps: Props = {
    color: 'success',
    on: false,
    disabled: false,
    size: undefined,
    icon: false
  };

  render() {
    const { on, color, disabled, size, icon } = this.props;
    return (
      <span className={classNames('m-switch', {
        [`m-switch--${color}`]: on,
        'm-switch-secondary': !on,
        [`m-switch--${sizeMap[size || '']}`]: size,
        ['m-switch--icon']: icon
      })}>
        <label style={{ marginBottom: 0 }}>
          <input
            type="checkbox"
            checked={on}
            disabled={disabled}
            onChange={this.onChange}
            />
          <span />
        </label>
      </span>
    );
  }

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(e.target.checked);
  }
}