/**
 * @file: UserWeixinBindingTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, UserWeixinBindingType } from 'model';

export const UserWeixinBindingTypeOptions: Array<
  Option<UserWeixinBindingType>
> = [
  { value: UserWeixinBindingType.Mp, label: 'user_weixin_binding_type.mp' },
  { value: UserWeixinBindingType.Open, label: 'user_weixin_binding_type.open' },
  { value: UserWeixinBindingType.App, label: 'user_weixin_binding_type.app' },
];

export const UserWeixinBindingTypeOptionsWithDefault: Array<
  Option<UserWeixinBindingType | null>
> = [
  { value: null, label: 'user_weixin_binding_type.__default__' },
  { value: UserWeixinBindingType.Mp, label: 'user_weixin_binding_type.mp' },
  { value: UserWeixinBindingType.Open, label: 'user_weixin_binding_type.open' },
  { value: UserWeixinBindingType.App, label: 'user_weixin_binding_type.app' },
];
