import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import { ItemMaterialEditor } from 'app/inspection/quotation-template-config/items/editor/MaterialEditor';
import {
  findQuotationItemByRef,
  keyOfMaterial,
} from 'app/inspection/quotation-template-config/util';
import { QuotationItemRef, QuotationTemplateMaterialStaged } from 'model';
import {
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { Button } from 'shared/metronic/components';
import invariant from 'tiny-invariant';
import { usePersistFn } from 'utils/usePersistFn';

const kDefaultMaterial: QuotationTemplateMaterialStaged = {
  id: '',
  name: '',
  itemRef: {} as any,
};

export const QuotationMaterialEditorModal = memo(() => {
  const { state } = useQuotationTemplateConfigContext();
  const dispatch = useDispatch();

  const ref = useRef<EditorRef>(null);

  const materialBeingEdited = useMemo<
    | {
        material: QuotationTemplateMaterialStaged;
        mode: 'add' | 'edit';
      }
    | undefined
  >(() => {
    if (state.materialRefBeingEdited == null) {
      return undefined;
    }

    const { categoryId, groupId, subjectId, itemId, materialId } =
      state.materialRefBeingEdited;

    const itemRef: QuotationItemRef = {
      categoryId,
      groupId,
      subjectId,
      itemId,
    };

    const item = findQuotationItemByRef(state.staged, itemRef);
    invariant(item != null);

    const material = item.materials?.find(x => x.id === materialId);

    return {
      material: material ?? {
        id: materialId,
        name: '',
        itemRef,
      },
      mode: material == null ? 'add' : 'edit',
    };
  }, [state.materialRefBeingEdited, state.staged]);

  const onConfirm = useCallback(() => {
    invariant(state.materialRefBeingEdited != null);
    invariant(materialBeingEdited != null);

    if (!ref.current?.validate()) {
      return;
    }

    const item = findQuotationItemByRef(
      state.staged,
      state.materialRefBeingEdited,
    );
    invariant(item != null);

    const staged = ref.current.getStaged();
    const key = keyOfMaterial(staged);

    if (
      (materialBeingEdited.mode === 'add' &&
        item.materials?.some(x => keyOfMaterial(x) === key)) ||
      (materialBeingEdited.mode === 'edit' &&
        item.materials?.some(
          x => x.id !== staged.id && keyOfMaterial(x) === key,
        ))
    ) {
      ref.current.setError(
        'name',
        <Translate
          id="quotation_tpl.material.error.duplicate_material"
          data={{ name: staged.name }}
        />,
      );
      return;
    }

    dispatch(quotationTplActions.commitMaterialBeingEdited(staged));
  }, [
    dispatch,
    materialBeingEdited,
    state.materialRefBeingEdited,
    state.staged,
  ]);

  const onCancel = useCallback(() => {
    if (!ref.current?.isDirty) {
      dispatch(quotationTplActions.cancelMaterialBeingEdited());
      return;
    }
  }, [dispatch]);

  return (
    <>
      <Modal isOpen={materialBeingEdited != null}>
        <ModalHeader>
          {materialBeingEdited ? (
            <Translate
              id={`quotation_tpl.material.modal.editor.title.${materialBeingEdited.mode}`}
            />
          ) : (
            ''
          )}
        </ModalHeader>
        <ModalBody>
          <Editor
            material={materialBeingEdited?.material ?? kDefaultMaterial}
            key={materialBeingEdited?.material.id ?? ''}
            ref={ref}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={onConfirm}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

type EditorRef = {
  getStaged: () => QuotationTemplateMaterialStaged;
  validate: () => boolean;
  setError: (name: string, error: any) => void;
  get isDirty(): boolean;
};

type EditorProps = {
  material: QuotationTemplateMaterialStaged;
};

const Editor = memo(
  forwardRef<EditorRef, EditorProps>((props, ref) => {
    const isDirty = useRef(false);
    const [showErrorOnChange, setShowErrorOnChange] = useState(false);
    const [staged, setStaged] = useState(props.material);
    const [errors, setErrors] = useState<Record<string, ReactNode>>({});
    const [expanded, setExpanded] = useState(false);

    const validate = usePersistFn(() => {
      const material = staged;

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const errors: Record<string, ReactNode> = {};

      if (!material.name?.trim()) {
        errors.name = (
          <Translate id="quotation_tpl.material.error.name_required" />
        );
      }

      if (material.price != null && material.price < 0) {
        errors.price = (
          <Translate id="quotation_tpl.material.error.invalid_price" />
        );
      }

      if (material.discountPrice != null) {
        if (material.discountPrice < 0) {
          errors.discountPrice = (
            <Translate id="quotation_tpl.material.error.invalid_price" />
          );
        } else if (material.price == null) {
          errors.price = (
            <Translate id="quotation_tpl.material.error.price_required" />
          );
        } else if (material.discountPrice >= material.price) {
          errors.discountPrice = (
            <Translate id="quotation_tpl.material.error.invalid_discount_price" />
          );
        }
      }

      const hasErrors = Boolean(Object.keys(errors).length);

      if (!showErrorOnChange && hasErrors) {
        setShowErrorOnChange(true);
      }

      setErrors(errors);

      if (hasErrors) {
        return false;
      }

      return true;
    });

    useImperativeHandle(ref, () => ({
      getStaged: () => ({ ...staged, name: staged.name }),
      validate,
      setError: (name, error) => {
        setErrors(x => ({ ...x, [name]: error }));
      },
      get isDirty() {
        return isDirty.current;
      },
    }));

    const onErrorStateChange = usePersistFn(
      (_materialId: string, field: string, error: ReactNode | undefined) => {
        setErrors(prev => ({
          ...prev,
          [field]: error,
        }));
      },
    );

    const onToggleExpand = usePersistFn(() => {
      setExpanded(x => !x);
    });

    return (
      <ItemMaterialEditor
        material={staged}
        onChange={setStaged}
        showErrorOnChange={showErrorOnChange}
        errors={errors}
        expanded={expanded}
        standalone={true}
        onToggleExpand={onToggleExpand}
        onErrorStateChange={onErrorStateChange}
      />
    );
  }),
);
