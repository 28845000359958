import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleSystemModule } from 'model';
import { VehicleSystemModules } from '../states';

const initialState: VehicleSystemModules = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {},
};

export default createAsyncListActionReducers<
  VehicleSystemModule,
  VehicleSystemModules
>('inspection.vehicle_system_modules', initialState, {
  mapItemKey: x => x.id,
  expandAllTreeListNodeByDefault: true,
});
