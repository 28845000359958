import { AppState, TransFunction } from 'app';
import { History } from 'history';
import { RouteViewProps, tokenService } from 'lib';
import { LoginResult } from 'model';
import { ChangeEvent, Component, FormEvent, MouseEvent } from 'react';
import { TranslateFunction, getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { authService } from 'services';
import { Alert, Button } from 'shared/metronic/components';
import { sha256 } from 'utils';
import { isEmail } from 'utils/validators';
import { Input } from './Input';
import { Layout } from './Layout';

export interface Props {
  trans: TransFunction;
  translate: TranslateFunction;
  history: History;
  onLoad?: () => any;
}

export interface State {
  userName: string;
  password: string;
  errorMsg: string;
}

const mapStateToProps = (state: AppState, ownProps: RouteViewProps) => {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    history: ownProps.history,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    onLoad: () => {
      // todo
    },
  };
};

export class LoginComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      errorMsg: '',
    };
  }

  componentDidMount() {
    this.props.onLoad?.();
  }

  render() {
    const { trans } = this.props;
    return (
      <Layout
        className="m-login__signin"
        title={trans('login.title')}
        desc={trans('login.sub_title')}
      >
        {this.renderLoginForm()}
      </Layout>
    );
  }

  renderLoginForm() {
    const { trans } = this.props;
    return (
      <form className="m-login__form m-form" action="" onSubmit={this.onSubmit}>
        {this.state.errorMsg && (
          <Alert
            className="m--font-danger text-center"
            style={{ backgroundColor: 'transparent' }}
          >
            {this.state.errorMsg}
          </Alert>
        )}
        <div className="form-group m-form__group" style={{ padding: '0 65px' }}>
          <Input
            className="form-control m-input"
            type="text"
            placeholder={trans('login.placeholder.username')}
            autoComplete="nope"
            name="userName"
            value={this.state.userName}
            onChange={this.onUserNameChange}
          />
        </div>
        <div className="form-group m-form__group" style={{ padding: '0 65px' }}>
          <Input
            className="form-control m-input"
            type="password"
            placeholder={trans('login.placeholder.password')}
            name="password"
            autoComplete="nope"
            value={this.state.password}
            onChange={this.onPasswordChange}
          />
        </div>
        <div className="m-login__form-action">
          <Button
            color="focus"
            pill
            custom
            air
            className="m-login__btn"
            onClick={this.onLogin}
          >
            {trans('login.login_btn')}
          </Button>
        </div>
      </form>
    );
  }

  onUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ userName: e.target.value });
  };

  onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: e.target.value });
  };

  onSignup = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.history.push('/signup');
  };

  onLogin = () => {
    const { trans } = this.props;

    const userName = this.state.userName.trim();
    const password = this.state.password.trim();

    if (!userName) {
      this.setState({
        errorMsg: trans('login.error.username_required'),
      });
      return;
    }

    if (!/^[a-z][a-z0-9_.]+$/.test(userName) && !isEmail(userName)) {
      this.setState({
        errorMsg: trans('login.error.invalid_username'),
      });
      return;
    }

    if (!password) {
      this.setState({
        errorMsg: trans('login.error.password_required'),
      });
      return;
    }

    void this.login();
  };

  async login() {
    const { trans } = this.props;

    try {
      const loginResult: LoginResult = await authService.login(
        this.state.userName.trim(),
        sha256(this.state.password.trim())!,
      );

      tokenService.setToken(loginResult.token);

      location.href = '/';
    } catch (e) {
      let msg = trans('general_error');
      if (e.status === 500) {
        msg = trans('internal_server_error');
      } else if (e.status === 404 || e.status === 403) {
        msg = trans('login.error.user_or_password_error');
      }
      console.log('error login: ', JSON.stringify(e));
      this.setState({ errorMsg: msg });
    }
  }

  onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.onLogin();
  };
}

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginComponent);
