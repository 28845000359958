/**
 * @file: OfficialAccountGrantedPermissionTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OfficialAccountGrantedPermissionType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: OfficialAccountGrantedPermissionType | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

const Values = {
  [OfficialAccountGrantedPermissionType.Message]: 'message',
  [OfficialAccountGrantedPermissionType.User]: 'user',
  [OfficialAccountGrantedPermissionType.Account]: 'account',
  [OfficialAccountGrantedPermissionType.WebPage]: 'web_page',
  [OfficialAccountGrantedPermissionType.Store]: 'store',
  [OfficialAccountGrantedPermissionType.MultiServiceSupport]:
    'multi_service_support',
  [OfficialAccountGrantedPermissionType.MassMessageAndNotification]:
    'mass_message_and_notification',
  [OfficialAccountGrantedPermissionType.Card]: 'card',
  [OfficialAccountGrantedPermissionType.Scan]: 'scan',
  [OfficialAccountGrantedPermissionType.WifiConnect]: 'wifi_connect',
  [OfficialAccountGrantedPermissionType.Media]: 'media',
  [OfficialAccountGrantedPermissionType.Shake]: 'shake',
  [OfficialAccountGrantedPermissionType.StoreMiniprogram]: 'store_miniprogram',
  [OfficialAccountGrantedPermissionType.Menu]: 'menu',
  [OfficialAccountGrantedPermissionType.CertificationStatus]:
    'certification_status',
  [OfficialAccountGrantedPermissionType.MiniprogramAccount]:
    'miniprogram_account',
  [OfficialAccountGrantedPermissionType.MiniprogramDevAnalysis]:
    'miniprogram_dev_analysis',
  [OfficialAccountGrantedPermissionType.MiniprogramServiceSupportMessage]:
    'miniprogram_service_support_message',
  [OfficialAccountGrantedPermissionType.MiniprogramWeixinLogin]:
    'miniprogram_weixin_login',
  [OfficialAccountGrantedPermissionType.MiniprogramAnalysis]:
    'miniprogram_analysis',
  [OfficialAccountGrantedPermissionType.CityServiceApi]: 'city_service_api',
  [OfficialAccountGrantedPermissionType.Ads]: 'ads',
  [OfficialAccountGrantedPermissionType.OpenPlatformAccount]:
    'open_platform_account',
  [OfficialAccountGrantedPermissionType.MiniprogramOpenPlatformAccount]:
    'miniprogram_open_platform_account',
  [OfficialAccountGrantedPermissionType.Invoice]: 'invoice',
  [OfficialAccountGrantedPermissionType.MiniprogramBasicInfo]:
    'miniprogram_basic_info',
  [OfficialAccountGrantedPermissionType.MiniprogramNearlyPlaces]:
    'miniprogram_nearly_places',
  [OfficialAccountGrantedPermissionType.MiniprogramPlugin]:
    'miniprogram_plugin',
  [OfficialAccountGrantedPermissionType.WidgetSearch]: 'widget_search',
  [OfficialAccountGrantedPermissionType.MiniprogramCloudDev]:
    'miniprogram_cloud_dev',
  [OfficialAccountGrantedPermissionType.ServiceAccountConversation]:
    'service_account_conversation',
};

export const OfficialAccountGrantedPermissionTypeLabel = React.memo<Props>(
  props => {
    const { value, color, ...otherProps } = props;

    let enumValue = '';
    if (value !== null && value !== undefined) {
      enumValue = Values[value];
    }

    const labelColor =
      value && LabelColors.OfficialAccountGrantedPermissionType?.[enumValue]
        ? LabelColors.OfficialAccountGrantedPermissionType[enumValue]
        : color;

    return (
      <Badge color={labelColor} {...otherProps}>
        <Translate
          id={`official_account_granted_permission_type.${enumValue}`}
        />
      </Badge>
    );
  },
);
