/**
 * @file: VehicleInspectionSiteVersionService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionSiteVersion,
  VehicleInspectionSiteVersionListFilter,
} from 'model';

export class VehicleInspectionSiteVersionServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionSiteVersionListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionSiteVersion>
      : VehicleInspectionSiteVersion[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-site-versions',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionSiteVersion | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-site-versions/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionSiteVersion: Partial<VehicleInspectionSiteVersion>,
  ): Promise<VehicleInspectionSiteVersion> {
    return await this.api
      .post()
      .url('/vehicle-inspection-site-versions')
      .data(vehicleInspectionSiteVersion)
      .future();
  }

  async update(
    vehicleInspectionSiteVersion: Partial<VehicleInspectionSiteVersion>,
  ): Promise<VehicleInspectionSiteVersion> {
    return await this.api
      .put()
      .url('/vehicle-inspection-site-versions/:id', {
        id: vehicleInspectionSiteVersion.id,
      })
      .data(vehicleInspectionSiteVersion)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-site-versions/:id', { id })
      .future();
  }
}
