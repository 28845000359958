/**
 * @file: ServiceEditionService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ServiceEditionServiceBase } from './base/ServiceEditionService';

export class ServiceEditionService extends ServiceEditionServiceBase {
  // add custom service implementation here
}
