/**
 * @file: InspectionTemplatePredefinedTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionTemplatePredefinedType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: InspectionTemplatePredefinedType | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

export const InspectionTemplatePredefinedTypeLabel = React.memo<Props>(
  props => {
    const { value, color, ...otherProps } = props;

    const labelColor =
      value && LabelColors.InspectionTemplatePredefinedType?.[value]
        ? LabelColors.InspectionTemplatePredefinedType[value]
        : color;

    return (
      <Badge color={labelColor} {...otherProps}>
        <Translate id={`inspection_template_predefined_type.${value}`} />
      </Badge>
    );
  },
);
