import { APIService } from 'lib/restful-client/api.service';
import { QuotationTemplateConfig } from 'model';

export class QuotationTemplateConfigService {
  constructor(private readonly api: APIService) {}

  async getQuotationTemplateConfig(): Promise<QuotationTemplateConfig> {
    return await this.api.get().url('/quotation-template-config').future();
  }

  async saveQuotationTemplateConfig(config: QuotationTemplateConfig) {
    return await this.api
      .post()
      .url('/quotation-template-config')
      .data({ config })
      .future();
  }
}
