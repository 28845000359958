import React, { Component } from 'react';
import json2mq from 'json2mq';

export interface MediaQueryObject {
  [id: string]: boolean | number | string;
}

export interface MediaProps {
  query: string | MediaQueryObject | MediaQueryObject[];
  defaultMatches?: boolean;
  children?: ((matches: boolean) => React.ReactNode) | React.ReactNode;
  render?: () => React.ReactNode;
  targetWindow?: Window;
  onChange?: (matches: boolean) => void;
}

interface MediaState {
  matches: boolean;
}

export class Media extends Component<MediaProps, MediaState> {
  static defaultProps: Partial<MediaProps> = {
    defaultMatches: true
  };

  private mediaQueryList: MediaQueryList;

  constructor(props: MediaProps) {
    super(props);
    this.state = {
      matches: this.props.defaultMatches!
    };
  }

  updateMatches = () => {
    const { matches } = this.mediaQueryList;

    this.setState({ matches });

    const { onChange } = this.props;

    if (onChange) {
      onChange(matches);
    }
  }

  componentDidMount() {
    if (typeof window !== 'object') return;

    const targetWindow = this.props.targetWindow || window;

    let { query } = this.props;
    if (typeof query !== 'string') query = json2mq(query as any);

    this.mediaQueryList = targetWindow.matchMedia(query as string);
    this.mediaQueryList.addListener(this.updateMatches);
    this.updateMatches();
  }

  componentWillUnmount() {
    this.mediaQueryList.removeListener(this.updateMatches);
  }

  render() {
    const { children, render } = this.props;
    const { matches } = this.state;

    return render
      ? matches
        ? render()
        : null
      : children
        ? typeof children === 'function'
          ? (children as any)(matches)
          : !Array.isArray(children) || children.length // Preact defaults to empty children array
            ? matches
              ? React.Children.only(children)
              : null
            : null
        : null;
  }
}