/**
 * @file: AdminUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AdminUser } from 'model';
import { AdminUserServiceBase } from './base/AdminUserService';

export class AdminUserService extends AdminUserServiceBase {
  async updateProfile(profile: Partial<AdminUser>): Promise<AdminUser> {
    return await this.api.put().url('/me/profile').data(profile).future();
  }

  async getAdminUserAcl(userId: number): Promise<string[]> {
    return await this.api
      .get()
      .url('/admin-users/:userId/acl', { userId })
      .future();
  }

  async setAdminUserAcl(
    userId: number,
    grantedAccesses: string[],
  ): Promise<string[]> {
    return await this.api
      .put()
      .url('/admin-users/:id/acl', { id: userId })
      .data({ grantedAccesses })
      .future();
  }
}
