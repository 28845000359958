import classNames from 'classnames';
import { Organization, Store } from 'model';
import { Component, CSSProperties } from 'react';
import { Options } from 'react-select';
import { organizationService, storeService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  orgId?: number | null;
  storeId?: number | null;
  className?: string;
  orgOnly?: boolean;
  orgPlaceholder?: string;
  storePlaceholder?: string;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  valueContainerStyle?: CSSProperties;
  onChange: (
    orgId: number | undefined,
    storeId: number | undefined,
    org: Organization | null,
    store: Store | null,
  ) => void;
}

interface State {
  organizations: Organization[] | null;
  isLoadingOrganizations: boolean;
  stores: Store[] | null;
  isLoadingStores: boolean;
}

export class StorePicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      organizations: null,
      isLoadingOrganizations: false,
      stores: null,
      isLoadingStores: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.orgId !== prevProps.orgId) {
      void this.onLoadOrganizations('');
    }
  }

  render() {
    const { organizations, isLoadingOrganizations, stores, isLoadingStores } =
      this.state;
    const {
      style,
      containerStyle,
      menuStyle,
      valueContainerStyle,
      orgId,
      storeId,
      className,
      orgOnly,
      orgPlaceholder,
      storePlaceholder,
    } = this.props;

    const selectedOrg =
      organizations && orgId ? organizations.find(x => x.id === orgId) : null;

    const selectedStore =
      stores && storeId ? stores.find(x => x.id === storeId) : null;

    return (
      <div className={classNames('store-picker', className)} style={style}>
        <Select<Organization>
          values={[]}
          valueProp="id"
          labelProp="name"
          className="store-picker__component store-picker__org"
          selectedValue={selectedOrg}
          placeholder={getString(
            orgPlaceholder ?? 'store_picker.placeholder.select_org',
          )}
          isLoading={isLoadingOrganizations}
          isClearable
          noOptionsMessage={this.onNoOrganizationsMessage}
          async
          defaultValues
          onLoadValues={this.onLoadOrganizations}
          onChange={this.onOrganizationChange}
          containerStyle={containerStyle}
          menuStyle={menuStyle}
          valueContainerStyle={valueContainerStyle}
        />
        {orgOnly ? null : (
          <Select<Store>
            values={stores || []}
            valueProp="id"
            labelProp="name"
            className="store-picker__component store-picker__store"
            selectedValue={selectedStore}
            placeholder={getString(
              storePlaceholder ?? 'store_picker.placeholder.select_store',
            )}
            isLoading={isLoadingStores}
            isClearable
            noOptionsMessage={getString('store_picker.no_values_msg.store')}
            onChange={this.onStoreChange}
            containerStyle={containerStyle}
            menuStyle={menuStyle}
            valueContainerStyle={valueContainerStyle}
          />
        )}
      </div>
    );
  }

  onLoadOrganizations = async (inputValue: string): Promise<Organization[]> => {
    // clear stores.
    if (this.state.stores !== null) {
      this.setState({ stores: null, isLoadingStores: false });
    }

    if (!inputValue) {
      const { orgId } = this.props;
      if (orgId) {
        let org: Organization | null | undefined =
          this.state.organizations?.find(x => x.id === orgId);
        if (!org) {
          this.setState({ isLoadingOrganizations: true });
          org = await organizationService.get(orgId);
          this.setState({ isLoadingOrganizations: false });
        }

        if (!org) {
          this.setState({ organizations: [] });
          return [];
        }

        this.setState({ organizations: [org] });

        if (
          this.state.stores == null ||
          this.state.stores.length === 0 ||
          this.state.stores.some(x => x.orgId !== orgId)
        ) {
          this.setState({ isLoadingStores: true });
          // load associated stores
          const stores = (await storeService.list(
            { orgId: org.id },
            null,
            0,
            0,
          )) as any;
          this.setState({
            stores,
            isLoadingStores: false,
          });
        }

        return [org];
      }

      return [];
    }

    if (inputValue.length === 1) return [];

    this.setState({ isLoadingOrganizations: true });
    try {
      const orgs = (await organizationService.list(
        { keyword: inputValue },
        null,
        0,
        0,
      )) as any;
      this.setState({
        isLoadingOrganizations: false,
        organizations: orgs,
        stores: null,
      });
      return orgs;
    } catch (e) {
      console.error(e);
      this.setState({
        isLoadingOrganizations: false,
        organizations: null,
        stores: null,
      });
    }

    return [];
  };

  onNoOrganizationsMessage = (obj: { inputValue: string }): string | null => {
    if (!obj.inputValue || obj.inputValue.length === 1) {
      return getString('store_picker.no_values_msg.org_hint');
    }
    return getString('store_picker.no_values_msg.org');
  };

  onOrganizationChange = async (value: Options<Organization>) => {
    if (Array.isArray(value)) {
      return;
    }

    if (!value) {
      this.props.onChange(undefined, undefined, null, null);
      this.setState({ stores: null });
      return;
    }

    const organization = value as unknown as Organization;

    this.props.onChange(organization.id, undefined, organization, null);

    if (this.props.orgOnly) {
      return;
    }

    // load stores
    this.setState({ stores: null, isLoadingStores: true });

    try {
      const stores = (await storeService.list(
        { orgId: organization.id },
        null,
        0,
        0,
      )) as any;
      this.setState({ stores, isLoadingStores: false });
    } catch (e) {
      console.error(e);
      this.setState({ stores: null, isLoadingStores: false });
    }
  };

  onStoreChange = (value: Options<Store>) => {
    if (Array.isArray(value)) {
      return;
    }

    const organization = this.state.organizations!.find(
      x => x.id === this.props.orgId,
    )!;

    if (!value) {
      this.props.onChange(
        this.props.orgId || undefined,
        undefined,
        organization,
        null,
      );
      return;
    }

    const store = value as unknown as Store;

    this.props.onChange(this.props.orgId!, store.id, organization, store);
  };
}
