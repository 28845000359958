import { InspectionSiteType, InspectionSiteTypeOptions } from 'model';
import { ChangeEvent, memo, useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { getString } from 'shared/components';
import { Alert, Button } from 'shared/metronic/components';

export const SetSiteTypeModal = memo(
  ({
    visible,
    loading,
    error,
    onClose,
    onConfirm,
  }: {
    visible?: boolean;
    loading?: boolean;
    error?: Error | null;
    onClose?: () => void;
    onConfirm?: (type: InspectionSiteType) => void;
  }) => {
    const [type, setType] = useState<InspectionSiteType>(
      InspectionSiteType.Default,
    );

    const onConfirmClick = useCallback(async () => {
      onConfirm?.(type);
    }, [type, onConfirm]);

    const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
      const value = Number(e.target.value);
      setType(value);
    }, []);

    return (
      <Modal isOpen={visible}>
        <ModalHeader>
          <Translate id={`inspection_site.modal.set_type.title`} />
        </ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error.message}</Alert>}
          <ReactMarkdown>
            {getString(`inspection_site.modal.set_type.msg`)}
          </ReactMarkdown>
          <select value={type} className="form-control" onChange={onChange}>
            {InspectionSiteTypeOptions.map(item => (
              <option value={item.value} key={item.value}>
                {getString(item.label)}
              </option>
            ))}
          </select>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose} disabled={loading}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button
            color="primary"
            onClick={onConfirmClick}
            disabled={loading}
            loader={loading}
          >
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
