/**
 * @file: CommonTaskStatusLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { CommonTaskStatus } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: CommonTaskStatus | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

const Values = {
  [CommonTaskStatus.Pending]: 'pending',
  [CommonTaskStatus.InProgress]: 'in_progress',
  [CommonTaskStatus.Finished]: 'finished',
};

export const CommonTaskStatusLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.CommonTaskStatus?.[enumValue]
      ? LabelColors.CommonTaskStatus[enumValue]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`common_task_status.${enumValue}`} />
    </Badge>
  );
});
