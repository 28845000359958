import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleInspectionComment } from 'model';
import { vehicleInspectionCommentService } from 'services';

const actionCreators = createListAsyncActionCreators<
  AppState,
  VehicleInspectionComment
>('dashboard.recent_inspection_comments', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  async fetchHandler(state: AppState) {
    return await vehicleInspectionCommentService.list(
      state.dashboard.recentInspectionComments.filter || {},
      state.dashboard.recentInspectionComments.sorts?.[0],
      state.dashboard.recentInspectionComments.offset,
      state.dashboard.recentInspectionComments.limit,
      { count: true, recursive: true },
    );
  },
});

export const {
  fetch: fetchDashboardRecentInspectionComments,
  invalidate: invalidateDashboardRecentInspectionComments,
} = actionCreators;
