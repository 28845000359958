import { ServiceDurationUnit } from 'model';

export function durationFromString(
  s: string | null | undefined,
): [number | null | undefined, ServiceDurationUnit] {
  if (!s) {
    return [0, ServiceDurationUnit.None];
  }
  const match = /^\s*(\d+)?([ymd])\s*$/.exec(s);
  if (match == null) {
    throw new Error('Invalid cycle string: ' + s);
  }
  return [
    match[1] ? Number(match[1]) : null,
    match[2] === 'y'
      ? ServiceDurationUnit.Year
      : match[2] === 'm'
      ? ServiceDurationUnit.Month
      : ServiceDurationUnit.Day,
  ];
}

export function durationToString(
  value: number | null | undefined,
  unit: ServiceDurationUnit | null | undefined,
): string | null | undefined {
  if ((value == null && unit == null) || unit === ServiceDurationUnit.None) {
    return null;
  }
  if (unit == null) {
    return null;
  }
  const suffix =
    unit === ServiceDurationUnit.Year
      ? 'y'
      : unit === ServiceDurationUnit.Month
      ? 'm'
      : 'd';
  return `${value == null ? '' : value}${suffix}`;
}
