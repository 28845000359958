import { InspectionTemplatePredefinedType } from 'model';

export const InspectionTemplatePredefinedTypeList = [
  InspectionTemplatePredefinedType.FullInspection,
  InspectionTemplatePredefinedType.AirConditionerInspection,
  InspectionTemplatePredefinedType.CarWashBeauty,
  InspectionTemplatePredefinedType.Other
];

export const PredefinedTemplateIcons = {
  [InspectionTemplatePredefinedType.FullInspection]: 'img/icon-full-inspection3.svg',
  [InspectionTemplatePredefinedType.AirConditionerInspection]: 'img/icon-airconditioner-inspection.svg',
  [InspectionTemplatePredefinedType.CarWashBeauty]: 'img/icon-car-wash-beauty.svg',
  [InspectionTemplatePredefinedType.Other]: 'img/icon-custom-template4.svg'
};