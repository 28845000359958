import { useSelectedGroup } from 'app/inspection/quotation-template-config/hooks/useSelectedGroup';
import { ConfirmRemoveItemModal } from 'app/inspection/quotation-template-config/items/ConfirmRemoveModal';
import { QuotationItemEditorModal } from 'app/inspection/quotation-template-config/items/editor';
import { ItemPanel } from 'app/inspection/quotation-template-config/items/ItemPanel';
import { ConfirmRemoveMaterialModal } from 'app/inspection/quotation-template-config/items/material/ConfirmRemoveModal';
import { QuotationMaterialEditorModal } from 'app/inspection/quotation-template-config/items/material/Editor';
import { SubjectList } from 'app/inspection/quotation-template-config/subject/SubjectList';
import { SubjectListWithItems } from 'app/inspection/quotation-template-config/subject/SubjectListWithItems';
import { memo, useMemo } from 'react';
import { useQuotationTemplateConfigContext } from './Context';
import { EmptyDetail } from './EmptyDetail';

export const Detail = memo(() => {
  const {
    editable,
    state: { selectedGroupRef, staged },
  } = useQuotationTemplateConfigContext();

  const selectedGroup = useSelectedGroup();
  const category = useMemo(() => {
    if (selectedGroupRef?.type === 'all-groups') {
      const categoryId = selectedGroupRef.categoryId;
      return staged.categories.find(x => x.id === categoryId);
    }
    return undefined;
  }, [selectedGroupRef, staged.categories]);

  return (
    <>
      <div className="quotation-tpl__detail-panel">
        {selectedGroupRef?.type === 'group' ? (
          <div className="quotation-tpl__group-detail">
            <SubjectList group={selectedGroup!} editable={editable} />
            <ItemPanel editable={editable} />
          </div>
        ) : selectedGroupRef?.type === 'all-groups' ? (
          <div className="quotation-tpl__groups-with-subjects">
            {category?.groups
              .filter(x => x.subjects.length > 0)
              .map(group => (
                <div key={group.id}>
                  <SubjectListWithItems
                    subjects={group.subjects}
                    editable={editable}
                  />
                </div>
              ))}
          </div>
        ) : (
          <EmptyDetail />
        )}
      </div>
      <QuotationItemEditorModal />
      <ConfirmRemoveItemModal />
      <QuotationMaterialEditorModal />
      <ConfirmRemoveMaterialModal />
    </>
  );
});
