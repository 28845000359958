import { Agent, InspectionTemplateBarrierLevel } from 'model';
import { memo, MouseEvent, useCallback, useEffect, useState } from 'react';
import {
  agentService,
  vehicleInspectionTemplateBarrierService,
  vehicleInspectionTemplateService,
} from 'services';
import { ScopedTranslate } from 'shared/components';

const S = memo(({ id }: { id: string }) => {
  return <ScopedTranslate scope="inspection_template.barriers" id={id} />;
});

const Item = memo(
  ({
    id,
    name,
    checked,
    onClick,
  }: {
    id: number;
    checked?: boolean;
    name: string;
    onClick?: (e: MouseEvent<HTMLInputElement>) => void;
  }) => {
    return (
      <li
        key={id}
        style={{
          listStyleType: 'none',
          marginLeft: 0,
          paddingLeft: 0,
          marginBottom: '0.3rem',
        }}
      >
        <input
          id={`barrier-agent-${id}`}
          type="checkbox"
          value={id}
          onClick={onClick}
          onChange={() => null}
          checked={checked}
          style={{
            verticalAlign: 'middle',
          }}
        />
        <label
          htmlFor={`barrier-agent-${id}`}
          style={{
            marginLeft: '0.35rem',
            verticalAlign: 'middle',
            marginBottom: 0,
          }}
        >
          {name}
        </label>
      </li>
    );
  },
);

export const BarriersSettings = memo(
  ({ templateId }: { templateId: number }) => {
    const [agents, setAgents] = useState<Agent[]>();
    const [targetIdSet, setTargetIdSet] = useState<Set<number>>();

    useEffect(() => {
      void (async () => {
        const [results, barriers] = await Promise.all([
          agentService.list(null, null, undefined, undefined, {
            recursive: false,
          }),
          vehicleInspectionTemplateBarrierService.list(
            { targetLevel: [InspectionTemplateBarrierLevel.Agent], templateId },
            null,
            undefined,
            undefined,
            { recursive: false },
          ),
        ]);
        setAgents(results);
        setTargetIdSet(new Set(barriers.map(x => x.targetId)));
      })();
    }, [templateId]);

    const onClick = useCallback(
      async (e: MouseEvent<HTMLInputElement>) => {
        if (targetIdSet == null) return;
        const agentId = Number(e.currentTarget.value);
        try {
          const newSet = new Set(targetIdSet);
          if (targetIdSet.has(agentId)) {
            await vehicleInspectionTemplateService.removeTargetInspectionTemplateBarrier(
              InspectionTemplateBarrierLevel.Agent,
              agentId,
              templateId,
            );
            newSet.delete(agentId);
          } else {
            await vehicleInspectionTemplateService.addTargetInspectionTemplateBarrier(
              InspectionTemplateBarrierLevel.Agent,
              agentId,
              templateId,
            );
            newSet.add(agentId);
          }
          setTargetIdSet(newSet);
          // eslint-disable-next-line @typescript-eslint/no-shadow
        } catch (e) {
          alert(e.message);
        }
      },
      [targetIdSet, templateId],
    );

    if (!agents?.length) {
      return (
        <div>
          <S id="no_agents" />
        </div>
      );
    }

    return (
      <div>
        <div
          style={{
            fontWeight: 'bold',
            marginBottom: '0.5rem',
          }}
        >
          <S id="description" />
        </div>
        <ul
          style={{
            marginLeft: 0,
            paddingLeft: 0,
          }}
        >
          {agents.map(agent => (
            <Item
              key={agent.id}
              id={agent.id}
              name={agent.name}
              checked={!targetIdSet?.has(agent.id)}
              onClick={onClick}
            />
          ))}
        </ul>
      </div>
    );
  },
);
