import classNames from 'classnames';
import { FileUploadResult } from 'model';
import numeral from 'numeral';
import {
  CSSProperties,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Translate } from 'react-localize-redux';
import { commonService } from 'services';
import { Block } from 'shared/metronic/components';

import './FilePicker.scss';

export type ValueType =
  | string
  | string[]
  | FileUploadResult
  | FileUploadResult[];

export type ImageFitType = 'contain' | 'cover' | 'fill' | 'inside' | 'outside';

interface Props {
  accept?: string | string[];
  realm?: string;
  maxSize?: number;
  disabled?: boolean;
  multiple?: boolean;
  image?: boolean;
  value?: string | string[] | FileUploadResult | FileUploadResult[];
  imageSize?: number;
  imageWidth?: number;
  imageHeight?: number;
  imageFit?: ImageFitType;
  cover?: boolean;
  coverSize?: number;
  coverWidth?: number;
  coverFit?: ImageFitType;
  placeholder?: boolean;
  coverHeight?: number;
  style?: CSSProperties;
  noUploadingMsg?: boolean;
  uploadingMsgStyle?: CSSProperties;
  onChange?: (value: ValueType) => void;
  children?: (
    value: ValueType | null | undefined,
  ) => ReactNode | null | undefined;
}

export function FilePicker(props: Props) {
  const {
    realm,
    image,
    multiple,
    accept,
    disabled,
    maxSize,
    value,
    children,
    imageSize,
    imageWidth,
    imageHeight,
    imageFit,
    cover,
    coverSize,
    coverWidth,
    coverHeight,
    coverFit,
    placeholder,
    style,
    noUploadingMsg,
    uploadingMsgStyle,
    onChange,
  } = props;

  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState<number | null>(null);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const uploadedFiles: FileUploadResult[] = [];

      for (const file of acceptedFiles) {
        // upload the file.
        const form = new FormData();
        form.append('realm', realm || '');
        form.append('filename', file.name);
        form.append('size', String(file.size));
        form.append('type', file.type);
        form.append('blob', file);
        form.append('cover', cover ? '1' : '');
        form.append('blurhash', '1');

        imageSize && form.append('imageSize', String(imageSize));
        imageWidth && form.append('imageWidth', String(imageWidth));
        imageHeight && form.append('imageHeight', String(imageHeight));
        imageFit && form.append('imageFit', String(imageFit));

        coverSize && form.append('coverSize', String(coverSize));
        coverWidth && form.append('coverWidth', String(coverWidth));
        coverHeight && form.append('coverHeight', String(coverHeight));
        coverFit && form.append('coverFit', coverFit);

        setIsUploading(true);
        try {
          setProgress(0);
          const uploadResult = await commonService.uploadFile(
            form,
            (e: ProgressEvent) => {
              console.log(JSON.stringify(e));
              if (e.total) {
                setProgress(e.loaded / e.total);
              }
            },
          );
          uploadedFiles.push(uploadResult);
          setProgress(null);
        } finally {
          setIsUploading(false);
        }
      }

      onChange && onChange(multiple ? uploadedFiles : uploadedFiles[0]);
    },
    [
      cover,
      coverFit,
      coverHeight,
      coverSize,
      coverWidth,
      imageFit,
      imageHeight,
      imageSize,
      imageWidth,
      multiple,
      onChange,
      realm,
    ],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept && (image ? 'image/png, image/jpeg, image/gif' : accept),
    disabled,
    maxSize,
    preventDropOnDocument: true,
  });

  const msgKey = [
    'dropzone',
    multiple ? 'multiple' : 'single',
    'msg',
    `${isDragActive ? 'active' : 'default'}`,
  ].join('.');

  const preview = children && children(value);

  const uploadingMsg = useMemo(
    () => (
      <>
        {!noUploadingMsg && (
          <span>
            <Translate
              id={`dropzone.uploading${process !== null ? '_progress' : ''}`}
              data={{ progress: numeral(progress || 0).format('[0]%') }}
            />
          </span>
        )}
        <span>
          <div className="m-loader m-loader--success" />
        </span>
      </>
    ),
    [noUploadingMsg, progress],
  );

  return (
    <Block
      active={isUploading}
      msg={uploadingMsg}
      style={style}
      msgStyle={uploadingMsgStyle}
    >
      <section>
        <div
          {...getRootProps({
            className: classNames('drop-zone', {
              'drop-zone--active': isDragActive,
            }),
          })}
        >
          <input {...getInputProps()} />
          {placeholder !== false && (
            <p className="placeholder">
              <Translate id={msgKey} />
            </p>
          )}
          {preview && <div className="drop-zone-preview">{preview}</div>}
        </div>
      </section>
    </Block>
  );
}
