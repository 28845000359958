/**
 * @file: CommonTaskStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { CommonTaskStatus, Option } from 'model';

export const CommonTaskStatusOptions: Array<Option<CommonTaskStatus>> = [
  { value: CommonTaskStatus.Pending, label: 'common_task_status.pending' },
  {
    value: CommonTaskStatus.InProgress,
    label: 'common_task_status.in_progress',
  },
  { value: CommonTaskStatus.Finished, label: 'common_task_status.finished' },
];

export const CommonTaskStatusOptionsWithDefault: Array<
  Option<CommonTaskStatus | null>
> = [
  { value: null, label: 'common_task_status.__default__' },
  { value: CommonTaskStatus.Pending, label: 'common_task_status.pending' },
  {
    value: CommonTaskStatus.InProgress,
    label: 'common_task_status.in_progress',
  },
  { value: CommonTaskStatus.Finished, label: 'common_task_status.finished' },
];
