import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import {
  AclObjectList,
  QuotationPartCatalog,
  QuotationPartCatalogListFilter,
} from 'model';
import { ChangeEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { quotationPartCatalogService } from 'services';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { formatTime } from 'utils';
import {
  importQuotationPartCatalogs,
  importQuotationPartCatalogsFailed,
  importQuotationPartCatalogsSuccess,
  quotationPartCatalogActions,
} from '../duck/actions';
import { QuotationPartCatalogs } from '../duck/states';

interface Props
  extends EntityListProps<
    QuotationPartCatalog,
    QuotationPartCatalogListFilter
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  QuotationPartCatalog,
  QuotationPartCatalogListFilter,
  number,
  Props
>();

export const QuotationPartCatalogList = componentClassBuilder
  .i18nPrefix('quotation_part_catalog')
  .accessRights({
    full: AclObjectList.QuotationPartCatalogFullAccess,
    readonly: AclObjectList.QuotationPartCatalogReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="settings.breadcrumb.it" /> },
    { text: <Translate id="settings.breadcrumb.quotation_part_catalogs" /> },
  ])
  .entities(state => state.settings.quotationPartCatalogs)
  .actions(quotationPartCatalogActions)
  .features({ addEntity: false })
  .toolbarItems(toolbar =>
    toolbar
      .text({
        prop: 'quotationName',
        label: 'quotation_part_catalog.toolbar.label.quotation_name',
        placeholder:
          'quotation_part_catalog.toolbar.placeholder.quotation_name',
      })
      .text({
        prop: 'partName',
        label: 'quotation_part_catalog.toolbar.label.part_name',
        placeholder: 'quotation_part_catalog.toolbar.placeholder.part_name',
      })
      .button({
        file: true,
        color: 'info',
        text: 'quotation_part_catalog.toolbar.button.import',
        accepts: [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
        loading: (props: Props) => {
          const entities = props.entities as QuotationPartCatalogs;
          return entities.isImporting;
        },
        shouldDisable: (_, props: Props) => {
          const entities = props.entities as QuotationPartCatalogs;
          return entities.isImporting;
        },
        onFileChange: (e: ChangeEvent<HTMLInputElement>, props: Props) => {
          if (!e.target.files?.length) return;
          const file = e.target.files[0];
          console.log(file.type);
          if (
            (file.type &&
              file.type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (file.name && !file.name.toLowerCase().endsWith('.xlsx'))
          ) {
            alert(getString('quotation_part_catalog.import.invalid_file'));
            return;
          }
          const reader = new FileReader();
          reader.addEventListener('load', async () => {
            const dataUri = reader.result as string;
            const { dispatch } = props;
            dispatch(showAppLoading());
            dispatch(importQuotationPartCatalogs());
            try {
              const result = await quotationPartCatalogService.import(dataUri);
              dispatch(hideAppLoading());
              dispatch(importQuotationPartCatalogsSuccess(result));
              dispatch(quotationPartCatalogActions.invalidate(true));
              dispatch(
                showAppModal(
                  getString('quotation_part_catalog.import.success.title'),
                  getString('quotation_part_catalog.import.success.msg', {
                    count: result,
                  }),
                ),
              );
              // eslint-disable-next-line @typescript-eslint/no-shadow
            } catch (e) {
              dispatch(hideAppLoading());
              dispatch(importQuotationPartCatalogsFailed(e));
              dispatch(
                showAppModal(
                  getString('quotation_part_catalog.import.error.title'),
                  getString('quotation_part_catalog.import.error.msg', {
                    errorMsg: e.message,
                  }),
                ),
              );
            }
          });
          reader.readAsDataURL(file);
        },
      })
      .button({
        placement: 'right',
        text: 'quotation_part_catalog.toolbar.button.search',
        onClick: (props: Props) => {
          props.dispatch(quotationPartCatalogActions.invalidate(true));
        },
      }),
  )
  .columns([
    {
      prop: 'quotationName',
      width: 150,
      text: 'quotation_part_catalog.col.quotation_name',
    },
    {
      prop: 'partName',
      width: 120,
      text: 'quotation_part_catalog.col.part_name',
    },
    {
      prop: 'qty',
      width: 80,
      text: 'quotation_part_catalog.col.qty',
    },
    {
      prop: 'unit',
      width: 80,
      text: 'quotation_part_catalog.col.unit',
      align: 'center',
    },
    {
      prop: 'createdAt',
      width: 150,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .getClass();
