/**
 * @file: productBrands.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { ProductBrand } from 'model';
import { productBrandService } from 'services';
import { ActionTypes } from '../types';

export const productBrandActions = createListAsyncActionCreators<
  AppState,
  ProductBrand
>('app.settings.product_brands', {
  getItemBeingCreated: (state: AppState) =>
    state.settings.productBrands.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.settings.productBrands.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.settings.productBrands.itemsBeingDeleted,

  fetchHandler: (state: AppState) => {
    return productBrandService.list(
      state.settings.productBrands.filter,
      state.settings.productBrands.sorts?.[0],
      state.settings.productBrands.offset,
      state.settings.productBrands.limit,
      { count: true, recursive: false },
    );
  },
  create: (entity: ProductBrand) => {
    return productBrandService.create(entity);
  },
  update: (entity: ProductBrand) => {
    return productBrandService.update(entity);
  },
  delete: (entity: ProductBrand) => {
    return productBrandService.delete(entity.id);
  },
  deleteMulti: (entities: ProductBrand[]) => {
    if (!entities.length) return Promise.resolve();
    if (entities.length === 1) {
      return productBrandService.delete(entities[0].id);
    }
    return Promise.reject(
      new Error('delete multi product brands is not supported for now. '),
    );
  },
});

export function importProductBrands() {
  return createStandardAction(ActionTypes.ImportProductBrands);
}

export function importProductBrandsSuccess(count: number) {
  return createStandardAction(ActionTypes.ImportProductBrandsSuccess, count);
}

export function importProductBrandsFailed(error: Error) {
  return createStandardAction(ActionTypes.ImportProductBrandsFailed, error);
}
