import { AsyncListState } from 'lib/duck/interfaces';
import { Render } from 'model';
import React, { Component, ReactChildren, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { Alert, Button } from 'shared/metronic/components';
import { AsideRight } from '../AsideRight';

import './EntityEditorSidebar.scss';

const SIMULATE_COMMITTING = false;

interface Props<T> {
  values: AsyncListState<T>;
  title: string | ReactNode;
  children: ReactChildren | ((entity: T | Partial<T>) => any);
  confirmClose?: boolean;
  error?: Error | null;
  onSave: (entity: T | Partial<T>) => void;
  onCancel: () => void;
  onValidate?: (entity: T | Partial<T>, isCreating: boolean) => void;
}

export type EntityEditorSidebarProps<T> = Props<T>;

interface State {
  showConfirmClose: boolean;
  error: Error | null;
}

interface ConfirmCloseEntityEditorSidebarModalProps {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export function ConfirmCloseEntityEditorSidebarModal(
  props: ConfirmCloseEntityEditorSidebarModalProps,
) {
  return (
    <Modal isOpen={props.open}>
      <ModalHeader>
        <Translate id="confirm_entity_editor_modal_title" />
      </ModalHeader>
      <ModalBody>
        <Translate id="confirm_entity_editor_modal_msg" />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.onClose}>
          <Translate id="yes_btn_text" />
        </Button>
        <Button color="secondary" onClick={props.onCancel}>
          <Translate id="no_btn_text" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export class EntityEditorSidebar<T>
  extends Component<Props<T>, State>
  implements Render<Props<T>>
{
  private readonly scrollWrapperRef = React.createRef<HTMLElement>();

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      showConfirmClose: false,
      error: null,
    };
  }
  render() {
    const { values, title, onCancel, children } = this.props;
    const isAdd = Boolean(values.itemBeingCreated);
    const isEdit = Boolean(values.itemBeingUpdated);
    const entity = (values.itemBeingCreated || values.itemBeingUpdated) as
      | T
      | Partial<T>;
    const show = isAdd || isEdit;
    const error = this.props.error || this.state.error;
    const spinning = Boolean(
      values.isCommittingItemBeingCreated ||
        values.isCommittingItemBeingUpdated,
    );
    return (
      <AsideRight
        open={isAdd || isEdit}
        spin={spinning || SIMULATE_COMMITTING}
        scrollWrapperRef={this.scrollWrapperRef}
        onClose={this.onClose}
      >
        <div className="entity-editor-sidebar">
          <ul
            className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
            style={{ marginBottom: 15 }}
          >
            <li className="nav-item m-tabs__item">
              <a
                className="nav-link m-tabs__link active"
                style={{ fontWeight: 'bold', fontSize: '120%' }}
              >
                {typeof title === 'string' ? (
                  <Translate id={`${title}.title.${isAdd ? 'add' : 'edit'}`} />
                ) : (
                  title
                )}
              </a>
            </li>
          </ul>
          {error && (
            <Alert color="danger" icon="fa fa-exclamation-triangle">
              {error.message}
            </Alert>
          )}
          {show &&
            (typeof children === 'function' ? children(entity) : children)}
          <div className="m-portlet__foot m-portlet__foot--fit mt-5">
            <div className="m-form__actions m-form__actions text-center">
              <Button color="brand" onClick={this.onSave}>
                <Translate id="save_btn_text" />
              </Button>
              &nbsp;
              <Button color="default" onClick={onCancel}>
                <Translate id="cancel_btn_text" />
              </Button>
            </div>
          </div>
        </div>
        {this.renderConfirmDeleteModal()}
      </AsideRight>
    );
  }

  onSave = () => {
    let entity: T | Partial<T> | null = null;
    let isCreating = false;
    if (this.props.values.itemBeingCreated) {
      entity = this.props.values.itemBeingCreated;
      isCreating = true;
    }
    if (this.props.values.itemBeingUpdated) {
      entity = this.props.values.itemBeingUpdated;
    }
    if (entity) {
      try {
        this.props.onValidate && this.props.onValidate(entity, isCreating);
      } catch (e) {
        if (this.scrollWrapperRef.current) {
          this.scrollWrapperRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
        this.setState({ error: e });
        return;
      }
      this.setState({ error: null }, () => {
        this.props.onSave(entity!);
      });
    }
  };

  onClose = () => {
    // see if has change.
    const { confirmClose, values, onCancel } = this.props;

    const spinning =
      Boolean(
        values.isCommittingItemBeingCreated ||
          values.isCommittingItemBeingUpdated,
      ) || SIMULATE_COMMITTING;

    if (spinning) {
      return;
    }

    const dirty =
      (values.itemBeingCreated && values.isItemBeingCreatedDirty) ||
      (values.itemBeingUpdated && values.isItemBeingUpdatedDirty);

    if (confirmClose === false || !dirty) {
      if (this.state.error) {
        this.setState({ error: null });
      }
      onCancel();
      return;
    }

    this.setState({
      showConfirmClose: true,
    });
  };

  onConfirmClose = () => {
    this.setState({ showConfirmClose: false, error: null }, () => {
      this.props.onCancel();
    });
  };

  onCancelClose = () => {
    this.setState({
      showConfirmClose: false,
    });
  };

  private renderConfirmDeleteModal() {
    return (
      <ConfirmCloseEntityEditorSidebarModal
        open={this.state.showConfirmClose}
        onClose={this.onConfirmClose}
        onCancel={this.onCancelClose}
      />
    );
  }
}
