/**
 * @file: VehicleInspectionCommentService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionCommentServiceBase } from './base/VehicleInspectionCommentService';

export class VehicleInspectionCommentService extends VehicleInspectionCommentServiceBase {
  // add custom service implementation here
}
