import { getScopedTransFunction } from 'app';
import classNames from 'classnames';
import {
  VehicleInspectionSite,
  VehicleInspectionSiteCheckItem,
  VehicleInspectionSiteCheckItemOption,
} from 'model';
import React, { MouseEvent, PureComponent, ReactNode } from 'react';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { OptionValueTypeLabel, SeverityLevelLabel } from 'shared/components';
import { arr2map } from 'utils';
import {
  InspectionSiteItemOptions,
  InspectionSiteItems,
  InspectionTools,
} from '../duck/states';

import './detail.scss';

interface Props {
  site: VehicleInspectionSite;
  items: InspectionSiteItems;
  options: InspectionSiteItemOptions;
  tools: InspectionTools;
  hasSiteFullAccessRight: boolean;
  hasItemFullAccessRight: boolean;
  hasOptionFullAccessRight: boolean;
  translate: TranslateFunction;
  onAddItem: (site: VehicleInspectionSite) => void;
  onEditItem: (item: VehicleInspectionSiteCheckItem) => void;
  onDeleteItem: (item: VehicleInspectionSiteCheckItem) => void;
  onAddOption: (
    site: VehicleInspectionSite,
    item: VehicleInspectionSiteCheckItem,
  ) => void;
  onEditOption: (option: VehicleInspectionSiteCheckItemOption) => void;
  onDeleteOption: (option: VehicleInspectionSiteCheckItemOption) => void;
}

export class SiteDetail extends PureComponent<Props> {
  render() {
    const {
      site,
      items,
      options,
      tools,
      hasSiteFullAccessRight,
      hasItemFullAccessRight,
      hasOptionFullAccessRight,
    } = this.props;
    if (!items.result || !options.result) {
      if (items.isLoading || options.isLoading) {
        return (
          <div className="inspection-site-detail__loading">
            <Translate id="inspection_site.detail.loading" />
          </div>
        );
      }
      return (
        <div className="inspection-site-detail__empty">
          <Translate id="inspection_site.detail.empty" />
        </div>
      );
    }
    const itemList = items.result.filter(x => x.siteId === site.id);
    const $detail = getScopedTransFunction(
      this.props.translate,
      'inspection_site.detail',
    );
    const $col = this.props.translate;
    const $icol = getScopedTransFunction(
      this.props.translate,
      'inspection_site.detail.col.item',
    );
    const $ocol = getScopedTransFunction(
      this.props.translate,
      'inspection_site.detail.col.option',
    );
    const $cells = () => {
      const cells: ReactNode[] = [];
      return new (class Builder {
        append(key: string, content: any, rowSpan?: number) {
          const cell = (
            <td key={key} rowSpan={rowSpan} className={`col__${key}`}>
              {content === null || content === undefined || content === ''
                ? '/'
                : content}
            </td>
          );
          cells.push(cell);
          return this;
        }
        when(condition: boolean, build: (builder: Builder) => void) {
          if (!condition) return this;
          build(this);
          return this;
        }
        cells() {
          return cells;
        }
      })();
    };
    const toolMap = arr2map(tools.result || [], x => x.id);
    const $actions = <T extends object>(
      data: T,
      onEdit?: (data: T) => void,
      onDelete?: (data: T) => void,
      onAdd?: (data: T) => void,
    ): ReactNode => {
      const onadd = (e: MouseEvent) => {
        e.preventDefault();
        onAdd?.(data);
      };
      const onedit = (e: MouseEvent) => {
        e.preventDefault();
        onEdit?.(data);
      };
      const ondelete = (e: MouseEvent) => {
        e.preventDefault();
        onDelete?.(data);
      };
      return (
        <span className="actions">
          {onAdd && (
            <a href="#" onClick={onadd}>
              <i className="la la-plus" />
            </a>
          )}
          {onEdit && (
            <a href="#" onClick={onedit}>
              <i className="la la-pencil" />
            </a>
          )}
          {onDelete && (
            <a href="#" onClick={ondelete}>
              <i className="la la-remove" />
            </a>
          )}
        </span>
      );
    };

    const onAddItem = (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      this.props.onAddItem(site);
    };

    return (
      <div className="inspection-site-detail">
        <table
          className="inspection-site-detail__items"
          cellSpacing={0}
          cellPadding={0}
        >
          <thead>
            <tr>
              <th className="col__item-name" style={{ whiteSpace: 'nowrap' }}>
                {$icol('name')}
                {hasSiteFullAccessRight && hasItemFullAccessRight && (
                  <a
                    href="#"
                    onClick={onAddItem}
                    style={{
                      marginLeft: 4,
                      textDecoration: 'none',
                      fontSize: '1.2rem',
                    }}
                  >
                    <i className="la la-plus" />
                  </a>
                )}
              </th>
              <th className="col__opt-label">{$ocol('label')}</th>
              <th className="col__opt-status">{$ocol('status')}</th>
              <th className="col__opt-desc">{$ocol('desc')}</th>
              <th className="col__opt-advice">{$ocol('advice')}</th>
              <th className="col__opt-is-pic-preferred">
                {$icol('is_pic_preferred')}
              </th>
              <th className="col__opt-rpic">{$ocol('ref_pic')}</th>
              {hasSiteFullAccessRight &&
                hasItemFullAccessRight &&
                hasOptionFullAccessRight && (
                  <th className="col__opt-actions">
                    {$col('col.actions') as any}
                  </th>
                )}
              <th className="col__item-rstate">{$icol('ref_state')}</th>
              <th className="col__item-rpic">{$icol('ref_pic')}</th>
              <th className="col__item-vtype">{$icol('value_type')}</th>
              <th className="col__item-vunit">{$icol('value_unit')}</th>
              <th className="col__item-pfid">{$icol('protocol_field_id')}</th>
              <th className="col__item-ifactor">{$icol('influence_factor')}</th>
              <th className="col__item-tool">{$icol('tool')}</th>
              <th className="col__item-is-pic-preferred">
                {$icol('is_pic_preferred')}
              </th>
              {hasSiteFullAccessRight && hasItemFullAccessRight && (
                <th className="col__item-actions">
                  {$col('col.actions') as any}
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {itemList.map((item, index) => {
              const optionList = options.result!.filter(
                x => x.itemId === item.id,
              );
              const list: Array<VehicleInspectionSiteCheckItemOption | null> =
                optionList.length ? optionList : [null];
              const rowSpan = Math.max(1, optionList.length);
              const tool = item.toolId ? toolMap[item.toolId] : null;
              return (
                <React.Fragment key={item.id}>
                  {list.map((option, i) => {
                    const cells = $cells()
                      .when(i === 0, $builder =>
                        $builder.append('item-name', item.name, rowSpan),
                      )
                      .append(
                        'opt-label',
                        option
                          ? option.label
                          : $actions(item, undefined, undefined, () => {
                              this.props.onAddOption(site, item);
                            }),
                      )
                      .append(
                        'opt-status',
                        (option && (
                          <SeverityLevelLabel value={option.severityLevel} />
                        )) ||
                          '-',
                      )
                      .append('opt-desc', option?.description || '-')
                      .append('opt-advice', option?.maintenanceAdvice || '-')
                      .append(
                        'opt-is-pic-preferred',
                        option?.isPicPreferred ? (
                          <i
                            className="la la-check m--font-success"
                            style={{ fontSize: '1.2rem' }}
                          />
                        ) : (
                          '-'
                        ),
                      )
                      .append(
                        'opt-rpic',
                        option?.imgList
                          ?.split(',')
                          .map(x => x.trim())
                          .filter(x => x).length || $detail('no_pic'),
                      )
                      .when(
                        hasSiteFullAccessRight &&
                          hasItemFullAccessRight &&
                          hasOptionFullAccessRight,
                        builder =>
                          builder.append(
                            'opt-actions',
                            option
                              ? $actions(
                                  option,
                                  () => {
                                    this.props.onEditOption(option!);
                                  },
                                  () => {
                                    this.props.onDeleteOption(option!);
                                  },
                                )
                              : $actions(item),
                          ),
                      )
                      .when(i === 0, $builder =>
                        $builder
                          .append('item-rstate', item.referenceState, rowSpan)
                          .append(
                            'item-rpic',
                            item?.imgList
                              ?.split(',')
                              .map(x => x.trim())
                              .filter(x => x).length || $detail('no_pic'),
                            rowSpan,
                          )
                          .append(
                            'item-vtype',
                            item.valueType ? (
                              <OptionValueTypeLabel value={item.valueType} />
                            ) : null,
                            rowSpan,
                          )
                          .append('item-vunit', item.valueUnit, rowSpan)
                          .append('item-pfid', item.protocolFieldId, rowSpan)
                          .append('item-ifactor', item.influenceFactor, rowSpan)
                          .append('item-tool', tool?.name, rowSpan)
                          .append(
                            'item-is-pic-preferred',
                            item.isPicPreferred ? (
                              <i
                                className="la la-check m--font-success"
                                style={{ fontSize: '1.4rem' }}
                              />
                            ) : (
                              '-'
                            ),
                            rowSpan,
                          )
                          .when(
                            hasSiteFullAccessRight && hasItemFullAccessRight,
                            builder =>
                              builder.append(
                                'item-actions',
                                $actions(
                                  item,
                                  () => {
                                    this.props.onEditItem(item);
                                  },
                                  () => {
                                    this.props.onDeleteItem(item);
                                  },
                                  () => {
                                    this.props.onAddOption(site, item);
                                  },
                                ),
                                rowSpan,
                              ),
                          ),
                      )
                      .cells();
                    return (
                      <tr
                        key={option?.id || '__placeholder__'}
                        className={classNames({
                          'row--alt': index % 2 === 1,
                        })}
                      >
                        {cells}
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
