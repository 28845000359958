/**
 * @file: task-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInspectionTask } from 'model';
import { VehicleInspectionTasks } from '../states';
import { ActionTypes } from '../types';

const initialState: VehicleInspectionTasks = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {
    status: [],
  },
  sorts: [
    {
      property: 'createdAt',
      dir: 'desc',
    },
  ],
};

const defaultRecuder = createAsyncListActionReducers<
  VehicleInspectionTask,
  VehicleInspectionTasks
>('tasks.task_list', initialState, {
  mapItemKey: x => x.id,
});

export default function vehicleInspectionTasksReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: VehicleInspectionTasks = initialState,
  action: StandardAction<any>,
): VehicleInspectionTasks {
  switch (action.type) {
    case ActionTypes.SetActiveTask: {
      return { ...state, activeTaskId: action.payload };
    }
    default: {
      return defaultRecuder(state, action);
    }
  }
}
