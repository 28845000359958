/**
 * @file: UserWeixinBindingService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { UserWeixinBindingServiceBase } from './base/UserWeixinBindingService';

export class UserWeixinBindingService extends UserWeixinBindingServiceBase {
  // add custom service implementation here
}
