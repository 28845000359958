/**
 * @file: LbsService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { CoordsType, GeoLocation } from 'model';

export class LbsService {
  constructor(private readonly api: APIService) {}

  async geocoding(
    address: string,
    type?: CoordsType,
    city?: string,
  ): Promise<GeoLocation> {
    return await this.api
      .post()
      .url('/lbs/geocoding')
      .data({ address, type, city })
      .future();
  }
}
