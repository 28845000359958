/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { SortInfo } from 'lib';
import { autoActionType } from 'lib/duck/actions/base';
import {
  ACTIVE_LIST_GROUP_KEY_CHANGED,
  CLEAR_SELECTION,
  COLLAPSE_ALL_ITEM_DETAIL,
  COLLAPSE_ALL_LIST_GROUP,
  COLLAPSE_ITEM_DETAIL,
  COLLAPSE_LIST_GROUP,
  COLLAPSE_LIST_ITEM_NODE,
  CREATE_ITEM,
  CREATE_ITEM_FAILED,
  CREATE_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAILED,
  DELETE_ITEM_SUCCESS,
  DELETE_MULTI,
  DELETE_MULTI_FAILED,
  DELETE_MULTI_SUCCESS,
  EXPAND_ALL_ITEM_DETAIL,
  EXPAND_ALL_LIST_GROUP,
  EXPAND_ITEM_DETAIL,
  EXPAND_LIST_GROUP,
  EXPAND_LIST_ITEM_NODE,
  INVALIDATE,
  ITEM_BEING_CREATED,
  ITEM_BEING_CREATED_CANCEL,
  ITEM_BEING_CREATED_CHANGED,
  ITEM_BEING_CREATED_COMMIT,
  ITEM_BEING_UPDATED,
  ITEM_BEING_UPDATED_CANCEL,
  ITEM_BEING_UPDATED_CHANGED,
  ITEM_BEING_UPDATED_COMMIT,
  ITEM_DESELECTED,
  ITEM_SELECTED,
  ITEMS_BEING_DELETED,
  ITEMS_BEING_DELETED_CANCEL,
  ITEMS_BEING_DELETED_COMMIT,
  LOAD_MORE,
  LOAD_MORE_FAILED,
  LOAD_MORE_SUCCESS,
  REMOVE_SORT_PROPERTY,
  SET_SORT_PROPERTY,
  TOGGLE_ALL_SELECTION,
  TOGGLE_SORT_PROPERTY,
  UPDATE_FILTER,
  UPDATE_ITEM,
  UPDATE_ITEM_FAILED,
  UPDATE_ITEM_SUCCESS,
  UPDATE_LIMIT,
  UPDATE_OFFSET,
  UPDATE_SELECTION,
  UPDATE_TOTAL,
} from 'lib/duck/actions/constants';
import { ListResultAction } from 'lib/duck/actions/list/interfaces';
import { ObjectResultAction } from 'lib/duck/actions/object';
import {
  AsyncListState,
  ObjectKeyType,
  StandardAction,
} from 'lib/duck/interfaces';
import { createAsyncActionReducers } from '../base';
import { AsyncActionReducer } from '../base/interfaces';
import { AsyncListActionReducersOptions } from './interfaces';

export * from './interfaces';

export function createAsyncListActionReducers<
  T,
  TState extends AsyncListState<T>,
  TKey extends ObjectKeyType = any,
>(
  scope: string,
  initialState: TState,
  options?: AsyncListActionReducersOptions<T, TState, TKey>,
): AsyncActionReducer<TState> {
  options = options || ({} as AsyncListActionReducersOptions<T, TState, TKey>);
  const {
    onLoadSuccess,
    onLoadFailed,
    onLoadMore,
    onLoadMoreSuccess,
    onLoadMoreFailed,
    onInvalidate,
    onCreate,
    onCreateSuccess,
    onCreateFailed,
    onUpdate,
    onUpdateSuccess,
    onUpdateFailed,
    onDelete,
    onDeleteSuccess,
    onDeleteFailed,
    onDeleteMulti,
    onDeleteMultiSuccess,
    onDeleteMultiFailed,
    onItemBeingUpdated,
    onUpdateFilter,
    mapItemKey,
    insertItemCreated,
    contactMoreItems,
    pagingMode,
  } = options;

  const next = createAsyncActionReducers<T[], TState, ListResultAction<T>>(
    scope,
    initialState,
    Object.assign({}, options, {
      onLoadSuccess: (state: TState) => {
        if (state.reset) {
          state = Object.assign({}, state, {
            offset: 0,
            reset: void 0,
            selection: [],
            hasMore:
              state.result!.length > 0 && state.result!.length === state.limit,
          });
        } else {
          state = Object.assign({}, state, {
            selection: [],
            hasMore:
              state.result!.length > 0 && state.result!.length === state.limit,
          });
        }
        return onLoadSuccess ? onLoadSuccess(state) : state;
      },
      onLoadFailed: (state: TState) => {
        if (state.reset) {
          state = Object.assign({}, state, { reset: void 0 });
        }
        return onLoadFailed ? onLoadFailed(state) : state;
      },
    }),
  );
  // eslint-disable-next-line @typescript-eslint/default-param-last
  return (state: TState = initialState, action: StandardAction<any>) => {
    switch (action.type) {
      case autoActionType(scope, UPDATE_TOTAL):
        return Object.assign({}, state, {
          total: action.payload as number,
        });
      case autoActionType(scope, UPDATE_OFFSET):
        return Object.assign({}, state, {
          offset: action.payload as number,
        });
      case autoActionType(scope, UPDATE_LIMIT):
        return Object.assign({}, state, {
          offset: 0,
          limit: action.payload as number,
        });

      // handle item selection
      case autoActionType(scope, UPDATE_SELECTION):
        return Object.assign({}, state, {
          selection: action.payload as TKey[],
        });
      case autoActionType(scope, ITEM_SELECTED): {
        const item = action.payload as T;
        let selection = state.selection || [];
        const key = mapItemKey!(item);
        if (!selection.includes(key)) {
          selection = [...selection, key];
          return Object.assign({}, state, { selection });
        }
        return state;
      }
      case autoActionType(scope, ITEM_DESELECTED): {
        const item = action.payload as T;
        const key = mapItemKey!(item);
        let selection = state.selection || [];
        if (selection.includes(key)) {
          selection = selection.filter(x => x !== key);
          return Object.assign({}, state, { selection });
        }
        return state;
      }
      case autoActionType(scope, TOGGLE_ALL_SELECTION): {
        const buildVisibleItems: () => T[] =
          action.payload || options?.buildVisibleItems;
        const items = buildVisibleItems
          ? buildVisibleItems()
          : state.result || [];
        const selection = state.selection || [];
        const isAllSelected = items.every(x =>
          selection.includes(mapItemKey!(x)),
        );
        if (isAllSelected) {
          return Object.assign({}, state, { selection: [] });
        } else {
          return Object.assign({}, state, {
            selection: items.slice().map(x => mapItemKey!(x)),
          });
        }
      }
      case autoActionType(scope, CLEAR_SELECTION): {
        return Object.assign({}, state, {
          selection: [],
        });
      }
      case autoActionType(scope, EXPAND_LIST_ITEM_NODE): {
        const item = action.payload as T;
        const id = mapItemKey!(item);
        if (options?.expandAllTreeListNodeByDefault) {
          if (!state.collapsedItemIds) return state;
          const collapsedItemIds = new Set<TKey>(state.collapsedItemIds);
          collapsedItemIds.delete(id);
          return { ...state, collapsedItemIds };
        }
        const expandedItemIds = state.expandedItemIds
          ? new Set<TKey>(state.expandedItemIds)
          : new Set<TKey>();
        expandedItemIds.add(id);
        return { ...state, expandedItemIds };
      }
      case autoActionType(scope, COLLAPSE_LIST_ITEM_NODE): {
        const item = action.payload as T;
        const id = mapItemKey!(item);
        if (options?.expandAllTreeListNodeByDefault) {
          const collapsedItemIds = state.collapsedItemIds
            ? new Set<TKey>(state.collapsedItemIds)
            : new Set<TKey>();
          collapsedItemIds.add(id);
          return { ...state, collapsedItemIds };
        }
        if (!state.expandedItemIds) return state;
        const expandedItemIds = new Set<TKey>(state.expandedItemIds);
        expandedItemIds.delete(id);
        return { ...state, expandedItemIds };
      }
      case autoActionType(scope, LOAD_MORE):
        if (state.isLoading || state.isLoadingMore) return state;
        if (
          typeof state.offset !== 'number' ||
          typeof state.limit !== 'number'
        ) {
          throw new Error(
            'offset and limit is required for load_more action. ',
          );
        }
        state = Object.assign({}, state, {
          isLoadingMore: true,
          error: null,
          offset: state.offset + state.limit,
        });
        return onLoadMore ? onLoadMore(state) : state;
      case autoActionType(scope, LOAD_MORE_SUCCESS): {
        const payload = (action as ListResultAction<T>).payload!;
        if (!payload.result) {
          throw new Error(`${autoActionType(scope, LOAD_MORE)} returns null. `);
        }
        let result = payload.result;
        if (pagingMode === 'infinite-scroll') {
          if (contactMoreItems) {
            result = contactMoreItems(payload.result, state.result || []);
          } else {
            result = (state.result || []).concat(payload.result);
          }
        }
        state = Object.assign({}, state, {
          isLoadingMore: false,
          error: null,
          result,
          hasMore:
            payload.result.length > 0 && payload.result.length === state.limit,
        });
        return onLoadMoreSuccess ? onLoadMoreSuccess(state) : state;
      }
      case autoActionType(scope, LOAD_MORE_FAILED): {
        const payload = (action as ListResultAction<T>).payload!;
        state = Object.assign({}, state, {
          isLoadingMore: false,
          offset: state.offset! - state.limit!,
          error: payload.error,
        });
        return onLoadMoreFailed ? onLoadMoreFailed(state) : state;
      }
      case autoActionType(scope, INVALIDATE): {
        const reset = action.payload as boolean;
        state = Object.assign({}, state, {
          isLoading: false,
          isLoadingMore: false,
          error: null,
          reset,
          offset: reset ? 0 : state.offset,
        });
        return onInvalidate ? onInvalidate(state, reset) : state;
      }
      case autoActionType(scope, CREATE_ITEM): // create
        state = Object.assign({}, state, {
          isCreating: true,
          createError: null,
          createdId: null,
        });
        return onCreate ? onCreate(state) : state;
      case autoActionType(scope, CREATE_ITEM_SUCCESS): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const payload = (action as ObjectResultAction<T>).payload!;
        const item = payload.result;
        if (!item) {
          throw new Error(
            `$${autoActionType(scope, CREATE_ITEM_SUCCESS)} returns null`,
          );
        }

        // if the list is not loaded yet, we're probably creating
        // an object outside the list context. In this case, just skip
        // update result, but only update the status.
        const key = mapItemKey(item);
        const result = state.result && [...state.result];
        if (result) {
          if (insertItemCreated) {
            insertItemCreated(item, result);
          } else {
            result.push(item);
          }
        }

        state = Object.assign(
          {},
          state,
          {
            isCreating: false,
            createError: null,
            createdId: key,
            result,
          },
          state.isCommittingItemBeingCreated
            ? {
                isCommittingItemBeingCreated: false,
                itemBeingCreated: void 0,
                isItemBeingCreatedDirty: void 0,
              }
            : {},
        );
        return onCreateSuccess ? onCreateSuccess(state) : state;
      }
      case autoActionType(scope, CREATE_ITEM_FAILED): {
        const payload = (action as ObjectResultAction<T>).payload!;
        state = Object.assign(
          {},
          state,
          {
            isCreating: false,
            createError: payload.error,
            createdId: null,
          },
          state.isCommittingItemBeingCreated
            ? {
                isCommittingItemBeingCreated: false,
              }
            : {},
        );
        return onCreateFailed ? onCreateFailed(state) : state;
      }
      case autoActionType(scope, UPDATE_ITEM): {
        // update
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const item = (action as StandardAction<T>).payload!;
        const key = mapItemKey(item);
        state = Object.assign({}, state, {
          isUpdating: true,
          lastUpdateError: null,
          lastUpdateItemId: key,
          updateStatus: Object.assign({}, state.updateStatus, {
            [key]: { requesting: true, error: null },
          }),
        });
        return onUpdate ? onUpdate(state) : state;
      }
      case autoActionType(scope, UPDATE_ITEM_SUCCESS): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const payload = (action as ObjectResultAction<T>).payload!;
        const item = payload.extra;
        const result = payload.result;
        const key = mapItemKey(item);
        state = Object.assign(
          {},
          state,
          {
            isUpdating: false,
            lastUpdateError: null,
            updateStatus: Object.assign({}, state.updateStatus, {
              [key]: { requesting: false, error: null },
            }),
            result: state.result!.map(x => {
              return mapItemKey(x) === key ? Object.assign({}, x, result) : x;
            }),
          },
          state.isCommittingItemBeingUpdated
            ? {
                isCommittingItemBeingUpdated: false,
                itemBeingUpdated: void 0,
                isItemBeingUpdatedDirty: void 0,
              }
            : {},
        );
        return onUpdateSuccess ? onUpdateSuccess(state) : state;
      }
      case autoActionType(scope, UPDATE_ITEM_FAILED): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const payload = (action as ObjectResultAction<T>).payload!;
        const item = payload.extra;
        const error = payload.error;
        const key = mapItemKey(item);
        state = Object.assign(
          {},
          state,
          {
            isUpdating: false,
            lastUpdateError: error,
            updateStatus: Object.assign({}, state.updateStatus, {
              [key]: { requesting: false, error },
            }),
          },
          state.isCommittingItemBeingUpdated
            ? {
                isCommittingItemBeingUpdated: false,
              }
            : {},
        );
        return onUpdateFailed ? onUpdateFailed(state) : state;
      }
      case autoActionType(scope, DELETE_ITEM): {
        // delete
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const item = (action as StandardAction<T>).payload!;
        const key = mapItemKey(item);
        state = Object.assign({}, state, {
          isDeleting: true,
          lastDeleteError: null,
          lastDeleteItemId: key,
          deleteStatus: Object.assign({}, state.deleteStatus, {
            [key]: { requesting: true, error: null },
          }),
        });
        return onDelete ? onDelete(state) : state;
      }
      case autoActionType(scope, DELETE_ITEM_SUCCESS): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const payload = (action as ObjectResultAction<T>).payload!;
        const item = payload.extra;
        const key = mapItemKey(item);
        state = Object.assign({}, state, {
          isDeleting: false,
          lastDeleteError: null,
          total: state.total && state.total > 0 ? state.total - 1 : state.total,
          result: state.result!.filter(x => mapItemKey(x) !== key),
          selection: state.selection?.filter(x => x !== key),
          deleteStatus: Object.assign({}, state.deleteStatus, {
            [key]: { requesting: false, error: null },
          }),
        });
        return onDeleteSuccess ? onDeleteSuccess(state) : state;
      }
      case autoActionType(scope, DELETE_ITEM_FAILED): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const payload = (action as ObjectResultAction<T>).payload!;
        const item = payload.extra;
        const error = payload.error;
        const key = mapItemKey(item);
        state = Object.assign({}, state, {
          isDeleting: false,
          lastDeleteError: error,
          deleteStatus: Object.assign({}, state.deleteStatus, {
            [key]: { requesting: false, error },
          }),
        });
        return onDeleteFailed ? onDeleteFailed(state) : state;
      }
      case autoActionType(scope, DELETE_MULTI): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const items = action.payload as T[];
        const keys = items.map(x => mapItemKey(x));
        state = Object.assign({}, state, {
          isDeleting: true,
          lastDeleteError: null,
          lastDeleteItemIds: keys,
          deleteStatus: Object.assign(
            {},
            state.deleteStatus,
            keys.reduce((obj, key) => {
              (obj as any)[key] = { requesting: true, error: null };
              return obj;
            }, {}),
          ),
        });
        return onDeleteMulti ? onDeleteMulti(state) : state;
      }
      case autoActionType(scope, DELETE_MULTI_SUCCESS): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const payload = (action as ObjectResultAction<T[]>).payload!;
        const items = payload.extra;
        const keys: TKey[] = items.map((x: T) => mapItemKey(x));
        state = Object.assign(
          {},
          state,
          {
            isDeleting: false,
            lastDeleteError: null,
            result: state.result!.filter(x => !keys.includes(mapItemKey(x))),
            total:
              state.total && state.total > 0
                ? state.total - keys.length
                : state.total,
            selection: state.selection?.filter(x => !keys.includes(x)),
            deleteStatus: Object.assign(
              {},
              state.deleteStatus,
              keys.reduce<{
                [key: string]: {
                  requesting: boolean;
                  error: Error | null | undefined;
                };
              }>((obj, key) => {
                obj[key as any] = { requesting: false, error: null };
                return obj;
              }, {}),
            ),
          },
          state.isCommitingItemsBeingDeleted
            ? {
                isCommitingItemsBeingDeleted: false,
                itemsBeingDeleted: void 0,
              }
            : {},
        );
        return onDeleteMultiSuccess ? onDeleteMultiSuccess(state) : state;
      }
      case autoActionType(scope, DELETE_MULTI_FAILED): {
        if (!mapItemKey) {
          throw new Error(`${scope} options.mapItemKey is required. `);
        }
        const payload = (action as ObjectResultAction<T[]>).payload!;
        const items = payload.extra;
        const error = payload.error;
        const keys: TKey[] = items.map((x: T) => mapItemKey(x));
        state = Object.assign(
          {},
          state,
          {
            isDeleting: false,
            lastDeleteError: error,
            deleteStatus: Object.assign(
              {},
              state.deleteStatus,
              keys.reduce<{
                [key: string]: {
                  requesting: boolean;
                  error: Error | null | undefined;
                };
              }>((obj, key) => {
                obj[key as any] = { requesting: false, error };
                return obj;
              }, {}),
            ),
          },
          state.isCommitingItemsBeingDeleted
            ? {
                isCommitingItemsBeingDeleted: false,
              }
            : {},
        );
        return onDeleteMultiFailed ? onDeleteMultiFailed(state) : state;
      }

      case autoActionType(scope, ITEM_BEING_CREATED):
        return Object.assign({}, state, {
          itemBeingCreated: action.payload as Partial<T>,
          isItemBeingCreatedDirty: false,
        } as Partial<TState>);
      case autoActionType(scope, ITEM_BEING_CREATED_CHANGED):
        return Object.assign({}, state, {
          isItemBeingCreatedDirty: true,
          itemBeingCreated: Object.assign(
            {},
            state.itemBeingCreated,
            action.payload as Partial<T>,
          ),
        });
      case autoActionType(scope, ITEM_BEING_CREATED_COMMIT):
        return Object.assign({}, state, {
          isCommittingItemBeingCreated: true,
        } as Partial<TState>);
      case autoActionType(scope, ITEM_BEING_CREATED_CANCEL):
        return Object.assign({}, state, {
          itemBeingCreated: void 0,
          isCommittingItemBeingCreated: false,
          isItemBeingCreatedDirty: void 0,
          createError: void 0,
        } as Partial<TState>);

      case autoActionType(scope, ITEM_BEING_UPDATED): {
        state = Object.assign({}, state, {
          itemBeingUpdated: Object.assign({}, action.payload as T), // copy
          isItemBeingUpdatedDirty: false,
        } as Partial<TState>);
        return onItemBeingUpdated ? onItemBeingUpdated(state) : state;
      }
      case autoActionType(scope, ITEM_BEING_UPDATED_CHANGED):
        return Object.assign({}, state, {
          isItemBeingUpdatedDirty: true,
          itemBeingUpdated: Object.assign(
            {},
            state.itemBeingUpdated,
            action.payload as Partial<T>,
          ),
        });
      case autoActionType(scope, ITEM_BEING_UPDATED_COMMIT):
        return Object.assign({}, state, {
          isCommittingItemBeingUpdated: true,
        } as Partial<TState>);
      case autoActionType(scope, ITEM_BEING_UPDATED_CANCEL):
        return Object.assign({}, state, {
          itemBeingUpdated: void 0,
          isCommittingItemBeingUpdated: false,
          lastUpdateError: void 0,
          isItemBeingUpdatedDirty: void 0,
        } as Partial<TState>);

      case autoActionType(scope, ITEMS_BEING_DELETED): {
        return Object.assign({}, state, {
          itemsBeingDeleted: action.payload as T[],
        } as Partial<TState>);
      }
      case autoActionType(scope, ITEMS_BEING_DELETED_COMMIT): {
        return Object.assign({}, state, {
          lastDeleteError: null,
          isCommitingItemsBeingDeleted: true,
        } as Partial<TState>);
      }
      case autoActionType(scope, ITEMS_BEING_DELETED_CANCEL): {
        return Object.assign({}, state, {
          lastDeleteError: void 0,
          isCommitingItemsBeingDeleted: false,
          itemsBeingDeleted: void 0,
        } as Partial<TState>);
      }

      case autoActionType(scope, TOGGLE_SORT_PROPERTY): {
        let sorts = state.sorts || [];
        const { property, dir } = action.payload as SortInfo;
        const sort = sorts.find(x => x.property === property);
        if (!sort) {
          if (options!.allowMultipleSortProperties) {
            sorts = [...sorts, { property, dir: dir || 'asc' }];
          } else {
            sorts = [{ property, dir: dir || 'asc' }];
          }
        } else {
          sorts = sorts.map(x =>
            x === sort
              ? Object.assign({}, x, {
                  dir: x.dir === 'asc' ? 'desc' : 'asc',
                })
              : x,
          );
        }
        return Object.assign({}, state, { sorts });
      }
      case autoActionType(scope, REMOVE_SORT_PROPERTY): {
        let sorts = state.sorts || [];
        const property = action.payload as string;
        const sort = sorts.find(x => x.property === property);
        if (sort) {
          sorts = sorts.filter(x => x.property !== property);
          return Object.assign({}, state, { sorts });
        }
        return state;
      }
      case autoActionType(scope, SET_SORT_PROPERTY): {
        let sorts = state.sorts || [];
        const { property, dir = 'asc' } = action.payload as SortInfo;
        const sort = sorts.find(x => x.property === property);
        if (!sort) {
          if (options!.allowMultipleSortProperties) {
            sorts = [...sorts, { property, dir }];
          } else {
            sorts = [{ property, dir }];
          }
        } else {
          sorts = sorts.map(x =>
            x === sort ? Object.assign({}, x, { dir }) : x,
          );
        }
        return Object.assign({}, state, { sorts });
      }
      case autoActionType(scope, UPDATE_FILTER): {
        const filter = action.payload;
        state = Object.assign({}, state, {
          filter: Object.assign({}, state.filter, filter),
        });
        return onUpdateFilter ? onUpdateFilter(state) : state;
      }
      case autoActionType(scope, ACTIVE_LIST_GROUP_KEY_CHANGED): {
        return {
          ...state,
          activeGroupKey: action.payload.key,
          activeGroupContext: action.payload.context,
        };
      }
      case autoActionType(scope, EXPAND_LIST_GROUP): {
        const value = action.payload as any;
        const key =
          state.activeGroupKey === null || state.activeGroupKey === undefined
            ? '__default_list_group_key__'
            : state.activeGroupKey;
        if (options?.collapseListGroupByDefault) {
          return {
            ...state,
            expandedGroups: addToMapSet(state.expandedGroups, key, value),
          };
        }
        return {
          ...state,
          collapsedGroups: removeFromMapSet(state.collapsedGroups, key, value),
        };
      }
      case autoActionType(scope, COLLAPSE_LIST_GROUP): {
        const value = action.payload as any;
        const key =
          state.activeGroupKey === null || state.activeGroupKey === undefined
            ? '__default_list_group_key__'
            : state.activeGroupKey;
        if (options?.collapseListGroupByDefault) {
          return {
            ...state,
            expandedGroups: removeFromMapSet(state.expandedGroups, key, value),
          };
        }
        return {
          ...state,
          collapsedGroups: addToMapSet(state.collapsedGroups, key, value),
        };
      }
      case autoActionType(scope, EXPAND_ALL_LIST_GROUP): {
        const key =
          state.activeGroupKey === null || state.activeGroupKey === undefined
            ? '__default_list_group_key__'
            : state.activeGroupKey;
        const { buildAllGroupValues } = (action.payload || {}) as any;
        const context = state.activeGroupContext;
        if (options?.collapseListGroupByDefault) {
          return {
            ...state,
            expandedGroups: assignMapSet(
              state.expandedGroups,
              key,
              buildAllGroupValues(key, context),
            ),
          };
        }
        return {
          ...state,
          collapsedGroups: assignMapSet(state.collapsedGroups, key, []),
        };
      }
      case autoActionType(scope, COLLAPSE_ALL_LIST_GROUP): {
        const key =
          state.activeGroupKey === null || state.activeGroupKey === undefined
            ? '__default_list_group_key__'
            : state.activeGroupKey;
        const { buildAllGroupValues } = (action.payload || {}) as any;
        const context = state.activeGroupContext;
        if (options?.collapseListGroupByDefault) {
          return {
            ...state,
            expandedGroups: assignMapSet(state.expandedGroups, key, []),
          };
        }
        return {
          ...state,
          collapsedGroups: assignMapSet(
            state.collapsedGroups,
            key,
            buildAllGroupValues(key, context),
          ),
        };
      }
      case autoActionType(scope, EXPAND_ITEM_DETAIL): {
        const { key = '__default_detail_key__', item } = action.payload as any;
        const id = mapItemKey!(item);
        if (options?.expandItemDetailByDefault) {
          return {
            ...state,
            collpasedDetailItemIds: removeFromMapSet(
              state.collpasedDetailItemIds,
              key,
              id,
            ),
          };
        }
        return {
          ...state,
          expandedDetailItemIds: addToMapSet(
            state.expandedDetailItemIds,
            key,
            id,
          ),
        };
      }
      case autoActionType(scope, COLLAPSE_ITEM_DETAIL): {
        const { key = '__default_detail_key__', item } = action.payload as any;
        const id = mapItemKey!(item);
        if (options?.expandItemDetailByDefault) {
          return {
            ...state,
            collpasedDetailItemIds: addToMapSet(
              state.collpasedDetailItemIds,
              key,
              id,
            ),
          };
        }
        return {
          ...state,
          expandedDetailItemIds: removeFromMapSet(
            state.expandedDetailItemIds,
            key,
            id,
          ),
        };
      }
      case autoActionType(scope, EXPAND_ALL_ITEM_DETAIL): {
        const { key = '__default_detail_key__', visibleItemsBuilder } =
          action.payload as {
            key?: any;
            visibleItemsBuilder: () => T[];
          };
        if (options?.expandItemDetailByDefault) {
          return {
            ...state,
            collpasedDetailItemIds: assignMapSet(
              state.collpasedDetailItemIds,
              key,
              [],
            ),
          };
        }
        return {
          ...state,
          expandedDetailItemIds: assignMapSet(
            state.expandedDetailItemIds,
            key,
            visibleItemsBuilder().map(x => mapItemKey!(x)),
          ),
        };
      }
      case autoActionType(scope, COLLAPSE_ALL_ITEM_DETAIL): {
        const { key = '__default_detail_key__', visibleItemsBuilder } =
          action.payload as {
            key?: any;
            visibleItemsBuilder: () => T[];
          };
        if (options?.expandItemDetailByDefault) {
          return {
            ...state,
            collpasedDetailItemIds: assignMapSet(
              state.collpasedDetailItemIds,
              key,
              visibleItemsBuilder().map(x => mapItemKey!(x)),
            ),
          };
        }
        return {
          ...state,
          expandedDetailItemIds: assignMapSet(
            state.expandedDetailItemIds,
            key,
            [],
          ),
        };
      }
      default:
        return next(state, action);
    }
  };
}

// set operation helpers
function addToSet<T = any>(set: Set<T> | undefined | null, item: T): Set<T> {
  const newSet = new Set(set);
  newSet.add(item);
  return newSet;
}

function removeFromSet<T = any>(
  set: Set<T> | undefined | null,
  item: T,
): Set<T> {
  const newSet = new Set(set);
  newSet.delete(item);
  return newSet;
}

// map operation helpers
function updateMap<T = any, U = any>(
  map: Map<T, U> | undefined | null,
  key: T,
  value: U,
): Map<T, U> {
  const newMap = map ? new Map<T, U>([...map]) : new Map<T, U>();
  newMap.set(key, value);
  return newMap;
}

function addToMapSet<T, U>(
  map: Map<T, Set<U>> | null | undefined,
  key: T,
  item: U,
): Map<T, Set<U>> {
  const set = map?.get(key);
  return updateMap<T, Set<U>>(map, key, addToSet(set, item));
}

function removeFromMapSet<T, U>(
  map: Map<T, Set<U>> | null | undefined,
  key: T,
  item: U,
): Map<T, Set<U>> {
  const set = map?.get(key);
  return updateMap<T, Set<U>>(map, key, removeFromSet(set, item));
}

function assignMapSet<T, U>(
  map: Map<T, Set<U>> | null | undefined,
  key: T,
  set: Set<U> | IterableIterator<U> | U[],
) {
  if (!(set instanceof Set)) {
    set = new Set<U>(set);
  }
  return updateMap(map, key, set);
}
