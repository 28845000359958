import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import { AclObjectList, ProductBrand, ProductBrandListFilter } from 'model';
import { ChangeEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { commonService, productBrandService } from 'services';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { formatTime } from 'utils';
import {
  importProductBrands,
  importProductBrandsFailed,
  importProductBrandsSuccess,
  productBrandActions,
} from '../duck/actions';
import { ProductBrands } from '../duck/states';

interface Props extends EntityListProps<ProductBrand, ProductBrandListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  ProductBrand,
  ProductBrandListFilter,
  number,
  Props
>();

export const ProductBrandList = componentClassBuilder
  .i18nPrefix('product_brand')
  .accessRights({
    full: AclObjectList.ProductBrandFullAccess,
    readonly: AclObjectList.ProductBrandReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="settings.breadcrumb.it" /> },
    { text: <Translate id="settings.breadcrumb.product_brands" /> },
  ])
  .entities(state => state.settings.productBrands)
  .actions(productBrandActions)
  .addActionButtons(['edit', 'remove'])
  .toolbarItems(toolbar =>
    toolbar
      .text({
        prop: 'keyword' as any,
        label: 'product_brand.toolbar.label.keyword',
        placeholder: 'product_brand.toolbar.placeholder.keyword',
      })
      .button({
        file: true,
        color: 'info',
        text: 'product_brand.toolbar.button.import',
        accepts: [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
        loading: (props: Props) => {
          const entities = props.entities as ProductBrands;
          return entities.isImporting;
        },
        shouldDisable: (_, props: Props) => {
          const entities = props.entities as ProductBrands;
          return entities.isImporting;
        },
        onFileChange: (e: ChangeEvent<HTMLInputElement>, props: Props) => {
          if (!e.target.files?.length) return;
          const file = e.target.files[0];
          console.log(file.type);
          if (
            (file.type &&
              file.type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (file.name && !file.name.toLowerCase().endsWith('.xlsx'))
          ) {
            alert(getString('product_brand.import.invalid_file'));
            return;
          }
          const reader = new FileReader();
          reader.addEventListener('load', async () => {
            const dataUri = reader.result as string;
            const { dispatch } = props;
            dispatch(showAppLoading());
            dispatch(importProductBrands());
            try {
              const result = await productBrandService.import(dataUri);
              dispatch(hideAppLoading());
              dispatch(importProductBrandsSuccess(result));
              dispatch(productBrandActions.invalidate(true));
              dispatch(
                showAppModal(
                  getString('product_brand.import.success.title'),
                  getString('product_brand.import.success.msg', {
                    count: result,
                  }),
                ),
              );
              // eslint-disable-next-line @typescript-eslint/no-shadow
            } catch (e) {
              dispatch(hideAppLoading());
              dispatch(importProductBrandsFailed(e));
              dispatch(
                showAppModal(
                  getString('product_brand.import.error.title'),
                  getString('product_brand.import.error.msg', {
                    errorMsg: e.message,
                  }),
                ),
              );
            }
          });
          reader.readAsDataURL(file);
        },
      })
      .button({
        placement: 'right',
        text: 'product_brand.toolbar.button.search',
        onClick: (props: Props) => {
          props.dispatch(productBrandActions.invalidate(true));
        },
      }),
  )
  .editor(builder =>
    builder
      .uncontrolled()
      .text({
        prop: 'name',
        label: 'product_brand.editor.label.name',
        placeholder: 'product_brand.editor.placeholder.name',
        onChange: async (changes, props: Props) => {
          const { dispatch } = props;
          const updatePyInitial = (pyInitial: string) => {
            if (props.entities.itemBeingCreated) {
              dispatch(
                productBrandActions.itemBeingCreatedChanged({ pyInitial }),
              );
            } else {
              dispatch(
                productBrandActions.itemBeingUpdatedChanged({ pyInitial }),
              );
            }
          };
          if (changes.name) {
            try {
              const pyInitial = await commonService.getPyInitial(changes.name);
              updatePyInitial(pyInitial);
            } catch (e) {
              console.error(e);
              updatePyInitial('');
            }
          } else {
            updatePyInitial('');
          }
        },
      })
      .text({
        prop: 'pyInitial',
        label: 'product_brand.editor.label.py_initial',
        placeholder: 'product_brand.editor.placeholder.py_initial',
      })
      .image({
        prop: 'logoUrl',
        label: 'product_brand.editor.label.logo',
        filePicker: {
          cover: false,
          realm: 'product-brands/logos',
          accept: 'image/png, image/jpeg',
        },
      }),
  )
  .columns([
    {
      prop: 'id',
      width: 150,
      text: 'col.id',
    },
    {
      prop: 'name',
      width: 250,
      text: 'product_brand.col.name',
      render: item => (
        <span style={{ verticalAlign: 'middle' }}>
          <img
            src={
              item.logoUrl ??
              'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
            }
            style={{
              width: 60,
              height: 60,
              borderRadius: 5,
              backgroundColor: '#eee',
              verticalAlign: 'middle',
              marginRight: '0.5rem',
            }}
          />
          {item.name}
        </span>
      ),
    },
    {
      prop: 'pyInitial',
      width: 120,
      text: 'product_brand.col.py_initial',
    },
    {
      prop: 'createdAt',
      width: 150,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .validate(entity => {
    let msg = '';

    if (!entity.name?.trim()) {
      msg = 'name_required';
    } else if (!entity.pyInitial) {
      msg = 'py_initial_required';
    }

    if (msg) {
      throw new Error(getString(`product_brand.editor.error.${msg}`));
    }
  })
  .getClass();
