/**
 * @file: adminUsersAcl.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { adminUserService } from 'services';
import { createObjectMapAsyncActionCreators } from 'lib/duck/actions';
import { AdminUserAccessRights } from 'model';

const actionCreators = createObjectMapAsyncActionCreators<
  AppState,
  number,
  AdminUserAccessRights
>('app.settings.admin_users.acl', {
  fetchHandler: () => Promise.reject(),
  fetchObjectHandler: async (_, userId: number) => {
    const acl = await adminUserService.getAdminUserAcl(userId);
    return { userId, acl };
  },
  updateObjectHandler: async (_, obj: AdminUserAccessRights) => {
    const acl = await adminUserService.setAdminUserAcl(obj.userId, obj.acl);
    return { userId: obj.userId, acl };
  },
  mapValueToKey: (obj: AdminUserAccessRights) => obj.userId,
});

export const {
  fetchObjectByKey: fetchAdminUserAcl,
  requestUpdateObjectByKey: updateAdminUserAcl,
} = actionCreators;
