import { StandardAction } from 'lib/duck/interfaces';
import { DatePartitionType, DateRange } from 'model';
import moment from 'moment';
import { combineReducers } from 'redux';
import { DashboardState, StatAspect } from '../states';
import { ActionTypes } from '../types';
import companionSummaryStats from './companion-summary-stats';
import inspectionCommentStatsByTime from './inspection-comment-stats-by-time';
import inspectionReportStatsByTime from './inspection-report-stats-by-time';
import inspectionTaskStatsByTime from './inspection-task-stats-by-time';
import orgStatsByTime from './org-stats-by-time';
import recentInspectionComments from './recent-inspection-comments';
import recentInspectionReports from './recent-inspection-reports';
import recentInspectionTasks from './recent-inspection-tasks';
import recentOrganizations from './recent-orgs';
import recentReceptionOrders from './recent-reception-orders';
import recentStores from './recent-stores';
import recentUsers from './recent-users';
import recentVehicleInfos from './recent-vehicles';
import receptionOrderStatsByTime from './reception-order-stats-by-time';
import storeStatsByTime from './store-stats-by-time';
import summaryStats from './summary-stats';
import userStatsByTime from './user-stats-by-time';
import vehicleStatsByTime from './vehicle-stats-by-time';

const defaultDateRange: DateRange = {
  startDate: moment().startOf('day').add(-14, 'd').format('YYYY-MM-DD'),
  endDate: moment().startOf('day').add(1, 'd').format('YYYY-MM-DD'),
};

function dateRangeReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = defaultDateRange,
  action: StandardAction<DateRange>,
): DateRange {
  switch (action.type) {
    case ActionTypes.UpdateStatsDateRange: {
      return action.payload!;
    }
    default:
      return state;
  }
}

function activeStatAspectReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: StatAspect = StatAspect.ReceptionOrder,
  action: StandardAction<StatAspect>,
): StatAspect {
  if (action.type === ActionTypes.UpdateActiveStatAspect) {
    return action.payload!;
  }
  return state;
}

const reducer = combineReducers({
  dateRange: dateRangeReducer,
  activeStatAspect: activeStatAspectReducer,
  summaryStats,
  companionSummaryStats,
  orgStatsByTime,
  storeStatsByTime,
  userStatsByTime,
  receptionOrderStatsByTime,
  inspectionTaskStatsByTime,
  vehicleStatsByTime,
  inspectionReportStatsByTime,
  inspectionCommentStatsByTime,
  recentOrganizations,
  recentStores,
  recentUsers,
  recentReceptionOrders,
  recentInspectionTasks,
  recentInspectionReports,
  recentInspectionComments,
  recentVehicleInfos,
});

type StatsByTimeKey = Pick<
  DashboardState,
  | 'orgStatsByTime'
  | 'storeStatsByTime'
  | 'userStatsByTime'
  | 'receptionOrderStatsByTime'
  | 'inspectionTaskStatsByTime'
  | 'vehicleStatsByTime'
  | 'inspectionReportStatsByTime'
  | 'inspectionCommentStatsByTime'
>;

type StatsByTimeMap = {
  [aspect in StatAspect]: keyof StatsByTimeKey;
};

const statsByTimeMap: StatsByTimeMap = {
  [StatAspect.Organization]: 'orgStatsByTime',
  [StatAspect.Store]: 'storeStatsByTime',
  [StatAspect.User]: 'userStatsByTime',
  [StatAspect.VehicleInfo]: 'vehicleStatsByTime',
  [StatAspect.ReceptionOrder]: 'receptionOrderStatsByTime',
  [StatAspect.InspectionTask]: 'inspectionTaskStatsByTime',
  [StatAspect.InspectionReport]: 'inspectionReportStatsByTime',
  [StatAspect.InspectionComment]: 'inspectionCommentStatsByTime',
};

interface UpdateDatePartitionTypeActionPayload {
  statAspect?: StatAspect;
  partition: DatePartitionType;
}

export default function (state: DashboardState, action: StandardAction<any>) {
  if (action.type === ActionTypes.UpdateDatePartitionType) {
    const { partition, statAspect } =
      action.payload as UpdateDatePartitionTypeActionPayload;
    const key = statAspect
      ? statsByTimeMap[statAspect]
      : statsByTimeMap[state.activeStatAspect];
    const statsByTime = state[key];
    if (statsByTime.partition === partition) {
      return state;
    }
    return Object.assign({}, state, {
      [key]: Object.assign({}, statsByTime, { partition }),
    });
  }
  return reducer(state, action);
}
