/**
 * @file: InspectionToolService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionToolServiceBase } from './base/InspectionToolService';

export class InspectionToolService extends InspectionToolServiceBase {
  // add custom service implementation here
}
