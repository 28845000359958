import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { User } from 'model';
import { RecentUsers } from '../states';

const initialState: RecentUsers = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'createdAt', dir: 'desc' }],
  offset: 0,
  limit: 15
};

export default createAsyncListActionReducers<User, RecentUsers>(
  'dashboard.recent_users',
  initialState,
  {
    mapItemKey: x => x.id
  }
);