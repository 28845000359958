import React, { HTMLProps } from 'react';
import classNames from 'classnames';

interface Props extends HTMLProps<HTMLLIElement> {
  fit?: boolean;
}

export const NavSeparator = (props: Props) => {
  const { className, fit, children, ...otherProps } = props;
  return (
    <li className={classNames('m-nav__separator', className, {
      'm-nav__separator--fit': fit
      })}
      {...otherProps}
      >
      {children}
    </li>
    );
};