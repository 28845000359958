import React, { Component } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Column, DataTable } from 'shared/metronic/components';
import { getScopedTransFunction } from 'app';
import { RecentInspectionTasks } from './duck/states';
import { VehicleInspectionTask } from 'model';
import { formatTime } from 'utils';
import {
  InspectionOrderTypeLabel,
  InspectionTaskStatusLabel,
  Mileage,
} from 'shared/components';

interface Props extends LocalizeContextProps {
  tasks: RecentInspectionTasks;
}

export class RecentVehicleInspectionTaskListComponent extends Component<Props> {
  private columns: Array<Column<VehicleInspectionTask>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { tasks } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="id"
        selModel="none"
        data={tasks.result}
        isLoading={tasks.isLoading}
        minHeight={0}
        error={tasks.error}
      />
    );
  }

  private init() {
    const { translate } = this.props;
    const trans = getScopedTransFunction(translate, '');
    this.columns = [
      {
        prop: 'storeName',
        width: 150,
        text: trans('col.store_name'),
      },
      {
        prop: 'taskNo',
        width: 120,
        text: trans('col.task_no'),
      },
      {
        prop: 'orderNo',
        width: 200,
        text: trans('col.order_no'),
      },
      {
        prop: 'orderType',
        width: 80,
        align: 'center',
        text: trans('col.order_type'),
        render: ({ orderType }) => (
          <InspectionOrderTypeLabel value={orderType} />
        ),
      },
      {
        prop: 'status',
        width: 120,
        align: 'center',
        text: trans('col.status'),
        render: ({ status }) => <InspectionTaskStatusLabel value={status} />,
      },
      {
        prop: 'customerName',
        width: 100,
        hidden: true,
        text: trans('col.customer_name'),
      },
      {
        prop: 'vehicleName',
        width: 250,
        text: trans('col.vehicle_name'),
      },
      {
        prop: 'vin',
        width: 150,
        text: trans(`col.vin`),
      },
      {
        prop: 'licensePlateNo',
        width: 100,
        text: trans('col.license_plate_no'),
      },
      {
        prop: 'vehicleMileage',
        width: 100,
        text: trans('col.vehicle_mileage'),
        render: ({ vehicleMileage }) => <Mileage value={vehicleMileage} />,
      },
      {
        prop: 'startedAt',
        text: trans('col.created_at'),
        width: 150,
        align: 'center',
        render: ({ startedAt }) => (startedAt ? formatTime(startedAt) : '-'),
      },
    ];
  }
}

export const RecentVehicleInspectionTaskList = withLocalize(
  RecentVehicleInspectionTaskListComponent,
);
