/**
 * @file: ProductAgentUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  ProductAgentUser,
  ProductAgentUserListFilter,
  SortInfo,
} from 'model';

export class ProductAgentUserServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<ProductAgentUserListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<ProductAgentUser>
      : ProductAgentUser[]
  > {
    return await this.api
      .get()
      .url(
        '/product-agent-users',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<ProductAgentUser | null> {
    return await this.api
      .get()
      .url('/product-agent-users/:id', { id })
      .future();
  }

  async getUserByUid(uid: string): Promise<ProductAgentUser | null> {
    if (!uid) {
      throw new Error('missing uid parameter');
    }
    return await this.api
      .get()
      .url('/product-agent-users/query/uid', { uid })
      .future();
  }

  async getUserByMobile(mobile: string): Promise<ProductAgentUser | null> {
    if (!mobile) {
      throw new Error('missing mobile parameter');
    }
    return await this.api
      .get()
      .url('/product-agent-users/query/mobile', { mobile })
      .future();
  }

  async create(
    productAgentUser: Partial<ProductAgentUser>,
  ): Promise<ProductAgentUser> {
    return await this.api
      .post()
      .url('/product-agent-users')
      .data(productAgentUser)
      .future();
  }

  async update(
    productAgentUser: Partial<ProductAgentUser>,
  ): Promise<ProductAgentUser> {
    return await this.api
      .put()
      .url('/product-agent-users/:id', { id: productAgentUser.id })
      .data(productAgentUser)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/product-agent-users/:id', { id })
      .future();
  }
}
