import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import { AclObjectList, AutoPartName, AutoPartNameListFilter } from 'model';
import { ChangeEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { autoPartNameService } from 'services';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { formatTime } from 'utils';
import {
  autoPartNameActions,
  importAutoPartNames,
  importAutoPartNamesFailed,
  importAutoPartNamesSuccess,
} from '../duck/actions';
import { AutoPartNames } from '../duck/states';

interface Props extends EntityListProps<AutoPartName, AutoPartNameListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  AutoPartName,
  AutoPartNameListFilter,
  number,
  Props
>();

export const AutoPartNameList = componentClassBuilder
  .i18nPrefix('auto_part_name')
  .accessRights({
    full: AclObjectList.AutoPartNameFullAccess,
    readonly: AclObjectList.AutoPartNameReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="settings.breadcrumb.it" /> },
    { text: <Translate id="settings.breadcrumb.auto_part_names" /> },
  ])
  .entities(state => state.settings.autoPartNames)
  .actions(autoPartNameActions)
  .features({ addEntity: false })
  .toolbarItems(toolbar =>
    toolbar
      .text({
        prop: 'keyword' as any,
        label: 'auto_part_name.toolbar.label.keyword',
        placeholder: 'auto_part_name.toolbar.placeholder.keyword',
      })
      .button({
        file: true,
        color: 'info',
        text: 'auto_part_name.toolbar.button.import',
        accepts: [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
        loading: (props: Props) => {
          const entities = props.entities as AutoPartNames;
          return entities.isImporting;
        },
        shouldDisable: (_, props: Props) => {
          const entities = props.entities as AutoPartNames;
          return entities.isImporting;
        },
        onFileChange: (e: ChangeEvent<HTMLInputElement>, props: Props) => {
          if (!e.target.files?.length) return;
          const file = e.target.files[0];
          console.log(file.type);
          if (
            (file.type &&
              file.type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (file.name && !file.name.toLowerCase().endsWith('.xlsx'))
          ) {
            alert(getString('auto_part_name.import.invalid_file'));
            return;
          }
          const reader = new FileReader();
          reader.addEventListener('load', async () => {
            const dataUri = reader.result as string;
            const { dispatch } = props;
            dispatch(showAppLoading());
            dispatch(importAutoPartNames());
            try {
              const result = await autoPartNameService.import(dataUri);
              dispatch(hideAppLoading());
              dispatch(importAutoPartNamesSuccess(result));
              dispatch(autoPartNameActions.invalidate(true));
              dispatch(
                showAppModal(
                  getString('auto_part_name.import.success.title'),
                  getString('auto_part_name.import.success.msg', {
                    count: result,
                  }),
                ),
              );
              // eslint-disable-next-line @typescript-eslint/no-shadow
            } catch (e) {
              dispatch(hideAppLoading());
              dispatch(importAutoPartNamesFailed(e));
              dispatch(
                showAppModal(
                  getString('auto_part_name.import.error.title'),
                  getString('auto_part_name.import.error.msg', {
                    errorMsg: e.message,
                  }),
                ),
              );
            }
          });
          reader.readAsDataURL(file);
        },
      })
      .button({
        placement: 'right',
        text: 'auto_part_name.toolbar.button.search',
        onClick: (props: Props) => {
          props.dispatch(autoPartNameActions.invalidate(true));
        },
      }),
  )
  .columns([
    {
      prop: 'name',
      width: 120,
      text: 'auto_part_name.col.name',
    },
    {
      prop: 'pyInitial',
      width: 120,
      text: 'auto_part_name.col.py_initial',
    },
    {
      prop: 'alias',
      width: 120,
      text: 'auto_part_name.col.alias',
    },
    {
      prop: 'aliasPyInitial',
      width: 100,
      text: 'auto_part_name.col.alias_py_initial',
    },
    {
      prop: 'unit',
      width: 80,
      text: 'auto_part_name.col.unit',
      align: 'center',
    },
    {
      prop: 'createdAt',
      width: 150,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .getClass();
