/**
 * @file: UserRoleTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, UserRoleType } from 'model';

export const UserRoleTypeOptions: Array<Option<UserRoleType>> = [
  { value: UserRoleType.GeneralUser, label: 'user_role_type.general' },
  { value: UserRoleType.GuestAgent, label: 'user_role_type.guest_agent' },
];

export const UserRoleTypeOptionsWithDefault: Array<
  Option<UserRoleType | null>
> = [
  { value: null, label: 'user_role_type.__default__' },
  { value: UserRoleType.GeneralUser, label: 'user_role_type.general' },
  { value: UserRoleType.GuestAgent, label: 'user_role_type.guest_agent' },
];
