import { showAppLoading } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { quotationTplActions } from 'app/inspection/duck/actions/quotation-template-config';
import { QuotationTemplateConfigState } from 'app/inspection/duck/states';
import { Actions } from 'app/inspection/quotation-template-config/Actions';
import { Content } from 'app/inspection/quotation-template-config/Content';
import {
  QuotationTemplateConfigContext,
  QuotationTemplateConfigProvider,
} from 'app/inspection/quotation-template-config/Context';
import { AclObjectList, QuotationTemplateConfig } from 'model';
import { useCallback, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { BreadcrumbItem, Page, getString } from 'shared/components';

import { CreateFromConstructionModal } from 'app/inspection/quotation-template-config/CreateFromConstructionModal';
import { constructionTemplateConfigToStaged } from 'app/inspection/quotation-template-config/util';
import { constructionTemplateConfigService } from 'services';
import './index.scss';

export const QuotationTemplateConfigManager = () => {
  const [breadcrumbs] = useState<BreadcrumbItem[]>(() => {
    return [
      { text: <Translate id="inspection.breadcrumb.it" /> },
      {
        text: (
          <Translate id="inspection.breadcrumb.quotation_template_config" />
        ),
        href: '/inspection/quotation-template-config',
      },
    ];
  });

  const [showCreateFromConstructionModal, setShowCreateFromConstructionModal] =
    useState(false);

  const dispatch = useDispatch();

  const onLoad = useCallback(
    (err: Error | null, res: QuotationTemplateConfig) => {
      if (err == null) {
        dispatch(quotationTplActions.quotationTemplateConfigLoaded(res));
      } else {
        dispatch(
          showAppLoading({
            status: 'error',
            timeout: 3000,
            message: getString('quotation_tpl.load_error', {
              msg: err.message,
            }),
          }),
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(quotationTplActions.fetchQuotationTemplateConfig(onLoad));
  }, [dispatch, onLoad]);

  const state = useSelector<AppState, QuotationTemplateConfigState>(
    x => x.inspection.quotationTemplateConfig,
  );

  const hasChanges = state.dirty;

  const onRefresh = useCallback(() => {
    if (hasChanges) {
      return;
    }
    dispatch(quotationTplActions.refreshQuotationTemplateConfig(true, onLoad));
  }, [dispatch, hasChanges, onLoad]);

  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => {
        return getString('quotation_tpl.changes.navigate_away_warn');
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [hasChanges]);

  const onCreateFromConstructionTemplate = useCallback(() => {
    setShowCreateFromConstructionModal(true);
  }, []);

  const onConfirmCreateFromConstructionTemplate = useCallback(async () => {
    const constructionTemplate =
      await constructionTemplateConfigService.getConstructionTemplateConfig();
    dispatch(
      quotationTplActions.applyChangesToTemplateConfig(draft => {
        draft.staged = constructionTemplateConfigToStaged(constructionTemplate);
      }),
    );
    setShowCreateFromConstructionModal(false);
  }, [dispatch]);

  const onCancelCreateFromConstructionTemplate = useCallback(() => {
    setShowCreateFromConstructionModal(false);
  }, []);

  return (
    <QuotationTemplateConfigProvider state={state}>
      <Prompt
        when={hasChanges}
        message={getString('quotation_tpl.changes.navigate_away_warn')}
      />
      <Page
        title={<Translate id="quotation_tpl.title" />}
        fullAccessRight={AclObjectList.VehicleQuotationTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleQuotationTemplateReadonlyAccess
        }
        onRefresh={onRefresh}
        breadcrumbs={breadcrumbs}
        noPadding
        compact={true}
        bodyClassName="quotation-tpl"
        headerComplement={
          <QuotationTemplateConfigContext.Consumer>
            {({ editable }) =>
              editable ? (
                <Actions
                  onCreateFromConstructionTemplate={
                    onCreateFromConstructionTemplate
                  }
                />
              ) : null
            }
          </QuotationTemplateConfigContext.Consumer>
        }
      >
        <Content />
      </Page>
      <CreateFromConstructionModal
        open={showCreateFromConstructionModal}
        onConfirm={onConfirmCreateFromConstructionTemplate}
        onCancel={onCancelCreateFromConstructionTemplate}
      />
    </QuotationTemplateConfigProvider>
  );
};
