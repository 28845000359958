/**
 * @file: OrgSubscribedUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OrgSubscribedUserServiceBase } from './base/OrgSubscribedUserService';

export class OrgSubscribedUserService extends OrgSubscribedUserServiceBase {
  // add custom service implementation here
}
