/**
 * @file: PaymentProviderId.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum PaymentProviderId {
  Wechat = 'wechat',
  Alipay = 'alipay',
}

export const PaymentProviderIdValueSet = new Set([
  PaymentProviderId.Wechat,
  PaymentProviderId.Alipay,
]);
