/**
 * @file: org-customers.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import {
  Customer,
  CustomerListFilter,
  ListResult,
  StoreCustomer,
  StoreCustomerListFilter,
} from 'model';
import { customerService, storeCustomerService } from 'services';

export const orgCustomerActions = createListAsyncActionCreators<
  AppState,
  Customer,
  CustomerListFilter,
  number
>('customers.org_customers', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  async fetchHandler(state: AppState) {
    if (state.customers.orgCustomers.filter?.storeId) {
      const customerFilter: Partial<CustomerListFilter> = {};
      if (state.customers.orgCustomers.filter) {
        for (const prop in state.customers.orgCustomers.filter) {
          if (prop === 'orgId' || prop === 'storeId') continue;
          if ((state.customers.orgCustomers.filter as any)[prop]) {
            (customerFilter as any)[prop] = (
              state.customers.orgCustomers.filter as any
            )[prop];
          }
        }
      }
      const filter: Partial<StoreCustomerListFilter> = {
        orgId: state.customers.orgCustomers.filter?.orgId,
        storeId: state.customers.orgCustomers.filter?.storeId,
        customerFilter,
      };
      const storeCustomers = (await storeCustomerService.list(
        filter,
        null,
        state.customers.orgCustomers.offset,
        state.customers.orgCustomers.limit,
        { count: true, recursive: true },
      )) as ListResult<StoreCustomer>;
      const customers: ListResult<Customer> = {
        total: storeCustomers.total,
        items: storeCustomers.items.map(
          x =>
            ({
              id: x.customerId,
              orgId: x.orgId,
              userId: x.customer?.userId,
              fullName: x.customer?.fullName,
              gender: x.customer?.gender,
              mobile: x.customer?.mobile,
              idCardNo: x.customer?.idCardNo,
              email: x.customer?.email,
              qq: x.customer?.qq,
              wechatId: x.customer?.wechatId,
              isVip: x.customer?.isVip,
              createdAt: x.customer?.createdAt,
              updatedAt: x.customer?.updatedAt,
            }) as Customer,
        ),
      };
      return customers;
    }
    return await customerService.list(
      state.customers.orgCustomers.filter,
      state.customers.orgCustomers.sorts?.[0],
      state.customers.orgCustomers.offset,
      state.customers.orgCustomers.limit,
      { count: true, recursive: true },
    );
  },
});
