/**
 * @file: CustomerService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { CustomerServiceBase } from './base/CustomerService';

export class CustomerService extends CustomerServiceBase {
  // add custom service implementation here
}
