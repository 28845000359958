/**
 * @file: inspection-subject.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import {
  VehicleInspectionSubject,
  VehicleInspectionSubjectListFilter,
} from 'model';
import { vehicleInspectionSubjectService } from 'services';

export const inspectionSubjectActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionSubject,
  VehicleInspectionSubjectListFilter,
  number
>('inspection.inspection_subjects', {
  shouldInvalidateForFilter: false,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.inspectionSubjects.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.inspectionSubjects.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.inspectionSubjects.itemsBeingDeleted,
  async fetchHandler(_state: AppState) {
    return await vehicleInspectionSubjectService.list(
      // state.inspection.inspectionSubjects.filter,
      {},
      // state.inspection.inspectionSubjects.sorts && state.inspection.inspectionSubjects.sorts[0],
      null,
      0,
      0,
      { count: false, recursive: false },
    );
  },
  async create(site: Partial<VehicleInspectionSubject>) {
    return await vehicleInspectionSubjectService.create(site);
  },
  async update(site: VehicleInspectionSubject) {
    return await vehicleInspectionSubjectService.update(site);
  },
  async delete(site: VehicleInspectionSubject) {
    await vehicleInspectionSubjectService.delete(site.id);
  },
  async deleteMulti(sites: VehicleInspectionSubject[]) {
    if (sites.length === 1) {
      await vehicleInspectionSubjectService.delete(sites[0].id);
      return;
    }
    throw new Error('delete multi inspection subjects is not supported. ');
  },
});
