/**
 * @file: move.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export function array_move<T = any>(
  arr: T[],
  old_index: number,
  new_index: number,
): void {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined as any);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export function array_moved<T = any>(
  arr: T[],
  old_index: number,
  new_index: number,
): T[] {
  arr = arr.slice();
  array_move(arr, old_index, new_index);
  return arr; // for testing
}
