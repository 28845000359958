/**
 * @file: TaskSchedulerStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum TaskSchedulerStatus {
  Disabled = 'disabled',
  Scheduled = 'scheduled',
  Started = 'started',
  Stopped = 'stopped',
  Finished = 'finished',
}

export const TaskSchedulerStatusValueSet = new Set([
  TaskSchedulerStatus.Disabled,
  TaskSchedulerStatus.Scheduled,
  TaskSchedulerStatus.Started,
  TaskSchedulerStatus.Stopped,
  TaskSchedulerStatus.Finished,
]);
