import { APIService } from 'lib/restful-client/api.service';

export class OpenApiService {
  constructor(private readonly api: APIService) {}

  async sendAppSecretEmail(appKey: string, email: string): Promise<void> {
    return void await this.api
      .post()
      .url('/openapi/send-appsecret-email')
      .data({ appKey, email })
      .future();
  }
}
