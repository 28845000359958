/**
 * @file: ProductAgentAccountTxStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductAgentAccountTxStatus } from 'model';

export const ProductAgentAccountTxStatusOptions: Array<
  Option<ProductAgentAccountTxStatus>
> = [
  {
    value: ProductAgentAccountTxStatus.Pending,
    label: 'product_agent_account_tx_status.pending',
  },
  {
    value: ProductAgentAccountTxStatus.PendingEntry,
    label: 'product_agent_account_tx_status.pending_entry',
  },
  {
    value: ProductAgentAccountTxStatus.DoneEntry,
    label: 'product_agent_account_tx_status.done_entry',
  },
  {
    value: ProductAgentAccountTxStatus.Closed,
    label: 'product_agent_account_tx_status.closed',
  },
  {
    value: ProductAgentAccountTxStatus.Finished,
    label: 'product_agent_account_tx_status.finished',
  },
];

export const ProductAgentAccountTxStatusOptionsWithDefault: Array<
  Option<ProductAgentAccountTxStatus | null>
> = [
  { value: null, label: 'product_agent_account_tx_status.__default__' },
  {
    value: ProductAgentAccountTxStatus.Pending,
    label: 'product_agent_account_tx_status.pending',
  },
  {
    value: ProductAgentAccountTxStatus.PendingEntry,
    label: 'product_agent_account_tx_status.pending_entry',
  },
  {
    value: ProductAgentAccountTxStatus.DoneEntry,
    label: 'product_agent_account_tx_status.done_entry',
  },
  {
    value: ProductAgentAccountTxStatus.Closed,
    label: 'product_agent_account_tx_status.closed',
  },
  {
    value: ProductAgentAccountTxStatus.Finished,
    label: 'product_agent_account_tx_status.finished',
  },
];
