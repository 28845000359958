import classNames from 'classnames';
import React, { ChangeEvent, Component, MouseEvent, ReactNode } from 'react';
import { Checkbox } from '../Checkbox';
import { Node } from './types';

const arrowImg: string = require('!!svg-url-loader!./arrow.svg');

interface TreeNodeProps {
  node: Node;
  onRenderNodeText?: (node: Node) => string | ReactNode;
  onClick: (node: Node) => 'toggle' | undefined;
  onExpand: (node: Node) => void;
  onCollapse: (node: Node) => void;
  onCheckChange?: (node: Node, checked: boolean) => void;
  onMouseEnter?: (node: Node, e: MouseEvent) => void;
  onMouseLeave?: (node: Node, e: MouseEvent) => void;
  onRenderNodeDecoratedView?: (
    node: Node,
  ) => ReactNode | string | null | undefined;
  onRenderEmptyPlaceholder?: (node: Node) => string | ReactNode;
  onRenderLoading?: (node: Node) => string | ReactNode;
}

export class TreeNode extends Component<TreeNodeProps> {
  private readonly nodeRef = React.createRef<HTMLDivElement>();
  private readonly childrenRef = React.createRef<HTMLDivElement>();
  private readonly arrowRef = React.createRef<HTMLAnchorElement>();
  private useTransitionEnd = false;

  render() {
    const { node, onRenderNodeText } = this.props;
    const { expanded, selected, children, checked } = node;
    return (
      <div
        ref={this.nodeRef}
        className={classNames('tree__node', node.className, {
          'tree__node--expanded': expanded,
          'tree__node--selected': selected,
        })}
      >
        <div className="tree__node-it">
          {children && (
            <a
              href="#"
              className="tree__arrow"
              onClick={this.onArrowClick}
              ref={this.arrowRef}
            >
              <img src={arrowImg} alt="" />
            </a>
          )}
          {checked !== undefined ? (
            <label className="m-checkbox tree__node-check">
              <Checkbox
                checked={checked === true}
                indeterminate={checked === 'intermediate'}
                onChange={this.onNodeCheckChange(node)}
              />
              <span />
            </label>
          ) : null}
          {this.renderIcon()}
          <div
            className="tree__node-text"
            onMouseEnter={this.onNodeTextMouseEnter}
            onMouseLeave={this.onNodeTextMouseLeave}
          >
            <a href="#" onClick={this.onNodeClick}>
              {(onRenderNodeText && onRenderNodeText(node)) || node.text}
            </a>
            {this.renderDecoratedView()}
          </div>
        </div>
        {this.renderChildren()}
      </div>
    );
  }

  renderIcon() {
    const { icon, iconCls } = this.props.node;
    if (iconCls) {
      return (
        <span className="tree__node-icon">
          <i className={iconCls} />
        </span>
      );
    }
    if (icon) {
      return (
        <span className="tree__node-icon">
          <img src={icon} alt="" />
        </span>
      );
    }
    return null;
  }

  renderDecoratedView() {
    const { node, onRenderNodeDecoratedView } = this.props;
    const decoratedView =
      onRenderNodeDecoratedView && onRenderNodeDecoratedView(node);
    if (!decoratedView) return null;
    return <div className="tree__node-decoration">{decoratedView}</div>;
  }

  renderChildren() {
    const { onRenderEmptyPlaceholder, onRenderLoading, node } = this.props;
    const { children, expanded, isLoading } = node;

    if (!children?.length && isLoading && expanded) {
      let loading: ReactNode | string | null = null;
      if (onRenderLoading) {
        loading = onRenderLoading(node);
      } else {
        loading = (
          <div>
            <i className="fa fa-spinner" />
            Loading...
          </div>
        );
      }
      return (
        <div
          ref={this.childrenRef}
          className={classNames('tree__children', 'tree__children--expanded')}
        >
          {loading}
        </div>
      );
    }

    if (!children) return null;

    let emptyView: ReactNode | null = null;
    if (!children.length && onRenderEmptyPlaceholder) {
      emptyView = onRenderEmptyPlaceholder(node);
    }
    return (
      <div
        ref={this.childrenRef}
        className={classNames('tree__children', {
          'tree__children--expanded': expanded,
          'tree__children--collapsed': !expanded,
        })}
      >
        {emptyView && <div className="tree__children-empty">{emptyView}</div>}
        {children.map(childNode => (
          <TreeNode
            key={childNode.id}
            node={childNode}
            onRenderNodeText={this.props.onRenderNodeText}
            onClick={this.props.onClick}
            onExpand={this.props.onExpand}
            onCollapse={this.props.onCollapse}
            onCheckChange={this.props.onCheckChange}
            onMouseEnter={this.props.onMouseEnter}
            onMouseLeave={this.props.onMouseLeave}
            onRenderNodeDecoratedView={this.props.onRenderNodeDecoratedView}
            onRenderEmptyPlaceholder={this.props.onRenderEmptyPlaceholder}
            onRenderLoading={this.props.onRenderLoading}
          />
        ))}
      </div>
    );
  }

  onNodeClick = (e: MouseEvent) => {
    e.preventDefault();
    if ('toggle' === this.props.onClick(this.props.node)) {
      this.onArrowClick(e as any);
    }
  };

  onNodeTextMouseEnter = (e: MouseEvent) => {
    this.props.onMouseEnter && this.props.onMouseEnter(this.props.node, e);
  };

  onNodeTextMouseLeave = (e: MouseEvent) => {
    this.props.onMouseLeave && this.props.onMouseLeave(this.props.node, e);
  };

  onNodeCheckChange(node: Node) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      this.props.onCheckChange &&
        this.props.onCheckChange(node, e.target.checked);
    };
  }

  onArrowClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const el = this.childrenRef.current;
    const arrowEl = this.arrowRef.current;
    const { node } = this.props;

    if (node.expanded) {
      if (arrowEl) {
        arrowEl.classList.add('tree__arrow--collapse-enter');
      }

      if (el) {
        this.useTransitionEnd = true;
        el.addEventListener('transitionend', this.onNodeChildrenTransitionEnd);
        if (el.scrollHeight === 0) {
          setTimeout(() => {
            this.onNodeChildrenTransitionEnd();
          }, 200);
        } else {
          el.style.height = el.scrollHeight + 'px';
          setTimeout(() => {
            if (el) {
              el.style.height = '0px';
            }
          });
        }
      } else {
        this.props.onCollapse(node);
      }
    } else {
      if (arrowEl) {
        arrowEl.classList.add('tree__arrow--expand-enter');
      }
      if (el) {
        this.useTransitionEnd = true;
        if (el.offsetHeight === el.scrollHeight) {
          setTimeout(() => {
            this.onNodeChildrenTransitionEnd();
          }, 200);
        } else {
          el.addEventListener(
            'transitionend',
            this.onNodeChildrenTransitionEnd,
          );
          el.style.height = el.scrollHeight + 'px';
        }
      } else {
        this.props.onExpand(node);
      }
    }
  };

  onNodeChildrenTransitionEnd = () => {
    if (!this.useTransitionEnd) return;

    this.useTransitionEnd = false;

    const { node } = this.props;
    const el = this.childrenRef.current;
    const arrowEl = this.arrowRef.current;

    if (el) {
      el.removeEventListener('transitionend', this.onNodeChildrenTransitionEnd);
      const style = el.style as any;
      if (node.expanded) {
        this.props.onCollapse(node);
        if (arrowEl) {
          arrowEl.classList.remove('tree__arrow--collapse-enter');
        }
      } else {
        this.props.onExpand(node);
        if (arrowEl) {
          arrowEl.classList.remove('tree__arrow--expand-enter');
        }
      }
      style.height = null;
    } else {
      if (node.expanded) {
        this.props.onCollapse(node);
      } else {
        this.props.onExpand(node);
      }
    }
  };
}
