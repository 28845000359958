/**
 * @file: ApprovalStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ApprovalStatus, Option } from 'model';

export const ApprovalStatusOptions: Array<Option<ApprovalStatus>> = [
  { value: ApprovalStatus.Pending, label: 'approval_status.pending' },
  { value: ApprovalStatus.Accepted, label: 'approval_status.accepted' },
  { value: ApprovalStatus.Rejected, label: 'approval_status.rejected' },
];

export const ApprovalStatusOptionsWithDefault: Array<
  Option<ApprovalStatus | null>
> = [
  { value: null, label: 'approval_status.__default__' },
  { value: ApprovalStatus.Pending, label: 'approval_status.pending' },
  { value: ApprovalStatus.Accepted, label: 'approval_status.accepted' },
  { value: ApprovalStatus.Rejected, label: 'approval_status.rejected' },
];
