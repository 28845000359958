import { Identity } from 'model';
import { Component, Fragment, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Menu, SideMenu } from 'shared/metronic/components';
import { MenuConfig } from './config';

interface Props extends RouteComponentProps<any> {
  identity?: Identity;
  showBrand?: boolean;
}

export class MainNavImpl extends Component<Props> {
  render() {
    const { location } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <>
            {this.renderBrand()}
            <SideMenu>
              {MenuConfig.map(section => (
                <Fragment key={section.key}>
                  {section.title && (
                    <Menu.Section
                      text={translate(section.title) as ReactNode}
                    />
                  )}
                  {section.items
                    .filter(x => x.hidden !== true)
                    .map(item => (
                      <Menu.Item
                        key={item.id}
                        text={translate(item.text) as ReactNode}
                        icon={item.icon}
                        className="text-nowrap"
                        href={item.href}
                        active={location.pathname === item.href}
                      />
                    ))}
                </Fragment>
              ))}
            </SideMenu>
          </>
        )}
      </Translate>
    );
  }

  private renderBrand() {
    if (!this.props.showBrand) return null;

    return (
      <div
        className="m-stack__item m-brand  m-brand--skin-dark"
        style={{ marginTop: -10 }}
      >
        <div className="m-stack m-stack--ver m-stack--general">
          <div className="m-stack__item m-stack__item--middle m-brand__logo">
            <Link
              to="/"
              className="m-brand__logo-wrapper"
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                whiteSpace: 'nowrap',
                textDecoration: 'none',
                color: '#898b96',
              }}
            >
              <Translate id="brand" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export const MainNav = withRouter(MainNavImpl);
