import classNames from 'classnames';
import { ColorType } from 'model';
import React, {
  Component,
  CSSProperties,
  HTMLProps,
  MouseEvent,
  ReactNode,
} from 'react';

interface State {
  isOpen?: boolean;
}

interface Props extends HTMLProps<HTMLDivElement> {
  text?: string | ReactNode;
  icon?: string;
  color?: ColorType | 'light';
  children: ReactNode;
  inline?: boolean;
  noArrow?: boolean;
  btnSize?: 'sm' | 'md' | 'lg';
  btnClassName?: string;
  btnStyle?: CSSProperties;
  dropup?: boolean;
  noToggle?: boolean;
  dropdownPush?: boolean;
  dropDownSize?: 'small' | 'medium' | 'large' | 'huge' | 'auto';
  dropDownStyle?: CSSProperties;
  dropdownContentStyle?: CSSProperties;
  dropdownAlign?: 'left' | 'center' | 'right';
  dropdownArrowPlacement?: 'left' | 'center' | 'right';
  dropdownArrowStyle?: CSSProperties;
  dropdownHeaderBgFill?: boolean;
  dropdownMobileFullWidth?: boolean;
  dropdownAlignPush?: boolean;
  dropdownSkin?: 'light';
  closeDropdownOnClick?: boolean;
  dropdownAlwaysShow?: boolean;
}

export class DropdownButton extends Component<Props, State> {
  private readonly elemRef = React.createRef<HTMLDivElement>();
  private readonly dropDownRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  close() {
    this.setState({ isOpen: false });
    document.removeEventListener('click', this.onDocumentClick);
  }

  render() {
    const {
      text,
      icon,
      color,
      children,
      inline,
      dropdownAlign,
      dropdownPush,
      dropup,
      className,
      noArrow,
      btnSize,
      btnClassName,
      btnStyle,
      noToggle,
      dropDownSize,
      dropDownStyle,
      dropdownArrowPlacement,
      dropdownArrowStyle,
      dropdownContentStyle,
      dropdownHeaderBgFill,
      dropdownMobileFullWidth,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dropdownAlignPush,
      dropdownSkin,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      closeDropdownOnClick,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dropdownAlwaysShow,
      ...props
    } = this.props;

    const arrow = children && (
      <span
        className={`m-dropdown__arrow m-dropdown__arrow--adjust m-dropdown__arrow--${dropdownArrowPlacement || 'left'}`}
        style={dropdownArrowStyle}
      />
    );

    const inner = children && (
      <div className="m-dropdown__inner">
        <div className="m-dropdown__body">
          <div className="m-dropdown__content" style={dropdownContentStyle}>
            {children}
          </div>
        </div>
      </div>
    );

    return (
      <div
        ref={this.elemRef}
        className={classNames(
          'm-dropdown',
          {
            'm-dropdown--up': dropup,
            'm-dropdown--inline': inline,
            'm-dropdown--arrow': !noArrow,
            [`m-dropdown--align-${dropdownAlign}`]: dropdownAlign,
            'm-dropdown--align-push': dropdownPush,
            [`m-dropdown--${dropDownSize}`]:
              dropDownSize && dropDownSize !== 'auto',
            'm-dropdown--header-bg-fill': dropdownHeaderBgFill,
            'm-dropdown--mobile-full-width': dropdownMobileFullWidth,
            'm-dropdown--skin-light': dropdownSkin === 'light',
            'm-dropdown--open': this.state.isOpen,
          },
          className,
        )}
        {...props}
      >
        <a
          href="#"
          onClick={this.onClick}
          style={btnStyle}
          className={classNames(
            'btn',
            {
              [`btn-${color}`]: color,
              [`btn-${btnSize}`]: btnSize,
              'm-dropdown--toggle': !noToggle,
              'dropdown-toggle': !noToggle,
            },
            btnClassName,
          )}
        >
          {icon && <i className={icon} />}
          {text}
        </a>
        {children && (
          <div
            className="m-dropdown__wrapper"
            ref={this.dropDownRef}
            style={Object.assign(
              {
                width: dropDownSize === 'auto' ? 'auto' : void 0,
              },
              dropDownStyle,
            )}
          >
            {dropup ? (
              <>
                {inner}
                {arrow}
              </>
            ) : (
              <>
                {arrow}
                {inner}
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  onClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen }, () => {
      if (this.state.isOpen) {
        document.addEventListener('click', this.onDocumentClick);
      } else {
        document.removeEventListener('click', this.onDocumentClick);
      }
    });
  };

  onDocumentClick = (e: Event) => {
    if (this.props.dropdownAlwaysShow) return;

    const target = e.target as HTMLElement;
    let elem: HTMLElement | null = target;
    while (elem && elem !== this.elemRef.current) {
      elem = elem.parentElement;
    }
    if (!elem) {
      this.setState({ isOpen: false });
      document.removeEventListener('click', this.onDocumentClick);
    } else if (this.dropDownRef.current) {
      elem = target;
      while (elem && elem !== this.dropDownRef.current) {
        elem = elem.parentElement;
      }
      if (elem && this.props.closeDropdownOnClick && !e.cancelBubble) {
        this.close();
      }
    }
  };
}
