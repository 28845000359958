import React, { MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { CSSProperties } from 'styled-components';
import { formatTime } from 'utils';
import { orderListActions, setOrderListActiveOrderId } from './duck/actions';
import {
  VehicleReceptionOrder,
  VehicleReceptionOrderListFilter,
  AclObjectList,
} from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  StringLabel,
  StorePicker,
  AsideRight,
  InspectionOrderTypeLabel,
  ReceptionOrderStatusLabel,
  InspectionOrderPriorityLabel,
  StoreName,
} from 'shared/components';
import { OrderDetailView } from './Detail';
import { VehicleReceptionOrders } from './duck/states';
import {
  ReceptionOrderStatusOptionsWithDefault,
  InspectionOrderTypeOptionsWithDefault,
} from 'model/EnumOptions';

import './index.scss';

interface Props
  extends EntityListProps<
    VehicleReceptionOrder,
    VehicleReceptionOrderListFilter
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  VehicleReceptionOrder,
  VehicleReceptionOrderListFilter,
  number,
  Props
>();

export const VehicleReceptionOrderList = componentClassBuilder
  .i18nPrefix('orders')
  .accessRights({
    full: AclObjectList.VehicleReceptionOrderFullAccess,
    readonly: AclObjectList.VehicleReceptionOrderReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="orders.breadcrumb.it" /> },
    { text: <Translate id="orders.breadcrumb.order_list" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.orders.orderList)
  .actions(orderListActions)
  .toolbarItems(builder => {
    builder
      .custom({
        prop: 'orgId',
        render: (filter, applyChanges) => {
          const onChange = (
            orgId: number | undefined,
            storeId: number | undefined,
          ) => {
            applyChanges(changes => {
              changes.orgId = orgId;
              changes.storeId = storeId;
            });
          };
          return (
            <div className="order-list-toolbar__store-picker-wrapper">
              <StorePicker
                orgId={filter.orgId}
                storeId={filter.storeId}
                onChange={onChange}
              />
            </div>
          );
        },
      })
      .text({
        prop: 'orderNo',
        label: 'orders.order_list.toolbar.label.order_no',
        placeholder: 'orders.order_list.toolbar.placeholder.order_no',
        width: 170,
      })
      .select({
        prop: 'status',
        width: 150,
        array: true,
        clearable: true,
        label: 'orders.order_list.toolbar.label.status',
        placeholder: 'orders.order_list.toolbar.placeholder.status',
        values: ReceptionOrderStatusOptionsWithDefault.map(x => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
      })
      .select({
        prop: 'orderType',
        width: 150,
        array: true,
        clearable: true,
        label: 'orders.order_list.toolbar.label.order_type',
        placeholder: 'orders.order_list.toolbar.placeholder.order_type',
        values: InspectionOrderTypeOptionsWithDefault.map(x => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(orderListActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'orderId',
      width: 100,
      align: 'center',
      text: '',
      render: (order: VehicleReceptionOrder) => {
        return (
          <img
            src={order.vehicleBrandLogo!}
            alt={order.vehicleBrandName!}
            style={{ width: 100 }}
          />
        );
      },
    },
    {
      prop: 'orderNo',
      width: 200,
      text: 'col.order_no',
      render: (order: VehicleReceptionOrder, props: Props) => {
        const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          const { dispatch } = props;
          dispatch(setOrderListActiveOrderId(order.id));
        };
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StoreName orgId={order.orgId} mode="org" />
            </div>
            <div style={style}>
              <StoreName storeId={order.storeId} mode="store" />
            </div>
            <div style={{ margin: '5px 0' }}>
              <a
                href="#"
                style={{ textDecoration: 'underline', color: '#222' }}
                onClick={onClick}
              >
                {order.orderNo}
              </a>
            </div>
            <div>
              <InspectionOrderTypeLabel value={order.orderType} />
            </div>
          </div>
        );
      },
    },
    {
      prop: 'vehicleName',
      width: 250,
      text: 'col.vehicle_info',
      render: entity => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="vehicle_label.name" />
              {entity.vehicleName}
            </div>
            <div style={style}>
              <StringLabel value="vehicle_label.brand" />
              {entity.vehicleBrandName}
            </div>
            <div style={style}>
              <StringLabel value="vehicle_label.mileage" />
              {entity.vehicleMileage! < 1
                ? entity.vehicleMileage! * 10000
                : entity.vehicleMileage}
              <StringLabel value="value_unit.km" />
            </div>
          </div>
        );
      },
    },
    {
      prop: 'customerName',
      width: 150,
      hidden: true,
      text: 'col.customer_name',
      render: entity => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="contact_label.name" />
              {entity.customerName}
            </div>
            <div style={style}>
              <StringLabel value="contact_label.mobile" />
              {entity.customerMobile}
            </div>
          </div>
        );
      },
    },
    {
      prop: 'status',
      width: 80,
      align: 'center',
      text: 'col.status',
      render: ({ status }) => <ReceptionOrderStatusLabel value={status} />,
    },
    {
      prop: 'priority',
      width: 80,
      align: 'center',
      text: 'orders.order_list.col.priority',
      render: ({ priority }) => (
        <InspectionOrderPriorityLabel value={priority} />
      ),
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 200,
      render: order => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="orders.detail.label.created_at" />
              {formatTime(order.createdAt)}
            </div>
            <div style={style}>
              <StringLabel value="orders.detail.label.started_at" />
              {order.startedAt ? formatTime(order.startedAt) : '-'}
            </div>
            <div style={style}>
              <StringLabel value="orders.detail.label.finished_at" />
              {order.finishedAt ? formatTime(order.finishedAt) : '-'}
            </div>
          </div>
        );
      },
    },
  ])
  .onRender((props: Props) => {
    const { dispatch } = props;
    const entities = props.entities as VehicleReceptionOrders;
    const onClose = () => {
      dispatch(setOrderListActiveOrderId(undefined));
    };
    const onDelete = (order: VehicleReceptionOrder) => {
      dispatch(setOrderListActiveOrderId(undefined));
      dispatch(orderListActions.itemsBeingDeleted([order]));
    };
    return (
      <AsideRight open={Boolean(entities.activeOrderId)} onClose={onClose}>
        {entities.result && entities.activeOrderId && (
          <OrderDetailView
            order={entities.result.find(x => x.id === entities.activeOrderId)}
            onDelete={onDelete}
          />
        )}
      </AsideRight>
    );
  })
  .getClass();
