/**
 * @file: ServiceDurationUnit.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ServiceDurationUnit {
  None = 0,
  Day = 1,
  Month = 2,
  Year = 3,
}

export const ServiceDurationUnitValueSet = new Set([
  ServiceDurationUnit.None,
  ServiceDurationUnit.Day,
  ServiceDurationUnit.Month,
  ServiceDurationUnit.Year,
]);
