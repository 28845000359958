import classNames from 'classnames';
import React, { Component } from 'react';
import './Dropdown.scss';
import { DropdownHeader } from './DropdownHeader';

interface Props {
  [name: string]: any;
  align?: 'left' | 'center' | 'right';
  title?: string;
  subTitle?: string;
  className?: string;
  children: any;
  noPadding?: boolean;
}

export class Dropdown extends Component<Props> {
  static Header = DropdownHeader;
  render() {
    const {
      align,
      title,
      subTitle,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      className,
      children,
      noPadding,
      ...props
    } = this.props;
    // find the dropdown header component.
    const childComponents = React.Children.toArray(children);
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let header: any;
    for (let i = childComponents.length - 1; i >= 0; i--) {
      const child = childComponents[i];
      if (
        typeof child !== 'string' &&
        typeof child !== 'number' &&
        typeof (child as any).type !== 'string' &&
        ((child as any).type as any).displayName === 'DropdownHeader'
      ) {
        header = child;
        childComponents.splice(i, 1);
      }
    }
    return (
      <>
        {header ||
          ((title || subTitle) && (
            <DropdownHeader title={title} align={align} subTitle={subTitle} />
          ))}
        <div
          className={classNames('m-dropdown__body', {
            'm-dropdown__body--paddingless': noPadding,
          })}
          {...props}
        >
          <div className="m-dropdown__content">
            <>{childComponents}</>
          </div>
        </div>
      </>
    );
  }
}
