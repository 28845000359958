/**
 * @file: UserRoleType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum UserRoleType {
  GeneralUser = 'general',
  GuestAgent = 'guest_agent',
}

export const UserRoleTypeValueSet = new Set([
  UserRoleType.GeneralUser,
  UserRoleType.GuestAgent,
]);
