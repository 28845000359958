import { Organization, Store } from 'model';
import { memo, useEffect, useState } from 'react';
import {
  getCachedOrgInfo,
  getCachedStoreInfo,
  queryOrgInfo,
  queryStoreInfo,
} from 'shared/utils';
import { CSSProperties } from 'styled-components';

export const StoreName = memo(
  ({
    orgId,
    storeId,
    mode,
    style,
    className,
  }: {
    orgId?: number | null;
    storeId?: number | null;
    mode?: 'org' | 'store' | 'default';
    style?: CSSProperties;
    className?: string;
  }) => {
    const [org, setOrg] = useState<Organization | null | undefined>(() =>
      orgId ? getCachedOrgInfo(orgId) : undefined,
    );
    const [store, setStore] = useState<Store | null | undefined>(() =>
      storeId ? getCachedStoreInfo(storeId) : undefined,
    );
    useEffect(() => {
      if (!orgId || mode === 'store' || getCachedOrgInfo(orgId)) {
        return;
      }

      queryOrgInfo(orgId)
        .then(setOrg)
        .catch(err => {
          console.error(err);
        });
    }, [mode, orgId]);

    useEffect(() => {
      if (!storeId || mode === 'org' || getCachedStoreInfo(storeId)) {
        return;
      }

      queryStoreInfo(storeId)
        .then(setStore)
        .catch(err => {
          console.error(err);
        });
    }, [mode, storeId]);

    if (
      (!org && !store) ||
      (mode === 'store' && !store) ||
      (mode === 'org' && !org)
    ) {
      return null;
    }

    if (mode === 'store') {
      return (
        <span style={style} className={className}>
          {store?.name}
        </span>
      );
    }

    if (mode === 'org') {
      return (
        <span style={style} className={className}>
          {org?.name}
        </span>
      );
    }

    return (
      <span style={style} className={className}>
        {store ? store.name : org?.name}
      </span>
    );
  },
);
