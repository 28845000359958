import React from 'react';
import { Translate } from 'react-localize-redux';
import { EntityListComponentClassBuilder, EntityListProps, getString } from 'shared/components';
import { AclObjectList, VehicleInspectionSubject, VehicleInspectionSubjectListFilter } from 'model';
import { inspectionSubjectActions } from '../duck/actions';
import { commonService } from 'services';

interface Props extends EntityListProps<VehicleInspectionSubject, VehicleInspectionSubjectListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
VehicleInspectionSubject,
VehicleInspectionSubjectListFilter,
  number,
  Props
  >();

export const InspectionSubjectList = componentClassBuilder
  .i18nPrefix('inspection_subject')
  .accessRights({
    full: AclObjectList.VehicleInspectionSubjectFullAccess,
    readonly: AclObjectList.VehicleInspectionSubjectReadonlyAccess
  })
  .breadcrumbs([
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.inspection_subjects" /> }
  ])
  .entities(state => state.inspection.inspectionSubjects)
  .actions(inspectionSubjectActions)
  .editor(builder => builder.uncontrolled().text({
    prop: 'name',
    label: 'inspection_subject.editor.label.name',
    placeholder: 'inspection_subject.editor.placeholder.name',
    onChange: async (changes, props: Props) => {
      const { dispatch } = props;
      const updatePyInitial = (pyInitial: string) => {
        if (props.entities.itemBeingCreated) {
          dispatch(inspectionSubjectActions.itemBeingCreatedChanged({ pyInitial }));
        } else {
          dispatch(inspectionSubjectActions.itemBeingUpdatedChanged({ pyInitial }));
        }
      };
      if (changes.name) {
        try {
          const pyInitial = await commonService.getPyInitial(changes.name);
          updatePyInitial(pyInitial);
        } catch (e) {
          console.error(e);
          updatePyInitial('');
        }
      } else {
        updatePyInitial('');
      }
    }
  }).text({
    prop: 'pyInitial',
    label: 'inspection_subject.editor.label.py_initial',
    placeholder: 'inspection_subject.editor.placeholder.py_initial'
  }))
  .toolbarItems(builder => builder.text({
    prop: 'name',
    label: 'inspection_subject.toolbar.label.keyword',
    placeholder: 'inspection_subject.toolbar.placeholder.keyword'
  }))
  .columns([{
    prop: 'name',
    width: 200,
    text: 'inspection_subject.col.name'
  }, {
    prop: 'pyInitial',
    width: 100,
    text: 'inspection_subject.col.py_initial'
  }]
)
.addActionButtons(['edit', 'remove'])
.filter((items, props) => {
  const keyword = props.entities.filter?.name?.trim();
  if (keyword) {
    return items.filter(x => x.name.includes(keyword) || x.pyInitial?.includes(keyword));
  }
  return items;
})
.validate(entity => {
  const name = entity.name?.trim();
  const pyInitial = entity.pyInitial?.trim();
  let errCode: string = '';
  if (!name) {
    errCode = 'name_required';
  } else if (!pyInitial) {
    errCode = 'py_initial_required';
  }
  if (errCode) {
    const errMsg = getString('inspection_subject.editor.error.' + errCode);
    throw new Error(errMsg);
  }
}).getClass();
