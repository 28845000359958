import classNames from 'classnames';
import React, { Component, CSSProperties, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  [name: string]: any;
  active?: boolean;
  className?: string;
  badge?: string | number;
  badgeDot?: boolean;
  badgeDotSmall?: boolean;
  badgeColor?: string;
  badgeRight?: string | number;
  text?: string | ReactNode;
  title?: string | ReactNode;
  titleCls?: string;
  icon?: string | ReactNode;
  href?: string;
  linkStyle?: CSSProperties;
  dropdownSize?: 'large' | 'medium' | 'small';
  dropdownAlign?: 'left' | 'center' | 'right';
  dropdownArrowPlacement?: 'left' | 'center' | 'right';
  dropdownArrowStyle?: CSSProperties;
  dropdownHeaderBgFill?: boolean;
  dropdownMobileFullWidth?: boolean;
  dropdownAlignPush?: boolean;
  dropdownSkin?: 'light';
  render?: () => ReactNode;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

interface State {
  isOpen?: boolean;
}

export class NavItem extends Component<Props, State> {
  private readonly elemRef = React.createRef<HTMLLIElement>();

  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    const {
      active,
      text,
      href,
      title,
      titleCls,
      className,
      badge,
      badgeDot,
      badgeDotSmall,
      badgeColor,
      badgeRight,
      icon,
      linkStyle,
      children,
      dropdownSize,
      dropdownAlign,
      dropdownArrowPlacement,
      dropdownArrowStyle,
      dropdownHeaderBgFill,
      dropdownMobileFullWidth,
      dropdownAlignPush,
      dropdownSkin,
      render,
      ...otherProps
    } = this.props;

    const hasDropdown = Boolean(children);

    const cls = [
      'm-nav__item',
      className,
      {
        'm-dropdown': hasDropdown,
        'm-dropdown--open': hasDropdown && this.state.isOpen,
        [`m-dropdown--${dropdownSize}`]: hasDropdown && dropdownSize,
        'm-dropdown--arrow': hasDropdown,
        [`m-dropdown--align-${dropdownAlign || 'center'}`]: hasDropdown,
        'm-dropdown--header-bg-fill': hasDropdown && dropdownHeaderBgFill,
        'm-dropdown--mobile-full-width': hasDropdown && dropdownMobileFullWidth,
        'm-dropdown--align-push': hasDropdown && dropdownAlignPush,
        'm-dropdown--skin-light': hasDropdown && dropdownSkin === 'light',
        'm-nav__item--active': active,
      },
    ];
    if (render) {
      return (
        <li
          ref={this.elemRef}
          className={classNames(cls)}
          {...otherProps}
          aria-expanded="true"
          onClick={this.onClick}
        >
          {render()}
        </li>
      );
    }
    return (
      <li
        ref={this.elemRef}
        className={classNames(cls)}
        {...otherProps}
        aria-expanded="true"
      >
        <Link
          to={href || '#'}
          className={classNames('m-nav__link', {
            'm-dropdown__toggle': hasDropdown,
          })}
          style={linkStyle}
          onClick={this.onClick}
        >
          {(badgeDot || badge) && (
            <span
              className={classNames('m-nav__link-badge m-badge', {
                'm-badge--dot': badgeDot,
                'm-badge--dot-small': badgeDot && badgeDotSmall,
                [`m-badge--${badgeColor || 'danger'}`]: true,
              })}
            >
              {badge && !badgeDot && badge}
            </span>
          )}

          {icon && (
            <span className="m-nav__link-icon">
              {typeof icon === 'string' ? <i className={icon} /> : icon}
            </span>
          )}

          {badgeRight ? (
            <span className="m-nav__link-title">
              <span className="m-nav__link-wrap">
                {text && <span className="m-nav__link-text">{text}</span>}
                <span className="m-nav__link-badge">
                  <span
                    className={classNames('m-badge', {
                      [`m-badge--${badgeColor || 'danger'}`]: true,
                    })}
                  >
                    {badgeRight}
                  </span>
                </span>
              </span>
            </span>
          ) : (
            <>
              {text && <span className="m-nav__link-text">{text}</span>}
              {title && (
                <span
                  className={classNames(
                    'm-nav__link-title',
                    'm-nav__link-text',
                    titleCls,
                  )}
                >
                  {title}
                </span>
              )}
            </>
          )}
        </Link>
        {children && (
          <div className="m-dropdown__wrapper">
            <span
              className={`m-dropdown__arrow m-dropdown__arrow--adjust m-dropdown__arrow--${dropdownArrowPlacement || 'center'}`}
              style={dropdownArrowStyle}
            />
            <div className="m-dropdown__inner">{children}</div>
          </div>
        )}
      </li>
    );
  }

  onClick = (e: any) => {
    const { href } = this.props;
    const currentTarget = e.currentTarget as HTMLElement;
    const target = e.target as HTMLElement;

    // detect if the click is from the drop down.
    let elem: HTMLElement | null = target;
    while (
      elem &&
      elem !== currentTarget &&
      !elem.classList.contains('m-dropdown__wrapper')
    ) {
      elem = elem.parentElement;
    }

    if (elem !== currentTarget) {
      return;
    }

    if (!href || href === '#') {
      e.preventDefault();
    }

    const hasDropdown = Boolean(this.props.children);

    if (hasDropdown) {
      this.setState({ isOpen: !this.state.isOpen }, () => {
        if (this.state.isOpen) {
          document.addEventListener('click', this.onDocumentClick);
        } else {
          document.removeEventListener('click', this.onDocumentClick);
        }
      });
    } else {
      this.props.onClick && this.props.onClick(e);
    }
  };

  onDocumentClick = (e: Event) => {
    const target = e.target as HTMLElement;
    let elem: HTMLElement | null = target;
    while (elem && elem !== this.elemRef.current) {
      elem = elem.parentElement;
    }
    if (!elem) {
      this.setState({ isOpen: false });
      document.removeEventListener('click', this.onDocumentClick);
    }
  };
}
