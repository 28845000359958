/**
 * @file: ServiceEnrollmentStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ServiceEnrollmentStatus } from 'model';

export const ServiceEnrollmentStatusOptions: Array<
  Option<ServiceEnrollmentStatus>
> = [
  {
    value: ServiceEnrollmentStatus.Active,
    label: 'service_enrollment_status.active',
  },
  {
    value: ServiceEnrollmentStatus.Finished,
    label: 'service_enrollment_status.finished',
  },
];

export const ServiceEnrollmentStatusOptionsWithDefault: Array<
  Option<ServiceEnrollmentStatus | null>
> = [
  { value: null, label: 'service_enrollment_status.__default__' },
  {
    value: ServiceEnrollmentStatus.Active,
    label: 'service_enrollment_status.active',
  },
  {
    value: ServiceEnrollmentStatus.Finished,
    label: 'service_enrollment_status.finished',
  },
];
