import { Component, ReactNode } from 'react';
import { PageContent } from './PageContent';
import { SubHeader } from './SubHeader';

export class Portal extends Component<{ children?: ReactNode }> {
  static Header = SubHeader;
  static Content = PageContent;

  render() {
    return <>{this.props.children}</>;
  }
}
