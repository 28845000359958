/**
 * @file: ServiceEditionVariantTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ServiceEditionVariantType } from 'model';

export const ServiceEditionVariantTypeOptions: Array<
  Option<ServiceEditionVariantType>
> = [
  {
    value: ServiceEditionVariantType.Basic,
    label: 'service_edition_variant_type.basic',
  },
  {
    value: ServiceEditionVariantType.Standard,
    label: 'service_edition_variant_type.standard',
  },
  {
    value: ServiceEditionVariantType.Premium,
    label: 'service_edition_variant_type.premium',
  },
];

export const ServiceEditionVariantTypeOptionsWithDefault: Array<
  Option<ServiceEditionVariantType | null>
> = [
  { value: null, label: 'service_edition_variant_type.__default__' },
  {
    value: ServiceEditionVariantType.Basic,
    label: 'service_edition_variant_type.basic',
  },
  {
    value: ServiceEditionVariantType.Standard,
    label: 'service_edition_variant_type.standard',
  },
  {
    value: ServiceEditionVariantType.Premium,
    label: 'service_edition_variant_type.premium',
  },
];
