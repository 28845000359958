import { setActiveProductAgentForTxHistory } from 'app/customers/duck/actions';
import { ProductAgents } from 'app/customers/duck/states';
import { AppState } from 'app/duck/states';
import {
  IdType,
  ListResult,
  ProductAgentAccountTransaction,
  ProductAgentAccountTxStatus,
  ProductAgentAccountTxType,
} from 'model';
import { memo, useCallback, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { productAgentService } from 'services';
import {
  AsideRight,
  ProductAgentAccountTxCategoryLabel,
  ProductAgentAccountTxStatusLabel,
  ProductAgentAccountTxTypeLabel,
  getString,
} from 'shared/components';
import { Column, DataTable } from 'shared/metronic/components';
import { formatTime } from 'utils';

export const TxHistory = memo(() => {
  const dispatch = useDispatch();

  const agents = useSelector<AppState, ProductAgents>(
    state => state.customers.productAgents,
  );
  const activeAgentIdForTxHistory = agents.activeAgentIdForTxHistory;
  const activeAgent = activeAgentIdForTxHistory
    ? agents.result?.find(
        x => String(x.id) === String(activeAgentIdForTxHistory),
      )
    : undefined;

  const onClose = useCallback(() => {
    dispatch(setActiveProductAgentForTxHistory(undefined));
  }, [dispatch]);

  return (
    <AsideRight
      open={activeAgentIdForTxHistory != null}
      onClose={onClose}
      width={window.innerWidth * 0.75}
    >
      <ul
        className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
        style={{ marginBottom: 15 }}
      >
        <li className="nav-item m-tabs__item">
          <a
            className="nav-link m-tabs__link active"
            style={{ fontWeight: 'bold', fontSize: '120%' }}
          >
            <Translate
              id="product_agent.tx_history.title"
              data={{ account_name: activeAgent?.name }}
            />
          </a>
        </li>
      </ul>
      {activeAgentIdForTxHistory ? (
        <Content
          key={activeAgentIdForTxHistory}
          agentId={activeAgentIdForTxHistory}
        />
      ) : null}
    </AsideRight>
  );
});

const Content = memo(({ agentId }: { agentId: IdType }) => {
  const [transactions, setTransactions] =
    useState<ListResult<ProductAgentAccountTransaction>>();
  const [error, setError] = useState<Error>();
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);

  const refresh = useCallback(() => {
    productAgentService
      .searchAccountTransactions(agentId, offset, limit)
      .then(setTransactions)
      .catch(setError);
  }, [agentId, limit, offset]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const confirmRechargeTx = useCallback(
    async (txId: string) => {
      if (
        confirm(
          getString('product_agent.tx_history.confirm_recharge_tx_confirm_msg'),
        )
      ) {
        try {
          await productAgentService.confirmAccountRechargeTx(agentId, txId);
          refresh();
        } catch (e) {
          alert(e.message);
        }
      }
    },
    [agentId, refresh],
  );

  const columns: Column<ProductAgentAccountTransaction>[] = [
    {
      prop: 'txId',
      align: 'left',
      text: getString('product_agent.col.tx.id'),
      width: 150,
    },
    {
      prop: 'txCategory',
      align: 'center',
      text: getString('product_agent.col.tx.category'),
      width: 70,
      render: ({ txCategory }) => (
        <ProductAgentAccountTxCategoryLabel value={txCategory} />
      ),
    },
    {
      prop: 'txType',
      align: 'center',
      text: getString('product_agent.col.tx.type'),
      width: 70,
      render: ({ txType }) => <ProductAgentAccountTxTypeLabel value={txType} />,
    },
    {
      prop: 'amount',
      align: 'right',
      text: getString('product_agent.col.tx.amount'),
      width: 70,
      render: ({ amount }) => (amount / 100).toFixed(2),
    },
    {
      prop: 'balance',
      align: 'right',
      text: getString('product_agent.col.tx.balance'),
      width: 70,
      render: ({ balance }) => (balance / 100).toFixed(2),
    },
    {
      prop: 'pendingBalance',
      align: 'right',
      text: getString('product_agent.col.tx.pending_balance'),
      width: 100,
      render: ({ pendingBalance }) => (pendingBalance / 100).toFixed(2),
    },
    {
      prop: 'status',
      align: 'center',
      text: getString('product_agent.col.tx.status'),
      width: 100,
      render: ({ status, txId, txType }) =>
        txType === ProductAgentAccountTxType.Recharge &&
        status === ProductAgentAccountTxStatus.PendingEntry ? (
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              void confirmRechargeTx(txId);
            }}
          >
            <ProductAgentAccountTxStatusLabel value={status} />
          </a>
        ) : (
          <ProductAgentAccountTxStatusLabel value={status} />
        ),
    },
    {
      prop: 'txCreateTime',
      align: 'center',
      text: getString('product_agent.col.tx.create_time'),
      width: 150,
      render: ({ txCreateTime }) => formatTime(txCreateTime),
    },
    {
      prop: 'txSuccessTime',
      align: 'center',
      text: getString('product_agent.col.tx.success_time'),
      width: 150,
      render: ({ txSuccessTime }) =>
        txSuccessTime ? formatTime(txSuccessTime) : '-',
    },
    {
      prop: 'txClosedAt',
      align: 'center',
      text: getString('product_agent.col.tx.closed_at'),
      width: 100,
      render: ({ txClosedAt }) => (txClosedAt ? formatTime(txClosedAt) : '-'),
    },
    {
      prop: 'closeReason',
      align: 'center',
      text: getString('product_agent.col.tx.close_reason'),
      width: 100,
      render: ({ closeReason }) => closeReason ?? '-',
    },
  ];

  return (
    <DataTable
      data={transactions?.items}
      error={error}
      columns={columns}
      idProp="id"
      selModel={'none'}
      limit={limit}
      onLimitChange={setLimit}
      offset={offset}
      onOffsetChange={setOffset}
      total={transactions?.total}
    />
  );
});
