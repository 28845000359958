import { fetchAclList } from 'app/duck/actions';
import { AclList, AppState } from 'app/duck/states';
import classNames from 'classnames';
import {
  AclObjectList,
  AdminUser,
  AdminUserAccessRights,
  AdminUserListFilter,
  AdminUserRole,
} from 'model';
import { AdminUserRoleOptions } from 'model/EnumOptions';
import { Translate } from 'react-localize-redux';
import {
  AdminUserRoleLabel,
  AsideRight,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { formatTime, loadAsyncState } from 'utils';
import { isEmail } from 'utils/validators';
import {
  adminUserActions,
  fetchAdminUserAcl,
  setCurrentAdminUserAcl,
  updateAdminUserAcl,
} from '../duck/actions';
import { AdminUsersAcl } from '../duck/states';
import { AclEditor } from './AclEditor';

interface Props extends EntityListProps<AdminUser, AdminUserListFilter> {
  acl: AclList;
  adminUsersAcl: AdminUsersAcl;
  currentAdminUserAcl: AdminUserAccessRights;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  AdminUser,
  AdminUserListFilter,
  number,
  Props
>();

export const AdminUserList = componentClassBuilder
  .i18nPrefix('admin_user')
  .accessRights({
    full: AclObjectList.AdminUserFullAccess,
    readonly: AclObjectList.AdminUserReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="settings.breadcrumb.it" /> },
    { text: <Translate id="settings.breadcrumb.admin_users" /> },
  ])
  .entities(state => state.settings.adminUsers)
  .actions(adminUserActions)
  .mapStateToProps(state => ({
    acl: state.acl,
    adminUsersAcl: state.settings.adminUsersAcl,
    currentAdminUserAcl: state.settings.adminUsers.currentAcl,
  }))
  .componentDidMount((state: AppState, props: Props) => {
    const { dispatch } = props;
    loadAsyncState(state.acl, () => dispatch(fetchAclList()));
  })
  .componentDidUpdate((props: Props, prevProps: Props) => {
    if (props.currentAdminUserAcl) {
      const { userId } = props.currentAdminUserAcl;
      if (
        !prevProps.adminUsersAcl.result![userId] &&
        props.adminUsersAcl.result![userId]
      ) {
        props.dispatch(
          setCurrentAdminUserAcl(
            userId,
            props.adminUsersAcl.result![userId].acl,
          ),
        );
      }
    }
  })
  .editor(builder =>
    builder
      .text({
        prop: 'userName',
        label: 'admin_user.editor.label.user_name',
        placeholder: 'admin_user.editor.placeholder.user_name',
        helpText: 'admin_user.editor.help_text.user_name',
        autocomplete: false,
        disabled: entity =>
          Boolean(entity.id && entity.role === AdminUserRole.SuperUsers),
      })
      .text({
        type: 'password',
        prop: 'password',
        label: 'admin_user.editor.label.password',
        placeholder: 'admin_user.editor.placeholder.password',
        helpText: 'admin_user.editor.help_text.password',
        autocomplete: false,
        disabled: entity =>
          Boolean(entity.id && entity.role === AdminUserRole.SuperUsers),
      })
      .checkbox({
        prop: 'isPasswordProvisional',
        label: 'admin_user.editor.label.is_password_provisional',
        disabled: entity =>
          Boolean(entity.id && entity.role === AdminUserRole.SuperUsers),
      })
      .text({
        prop: 'nick',
        label: 'admin_user.editor.label.nick',
        placeholder: 'admin_user.editor.placeholder.nick',
        autocomplete: false,
        disabled: entity =>
          Boolean(entity.id && entity.role === AdminUserRole.SuperUsers),
      })
      .text({
        prop: 'email',
        label: 'admin_user.editor.label.email',
        placeholder: 'admin_user.editor.placeholder.email',
        autocomplete: false,
        disabled: entity =>
          Boolean(entity.id && entity.role === AdminUserRole.SuperUsers),
      })
      .select({
        prop: 'role',
        options: AdminUserRoleOptions.filter(
          x => x.value !== AdminUserRole.SuperUsers,
        ),
        label: 'admin_user.editor.label.role',
        placeholder: 'admin_user.editor.placeholder.role',
        autocomplete: false,
        disabled: entity =>
          Boolean(entity.id && entity.role === AdminUserRole.SuperUsers),
      })
      .checkbox({
        prop: 'enabled',
        label: 'admin_user.editor.label.enabled',
        disabled: entity =>
          Boolean(entity.id && entity.role === AdminUserRole.SuperUsers),
      }),
  )
  .toolbarItems(builder =>
    builder
      .text({
        prop: 'userName',
        label: 'admin_user.toolbar.label.user_name',
        placeholder: 'admin_user.toolbar.label.user_name',
      })
      .text({
        prop: 'email',
        label: 'admin_user.editor.label.email',
        placeholder: 'admin_user.editor.label.email',
      })
      .select({
        prop: 'role',
        values: AdminUserRoleOptions.map((x: any) => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
        label: 'admin_user.editor.label.role',
        width: 200,
        placeholder: 'admin_user.editor.label.role',
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(adminUserActions.invalidate(true));
        },
      }),
  )
  .addActionButtons([
    'edit',
    {
      key: 'acl',
      icon: 'la la-unlock',
      rights: AclObjectList.AclFullAccess,
      onClick: (item: AdminUser, props: Props) => {
        const { dispatch } = props;
        const { adminUsersAcl } = props;
        const userId = item.id;
        if (!adminUsersAcl.result![userId]) {
          dispatch(fetchAdminUserAcl(userId));
          dispatch(setCurrentAdminUserAcl(userId, []));
        } else {
          dispatch(
            setCurrentAdminUserAcl(userId, adminUsersAcl.result![userId].acl),
          );
        }
      },
    },
    'remove',
  ])
  .columns([
    {
      prop: 'userName',
      width: 120,
      text: 'col.user_name',
      render: (user: AdminUser) => (
        <span className="d-flex align-items-center">
          <i
            className={classNames({
              'fa fa-fw fa-user-lock': user.role === AdminUserRole.SuperUsers,
              'fa fa-fw fa-user': user.role !== AdminUserRole.SuperUsers,
              'm--font-success': user.role === AdminUserRole.SuperUsers,
              'm--font-metal': user.role !== AdminUserRole.SuperUsers,
            })}
          />
          &nbsp;
          {user.userName}
        </span>
      ),
    },
    {
      prop: 'email',
      width: 200,
      text: 'col.email',
      render: (adminUser: AdminUser) => adminUser.email || '-',
    },
    {
      prop: 'nick',
      width: 120,
      text: 'col.nick',
      render: (adminUser: AdminUser) => adminUser.nick || '-',
    },
    {
      prop: 'role',
      width: 100,
      text: 'col.role',
      align: 'center',
      render: ({ role }) => <AdminUserRoleLabel value={role} />,
    },
    {
      prop: 'enabled',
      width: 80,
      text: 'col.enabled',
      align: 'center',
      render: ({ enabled }) => (
        <i
          className={classNames({
            'fa fa-user-check': enabled,
            'fa fa-user-times': !enabled,
            'm--font-success': enabled,
            'm--font-danger': !enabled,
          })}
        />
      ),
    },
    {
      prop: 'lastLoginAt',
      width: 150,
      text: 'admin_user.col.last_login_at',
      align: 'center',
      render: ({ lastLoginAt }) =>
        lastLoginAt ? formatTime(lastLoginAt) : '-',
    },
  ])
  .onAdd(user => {
    user.isPasswordProvisional = true;
    user.enabled = true;
  })
  .validate((entity, isCreating) => {
    const userName = entity.userName?.trim();
    const password = entity.password?.trim();
    const role = entity.role;
    const email = entity.email?.trim();

    let msg = '';

    if (!userName) {
      msg = 'user_name_required';
    } else if (!/^[a-z][a-z0-9_]{0,19}$/.test(userName)) {
      msg = 'invalid_user_name';
    } else if (isCreating && !password) {
      msg = 'password_required';
    } else if (isCreating && (password!.length < 5 || password!.length > 30)) {
      msg = 'password_length_error';
    } else if (!role) {
      msg = 'role_required';
    } else if (email && !isEmail(email)) {
      msg = 'invalid_email';
    }

    if (msg) {
      throw new Error(getString(`admin_user.editor.error.${msg}`));
    }
  })
  .onRender((props: Props) => {
    const { acl, currentAdminUserAcl } = props;

    const onAclAsideRightClose = () => {
      const { dispatch } = props;
      dispatch(setCurrentAdminUserAcl(null, []));
    };

    const onAclChange = (values: string[]) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { currentAdminUserAcl, dispatch } = props;
      if (!currentAdminUserAcl) return;
      const { userId } = currentAdminUserAcl;
      dispatch(setCurrentAdminUserAcl(userId, values));
    };

    const onSaveAcl = async () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { currentAdminUserAcl, dispatch } = props;
      if (!currentAdminUserAcl) return;
      dispatch(
        updateAdminUserAcl(
          currentAdminUserAcl.userId,
          currentAdminUserAcl,
          (err: Error) => {
            if (!err) {
              dispatch(setCurrentAdminUserAcl(null, []));
            }
          },
        ),
      );
    };

    const error = props.adminUsersAcl.lastUpdateError;
    const user = currentAdminUserAcl
      ? props.entities.result?.find(x => x.id === currentAdminUserAcl.userId)
      : null;

    return (
      <AsideRight
        open={Boolean(currentAdminUserAcl)}
        onClose={onAclAsideRightClose}
        width={350}
      >
        <AclEditor
          isSuperUser={Boolean(user && user.role === AdminUserRole.SuperUsers)}
          error={error}
          acl={acl.result || []}
          selected={currentAdminUserAcl?.acl || []}
          onChange={onAclChange}
          onSave={onSaveAcl}
        />
      </AsideRight>
    );
  })
  .getClass();
