import { Organization } from 'model';
import React, { memo, useEffect, useState } from 'react';
import { getCachedOrgInfo, queryOrgInfo } from 'shared/utils';
import { CSSProperties } from 'styled-components';

export const OrgInfoView = memo(
  ({
    orgId,
    style,
    className,
    children,
  }: {
    orgId: number;
    style?: CSSProperties;
    className?: string;
    children?: (org: Organization | null) => any;
  }) => {
    const [org, setOrg] = useState<Organization | null | undefined>(() =>
      orgId ? getCachedOrgInfo(orgId) : undefined,
    );
    useEffect(() => {
      if (getCachedOrgInfo(orgId)) {
        return;
      }

      queryOrgInfo(orgId)
        .then(setOrg)
        .catch(err => {
          console.error(err);
        });
    }, [orgId]);

    if (org === undefined) {
      return null;
    }

    if (children != null) {
      return children(org);
    }

    return (
      <span style={style} className={className}>
        {org?.name ?? ''}
      </span>
    );
  },
);
