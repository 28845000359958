/**
 * @file: configurations.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { SystemConfigurationItem } from 'model';
import { SystemConfigurations } from '../states';

const initialState: SystemConfigurations = {
  isLoading: false,
  result: null,
  error: null,
};

export default createAsyncListActionReducers<
  SystemConfigurationItem,
  SystemConfigurations
>('app.settings.configurations', initialState, {
  mapItemKey: (item: SystemConfigurationItem) => item.name,
});
