/**
 * @file: StoreMediaCategoryOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, StoreMediaCategory } from 'model';

export const StoreMediaCategoryOptions: Array<Option<StoreMediaCategory>> = [
  { value: StoreMediaCategory.Default, label: 'store_media_category.default' },
  {
    value: StoreMediaCategory.HeadImage,
    label: 'store_media_category.head_image',
  },
];

export const StoreMediaCategoryOptionsWithDefault: Array<
  Option<StoreMediaCategory | null>
> = [
  { value: null, label: 'store_media_category.__default__' },
  { value: StoreMediaCategory.Default, label: 'store_media_category.default' },
  {
    value: StoreMediaCategory.HeadImage,
    label: 'store_media_category.head_image',
  },
];
