/**
 * @file: VehicleSubscriptionSourceType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum VehicleSubscriptionSourceType {
  OfficialAccountQrcode = 1,
  OrderServiceQrcode = 2,
  OpenApi = 3,
}

export const VehicleSubscriptionSourceTypeValueSet = new Set([
  VehicleSubscriptionSourceType.OfficialAccountQrcode,
  VehicleSubscriptionSourceType.OrderServiceQrcode,
  VehicleSubscriptionSourceType.OpenApi,
]);
