/**
 * @file: org-customers.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { Customer } from 'model';
import { OrgCustomers } from '../states';

const initialState: OrgCustomers = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {}
};

export default createAsyncListActionReducers<Customer, OrgCustomers>(
  'customers.org_customers',
  initialState,
  {
    mapItemKey: x => x.id
  }
);