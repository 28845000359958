import classNames from 'classnames';
import { ColorType } from 'model';
import React, { ReactNode, HTMLProps } from 'react';

interface Props extends Omit<HTMLProps<HTMLElement>, 'value'> {
  value?: boolean;
  trueColor?: ColorType;
  falseColor?: ColorType;
  showFalseIcon?: boolean;
  falsePlaceholder?: ReactNode;
}

// import CheckIcon from '!@svgr/webpack!lib/metronic/assets/icons/svg/navigation/check.svg';

export const Checkmark = React.memo<Props>(({
  value,
  trueColor = 'success',
  falseColor = 'danger',
  showFalseIcon = false,
  falsePlaceholder = '-',
  ...props
}) => <>
  {value ? (
    <i className={classNames('fa fa-check-circle', {
      [`m--font-${trueColor}`]: trueColor
      })} {...props}
      />
    ) : (
    showFalseIcon ? (
      <i className={classNames('fa fa-minus-circle', {
        [`m--font-${falseColor}`]: falseColor
      })} {...props}
      />
     ) : falsePlaceholder
  )
}</>);