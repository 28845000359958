/**
 * @file: quotationPartCatalogs.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { QuotationPartCatalog } from 'model';
import { QuotationPartCatalogs } from '../states';
import { ActionTypes } from '../types';

const initialState: QuotationPartCatalogs = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
  isImporting: false,
};

const defaultReducer = createAsyncListActionReducers<
  QuotationPartCatalog,
  QuotationPartCatalogs
>('app.settings.quotation_part_catalogs', initialState, {
  mapItemKey: x => x.id,
});

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: QuotationPartCatalogs = initialState,
  action: StandardAction<any>,
): QuotationPartCatalogs {
  switch (action.type) {
    case ActionTypes.ImportQuotationPartCatalogs:
      return { ...state, isImporting: true, importError: undefined };
    case ActionTypes.ImportQuotationPartCatalogsSuccess: {
      return { ...state, isImporting: false, importError: undefined };
    }
    case ActionTypes.ImportQuotationPartCatalogsFailed: {
      return { ...state, isImporting: false, importError: action.payload };
    }
    default:
      return defaultReducer(state, action);
  }
}
