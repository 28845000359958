import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { StandardAction } from 'lib/duck/interfaces';
import { IdType, ProductAgent, ProductAgentListFilter } from 'model';
import { productAgentService } from 'services';
import { ActionTypes } from '../types';

export const productAgentActions = createListAsyncActionCreators<
  AppState,
  ProductAgent,
  ProductAgentListFilter,
  number
>('customers.product_agents', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.customers.productAgents.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.customers.productAgents.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.customers.productAgents.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await productAgentService.list(
      state.customers.productAgents.filter,
      state.customers.productAgents.sorts?.[0],
      state.customers.productAgents.offset,
      state.customers.productAgents.limit,
      { count: true, recursive: true },
    );
  },
  async create(agent: Partial<ProductAgent>) {
    return await productAgentService.create({
      ...agent,
      credit: Math.floor((agent.credit ?? 0) * 100),
    });
  },
  async update(agent: ProductAgent) {
    return await productAgentService.update({
      ...agent,
      credit: Math.floor((agent.credit ?? 0) * 100),
    });
  },
  async delete(agent: ProductAgent) {
    await productAgentService.delete(agent.id);
  },
  async deleteMulti(orgs: ProductAgent[]) {
    if (orgs.length === 1) {
      await productAgentService.delete(orgs[0].id);
      return;
    }
    throw new Error('delete multi productAgents is not supported. ');
  },
});

export function setActiveProductAgentForTxHistory(
  agentId: IdType | null | undefined,
): StandardAction<any> {
  return {
    type: ActionTypes.SetActiveProductAgentForTxHistory,
    payload: agentId,
  };
}
