/**
 * @file: SiteInspectionTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { SiteInspectionType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: SiteInspectionType | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

const Values = {
  [SiteInspectionType.Default]: 'default',
  [SiteInspectionType.Dashboard]: 'dashboard',
  [SiteInspectionType.Facade]: 'facade',
  [SiteInspectionType.AirConditioner]: 'air_conditioner',
};

export const SiteInspectionTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.SiteInspectionType?.[enumValue]
      ? LabelColors.SiteInspectionType[enumValue]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`site_inspection_type.${enumValue}`} />
    </Badge>
  );
});
