import React, { MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { agentActions } from '../duck/actions';
import { Agent, AgentListFilter, AclObjectList } from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  getString,
} from 'shared/components';
import { SendStatsRemind } from './SendStatsRemind';
import './index.scss';
import { agentService, organizationService } from 'services';
import { showAppLoading } from 'app/duck/actions';
import { isEmail } from 'utils/validators';

interface Props extends EntityListProps<Agent, AgentListFilter> {
  activeOrgForQrcodeDownloader: Agent | null | undefined;
}

type State = {
  agentToSendStatsRemind?: Agent;
};

const componentClassBuilder = new EntityListComponentClassBuilder<
  Agent,
  AgentListFilter,
  number,
  Props,
  State
>();

export const AgentList = componentClassBuilder
  .i18nPrefix('agent')
  .accessRights({
    full: AclObjectList.AgentFullAccess,
    readonly: AclObjectList.AgentReadonlyAccess,
  })
  .withInitialState({ agentToSendStatsRemind: undefined })
  .breadcrumbs([
    { text: <Translate id="customer.breadcrumb.it" /> },
    { text: <Translate id="customer.breadcrumb.agents" /> },
  ])
  .entities(state => state.customers.agents)
  .actions(agentActions)
  .requireAreas()
  .editor(builder =>
    builder
      .agent({
        prop: 'parentAgentId',
        label: 'agent.editor.label.parent_agent',
        placeholder: 'agent.editor.placeholder.parent_agent',
      })
      .reactSelect({
        prop: 'masterOrgId',
        label: 'agent.editor.label.master_org',
        placeholder: 'agent.editor.placeholder.master_org',
        valueProp: 'id',
        labelProp: 'name',
        async: true,
        stateId: 'agent-master-organizations',
        values: [],
        defaultValues: true,
        noOptionsMessage: ({ inputValue }) => {
          return getString(
            inputValue
              ? 'store.org_picker.no_values'
              : 'store.org_picker.no_values_hint',
          );
        },
        onLoadValues: async (keyword, entity) => {
          if (!keyword) {
            if (entity.masterOrgId) {
              const org = await organizationService.get(entity.masterOrgId);
              if (org != null) {
                return [{ id: org.id, name: org.name }];
              }
            }
            return [];
          }
          const listResult = await organizationService.list(
            { keyword },
            null,
            0,
            0,
          );
          return listResult as any;
        },
      })
      .text({
        prop: 'name',
        label: 'agent.editor.label.name',
        placeholder: 'agent.editor.placeholder.name',
        helpText: 'agent.editor.help_text.name',
      })
      .text({
        prop: 'code',
        label: 'agent.editor.label.code',
        placeholder: 'agent.editor.placeholder.code',
        helpText: 'agent.editor.help_text.code',
      })
      .text({
        prop: 'shortName',
        label: 'agent.editor.label.short_name',
        placeholder: 'agent.editor.placeholder.short_name',
        helpText: 'agent.editor.help_text.short_name',
      })
      .area({
        label: 'agent.editor.label.city',
      })
      .text({
        prop: 'servicePhone',
        label: 'agent.editor.label.service_phone',
        placeholder: 'agent.editor.placeholder.service_phone',
      })
      .text({
        prop: 'notificationEmail',
        label: 'agent.editor.label.notification_email',
        placeholder: 'agent.editor.placeholder.notification_email',
        helpText: 'agent.editor.help_text.notification_email',
      })
      .text({
        prop: 'address',
        label: 'agent.editor.label.address',
        placeholder: 'agent.editor.placeholder.address',
      })
      .text({
        prop: 'website',
        label: 'agent.editor.label.website',
        placeholder: 'agent.editor.placeholder.website',
      })
      .text({
        prop: 'appDownloadUrl',
        label: 'agent.editor.label.app_download_url',
        placeholder: 'agent.editor.placeholder.app_download_url',
        helpText: 'agent.editor.help_text.app_download_url',
      })
      .image({
        prop: 'logoUrl',
        label: 'agent.editor.label.logo',
        filePicker: {
          cover: false,
          realm: 'agent/logos',
          accept: 'image/png, image/jpeg',
        },
        helpText: 'agent.editor.help_text.logo',
      })
      .image({
        prop: 'appIconUrl',
        label: 'agent.editor.label.app_icon',
        filePicker: {
          cover: false,
          realm: 'agent/appicons',
          accept: 'image/png, image/jpeg',
        },
        helpText: 'agent.editor.help_text.app_icon',
      })
      .image({
        prop: 'launchImageUrl',
        label: 'agent.editor.label.launch_image',
        filePicker: {
          cover: false,
          realm: 'agent/logos',
          accept: 'image/png, image/jpeg',
        },
        helpText: 'agent.editor.help_text.launch_image',
      })
      .text({
        prop: 'contactName',
        label: 'agent.editor.label.contact.name',
        placeholder: 'agent.editor.placeholder.contact.name',
      })
      .text({
        prop: 'contactPhone',
        label: 'agent.editor.label.contact.phone',
        placeholder: 'agent.editor.placeholder.contact.phone',
      })
      .textArea({
        prop: 'remark',
        label: 'agent.editor.label.description',
        placeholder: 'agent.editor.placeholder.description',
      }),
  )
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'name',
        label: 'agent.toolbar.label.name',
        placeholder: 'agent.toolbar.placeholder.name',
        width: 250,
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(agentActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'logoUrl',
      width: 100,
      text: 'agent.col.logo',
      render: ({ logoUrl }) => (
        <img
          className="agent-info__logo"
          style={{ width: 100, height: 100 }}
          src={logoUrl || '/img/org-placeholder.png'}
        />
      ),
    },
    {
      prop: 'name',
      width: 250,
      text: 'agent.col.name',
      render(item, extra: Props) {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          extra.dispatch(agentActions.itemBeingUpdated(item));
        };
        const renderInfo = (label: string, value: any) =>
          value == null ? null : (
            <dl>
              <dt>
                <Translate id={`agent.info.label.${label}`} />
              </dt>
              <dd>{value}</dd>
            </dl>
          );

        return (
          <div className="agent-info__detail">
            <p>
              <a href="#" onClick={onClick}>
                <strong>{item.name}</strong>
              </a>
            </p>
            {renderInfo('code', item.code)}
            {renderInfo('short_name', item.shortName)}
            {renderInfo('service_phone', item.servicePhone)}
            {renderInfo('contact', item.contactName)}
            {renderInfo('contact_phone', item.contactPhone)}
            {renderInfo(
              'website',
              item.website ? (
                <a href={item.website} target="_blank" rel="noreferrer">
                  {item.website}
                </a>
              ) : null,
            )}
          </div>
        );
      },
    },
    {
      prop: 'address',
      width: 200,
      text: 'col.address',
      render: (entity, props: Props) => {
        if (!entity.provinceId) return '-';
        if (!props.areas?.result || props.areas.isLoading) {
          return '...';
        }
        const province = props.areas.result.getNodeById(entity.provinceId);
        const city = props.areas.result.getNodeById(entity.cityId!);
        const district = props.areas.result.getNodeById(entity.districtId!);
        const parts: string[] = [];
        if (province.children.length > 1) {
          parts.push(province.area.name);
        }
        parts.push(city.area.name, district.area.name);
        return (
          <div>
            <p style={{ marginBottom: 4 }}>{parts.join('/')}</p>
            <p>{entity.address}</p>
          </div>
        );
      },
    },
    {
      prop: 'notificationEmail',
      width: 200,
      text: 'agent.col.notification_email',
      render: ({ notificationEmail }) =>
        notificationEmail ? (
          <span
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {notificationEmail
              ?.split(/[,，]/g)
              .map(x => x.trim())
              .filter(x => x)
              .join(', ')}
          </span>
        ) : (
          <span className="text-danger">
            (<Translate id="agent.info.notification_email_not_set" />)
          </span>
        ),
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons([
    'edit',
    {
      key: 'stats-remind',
      icon: 'la la-pie-chart',
      tooltip: 'store.send_stats_remind.tooltip',
      onClick: (item, _, component) => {
        component.setState({
          agentToSendStatsRemind: item,
        });
      },
    },
    'remove',
  ])
  .validate(entity => {
    const masterOrgId = entity.masterOrgId;
    const name = entity.name?.trim();
    const shortName = entity.shortName?.trim();
    const address = entity.address?.trim();
    const servicePhone = entity.servicePhone?.trim();
    const code = entity.code?.trim();
    const notificationEmails = (entity.notificationEmail?.trim() ?? '')
      .split(/[,，]/g)
      .map(x => x.trim())
      .filter(x => x);

    if (!masterOrgId) {
      throw new Error(getString('agent.editor.error.master_org_required'));
    }
    if (!name) {
      throw new Error(getString('agent.editor.error.name_required'));
    }
    if (!shortName) {
      throw new Error(getString('agent.editor.error.short_name_required'));
    }
    if (!address) {
      throw new Error(getString('agent.editor.error.address_required'));
    }
    if (!servicePhone) {
      throw new Error(getString('agent.editor.error.service_phone_required'));
    }
    if (!entity.provinceId || !entity.cityId || !entity.districtId) {
      throw new Error(getString('agent.editor.error.city_required'));
    }
    if (!notificationEmails.length) {
      throw new Error(
        getString('agent.editor.error.notification_email_required'),
      );
    }
    if (notificationEmails.some(x => !isEmail(x))) {
      throw new Error(
        getString('agent.editor.error.invalid_notification_email'),
      );
    }
    if (!code) {
      throw new Error(getString('agent.editor.error.code_required'));
    }
    if (!/^[a-z0-9]{4,}$/i.test(code)) {
      throw new Error(getString('agent.editor.error.invalid_code'));
    }
  })
  .onRender((props, component) => {
    const agent = component.state.agentToSendStatsRemind;
    const onSend = async (targetUserIds: number[]) => {
      if (agent == null) return;
      component.setState({ agentToSendStatsRemind: undefined });
      props.dispatch(
        showAppLoading({
          message: getString('agent.send_stats_remind.loading'),
        }),
      );
      agentService
        .sendStoreStatsRemind(
          agent.id,
          targetUserIds.length ? targetUserIds : undefined,
        )
        .then(() => {
          props.dispatch(
            showAppLoading({
              message: getString('agent.send_stats_remind.success'),
              status: 'success',
              timeout: 5000,
            }),
          );
        })
        .catch(err => {
          console.error(err);
          props.dispatch(
            showAppLoading({
              message:
                getString('agent.send_stats_remind.failed') +
                ` (${err.message})`,
              status: 'error',
              timeout: 5000,
            }),
          );
        });
    };
    return (
      <SendStatsRemind
        agent={agent}
        onSend={onSend}
        onClose={() =>
          component.setState({ agentToSendStatsRemind: undefined })
        }
      />
    );
  })
  .getClass();
