/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ListResult, SortDirection } from 'lib';
import {
  ActionThunk,
  AsyncListState,
  DeleteCallback,
  DispatchFn,
  FetchCallback,
  ObjectKeyType,
  StandardAction,
} from 'lib/duck/interfaces';
import {
  autoActionType,
  createAsyncActionCreators,
  createStandardAction,
} from '../base';
import {
  ACTIVE_LIST_GROUP_KEY_CHANGED,
  CLEAR_SELECTION,
  COLLAPSE_ALL_ITEM_DETAIL,
  COLLAPSE_ALL_LIST_GROUP,
  COLLAPSE_ITEM_DETAIL,
  COLLAPSE_LIST_GROUP,
  COLLAPSE_LIST_ITEM_NODE,
  CREATE_ITEM,
  CREATE_ITEM_FAILED,
  CREATE_ITEM_SUCCESS,
  DELETE_ITEM,
  DELETE_ITEM_FAILED,
  DELETE_ITEM_SUCCESS,
  DELETE_MULTI,
  DELETE_MULTI_FAILED,
  DELETE_MULTI_SUCCESS,
  EXPAND_ALL_ITEM_DETAIL,
  EXPAND_ALL_LIST_GROUP,
  EXPAND_ITEM_DETAIL,
  EXPAND_LIST_GROUP,
  EXPAND_LIST_ITEM_NODE,
  INVALIDATE,
  ITEM_BEING_CREATED,
  ITEM_BEING_CREATED_CANCEL,
  ITEM_BEING_CREATED_CHANGED,
  ITEM_BEING_CREATED_COMMIT,
  ITEM_BEING_UPDATED,
  ITEM_BEING_UPDATED_CANCEL,
  ITEM_BEING_UPDATED_CHANGED,
  ITEM_BEING_UPDATED_COMMIT,
  ITEM_DESELECTED,
  ITEM_SELECTED,
  ITEMS_BEING_DELETED,
  ITEMS_BEING_DELETED_CANCEL,
  ITEMS_BEING_DELETED_COMMIT,
  LOAD_MORE,
  LOAD_MORE_FAILED,
  LOAD_MORE_SUCCESS,
  REMOVE_SORT_PROPERTY,
  SET_SORT_PROPERTY,
  TOGGLE_ALL_SELECTION,
  TOGGLE_SORT_PROPERTY,
  UPDATE_FILTER,
  UPDATE_ITEM,
  UPDATE_ITEM_FAILED,
  UPDATE_ITEM_SUCCESS,
  UPDATE_LIMIT,
  UPDATE_OFFSET,
  UPDATE_SELECTION,
  UPDATE_TOTAL,
} from '../constants';
import { createObjectResultAction, ObjectResultAction } from '../object';
import {
  AsyncListActionCreators,
  AsyncListActionCreatorsOptions,
  ListResultAction,
  ListResultActionPayload,
} from './interfaces';

export * from './interfaces';

export function createListResultAction<T>(
  type: string,
  error: Error | null,
  result?: T[] | null,
): ListResultAction<T> {
  return createStandardAction<ListResultActionPayload<T>>(type, {
    error,
    result,
  });
}

export function createListAsyncActionCreators<
  TAppState,
  T,
  TFilter = any,
  TKey extends ObjectKeyType = any,
>(
  scope: string,
  options: AsyncListActionCreatorsOptions<TAppState, T, TFilter, TKey>,
): AsyncListActionCreators<TAppState, T, TFilter> {
  const { fetchHandler } = options;

  const creators = createAsyncActionCreators<
    TAppState,
    T[] | ListResult<T>,
    ListResultAction<T>
  >(
    scope,
    Object.assign({}, options, {
      shouldCacheResult(state: TAppState) {
        if (!options.getState) return false;
        const asyncListState: AsyncListState<T> = options.getState(state);
        if (!asyncListState.offset || !asyncListState.limit) return true;
        return false;
      },
    }),
    // handle list result specically.
    (
      dispatch: DispatchFn<TAppState>,
      result: T[] | ListResult<T>,
      callback: (data: any) => void,
    ) => {
      if (Array.isArray(result)) {
        callback(result);
      } else {
        dispatch(totalUpdated(result.total));
        callback(result.items);
      }
    },
    (reset: boolean, fetch: (...args: any[]) => ActionThunk<TAppState>) => {
      return (dispatch: DispatchFn<TAppState>, getState: () => TAppState) => {
        dispatch(
          createStandardAction(autoActionType(scope, INVALIDATE), reset),
        );
        const state: TAppState = getState();
        dispatch(fetch(state));
      };
    },
  );

  function loadMore(): StandardAction<any> {
    return createStandardAction(autoActionType(scope, LOAD_MORE));
  }

  function loadMoreSuccess(result: T[]): ListResultAction<T> {
    return createListResultAction<T>(
      autoActionType(scope, LOAD_MORE_SUCCESS),
      null,
      result,
    );
  }

  function loadMoreFailed(error: Error): ListResultAction<T> {
    return createListResultAction<T>(
      autoActionType(scope, LOAD_MORE_FAILED),
      error,
    );
  }

  function fetchMore(...args: any[]): ActionThunk<TAppState> {
    if (!options.getState) {
      throw new Error('options.getState is required for fetchMore action. ');
    }
    return (dispatch, getState) => {
      let state: TAppState = getState();

      const asyncListState = options.getState!(state) as AsyncListState<T>;
      if (!asyncListState.hasMore) return;

      dispatch(loadMore());

      state = getState();

      args = [state, ...args];
      // eslint-disable-next-line prefer-spread
      fetchHandler
        .apply(null, args)
        .then((result: T[] | ListResult<T>) => {
          if (Array.isArray(result)) {
            dispatch(loadMoreSuccess(result));
          } else {
            dispatch(totalUpdated(result.total));
            dispatch(loadMoreSuccess(result.items));
          }
        })
        .catch((err: Error) => {
          // todo: probably a bug here?
          dispatch(creators.loadFailed(err));
        });
    };
  }

  function totalUpdated(total: number): StandardAction<number> {
    return createStandardAction<number>(
      autoActionType(scope, UPDATE_TOTAL),
      total,
    );
  }

  function offsetUpdated(offset: number): StandardAction<number> {
    return createStandardAction<number>(
      autoActionType(scope, UPDATE_OFFSET),
      offset,
    );
  }

  function limitUpdated(limit: number): StandardAction<number> {
    return createStandardAction<number>(
      autoActionType(scope, UPDATE_LIMIT),
      limit,
    );
  }

  function updateDataOffset(offset: number): ActionThunk<TAppState> {
    return dispatch => {
      dispatch(offsetUpdated(offset));
      dispatch(creators.fetch());
    };
  }

  function toggleSortProperty(
    property: string,
    defaultDir: SortDirection,
  ): ActionThunk<TAppState> {
    return dispatch => {
      const action = createStandardAction(
        autoActionType(scope, TOGGLE_SORT_PROPERTY),
        { property, dir: defaultDir },
      );
      dispatch(action);
      dispatch(creators.invalidate(true));
    };
  }

  function removeSortProperty(property: string): ActionThunk<TAppState> {
    return dispatch => {
      const action = createStandardAction(
        autoActionType(scope, REMOVE_SORT_PROPERTY),
        property,
      );
      dispatch(action);
      dispatch(creators.invalidate(true));
    };
  }

  function setSortProperty(
    property: string,
    dir: SortDirection,
  ): ActionThunk<TAppState> {
    return dispatch => {
      const action = createStandardAction(
        autoActionType(scope, SET_SORT_PROPERTY),
        { property, dir },
      );
      dispatch(action);
      dispatch(creators.invalidate(true));
    };
  }

  function updateSelection(selection: T[]): StandardAction<TKey[]> {
    if (!options.mapItemKey) {
      throw new Error('options.mapItemKey required');
    }
    return createStandardAction(
      autoActionType(scope, UPDATE_SELECTION),
      selection.map(x => options.mapItemKey!(x)),
    );
  }

  function itemSelected(item: T): StandardAction<T> {
    return createStandardAction(autoActionType(scope, ITEM_SELECTED), item);
  }

  function itemDeselected(item: T): StandardAction<T> {
    return createStandardAction(autoActionType(scope, ITEM_DESELECTED), item);
  }

  function toggleAllSelection(
    buildVisibleItems?: () => T[],
  ): StandardAction<any> {
    return createStandardAction(
      autoActionType(scope, TOGGLE_ALL_SELECTION),
      buildVisibleItems,
    );
  }

  function clearSelection(): StandardAction<void> {
    return createStandardAction(autoActionType(scope, CLEAR_SELECTION));
  }

  function expandListItemNode(item: T): StandardAction<T> {
    return createStandardAction(
      autoActionType(scope, EXPAND_LIST_ITEM_NODE),
      item,
    );
  }

  function collapseListItemNode(item: T): StandardAction<T> {
    return createStandardAction(
      autoActionType(scope, COLLAPSE_LIST_ITEM_NODE),
      item,
    );
  }

  function activeListGroupKeyChanged(
    key: any,
    context?: any,
  ): ActionThunk<TAppState> {
    return dispatch => {
      dispatch(clearSelection());
      dispatch(
        createStandardAction(
          autoActionType(scope, ACTIVE_LIST_GROUP_KEY_CHANGED),
          { key, context },
        ),
      );
    };
  }

  function expandListGroup(value: any): StandardAction<any> {
    return createStandardAction(
      autoActionType(scope, EXPAND_LIST_GROUP),
      value,
    );
  }

  function collapseListGroup(value: any): StandardAction<any> {
    return createStandardAction(
      autoActionType(scope, COLLAPSE_LIST_GROUP),
      value,
    );
  }

  function expandAllListGroup(): ActionThunk<TAppState> {
    const buildAllGroupValues = options.buildAllGroupValues;
    if (!buildAllGroupValues) {
      throw new Error('missing listActions.options.buildAllGroupValues');
    }
    return (dispatch, getState) => {
      const state = getState();
      const action = createStandardAction(
        autoActionType(scope, EXPAND_ALL_LIST_GROUP),
        {
          buildAllGroupValues: (key: any, context: any) => {
            return buildAllGroupValues(state, key, context);
          },
        },
      );
      dispatch(action);
    };
  }

  function collapseAllListGroup(): ActionThunk<TAppState> {
    const buildAllGroupValues = options.buildAllGroupValues;
    if (!buildAllGroupValues) {
      throw new Error('missing listActions.options.buildAllGroupValues');
    }
    return (dispatch, getState) => {
      const state = getState();
      const action = createStandardAction(
        autoActionType(scope, COLLAPSE_ALL_LIST_GROUP),
        {
          buildAllGroupValues: (key: any, context: any) => {
            return buildAllGroupValues(state, key, context);
          },
        },
      );
      dispatch(action);
    };
  }

  function expandItemDetail(item: T, key?: any): StandardAction<any> {
    return createStandardAction(autoActionType(scope, EXPAND_ITEM_DETAIL), {
      key,
      item,
    });
  }

  function collapseItemDetail(item: T, key?: any): StandardAction<any> {
    return createStandardAction(autoActionType(scope, COLLAPSE_ITEM_DETAIL), {
      key,
      item,
    });
  }

  function expandAllItemDetail(
    key?: any,
    buildVisibleItems?: () => T[],
  ): ActionThunk<TAppState> {
    return (dispatch, getState) => {
      let visibleItemsBuilder = buildVisibleItems;
      if (!visibleItemsBuilder) {
        if (!options.getAllVisibleItems) {
          throw new Error('missing listActions.options.getAllVisibleItems');
        }
        visibleItemsBuilder = () => {
          const state = getState();
          return options.getAllVisibleItems!(state, key);
        };
      }
      const action = createStandardAction(
        autoActionType(scope, EXPAND_ALL_ITEM_DETAIL),
        { key, visibleItemsBuilder },
      );
      dispatch(action);
    };
  }

  function collapseAllItemDetail(
    key?: any,
    buildVisibleItems?: () => T[],
  ): ActionThunk<TAppState> {
    return (dispatch, getState) => {
      let visibleItemsBuilder = buildVisibleItems;
      if (!visibleItemsBuilder) {
        if (!options.getAllVisibleItems) {
          throw new Error('missing listActions.options.getAllVisibleItems');
        }
        visibleItemsBuilder = () => {
          const state = getState();
          return options.getAllVisibleItems!(state, key);
        };
      }
      const action = createStandardAction(
        autoActionType(scope, COLLAPSE_ALL_ITEM_DETAIL),
        { key, visibleItemsBuilder },
      );
      dispatch(action);
    };
  }

  function updateDataLimit(limit: number): ActionThunk<TAppState> {
    return dispatch => {
      dispatch(limitUpdated(limit));
      dispatch(creators.fetch());
    };
  }

  // create
  function create(item: Partial<T>): StandardAction<Partial<T>> {
    return createStandardAction(autoActionType(scope, CREATE_ITEM), item);
  }

  function createSuccess(
    item: Partial<T>,
    result: T,
  ): ObjectResultAction<T, Partial<T>> {
    return createObjectResultAction(
      autoActionType(scope, CREATE_ITEM_SUCCESS),
      null,
      result,
      item,
    );
  }

  function createFailed(
    item: Partial<T>,
    error: Error,
  ): ObjectResultAction<T, Partial<T>> {
    return createObjectResultAction<T, Partial<T>>(
      autoActionType(scope, CREATE_ITEM_FAILED),
      error,
      null,
      item,
    );
  }

  function requestCreate(
    item: Partial<T>,
    ...args: any[]
  ): ActionThunk<TAppState> {
    let callback: FetchCallback<T> | null = null;
    if (typeof args[args.length - 1] === 'function') {
      callback = args[args.length - 1];
    }
    return dispatch => {
      if (!options.create) {
        throw new Error(`${scope} options.create is required. `);
      }
      dispatch(create(item));
      options.create
        .apply(null, [item, ...args])
        .then((result: T) => {
          callback && callback(null, result);
          dispatch(createSuccess(item, result));
        })
        .catch((err: Error) => {
          callback && callback(err, undefined);
          dispatch(createFailed(item, err));
        });
    };
  }

  // update
  function update(item: T): StandardAction<T> {
    return createStandardAction(autoActionType(scope, UPDATE_ITEM), item);
  }

  function updateSuccess(item: T, result: T): ObjectResultAction<T, T> {
    return createObjectResultAction(
      autoActionType(scope, UPDATE_ITEM_SUCCESS),
      null,
      result,
      item,
    );
  }

  function updateFailed(item: T, error: Error): ObjectResultAction<T, T> {
    return createObjectResultAction<T, T>(
      autoActionType(scope, UPDATE_ITEM_FAILED),
      error,
      null,
      item,
    );
  }

  function requestUpdate(item: T, ...args: any[]): ActionThunk<TAppState> {
    let callback: FetchCallback<T> | null = null;
    if (typeof args[args.length - 1] === 'function') {
      callback = args[args.length - 1];
    }
    return dispatch => {
      if (!options.update) {
        throw new Error(`${scope} options.update is required. `);
      }
      dispatch(update(item));
      options.update
        .apply(null, [item, ...args])
        .then((result: T) => {
          callback && callback(null, result);
          dispatch(updateSuccess(item, result));
        })
        .catch((err: Error) => {
          callback && callback(err, undefined);
          dispatch(updateFailed(item, err));
        });
    };
  }

  // delete
  function deleteItem(item: T): StandardAction<T> {
    return createStandardAction(autoActionType(scope, DELETE_ITEM), item);
  }

  function deleteSuccess(item: T): ObjectResultAction<T, T> {
    return createObjectResultAction<T, T>(
      autoActionType(scope, DELETE_ITEM_SUCCESS),
      null,
      null,
      item,
    );
  }

  function deleteFailed(item: T, error: Error): ObjectResultAction<T, T> {
    return createObjectResultAction<T, T>(
      autoActionType(scope, DELETE_ITEM_FAILED),
      error,
      null,
      item,
    );
  }

  function requestDelete(item: T, ...args: any[]): ActionThunk<TAppState> {
    let callback: DeleteCallback | null = null;
    if (typeof args[args.length - 1] === 'function') {
      callback = args[args.length - 1];
    }
    return dispatch => {
      if (!options.delete) {
        throw new Error(`${scope} options.delete is required. `);
      }
      dispatch(deleteItem(item));
      options.delete
        .apply(null, [item, ...args])
        .then(() => {
          callback && callback(undefined);
          dispatch(deleteSuccess(item));
        })
        .catch((err: Error) => {
          callback && callback(err);
          dispatch(deleteFailed(item, err));
        });
    };
  }

  // delete multi
  function deleteItems(items: T[]): StandardAction<T[]> {
    return createStandardAction(autoActionType(scope, DELETE_MULTI), items);
  }

  function deleteItemsSuccess(items: T[]): ObjectResultAction<T[], T[]> {
    return createObjectResultAction<T[], T[]>(
      autoActionType(scope, DELETE_MULTI_SUCCESS),
      null,
      null,
      items,
    );
  }

  function deleteItemsFailed(
    items: T[],
    error: Error,
  ): ObjectResultAction<T[], T[]> {
    return createObjectResultAction<T[], T[]>(
      autoActionType(scope, DELETE_MULTI_FAILED),
      error,
      null,
      items,
    );
  }

  function requestDeleteItems(
    items: T[],
    ...args: any[]
  ): ActionThunk<TAppState> {
    let callback: DeleteCallback | null = null;
    if (typeof args[args.length - 1] === 'function') {
      callback = args[args.length - 1];
    }
    return dispatch => {
      if (!items.length) return;
      if (items.length > 1 && !options.deleteMulti) {
        throw new Error(`${scope} options.deleteMulti is required. `);
      }
      if (items.length === 1 && !options.delete) {
        throw new Error(`${scope} options.delete is required. `);
      }
      dispatch(deleteItems(items));
      const deleteTask =
        items.length > 1
          ? options.deleteMulti!.apply(null, [items, ...args])
          : options.delete!.apply(null, [items[0], ...args]);
      deleteTask
        .then(() => {
          callback && callback(undefined);
          dispatch(deleteItemsSuccess(items));
        })
        .catch((err: Error) => {
          callback && callback(err);
          dispatch(deleteItemsFailed(items, err));
        });
    };
  }

  function itemBeingCreated(item: Partial<T>) {
    return createStandardAction(
      autoActionType(scope, ITEM_BEING_CREATED),
      item,
    );
  }

  function itemBeingCreatedChanged(changes: Partial<T>) {
    return createStandardAction(
      autoActionType(scope, ITEM_BEING_CREATED_CHANGED),
      changes,
    );
  }

  function commitItemBeingCreated(): ActionThunk<TAppState> {
    return (dispatch, getState) => {
      const state = getState();
      if (!options.getItemBeingCreated) {
        throw new Error(`${scope} options.getItemBeingCreated is required. `);
      }
      const item = options.getItemBeingCreated(state);
      if (!item) {
        throw new Error(`${scope} options.getItemBeingCreated returns null`);
      }
      dispatch(
        createStandardAction(autoActionType(scope, ITEM_BEING_CREATED_COMMIT)),
      );
      dispatch(requestCreate(item));
    };
  }

  function cancelItemBeingCreated(): StandardAction<void> {
    return createStandardAction(
      autoActionType(scope, ITEM_BEING_CREATED_CANCEL),
    );
  }

  function itemBeingUpdated(item: T) {
    return createStandardAction(
      autoActionType(scope, ITEM_BEING_UPDATED),
      item,
    );
  }

  function itemBeingUpdatedChanged(changes: Partial<T>) {
    return createStandardAction(
      autoActionType(scope, ITEM_BEING_UPDATED_CHANGED),
      changes,
    );
  }

  function commitItemBeingUpdated(): ActionThunk<TAppState> {
    return (dispatch, getState) => {
      const state = getState();
      if (!options.getItemBeingUpdated) {
        throw new Error(`${scope} options.getItemBeingUpdated is required. `);
      }
      const item = options.getItemBeingUpdated(state);
      if (!item) {
        throw new Error(`${scope} options.getItemBeingUpdated returns null`);
      }
      dispatch(
        createStandardAction(autoActionType(scope, ITEM_BEING_UPDATED_COMMIT)),
      );
      dispatch(requestUpdate(item));
    };
  }

  function cancelItemBeingUpdated(): StandardAction<void> {
    return createStandardAction(
      autoActionType(scope, ITEM_BEING_UPDATED_CANCEL),
    );
  }

  function itemsBeingDeleted(items: T[]) {
    return createStandardAction(
      autoActionType(scope, ITEMS_BEING_DELETED),
      items,
    );
  }

  function commitItemsBeingDeleted(...args: any[]): ActionThunk<TAppState> {
    return (dispatch, getState) => {
      const state = getState();
      if (!options.getItemsBeingDeleted) {
        throw new Error(`${scope} options.getItemsBeingDeleted is required. `);
      }
      const items = options.getItemsBeingDeleted(state);
      if (!items) {
        throw new Error(`${scope} options.getItemsBeingDeleted returns null`);
      }
      dispatch(
        createStandardAction(autoActionType(scope, ITEMS_BEING_DELETED_COMMIT)),
      );
      dispatch(requestDeleteItems(items, ...args));
    };
  }

  function cancelItemsBeingDeleted(): StandardAction<void> {
    return createStandardAction(
      autoActionType(scope, ITEMS_BEING_DELETED_CANCEL),
    );
  }

  function updateFilter(
    filter: Partial<TFilter>,
  ): ActionThunk<TAppState> | StandardAction<Partial<TFilter>> {
    const predict = options.shouldInvalidateForFilter;
    const shouldInvalidate =
      typeof predict === 'boolean'
        ? predict
        : typeof predict === 'function'
          ? predict(filter)
          : false;
    const updateFilterAction = createStandardAction(
      autoActionType(scope, UPDATE_FILTER),
      filter,
    );
    if (shouldInvalidate) {
      return (dispatch: DispatchFn<TAppState>) => {
        dispatch(updateFilterAction);
        const invalidateAction = creators.invalidate(true);
        dispatch(invalidateAction);
      };
    }
    return updateFilterAction;
  }

  return {
    loadMore,
    loadMoreSuccess,
    loadMoreFailed,
    fetchMore,
    create,
    createSuccess,
    createFailed,
    requestCreate,
    update,
    updateSuccess,
    updateFailed,
    requestUpdate,
    deleteItem,
    deleteSuccess,
    deleteFailed,
    requestDelete,
    deleteItems,
    deleteItemsSuccess,
    deleteItemsFailed,
    requestDeleteItems,
    updateDataOffset,
    updateDataLimit,
    toggleSortProperty,
    removeSortProperty,
    setSortProperty,
    updateSelection,
    itemSelected,
    itemDeselected,
    toggleAllSelection,
    clearSelection,
    expandListItemNode,
    collapseListItemNode,
    itemBeingCreated,
    itemBeingCreatedChanged,
    commitItemBeingCreated,
    cancelItemBeingCreated,
    itemBeingUpdated,
    itemBeingUpdatedChanged,
    commitItemBeingUpdated,
    cancelItemBeingUpdated,
    itemsBeingDeleted,
    commitItemsBeingDeleted,
    cancelItemsBeingDeleted,
    updateFilter,
    activeListGroupKeyChanged,
    expandListGroup,
    collapseListGroup,
    expandAllListGroup,
    collapseAllListGroup,
    expandItemDetail,
    collapseItemDetail,
    expandAllItemDetail,
    collapseAllItemDetail,
    ...creators,
  };
}
// #endregion
