/**
 * @file: SeverityLevelOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, SeverityLevel } from 'model';

export const SeverityLevelOptions: Array<Option<SeverityLevel>> = [
  { value: SeverityLevel.None, label: 'severity_level.none' },
  { value: SeverityLevel.Slight, label: 'severity_level.slight' },
  { value: SeverityLevel.Notice, label: 'severity_level.notice' },
  { value: SeverityLevel.Warning, label: 'severity_level.warning' },
  { value: SeverityLevel.Danger, label: 'severity_level.danger' },
];

export const SeverityLevelOptionsWithDefault: Array<
  Option<SeverityLevel | null>
> = [
  { value: null, label: 'severity_level.__default__' },
  { value: SeverityLevel.None, label: 'severity_level.none' },
  { value: SeverityLevel.Slight, label: 'severity_level.slight' },
  { value: SeverityLevel.Notice, label: 'severity_level.notice' },
  { value: SeverityLevel.Warning, label: 'severity_level.warning' },
  { value: SeverityLevel.Danger, label: 'severity_level.danger' },
];
