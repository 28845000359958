/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { cacheService } from 'lib';
import {
  ActionThunk,
  DispatchFn,
  FetchCallback,
  FetchHandler,
  ObjectKeyType,
  StandardAction,
} from '../../interfaces';
import {
  INVALIDATE,
  LOAD,
  LOAD_FAILED,
  LOAD_FROM_CACHE,
  LOAD_SUCCESS,
} from '../constants';
import { ObjectResultAction, createObjectResultAction } from '../object';

export function autoActionType(scope: string, name: string, key?: string) {
  return scope
    .replace(/\//g, '.')
    .split(/\./g)
    .concat(...(key ? [name, key] : [name]))
    .join('.');
}

export function createStandardAction<T>(
  type: string,
  payload?: T | null,
): StandardAction<T> {
  return { type, payload };
}

// #region create async action creators
export interface AsyncActionCreatorsOptions<TAppState, T, TCacheValue = T> {
  fetchHandler: FetchHandler<T>;
  shouldFetchOnInvalidate?: boolean;
  get?: (state: TAppState, key?: ObjectKeyType) => TCacheValue | null;
  getState?: (state: TAppState, key?: ObjectKeyType) => any;
  cache?: boolean;
  cacheKey?: string;
  shouldCacheResult?: (state: TAppState, extra?: any) => boolean;
}

export interface AsyncActionCreators<
  TAppState,
  T,
  TResultAction = ObjectResultAction<T>,
> {
  load(): StandardAction<any>;
  loadSuccess(result: T): TResultAction;
  loadFailed(error: Error): TResultAction;
  invalidate(
    reset?: boolean,
    callback?: FetchCallback<T>,
  ): StandardAction<boolean> | ActionThunk<TAppState>;
  fetch(...args: any[]): ActionThunk<TAppState>;
}

export function createAsyncActionCreators<
  TAppState,
  T,
  TResultAction = ObjectResultAction<T>,
  TCacheValue = T,
>(
  scope: string,
  options: AsyncActionCreatorsOptions<TAppState, T, TCacheValue>,
  fetchSuccessHandler?: (
    dispatch: (action: any) => void,
    result: any,
    callback: (result: any) => void,
  ) => void,
  invalidateHandler?: (
    reset: boolean | undefined,
    fetch: (...args: any[]) => ActionThunk<TAppState>,
  ) => StandardAction<any> | ActionThunk<TAppState>,
): AsyncActionCreators<TAppState, T, TResultAction> {
  if (options.cache && !options.get) {
    console.warn(
      `${scope}: options.get should be set when options.cache is set'`,
    );
  }
  const { fetchHandler } = options;

  const loadActionType = autoActionType(scope, LOAD);
  const loadSuccessActionType = autoActionType(scope, LOAD_SUCCESS);
  const loadFailedActionType = autoActionType(scope, LOAD_FAILED);
  const invalidateActionType = autoActionType(scope, INVALIDATE);

  function load(): StandardAction<any> {
    return createStandardAction(loadActionType);
  }

  function loadSuccess(result: T): TResultAction {
    const action = createObjectResultAction(
      loadSuccessActionType,
      null,
      result,
    );
    return action as any as TResultAction;
  }

  function loadFailed(error: Error): TResultAction {
    const action = createObjectResultAction(loadFailedActionType, error);
    return action as any as TResultAction;
  }

  function invalidate(
    reset?: boolean,
    callback?: FetchCallback<T>,
  ): StandardAction<boolean> | ActionThunk<TAppState> {
    if (invalidateHandler) {
      return invalidateHandler(reset, (...args: any[]) => {
        return fetch(...args, callback);
      });
    }
    const invalidateAction = createStandardAction(invalidateActionType, reset);
    if (options.shouldFetchOnInvalidate) {
      return (dispatch: DispatchFn<TAppState>) => {
        dispatch(invalidateAction);
        dispatch(fetch(callback));
      };
    }
    return invalidateAction;
  }

  function fetch(...args: any[]): ActionThunk<TAppState> {
    return (dispatch, getState) => {
      dispatch(load());

      let callback: FetchCallback<T> | null = null;
      if (typeof args[args.length - 1] === 'function') {
        callback = args[args.length - 1];
      }

      const state: TAppState = getState();

      let fetchedFromServer = false;
      const cacheKey = options.cacheKey || `${loadActionType}.cached`;

      if (options.cache && options.get) {
        const result = options.get(state);
        if (result === null) {
          cacheService
            .get<T>(cacheKey)
            .then(value => {
              if (!fetchedFromServer && value !== undefined) {
                const loadFromCacheAction = createStandardAction(
                  autoActionType(scope, LOAD_FROM_CACHE),
                  value,
                );
                dispatch(loadFromCacheAction);
              }
            })
            .catch(() => {
              /* noop */
            });
        }
      }

      args = [state, ...args];
      // eslint-disable-next-line prefer-spread
      fetchHandler
        .apply(null, args)
        .then((result: any) => {
          fetchedFromServer = true;
          if (
            options.cache &&
            (!options.shouldCacheResult || options.shouldCacheResult(state))
          ) {
            // save the result to cache.
            cacheService
              .set(cacheKey, result)
              .then(() => {
                console.log('%s saved to cache', loadActionType);
              })
              .catch(() => {
                /* noop */
              });
          }
          callback && callback(null, result);
          if (fetchSuccessHandler) {
            fetchSuccessHandler(dispatch, result, handleResult => {
              dispatch(loadSuccess(handleResult));
            });
          } else {
            dispatch(loadSuccess(result));
          }
        })
        .catch((err: Error) => {
          callback && callback(err, undefined);
          dispatch(loadFailed(err));
        });
    };
  }

  return {
    load,
    loadSuccess,
    loadFailed,
    invalidate,
    fetch,
  };
}

// #endregion
