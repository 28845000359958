import { storageService } from 'lib';
import React, { Component, MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { InlineSvg } from '../InlineSvg';

import './index.scss';

export class Footer extends Component {
  render() {
    return (
      <footer className="m-grid__item m-footer">
        <div className="m-container m-container--fluid m-container--full-height m-page__container">
          <div className="m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop">
            <div className="m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last">
              <span className="m-footer__copyright">
                2019-2020 © Sichuan Zhichetech Co., Ltd.
              </span>
              <span className="m-footer__lang">
                <a href="#" className="m-nav__link" onClick={this.onLangClick('zh')}>
                  <InlineSvg src="img/lang-zh-circle.svg" className="m-footer__lang-icon" />
                </a>
                <a href="#" className="m-nav__link" onClick={this.onLangClick('en')}>
                  <InlineSvg src="img/lang-en-circle.svg" className="m-footer__lang-icon" />
                </a>
              </span>
            </div>
            <div className="m-stack__item m-stack__item--right m-stack__item--middle m-stack__item--first">
              <ul className="m-footer__nav m-nav m-nav--inline m--pull-right">
                <li className="m-nav__item">
                  <a href="#" className="m-nav__link">
                    <Translate id="foot_links.about" />
                  </a>
                </li>
                <li className="m-nav__item">
                  <a href="#" className="m-nav__link">
                    <Translate id="foot_links.privacy" />
                  </a>
                </li>
                <li className="m-nav__item">
                  <a href="#" className="m-nav__link">
                    <Translate id="foot_links.terms" />
                  </a>
                </li>
                <li className="m-nav__item">
                  <a href="#" className="m-nav__link">
                    <Translate id="foot_links.contact" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  onLangClick(lang: 'en' | 'zh') {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      storageService.ls_set('lang', lang);
      location.reload();
    };
  }
}