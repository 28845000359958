import { MainNavStatus } from 'app';
import { fetchUserInfo } from 'app/duck/actions';
import { store } from 'app/duck/store';
import classNames from 'classnames';
import { tokenService } from 'lib';
import { AdminUser, FileInfo } from 'model';
import { Component, FocusEvent, MouseEvent, ReactNode } from 'react';
import {
  LocalizeContextProps,
  Translate,
  TranslateFunction,
  withLocalize,
} from 'react-localize-redux';
import { Link } from 'react-router-dom';
import { adminUserService } from 'services';
import { Dropdown, Menu, MenuItemProps, Nav } from 'shared/metronic/components';
import { AppStatus } from 'shared/types';
import { AppLoading } from '../AppLoading';
import { FilePicker } from '../FilePicker';

interface Props extends LocalizeContextProps {
  currentUser: AdminUser | null;
  mainNavStatus: MainNavStatus;
  mainNavOpenInSmallScreen: boolean;
  showLoading: boolean;
  loadingMessage: string | ReactNode;
  loadingStatus?: AppStatus;
  onToggleMinimize?: (status?: MainNavStatus) => void;
  onOpenMainNavInSmallScreen?: () => void;
  onLanguageChanged?: (lang: string) => void;
}

interface State {
  isEditingNick: boolean;
}

export class HeaderImpl extends Component<Props, State> {
  state: State = { isEditingNick: false };

  render() {
    return (
      <header id="m_header" className="m-grid__item m-header">
        <div className="m-container m-container--fluid m-container--full-height">
          <div className="m-stack m-stack--ver m-stack--desktop">
            {this.renderBrand()}
            {this.renderHeader()}
          </div>
        </div>
      </header>
    );
  }

  getAvatar() {
    return (
      this.props.currentUser?.avatarUrl ||
      'vendors/metronic/base/images/users/user4.jpg'
    );
  }

  renderBrand() {
    return (
      <div className="m-stack__item m-brand  m-brand--skin-dark">
        <div className="m-stack m-stack--ver m-stack--general">
          <div className="m-stack__item m-stack__item--middle m-brand__logo">
            <Link
              to="/"
              className="m-brand__logo-wrapper"
              style={{
                fontSize: '24px',
                fontWeight: 'bolder',
                whiteSpace: 'nowrap',
                textDecoration: 'none',
                color: '#898b96',
              }}
            >
              <Translate id="brand" />
            </Link>
          </div>
          <div className="m-stack__item m-stack__item--middle m-brand__tools">
            <a
              href="#"
              id="m_aside_left_minimize_toggle"
              onClick={this.onToggleMinimize}
              className={classNames(
                'm-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block',
                {
                  'm-brand__toggler--active':
                    this.props.mainNavStatus === 'minimized',
                },
              )}
            >
              <span />
            </a>
            <a
              href="#"
              id="m_aside_left_offcanvas_toggle"
              className={classNames(
                'm-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block',
                {
                  'm-brand__toggler--active':
                    this.props.mainNavOpenInSmallScreen,
                },
              )}
              onClick={this.onOpenMainNav}
            >
              <span />
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <div
        className="m-stack__item m-stack__item--fluid m-header-head"
        style={{ position: 'relative' }}
      >
        <button
          className="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-dark"
          id="m_aside_header_menu_mobile_close_btn"
        >
          <i className="la la-close" />
        </button>
        <div
          id="m_header_menu"
          className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark"
        >
          {this.renderHeaderMenu()}
        </div>
        <div
          id="m_header_topbar"
          className="m-topbar m-stack m-stack--ver m-stack--general m-stack--fluid"
        >
          <div className="m-stack__item m-topbar__nav-wrapper">
            {this.renderHeaderTopBar()}
          </div>
        </div>
        <AppLoading
          show={this.props.showLoading}
          message={this.props.loadingMessage}
          status={this.props.loadingStatus}
        />
      </div>
    );
  }

  getShortcuts(translate: TranslateFunction): MenuItemProps[] {
    const shortcuts: MenuItemProps[] = [
      {
        text: translate('dashboard.title') as any,
        href: '/',
        icon: 'flaticon-line-graph',
      },
      {
        text: translate('project.projects.title'),
        href: 'projects/list',
        icon: 'flaticon-web',
      },
      {
        text: translate('settings.users.title'),
        href: '/settings/users',
        icon: 'flaticon-map',
      },
    ];
    return shortcuts;
  }

  renderHeaderMenu() {
    return (
      <Translate>
        {({ translate }) => (
          <Menu>
            {/* apps menu */}
            <Menu.Item
              text={translate('header.shortcuts') as any}
              icon="flaticon-paper-plane"
              dropdown
              subMenuPlacement="left"
              subMenuArrowOffset={87.5}
              items={this.getShortcuts(translate)}
            />
          </Menu>
        )}
      </Translate>
    );
  }

  renderHeaderTopBar() {
    const { currentUser, translate } = this.props;
    return (
      <Nav className="m-topbar__nav" inline>
        <Nav.Item
          text={
            <span style={{ fontSize: '1.2rem', fontWeight: 500 }}>
              {translate('system_name') as any}
            </span>
          }
        />
        <Nav.Item
          className="m-topbar__user-profile m-topbar__user-profile--img"
          text={
            <>
              <span className="m-topbar__userpic">
                <img
                  className="m--img-rounded m--marginless"
                  src={this.getAvatar()}
                />
              </span>
              <span className="m-topbar__username m--hide">
                {currentUser && (currentUser.nick || currentUser.userName)}
              </span>
            </>
          }
          dropdownSize="medium"
          dropdownAlign="right"
          dropdownArrowPlacement="right"
          dropdownArrowStyle={{ right: 12.5 }}
          dropdownSkin="light"
          dropdownHeaderBgFill
          dropdownMobileFullWidth
        >
          <Dropdown>
            <Dropdown.Header
              className="m--align-center"
              style={{
                background: `url(vendors/metronic/base/images/user_profile_bg.jpg)`,
                backgroundSize: 'cover',
              }}
            >
              <div className="m-card-user m-card-user--skin-dark">
                <div className="m-card-user__pic">
                  <FilePicker
                    image
                    imageWidth={200}
                    imageHeight={200}
                    imageFit="cover"
                    placeholder={false}
                    realm="avatar"
                    onChange={this.onAvatarUploaded}
                  >
                    {() => (
                      <img
                        src={this.getAvatar()}
                        className="m--img-rounded m--marginless"
                        title={translate('header.change_avatar') as string}
                        alt=""
                      />
                    )}
                  </FilePicker>
                </div>
                <div className="m-card-user__details">
                  <span
                    className="m-card-user__name m--font-weight-500"
                    style={{ fontSize: '1.5rem' }}
                  >
                    {this.state.isEditingNick ? (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        defaultValue={currentUser?.nick || ''}
                        onBlur={this.onNickBlur}
                        placeholder={
                          translate('header.nick_placeholder') as string
                        }
                      />
                    ) : (
                      <a
                        href="#"
                        onClick={this.onEditNick}
                        style={{ color: '#fff', textDecoration: 'none' }}
                        title={translate('header.edit_nick_name') as string}
                      >
                        {currentUser && (currentUser.nick || '[No nick name]')}
                      </a>
                    )}
                  </span>
                  <span className="m-card-user__name m--font-weight-500">
                    {currentUser?.userName ?? ''}
                  </span>
                </div>
              </div>
            </Dropdown.Header>
            <Nav skin="light">
              <Nav.Section className="m--hide" />
              <Nav.Item render={this.renderLogoutButton} />
            </Nav>
          </Dropdown>
        </Nav.Item>
      </Nav>
    );
  }

  renderLogoutButton = () => {
    return (
      <a
        href="#"
        onClick={this.onLogout}
        className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
      >
        Logout
      </a>
    );
  };

  onLogout = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    tokenService.removeToken();
    location.href = '/';
    location.reload();
  };

  onToggleMinimize = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onToggleMinimize && this.props.onToggleMinimize();
  };

  onOpenMainNav = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.onOpenMainNavInSmallScreen &&
      this.props.onOpenMainNavInSmallScreen();
  };

  onAvatarUploaded = async (file: FileInfo) => {
    try {
      await adminUserService.updateProfile({ avatarUrl: file.url });
      store.dispatch(fetchUserInfo() as any);
    } catch (e) {
      alert('error update profile picture: ' + e.message);
    }
  };

  onEditNick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setTimeout(() => {
      this.setState({ isEditingNick: true });
    }, 0);
  };

  onNickBlur = async (e: FocusEvent<HTMLInputElement>) => {
    const nick = e.target.value.trim();
    if (!nick) {
      this.setState({ isEditingNick: false });
      return;
    }
    try {
      await adminUserService.updateProfile({ nick });
      store.dispatch(fetchUserInfo() as any);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (e) {
      alert('error update profile picture: ' + e.message);
    }
    this.setState({ isEditingNick: false });
  };
}

export const Header = withLocalize<Props>(HeaderImpl);
