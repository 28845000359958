import { TransFunction } from 'app';
import { AppState } from 'app/duck/states';
import classNames from 'classnames';
import { History } from 'history';
import { RouteViewProps } from 'lib';
import { withAcl } from 'lib/decorators/acl';
import {
  AclObjectList,
  Agent,
  InspectionTemplatePredefinedType,
  VehicleInspectionTemplate,
} from 'model';
import { InspectionTemplateConf } from 'model/viewmodel/InspectionTemplateConf';
import moment from 'moment';
import {
  ChangeEvent,
  Component,
  memo,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { agentService, vehicleInspectionTemplateService } from 'services';
import { ActionButtonCls } from 'shared';
import {
  authenticate,
  Badge,
  BreadcrumbItem,
  CommonEntityListProps,
  ConfirmDeleteModal,
  getString,
  InlineSvg,
  InspectionTemplatePredefinedTypeLabel,
  InspectionTemplateSceneTypeLabel,
  Page,
  Restricted,
} from 'shared/components';
import { Button, Column, DataTable } from 'shared/metronic/components';
import { arr2group, arr2map, loadAsyncList } from 'utils';
import { duplicateTemplate, inspectionTemplateActions } from '../duck/actions';
import { InspectionTemplates } from '../duck/states';
import { TemplateEditor } from './editor';
import {
  InspectionTemplatePredefinedTypeList,
  PredefinedTemplateIcons,
} from './helpers';

import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import { AgentPicker } from 'shared/components/AgentPicker';
import './manager.scss';

type TemplateListColumn = Column<VehicleInspectionTemplate>;

interface Props extends CommonEntityListProps, LocalizeContextProps {
  templates: InspectionTemplates;
  history: History;
}

const AgentLabel = memo(({ agentId }: { agentId: number }) => {
  const [agent, setAgent] = useState<Agent | null>();
  const [error, setError] = useState<Error | null>();
  useEffect(() => {
    agentService.get(agentId).then(setAgent).catch(setError);
  }, [agentId]);
  if (agent) {
    return <span>{agent.shortName}</span>;
  }
  if (error) {
    return <span style={{ color: 'red' }}>error</span>;
  }
  return <span>loading... </span>;
});

export type TemplateManagerProps = Props;

function mapStateToProps(
  state: AppState,
  ownProps: RouteViewProps,
): Partial<Props> {
  return {
    history: ownProps.history,
    templates: state.inspection.templates,
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

@withAcl()
class InspectionTemplateManagerImpl extends Component<Props> {
  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.templates_manager" /> },
  ];

  componentDidMount() {
    this.loadTemplates();
  }

  render() {
    const { trans, templates } = this.props;
    return (
      <Page
        title={trans('inspection_template.manager.title')}
        breadcrumbs={this.breadcrumbs}
        fullAccessRight={AclObjectList.VehicleInspectionTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleInspectionTemplateReadonlyAccess
        }
        error={templates.error}
        onAdd={this.onAdd}
        onRefresh={this.onRefresh}
        headerComplement={this.renderHeaderComplement()}
        compact
        bodyClassName="template-manager"
      >
        {this.renderPredefinedTemplatesPreview()}
        {this.renderDataList()}
        {this.renderSidebar()}
        {this.renderConfirmDeleteModal()}
      </Page>
    );
  }

  renderHeaderComplement() {
    return (
      <>
        <AgentPicker
          agentId={this.props.templates.filter?.agentId}
          onChange={agentId => {
            this.props.dispatch(
              inspectionTemplateActions.updateFilter({
                agentId,
              }),
            );
            this.props.dispatch(inspectionTemplateActions.invalidate(true));
          }}
          style={{ width: 250, order: -1, marginRight: 10, marginLeft: 10 }}
        />
        <Restricted
          rights={AclObjectList.VehicleInspectionTemplateFullAccess}
          silent
        >
          <div className="template-manager-header-actions">
            <Button
              icon
              iconOnly
              air
              large
              pill
              color="focus"
              file
              onFileChange={this.onImportTemplates}
            >
              <i className="la la-upload" />
            </Button>
          </div>
        </Restricted>
      </>
    );
  }

  renderSidebar() {
    const { dispatch, templates } = this.props;
    return (
      <TemplateEditor
        entities={templates}
        actions={inspectionTemplateActions}
        dispatch={dispatch}
        extra={{ props: this.props }}
      />
    );
  }

  renderConfirmDeleteModal() {
    const { templates } = this.props;
    return (
      <ConfirmDeleteModal
        localeSegment={'inspection_template'}
        isOpen={Boolean(templates.itemsBeingDeleted?.[0])}
        isDeleting={templates.isDeleting}
        error={templates.lastDeleteError}
        onConfirm={this.onConfirmDelete}
        onCancel={this.onCancelDelete}
      />
    );
  }

  renderPredefinedTemplatesPreview() {
    const { templates } = this.props;
    const groups = arr2group(
      templates.result || [],
      x => x.predefinedType || InspectionTemplatePredefinedType.Other,
    );
    const map: { [type: string]: VehicleInspectionTemplate[] } = arr2map(
      groups,
      x => x.key,
      x => x.items,
    );
    const currentType = templates.filter?.predefinedType?.[0];

    return (
      <div className="predefined-templates-preview">
        {InspectionTemplatePredefinedTypeList.map(type => (
          <div
            className={classNames('predefined-templates-preview__item', {
              'predefined-templates-preview__item--active':
                currentType === type,
            })}
            key={type}
          >
            <div
              className="predefined-templates-preview__item-top"
              onClick={this.onPredefinedTypeClick(type)}
            >
              <div className="predefined-templates-preview__icon">
                <InlineSvg src={PredefinedTemplateIcons[type]} />
              </div>
              <div className="predefined-templates-preview__label">
                <Translate id={`inspection_template_predefined_type.${type}`} />
              </div>
            </div>
            <div className="predefined-templates-preview__info">
              <span className="predefined-templates-preview__count">
                <Translate
                  id="inspection_template.list.preview.count_label"
                  data={{ count: (map[type] || []).length }}
                />
              </span>
              <Restricted
                rights={AclObjectList.VehicleInspectionTemplateFullAccess}
                silent
              >
                <a
                  href="#"
                  onClick={this.onAddForPredefinedType(type)}
                  className="predefined-templates-preview__action-link"
                >
                  <i className="la la-plus" />
                  <Translate id="inspection_template.list.preview.add_button" />
                </a>
              </Restricted>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderDataList() {
    const { templates } = this.props;
    const filter = templates.filter || {};
    let data = templates.result;
    if (data && filter.predefinedType?.length) {
      const predefinedType = filter.predefinedType[0];
      data = data.filter(x => x.predefinedType === predefinedType);
    }
    const columns = this.buildColumns();
    this.addActionButtons(columns);
    return (
      <DataTable<VehicleInspectionTemplate, number>
        columns={columns}
        idProp="id"
        selModel="none"
        data={data}
        isLoading={templates.isLoading}
        minHeight={400}
        selection={templates.selection}
      />
    );
  }

  onRefresh = () => {
    this.loadTemplates(true);
  };

  onAdd = (predefinedType?: InspectionTemplatePredefinedType) => {
    const { dispatch, templates } = this.props;
    if (!predefinedType && templates.result) {
      const fullInspectionTemplate = templates.result.find(
        x =>
          x.predefinedType ===
            InspectionTemplatePredefinedType.FullInspection && !x.disabled,
      );
      if (!fullInspectionTemplate) {
        predefinedType = InspectionTemplatePredefinedType.FullInspection;
      }
    }
    dispatch(
      inspectionTemplateActions.itemBeingCreated({
        predefinedType:
          predefinedType || InspectionTemplatePredefinedType.Other,
      }),
    );
  };

  onAddForPredefinedType(predefinedType: InspectionTemplatePredefinedType) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      this.onAdd(predefinedType);
    };
  }

  onPredefinedTypeClick(predefinedType: InspectionTemplatePredefinedType) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch } = this.props;
      dispatch(
        inspectionTemplateActions.updateFilter({
          predefinedType: [predefinedType],
        }),
      );
    };
  }

  onConfirmDelete = () => {
    const { dispatch } = this.props;
    dispatch(inspectionTemplateActions.commitItemsBeingDeleted());
  };

  onCancelDelete = () => {
    const { dispatch } = this.props;
    dispatch(inspectionTemplateActions.cancelItemsBeingDeleted());
  };

  onImportTemplates = async (e: ChangeEvent<HTMLInputElement>) => {
    const accepts = ['application/json'];
    const file = this.acceptFile(e, accepts, 'invalid_file');
    if (!file) return;
    const { dispatch } = this.props;
    dispatch(
      showAppLoading({
        message: getString('inspection_template.manager.importing'),
        status: 'loading',
      }),
    );
    try {
      await vehicleInspectionTemplateService.importTemplates(file);
      dispatch(inspectionTemplateActions.invalidate(true));
      dispatch(
        showAppLoading({
          message: getString('inspection_template.manager.import_success'),
          status: 'success',
          timeout: 2000,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (e) {
      dispatch(hideAppLoading());
      dispatch(
        showAppModal(
          getString('inspection_template.modal.import_error.title'),
          getString('inspection_template.modal.import_error.msg'),
        ),
      );
    }
  };

  acceptFile(
    e: ChangeEvent<HTMLInputElement>,
    accepts: string[],
    error: string,
  ): File | false {
    if (!e.target.files?.length) return false;
    const file = e.target.files[0];
    if (!accepts.includes(file.type)) {
      alert(getString(`inspection_template.manager.${error}`));
      return false;
    }
    return file;
  }

  loadTemplates(force?: boolean) {
    const { dispatch, templates } = this.props;
    loadAsyncList(
      templates,
      () => dispatch(inspectionTemplateActions.fetch()),
      force,
    );
  }

  async exportTemplates(templates: VehicleInspectionTemplate[]) {
    const ids = templates.map(x => x.id);
    const url = vehicleInspectionTemplateService.getExportTemplatesUrl(ids);
    console.log('download url is: %s', url);
    const link = document.createElement('a');
    const time = moment().format('YYYYMMDD');
    link.download =
      templates.length === 1
        ? `${templates[0].name}-exported-${time}.json`
        : `exported-templates-${time}.json`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  buildColumns(): TemplateListColumn[] {
    const { trans } = this.props;
    return [
      {
        prop: 'source',
        text: trans('inspection_template.col.source'),
        width: 50,
        align: 'center',
        render: template => {
          const source = template.agentId
            ? 'agent'
            : template.storeId
              ? 'store'
              : template.orgId
                ? 'org'
                : 'system';
          return <Translate id={`inspection_template.source.${source}`} />;
        },
      },
      {
        prop: 'name',
        text: trans('inspection_template.col.name'),
        width: 150,
      },
      {
        prop: 'sceneType',
        text: trans('inspection_template.col.scene_type'),
        width: 100,
        render: ({ sceneType }) => {
          return <InspectionTemplateSceneTypeLabel value={sceneType} />;
        },
      },
      {
        prop: 'predefinedType',
        text: trans('inspection_template.col.predefined_type'),
        width: 80,
        render: ({ predefinedType }) => {
          return (
            <InspectionTemplatePredefinedTypeLabel value={predefinedType} />
          );
        },
      },
      {
        prop: 'agentId',
        text: trans('inspection_template.col.agent'),
        width: 80,
        render: template => {
          if (!template.agentId) return '-';
          return <AgentLabel agentId={template.agentId} />;
        },
      },
      {
        prop: 'isSystemDefault',
        text: trans('inspection_template.col.is_system_default'),
        width: 60,
        align: 'center',
        render: ({ isSystemDefault }) => {
          return isSystemDefault ? (
            <Badge color="success">
              <Translate id="inspection_template.label.default" />
            </Badge>
          ) : (
            '/'
          );
        },
      },
      {
        prop: 'description',
        text: trans('inspection_template.col.description'),
        width: 150,
      },
      {
        prop: 'enabled',
        text: trans('inspection_template.col.enabled'),
        width: 40,
        align: 'center',
        render: ({ disabled }) => {
          return (
            <i
              className={classNames('la la-check-circle', {
                'm--font-success': !disabled,
                'm--font-metal': disabled,
              })}
            />
          );
        },
      },
      {
        prop: 'conf',
        text: trans('inspection_template.col.conf'),
        width: 120,
        render: site => {
          const conf = site.conf
            ? (JSON.parse(site.conf) as InspectionTemplateConf)
            : null;
          if (!conf?.categories.length) {
            return (
              <span className="m--font-warning">
                <Translate id="inspection_template.label.no_conf" />
              </span>
            );
          }
          return (
            <Translate
              id="inspection_template.label.conf"
              data={{
                categoryCount: conf.categories.length,
                siteCount: conf.categories.reduce((res, c) => {
                  res += c.groups.reduce((x, g) => {
                    x += g.siteIds.length;
                    return x;
                  }, 0);
                  return res;
                }, 0),
              }}
            />
          );
        },
      },
    ];
  }

  addActionButtons(columns: TemplateListColumn[]) {
    const fullAccessRight = AclObjectList.VehicleInspectionSiteFullAccess;
    if (!this.props.$has(fullAccessRight)) {
      columns.push({
        prop: 'actions',
        text: <Translate id="col.actions" />,
        align: 'center',
        width: 135,
        render: (template: VehicleInspectionTemplate) => {
          const onViewDetail = (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            const { history } = this.props;
            setTimeout(() => {
              history.push('/inspection/templates/detail?id=' + template.id);
            }, 0);
          };
          return (
            <>
              <button
                className={ActionButtonCls}
                onClick={onViewDetail}
                data-toggle="tooltip"
                data-container=".template-manager"
                title={getString('inspection_template.tooltip.view_detail')}
              >
                <i className="la la-eye" />
              </button>
            </>
          );
        },
      });
      return;
    }
    columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: 175,
      render: (template: VehicleInspectionTemplate) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(inspectionTemplateActions.itemBeingUpdated(template));
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(inspectionTemplateActions.itemsBeingDeleted([template]));
        };
        const onConfig = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { history } = this.props;
          setTimeout(() => {
            history.push('/inspection/templates/detail?id=' + template.id);
          }, 0);
        };
        const onDuplicate = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(
            duplicateTemplate({
              ...template,
              name: `${template.name}-${getString(
                'inspection_template.label.clone_suffix',
              )}`,
            }),
          );
        };
        const onExport = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          void this.exportTemplates([template]);
        };

        return (
          <>
            <button
              className={ActionButtonCls}
              onClick={onEdit}
              data-toggle="tooltip"
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.edit')}
            >
              <i className="la la-edit" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onConfig}
              data-toggle="tooltip"
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.config')}
            >
              <i className="la la-cog" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onDuplicate}
              data-toggle="tooltip"
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.duplicate')}
            >
              <i className="la la-copy" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onExport}
              data-toggle="tooltip"
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.export')}
            >
              <i className="la la-download" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onDelete}
              data-toggle="tooltip"
              data-container=".template-manager"
              title={getString('inspection_template.tooltip.delete')}
            >
              <i className="la la-trash" />
            </button>
          </>
        );
      },
    });
  }
}

export const InspectionTemplateManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, InspectionTemplateManagerImpl>(
    withLocalize<Props>(InspectionTemplateManagerImpl) as any,
  ),
);
