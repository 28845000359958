import React, { Component } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Column, DataTable } from 'shared/metronic/components';
import { getScopedTransFunction } from 'app';
import { RecentInspectionReports } from './duck/states';
import { VehicleReport } from 'model';
import { formatTime } from 'utils';
import { InspectionOrderTypeLabel } from 'shared/components';

interface Props extends LocalizeContextProps {
  reports: RecentInspectionReports;
}

export class RecentVehicleInspectionReportListComponent extends Component<Props> {
  private columns: Array<Column<VehicleReport>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { reports } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="_id"
        selModel="none"
        data={reports.result}
        isLoading={reports.isLoading}
        minHeight={0}
        error={reports.error}
        />
    );
  }

  private init() {
    const { translate } = this.props;
    const trans = getScopedTransFunction(translate, '');
    this.columns = [{
      prop: '_id',
      width: 150,
      text: trans('inspection_report.col.report_no')
    }, {
      prop: 'orderNo',
      width: 150,
      text: trans('col.order_no')
    }, {
      prop: 'orderType',
      width: 120,
      text: trans('col.order_type'),
      render: ({ orderType }) => <InspectionOrderTypeLabel value={orderType} />
    }, {
      prop: 'vehicleName',
      width: 150,
      text: trans('col.vehicle_name')
    }, {
      prop: 'vin',
      width: 80,
      text: trans(`col.vin`)
    }, {
      prop: 'licensePlateNo',
      width: 100,
      text: trans('col.license_plate_no')
    }, {
      prop: 'vehicleMileage',
      width: 100,
      text: trans('col.vehicle_mileage')
    }, {
      prop: 'createdAt',
      text: trans('col.created_at'),
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt)
    }];
  }
}

export const RecentVehicleInspectionReportList = withLocalize(
  RecentVehicleInspectionReportListComponent
  );