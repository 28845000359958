/**
 * @file: summary-states.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncActionReducers } from 'lib/duck/reducers';
import { SummaryStats } from 'model';
import { SummaryStatsState } from '../states';

const initialState: SummaryStatsState = {
  isLoading: false,
  result: null,
  error: null,
};

export default createAsyncActionReducers<SummaryStats, SummaryStatsState>(
  'dashboard.summary_stats',
  initialState,
);
