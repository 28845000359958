import { AppState } from 'app/duck/states';
import { DispatchFn } from 'lib/duck/interfaces';
import { VehicleInspectionSiteCheckItem } from 'model';
import { OptionValueTypeOptions } from 'model/EnumOptions';
import { ChangeEvent } from 'react';
import { getString, withEntityEditorSidebarBuilder } from 'shared/components';
import { getValueUnitByValueType } from '../../common/helpers';
import { ProtocolFieldIdGroups } from '../../common/protocol-field-ids';
import { inspectionSiteItemActions } from '../../duck/actions';
import { InspectionSiteItems } from '../../duck/states';

interface Context {
  props: {
    dispatch: DispatchFn<AppState>;
    items: InspectionSiteItems;
  };
}

export const ItemEditor =
  withEntityEditorSidebarBuilder<VehicleInspectionSiteCheckItem>()
    .withI18nPrefix('inspection_site_item')
    .withForm(builder =>
      builder
        .uncontrolled()
        .text({
          prop: 'name',
          label: 'inspection_site_item.editor.label.name',
          placeholder: 'inspection_site_item.editor.placeholder.name',
        })
        .select({
          prop: 'valueType',
          label: 'inspection_site_item.editor.label.value_type',
          placeholder: 'inspection_site_item.editor.placeholder.value_type',
          helpText: 'inspection_site_item.editor.help_text.value_type',
          options: OptionValueTypeOptions,
          onChange: changes => {
            if (!changes.valueType) {
              changes.valueUnit = '';
            } else {
              const valueUnit = getValueUnitByValueType(changes.valueType);
              changes.valueUnit = valueUnit;
            }
          },
        })
        .text({
          prop: 'valueUnit',
          label: 'inspection_site_item.editor.label.value_unit',
          placeholder: 'inspection_site_item.editor.placeholder.value_unit',
          helpText: 'inspection_site_item.editor.help_text.value_unit',
        })
        .select({
          prop: 'toolId',
          label: 'inspection_site_item.editor.label.tool',
          placeholder: 'inspection_site_item.editor.placeholder.tool',
          helpText: 'inspection_site_item.editor.help_text.tool',
          options: (context: any) => {
            const { props } = context.extra;
            return props.tools?.result || [];
          },
          valueProp: 'id',
          labelProp: 'name',
        })
        .custom({
          label: 'inspection_site_item.editor.label.protocol_field_id',
          placeholder:
            'inspection_site_item.editor.placeholder.protocol_field_id',
          helpText: 'inspection_site_item.editor.help_text.protocol_field_id',
          render: (context: Context) => {
            const { props } = context;
            const item =
              props.items.itemBeingCreated || props.items.itemBeingUpdated;
            const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
              const protocolFieldId = e.target.value
                ? Number(e.target.value)
                : null;
              if (props.items.itemBeingCreated) {
                props.dispatch(
                  inspectionSiteItemActions.itemBeingCreatedChanged!({
                    protocolFieldId,
                  }),
                );
              } else {
                props.dispatch(
                  inspectionSiteItemActions.itemBeingUpdatedChanged!({
                    protocolFieldId,
                  }),
                );
              }
            };
            return (
              <select
                className="form-control"
                value={item?.protocolFieldId || ''}
                onChange={onChange}
              >
                <option value="">
                  {getString(
                    'inspection_site_item.editor.placeholder.protocol_field_id',
                  )}
                </option>
                {ProtocolFieldIdGroups.map(group => (
                  <optgroup
                    label={getString(
                      `inspection_site_item.field_id_group.${group.name}`,
                    )}
                    key={group.name}
                  >
                    {group.fieldIds.map(fieldId => (
                      <option value={fieldId.id} key={fieldId.id}>
                        {getString(
                          `inspection_site_item.field_id.${fieldId.name}`,
                          { id: fieldId.id },
                        )}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            );
          },
        })
        .text({
          type: 'number',
          prop: 'influenceFactor',
          label: 'inspection_site_item.editor.label.influence_factor',
          placeholder:
            'inspection_site_item.editor.placeholder.influence_factor',
          helpText: 'inspection_site_item.editor.help_text.influence_factor',
        })
        .text({
          type: 'number',
          prop: 'sortOrder',
          label: 'inspection_site_item.editor.label.sort_order',
          placeholder: 'inspection_site_item.editor.placeholder.sort_order',
        })
        .textArea({
          prop: 'description',
          label: 'inspection_site_item.editor.label.description',
          placeholder: 'inspection_site_item.editor.placeholder.description',
        }),
    )
    .withValidator(item => {
      let errCode: string | undefined = undefined;
      const name = item.name?.trim();
      if (!name) {
        errCode = 'name_required';
      } else if (!item.influenceFactor) {
        errCode = 'influence_factor_required';
      } else if (item.influenceFactor < 1 || item.influenceFactor > 10) {
        errCode = 'invalid_influence_factor';
      }
      if (errCode) {
        const errMsg = getString(
          'inspection_site_item.editor.error.' + errCode,
        );
        throw new Error(errMsg);
      }
    })
    .getClass();
