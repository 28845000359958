/**
 * @file: builder.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  ToolbarItem,
  ToolbarItemText,
  ToolbarItemType,
  ToolbarItemSelect,
  ToolbarItemDatePicker,
  ToolbarItemDropdown,
  ToolbarItemButton,
  ToolbarItemSeparator,
  ToolbarItemCustom,
  ToolbarItemButtonGroup,
  ToolbarItemParamType,
  ToolbarItemPlacement,
  ToolbarItemAgentPicker,
  ToolbarItemStorePicker,
  ToolbarItemServiceEditionPicker,
} from './types';
import { ReactNode } from 'react';

export class ToolbarItemsBuilder<T> {
  private readonly items: Array<ToolbarItem<T>> = [];

  text(item: ToolbarItemParamType<T, ToolbarItemText<T>>): this {
    this.items.push({ type: ToolbarItemType.Text, ...item });
    return this;
  }

  select<U = { [key: string]: any }>(
    item: ToolbarItemParamType<T, ToolbarItemSelect<T, U>>,
  ): this {
    this.items.push({ type: ToolbarItemType.Select, ...item });
    return this;
  }

  datePicker(item: ToolbarItemParamType<T, ToolbarItemDatePicker<T>>): this {
    this.items.push({ type: ToolbarItemType.DatePicker, ...item });
    return this;
  }

  agentPicker(item: ToolbarItemParamType<T, ToolbarItemAgentPicker<T>>): this {
    this.items.push({ type: ToolbarItemType.AgentPicker, ...item });
    return this;
  }

  storePicker(item: ToolbarItemParamType<T, ToolbarItemStorePicker<T>>): this {
    this.items.push({ type: ToolbarItemType.StorePicker, ...item });
    return this;
  }

  serviceEditionPicker(
    item: ToolbarItemParamType<T, ToolbarItemServiceEditionPicker<T>>,
  ): this {
    this.items.push({ type: ToolbarItemType.ServiceEditionPicker, ...item });
    return this;
  }

  dropdown(item: ToolbarItemParamType<T, ToolbarItemDropdown<T>>): this {
    this.items.push({ type: ToolbarItemType.Dropdown, ...item });
    return this;
  }

  button(item: ToolbarItemParamType<T, ToolbarItemButton<T>>): this {
    this.items.push({ type: ToolbarItemType.Button, ...item, prop: '' as any });
    return this;
  }

  separator(item: ToolbarItemSeparator<T>): this {
    // @ts-ignore
    this.items.push({ type: ToolbarItemType.Separator, ...item });
    return this;
  }

  custom(item: ToolbarItemParamType<T, ToolbarItemCustom<T>>): this {
    this.items.push({ type: ToolbarItemType.Custom, ...item });
    return this;
  }

  buttonGroup(
    buildGroup: (builder: ToolbarButtonGroupBuilder<T>) => void,
  ): this {
    const builder = new ToolbarButtonGroupBuilder<T>();
    buildGroup(builder);
    this.items.push(builder.buildGroup());
    return this;
  }

  build(): Array<ToolbarItem<T>> {
    return this.items;
  }
}

export class ToolbarButtonGroupBuilder<T> {
  size?: 'small' | 'large';
  label?: string | ReactNode;
  placement?: ToolbarItemPlacement;
  private readonly buttons: Array<ToolbarItemButton<T>> = [];

  button(item: ToolbarItemParamType<T, ToolbarItemButton<T>>): this {
    this.buttons.push({ type: ToolbarItemType.Button, ...item });
    return this;
  }

  withLabel(label: string | ReactNode): this {
    this.label = label;
    return this;
  }

  withSize(size: 'small' | 'large'): this {
    this.size = size;
    return this;
  }

  small(): this {
    return this.withSize('small');
  }

  large(): this {
    return this.withSize('large');
  }

  withPlacement(placement: ToolbarItemPlacement): this {
    this.placement = placement;
    return this;
  }

  buildGroup(): ToolbarItemButtonGroup<T> {
    return {
      label: this.label,
      placement: this.placement,
      type: ToolbarItemType.ButtonGroup,
      buttons: this.buttons,
      size: this.size,
    };
  }
}
