import { ServiceEdition, Store } from 'model';
import { StoreEnrollmentParams } from 'model/StoreEnrollmentParams';
import { memo, useCallback, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { storeService } from 'services';
import {
  AsideRight,
  EntityEditorForm,
  EntityEditorFormBuilder,
} from 'shared/components';
import { Alert, Button } from 'shared/metronic/components';
import { durationFromString } from 'utils/serviceDurationUtil';

type Props = {
  store: Store;
  onClose?: () => void;
  onRenewed?: (store: Store) => void;
};

export const RenewAside = memo(
  ({
    store,
    onClose,
    onRenewed,
  }: Omit<Props, 'store'> & {
    store?: Store;
  }) => {
    const [loading, setLoading] = useState(false);
    return (
      <AsideRight open={Boolean(store)} onClose={onClose} spin={loading}>
        {store ? (
          <Content
            store={store}
            onRenewed={onRenewed}
            onClose={onClose}
            setLoading={setLoading}
          />
        ) : null}
      </AsideRight>
    );
  },
);

const Content = memo(
  ({
    store,
    onClose,
    onRenewed,
    setLoading,
  }: Props & {
    setLoading: (loading: boolean) => void;
  }) => {
    const [params, setParams] = useState<StoreEnrollmentParams>({
      editionId: store.editionId ?? '',
      userAccountLimit: store.userAccountLimit,
      isTrialEdition: store.isTrialEdition,
      isTvVersionEnabled: store.isTvEditionEnabled,
      isDataV1Enabled: store.isDataV1Enabled,
      isQuotationEnabled: store.isQuotationEnabled,
      isNewBiEnabled: store.isNewBiEnabled,
      isNewMarketingEnabled: store.isNewMarketingEnabled,
      renewCycle: store.serviceRenewCycle ?? '1y',
    });
    const [error, setError] = useState<Error>();

    const form = useMemo(() => {
      const builder = new EntityEditorFormBuilder<StoreEnrollmentParams>();
      builder
        .serviceEdition({
          prop: 'editionId',
          label: 'store.editor.label.service_edition',
          helpText: 'store.editor.help_text.service_edition',
          placeholder: 'store.editor.placeholder.service_edition',
          onChange(_changes, ...args) {
            const edition = args[1] as ServiceEdition | undefined;
            if (edition != null) {
              setParams(values => ({
                ...values,
                editionId: edition.sku,
                userAccountLimit: edition.userAccountLimit,
                renewTime: undefined,
                isTrialEdition: edition.isTrialEdition,
                isTvVersionEnabled: edition.isTvVersionEnabled,
                isDataV1Enabled: edition.isDataV1Enabled,
                isQuotationEnabled: edition.isQuotationEnabled,
                isNewBiEnabled: edition.isNewBiEnabled,
                isNewMarketingEnabled: edition.isNewMarketingEnabled,
              }));
            }
          },
        })
        .checkbox({
          prop: 'isTrialEdition',
          label: 'store.editor.label.trial_edition',
        })
        .text({
          type: 'number',
          prop: 'userAccountLimit',
          label: 'service_edition.editor.label.user_account_limit',
          placeholder: 'service_edition.editor.placeholder.user_account_limit',
          helpText: 'service_edition.editor.help_text.user_account_limit',
        })
        .serviceDuration({
          prop: 'renewCycle',
          label: 'store.editor.label.renew_cycle',
          placeholder: 'store.editor.placeholder.renew_cycle',
          required: true,
        })
        .text({
          type: 'datetime-local',
          prop: 'renewTime',
          label: 'store.editor.label.expire_time',
          placeholder: 'store.editor.placeholder.expire_time',
          helpText: 'store.editor.help_text.service_renew_time',
        })
        .checkbox({
          prop: 'isTvVersionEnabled',
          label: 'service_edition.editor.label.is_tv_version_enabled',
        })
        .checkbox({
          prop: 'isDataV1Enabled',
          label: 'service_edition.editor.label.is_data_v1_enabled',
        })
        .checkbox({
          prop: 'isQuotationEnabled',
          label: 'service_edition.editor.label.is_quotation_enabled',
        })
        .checkbox({
          prop: 'isNewBiEnabled',
          label: 'service_edition.editor.label.is_new_bi_enabled',
        })
        .checkbox({
          prop: 'isNewMarketingEnabled',
          label: 'service_edition.editor.label.is_new_marketing_enabled',
        });
      return builder.build();
    }, []);

    const onChange = useCallback((changes: Partial<StoreEnrollmentParams>) => {
      setParams(values => ({ ...values, ...changes }));
    }, []);

    const onGetExtraInfo = useCallback(() => ({}), []);

    const onRenewClick = useCallback(async () => {
      setLoading(true);
      setError(undefined);
      try {
        const result = await storeService.renew(store.id, params);
        onRenewed?.(result);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    }, [setLoading, store.id, params, onRenewed]);

    const [v, u] = durationFromString(params.renewCycle);

    const disabled = Boolean(!params.editionId) || !v || !u;

    return (
      <div className="entity-editor-sidebar">
        <ul
          className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
          style={{ marginBottom: 15 }}
        >
          <li className="nav-item m-tabs__item">
            <a
              className="nav-link m-tabs__link active"
              style={{ fontWeight: 'bold', fontSize: '120%' }}
            >
              <Translate id="store.renew.title" />
            </a>
          </li>
        </ul>
        {error && (
          <Alert color="danger" icon="fa fa-exclamation-triangle">
            {error.message}
          </Alert>
        )}
        <form className="entity-editor-form m-form">
          <div className="m-portlet__body">
            <div className="m-form__section m-form__section--first">
              <EntityEditorForm
                entity={params}
                onChange={onChange}
                elements={form.elements}
                autocomplete={form.autocomplete}
                useUncontrolled={form.useUncontrolled}
                onGetExtraInfo={onGetExtraInfo}
              />
            </div>
          </div>
        </form>
        <div className="m-portlet__foot m-portlet__foot--fit mt-5">
          <div className="m-form__actions m-form__actions text-center">
            <Button color="brand" onClick={onRenewClick} disabled={disabled}>
              <Translate id="store.renew.btn.renew" />
            </Button>
            &nbsp;
            <Button color="default" onClick={onClose}>
              <Translate id="cancel_btn_text" />
            </Button>
          </div>
        </div>
      </div>
    );
  },
);
