/**
 * @file: ServiceEditionType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ServiceEditionType {
  Qf = 'QF',
  S4 = '4S',
  Yz = 'YZ',
}

export const ServiceEditionTypeValueSet = new Set([
  ServiceEditionType.Qf,
  ServiceEditionType.S4,
  ServiceEditionType.Yz,
]);
