import { combineReducers, Reducer } from 'redux';
import { AppState } from 'app/duck/states';
import { localizeReducer } from 'react-localize-redux';

import user from './user';
import ui from './ui';
import areas from './areas';
import acl from './acl';

import dashboard from 'app/dashboard/duck/reducers';
import settings from 'app/settings/duck/reducers';
import customers from 'app/customers/duck/reducers';
import inspection from 'app/inspection/duck/reducers';
import orders from 'app/orders/duck/reducers';
import tasks from 'app/tasks/duck/reducers';
import reports from 'app/reports/duck/reducers';
import vehicles from 'app/vehicles/duck/reducers';
import modal from './modal';
import loading from './loading';
import openapi from 'app/openapi/duck/reducers';
import dataExportTasks from './data-export-task';
import sysBatchJobs from './batch-jobs';

export const rootReducer: Reducer<AppState> = combineReducers({
  localize: localizeReducer,
  user,
  ui,
  areas,
  acl,
  dashboard,
  settings,
  customers,
  inspection,
  orders,
  tasks,
  reports,
  vehicles,
  modal,
  loading,
  openapi,
  dataExportTasks,
  sysBatchJobs,
});
