/**
 * @file: stores.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { Store, StoreListFilter } from 'model';
import { storeService } from 'services';
import { ActionTypes } from '../types';

export const storeActions = createListAsyncActionCreators<
  AppState,
  Store,
  StoreListFilter,
  number
>('customers.stores', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.customers.stores.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.customers.stores.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.customers.stores.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await storeService.list(
      state.customers.stores.filter,
      state.customers.stores.sorts?.[0],
      state.customers.stores.offset,
      state.customers.stores.limit,
      { count: true, recursive: true },
    );
  },
  async create(store: Partial<Store>) {
    const serviceRemindMobiles = (store.serviceRemindMobile?.trim() ?? '')
      .split(/[,，]/g)
      .map(x => x.trim())
      .filter(x => x);
    return await storeService.create({
      ...store,
      serviceRemindMobile: serviceRemindMobiles.length
        ? serviceRemindMobiles.join(',')
        : undefined,
    });
  },
  async update(store: Store) {
    const serviceRemindMobiles = (store.serviceRemindMobile?.trim() ?? '')
      .split(/[,，]/g)
      .map(x => x.trim())
      .filter(x => x);
    return await storeService.update({
      ...store,
      serviceRemindMobile: serviceRemindMobiles.length
        ? serviceRemindMobiles.join(',')
        : undefined,
    });
  },
  async delete(store: Store) {
    await storeService.delete(store.id);
  },
  async deleteMulti(stores: Store[]) {
    if (stores.length === 1) {
      await storeService.delete(stores[0].id);
      return;
    }
    throw new Error('delete multi stores is not supported. ');
  },
});

export function downloadQrcodeForStore(store: Store | null) {
  return createStandardAction(ActionTypes.DownloadQrcodeForStore, store);
}

export function renewStore(store: Store | null) {
  return createStandardAction(ActionTypes.RenewStore, store);
}
