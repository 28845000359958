/**
 * @file: OrgTeamService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OrgTeamServiceBase } from './base/OrgTeamService';

export class OrgTeamService extends OrgTeamServiceBase {
  // add custom service implementation here
}
