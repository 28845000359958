import classNames from 'classnames';
import { ConstructionTemplateInfo } from 'model';
import { memo, useCallback } from 'react';
import { FormatOptionLabelMeta } from 'react-select';
import { Select, getString } from 'shared/components';

export const TemplatePicker = memo(
  ({
    templates,
    value,
    disabled,
    placeholder,
    emptyText,
    onChange,
  }: {
    templates: ConstructionTemplateInfo[];
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    emptyText?: string;
    onChange?: (templateId: string | undefined) => void;
  }) => {
    const selected = templates.filter(x => x.id === value)[0];

    const handleChange = useCallback(
      (val: ConstructionTemplateInfo | undefined) => {
        onChange?.(val?.id);
      },
      [onChange],
    );

    const renderLabel = useCallback(
      (
        template: ConstructionTemplateInfo,
        meta: FormatOptionLabelMeta<ConstructionTemplateInfo>,
      ) => {
        if (meta.context === 'value') {
          return (
            <div
              className={classNames('construction-tpl-picker__value-content')}
            >
              {template.procedures.map(x => (
                <span key={x.id}>{x.name}</span>
              ))}
            </div>
          );
        }
        const current = Array.isArray(meta.selectValue)
          ? meta.selectValue[0]
          : meta.selectValue;
        console.log('render option: ', template.name, current?.name);

        return (
          <dl
            className={classNames('construction-tpl-picker__option', {
              'construction-tpl-picker__option--selected':
                current?.id === template.id,
            })}
          >
            <dt>{template.name}</dt>
            <dd>
              {template.procedures.map(x => (
                <span key={x.id}>{x.name}</span>
              ))}
            </dd>
          </dl>
        );
      },
      [],
    );

    return (
      <Select
        values={templates}
        valueProp={'id'}
        labelProp={'name'}
        disabled={disabled}
        selectedValue={selected}
        classNamePrefix="construction-tpl-picker"
        placeholder={
          placeholder ?? getString('construction_tpl.placeholder.template')
        }
        isClearable
        className="construction-tpl-picker"
        onFormatOptionLabel={renderLabel}
        onChange={handleChange}
        isSearchable={false}
        noOptionsMessage={
          emptyText ?? getString('construction_tpl.picker.empty_text')
        }
      />
    );
  },
);
