/**
 * @file: UserVehicleBindingCertificateService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { UserVehicleBindingCertificateServiceBase } from './base/UserVehicleBindingCertificateService';

export class UserVehicleBindingCertificateService extends UserVehicleBindingCertificateServiceBase {
  // add custom service implementation here
}
