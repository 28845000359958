import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { Agent } from 'model';
import { Agents } from '../states';

const initialState: Agents = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

export default createAsyncListActionReducers<Agent, Agents>(
  'customers.agents',
  initialState,
  {
    mapItemKey: x => x.id,
  },
);
