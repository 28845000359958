import {
  createAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { constructionTemplateConfigService } from 'services';
import { ActionTypes } from '../types';
import { Draft, produce } from 'immer';
import { ConstructionTemplateConfig } from 'model';
import { ActionThunk } from 'lib/duck/interfaces';
import { AppState } from 'app/duck/states';
import { ConstructionTemplateConfigState } from 'app/inspection/duck/states';

const actions = createAsyncActionCreators(
  'inspection.construction-template-config',
  {
    shouldFetchOnInvalidate: true,
    fetchHandler: () =>
      constructionTemplateConfigService.getConstructionTemplateConfig(),
  },
);

export function makeDefaultConstructionTemplateConfig(): ConstructionTemplateConfig {
  return {
    version: '1.0',
    categories: [],
    templates: [],
  };
}

export const {
  fetch: fetchConstructionTemplateConfig,
  invalidate: refreshConstructionTemplateConfig,
} = actions;

function selectSidebarItem(
  item:
    | { type: 'category'; categoryId: string }
    | { type: 'template'; templateId: string }
    | undefined,
) {
  return createStandardAction(
    ActionTypes.ConstructionTemplateConfigSelectSidebarItem,
    item,
  );
}

function applyChangesToTemplateConfig(
  recipe: (
    draft: ConstructionTemplateConfig,
    state: Draft<ConstructionTemplateConfigState>,
  ) => void,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    let templateConfigState = produce(
      state.inspection.constructionTemplateConfig,
      draft => {
        if (!draft.templateConfigBeingEdited) {
          draft.templateConfigBeingEdited =
            draft.result ?? makeDefaultConstructionTemplateConfig();
        }
      },
    );
    templateConfigState = produce(templateConfigState, draft => {
      recipe(draft.templateConfigBeingEdited!, draft);
    });
    dispatch(
      createStandardAction(
        ActionTypes.ConstructionTemplateConfigChanged,
        templateConfigState,
      ),
    );
  };
}

function resetChanges(withConfig?: ConstructionTemplateConfig) {
  return createStandardAction(
    ActionTypes.ResetConstructionTemplateConfigChanges,
    withConfig,
  );
}

export const constructionTplActions = {
  ...actions,
  selectSidebarItem,
  applyChangesToTemplateConfig,
  resetChanges,
};
