import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { User } from 'model';
import { userService } from 'services';

const actionCreators = createListAsyncActionCreators<AppState, User>(
  'dashboard.recent_users',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      return await userService.list(
        state.dashboard.recentUsers.filter || {},
        state.dashboard.recentUsers.sorts?.[0],
        state.dashboard.recentUsers.offset,
        state.dashboard.recentUsers.limit
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentUsers,
  invalidate: invalidateDashboardRecentUsers
} = actionCreators;