import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleReceptionOrder } from 'model';
import { vehicleReceptionOrderService } from 'services';

const actionCreators = createListAsyncActionCreators<AppState, VehicleReceptionOrder>(
  'dashboard.recent_reception_orders',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      return await vehicleReceptionOrderService.list(
        state.dashboard.recentReceptionOrders.filter || {},
        state.dashboard.recentReceptionOrders.sorts?.[0],
        state.dashboard.recentReceptionOrders.offset,
        state.dashboard.recentReceptionOrders.limit,
        { count: false, recursive: true }
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentReceptionOrders,
  invalidate: invalidateDashboardRecentReceptionOrders
} = actionCreators;