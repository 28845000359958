/**
 * @file: ServiceEditionVariantType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ServiceEditionVariantType {
  Basic = 'basic',
  Standard = 'standard',
  Premium = 'premium',
}

export const ServiceEditionVariantTypeValueSet = new Set([
  ServiceEditionVariantType.Basic,
  ServiceEditionVariantType.Standard,
  ServiceEditionVariantType.Premium,
]);
