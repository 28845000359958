import classNames from 'classnames';
import { Component, ReactNode } from 'react';

interface Props {
  [name: string]: any;
  align?: 'left' | 'center' | 'right';
  title?: string | ReactNode;
  subTitle?: string | ReactNode;
  className?: string;
  children?: ReactNode;
}

export class DropdownHeader extends Component<Props> {
  static displayName = 'DropdownHeader';

  render() {
    const { align, title, subTitle, className, children, ...props } =
      this.props;
    return (
      <div
        className={classNames(
          `m-dropdown__header m--align-${align || 'left'}`,
          className,
        )}
        {...props}
      >
        {children || (
          <>
            <span className="m-dropdown__header-title">{title}</span>
            {subTitle && (
              <span className="m-dropdown__header-subtitle">{subTitle}</span>
            )}
          </>
        )}
      </div>
    );
  }
}
