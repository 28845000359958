import React, { Component, ReactNode } from 'react';
import { Scrollable } from './Scrollable';
import { getViewportHeight } from 'utils/ViewportSize';

interface Props {
  children?: ReactNode;
}

interface State {
  height: number | undefined;
}

export class SideMenu extends Component<Props, State> {
  private readonly containerElRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      height: this.getHeight()
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate() {
    const height = this.getHeight();
    if (this.state.height !== height) {
      this.setState({ height });
    }
  }

  onResize = () => {
    this.setState({ height: this.getHeight() });
  }

  getHeight(): number | undefined {
    const elHeader = document.querySelector('#m_header');
    if (!elHeader) return undefined;
    return getViewportHeight() - elHeader.clientHeight;
  }

  render() {
    return (
      <Scrollable
        containerElRef={this.containerElRef}
        className="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark"
        options={{ suppressScrollX: true }}
        height={this.state.height}
        >
        <ul className="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
          {this.props.children}
        </ul>
      </Scrollable>
    );
  }
}