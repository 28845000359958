import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import { SubjectListItem } from 'app/inspection/quotation-template-config/subject/SubjectListItem';
import { makeSubjectRef } from 'app/inspection/quotation-template-config/util';
import { QuotationGroupRef, QuotationTemplateGroupStaged } from 'model';
import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import { EmptySubjects } from './EmptySubjects';

export const SubjectList = memo(
  ({
    group,
    editable,
  }: {
    editable: boolean;
    group: QuotationTemplateGroupStaged;
  }) => {
    const { state } = useQuotationTemplateConfigContext();

    const dispatch = useDispatch();

    const groupRef = useMemo<QuotationGroupRef>(
      () => ({ categoryId: group.categoryId, groupId: group.id }),
      [group.categoryId, group.id],
    );

    const onAddSubject = useCallback(() => {
      dispatch(quotationTplActions.addSubject(groupRef));
    }, [dispatch, groupRef]);

    const onEditSubject = useCallback(
      (subjectId: string) => {
        dispatch(
          quotationTplActions.editSubject(makeSubjectRef(groupRef, subjectId)),
        );
      },
      [dispatch, groupRef],
    );

    const onRemoveSubject = useCallback(
      (subjectId: string) => {
        dispatch(
          quotationTplActions.removeSubject(
            makeSubjectRef(groupRef, subjectId),
          ),
        );
      },
      [dispatch, groupRef],
    );

    const onSubjectSelected = useCallback(
      (subjectId: string) => {
        dispatch(
          quotationTplActions.subjectSelected(
            makeSubjectRef(groupRef, subjectId),
          ),
        );
      },
      [dispatch, groupRef],
    );

    const onSubjectSortEnd: SortEndHandler = useCallback(
      e => {
        const { newIndex, oldIndex } = e;
        if (newIndex === oldIndex) return;
        dispatch(
          quotationTplActions.subjectMoved(groupRef, oldIndex, newIndex),
        );
      },
      [dispatch, groupRef],
    );

    return (
      <div className="quotation-tpl__subject-panel">
        {group.subjects.length === 0 ? (
          <EmptySubjects editable={editable} onAdd={onAddSubject} />
        ) : (
          <>
            <Header editable={editable} onAddSubject={onAddSubject} />
            <SortableSubjectList
              helperClass="quotation-tpl__subject--being-dragged"
              onSortEnd={onSubjectSortEnd}
              lockAxis="y"
              useWindowAsScrollContainer={true}
              distance={4}
            >
              {group.subjects.map((subject, index) => (
                <SortableSubjectListItem
                  key={subject.id}
                  subject={subject}
                  index={index}
                  selected={subject.id === state.selectedSubjectRef?.subjectId}
                  editable={editable}
                  onClick={onSubjectSelected}
                  onEdit={onEditSubject}
                  onRemove={onRemoveSubject}
                />
              ))}
            </SortableSubjectList>
          </>
        )}
      </div>
    );
  },
);

const Header = memo(
  ({
    editable,
    onAddSubject,
  }: {
    editable: boolean;
    onAddSubject: () => void;
  }) => {
    return (
      <div className="quotation-tpl__subject-panel-hd">
        <h3>
          <Translate id="quotation_tpl.subject.list.title" />
          {editable && (
            <LinkButton onClick={onAddSubject}>
              <i className={`la la-plus`} />
            </LinkButton>
          )}
        </h3>
      </div>
    );
  },
);

const ListContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="quotation-tpl__subject-list-sortable">{children}</div>;
};

const SortableSubjectList = SortableContainer(ListContainer);
const SortableSubjectListItem = SortableElement(SubjectListItem);
