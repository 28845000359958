/**
 * @file: auth.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import { Identity, AdminUser, LoginResult } from 'model';

export class AuthService {
  constructor(private readonly api: APIService) {}

  async getIdentity(args?: { returnUrl?: string }): Promise<Identity> {
    return await this.api
      .get()
      .url('/auth/identity', Object.assign({}, args))
      .future();
  }

  async getUserInfo(): Promise<AdminUser> {
    return await this.api.get().url('/auth/me').future();
  }

  async login(userName: string, password: string): Promise<LoginResult> {
    return await this.api.post().url('/auth').data({ userName, password }).future();
  }
}
