/**
 * @file index.tsx
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

import './chartjs-defaults';
import './polyfills';
import './support';

import { store } from 'app/duck/store';
import { ElementQueries } from 'css-element-queries';
import { ContinueContext, storageService, tokenService } from 'lib';
import qs from 'qs';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './app/App';

import { enableMapSet, enablePatches } from 'immer';
import { createRoot } from 'react-dom/client';

enableMapSet();
enablePatches();

// get the continue url before the weixin oauth2 login.
function getContinueUrl() {
  const continueContext =
    storageService.ls_get<ContinueContext>('.continue_context');
  if (continueContext?.url) {
    return continueContext.url;
  }
  const query = qs.parse(location.search.substr(1));
  delete query.token;
  delete query.openid;
  if (Object.keys(query).length) {
    return `${location.pathname}?${qs.stringify(query)}`;
  } else {
    return location.pathname;
  }
}

// handle oauth2 redirection/callback.
function handleOAuth2Redirect(): boolean {
  const query = qs.parse(location.search.substr(1));
  const { token } = query;
  if (token) {
    if (tokenService.verifyTokenAndOpenId(token)) {
      tokenService.setToken(token);
      location.href = getContinueUrl();
      return true;
    }
  }
  return false;
}

function init() {
  require('./init');

  const mainNavStatus = storageService.ls_get('mainNavStatus');
  if (mainNavStatus === 'minimized') {
    document.body.classList.add('m-brand--minimize', 'm-aside-left--minimize');
  }

  if (!handleOAuth2Redirect()) {
    const root = createRoot(document.getElementById('application')!);
    root.render(
      <Provider store={store}>
        <LocalizeProvider store={store}>
          <Router>
            <App />
          </Router>
        </LocalizeProvider>
      </Provider>,
    );
  }
}

init();

document.addEventListener('DOMContentLoaded', () => {
  ElementQueries.listen();
});
