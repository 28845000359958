/**
 * @file: AutoPartNameService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AutoPartNameServiceBase } from './base/AutoPartNameService';

export class AutoPartNameService extends AutoPartNameServiceBase {
  // add custom service implementation here
  async import(fileDataUri: string): Promise<number> {
    return await this.api
      .post()
      .url('/auto-part-names/import')
      .data({ data: fileDataUri })
      .future();
  }
}
