import React, { Component, ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';
import { ColorType } from 'model';

interface Props {
  text?: string | ReactNode;
  state?: string;
  time: string;
  leftBadgeColor?: ColorType;
  badge?: string;
  badgeColor?: ColorType;
  badgeWide?: boolean;
  read?: boolean;
  timeStyle?: CSSProperties;
}

export class TimelineItem extends Component<Props> {
  render() {
    const {
      text,
      time,
      badge,
      badgeColor,
      badgeWide,
      read,
      state,
      leftBadgeColor,
      timeStyle
    } = this.props;
    return (
      <div className={classNames('m-list-timeline__item', {
        'm-list-timeline__item--read': read,
        [`m-list-timeline__badge--state-${state}`]: state
      })}>
        <span className={classNames('m-list-timeline__badge', {
          [`m-list-timeline__badge--${leftBadgeColor}`]: leftBadgeColor
        })} />
        <span className="m-list-timeline__text">
          {text}
          {badge &&
          <>
          &nbsp;
          <span className={classNames(
            'm-badge',
            `m-badge--${badgeColor || 'success'}`,
            { 'm-badge--wide': badgeWide }
            )}
            >
            {badge}
          </span>
          </>}
        </span>
        <span className="m-list-timeline__time" style={timeStyle}>
          {time}
        </span>
      </div>
    );
  }
}