/**
 * @file: VehicleInspectionTaskDeliveryCheckMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskDeliveryCheckMedia,
  VehicleInspectionTaskDeliveryCheckMediaListFilter,
} from 'model';

export class VehicleInspectionTaskDeliveryCheckMediaServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskDeliveryCheckMediaListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskDeliveryCheckMedia>
      : VehicleInspectionTaskDeliveryCheckMedia[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-delivery-check-medias',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(
    id: number,
  ): Promise<VehicleInspectionTaskDeliveryCheckMedia | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-delivery-check-medias/:id', { id })
      .future();
  }

  async getMediaByCid(
    cid: string,
  ): Promise<VehicleInspectionTaskDeliveryCheckMedia | null> {
    if (!cid) {
      throw new Error('missing cid parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-task-delivery-check-medias/query/cid', { cid })
      .future();
  }

  async create(
    vehicleInspectionTaskDeliveryCheckMedia: Partial<VehicleInspectionTaskDeliveryCheckMedia>,
  ): Promise<VehicleInspectionTaskDeliveryCheckMedia> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-delivery-check-medias')
      .data(vehicleInspectionTaskDeliveryCheckMedia)
      .future();
  }

  async update(
    vehicleInspectionTaskDeliveryCheckMedia: Partial<VehicleInspectionTaskDeliveryCheckMedia>,
  ): Promise<VehicleInspectionTaskDeliveryCheckMedia> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-delivery-check-medias/:id', {
        id: vehicleInspectionTaskDeliveryCheckMedia.id,
      })
      .data(vehicleInspectionTaskDeliveryCheckMedia)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-delivery-check-medias/:id', { id })
      .future();
  }
}
