import classNames from 'classnames';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import PerfectScrollbar from 'perfect-scrollbar';
import React, { Component, HTMLProps, ReactNode, RefObject } from 'react';

import './Scrollable.scss';

type HandlerType = 'click-rail' | 'drag-thumb' | 'keyboard' | 'wheel' | 'touch';

interface Options {
  /**
   * a list of handlers to scroll the element.
   * Default: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch']
   */
  handlers?: HandlerType[];

  /**
   * The scroll speed applied to mousewheel event.
   * Default: 1
   */
  wheelSpeed?: number;

  /**
   *  If this option is true, when the scroll reaches the end of the side,
   * mousewheel event will be propagated to parent element.
   * Default: false
   */
  wheelPropagation?: boolean;

  /**
   * If this option is true, swipe scrolling will be eased.
   * Default: true
   */
  swipeEasing?: boolean;

  /**
   * When set to an integer value, the thumb part of the scrollbar will not
   * shrink below that number of pixels.
   * Default: null
   */
  minScrollbarLength?: number;

  /**
   * When set to an integer value, the thumb part of the scrollbar will not
   * expand over that number of pixels.
   * Default: null
   */
  maxScrollbarLength?: number;

  /**
   * This sets threashold for ps--scrolling-x and ps--scrolling-y classes to
   * remain. In the default CSS, they make scrollbars shown regardless of
   * hover state. The unit is millisecond.
   * Default: 1000
   */
  scrollingThreshold?: number;

  /**
   * When set to true, and only one (vertical or horizontal) scrollbar is
   * visible then both vertical and horizontal scrolling will affect the scrollbar.
   * Default: false
   */
  useBothWheelAxes?: boolean;

  /**
   * When set to true, the scroll bar in X axis will not be available,
   * regardless of the content width.
   * Default: false
   */
  suppressScrollX?: boolean;

  /**
   * When set to true, the scroll bar in Y axis will not be available,
   * regardless of the content height.
   * Default: false
   */
  suppressScrollY?: boolean;

  /**
   * The number of pixels the content width can surpass the container width
   * without enabling the X axis scroll bar. Allows some "wiggle room" or
   * "offset break", so that X axis scroll bar is not enabled just because of
   * a few pixels.
   * Default: 0
   */
  scrollXMarginOffset?: number;
}

interface Props extends HTMLProps<HTMLDivElement> {
  [name: string]: any;
  options?: Options;
  children?: ReactNode;
  height?: number | string;
  containerElRef?: RefObject<HTMLDivElement>;
}

export class Scrollable extends Component<Props> {
  private readonly containerElRef: RefObject<HTMLDivElement>;
  private ps: PerfectScrollbar | null | undefined;
  private rs: ResizeSensor | null | undefined;

  constructor(props: Props) {
    super(props);
    this.containerElRef =
      this.props.containerElRef || React.createRef<HTMLDivElement>();
  }

  componentDidMount() {
    const containerEl = this.containerElRef.current;
    if (containerEl) {
      const options = Object.assign(
        {},
        {
          wheelSpeed: 0.5,
          swipeEasing: true,
          wheelPropagation: false,
          minScrollbarLength: 40,
        },
        this.props.options,
      );
      this.ps = new PerfectScrollbar(containerEl, options);
      this.rs = new ResizeSensor(containerEl, this.onContainerResize);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.height !== prevProps.height) {
      this.ps && this.ps.update();
    }
  }

  componentWillUnmount() {
    this.rs && this.rs.detach(this.onContainerResize);
    this.ps && this.ps.destroy();
  }

  render() {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      options: _options,
      className,
      style,
      height,
      children,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      containerElRef: _containerElRef,
      ...props
    } = this.props;
    const styles = Object.assign({}, { height }, style);
    return (
      <div
        ref={this.containerElRef}
        className={classNames('m-scroller', className)}
        style={styles}
        data-scollable="true"
        {...props}
      >
        {children}
      </div>
    );
  }

  private readonly onContainerResize = () => {
    this.ps && this.ps.update();
  };
}
