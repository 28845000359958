import { Agent } from 'model';
import { PureComponent } from 'react';
import { AgentPicker } from 'shared/components/AgentPicker';
import { EntityWithAgentBounded, FormElementAgentPicker } from '../types';

interface Props<T extends EntityWithAgentBounded> {
  element: FormElementAgentPicker<T>;
  disabled?: boolean;
  agentId?: number | null;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormAgentPicker<
  T extends EntityWithAgentBounded,
> extends PureComponent<Props<T>> {
  render() {
    const { agentId, disabled } = this.props;
    return (
      <AgentPicker
        agentId={agentId}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (agentId: number | undefined, agent: Agent | null) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = agentId as any;
    void element.onChange?.(changes as any, extra, agent);
    this.props.onChange(changes as T);
  };
}
