/**
 * @file: OpenapiEventType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum OpenapiEventType {
  InternalTest = 'internal:test',
  OrderCreated = 'order:created',
  OrderUpdated = 'order:updated',
  OrderCanceled = 'order:canceled',
  OrderDeleted = 'order:deleted',
  OrderFinished = 'order:finished',
  OrderRollback = 'order:rollback',
  DashboardInfoChanged = 'dashboard-info:changed',
  PreInspectionSiteUpdated = 'pre-inspection:site:updated',
  PreInspectionStatusUpdated = 'pre-inspection:status:updated',
  PreInspectionUpdated = 'pre-inspection:updated',
  PreInspectionSiteCanceled = 'pre-inspection:site:canceled',
  PreInspectionCanceled = 'pre-inspection:canceled',
  InspectionFlowAdded = 'inspection:flow:added',
  InspectionFlowUpdated = 'inspection:flow:updated',
  InspectionFlowCanceled = 'inspection:flow:canceled',
  InspectionFlowStatusUpdated = 'inspection:flow:status:updated',
  InspectionStatusUpdated = 'inspection:status:updated',
  InspectionSiteUpdated = 'inspection:site:updated',
  InspectionUpdated = 'inspection:updated',
  InspectionSiteCanceled = 'inspection:site:canceled',
  InspectionCanceled = 'inspection:canceled',
  ConstructionJobsUpdated = 'construction:jobs:updated',
  ConstructionUpdated = 'construction:updated',
  ConstructionJobUpdated = 'construction:job:updated',
  ConstructionJobCanceled = 'construction:job:canceled',
  ConstructionJobStatusUpdated = 'construction:job:status:updated',
  ConstructionStatusUpdated = 'construction:status:updated',
  ConstructionCanceled = 'construction:canceled',
}

export const OpenapiEventTypeValueSet = new Set([
  OpenapiEventType.InternalTest,
  OpenapiEventType.OrderCreated,
  OpenapiEventType.OrderUpdated,
  OpenapiEventType.OrderCanceled,
  OpenapiEventType.OrderDeleted,
  OpenapiEventType.OrderFinished,
  OpenapiEventType.OrderRollback,
  OpenapiEventType.DashboardInfoChanged,
  OpenapiEventType.PreInspectionSiteUpdated,
  OpenapiEventType.PreInspectionStatusUpdated,
  OpenapiEventType.PreInspectionUpdated,
  OpenapiEventType.PreInspectionSiteCanceled,
  OpenapiEventType.PreInspectionCanceled,
  OpenapiEventType.InspectionFlowAdded,
  OpenapiEventType.InspectionFlowUpdated,
  OpenapiEventType.InspectionFlowCanceled,
  OpenapiEventType.InspectionFlowStatusUpdated,
  OpenapiEventType.InspectionStatusUpdated,
  OpenapiEventType.InspectionSiteUpdated,
  OpenapiEventType.InspectionUpdated,
  OpenapiEventType.InspectionSiteCanceled,
  OpenapiEventType.InspectionCanceled,
  OpenapiEventType.ConstructionJobsUpdated,
  OpenapiEventType.ConstructionUpdated,
  OpenapiEventType.ConstructionJobUpdated,
  OpenapiEventType.ConstructionJobCanceled,
  OpenapiEventType.ConstructionJobStatusUpdated,
  OpenapiEventType.ConstructionStatusUpdated,
  OpenapiEventType.ConstructionCanceled,
]);
