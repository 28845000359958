/**
 * @file: ServiceEnrollmentType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ServiceEnrollmentType {
  New = 'new',
  Renew = 'renew',
}

export const ServiceEnrollmentTypeValueSet = new Set([
  ServiceEnrollmentType.New,
  ServiceEnrollmentType.Renew,
]);
