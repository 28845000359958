import { Component } from 'react';

export class Oops extends Component {
  render() {
    return (
      <div
        className="m-grid__item m-grid__item--fluid m-grid  m-error-6"
        style={{
          backgroundImage: 'url(vendors/metronic/base/images/bg/bg-2.jpg)',
        }}
      >
        <div className="m-error_container">
          <div className="m-error_subtitle m--font-light">
            <h1>Oops...</h1>
            <p className="m-error_description m--font-light">
              Looks like something went wrong.
              <br />
              {`We're working on it`}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
