/**
 * @file: VehicleInspectionTaskDiagnosticJobService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskDiagnosticJobServiceBase } from './base/VehicleInspectionTaskDiagnosticJobService';

export class VehicleInspectionTaskDiagnosticJobService extends VehicleInspectionTaskDiagnosticJobServiceBase {
  // add custom service implementation here
}
