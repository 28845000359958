/**
 * @file: AbnormalLevelLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DataExportTaskRequest } from 'model/DataExportTaskRequest';
import React, { CSSProperties } from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue } from '../LabelColors';

interface Props {
  value: DataExportTaskRequest['target']['type'] | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  style?: CSSProperties;
  color?: ColorValue;
}

const Colors: { [p in DataExportTaskRequest['target']['type']]: string } = {
  agent: 'accent',
  org: 'brand',
  store: 'info',
};

export const DataExportTaskTargetTypeLabel = React.memo<Props>(props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value, color, ...otherProps } = props;

  const labelColor = value && Colors[value];

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`data_export_task_target_type.${value}`} />
    </Badge>
  );
});
