import classNames from 'classnames';
import React, { Component, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
}

export class Checkbox extends Component<Props> {
  private readonly el = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.el.current) {
      this.el.current.indeterminate = Boolean(this.props.indeterminate);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      if (this.el.current) {
        this.el.current.indeterminate = Boolean(this.props.indeterminate);
      }
    }
  }

  render() {
    const { indeterminate, className, ...props } = this.props;
    return (
      <input
        type="checkbox"
        ref={this.el}
        className={classNames(className, {
          indeterminate
        })}
        {...props}
        />
    );
  }
}