/**
 * @file: VehicleInspectionTaskCustomIssueMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskCustomIssueMedia,
  VehicleInspectionTaskCustomIssueMediaListFilter,
} from 'model';

export class VehicleInspectionTaskCustomIssueMediaServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskCustomIssueMediaListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskCustomIssueMedia>
      : VehicleInspectionTaskCustomIssueMedia[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-custom-issue-medias',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskCustomIssueMedia | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-custom-issue-medias/:id', { id })
      .future();
  }

  async getMediaByCid(
    cid: string,
  ): Promise<VehicleInspectionTaskCustomIssueMedia | null> {
    if (!cid) {
      throw new Error('missing cid parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-task-custom-issue-medias/query/cid', { cid })
      .future();
  }

  async create(
    vehicleInspectionTaskCustomIssueMedia: Partial<VehicleInspectionTaskCustomIssueMedia>,
  ): Promise<VehicleInspectionTaskCustomIssueMedia> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-custom-issue-medias')
      .data(vehicleInspectionTaskCustomIssueMedia)
      .future();
  }

  async update(
    vehicleInspectionTaskCustomIssueMedia: Partial<VehicleInspectionTaskCustomIssueMedia>,
  ): Promise<VehicleInspectionTaskCustomIssueMedia> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-custom-issue-medias/:id', {
        id: vehicleInspectionTaskCustomIssueMedia.id,
      })
      .data(vehicleInspectionTaskCustomIssueMedia)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-custom-issue-medias/:id', { id })
      .future();
  }
}
