import { ProductAgent } from 'model';
import { PureComponent } from 'react';
import { ProductAgentPicker } from 'shared/components/ProductAgentPicker';
import {
  EntityWithProductAgentBounded,
  FormElementProductAgentPicker,
} from '../types';

interface Props<T extends EntityWithProductAgentBounded> {
  element: FormElementProductAgentPicker<T>;
  disabled?: boolean;
  agentId?: number | null;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormProductAgentPicker<
  T extends EntityWithProductAgentBounded,
> extends PureComponent<Props<T>> {
  render() {
    const { agentId, disabled } = this.props;
    return (
      <ProductAgentPicker
        agentId={agentId}
        disabled={disabled}
        onChange={this.onChange}
      />
    );
  }

  onChange = (agentId: number | undefined, agent: ProductAgent | null) => {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = agentId as any;
    void element.onChange?.(changes as any, extra, agent);
    this.props.onChange(changes as T);
  };
}
