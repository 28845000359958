import classNames from 'classnames';
import React from 'react';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  getString,
} from 'shared/components';
import { OpenApiUser, OpenApiUserListFilter, AclObjectList } from 'model';
import { Translate } from 'react-localize-redux';
import { openApiUserActions } from '../duck/actions';
import { formatTime } from 'utils';
import { isEmail, isMobile } from 'utils/validators';

interface Props extends EntityListProps<OpenApiUser, OpenApiUserListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OpenApiUser,
  OpenApiUserListFilter,
  number,
  Props
>();

export const OpenApiUserList = componentClassBuilder
  .i18nPrefix('openapi_users')
  .accessRights({
    full: AclObjectList.OpenApiUserFullAccess,
    readonly: AclObjectList.OpenApiUserReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="openapi.breadcrumb.it" /> },
    { text: <Translate id="openapi.breadcrumb.users" /> },
  ])
  .entities(state => state.openapi.users)
  .actions(openApiUserActions)
  .editor(builder =>
    builder
      .text({
        prop: 'userName',
        label: 'openapi_users.editor.label.user_name',
        placeholder: 'openapi_users.editor.placeholder.user_name',
        helpText: 'openapi_users.editor.help_text.user_name',
        autocomplete: false,
        disabled: (_: any, props: Props) =>
          Boolean(props.entities.itemBeingUpdated),
      })
      .text({
        type: 'password',
        prop: 'password',
        label: 'openapi_users.editor.label.password',
        placeholder: 'openapi_users.editor.placeholder.password',
        helpText: 'openapi_users.editor.help_text.password',
        autocomplete: false,
      })
      .text({
        prop: 'name',
        label: 'openapi_users.editor.label.name',
        placeholder: 'openapi_users.editor.placeholder.name',
        autocomplete: false,
      })
      .text({
        prop: 'email',
        label: 'openapi_users.editor.label.email',
        placeholder: 'openapi_users.editor.placeholder.email',
        autocomplete: false,
      })
      .text({
        prop: 'mobile',
        label: 'openapi_users.editor.label.mobile',
        placeholder: 'openapi_users.editor.placeholder.mobile',
        autocomplete: false,
      })
      .checkbox({
        prop: 'enabled',
        label: 'openapi_users.editor.label.enabled',
      }),
  )
  .toolbarItems(builder =>
    builder
      .text({
        prop: 'userName',
        label: 'openapi_users.toolbar.label.user_name',
        placeholder: 'openapi_users.toolbar.placeholder.user_name',
      })
      .text({
        prop: 'name',
        label: 'openapi_users.editor.label.name',
        placeholder: 'openapi_users.toolbar.placeholder.name',
      })
      .text({
        prop: 'email',
        label: 'openapi_users.editor.label.email',
        placeholder: 'openapi_users.toolbar.placeholder.email',
      })
      .text({
        prop: 'mobile',
        label: 'openapi_users.editor.label.mobile',
        placeholder: 'openapi_users.toolbar.placeholder.mobile',
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(openApiUserActions.invalidate(true));
        },
      }),
  )
  .addActionButtons(['edit', 'remove'])
  .columns([
    {
      prop: 'userName',
      width: 120,
      text: 'col.user_name',
    },
    {
      prop: 'name',
      width: 120,
      text: 'col.name',
    },
    {
      prop: 'email',
      width: 200,
      text: 'col.email',
      render: (openApiUser: OpenApiUser) => openApiUser.email || '-',
    },
    {
      prop: 'mobile',
      width: 200,
      text: 'col.mobile',
      render: (openApiUser: OpenApiUser) => openApiUser.email || '-',
    },
    {
      prop: 'enabled',
      width: 80,
      text: 'col.enabled',
      align: 'center',
      render: ({ enabled }) => (
        <i
          className={classNames({
            'fa fa-user-check': enabled,
            'fa fa-user-times': !enabled,
            'm--font-success': enabled,
            'm--font-danger': !enabled,
          })}
        />
      ),
    },
    {
      prop: 'createdAt',
      width: 150,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt!),
    },
  ])
  .onAdd(user => {
    user.enabled = true;
  })
  .validate((entity, isCreating) => {
    const userName = entity.userName?.trim();
    const password = entity.password?.trim();
    const email = entity.email?.trim();
    const mobile = entity.mobile?.trim();

    let msg = '';

    if (!userName) {
      msg = 'user_name_required';
    } else if (!/^[a-z][a-z0-9_]{0,19}$/.test(userName)) {
      msg = 'invalid_user_name';
    } else if (isCreating && !password) {
      msg = 'password_required';
    } else if (isCreating && (password!.length < 5 || password!.length > 30)) {
      msg = 'password_length_error';
    } else if (!mobile) {
      msg = 'mobile_required';
    } else if (!isMobile(mobile)) {
      msg = 'invalid_mobile';
    } else if (!email) {
      msg = 'email_required';
    } else if (email && !isEmail(email)) {
      msg = 'invalid_email';
    }

    if (msg) {
      throw new Error(getString(`openapi_users.editor.error.${msg}`));
    }
  })
  .getClass();
