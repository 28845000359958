import React, { Component } from 'react';

interface Props {
  children?: React.ReactNode;
}

export class Breadcrumb extends Component<Props> {
  static displayName = 'Breadcrumb';
  render() {
    return (
      <ul className="m-subheader__breadcrumbs m-nav m-nav--inline">
      {this.props.children}
      </ul>
    );
  }
}