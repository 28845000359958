/**
 * @file storage.service.ts
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

export type Predict<T> = (obj: T) => boolean;
export type ResultFn<T, U, V> = (x: T, y: U) => V;

interface Provider {
  getItem: (key: string) => any;
  setItem: (key: string, value: string) => void;
  removeItem: (key: string) => void;
}

if (!(window as any).__offlineDataCache) {
  (window as any).__offlineDataCache = {};
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const memoryCache = (window as any).__offlineDataCache;

/**
 * localStorage polyfill.
 */
let localStorage = window.localStorage;
if (!localStorage) {
  localStorage = {
    getItem() {
      return null;
    },
    setItem() {
      /* noop*/
    },
    removeItem() {
      /* noop */
    },
  } as any;
}

/**
 * sessionStorage polyfill
 */
let sessionStorage = window.sessionStorage;
if (!sessionStorage) {
  sessionStorage = {
    getItem() {
      return null;
    },
    setItem() {
      /* noop */
    },
    removeItem() {
      /* noop */
    },
  } as any;
}

type PostFn<T> = (value: T) => T;

/**
 * @class local storage helper service.
 */
export class StorageService {
  /**
   * Get localStorage data by key and given default value.
   * @param {String} key the key of the data to get.
   * @param {Any} defaultValue default value if the the data does not exist.
   * @param {Function} postFn post function to handle the data value.
   */
  ls_get<T>(key: string, defaultValue?: T | PostFn<T>, postFn?: PostFn<T>): T {
    return this._get<T>(localStorage, key, defaultValue, postFn);
  }

  /**
   * Set localStorage data by given key and value.
   * @param {String} key the key of the data to set.
   * @param {Value} value value to set, null or undefined to remove the data.
   */
  ls_set<T>(key: string, value: T): void {
    // eslint-disable-line
    this._set<T>(localStorage, key, value);
  }

  /**
   * Remove localStorage data by the given key.
   * @param {String} key the key of the data to set.
   */
  ls_remove(key: string) {
    // eslint-disable-line
    this._remove(localStorage, key);
  }

  /**
   * Get sessionStorage data by key and given default value.
   * @param {String} key the key of the data to get.
   * @param {Any} defaultValue default value if the the data does not exist.
   * @param {Function} postFn post function to handle the data value.
   */
  ss_get<T>(key: string, defaultValue?: T | PostFn<T>, postFn?: PostFn<T>): T {
    return this._get<T>(sessionStorage, key, defaultValue, postFn);
  }

  /**
   * Set sessionStorage data by given key and value.
   * @param {String} key the key of the data to set.
   * @param {Value} value value to set, null or undefined to remove the data.
   */
  ss_set<T>(key: string, value: T): void {
    this._set<T>(sessionStorage, key, value);
  }

  /**
   * Remove sessionStorage data by the given key.
   * @param {String} key the key of the data to set.
   */
  ss_remove(key: string): void {
    // eslint-disable-line
    this._remove(sessionStorage, key);
  }

  private _get<T>(
    provider: Provider,
    key: string,
    defaultValue?: T | PostFn<T> | null | undefined,
    postFn?: PostFn<T> | null | undefined,
  ) {
    if (arguments.length === 1) {
      defaultValue = null;
    } else if (typeof defaultValue === 'function') {
      postFn = defaultValue as PostFn<T>;
      defaultValue = null;
    }
    const s = provider.getItem(key);
    if (s === null) {
      return defaultValue as T;
    }
    const value: T = JSON.parse(s);
    if (postFn) return postFn(value);
    return value;
  }

  private _set<T>(provider: Provider, key: string, value: T): void {
    if (value === void 0 || value === null) {
      localStorage.removeItem(key);
      return;
    }
    const s = JSON.stringify(value);
    provider.setItem(key, s);
  }

  private _remove(provider: Provider, key: string): void {
    provider.removeItem(key);
  }
}
