/**
 * @file: ServiceEnrollmentStatusLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ServiceEnrollmentStatus } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: ServiceEnrollmentStatus | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

export const ServiceEnrollmentStatusLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  const labelColor =
    value && LabelColors.ServiceEnrollmentStatus?.[value]
      ? LabelColors.ServiceEnrollmentStatus[value]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`service_enrollment_status.${value}`} />
    </Badge>
  );
});
