import React, { Component, ReactNode } from 'react';
import { EntityEditorSidebarProps, EntityEditorSidebar } from './sidebar';
import { AsyncListState, DispatchFn, EntityListActions } from 'lib/duck/interfaces';
import { EntityEditorFormBuilder } from '../EntityEditorForm/builder';
import { EntityEditorForm, FormElement } from '../EntityEditorForm';
import { ReactCtor } from 'model';
import { Areas, AppState } from 'app/duck/states';

interface SidebarHOCProps<T> {
  dispatch: DispatchFn<AppState>;
  actions: EntityListActions<AppState, any, any>;
  areas?: Areas;
  entities: AsyncListState<T>;
  extra?: any | (() => any);
}

type EntityValidator<T> = (entity: T | Partial<T>) => void;

export function withEntityEditorSidebarBuilder<T extends object>() {
  return new EntityEditorSidebarBuilder<T>();
}

export class EntityEditorSidebarBuilder<T extends object> {
  private i18nPrefix?: string;
  private validator?: EntityValidator<T>;
  private shouldConfirmClose?: boolean;
  private formElements: Array<FormElement<T>> = [];
  private useUncontrolled?: boolean;
  private autocomplete?: boolean;

  withI18nPrefix(i18nPrefix: string): this {
    this.i18nPrefix = i18nPrefix;
    return this;
  }

  withValidator(validator: EntityValidator<T>): this {
    this.validator = validator;
    return this;
  }

  withConfirmClose(shouldConfirmClose: boolean): this {
    this.shouldConfirmClose = shouldConfirmClose;
    return this;
  }

  withForm(build: (builder: EntityEditorFormBuilder<T>) => void): this {
    const builder = new EntityEditorFormBuilder<T>();
    build(builder);
    const { elements, useUncontrolled, autocomplete } = builder.build();
    this.formElements = elements;
    this.useUncontrolled = useUncontrolled;
    this.autocomplete = autocomplete;
    return this;
  }

  getClass() {
    const formElements = this.formElements;
    const useUncontrolled = this.useUncontrolled;
    const autocomplete = this.autocomplete;
    const i18nPrefix = this.i18nPrefix;
    const validator = this.validator;
    const shouldConfirmClose = this.shouldConfirmClose;

    const Sidebar: ReactCtor<EntityEditorSidebarProps<T>, any> = EntityEditorSidebar;

    class EntityEditorSidebarHOC extends Component<SidebarHOCProps<T>> {
      render() {
        const { entities } = this.props;
        return (
          <Sidebar
            values={entities}
            title={`${i18nPrefix}.editor`}
            confirmClose={shouldConfirmClose}
            error={entities.createError || entities.lastUpdateError}
            onSave={this.onSave}
            onCancel={this.onCancel}
            onValidate={validator}
            >
            {(entity: T | Partial<T>) => this.renderEntityEditor(entity)}
          </Sidebar>
        );
      }

      renderEntityEditor(entity: T | Partial<T>): ReactNode | null {
        return (
          <form className="entity-editor-form m-form">
            <div className="m-portlet__body">
              <div className="m-form__section m-form__section--first">
              <EntityEditorForm
                entity={entity}
                onChange={this.onChange}
                elements={formElements}
                useUncontrolled={useUncontrolled}
                autocomplete={autocomplete}
                areas={this.props.areas?.result}
                onGetExtraInfo={this.onGetExtraInfo(entity)}
                />
              </div>
            </div>
          </form>
        );
      }

      onSave = () => {
        const { entities, dispatch, actions } = this.props;
        if (entities.itemBeingCreated) {
          actions.commitItemBeingCreated &&
           dispatch(actions.commitItemBeingCreated());
        } else {
          actions.commitItemBeingUpdated &&
           dispatch(actions.commitItemBeingUpdated());
        }
      }

      onCancel = () => {
        const { entities, dispatch, actions } = this.props;
        if (entities.itemBeingCreated) {
          actions.cancelItemBeingCreated &&
           dispatch(actions.cancelItemBeingCreated());
        } else {
          actions.cancelItemBeingUpdated &&
           dispatch(actions.cancelItemBeingUpdated());
        }
      }

      onChange = (changes: Partial<T>) => {
        const { entities, dispatch, actions } = this.props;
        if (entities.itemBeingCreated) {
          actions.itemBeingCreatedChanged &&
            dispatch(actions.itemBeingCreatedChanged(changes));
        } else {
          actions.itemBeingUpdatedChanged &&
           dispatch(actions.itemBeingUpdatedChanged(changes));
        }
      }

      onGetExtraInfo = (entity: T | Partial<T>) => {
        return () => {
          let extra: any = {};
          if (typeof this.props.extra === 'function') {
            extra = this.props.extra();
          } else {
            extra = this.props.extra || {};
          }
          return {
            entity,
            ...extra
          };
        };
      }
    }

    return EntityEditorSidebarHOC;
  }
}