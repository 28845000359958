/**
 * @file: users.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { AdminUser, AdminUserAccessRights } from 'model';
import { adminUserService } from 'services';
import { sha256 } from 'utils';
import { ActionTypes } from '../types';
import { StandardAction } from 'lib/duck/interfaces';

export const adminUserActions = createListAsyncActionCreators<
  AppState,
  AdminUser
>('app.settings.admin_users', {
  getItemBeingCreated: (state: AppState) =>
    state.settings.adminUsers.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.settings.adminUsers.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.settings.adminUsers.itemsBeingDeleted,

  fetchHandler: (state: AppState) => {
    return adminUserService.list(
      state.settings.adminUsers.filter,
      state.settings.adminUsers.sorts?.[0],
      state.settings.adminUsers.offset,
      state.settings.adminUsers.limit,
      { count: true, recursive: false },
    );
  },
  create: (user: AdminUser) => {
    user = Object.assign({}, user, { password: sha256(user.password!) });
    return adminUserService.create(user);
  },
  update: (user: AdminUser) => {
    if (user.password) {
      user = Object.assign({}, user, { password: sha256(user.password) });
    }
    return adminUserService.update(user);
  },
  delete: (user: AdminUser) => {
    return adminUserService.delete(user.id);
  },
  deleteMulti: (users: AdminUser[]) => {
    if (!users.length) return Promise.resolve();
    if (users.length === 1) {
      return adminUserService.delete(users[0].id);
    }
    return Promise.reject(
      new Error('delete multi users is not supported for now. '),
    );
  },
});

export function setCurrentAdminUserAcl(
  userId: number | null,
  acl: string[],
): StandardAction<AdminUserAccessRights | null> {
  return createStandardAction(
    ActionTypes.SetCurrentAdminUserAcl,
    userId ? { userId, acl } : null,
  );
}
