import { MouseEvent, memo, useCallback } from 'react';
import { Translate } from 'react-localize-redux';
import { InlineSvg } from 'shared/components/InlineSvg';

export const EmptyCategories = memo(
  ({
    editable,
    onAddCategory,
  }: {
    editable: boolean;
    onAddCategory: () => void;
  }) => {
    const onAddCategoryClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        onAddCategory();
      },
      [onAddCategory],
    );

    return (
      <div className={`quotation-tpl__category-panel-empty`}>
        <InlineSvg src="img/no-category2.svg" />
        <span style={{ textAlign: 'center' }}>
          <Translate
            id={`quotation_tpl.category.empty${editable ? '' : '_readonly'}`}
            data={{
              add_category_btn: (
                <a href="#" onClick={onAddCategoryClick}>
                  <Translate id="quotation_tpl.category.btn.click_to_add_category" />
                </a>
              ),
            }}
          />
        </span>
      </div>
    );
  },
);
