import { SummaryStats } from 'model';
import { statsService } from 'services';
import { createAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { computeChainRelativeDateRange } from 'utils';

const actionCreators = createAsyncActionCreators<AppState, SummaryStats>(
  'dashboard.companion_summary_stats',
  {
    shouldFetchOnInvalidate: true,
    fetchHandler: (state: AppState) => {
      const { startDate, endDate } = computeChainRelativeDateRange(state.dashboard.dateRange);
      return statsService.getSummaryStats(startDate, endDate);
    }
  }
);

export const {
  fetch: fetchDashboardCompanionSummaryStats,
  invalidate: invalidateDashboardCompanionSummaryStats
} = actionCreators;