import { Component, ReactNode } from 'react';

interface Props {
  icon: string;
  children?: ReactNode;
}

export class PortletNavItem extends Component<Props> {
  render() {
    const { icon, children } = this.props;
    return (
      <li className="m-portlet__nav-item">
        <a href="#" className="m-portlet__nav-link m-portlet__nav-link--icon">
          <i className={icon} />
        </a>
        {children && (
          <div className="m-dropdown__wrapper">
            <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust" />
            <div className="m-dropdown__inner">
              <div className="m-dropdown__body">
                <div className="m-dropdown__content">{children}</div>
              </div>
            </div>
          </div>
        )}
      </li>
    );
  }
}
