/**
 * @file: VehicleInspectionTaskCheckSiteItemService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskCheckSiteItem,
  VehicleInspectionTaskCheckSiteItemData,
  VehicleInspectionTaskCheckSiteItemListFilter,
  VehicleInspectionTaskCheckSiteItemMedia,
} from 'model';

export class VehicleInspectionTaskCheckSiteItemServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskCheckSiteItemListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskCheckSiteItem>
      : VehicleInspectionTaskCheckSiteItem[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-check-site-items',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskCheckSiteItem | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-check-site-items/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionTaskCheckSiteItem: Partial<VehicleInspectionTaskCheckSiteItem>,
  ): Promise<VehicleInspectionTaskCheckSiteItem> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-check-site-items')
      .data(vehicleInspectionTaskCheckSiteItem)
      .future();
  }

  async update(
    vehicleInspectionTaskCheckSiteItem: Partial<VehicleInspectionTaskCheckSiteItem>,
  ): Promise<VehicleInspectionTaskCheckSiteItem> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-check-site-items/:id', {
        id: vehicleInspectionTaskCheckSiteItem.id,
      })
      .data(vehicleInspectionTaskCheckSiteItem)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-check-site-items/:id', { id })
      .future();
  }

  async getMediasByTaskItemId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskCheckSiteItemMedia[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-check-site-items/:id/medias', {
        id,
        recursive,
      })
      .future();
  }

  async createVehicleInspectionTaskCheckSiteItemMedia(
    media: Partial<VehicleInspectionTaskCheckSiteItemMedia>,
  ): Promise<VehicleInspectionTaskCheckSiteItemMedia> {
    const id = media.taskItemId;
    if (!id) {
      throw new Error('media.taskItemId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-inspection-task-check-site-items/:id/medias')
      .data(media)
      .future();
  }

  async updateVehicleInspectionTaskCheckSiteItemMedia(
    media: VehicleInspectionTaskCheckSiteItemMedia,
  ): Promise<VehicleInspectionTaskCheckSiteItemMedia> {
    const id = media.taskItemId;
    if (!id) {
      throw new Error('media.taskItemId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-inspection-task-check-site-items/:id/medias')
      .data(media)
      .future();
  }

  async deleteVehicleInspectionTaskCheckSiteItemMedia(
    itemId: number | VehicleInspectionTaskCheckSiteItemMedia,
    mediaId?: number,
  ): Promise<void> {
    if (itemId && typeof itemId === 'object') {
      mediaId = itemId.id;
      if (!itemId.taskItemId) {
        throw new Error('taskItemId cannot be null or undefined');
      }
      itemId = itemId.taskItemId;
    }
    if (!itemId || !mediaId) {
      throw new Error('itemId and mediaId are both required');
    }
    return void await this.api
      .delete()
      .url(
        '/vehicle-inspection-task-check-site-items/:itemId/medias/:mediaId',
        { itemId, mediaId },
      )
      .future();
  }

  async getDataByTaskItemId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskCheckSiteItemData[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-check-site-items/:id/data', {
        id,
        recursive,
      })
      .future();
  }

  async createVehicleInspectionTaskCheckSiteItemData(
    data: Partial<VehicleInspectionTaskCheckSiteItemData>,
  ): Promise<VehicleInspectionTaskCheckSiteItemData> {
    const id = data.taskItemId;
    if (!id) {
      throw new Error('data.taskItemId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-inspection-task-check-site-items/:id/data')
      .data(data)
      .future();
  }

  async updateVehicleInspectionTaskCheckSiteItemData(
    data: VehicleInspectionTaskCheckSiteItemData,
  ): Promise<VehicleInspectionTaskCheckSiteItemData> {
    const id = data.taskItemId;
    if (!id) {
      throw new Error('data.taskItemId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-inspection-task-check-site-items/:id/data')
      .data(data)
      .future();
  }

  async deleteVehicleInspectionTaskCheckSiteItemData(
    itemId: number | VehicleInspectionTaskCheckSiteItemData,
    dataId?: number,
  ): Promise<void> {
    if (itemId && typeof itemId === 'object') {
      dataId = itemId.id;
      if (!itemId.taskItemId) {
        throw new Error('taskItemId cannot be null or undefined');
      }
      itemId = itemId.taskItemId;
    }
    if (!itemId || !dataId) {
      throw new Error('itemId and dataId are both required');
    }
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-check-site-items/:itemId/data/:dataId', {
        itemId,
        dataId,
      })
      .future();
  }
}
