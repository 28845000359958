import { showAppLoading } from 'app/duck/actions';
import { AclObjectList } from 'model';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { systemService } from 'services';
import {
  EntityEditorForm,
  EntityEditorFormBuilder,
  ListToolbar,
  Page,
  ToolbarItemsBuilder,
  getString,
} from 'shared/components';
import { isEmail, isMobile } from 'utils/validators';

import './index.scss';

const PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_MOBILE_LIST =
  'product_agent.recharge_pending_entry.notify.mobile_list';

const PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_EMAIL_LIST =
  'product_agent.recharge_pending_entry.notify.email_list';

export const SystemConfigurationPage = memo(() => {
  const dispatch = useDispatch();

  const [systemConfig, setSystemConfig] = useState<Record<string, any>>();
  const [error, setError] = useState<Error>();
  const refresh = useCallback(() => {
    systemService.getSystemConfig().then(setSystemConfig).catch(setError);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const toolbarItems = useMemo(() => {
    return new ToolbarItemsBuilder()
      .custom({
        render: () => (
          <h3 style={{ marginBottom: 0 }}>
            <Translate id="system_config.page.sub_title" />
          </h3>
        ),
      })
      .button({
        text: getString('@string/btn_refresh'),
        onClick: refresh,
        placement: 'right',
      })
      .build();
  }, [refresh]);

  const formItems = useMemo(() => {
    return new EntityEditorFormBuilder<Record<string, any>>()
      .custom({
        label: '',
        render: () => (
          <h4 className="system-config__section-title">
            <Translate id="system_config.product_agent.recharge_pending_entry_notify.section_title" />
          </h4>
        ),
      })
      .multiTextInput({
        prop: PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_MOBILE_LIST,
        label:
          'system_config.product_agent.recharge_pending_entry_notify.mobile_list.label',
        placeholder:
          'system_config.product_agent.recharge_pending_entry_notify.mobile_list.placeholder',
        helpText:
          'system_config.product_agent.recharge_pending_entry_notify.mobile_list.help_text',
        serialize: x => x,
        deserialize: x => x,
        isValidInput: isMobile,
      })
      .multiTextInput({
        prop: PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_EMAIL_LIST,
        label:
          'system_config.product_agent.recharge_pending_entry_notify.email_list.label',
        placeholder:
          'system_config.product_agent.recharge_pending_entry_notify.email_list.placeholder',
        helpText:
          'system_config.product_agent.recharge_pending_entry_notify.email_list.help_text',
        serialize: x => x,
        deserialize: x => x,
        isValidInput: isEmail,
      })
      .build();
  }, []);

  const onChange = useCallback(
    async (values: Record<string, any>) => {
      const keys = Object.keys(values);

      let hasChanges = false;

      for (const key of keys) {
        if (
          key === PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_MOBILE_LIST ||
          key === PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_EMAIL_LIST
        ) {
          const value = values[key] as string[];
          const oldValue =
            systemConfig?.[
              PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_MOBILE_LIST
            ] ?? [];
          if (value.join(',') !== oldValue.join(',')) {
            const list = value
              .filter(x => x)
              .filter(x =>
                key === PRODUCT_AGENT_RECHARGE_PENDING_ENTRY_NOTIFY_MOBILE_LIST
                  ? isMobile(x)
                  : isEmail(x),
              );
            dispatch(showAppLoading());
            try {
              console.log('saving system config %s: ', key, list);
              await systemService.setSystemConfig(key, list);
              dispatch(
                showAppLoading({
                  status: 'success',
                  message: <Translate id="system_config.msg.save_successful" />,
                  timeout: 3000,
                }),
              );
              hasChanges = true;
            } catch (e) {
              dispatch(showAppLoading({ status: 'error', message: e.message }));
            }
          }
        }
      }

      if (hasChanges) {
        refresh();
      }
    },
    [systemConfig, dispatch, refresh],
  );

  return (
    <Page
      bodyClassName="system-config__editor"
      title={<Translate id="system_config.page.title" />}
      breadcrumbs={[
        { text: <Translate id="settings.breadcrumb.it" /> },
        { text: <Translate id="settings.breadcrumb.configurations" /> },
      ]}
      fullAccessRight={AclObjectList.SystemConfigurationFullAccess}
      readonlyAccessRight={AclObjectList.SystemConfigurationReadonlyAccess}
      error={error}
      onRefresh={refresh}
      compact={true}
    >
      <ListToolbar<any, any>
        items={toolbarItems}
        filter={{}}
        onFilterChange={() => null}
      />
      <form className="entity-editor-form m-form">
        <div
          className="m-portlet__body"
          style={{ padding: 0, paddingTop: '1rem' }}
        >
          <div className="m-form__section m-form__section--first">
            <EntityEditorForm
              entity={systemConfig ?? {}}
              elements={formItems.elements}
              onChange={onChange}
              useUncontrolled
            />
          </div>
        </div>
      </form>
    </Page>
  );
});
