import {
  TransFunction,
  getDateRangePickerLocale,
  getDefaultInputRanges,
  getDefaultStaticRanges,
} from 'app';
import { store as store_ } from 'app/duck/store';
import classNames from 'classnames';
import { ObjectKeyType } from 'lib/duck/interfaces';
import { Agent, Organization, Range, ServiceEdition, Store } from 'model';
import moment from 'moment';
import {
  ChangeEvent,
  Component,
  FocusEvent,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
} from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { Translate, getTranslate } from 'react-localize-redux';
import { AgentPicker } from 'shared/components/AgentPicker';
import { ServiceEditionPicker } from 'shared/components/ServiceEditionPicker';
import { StorePicker } from 'shared/components/StorePicker';
import { Button, DropdownButton } from 'shared/metronic/components';
import { Clear } from '../Clear';
import { Select } from '../Select';
import { StringLabel, getString } from '../StringLabel';
import {
  ToolbarItem,
  ToolbarItemAgentPicker,
  ToolbarItemButton,
  ToolbarItemButtonGroup,
  ToolbarItemCustom,
  ToolbarItemDatePicker,
  ToolbarItemDropdown,
  ToolbarItemSelect,
  ToolbarItemSeparator,
  ToolbarItemServiceEditionPicker,
  ToolbarItemStorePicker,
  ToolbarItemText,
  ToolbarItemType,
} from './types';

export * from './builder';
export * from './types';

export interface Props<T, TKey extends ObjectKeyType = number> {
  filter: Partial<T>;
  items?: Array<ToolbarItem<T>> | null;
  selection?: TKey[];
  onGetExtraInfo?: () => any;
  onFilterChange: (filter: Partial<T>) => void;
}

interface AsyncState<T = any> {
  isLoading?: boolean;
  values?: T[];
}

interface State {
  asyncResults: { [stateId: string]: AsyncState };
}

export class ListToolbar<
  T,
  TKey extends ObjectKeyType = number,
> extends Component<Props<T, TKey>, State> {
  constructor(props: Props<T, TKey>) {
    super(props);
    this.state = { asyncResults: {} };
  }

  render() {
    const { items } = this.props;
    if (!items?.length) return null;

    const trans = getTranslate(store_.getState().localize) as TransFunction;
    const leftItems = items.filter(x => x.placement !== 'right');
    const rightItems = items.filter(x => x.placement === 'right');
    return (
      <div className="list-toolbar d-flex align-items-center justify-content-between">
        <div className="list-toolbar__left d-flex align-items-center">
          {this.renderItems(leftItems, trans)}
        </div>
        <div className="list-toolbar__right d-flex align-items-center justify-content-end">
          {this.renderItems(rightItems, trans)}
        </div>
      </div>
    );
  }

  renderItems(items: Array<ToolbarItem<T>>, trans: TransFunction): ReactNode[] {
    return items.map((item, i) => (
      <Fragment key={i}>{this.renderItem(item, trans)}</Fragment>
    ));
  }

  renderItem(item: ToolbarItem<T>, trans: TransFunction): ReactNode | null {
    if (typeof item.hidden === 'function') {
      const extra = this.props.onGetExtraInfo?.();
      if (item.hidden(extra)) {
        return null;
      }
    } else if (item.hidden) {
      return null;
    }
    return (
      <div
        className={classNames('list-toolbar__filter', {
          'list-toolbar__filter--right': item.placement === 'right',
        })}
      >
        <label className="list-toolbar__label">
          <StringLabel value={item.label} />
        </label>
        {(() => {
          switch (item.type) {
            case ToolbarItemType.Text:
              return this.renderTextItem(item as ToolbarItemText<T>, trans);
            case ToolbarItemType.Select:
              return this.renderSelectItem(item as ToolbarItemSelect<T>, trans);
            case ToolbarItemType.DatePicker:
              return this.renderDatePickerItem(
                item as ToolbarItemDatePicker<T>,
                trans,
              );
            case ToolbarItemType.AgentPicker:
              return this.renderAgentPicker(item as ToolbarItemAgentPicker<T>);
            case ToolbarItemType.StorePicker:
              return this.renderStorePicker(item as ToolbarItemStorePicker<T>);
            case ToolbarItemType.ServiceEditionPicker:
              return this.renderServiceEditionPicker(
                item as ToolbarItemServiceEditionPicker<T>,
              );
            case ToolbarItemType.Dropdown:
              return this.renderDropdownItem(
                item as ToolbarItemDropdown<T>,
                trans,
              );
            case ToolbarItemType.Button:
              return this.renderButtonItem(
                item as any as ToolbarItemButton<T>,
                trans,
              );
            case ToolbarItemType.Separator:
              return this.renderSeparatorItem(
                item as ToolbarItemSeparator<T>,
                trans,
              );
            case ToolbarItemType.Custom:
              return this.renderCustomItem(item as ToolbarItemCustom<T>, trans);
            case ToolbarItemType.ButtonGroup:
              return this.renderButtonGroup(
                item as ToolbarItemButtonGroup<T>,
                trans,
              );
            default:
              return null;
          }
        })()}
      </div>
    );
  }

  renderTextItem(item: ToolbarItemText<T>, _trans: TransFunction): ReactNode {
    const { filter } = this.props;
    const value = filter[item.prop] as any;
    const isEmpty = !value || !String(value).trim().length;
    if (item.immediate) {
      return (
        <Clear
          visible={item.clearable !== false && !isEmpty}
          onClear={this.onClearTextField(item)}
        >
          <input
            type="text"
            className="form-control toolbar__text-field"
            placeholder={getString(item.placeholder)}
            value={value === null || value === undefined ? '' : value}
            onChange={this.onTextFieldChange(item)}
            style={{ width: item.width, ...item.style }}
          />
        </Clear>
      );
    }
    return (
      <Clear
        visible={item.clearable !== false && !isEmpty}
        onClear={this.onClearTextField(item)}
      >
        <input
          type="text"
          className="form-control toolbar__text-field"
          placeholder={getString(item.placeholder)}
          defaultValue={value === null || value === undefined ? '' : value}
          key={value === null || value === undefined ? '' : value}
          onBlur={this.onTextFieldBlur(item)}
          onKeyUp={this.onTextFieldKeyUp(item)}
          style={{ width: item.width, ...item.style }}
        />
      </Clear>
    );
  }

  renderSelectItem(
    item: ToolbarItemSelect<T>,
    _trans: TransFunction,
  ): ReactNode {
    const { filter } = this.props;
    const valueProp = item.valueProp || 'value';
    const extra = this.props.onGetExtraInfo?.();
    const value = filter[item.prop] as any;
    let values =
      typeof item.values === 'function' ? item.values(extra) : item.values;

    let isLoading = false;

    if (item.async) {
      if (!item.stateId) {
        throw new Error('toolbar async select requires stateId property');
      }
      const asyncResult = this.state.asyncResults[item.stateId];
      isLoading = (asyncResult && asyncResult.isLoading) || false;
      values = asyncResult?.values || [];
    }

    const selected = item.multi
      ? values.filter(x => value.includes(x))
      : values.find(x => x[valueProp] === value);

    return (
      <Select
        values={values}
        valueProp={item.valueProp || 'value'}
        labelProp={item.labelProp || 'label'}
        multi={item.multi}
        selectedValue={selected}
        placeholder={getString(item.placeholder)}
        width={item.width}
        isLoading={isLoading}
        isClearable={item.clearable}
        className="toolbar__select"
        onGetOptionValue={item.onGetOptionValue}
        onGetOptionLabel={item.onGetOptionLabel}
        onFormatOptionLabel={item.onFormatOptionLabel}
        onChange={this.onSelectChange(item)}
        noOptionsMessage={item.noOptionsMessage}
        async={item.async}
        defaultValues={item.defaultValues}
        onLoadValues={this.onLoadValues(item)}
        cacheValues={item.cacheValues}
      />
    );
  }

  renderDatePickerItem(
    item: ToolbarItemDatePicker<T>,
    trans: TransFunction,
  ): ReactNode {
    const value = this.props.filter[item.prop] as
      | Range<string | Date>
      | null
      | undefined;
    return (
      <Translate>
        {({ activeLanguage, translate }) => (
          <DropdownButton
            text={this.renderDateRangeText(value, getString(item.placeholder))}
            color="secondary"
            dropDownSize="auto"
            className="mr-3"
            dropdownPush={item.dropdownPush}
            dropDownStyle={item.dropDownStyle}
            dropdownContentStyle={item.dropdownContentStyle}
            dropdownAlign={item.dropdownAlign}
            dropdownArrowPlacement={item.dropdownArrowPlacement}
            dropdownArrowStyle={item.dropdownArrowStyle}
          >
            <DateRangePicker
              locale={getDateRangePickerLocale(activeLanguage.code)}
              dateDisplayFormat={translate('date_range.date_format') as any}
              ranges={
                value && (value.start || value.end)
                  ? [
                      {
                        startDate: value.start
                          ? moment(value.start).toDate()
                          : moment(value.end).toDate(),
                        endDate: value.end
                          ? moment(value.end).toDate()
                          : moment(value.start).toDate(),
                      },
                    ]
                  : [
                      {
                        startDate: undefined,
                        endDate: undefined,
                      },
                    ]
              }
              onChange={this.onDatePickerChange(item)}
              staticRanges={getDefaultStaticRanges(trans)}
              inputRanges={getDefaultInputRanges(trans)}
            />
          </DropdownButton>
        )}
      </Translate>
    );
  }

  renderAgentPicker(item: ToolbarItemAgentPicker<T>): ReactNode {
    return (
      <AgentPicker
        agentId={this.props.filter[item.prop] as any}
        placeholder={item.placeholder}
        onChange={this.onAgentPickerChange(item)}
        style={{ width: item.width, ...item.style }}
      />
    );
  }

  renderStorePicker(item: ToolbarItemStorePicker<T>): ReactNode {
    return (
      <StorePicker
        orgId={(this.props.filter as any)[item.orgIdProp ?? 'orgId'] as any}
        storeId={
          (this.props.filter as any)[item.storeIdProp ?? 'storeId'] as any
        }
        orgPlaceholder={item.orgPlaceholder}
        storePlaceholder={item.storePlaceholder}
        orgOnly={item.orgOnly}
        onChange={this.onStorePickerChange(item)}
        style={{ width: item.width, ...item.style }}
        containerStyle={item.containerStyle}
        menuStyle={item.menuStyle}
        valueContainerStyle={item.valueContainerStyle}
      />
    );
  }

  renderServiceEditionPicker(
    item: ToolbarItemServiceEditionPicker<T>,
  ): ReactNode {
    return (
      <ServiceEditionPicker
        serviceEditionId={this.props.filter[item.prop] as any}
        placeholder={item.placeholder}
        onChange={this.onServiceEditionPickerChange(item)}
        style={{ width: item.width, ...item.style }}
      />
    );
  }

  renderDropdownItem(
    item: ToolbarItemDropdown<T>,
    trans: TransFunction,
  ): ReactNode {
    return (
      <DropdownButton
        color="secondary"
        text={trans(item.text)}
        dropdownAlign={item.dropdownAlign || 'right'}
        dropdownArrowPlacement={item.dropdownArrowPlacement || 'right'}
        dropDownStyle={item.dropdownStyle}
      >
        {item.render()}
      </DropdownButton>
    );
  }

  renderButtonItem(
    item: ToolbarItemButton<T>,
    _trans: TransFunction,
    key?: string,
    inButtonGroup?: boolean,
  ): ReactNode {
    const extra = this.props.onGetExtraInfo?.();
    const onClick = (e: MouseEvent<any>) => {
      if (item.file) return;
      e.preventDefault();
      item.onClick && item.onClick(extra, item.context);
    };
    let text: string | ReactNode = '';
    if (typeof item.text === 'function') {
      text = item.text(extra);
    } else {
      text = item.text;
    }
    let isLoading: boolean | undefined = false;
    if (typeof item.loading === 'function') {
      isLoading = item.loading(extra);
    } else {
      isLoading = item.loading;
    }
    return (
      <Button
        className={classNames({
          'toolbar_button--file': item.file,
          toolbar__button: !inButtonGroup,
        })}
        key={key}
        small={item.size === 'small'}
        large={item.size === 'large'}
        outline={item.outline}
        color={item.color || 'brand'}
        onClick={onClick}
        style={item.style}
        file={item.file}
        accepts={item.accepts}
        loader={isLoading}
        disabled={
          item.shouldDisable
            ? item.shouldDisable(this.props.selection || [], extra)
            : item.disabled
        }
        onFileChange={this.onFileChange(item as ToolbarItemButton<T>)}
      >
        {typeof text === 'string' ? <StringLabel value={text} /> : text}
      </Button>
    );
  }

  onFileChange(item: ToolbarItemButton<T>) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const extra = this.props.onGetExtraInfo?.();
      item.onFileChange && item.onFileChange(e, extra);
    };
  }

  renderButtonGroup(
    item: ToolbarItemButtonGroup<T>,
    trans: TransFunction,
  ): ReactNode {
    if (!item.buttons.length) return null;
    return (
      <div
        className={classNames('btn-group', {
          'btn-group-sm': item.size === 'small',
          'btn-group-lg': item.size === 'large',
        })}
      >
        {item.buttons.map((button, i) =>
          this.renderButtonItem(button, trans, button.key || String(i), true),
        )}
      </div>
    );
  }

  renderSeparatorItem(
    item: ToolbarItemSeparator<T>,
    _trans: TransFunction,
  ): ReactNode {
    return <div className="toolbar__separator" style={item.style} />;
  }

  renderCustomItem(
    item: ToolbarItemCustom<T>,
    _trans: TransFunction,
  ): ReactNode | string | null {
    const extra = this.props.onGetExtraInfo?.();
    return item.render(
      this.props.filter,
      setter => {
        this.applyChanges(item, undefined, props => setter(props));
      },
      extra,
    );
  }

  onClearTextField(item: ToolbarItemText<T>) {
    return () => {
      this.applyChanges(item, undefined);
    };
  }

  onTextFieldBlur(item: ToolbarItemText<T>) {
    return (e: FocusEvent<HTMLInputElement>) => {
      const value = e.target.value.trim() as any;
      if (value !== (this.props.filter[item.prop] || '')) {
        this.applyChanges(item, value || undefined);
      }
    };
  }

  onTextFieldChange(item: ToolbarItemText<T>) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim() as any;
      if (value !== (this.props.filter[item.prop] || '')) {
        if (item.onChange) {
          item.onChange(value);
        }
        this.applyChanges(item, value || undefined);
      }
    };
  }

  onTextFieldKeyUp(item: ToolbarItemText<T>) {
    return (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const value = e.currentTarget.value.trim() as any;
        if (value !== (this.props.filter[item.prop] || '')) {
          this.applyChanges(item, value || undefined);
        }
      }
    };
  }

  onSelectChange<U = { [key: string]: any }>(item: ToolbarItemSelect<T, U>) {
    return (values: U | U[]) => {
      const valueProp = item.valueProp || 'value';
      const value = item.multi
        ? (values as U[]).map(t => (t as any)[valueProp])
        : !values || (values as any)[valueProp] === null
          ? undefined
          : item.array
            ? [(values as any)[valueProp]]
            : (values as any)[valueProp];
      this.applyChanges(item, value);
    };
  }

  onDatePickerChange(item: ToolbarItemDatePicker<T>) {
    return (ranges: RangeKeyDict) => {
      const key = Object.keys(ranges)[0];
      if (!key) {
        this.applyChanges(item, undefined);
        return;
      }
      const range = ranges[key];
      const start = moment(range.startDate).format('YYYY-MM-DD');
      const end = moment(range.endDate).format('YYYY-MM-DD');
      const value: Range<string> = {
        start,
        end,
        startInclusive: true,
        endInclusive: true,
      };
      this.applyChanges(item, value);
    };
  }

  onServiceEditionPickerChange(item: ToolbarItemServiceEditionPicker<T>) {
    return (serviceEdition: ServiceEdition | null) => {
      this.applyChanges(item, serviceEdition?.sku);
      item.onChange?.(serviceEdition);
    };
  }

  onAgentPickerChange(item: ToolbarItemAgentPicker<T>) {
    return (agentId: number | undefined, agent: Agent | null) => {
      this.applyChanges(item, agentId);
      item.onChange?.(agentId, agent);
    };
  }

  onStorePickerChange(item: ToolbarItemStorePicker<T>) {
    return (
      orgId: number | undefined,
      storeId: number | undefined,
      org: Organization | null,
      store: Store | null,
    ) => {
      this.applyChanges(item, { orgId, storeId }, (x: any) => {
        x[item.orgIdProp ?? 'orgId'] = orgId;
        x[item.storeIdProp ?? 'storeId'] = storeId;
      });
      item.onChange?.(orgId, storeId, org, store);
    };
  }

  renderDateRangeText(
    value: Range<string | Date> | null | undefined,
    placeholder?: string,
  ) {
    if (!value?.start || !value.end) {
      return placeholder || '';
    }

    const text = (
      <>
        <i
          className="flaticon-event-calendar-symbol"
          style={{ margin: '0 6px 0 0', fontSize: '0.95em' }}
        />
        <span style={{ marginRight: 6 }}>
          {moment(value.start).format('YYYY/MM/DD')}
          &nbsp;-&nbsp;
          {moment(value.end).format('YYYY/MM/DD')}
        </span>
      </>
    );

    return text;
  }

  onLoadValues(item: ToolbarItemSelect<T>) {
    return async (inputValue: string) => {
      if (!item.stateId) {
        throw new Error('toolbar async select requires stateId property');
      }
      if (!item.onLoadValues) {
        throw new Error('toolbar async select requires onLoadValues property');
      }

      const asyncResult = this.state.asyncResults[item.stateId] || {
        isLoading: false,
        selected: null,
        values: [],
      };

      const updateAsyncState = (props: Partial<AsyncState>) => {
        this.setState({
          asyncResults: {
            ...this.state.asyncResults,
            [item.stateId!]: { ...asyncResult, ...props },
          },
        });
      };

      updateAsyncState({ isLoading: true });

      try {
        const values = await item.onLoadValues(inputValue);
        updateAsyncState({ isLoading: false, values });
        return values;
      } catch (e) {
        console.error(e);
        updateAsyncState({ isLoading: false, values: [] });
      }

      return [];
    };
  }

  private readonly applyChanges = <U,>(
    item: ToolbarItem<T>,
    value: U,
    setter?: (props: Partial<T>, value: U) => void,
  ) => {
    const extra = this.props.onGetExtraInfo?.();
    const props: Partial<T> = {};
    if (item.onApplyChanges) {
      item.onApplyChanges(props, value, extra);
    } else if (setter) {
      setter(props, value);
    } else if (item.prop) {
      props[item.prop] = value as any;
    }
    this.props.onFilterChange(props);
  };
}
