import { makeId } from 'app/inspection/common/helpers';
import { QuotationTemplateConfigState } from 'app/inspection/duck/states';
import {
  makeSubjectRef,
  quotationTemplateConfigToStaged,
} from 'app/inspection/quotation-template-config/util';
import { Draft, produce } from 'immer';
import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncActionReducers } from 'lib/duck/reducers';
import {
  QuotationCategoryRef,
  QuotationGroupRef,
  QuotationItemRef,
  QuotationMaterialRef,
  QuotationSubjectRef,
  QuotationTemplateCategoryStaged,
  QuotationTemplateConfig,
  QuotationTemplateGroupStaged,
  QuotationTemplateItemStaged,
  QuotationTemplateMaterialStaged,
  QuotationTemplateSubjectStaged,
} from 'model';
import { array_move } from 'utils';
import { makeUniqueIdAlphabetic } from 'utils/id';
import { ActionTypes } from '../types';

const initialState: QuotationTemplateConfigState = {
  isLoading: false,
  dirty: false,
  error: null,
  result: null,
  selectedGroupRef: null,
  categoryIdBeingRemoved: null,
  groupRefBeingRemoved: null,
  staged: {
    categories: [],
  },
};

const defaultReducer = createAsyncActionReducers<
  QuotationTemplateConfig,
  QuotationTemplateConfigState
>('inspection.quotation-template-config', initialState);

const stateInitializedWithTemplateConfig = (
  draft: Draft<QuotationTemplateConfigState>,
  quotationTemplateConfig: QuotationTemplateConfig,
  dirty = false,
) => {
  const staged = quotationTemplateConfigToStaged(quotationTemplateConfig);
  Object.assign(draft, {
    staged,
    dirty,
    categoryIdBeingRemoved: null,
    categoryIdForNewGroup: null,
    categoryIdWhoseNameIsBeingEdited: null,
    categoryNameBeingEdited: null,
    groupNameBeingEdited: null,
    selectedGroupRef: null,
    selectedSubjectRef: null,
    groupRefBeingRemoved: null,
    subjectRefBeingEdited: null,
    subjectRefBeingRemoved: null,
    groupRefWhoseNameIsBeingEdited: null,
    itemRefBeingEdited: null,
    itemRefBeingRemoved: null,
    materialRefBeingEdited: null,
    materialRefBeingRemoved: null,
    isSaving: false,
    saveError: null,
  });
};

export default function (
  state: QuotationTemplateConfigState,
  action: StandardAction<any>,
): QuotationTemplateConfigState {
  switch (action.type) {
    case ActionTypes.QuotationTemplateConfigChanged: {
      const { recipe, options } = action.payload;
      return stateUpdated(state, recipe, options);
    }

    case ActionTypes.ResetQuotationTemplateConfigChanges: {
      const quotationTemplateConfig: QuotationTemplateConfig = action.payload ??
        state.result ?? { version: '1.0', categories: [] };
      return produce(state, draft => {
        stateInitializedWithTemplateConfig(
          draft,
          quotationTemplateConfig,
          action.payload != null,
        );
      });
    }

    case ActionTypes.QuotationTemplateConfigLoaded: {
      const quotationTemplateConfig = action.payload as QuotationTemplateConfig;
      return produce(state, draft => {
        stateInitializedWithTemplateConfig(draft, quotationTemplateConfig);
      });
    }

    case ActionTypes.QuotationTemplateConfigSaveSuccess: {
      return produce(state, draft => {
        draft.dirty = false;
      });
    }

    //#region category related action handlers
    case ActionTypes.QuotationTemplateConfigAddCategory: {
      return { ...state, categoryNameBeingEdited: '' };
    }

    case ActionTypes.QuotationTemplateConfigEditCategory: {
      const categoryId = action.payload as string;
      const category = state.staged.categories.find(x => x.id === categoryId)!;
      return produce(state, draft => {
        draft.categoryIdWhoseNameIsBeingEdited = categoryId;
        draft.categoryNameBeingEdited = category.name;
      });
    }

    case ActionTypes.QuotationTemplateConfigEditCategoryChanged: {
      return {
        ...state,
        categoryNameBeingEdited: action.payload,
      };
    }

    case ActionTypes.QuotationTemplateConfigEditCategoryCommitted: {
      const categoryId = state.categoryIdWhoseNameIsBeingEdited;
      if (categoryId) {
        return stateWithCategoryUpdated(
          state,
          categoryId,
          category => {
            category.name = action.payload || state.categoryNameBeingEdited!;
          },
          x => {
            x.categoryIdWhoseNameIsBeingEdited = null;
            x.categoryNameBeingEdited = null;
          },
        );
      } else {
        return stateWithCategoriesUpdated(
          state,
          categories => {
            const category: QuotationTemplateCategoryStaged = {
              id: makeId(),
              name: action.payload || state.categoryNameBeingEdited!,
              groups: [],
              expanded: true,
            };
            categories.push(category);
          },
          x => {
            x.categoryIdWhoseNameIsBeingEdited = null;
            x.categoryNameBeingEdited = null;
          },
        );
      }
    }

    case ActionTypes.QuotationTemplateConfigEditCategoryCancelled: {
      return {
        ...state,
        categoryIdWhoseNameIsBeingEdited: null,
        categoryNameBeingEdited: null,
      };
    }

    case ActionTypes.QuotationTemplateConfigRemoveCategory: {
      return { ...state, categoryIdBeingRemoved: action.payload };
    }

    case ActionTypes.QuotationTemplateConfigCommitRemoveCategory: {
      if (!state.categoryIdBeingRemoved) return state;
      const categoryIdBeingRemoved = state.categoryIdBeingRemoved;
      return stateWithCategoryUpdated(
        state,
        categoryIdBeingRemoved,
        (category, index, draft) => {
          if (
            draft.selectedGroupRef?.type === 'all-groups' &&
            draft.selectedGroupRef.categoryId === categoryIdBeingRemoved
          ) {
            draft.selectedGroupRef = null;
          } else if (draft.selectedGroupRef?.type === 'group') {
            const groupId = draft.selectedGroupRef.ref.groupId;
            if (category.groups.some(x => x.id === groupId)) {
              draft.selectedGroupRef = undefined;
              draft.selectedSubjectRef = undefined;
            }
          }
          draft.staged.categories.splice(index, 1);
          draft.categoryIdBeingRemoved = undefined;
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelRemoveCategory: {
      if (!state.categoryIdBeingRemoved) return state;
      return { ...state, categoryIdBeingRemoved: null };
    }

    case ActionTypes.QuotationTemplateConfigExpandCategory: {
      const id = action.payload as string;
      return stateWithCategoryUpdated(
        state,
        id,
        category => {
          category.expanded = true;
        },
        undefined,
        { markAsDirty: false },
      );
    }

    case ActionTypes.QuotationTemplateConfigCollapseCategory: {
      const id = action.payload as string;
      return stateWithCategoryUpdated(
        state,
        id,
        category => {
          category.expanded = false;
        },
        undefined,
        { markAsDirty: false },
      );
    }

    case ActionTypes.QuotationTemplateConfigCategoryMoved: {
      const { from, to } = action.payload as {
        id: string;
        from: number;
        to: number;
      };
      return stateWithCategoriesUpdated(state, categories => {
        array_move(categories, from, to);
      });
    }

    //#endregion

    //#region group related action handlers
    case ActionTypes.QuotationTemplateConfigGroupSelected: {
      return {
        ...state,
        selectedGroupRef: {
          type: 'group',
          ref: action.payload,
        },
        selectedSubjectRef: undefined,
      };
    }

    case ActionTypes.QuotationTemplateConfigAllGroupsSelected: {
      return {
        ...state,
        selectedGroupRef: { type: 'all-groups', categoryId: action.payload },
      };
    }

    case ActionTypes.QuotationTemplateConfigAddGroup: {
      const categoryId = action.payload as string;
      return {
        ...state,
        categoryIdForNewGroup: categoryId,
        groupNameBeingEdited: '',
        groupRefWhoseNameIsBeingEdited: null,
      };
    }

    case ActionTypes.QuotationTemplateConfigEditGroup: {
      const { groupRef, name } = action.payload as {
        groupRef: QuotationGroupRef;
        name: string;
      };
      return {
        ...state,
        categoryIdForNewGroup: null,
        groupRefWhoseNameIsBeingEdited: groupRef,
        groupNameBeingEdited: name,
      };
    }

    case ActionTypes.QuotationTemplateConfigEditGroupChanged: {
      return {
        ...state,
        groupNameBeingEdited: action.payload,
      };
    }

    case ActionTypes.QuotationTemplateConfigEditGroupCommitted: {
      const groupIdWhoseNameIsBeingEdited =
        state.groupRefWhoseNameIsBeingEdited;
      if (groupIdWhoseNameIsBeingEdited == null) {
        const categoryId = state.categoryIdForNewGroup!;
        const groupId = makeUniqueIdAlphabetic();
        const name = action.payload || state.groupNameBeingEdited!;
        return stateWithCategoryUpdated(
          state,
          categoryId,
          category => {
            const group: QuotationTemplateGroupStaged = {
              id: groupId,
              categoryId,
              name,
              subjects: [],
            };
            category.groups = [...category.groups, group];
          },
          x => {
            x.selectedGroupRef = {
              type: 'group',
              ref: { categoryId, groupId },
            };
            x.groupRefWhoseNameIsBeingEdited = null;
            x.groupNameBeingEdited = null;
            x.categoryIdForNewGroup = null;
          },
        );
      } else {
        return stateWithGroupUpdated(
          state,
          groupIdWhoseNameIsBeingEdited.categoryId,
          groupIdWhoseNameIsBeingEdited.groupId,
          group => {
            const name = action.payload || state.groupNameBeingEdited!;
            if (name !== group.name) {
              group.name = name;
            }
          },
          x => {
            x.groupRefWhoseNameIsBeingEdited = null;
            x.groupNameBeingEdited = null;
            x.categoryIdForNewGroup = null;
          },
        );
      }
    }

    case ActionTypes.QuotationTemplateConfigEditGroupCancelled: {
      return {
        ...state,
        groupRefWhoseNameIsBeingEdited: null,
        categoryIdForNewGroup: null,
        groupNameBeingEdited: null,
      };
    }

    case ActionTypes.QuotationTemplateConfigRemoveGroup: {
      return {
        ...state,
        groupRefBeingRemoved: action.payload,
      };
    }

    case ActionTypes.QuotationTemplateConfigCommitRemoveGroup: {
      const groupRef = state.groupRefBeingRemoved;
      if (groupRef == null) {
        return state;
      }
      return stateWithCategoryUpdated(
        state,
        groupRef.categoryId,
        category => {
          const index = category.groups.findIndex(
            x => x.id === groupRef.groupId,
          );
          if (index >= 0) {
            category.groups.splice(index, 1);
          }
        },
        x => {
          x.groupRefBeingRemoved = null;
          if (
            x.selectedGroupRef?.type === 'group' &&
            x.selectedGroupRef.ref.groupId === groupRef.groupId
          ) {
            x.selectedGroupRef = null;
            x.selectedSubjectRef = null;
          }
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelRemoveGroup: {
      return {
        ...state,
        groupRefBeingRemoved: null,
      };
    }

    case ActionTypes.QuotationTemplateConfigGroupMoved: {
      const { categoryRef, from, to } = action.payload as {
        categoryRef: QuotationCategoryRef;
        from: number;
        to: number;
      };
      return stateWithCategoryUpdated(
        state,
        categoryRef.categoryId,
        category => {
          array_move(category.groups, from, to);
        },
      );
    }

    //#endregion

    //#region subject related action handlers
    case ActionTypes.QuotationTemplateConfigAddSubject: {
      const subjectId = makeUniqueIdAlphabetic();
      const groupRef = action.payload as QuotationGroupRef;
      return {
        ...state,
        subjectRefBeingEdited: makeSubjectRef(groupRef, subjectId),
      };
    }

    case ActionTypes.QuotationTemplateConfigEditSubject: {
      return { ...state, subjectRefBeingEdited: action.payload };
    }

    case ActionTypes.QuotationTemplateConfigCommitSubjectBeingEdited: {
      if (state.subjectRefBeingEdited == null) return state;
      const subjectIdBeingEdited = state.subjectRefBeingEdited;
      const subjectStaged = action.payload as QuotationTemplateSubjectStaged;
      let isAdd = false;
      return stateWithGroupUpdated(
        state,
        subjectIdBeingEdited.categoryId,
        subjectIdBeingEdited.groupId,
        group => {
          const index = group.subjects.findIndex(
            x => x.id === subjectIdBeingEdited.subjectId,
          );
          if (index != null && index >= 0) {
            group.subjects[index] = subjectStaged;
          } else {
            group.subjects.push(subjectStaged);
            isAdd = true;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          state.subjectRefBeingEdited = undefined;
          if (isAdd) {
            state.selectedSubjectRef = subjectIdBeingEdited;
          }
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelSubjectBeingEdited: {
      return { ...state, subjectRefBeingEdited: undefined };
    }

    case ActionTypes.QuotationTemplateConfigSubjectSelected: {
      return {
        ...state,
        selectedSubjectRef: action.payload,
      };
    }

    case ActionTypes.QuotationTemplateConfigRemoveSubject: {
      return {
        ...state,
        subjectRefBeingRemoved: action.payload,
      };
    }

    case ActionTypes.QuotationTemplateConfigCommitRemoveSubject: {
      if (state.subjectRefBeingRemoved == null) {
        return state;
      }

      const subjectIdBeingRemoved = state.subjectRefBeingRemoved;
      return stateWithGroupUpdated(
        state,
        subjectIdBeingRemoved.categoryId,
        subjectIdBeingRemoved.groupId,
        group => {
          group.subjects = group.subjects.filter(
            x => x.id !== subjectIdBeingRemoved.subjectId,
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          state.subjectRefBeingRemoved = undefined;
          if (
            state.selectedSubjectRef?.subjectId ===
            subjectIdBeingRemoved.subjectId
          ) {
            state.selectedSubjectRef = undefined;
          }
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelRemoveSubject: {
      if (
        state.selectedGroupRef == null ||
        state.subjectRefBeingRemoved == null
      ) {
        return state;
      }
      return {
        ...state,
        subjectRefBeingRemoved: undefined,
      };
    }

    case ActionTypes.QuotationTemplateConfigSubjectMoved: {
      const { groupRef, from, to } = action.payload as {
        groupRef: QuotationGroupRef;
        from: number;
        to: number;
      };
      return stateWithGroupUpdated(
        state,
        groupRef.categoryId,
        groupRef.groupId,
        group => {
          array_move(group.subjects, from, to);
        },
      );
    }

    //#endregion

    //#region item related action handlers
    case ActionTypes.QuotationTemplateConfigAddItem: {
      const itemId = makeUniqueIdAlphabetic();
      const subjectRef = action.payload as QuotationSubjectRef;
      return { ...state, itemRefBeingEdited: { ...subjectRef, itemId } };
    }

    case ActionTypes.QuotationTemplateConfigEditItem: {
      return {
        ...state,
        itemRefBeingEdited: action.payload as QuotationItemRef,
      };
    }

    case ActionTypes.QuotationTemplateConfigCommitItemBeingEdited: {
      if (state.itemRefBeingEdited == null) {
        return state;
      }

      const itemStaged = action.payload as QuotationTemplateItemStaged;

      const itemIdBeingEdited = state.itemRefBeingEdited;

      return stateWithSubjectUpdated(
        state,
        itemIdBeingEdited,
        subject => {
          const index = subject.items.findIndex(
            x => x.id === itemIdBeingEdited.itemId,
          );
          if (index != null && index >= 0) {
            subject.items[index] = itemStaged;
          } else {
            subject.items.push(itemStaged);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          state.itemRefBeingEdited = undefined;
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelItemBeingEdited: {
      return { ...state, itemRefBeingEdited: undefined };
    }

    case ActionTypes.QuotationTemplateConfigRemoveItem: {
      return {
        ...state,
        itemRefBeingRemoved: action.payload,
      };
    }

    case ActionTypes.QuotationTemplateConfigCommitRemoveItem: {
      if (state.itemRefBeingRemoved == null) {
        return state;
      }

      const itemIdBeingRemoved = state.itemRefBeingRemoved;

      return stateWithSubjectUpdated(
        state,
        itemIdBeingRemoved,
        subject => {
          subject.items = subject.items.filter(
            x => x.id !== itemIdBeingRemoved.itemId,
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          state.itemRefBeingRemoved = undefined;
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelRemoveItem: {
      if (state.itemRefBeingRemoved == null) {
        return state;
      }

      return {
        ...state,
        itemRefBeingRemoved: undefined,
      };
    }

    case ActionTypes.QuotationTemplateConfigItemMoved: {
      const { from, to, subjectRef } = action.payload as {
        subjectRef: QuotationSubjectRef;
        from: number;
        to: number;
      };

      return stateWithSubjectUpdated(state, subjectRef, subject => {
        array_move(subject.items, from, to);
      });
    }

    case ActionTypes.QuotationTemplateConfigItemCheckedChange: {
      const { itemRef, checked } = action.payload as {
        itemRef: QuotationItemRef;
        checked: boolean;
      };

      return stateWithItemUpdated(state, itemRef, item => {
        item.included = checked;
      });
    }

    //#endregion

    //#region material related action handlers
    case ActionTypes.QuotationTemplateConfigAddMaterial: {
      const itemRef = action.payload as QuotationItemRef;
      const materialId = makeUniqueIdAlphabetic();
      return { ...state, materialRefBeingEdited: { ...itemRef, materialId } };
    }

    case ActionTypes.QuotationTemplateConfigEditMaterial: {
      return { ...state, materialRefBeingEdited: action.payload };
    }

    case ActionTypes.QuotationTemplateConfigCommitMaterialBeingEdited: {
      if (state.materialRefBeingEdited == null) {
        return state;
      }

      const materialIdBeingEdited = state.materialRefBeingEdited;

      const materialStaged = action.payload as QuotationTemplateMaterialStaged;

      return stateWithItemUpdated(
        state,
        materialIdBeingEdited,
        item => {
          if (item.materials == null) {
            item.materials = [];
          }
          const index = item.materials.findIndex(
            x => x.id === materialIdBeingEdited.materialId,
          );
          if (index != null && index >= 0) {
            item.materials[index] = materialStaged;
          } else {
            item.materials.push(materialStaged);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          state.materialRefBeingEdited = undefined;
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelMaterialBeingEdited: {
      return { ...state, materialRefBeingEdited: undefined };
    }

    case ActionTypes.QuotationTemplateConfigRemoveMaterial: {
      return {
        ...state,
        materialRefBeingRemoved: action.payload,
      };
    }

    case ActionTypes.QuotationTemplateConfigCommitRemoveMaterial: {
      if (state.materialRefBeingRemoved == null) {
        return state;
      }

      const materialIdBeingRemoved = state.materialRefBeingRemoved;

      return stateWithItemUpdated(
        state,
        materialIdBeingRemoved,
        item => {
          item.materials = item.materials?.filter(
            x => x.id !== materialIdBeingRemoved.materialId,
          );
        },
        // eslint-disable-next-line @typescript-eslint/no-shadow
        state => {
          state.materialRefBeingRemoved = undefined;
        },
      );
    }

    case ActionTypes.QuotationTemplateConfigCancelRemoveMaterial: {
      if (state.materialRefBeingRemoved == null) {
        return state;
      }

      return {
        ...state,
        materialRefBeingRemoved: undefined,
      };
    }

    case ActionTypes.QuotationTemplateConfigMaterialMoved: {
      const { itemRef, from, to } = action.payload as {
        itemRef: QuotationItemRef;
        from: number;
        to: number;
      };

      return stateWithItemUpdated(state, itemRef, item => {
        array_move(item.materials ?? [], from, to);
      });
    }

    case ActionTypes.QuotationTemplateConfigMaterialCheckedChange: {
      const { materialRef, checked } = action.payload as {
        materialRef: QuotationMaterialRef;
        checked: boolean;
      };

      return stateWithItemUpdated(state, materialRef, item => {
        const material = item.materials?.find(
          x => x.id === materialRef.materialId,
        );
        if (material) {
          if (material.optionGroup && checked) {
            const materialsInGroup = item.materials?.filter(
              x => x.optionGroup === material.optionGroup,
            );
            materialsInGroup?.forEach(x => (x.included = false));
          }
          material.included = checked;
        }
      });
    }

    //#endregion

    default: {
      return defaultReducer(state, action);
    }
  }
}

type StateUpdateOptions = {
  markAsDirty?: boolean;
};

function stateUpdated(
  state: QuotationTemplateConfigState,
  recipe: (draft: Draft<QuotationTemplateConfigState>) => void,
  options?: StateUpdateOptions,
) {
  const res = produce(state, recipe);
  if (res !== state && options?.markAsDirty !== false) {
    return produce(res, draft => {
      draft.dirty = true;
    });
  }
  return res;
}

function stateWithCategoriesUpdated(
  state: QuotationTemplateConfigState,
  update: (
    categories: Draft<QuotationTemplateCategoryStaged>[],
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: QuotationTemplateConfigState) => void,
  options?: StateUpdateOptions,
): QuotationTemplateConfigState {
  return stateUpdated(
    state,
    draft => {
      update(draft.staged.categories, draft);
      stateRecipe?.(draft);
    },
    options,
  );
}

function stateWithCategoryUpdated(
  state: QuotationTemplateConfigState,
  categoryId: string,
  update: (
    category: Draft<QuotationTemplateCategoryStaged>,
    index: number,
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: QuotationTemplateConfigState) => void,
  options?: StateUpdateOptions,
) {
  return stateWithCategoriesUpdated(
    state,
    (categories, draft) => {
      const index = categories.findIndex(x => x.id === categoryId);
      if (index < 0) {
        return;
      }
      update(categories[index], index, draft);
    },
    stateRecipe,
    options,
  );
}

function stateWithGroupUpdated(
  state: QuotationTemplateConfigState,
  categoryId: string,
  groupId: string,
  update: (
    group: Draft<QuotationTemplateGroupStaged>,
    index: number,
    category: Draft<QuotationTemplateCategoryStaged>,
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: Draft<QuotationTemplateConfigState>) => void,
  options?: StateUpdateOptions,
): QuotationTemplateConfigState {
  return stateUpdated(
    state,
    draft => {
      for (const category of draft.staged.categories) {
        if (category.id === categoryId) {
          for (let index = 0; index < category.groups.length; index++) {
            const group = category.groups[index];
            if (group.id === groupId) {
              update(group, index, category, draft);
              break;
            }
          }
        }
      }
      stateRecipe?.(draft);
    },
    options,
  );
}

function stateWithSubjectUpdated(
  state: QuotationTemplateConfigState,
  subjectRef: QuotationSubjectRef,
  update: (
    subject: Draft<QuotationTemplateSubjectStaged>,
    index: number,
    group: Draft<QuotationTemplateGroupStaged>,
    category: Draft<QuotationTemplateCategoryStaged>,
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: Draft<QuotationTemplateConfigState>) => void,
  options?: StateUpdateOptions,
): QuotationTemplateConfigState {
  return stateUpdated(
    state,
    draft => {
      for (const category of draft.staged.categories) {
        if (category.id === subjectRef.categoryId) {
          for (const group of category.groups) {
            if (group.id === subjectRef.groupId) {
              for (let index = 0; index < group.subjects.length; index++) {
                const subject = group.subjects[index];
                if (subject.id === subjectRef.subjectId) {
                  update(subject, index, group, category, draft);
                  break;
                }
              }
            }
          }
        }
      }
      stateRecipe?.(draft);
    },
    options,
  );
}

function stateWithItemUpdated(
  state: QuotationTemplateConfigState,
  itemRef: QuotationItemRef,
  update: (
    item: Draft<QuotationTemplateItemStaged>,
    index: number,
    subject: Draft<QuotationTemplateSubjectStaged>,
    group: Draft<QuotationTemplateGroupStaged>,
    category: Draft<QuotationTemplateCategoryStaged>,
    state: Draft<QuotationTemplateConfigState>,
  ) => void,
  stateRecipe?: (state: Draft<QuotationTemplateConfigState>) => void,
  options?: StateUpdateOptions,
): QuotationTemplateConfigState {
  return stateUpdated(
    state,
    draft => {
      for (const category of draft.staged.categories) {
        if (category.id === itemRef.categoryId) {
          for (const group of category.groups) {
            if (group.id === itemRef.groupId) {
              for (const subject of group.subjects) {
                if (subject.id === itemRef.subjectId) {
                  for (let index = 0; index < subject.items.length; index++) {
                    if (subject.items[index].id === itemRef.itemId) {
                      update(
                        subject.items[index],
                        index,
                        subject,
                        group,
                        category,
                        draft,
                      );
                      break;
                    }
                  }
                }
              }
            }
          }
        }
      }
      stateRecipe?.(draft);
    },
    options,
  );
}
