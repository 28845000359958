/**
 * @file: StoreService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Store } from 'model';
import { StoreEnrollmentParams } from 'model/StoreEnrollmentParams';
import { StoreServiceBase } from './base/StoreService';

export class StoreService extends StoreServiceBase {
  async renew(storeId: number, params: StoreEnrollmentParams): Promise<Store> {
    return await this.api
      .post()
      .url('/stores/:storeId/enrollments', { storeId })
      .data(params)
      .future();
  }
}
