import { combineReducers } from 'redux';

import organizations from './organizations';
import stores from './stores';
import orgMembers from './org-members';
import orgUsers from './org-users';
import orgCustomers from './org-customers';
import agents from './agents';
import productAgents from './product-agents';

export default combineReducers({
  organizations,
  stores,
  orgMembers,
  orgUsers,
  orgCustomers,
  agents,
  productAgents,
});
