import classNames from 'classnames';
import { Component, CSSProperties, FocusEvent, ReactNode } from 'react';
import ReactSelect, {
  FormatOptionLabelMeta,
  Options,
  Props as SelectProps,
} from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';

type CssClsType = string | string[] | { [key: string]: any };

interface Props<T> {
  logging?: boolean | string;
  values: T[];
  valueProp: string;
  labelProp: string;
  multi?: boolean;
  selectedValue?: T | T[] | null;
  autoFocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
  width?: number | string;
  isLoading?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  noOptionsMessage?: ((obj: { inputValue: string }) => string | null) | string;
  className?: CssClsType | CssClsType[];
  classNamePrefix?: string;
  containerStyle?: CSSProperties;
  menuStyle?: CSSProperties;
  valueContainerStyle?: CSSProperties;
  isOptionDisabled?: (option: T | any) => boolean;

  onGetOptionValue?: (option: T | any) => any;
  onGetOptionLabel?: (option: T | any) => any;
  onFormatOptionLabel?: (
    option: T | any,
    meta: FormatOptionLabelMeta<T | any>,
  ) => ReactNode;
  onChange: (value: T | T[] | null | undefined | any) => void;

  // async select props
  async?: boolean;
  defaultValues?: T[] | boolean;
  onLoadValues?: (
    inputValue: string,
    callback: (options: T[]) => void,
  ) => Promise<any> | undefined;
  cacheValues?: any;

  // creatable select props
  creatable?: boolean;
  createOnBlur?: boolean;
  allowCreateWhileLoading?: boolean;
  onFormatCreateLabel?: (inputValue: string) => ReactNode;
  isValidNewOption?: (
    inputValue: string,
    value: Options<T>,
    options: ReadonlyArray<T>,
  ) => boolean;
  onGetNewOptionData?: (inputValue: string, optionLabel: ReactNode) => T;
  onCreateOption?: (inputValue: string) => void;
  createOptionPosition?: 'first' | 'last';
}

export class Select<T> extends Component<Props<T>> {
  render() {
    const {
      values,
      selectedValue,
      logging,
      containerStyle,
      menuStyle,
      valueContainerStyle,
      creatable,
      createOnBlur,
    } = this.props;

    if (logging) {
      console.log(`[${logging}]select.styles: `, {
        containerStyle,
        menuStyle,
        valueContainerStyle,
      });
    }

    const selectProps: SelectProps<T> = {
      options: values,
      autoFocus: this.props.autoFocus,
      isLoading: this.props.isLoading,
      isMulti: this.props.multi,
      isClearable: this.props.isClearable,
      isSearchable: this.props.isSearchable,
      value: selectedValue,
      placeholder: this.props.placeholder,
      isDisabled: this.props.disabled,
      noOptionsMessage: this.noOptionsMessage,
      className: classNames(this.props.className),
      classNamePrefix: this.props.classNamePrefix,
      styles: {
        container: base => {
          const style = {
            ...base,
            width: this.props.width,
            ...containerStyle,
          };
          if (logging) {
            console.log(`[${logging}]select.style.container: `, style);
          }
          return style;
        },
        menu: base => ({ ...base, zIndex: 99999, ...menuStyle }),
        valueContainer: base => ({
          ...base,
          whiteSpace: 'nowrap',
          ...valueContainerStyle,
        }),
      },
      components: { IndicatorSeparator: null } as any,
      onChange: this.onChange as any,
      formatOptionLabel: this.props.onFormatOptionLabel,
      getOptionLabel: this.getOptionLabel as any,
      getOptionValue: this.getOptionValue as any,
      hideSelectedOptions: false,
      backspaceRemovesValue: true,
      isOptionDisabled: this.props.isOptionDisabled,
    };

    if (logging) {
      console.log('select.props: ', selectProps);
    }

    const creatableProps: CreatableProps<T, any, any> = {
      allowCreateWhileLoading: this.props.allowCreateWhileLoading,
      formatCreateLabel: this.props.onFormatCreateLabel,
      isValidNewOption: this.props.isValidNewOption,
      getNewOptionData: this.props.onGetNewOptionData,
      onCreateOption: this.props.onCreateOption,
      createOptionPosition: this.props.createOptionPosition,
      onBlur: function (e: FocusEvent<HTMLInputElement>) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const _this = this;
        const value = e.target.value;
        if (
          !creatable ||
          !createOnBlur ||
          !value ||
          !Array.isArray(_this.options)
        ) {
          return;
        }
        const option = _this.options?.find(
          x => _this.getOptionValue?.(x) === value,
        );
        if (option) {
          _this.onChange?.(
            option as any,
            {
              action: 'select-option',
            } as any,
          );
        }
      },
    };

    if (this.props.async) {
      if (!this.props.onLoadValues) {
        throw new Error('onLoadValues is required for async select component');
      }

      const asyncProps: AsyncProps<T, any, any> = {
        defaultOptions: this.props.defaultValues,
        loadOptions: this.props.onLoadValues,
        cacheOptions: this.props.cacheValues,
      };

      if (creatable) {
        return (
          <AsyncCreatableSelect<T>
            {...selectProps}
            {...asyncProps}
            {...creatableProps}
          />
        );
      } else {
        return <AsyncSelect<T> {...selectProps} {...asyncProps} />;
      }
    }

    if (creatable) {
      return <CreatableSelect<T> {...selectProps} {...creatableProps} />;
    }

    return <ReactSelect {...(selectProps as any)} />;
  }

  getOptionLabel = (option: T) => {
    const { labelProp, onGetOptionLabel } = this.props;
    if (onGetOptionLabel) return onGetOptionLabel(option);
    return (option as any)[labelProp];
  };

  getOptionValue = (option: T) => {
    const { valueProp, onGetOptionValue } = this.props;
    if (onGetOptionValue) return onGetOptionValue(option);
    return (option as any)[valueProp];
  };

  onChange = (option: T | T[] | null | undefined) => {
    this.props.onChange && this.props.onChange(option);
  };

  noOptionsMessage = (obj: { inputValue: string }): string | null => {
    if (!this.props.noOptionsMessage) return null;
    if (typeof this.props.noOptionsMessage === 'function') {
      return this.props.noOptionsMessage(obj);
    }
    return this.props.noOptionsMessage || '';
  };
}
