/**
 * @file: OrgUserAppleSignInBindingService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OrgUserAppleSignInBindingServiceBase } from './base/OrgUserAppleSignInBindingService';

export class OrgUserAppleSignInBindingService extends OrgUserAppleSignInBindingServiceBase {
  // add custom service implementation here
}
