/**
 * @file: organizations.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { Organization, OrganizationListFilter } from 'model';
import { organizationService } from 'services';
import { ActionTypes } from '../types';

export const organizationActions = createListAsyncActionCreators<
  AppState,
  Organization,
  OrganizationListFilter,
  number
>('customers.organizations', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.customers.organizations.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.customers.organizations.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.customers.organizations.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await organizationService.list(
      state.customers.organizations.filter,
      state.customers.organizations.sorts?.[0],
      state.customers.organizations.offset,
      state.customers.organizations.limit,
      { count: true, recursive: true },
    );
  },
  async create(org: Partial<Organization>) {
    org = {
      ...org,
      cid: org.cid?.trim(),
      name: org.name?.trim(),
      shortName: org.shortName?.trim(),
      brandName: org.brandName?.trim(),
      address: org.address?.trim(),
    };
    return await organizationService.create(org);
  },
  async update(org: Organization) {
    org = {
      ...org,
      name: org.name?.trim(),
      shortName: org.shortName?.trim(),
      brandName: org.brandName?.trim(),
      address: org.address?.trim(),
    };
    return await organizationService.update(org);
  },
  async delete(org: Organization) {
    await organizationService.delete(org.id);
  },
  async deleteMulti(orgs: Organization[]) {
    if (orgs.length === 1) {
      await organizationService.delete(orgs[0].id);
      return;
    }
    throw new Error('delete multi organizations is not supported. ');
  },
});

export function downloadQrcodeForOrg(org: Organization | null) {
  return createStandardAction(ActionTypes.DownloadQrcodeForOrg, org);
}
