import classNames from 'classnames';
import { HTMLProps, ReactNode } from 'react';

interface Props extends HTMLProps<HTMLLIElement> {
  text?: ReactNode;
}

export const MenuSection = (props: Props) => {
  const { className, text, ...otherProps } = props;
  return (
    <li className={classNames('m-menu__section', className)} {...otherProps}>
      <h4 className="m-menu__section-text">{text}</h4>
      <i className="m-menu__section-icon flaticon-more-v2" />
    </li>
  );
};
