import { DeliveryCheckTemplateManagerProps } from 'app/inspection/delivery-check-templates/list';
import {
  DeliveryCheckTemplateMatchTypeOptions,
  VehicleDeliveryCheckTemplate,
} from 'model';
import { systemService } from 'services';
import { getString, withEntityEditorSidebarBuilder } from 'shared/components';
import { BarriersSettings } from './BarriersSettings';

export const DeliveryCheckTemplateEditor =
  withEntityEditorSidebarBuilder<VehicleDeliveryCheckTemplate>()
    .withI18nPrefix('delivery_check_template')
    .withForm(builder =>
      builder
        .agent({
          prop: 'agentId',
          label: 'delivery_check_template.editor.label.agent',
          helpText: 'delivery_check_template.editor.help_text.agent',
        })
        .text({
          prop: 'name',
          label: 'delivery_check_template.editor.label.name',
          placeholder: 'delivery_check_template.editor.placeholder.name',
        })
        .select({
          prop: 'withInspectionResults',
          label: 'delivery_check_template.editor.label.with_inspection_results',
          placeholder:
            'delivery_check_template.editor.placeholder.with_inspection_results',
          helpText:
            'delivery_check_template.editor.help_text.with_inspection_results',
          options: DeliveryCheckTemplateMatchTypeOptions,
        })
        .select({
          prop: 'withConstructionJobs',
          label: 'delivery_check_template.editor.label.with_construction_jobs',
          placeholder:
            'delivery_check_template.editor.placeholder.with_construction_jobs',
          helpText:
            'delivery_check_template.editor.help_text.with_construction_jobs',
          options: DeliveryCheckTemplateMatchTypeOptions,
        })
        .reactSelect({
          valueProp: 'name',
          labelProp: 'name',
          prop: 'withOrderType',
          label: 'delivery_check_template.editor.label.with_order_type',
          placeholder:
            'delivery_check_template.editor.placeholder.with_order_type',
          helpText: 'delivery_check_template.editor.help_text.with_order_type',
          async: true,
          stateId: 'system-predefined-order-tags',
          values: [],
          defaultValues: true,
          clearable: true,
          searchable: false,
          async onLoadValues() {
            return await systemService.getPredefinedOrderTags();
          },
        })
        .checkbox({
          prop: 'isSystemDefault',
          label: 'delivery_check_template.editor.label.is_system_default',
        })
        .checkbox({
          prop: 'disabled',
          inverse: true,
          label: 'delivery_check_template.editor.label.enabled',
        })
        .custom({
          label: '',
          key: 'barriers',
          hidden: entity => Boolean(!entity.id || entity.agentId),
          render: ({ props }: { props: DeliveryCheckTemplateManagerProps }) => {
            if (!props.templates.itemBeingUpdated) return null;
            return (
              <BarriersSettings
                templateId={props.templates.itemBeingUpdated.id}
              />
            );
          },
        })
        .textArea({
          prop: 'description',
          label: 'delivery_check_template.editor.label.description',
          placeholder: 'delivery_check_template.editor.placeholder.description',
        }),
    )
    .withValidator(template => {
      let code: string | undefined = undefined;
      if (!template.name?.trim()) {
        code = 'template_name_required';
      }
      if (code) {
        const msg = getString(`delivery_check_template.editor.error.${code}`);
        throw new Error(msg);
      }
    })
    .getClass();
