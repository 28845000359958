import { Location } from 'history';
import { tokenService } from 'lib';
import { Identity } from 'model';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { authService } from 'services';
import { Oops } from 'shared/components/Oops';

import { AclContext } from 'lib/decorators/acl';
import './authenticate.scss';

interface Props {
  location: Location;
}

interface State {
  identity: Identity | null;
  redirectUrl: string | null;
  isLoading: boolean;
}

/**
 * High order component that requires authentication.
 * @param Component
 */
export function authenticate<TProps, T extends Component<TProps>>(
  WrappedComponent: T,
) {
  return class extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = { identity: null, redirectUrl: null, isLoading: false };
    }

    componentDidMount() {
      this.setState({ isLoading: true });
      authService
        .getIdentity({
          returnUrl: location.href,
        })
        .then(
          identity => {
            this.setState({ identity, isLoading: false });
          },
          err => {
            this.setState({ isLoading: false });

            if (!err.response?.authenticator) {
              console.error(err);
              return;
            }

            const { authenticator } = err.response;

            tokenService.removeToken();

            if (authenticator === 'form') {
              this.setState({ redirectUrl: '/login' });
            } else {
              const { redirectUri } = err.response.args;
              location.href = redirectUri;
            }
          },
        );
    }

    $has = (...rights: string[]) => {
      if (!this.state.identity) return false;
      if (!rights.length) return true;
      const identity = this.state.identity;
      return identity.acl.some(x => rights.includes(x));
    };

    render() {
      const { identity, redirectUrl, isLoading } = this.state;
      if (!identity) {
        if (redirectUrl) {
          return <Redirect to={redirectUrl} />;
        }
        return !isLoading ? <Oops /> : null;
      }

      const TargetComponent = WrappedComponent as any;

      return (
        <AclContext.Provider value={{ identity, $has: this.$has }}>
          <TargetComponent identity={identity} {...this.props} />
        </AclContext.Provider>
      );
    }
  };
}
