/**
 * @file: AdminUserAclService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AdminUserAclServiceBase } from './base/AdminUserAclService';

export class AdminUserAclService extends AdminUserAclServiceBase {
  // add custom service implementation here
}
