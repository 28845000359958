import { ConstructionTemplateConfigState } from 'app/inspection/duck/states';
import { AsyncState, StandardAction } from 'lib/duck/interfaces';
import { createAsyncActionReducers } from 'lib/duck/reducers';
import { ConstructionTemplateConfig } from 'model';
import { ActionTypes } from '../types';

const initialState: AsyncState<ConstructionTemplateConfig> = {
  isLoading: false,
  error: null,
  result: null,
};

const defaultReducer = createAsyncActionReducers<
  ConstructionTemplateConfig,
  AsyncState<ConstructionTemplateConfig>
>('inspection.construction-template-config', initialState);

export default function (
  state: ConstructionTemplateConfigState,
  action: StandardAction<any>,
) {
  switch (action.type) {
    case ActionTypes.ConstructionTemplateConfigSelectSidebarItem: {
      return {
        ...state,
        selectedSidebarItem: action.payload,
      };
    }
    case ActionTypes.ConstructionTemplateConfigChanged: {
      return action.payload;
    }
    case ActionTypes.ResetConstructionTemplateConfigChanges: {
      return {
        ...state,
        templateConfigBeingEdited: undefined,
        result: action.payload ?? state.result,
      };
    }
    default: {
      return defaultReducer(state, action);
    }
  }
}
