/**
 * @file: TaskSchedulerStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, TaskSchedulerStatus } from 'model';

export const TaskSchedulerStatusOptions: Array<Option<TaskSchedulerStatus>> = [
  {
    value: TaskSchedulerStatus.Disabled,
    label: 'task_scheduler_status.disabled',
  },
  {
    value: TaskSchedulerStatus.Scheduled,
    label: 'task_scheduler_status.scheduled',
  },
  {
    value: TaskSchedulerStatus.Started,
    label: 'task_scheduler_status.started',
  },
  {
    value: TaskSchedulerStatus.Stopped,
    label: 'task_scheduler_status.stopped',
  },
  {
    value: TaskSchedulerStatus.Finished,
    label: 'task_scheduler_status.finished',
  },
];

export const TaskSchedulerStatusOptionsWithDefault: Array<
  Option<TaskSchedulerStatus | null>
> = [
  { value: null, label: 'task_scheduler_status.__default__' },
  {
    value: TaskSchedulerStatus.Disabled,
    label: 'task_scheduler_status.disabled',
  },
  {
    value: TaskSchedulerStatus.Scheduled,
    label: 'task_scheduler_status.scheduled',
  },
  {
    value: TaskSchedulerStatus.Started,
    label: 'task_scheduler_status.started',
  },
  {
    value: TaskSchedulerStatus.Stopped,
    label: 'task_scheduler_status.stopped',
  },
  {
    value: TaskSchedulerStatus.Finished,
    label: 'task_scheduler_status.finished',
  },
];
