import { memo } from 'react';
import { Translate } from 'react-localize-redux';
import { InlineSvg } from 'shared/components/InlineSvg';
import { Button } from 'shared/metronic/components';

export const EmptyItems = memo(({ onAddItem }: { onAddItem: () => void }) => {
  return (
    <div className={`quotation-tpl__item-panel-empty`}>
      <InlineSvg src="img/no-data.svg" />
      <p style={{ textAlign: 'center' }}>
        <Translate id={`quotation_tpl.item.empty_text.no_items`} />
      </p>
      <div>
        <Button small color="brand" onClick={onAddItem}>
          <Translate id="quotation_tpl.item.button.add_item_now" />
        </Button>
      </div>
    </div>
  );
});
