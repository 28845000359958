import classNames from 'classnames';
import { Component, CSSProperties, ReactNode, RefObject } from 'react';
import styled from 'styled-components';

interface OverlayProps {
  $zIndex?: number;
  $overlayBgColor?: string;
  $overlayOpacity?: number;
  cursor?: string;
}

interface MsgProps {
  $zIndex?: number;
  cursor?: string;
  msg?: string | ReactNode;
}

type Props = {
  children: ReactNode;
  active?: boolean;
  $zIndex?: number;
  className?: string;
  style?: CSSProperties;
  msgClassName?: string;
  msgStyle?: CSSProperties;
  blockRef?: RefObject<HTMLDivElement>;
} & OverlayProps &
  MsgProps;

const BlockOverlay = styled.div<OverlayProps>`
  z-index: ${props => props.$zIndex!};
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${props => props.$overlayBgColor};
  opacity: ${props => props.$overlayOpacity};
  cursor: ${props => props.cursor};
  position: absolute;
`;

const BlockMsgContainer = styled.div<MsgProps>`
  z-index: ${props => props.$zIndex! + 11};
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockMsg = styled.div<MsgProps>`
  z-index: ${props => props.$zIndex! + 11};
  position: absolute;
  padding: 0;
  margin: 0;
  text-align: center;
  color: rgb(0, 0, 0);
  border: 0;
  cursor: ${props => props.cursor};
`;

export class Block extends Component<Props> {
  static defaultProps: Partial<Props> = {
    $zIndex: 1000,
    $overlayBgColor: 'rgb(0, 0, 0)',
    $overlayOpacity: 0.03,
    cursor: 'wait',
    msg: (
      <>
        <span>Loading... </span>
        <span>
          <div className="m-loader m-loader--success" />
        </span>
      </>
    ),
  };

  render() {
    const {
      children,
      style,
      className,
      active,
      $zIndex,
      $overlayBgColor,
      $overlayOpacity,
      cursor,
      msg,
      msgStyle,
      msgClassName,
      blockRef,
    } = this.props;

    const overlayProps = { $zIndex, $overlayBgColor, $overlayOpacity, cursor };
    const msgProps = { $zIndex, cursor };
    const containerProps = { $zIndex };

    return (
      <div
        className={classNames('m-block', className)}
        ref={blockRef}
        style={Object.assign({ position: 'relative', zoom: 1 }, style)}
      >
        {children}
        {active && (
          <>
            <div className="blockUI" style={{ display: 'none' }} />
            <BlockMsgContainer {...containerProps}>
              <BlockMsg className="blockUI blockMsg blockElement" {...msgProps}>
                <div
                  className={classNames('m-blockui', msgClassName)}
                  style={Object.assign({}, msgStyle)}
                >
                  {msg}
                </div>
              </BlockMsg>
            </BlockMsgContainer>
            <BlockOverlay {...overlayProps} />
          </>
        )}
      </div>
    );
  }
}
