import { storageService } from 'lib';
import React, { Component } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Route, Switch } from 'react-router-dom';
import { NotFound, Oops } from 'shared/components';
import { Main } from './Main';
import stringsEn from './i18n/en';
import stringsZh from './i18n/zh';
import { Login } from './login/Login';

interface Props extends LocalizeContextProps {
  token?: string;
}

export class AppComponent extends Component<Props> {
  constructor(props: Props) {
    super(props);
    const defaultLanguage = (
      storageService.ls_get<string>('lang') ||
      navigator.language ||
      (navigator as any).userLanguage ||
      'en-US'
    ).replace(/-.*$/, '');
    this.props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Chinese', code: 'zh' },
      ],
      options: {
        defaultLanguage,
        renderToStaticMarkup,
      },
    });
    this.props.addTranslationForLanguage(stringsEn, 'en');
    this.props.addTranslationForLanguage(stringsZh, 'zh');
  }

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/oops" component={Oops} />
          <Route exact path="/404" component={NotFound} />
          <Route path="/" component={Main} />
        </Switch>
      </React.Fragment>
    );
  }
}

export const App = withLocalize<Props>(AppComponent);
