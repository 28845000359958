/**
 * @file: quotationPartCatalogs.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators, createStandardAction } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { QuotationPartCatalog } from 'model';
import { quotationPartCatalogService } from 'services';
import { ActionTypes } from '../types';

export const quotationPartCatalogActions = createListAsyncActionCreators<AppState, QuotationPartCatalog>(
  'app.settings.quotation_part_catalogs',
  {
    getItemBeingCreated: (state: AppState) => state.settings.quotationPartCatalogs.itemBeingCreated,
    getItemBeingUpdated: (state: AppState) => state.settings.quotationPartCatalogs.itemBeingUpdated,
    getItemsBeingDeleted: (state: AppState) => state.settings.quotationPartCatalogs.itemsBeingDeleted,

    fetchHandler: (state: AppState) => {
      return quotationPartCatalogService.list(
        Object.assign({}, state.settings.quotationPartCatalogs.filter, { orgId: 0, storeId: 0 }),
        state.settings.quotationPartCatalogs.sorts?.[0],
        state.settings.quotationPartCatalogs.offset,
        state.settings.quotationPartCatalogs.limit,
        { count: true, recursive: false }
      );
    },
    create: (entity: QuotationPartCatalog) => {
      return quotationPartCatalogService.create(entity);
    },
    update: (entity: QuotationPartCatalog) => {
      return quotationPartCatalogService.update(entity);
    },
    delete: (entity: QuotationPartCatalog) => {
      return quotationPartCatalogService.delete(entity.id);
    },
    deleteMulti: (entities: QuotationPartCatalog[]) => {
      if (!entities.length) return Promise.resolve();
      if (entities.length === 1) {
        return quotationPartCatalogService.delete(entities[0].id);
      }
      return Promise.reject(
        new Error('delete multi quotation part catalogs is not supported for now. ')
        );
    }
  }
);

export function importQuotationPartCatalogs() {
  return createStandardAction(ActionTypes.ImportQuotationPartCatalogs);
}

export function importQuotationPartCatalogsSuccess(count: number) {
  return createStandardAction(ActionTypes.ImportQuotationPartCatalogsSuccess, count);
}

export function importQuotationPartCatalogsFailed(error: Error) {
  return createStandardAction(ActionTypes.ImportQuotationPartCatalogsFailed, error);
}