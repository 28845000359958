import React, { Component, ChangeEvent, ReactNode } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import {
  ServiceDurationUnit,
  ServiceDurationUnitOptions,
  ServiceDurationUnitOptionsWithDefault,
} from 'model';

import './service-duration-picker.scss';
import { getString } from 'shared/components/StringLabel';
import { numberFromString } from 'utils';
import {
  durationFromString,
  durationToString,
} from 'utils/serviceDurationUtil';

interface Props extends LocalizeContextProps {
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  required?: boolean;
  onChange: (value: string | null | undefined) => void;
}

class ServiceDurationPickerComponent extends Component<Props> {
  render() {
    const { value } = this.props;
    const [duration, unit] = durationFromString(value);
    return (
      <div className="service-duration-picker">
        {this.renderInput(duration, unit, this.onDurationValueChange)}
        {this.renderSelect(unit, this.onDurationUnitChange)}
      </div>
    );
  }

  renderInput(
    value: number | null | undefined,
    unit: ServiceDurationUnit,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  ) {
    return (
      <input
        type="number"
        value={unit === ServiceDurationUnit.None ? 0 : value ?? ''}
        placeholder={getString(this.props.placeholder)}
        onChange={onChange}
        disabled={this.props.disabled || unit === ServiceDurationUnit.None}
        className="form-control"
      />
    );
  }

  renderSelect(
    value: ServiceDurationUnit | undefined,
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
  ): ReactNode {
    const { disabled } = this.props;
    const options = (
      this.props.required
        ? ServiceDurationUnitOptions
        : ServiceDurationUnitOptionsWithDefault
    ).filter(x => x.value !== ServiceDurationUnit.None);
    return (
      <select
        className="form-control"
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        {options.map(option => (
          <option key={option.value ?? ''} value={option.value ?? ''}>
            {getString(option.label)}
          </option>
        ))}
      </select>
    );
  }

  onDurationValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = numberFromString(e.target.value);
    const [, unit] = durationFromString(this.props.value);
    const s = durationToString(value, unit);
    this.props.onChange(s);
  };

  onDurationUnitChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const [duration] = durationFromString(this.props.value);
    const temp = Number(e.target.value);
    const value = e.target.value
      ? temp
      : this.props.required
      ? ServiceDurationUnit.None
      : undefined;
    const s = durationToString(duration, value);
    this.props.onChange(s);
  };
}

export const ServiceDurationPicker = withLocalize<Props>(
  ServiceDurationPickerComponent,
);
