/**
 * @file: TransferBatchDetailStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum TransferBatchDetailStatus {
  Init = 'INIT',
  Pending = 'WAIT_PAY',
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
  Failed = 'FAIL',
}

export const TransferBatchDetailStatusValueSet = new Set([
  TransferBatchDetailStatus.Init,
  TransferBatchDetailStatus.Pending,
  TransferBatchDetailStatus.Processing,
  TransferBatchDetailStatus.Success,
  TransferBatchDetailStatus.Failed,
]);
