import { ConfirmRemoveSubjectModal } from 'app/inspection/quotation-template-config/subject/ConfirmRemoveModal';
import { SubjectEditor } from 'app/inspection/quotation-template-config/subject/SubjectEditor';
import { memo, useCallback, useEffect, useRef } from 'react';
import { Detail } from './Detail';
import { Sidebar } from './Sidebar';
import { ConfirmRemoveCategoryModal } from './category/ConfirmRemoveModal';
import { ConfirmRemoveGroupModal } from './group/ConfirmRemoveModal';

export const Content = memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  const height = useRef(0);

  const onResize = useCallback(() => {
    const footerEl = document.querySelector('.m-grid__item.m-footer');
    const contentEl = document.querySelector('.m-subheader + .m-content');
    if (footerEl && contentEl) {
      const footerRect = footerEl.getBoundingClientRect();
      const contentRect = contentEl.getBoundingClientRect();
      height.current = footerRect.top - contentRect.top - 60 - 2.2 * 13;
      console.log('panel height: %s', height.current);
    }
    if (ref.current) {
      ref.current.style.minHeight = `${height.current}px`;
    }
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  return (
    <div className="quotation-tpl__content" ref={ref}>
      <Sidebar />
      <Detail />
      <ConfirmRemoveCategoryModal />
      <ConfirmRemoveGroupModal />
      <ConfirmRemoveSubjectModal />
      <SubjectEditor />
    </div>
  );
});
