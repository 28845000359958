/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import classNames from 'classnames';

export const ActionButtonCls = classNames(
  'm-portlet__nav-link btn m-btn m-btn--hover-accent',
  'm-btn--icon m-btn--icon-only m-btn--pill'
);