/**
 * @file: ui-state.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { InspectionSiteInventoryUIState } from '../states';
import { ActionTypes } from '../types';

const initialState: InspectionSiteInventoryUIState = {
  expandedNodes: new Set(),
  collapsedNodes: new Set(),
  checkedNodes: new Set(),
};

export default function (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: InspectionSiteInventoryUIState = initialState,
  action: StandardAction<any>,
): InspectionSiteInventoryUIState {
  switch (action.type) {
    case ActionTypes.NodeSelected: {
      const nodeId = action.payload as string;
      return { ...state, selectedNodeId: nodeId };
    }
    case ActionTypes.NodeExpanded: {
      const { nodeId, expandAllByDefault } = action.payload as {
        nodeId: string;
        expandAllByDefault: boolean;
      };
      const expandedNodes = new Set(state.expandedNodes);
      const collapsedNodes = new Set(state.collapsedNodes);
      if (expandAllByDefault) {
        collapsedNodes.delete(nodeId);
      } else {
        expandedNodes.add(nodeId);
      }
      return { ...state, expandedNodes, collapsedNodes };
    }
    case ActionTypes.NodeCollapsed: {
      const { nodeId, expandAllByDefault } = action.payload as {
        nodeId: string;
        expandAllByDefault: boolean;
      };
      const expandedNodes = new Set(state.expandedNodes);
      const collapsedNodes = new Set(state.collapsedNodes);
      if (expandAllByDefault) {
        collapsedNodes.add(nodeId);
      } else {
        expandedNodes.delete(nodeId);
      }

      return { ...state, expandedNodes, collapsedNodes };
    }
    case ActionTypes.SetExpandedCollapsedNodes: {
      const { expandedNodes, collapsedNodes } = action.payload as {
        expandedNodes: Set<string>;
        collapsedNodes: Set<string>;
      };
      return { ...state, expandedNodes, collapsedNodes };
    }
    case ActionTypes.NodeMouseEnter: {
      const nodeId = action.payload as string;
      return { ...state, mouseEnteredNodeId: nodeId };
    }
    case ActionTypes.NodeMouseLeave: {
      return { ...state, mouseEnteredNodeId: undefined };
    }
    case ActionTypes.ImportInventory: {
      return { ...state, isImporting: true };
    }
    case ActionTypes.ImportInventorySuccess: {
      return { ...state, isImporting: false };
    }
    case ActionTypes.ImportInventoryFailed: {
      return { ...state, isImporting: false };
    }
    default:
      return state;
  }
}
