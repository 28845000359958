/**
 * @file: TransferBatchDetailFailReason.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum TransferBatchDetailFailReason {
  AccountFrozen = 'ACCOUNT_FROZEN',
  RealNameCheckFailed = 'REAL_NAME_CHECK_FAIL',
  InvalidName = 'NAME_NOT_CORRECT',
  InvalidOpenid = 'OPENID_INVALID',
  QuotaExceeded = 'TRANSFER_QUOTA_EXCEED',
  DailyQuotaExceeded = 'DAY_RECEIVED_QUOTA_EXCEED',
  MonthlyQuotaExceeded = 'MONTH_RECEIVED_QUOTA_EXCEED',
  DailyMaxCountExceeded = 'DAY_RECEIVED_COUNT_EXCEED',
  ProductUnauthorized = 'PRODUCT_AUTH_CHECK_FAIL',
  Timeout = 'OVERDUE_CLOSE',
  InvalidIdCard = 'ID_CARD_NOT_CORRECT',
  AccountNotExists = 'ACCOUNT_NOT_EXIST',
  TransferRisk = 'TRANSFER_RISK',
  Other = 'OTHER_FAIL_REASON_TYPE',
  UserAccountLimited = 'REALNAME_ACCOUNT_RECEIVED_QUOTA_EXCEED',
  UserAccountNotPermitted = 'RECEIVE_ACCOUNT_NOT_PERMMIT',
  UserAccountAbnormal = 'PAYEE_ACCOUNT_ABNORMAL',
  MerchantAccountAbnormal = 'PAYER_ACCOUNT_ABNORMAL',
  UnavailableScene = 'TRANSFER_SCENE_UNAVAILABLE',
  InvalidScene = 'TRANSFER_SCENE_INVALID',
  InvalidRemark = 'TRANSFER_REMARK_SET_FAIL',
  UserAccountNotConfigured = 'RECEIVE_ACCOUNT_NOT_CONFIGURE',
  B2CBlockedMonthlyQuotaExceeded = 'BLOCK_B2C_USERLIMITAMOUNT_BSRULE_MONTH',
  B2CBlockedTransferRisk = 'BLOCK_B2C_USERLIMITAMOUNT_MONTH',
  Rejected = 'MERCHANT_REJECT',
  ApprovalTimeout = 'MERCHANT_NOT_CONFIRM',
}

export const TransferBatchDetailFailReasonValueSet = new Set([
  TransferBatchDetailFailReason.AccountFrozen,
  TransferBatchDetailFailReason.RealNameCheckFailed,
  TransferBatchDetailFailReason.InvalidName,
  TransferBatchDetailFailReason.InvalidOpenid,
  TransferBatchDetailFailReason.QuotaExceeded,
  TransferBatchDetailFailReason.DailyQuotaExceeded,
  TransferBatchDetailFailReason.MonthlyQuotaExceeded,
  TransferBatchDetailFailReason.DailyMaxCountExceeded,
  TransferBatchDetailFailReason.ProductUnauthorized,
  TransferBatchDetailFailReason.Timeout,
  TransferBatchDetailFailReason.InvalidIdCard,
  TransferBatchDetailFailReason.AccountNotExists,
  TransferBatchDetailFailReason.TransferRisk,
  TransferBatchDetailFailReason.Other,
  TransferBatchDetailFailReason.UserAccountLimited,
  TransferBatchDetailFailReason.UserAccountNotPermitted,
  TransferBatchDetailFailReason.UserAccountAbnormal,
  TransferBatchDetailFailReason.MerchantAccountAbnormal,
  TransferBatchDetailFailReason.UnavailableScene,
  TransferBatchDetailFailReason.InvalidScene,
  TransferBatchDetailFailReason.InvalidRemark,
  TransferBatchDetailFailReason.UserAccountNotConfigured,
  TransferBatchDetailFailReason.B2CBlockedMonthlyQuotaExceeded,
  TransferBatchDetailFailReason.B2CBlockedTransferRisk,
  TransferBatchDetailFailReason.Rejected,
  TransferBatchDetailFailReason.ApprovalTimeout,
]);
