import { InspectionSiteNodeFilter } from 'model';
import { InspectionSiteInventoryImportResult } from 'model/viewmodel/InspectionSiteInventoryImportResult';
import { ChangeEvent, Component } from 'react';
import { vehicleInspectionSiteService } from 'services';
import {
  ListToolbar,
  ToolbarItem,
  ToolbarItemsBuilder,
  getString,
} from 'shared/components';

interface Props {
  filter: Partial<InspectionSiteNodeFilter>;
  isImporting: boolean;
  onImport: () => void;
  onImportSuccess: (result: InspectionSiteInventoryImportResult) => void;
  onImportFailed: (error: Error) => void;
  onAddCategory: () => void;
  onFilterChange: (filter: Partial<InspectionSiteNodeFilter>) => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
}

export class InspectionSiteToolbar extends Component<Props> {
  private readonly items: Array<ToolbarItem<any>> = [];

  constructor(props: Props) {
    super(props);

    const builder = new ToolbarItemsBuilder<InspectionSiteNodeFilter>();
    this.items = builder
      .text({
        prop: 'keyword',
        label: 'inspection_site.toolbar.label.keyword',
        placeholder: 'inspection_site.toolbar.placeholder.keyword',
        immediate: true,
      })
      .button({
        text: 'inspection_site.toolbar.button.add_category',
        color: 'brand',
        onClick: () => {
          this.props.onAddCategory();
        },
      })
      .button({
        text: () => {
          if (this.props.isImporting) {
            return 'inspection_site.import_sites.progress_msg';
          }
          return 'inspection_site.toolbar.button.import';
        },
        loading: () => this.props.isImporting,
        shouldDisable: () => this.props.isImporting,
        color: 'info',
        file: true,
        accepts: [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
        onFileChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target.files?.length) return;
          const file = e.target.files[0];
          console.log(file.type);
          if (
            (file.type &&
              file.type !==
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') ||
            (file.name && !file.name.toLowerCase().endsWith('.xlsx'))
          ) {
            alert(getString('inspection_site.import_sites.invalid_file'));
            return;
          }
          const reader = new FileReader();
          reader.addEventListener('load', async () => {
            const dataUri = reader.result as string;
            this.props.onImport();
            // setTimeout(() => {
            //   this.props.onImportFailed(new Error(`Error processing request '/api/vehicle-inspection-site-check-item-options':  Error: ER_NO_DEFAULT_FOR_FIELD: Field 'severity_level' doesn't have a default value`));
            // }, 3000);
            try {
              const result =
                await vehicleInspectionSiteService.importInventory(dataUri);
              this.props.onImportSuccess(result);
              // eslint-disable-next-line @typescript-eslint/no-shadow
            } catch (e) {
              this.props.onImportFailed(e);
            }
          });
          reader.readAsDataURL(file);
        },
      })
      .buttonGroup(group =>
        group
          .withPlacement('right')
          .button({
            key: 'expand_all',
            text: 'inspection_site.toolbar.button.expand_all',
            color: 'secondary',
            onClick: () => {
              this.props.onExpandAll();
            },
          })
          .button({
            key: 'collapse_all',
            text: 'inspection_site.toolbar.button.collapse_all',
            color: 'secondary',
            onClick: () => {
              this.props.onCollapseAll();
            },
          }),
      )
      .build();
  }

  render() {
    return (
      <ListToolbar
        filter={this.props.filter}
        items={this.items}
        onFilterChange={this.onFilterChange}
        onGetExtraInfo={this.onGetExtraInfo}
      />
    );
  }

  onFilterChange = (filter: Partial<InspectionSiteNodeFilter>) => {
    this.props.onFilterChange(filter);
  };

  onGetExtraInfo = () => this.props;
}
