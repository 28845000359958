import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { Agent, AgentListFilter } from 'model';
import { agentService } from 'services';
import { isEmail } from 'utils/validators';

export const agentActions = createListAsyncActionCreators<
  AppState,
  Agent,
  AgentListFilter,
  number
>('customers.agents', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.customers.agents.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.customers.agents.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.customers.agents.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await agentService.list(
      state.customers.agents.filter,
      state.customers.agents.sorts?.[0],
      state.customers.agents.offset,
      state.customers.agents.limit,
      { count: true, recursive: true },
    );
  },
  async create(agent: Partial<Agent>) {
    const notificationEmails = (agent.notificationEmail?.trim() ?? '')
      .split(/[,，]/g)
      .map(x => x.trim())
      .filter(x => x && isEmail(x));
    agent = {
      ...agent,
      parentAgentId: agent.parentAgentId ?? 0,
      name: agent.name?.trim(),
      shortName: agent.shortName?.trim(),
      address: agent.address?.trim(),
      notificationEmail: notificationEmails.length
        ? notificationEmails.join(',')
        : undefined,
    };

    return await agentService.create(agent);
  },
  async update(agent: Agent) {
    const notificationEmails = (agent.notificationEmail?.trim() ?? '')
      .split(/[,，]/g)
      .map(x => x.trim())
      .filter(x => x && isEmail(x));
    agent = {
      ...agent,
      parentAgentId: agent.parentAgentId ?? 0,
      name: agent.name?.trim(),
      shortName: agent.shortName?.trim(),
      address: agent.address?.trim(),
      notificationEmail: notificationEmails.length
        ? notificationEmails.join(',')
        : null,
    };
    return await agentService.update(agent);
  },
  async delete(agent: Agent) {
    await agentService.delete(agent.id);
  },
  async deleteMulti(orgs: Agent[]) {
    if (orgs.length === 1) {
      await agentService.delete(orgs[0].id);
      return;
    }
    throw new Error('delete multi agents is not supported. ');
  },
});
