import React, { Component } from 'react';
import $ from 'jquery';

interface Props {
  value?: string;
  placeholder?: string;
  options?: Summernote.Options;
  height?: number;
  disabled?: boolean;
  onInit?: ($summernote: JQuery) => void;
  onChange?: (value: string) => void;
  onCharacterCountChange?: (count: number) => void;
  onPaste?: ($summernote: JQuery) => void;
  onBlur?: ($summernote: JQuery) => void;
}

const DEFAULT_OPTIONS: Partial<Summernote.Options> = {
  height: 250,
  toolbar: [
    ['style', ['bold', 'italic', 'underline', 'clear']],
    ['font', ['strikethrough', 'superscript', 'subscript']],
    ['fontsize', ['fontsize']],
    ['color', ['color']],
    ['para', ['ul', 'ol', 'paragraph']],
    ['height', ['height']]
  ]
};

export class HtmlEditor extends Component<Props> {
  private readonly ref = React.createRef<HTMLDivElement>();
  private $summernote: JQuery;
  private $editable: JQuery;

  componentDidMount() {
    const options = Object.assign({}, DEFAULT_OPTIONS, this.props.options, {
      placeholder: this.props.placeholder || this.props.options?.placeholder,
      callbacks: {
        onInit: this.onInit,
        onChange: this.onChange,
        onBlur: this.onBlur
      }
    });
    $(this.ref.current!).summernote(options);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value) {
      const code: string = this.$summernote.summernote('code') as any;
      if (code !== this.props.value) {
        this.$summernote.summernote('saveRange');
        this.$summernote.summernote('code', this.props.value);
        this.$summernote.summernote('restoreRange');
      }
    }
    if (this.props.disabled !== prevProps.disabled) {
      this.$summernote.summernote(this.props.disabled ? 'disable' : 'enable');
    }
  }

  componentWillUnmount() {
    this.$summernote.summernote('destroy');
  }

  saveRange() {
    this.$summernote.summernote('editor.saveRange');
  }

  restoreRange() {
    this.$summernote.summernote('editor.restoreRange');
  }

  insertImage(src: string, filename?: (string | Summernote.EditImageCallback)) {
    this.$summernote.summernote('editor.insertImage', src, filename);
  }

  render() {
    return (
      <div ref={this.ref} />
    );
  }

  onInit = (e: any) => {
    this.$editable = e.editable;
    this.$summernote = e.note;
    if (this.props.value) {
      this.$summernote.summernote('code', this.props.value);
    }
    if (this.props.disabled) {
      this.$summernote.summernote('disable');
    }
    this.props.onInit && this.props.onInit(e.note);
  }

  onChange = (contents: string, _$editable: JQuery) => {
    if (this.props.onCharacterCountChange && this.$editable) {
      const c = this.$editable.text().length;
      this.props.onCharacterCountChange(c);
    }

    this.props.onChange && this.props.onChange(contents);
  }

  onBlur = () => {
    this.props.onBlur && this.props.onBlur(this.$summernote);
  }
}