/**
 * @file RequestBuilder
 * @author eric <xiang.xu@beego.io>
 * @copyright (c) 2019-2020 sichuan zhichetech co., ltd..
 */

import { APIRequestOptions } from 'lib/restful-client/APIRequestOptions';
import { RestfulService } from 'lib/restful-client/RestfulService';
import ms from 'ms';

export type TransformFn = (x: any) => any;
export type FilterFn = (value: any, index: number, array: any[]) => any;
export type UploadProgressListener = (e: ProgressEvent) => void;
/**
 * @class a simple request class builder.
 */
export class RequestBuilder {
  _api: RestfulService;
  _method: string | null | undefined;
  _json: boolean;
  _url: string;
  _data: any;
  _transformFn: TransformFn;
  _filterFn: FilterFn;
  _cache: boolean | number;
  _cacheKey: string | null | undefined;
  _timeout: number;
  _store: boolean;
  _storeKey: string | null | undefined;
  _fallbackHandler: <T>() => Promise<T>;
  _auth: boolean;
  _handleNetworkResult: <T>(result: T) => Promise<any>;
  _forceNetwork: boolean;
  _raw: boolean;
  _progressListener: UploadProgressListener | undefined;

  constructor(api: RestfulService, method?: string) {
    this._api = api;
    this._method = method;
    this._json = true;
    this._auth = true; // require authentication by default.
    this._raw = false;
  }

  /**
   * Set the url for the request.
   * @param {String} url the url optionally w/ parameters.
   * @param {Object} [args] url parameters.
   * @param {String} [apiEndPoint] api end point.
   * @returns {RequestBuilder} return self for further chaining.
   */
  url(url: string, args?: Record<string, any>, apiEndPoint?: string): this {
    this._url = this._api.url(url, args, apiEndPoint);
    return this;
  }

  /**
   * Set the request as a raw request.
   * @returns {RequestBuilder} return self for further chaining.
   */
  raw(): this {
    this._raw = true;
    return this;
  }

  /**
   * Set the data for the request.
   * @param {Object} data the data to send.
   * @param {Boolean} [json] if send the data as json.
   * @returns {RequestBuilder} return self for further chaining.
   */
  data(data: any, json?: boolean): this {
    this._data = data;
    this._json = data instanceof FormData ? false : json !== false;
    return this;
  }

  /**
   * Set the upload progress listener.
   * @param listener progress event listener.
   */
  listenUploadProgress(listener?: UploadProgressListener) {
    this._progressListener = listener;
    return this;
  }

  /**
   * Set the cacheability of the request.
   * @param {Boolean} cache if the request should be cached.
   * @param {String} key cache key
   * @returns {RequestBuilder} return self for further chaining.
   */
  cache(cache: boolean | number | string = true, key?: string): this {
    if (typeof cache === 'string') {
      cache = ms(cache) as number; // convert string to milliseconds.
    }
    this._cache = cache;
    this._cacheKey = key;
    return this;
  }

  /**
   * Set the timeout of the request.
   * @param {Number} timeout timeout in milliseconds.
   * @returns {RequestBuilder} return self for further chaining.
   */
  timeout(timeout: number): this {
    this._timeout = timeout;
    return this;
  }

  /**
   * Set the transform function for the response.
   * @param {TransformFn} transformFn response transform function.
   *   if the response is an array, the transformFn is called for
   *   each item. otherwise, the transformFn is called for the
   *   response itself.
   */
  transform(transformFn: TransformFn): this {
    this._transformFn = transformFn;
    return this;
  }

  /**
   * Set the filter function for the response.
   * @param {FilterFn} filterFn the filter function
   * @returns {RequestBuilder}
   */
  filter(filterFn: FilterFn): this {
    this._filterFn = filterFn;
    return this;
  }

  /**
   * Specify if the result should be stored in case the network failure.
   * @param {String} [key] the key to use for the request.
   * @returns {RequestBuilder}
   */
  store(key?: string) {
    this._store = true;
    this._storeKey = key;
    return this;
  }

  /**
   * Set if the request requires user authenticated.
   * @param {boolean} [auth = true]
   * @returns {RequestBuilder}
   */
  auth(auth: boolean = true) {
    this._auth = auth;
    return this;
  }

  /**
   * Force request from network.
   * @returns {RequestBuilder}
   */
  forceNetwork() {
    this._forceNetwork = true;
    return this;
  }

  /**
   * Set the local fallback handler.
   * @param {() => Promise<T>} handler
   * @returns {RequestBuilder}
   */
  fallback<T>(handler: () => Promise<T>) {
    this._fallbackHandler = handler as any;
    return this;
  }

  /**
   * Handle network result.
   * @param {(result: T) => Promise<any>} callback
   * @returns {RequestBuilder}
   */
  onNetworkResult<T>(callback: (result: T) => Promise<any>) {
    this._handleNetworkResult = callback as any;
    return this;
  }

  /**
   * Send the request.
   * @returns {Promise<T>}
   */
  async future<T = any>(): Promise<T> {
    const options: APIRequestOptions<T> = {
      url: this._url,
      method: this._method || 'GET',
      json: this._json,
      data: this._data,
      transformFn: this._transformFn,
      filterFn: this._filterFn,
      cache: this._cache,
      cacheKey: this._cacheKey,
      timeout: this._timeout,
      store: this._store,
      storeKey: this._storeKey,
      offlineFallbackHandler: this._fallbackHandler,
      auth: this._auth,
      handleNetworkResult: this._handleNetworkResult,
      forceNetwork: this._forceNetwork,
      raw: this._raw,
      uploadProgressListener: this._progressListener,
    };
    return await this._api.request(options);
  }
}
