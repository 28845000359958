/**
 * @file: vehicle-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { VehicleInfo } from 'model';
import { VehicleInfos } from '../states';

const initialState: VehicleInfos = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultRecuder = createAsyncListActionReducers<VehicleInfo, VehicleInfos>(
  'vehicles.vehicle_list',
  initialState,
  {
    mapItemKey: x => x.id,
  },
);

export default function vehicleInfosReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: VehicleInfos = initialState,
  action: StandardAction<any>,
): VehicleInfos {
  switch (action.type) {
    default: {
      return defaultRecuder(state, action);
    }
  }
}
