import { InspectionSiteInventoryImportResult } from 'model';

type InventoryImportResultFormatData = {
  categoryAdded: number;
  categoryUpdated: number;
  categoryRemoved: number;
  siteAdded: number;
  siteUpdated: number;
  siteRemoved: number;
  itemAdded: number;
  itemUpdated: number;
  itemRemoved: number;
  optionAdded: number;
  optionUpdated: number;
  optionRemoved: number;
};

export function inventoryImportResultToFormatData(
  result: InspectionSiteInventoryImportResult,
): InventoryImportResultFormatData {
  return {
    categoryAdded: result.category.added,
    categoryUpdated: result.category.updated,
    categoryRemoved: result.category.recycled,
    siteAdded: result.site.added,
    siteUpdated: result.site.updated,
    siteRemoved: result.site.recycled,
    itemAdded: result.item.added,
    itemUpdated: result.item.updated,
    itemRemoved: result.item.recycled,
    optionAdded: result.option.added,
    optionUpdated: result.option.updated,
    optionRemoved: result.option.recycled,
  };
}
