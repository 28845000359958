import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app', {
  ToggleMiminize: '',
  OpenMainNavInSmallScreen: '',
  CloseMainNavInSmallScreen: '',
  RevealAsideLeft: '',
  UnrevealAsideLeft: '',
  RevealAsideRight: '',
  UnrevealAsideRight: '',
  ProjectCreated: '',
  ProjectUpdated: '',
  ShowAppModal: '',
  CloseAppModal: '',
  ShowAppLoading: '',
  HideAppLoading: '',
  ShowAddDataExportTask: '',
  ShowAddBatchJob: '',
});
