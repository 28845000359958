/**
 * @file: ReceptionOrderStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ReceptionOrderStatus } from 'model';

export const ReceptionOrderStatusOptions: Array<Option<ReceptionOrderStatus>> =
  [
    {
      value: ReceptionOrderStatus.Pending,
      label: 'reception_order_status.pending',
    },
    {
      value: ReceptionOrderStatus.InProgress,
      label: 'reception_order_status.inprogress',
    },
    {
      value: ReceptionOrderStatus.Cancelled,
      label: 'reception_order_status.cancelled',
    },
    {
      value: ReceptionOrderStatus.Finished,
      label: 'reception_order_status.finished',
    },
  ];

export const ReceptionOrderStatusOptionsWithDefault: Array<
  Option<ReceptionOrderStatus | null>
> = [
  { value: null, label: 'reception_order_status.__default__' },
  {
    value: ReceptionOrderStatus.Pending,
    label: 'reception_order_status.pending',
  },
  {
    value: ReceptionOrderStatus.InProgress,
    label: 'reception_order_status.inprogress',
  },
  {
    value: ReceptionOrderStatus.Cancelled,
    label: 'reception_order_status.cancelled',
  },
  {
    value: ReceptionOrderStatus.Finished,
    label: 'reception_order_status.finished',
  },
];
