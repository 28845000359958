/**
 * @file: formatTime.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import moment from 'moment';

export function formatTime(
  time?: string | Date | number,
  format = 'YYYY-MM-DD HH:mm:ss',
): string {
  if (!time) return '';
  return moment(time).format(format);
}

export function formatDate(
  time?: string | Date | number,
  format = 'YYYY-MM-DD',
): string {
  if (!time) return '';
  return moment(time).format(format);
}
