import { MainNavStatus } from 'app';
import {
  AppLoadingState,
  AppModalState,
  AppState,
  UIState,
} from 'app/duck/states';
import { History } from 'history';
import { RouteViewProps, storageService } from 'lib';
import { DispatchFn, MapStateToPropsFn } from 'lib/duck/interfaces';
import { initWebsocket } from 'lib/websocket';
import { AdminUser, Identity } from 'model';
import React, { Component } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ThunkDispatch } from 'redux-thunk';
import {
  AppModal,
  authenticate,
  Footer,
  Header,
  MainNav,
  Media,
} from 'shared/components';
import { CustomerList } from './customers/org-customers';
import { OrgMemberList } from './customers/org-members';
import { OrgUserList } from './customers/org-users';
import { OrganizationList } from './customers/organizations';
import { StoreList } from './customers/stores';
import { Dashboard } from './dashboard/Dashboard';
import { InspectionSiteCategoryList } from './inspection/categories';
import { InspectionSubjectList } from './inspection/inspection-subjects';
import { InspectionSitesInventory } from './inspection/inventory';
import { InventorySummary } from './inspection/inventory/Summary';
import { InventoryManager } from './inspection/inventory/manager';
import { ServiceSubjectList } from './inspection/service-subjects';
import { InspectionTemplateDetailManager } from './inspection/templates/detail';
import { InspectionTemplateManager } from './inspection/templates/manager';
import { InspectionToolList } from './inspection/tools';
import { VehicleReceptionOrderList } from './orders';
import { VehicleInspectionReportList } from './reports';
import { AdminUserList } from './settings/admin-user';
import { AutoPartNameList } from './settings/auto-part-name';
import { QuotationPartCatalogList } from './settings/quotation-part-catalog';
import { VehicleInspectionTaskList } from './tasks';
import { VehicleInfoList } from './vehicles';

import { SysBatchJobList } from 'app/batch-jobs';
import { AgentList } from 'app/customers/agents';
import { ProductAgentList } from 'app/customers/product-agents';
import { DataExportTaskList } from 'app/data-export';
import { ConstructionTemplateConfigManager } from 'app/inspection/construction-template-config';
import { MaintenanceServiceSubjectList } from 'app/inspection/maintenance-service-subjects';
import { QuotationTemplateConfigManager } from 'app/inspection/quotation-template-config/Manager';
import { VehicleSystemModuleList } from 'app/inspection/vehicle-system-modules';
import { ProductBrandList } from 'app/settings/product-brands';
import { ServiceEditionList } from 'app/settings/service-edition';
import { SystemConfigurationPage } from 'app/settings/system-config';
import {
  closeAppModal,
  closeMainNavInSmallScreen,
  fetchAreas,
  fetchUserInfo,
  openMainNavInSmallScreen,
  revealAsideLeft,
  toggleMinimize,
  unrevealAsideLeft,
} from './duck/actions';
import { DeliveryCheckTemplateDetailManager } from './inspection/delivery-check-templates/detail';
import { DeliveryCheckTemplateManager } from './inspection/delivery-check-templates/list';
import { OpenApiAppList } from './openapi/apps';
import { OpenApiUserList } from './openapi/users';

const enableWebSocket = true;

interface Props extends LocalizeContextProps {
  dispatch: DispatchFn<AppState>;
  history: History;
  identity?: Identity;
  currentUser: AdminUser | null;
  activeShopId: number | null;
  uiState: UIState;
  appModal: AppModalState;
  appLoading: AppLoadingState;
  onLoad: () => void;
  onToggleMinimize: (status?: MainNavStatus) => void;
  onRevealAsideLeft: () => void;
  onUnrevealAsideLeft: () => void;
  // new (props: Props, context?: any): ComponentClass<Props>;
}

const mapStateToProps: MapStateToPropsFn<AppState, Partial<Props>> = (
  state: AppState,
  ownProps: RouteViewProps,
) => {
  return {
    currentUser: state.user.result,
    history: ownProps.history,
    uiState: state.ui,
    appModal: state.modal,
    appLoading: state.loading,
  };
};

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return {
    dispatch,
    onLoad: () => {
      dispatch((_: any, getState: () => AppState) => {
        const state = getState() as AppState;
        if (!state.user.result && !state.user.isLoading) {
          dispatch(fetchUserInfo());
        }
        if (!state.areas.result && !state.areas.isLoading) {
          dispatch(fetchAreas());
        }
      });
    },
    onToggleMinimize: (status?: MainNavStatus) => {
      dispatch(toggleMinimize(status));
    },
    onRevealAsideLeft: () => {
      dispatch(revealAsideLeft());
    },
    onUnrevealAsideLeft: () => {
      dispatch(unrevealAsideLeft());
    },
  };
}

export class MainComponent extends Component<Props, any, any> {
  private readonly mainNavElRef = React.createRef<HTMLDivElement>();
  private readonly asideLeftOverlayElRef = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.uiState.mainNavStatus !== this.props.uiState.mainNavStatus ||
      prevProps.uiState.asideLeftHover !== this.props.uiState.asideLeftHover ||
      prevProps.uiState.mainNavOpenInSmallScreen !==
        this.props.uiState.mainNavOpenInSmallScreen
    ) {
      this.updateBodyClass();
    }

    if (
      prevProps.uiState.mainNavOpenInSmallScreen !==
      this.props.uiState.mainNavOpenInSmallScreen
    ) {
      if (this.props.uiState.mainNavOpenInSmallScreen) {
        if (this.asideLeftOverlayElRef.current) {
          this.asideLeftOverlayElRef.current.addEventListener(
            'click',
            this.onAsideLeftOverlayClick,
          );
        }
      } else {
        if (this.asideLeftOverlayElRef.current) {
          this.asideLeftOverlayElRef.current.removeEventListener(
            'click',
            this.onAsideLeftOverlayClick,
          );
        }
      }
    }

    if (prevProps.appModal.isOpen && !this.props.appModal.isOpen) {
      this.props.appModal.onClose && this.props.appModal.onClose();
    }
  }

  componentDidMount() {
    this.props.onLoad && this.props.onLoad();
    this.updateBodyClass();
    enableWebSocket && initWebsocket();
  }

  updateBodyClass() {
    const { mainNavStatus, asideLeftHover, mainNavOpenInSmallScreen } =
      this.props.uiState;

    if (mainNavStatus === 'minimized') {
      document.body.classList.add(
        'm-brand--minimize',
        'm-aside-left--minimize',
      );
    } else if (mainNavStatus === 'open') {
      document.body.classList.remove(
        'm-brand--minimize',
        'm-aside-left--minimize',
      );
    }

    if (mainNavStatus === 'minimized' && !mainNavOpenInSmallScreen) {
      if (asideLeftHover) {
        document.body.classList.remove('m-aside-left--minimize');
        document.body.classList.add('m-aside-left--minimize-hover');
      } else {
        document.body.classList.remove('m-aside-left--minimize-hover');
        document.body.classList.add('m-aside-left--minimize');
      }
    }

    if (mainNavOpenInSmallScreen) {
      if (this.mainNavElRef.current) {
        this.mainNavElRef.current.classList.add('m-aside-left--on');
      }
    } else {
      if (this.mainNavElRef.current) {
        this.mainNavElRef.current.classList.remove('m-aside-left--on');
      }
    }
  }

  render() {
    const { appModal } = this.props;

    return (
      <React.Fragment>
        <ReactTooltip id="global" effect="solid" type="dark" place="top" />
        <Header
          currentUser={this.props.currentUser}
          mainNavStatus={this.props.uiState.mainNavStatus}
          mainNavOpenInSmallScreen={this.props.uiState.mainNavOpenInSmallScreen}
          showLoading={this.props.appLoading.show}
          loadingMessage={this.props.appLoading.message}
          loadingStatus={this.props.appLoading.status}
          onToggleMinimize={this.onToggleMinimize}
          onOpenMainNavInSmallScreen={this.onOpenMainNavInSmallScreen}
          onLanguageChanged={this.onLanguageChange}
        />
        <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
          <div
            ref={this.mainNavElRef}
            className="m-grid__item m-aside-left m-aside-left--skin-dark"
            onMouseEnter={this.onSideLeftMouseEnter}
            onMouseLeave={this.onSideLeftMouseLeave}
          >
            <MainNav showBrand={this.props.uiState.mainNavOpenInSmallScreen} />
            <Media
              query="(min-width: 1024px)"
              onChange={this.onScreenWidthChange}
            />
          </div>
          {this.props.uiState.mainNavOpenInSmallScreen && (
            <div
              className="m-aside-left-overlay"
              ref={this.asideLeftOverlayElRef}
            />
          )}
          <div className="m-grid__item m-grid__item--fluid m-wrapper">
            <Switch>
              <Route path="/business-customers/agents" component={AgentList} />
              <Route
                path="/business-customers/product-agents"
                component={ProductAgentList}
              />
              <Route
                path="/business-customers/organizations"
                component={OrganizationList}
              />
              <Route path="/business-customers/stores" component={StoreList} />
              <Route path="/business-customers/users" component={OrgUserList} />
              <Route
                path="/business-customers/members"
                component={OrgMemberList}
              />
              <Route
                path="/business-customers/customers"
                component={CustomerList}
              />
              <Route
                path="/inspection/categories"
                component={InspectionSiteCategoryList}
              />
              <Route
                path="/inspection/inventory/manager"
                component={InventoryManager}
              />
              <Route
                path="/inspection/inventory"
                component={InspectionSitesInventory}
              />
              <Route path="/inspection/tools" component={InspectionToolList} />
              <Route path="/inspection/summary" component={InventorySummary} />
              <Route
                path="/inspection/templates/manager"
                component={InspectionTemplateManager}
              />
              <Route
                path="/inspection/templates/detail"
                component={InspectionTemplateDetailManager}
              />
              <Route
                path="/inspection/service-subjects"
                component={ServiceSubjectList}
              />
              <Route
                path="/inspection/inspection-subjects"
                component={InspectionSubjectList}
              />
              <Route
                path="/inspection/delivery-check-templates/list"
                component={DeliveryCheckTemplateManager}
              />
              <Route
                path="/inspection/delivery-check-templates/detail"
                component={DeliveryCheckTemplateDetailManager}
              />
              <Route
                path="/inspection/maintenance-service-subjects"
                component={MaintenanceServiceSubjectList}
              />
              <Route
                path="/inspection/vehicle-system-modules"
                component={VehicleSystemModuleList}
              />
              <Route
                path="/inspection/construction-template-config"
                component={ConstructionTemplateConfigManager}
              />
              <Route
                path="/inspection/quotation-template-config"
                component={QuotationTemplateConfigManager}
              />
              <Route path="/settings/admin-users" component={AdminUserList} />
              <Route
                path="/settings/auto-part-names"
                component={AutoPartNameList}
              />
              <Route
                path="/settings/quotation-part-catalogs"
                component={QuotationPartCatalogList}
              />
              <Route
                path="/settings/configurations"
                component={SystemConfigurationPage}
              />
              <Route
                path="/settings/service-editions"
                component={ServiceEditionList}
              />
              <Route
                path="/settings/product-brands"
                component={ProductBrandList}
              />
              <Route
                path="/inspections/orders"
                component={VehicleReceptionOrderList}
              />
              <Route
                path="/inspections/tasks"
                component={VehicleInspectionTaskList}
              />
              <Route
                path="/inspections/reports"
                component={VehicleInspectionReportList}
              />
              <Route path="/data-export" component={DataExportTaskList} />
              <Route path="/batch-jobs" component={SysBatchJobList} />
              <Route path="/vehicles/list" component={VehicleInfoList} />
              <Route path="/openapi/users" component={OpenApiUserList} />
              <Route path="/openapi/apps" component={OpenApiAppList} />
              <Route path="/" component={Dashboard} />
              <Route path="" component={Dashboard} />
              <Route render={this.renderNotFound} />
            </Switch>
          </div>
        </div>
        <Footer />
        <AppModal
          isOpen={appModal.isOpen}
          title={appModal.title || ''}
          message={appModal.message || ''}
          onClose={this.onCloseAppModal}
        />
      </React.Fragment>
    );
  }

  private readonly renderNotFound = () => {
    return <Redirect to="/404" />;
  };

  private readonly onCloseAppModal = () => {
    const { dispatch } = this.props;
    dispatch(closeAppModal());
  };

  private readonly onToggleMinimize = (status?: MainNavStatus) => {
    this.props.onToggleMinimize(status);
  };

  private readonly onSideLeftMouseEnter = () => {
    if (this.props.uiState.mainNavStatus === 'minimized') {
      this.props.onRevealAsideLeft();
    }
  };

  private readonly onSideLeftMouseLeave = () => {
    if (this.props.uiState.mainNavStatus === 'minimized') {
      this.props.onUnrevealAsideLeft();
    }
  };

  private readonly onLanguageChange = (lang: string) => {
    storageService.ls_set('lang', lang);
    location.reload();
  };

  private readonly onOpenMainNavInSmallScreen = () => {
    this.props.dispatch(openMainNavInSmallScreen());
  };

  private readonly onAsideLeftOverlayClick = (e: MouseEvent) => {
    e.preventDefault();
    this.props.dispatch(closeMainNavInSmallScreen());
  };

  private readonly onScreenWidthChange = (matches: boolean) => {
    if (matches) {
      this.props.dispatch(closeMainNavInSmallScreen());
    }
  };
}

export const Main = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, MainComponent>(withLocalize<Props>(MainComponent) as any),
);
