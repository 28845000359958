/**
 * @file: UserVehicleBindingService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  UserVehicleBinding,
  UserVehicleBindingListFilter,
} from 'model';

export class UserVehicleBindingServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<UserVehicleBindingListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<UserVehicleBinding>
      : UserVehicleBinding[]
  > {
    return await this.api
      .get()
      .url(
        '/user-vehicle-bindings',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<UserVehicleBinding | null> {
    return await this.api
      .get()
      .url('/user-vehicle-bindings/:id', { id })
      .future();
  }

  async create(
    userVehicleBinding: Partial<UserVehicleBinding>,
  ): Promise<UserVehicleBinding> {
    return await this.api
      .post()
      .url('/user-vehicle-bindings')
      .data(userVehicleBinding)
      .future();
  }

  async update(
    userVehicleBinding: Partial<UserVehicleBinding>,
  ): Promise<UserVehicleBinding> {
    return await this.api
      .put()
      .url('/user-vehicle-bindings/:id', { id: userVehicleBinding.id })
      .data(userVehicleBinding)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/user-vehicle-bindings/:id', { id })
      .future();
  }
}
