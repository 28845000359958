/**
 * @file: VehicleSubscriptionDetailService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleSubscriptionDetailServiceBase } from './base/VehicleSubscriptionDetailService';

export class VehicleSubscriptionDetailService extends VehicleSubscriptionDetailServiceBase {
  // add custom service implementation here
}
