import React, { HTMLProps } from 'react';
import classNames from 'classnames';

interface Props extends HTMLProps<HTMLLIElement> {
  text?: string;
}

export const NavSection = (props: Props) => {
  const { className, text, ...otherProps } = props;
  return (
    <li className={classNames('m-nav__section', className)} {...otherProps}>
      <span className="m-nav__section-text">{text}</span>
    </li>
    );
};