/**
 * @file: InspectionTaskJobStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionTaskJobStatus, Option } from 'model';

export const InspectionTaskJobStatusOptions: Array<
  Option<InspectionTaskJobStatus>
> = [
  {
    value: InspectionTaskJobStatus.Pending,
    label: 'inspection_task_job_status.pending',
  },
  {
    value: InspectionTaskJobStatus.InProgress,
    label: 'inspection_task_job_status.in_progress',
  },
  {
    value: InspectionTaskJobStatus.Finished,
    label: 'inspection_task_job_status.finished',
  },
];

export const InspectionTaskJobStatusOptionsWithDefault: Array<
  Option<InspectionTaskJobStatus | null>
> = [
  { value: null, label: 'inspection_task_job_status.__default__' },
  {
    value: InspectionTaskJobStatus.Pending,
    label: 'inspection_task_job_status.pending',
  },
  {
    value: InspectionTaskJobStatus.InProgress,
    label: 'inspection_task_job_status.in_progress',
  },
  {
    value: InspectionTaskJobStatus.Finished,
    label: 'inspection_task_job_status.finished',
  },
];
