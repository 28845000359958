/**
 * @file: InspectionSiteRelTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionSiteRelType, Option } from 'model';

export const InspectionSiteRelTypeOptions: Array<
  Option<InspectionSiteRelType>
> = [
  {
    value: InspectionSiteRelType.Default,
    label: 'inspection_site_rel_type.default',
  },
  {
    value: InspectionSiteRelType.Structural,
    label: 'inspection_site_rel_type.structural',
  },
  {
    value: InspectionSiteRelType.Spatial,
    label: 'inspection_site_rel_type.spatial',
  },
  {
    value: InspectionSiteRelType.Workflow,
    label: 'inspection_site_rel_type.workflow',
  },
];

export const InspectionSiteRelTypeOptionsWithDefault: Array<
  Option<InspectionSiteRelType | null>
> = [
  { value: null, label: 'inspection_site_rel_type.__default__' },
  {
    value: InspectionSiteRelType.Default,
    label: 'inspection_site_rel_type.default',
  },
  {
    value: InspectionSiteRelType.Structural,
    label: 'inspection_site_rel_type.structural',
  },
  {
    value: InspectionSiteRelType.Spatial,
    label: 'inspection_site_rel_type.spatial',
  },
  {
    value: InspectionSiteRelType.Workflow,
    label: 'inspection_site_rel_type.workflow',
  },
];
