/**
 * @file: stores.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { Store } from 'model';
import { Stores } from '../states';
import { ActionTypes } from '../types';

const initialState: Stores = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<Store, Stores>(
  'customers.stores',
  initialState,
  {
    mapItemKey: x => x.id,
  },
);

export default function storesReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Stores = initialState,
  action: StandardAction<any>,
): Stores {
  switch (action.type) {
    case ActionTypes.DownloadQrcodeForStore: {
      const store = action.payload as Store | null | undefined;
      return { ...state, activeStoreForQrcodeDownloader: store };
    }
    case ActionTypes.RenewStore: {
      const store = action.payload as Store | null | undefined;
      return { ...state, activeStoreForRenew: store };
    }
    default:
      return defaultReducer(state, action);
  }
}
