import { quotationTplActions } from 'app/inspection/duck/actions';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmModal } from '../../../shared/components/ConfirmModal';
import { useQuotationTemplateConfigContext } from '../Context';

export const ConfirmRemoveSubjectModal = memo(() => {
  const { state } = useQuotationTemplateConfigContext();

  const dispatch = useDispatch();

  const onConfirmRemove = useCallback(() => {
    dispatch(quotationTplActions.commitRemoveSubject());
  }, [dispatch]);

  const onCancelRemove = useCallback(() => {
    dispatch(quotationTplActions.cancelRemoveSubject());
  }, [dispatch]);

  return (
    <ConfirmModal
      open={state.subjectRefBeingRemoved != null}
      title="quotation_tpl.subject.modal.confirm_remove.title"
      content="quotation_tpl.subject.modal.confirm_remove.msg"
      onConfirm={onConfirmRemove}
      onCancel={onCancelRemove}
    />
  );
});
