/**
 * @file: VehicleInspectionSiteCheckItemService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionSiteCheckItem,
  VehicleInspectionSiteCheckItemListFilter,
  VehicleInspectionSiteCheckItemMedia,
  VehicleInspectionSiteCheckItemOption,
} from 'model';

export class VehicleInspectionSiteCheckItemServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionSiteCheckItemListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionSiteCheckItem>
      : VehicleInspectionSiteCheckItem[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-site-check-items',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionSiteCheckItem | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-site-check-items/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionSiteCheckItem: Partial<VehicleInspectionSiteCheckItem>,
  ): Promise<VehicleInspectionSiteCheckItem> {
    return await this.api
      .post()
      .url('/vehicle-inspection-site-check-items')
      .data(vehicleInspectionSiteCheckItem)
      .future();
  }

  async update(
    vehicleInspectionSiteCheckItem: Partial<VehicleInspectionSiteCheckItem>,
  ): Promise<VehicleInspectionSiteCheckItem> {
    return await this.api
      .put()
      .url('/vehicle-inspection-site-check-items/:id', {
        id: vehicleInspectionSiteCheckItem.id,
      })
      .data(vehicleInspectionSiteCheckItem)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-site-check-items/:id', { id })
      .future();
  }

  async getMediasByItemId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionSiteCheckItemMedia[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-site-check-items/:id/medias', { id, recursive })
      .future();
  }

  async createVehicleInspectionSiteCheckItemMedia(
    media: Partial<VehicleInspectionSiteCheckItemMedia>,
  ): Promise<VehicleInspectionSiteCheckItemMedia> {
    const id = media.itemId;
    if (!id) {
      throw new Error('media.itemId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-inspection-site-check-items/:id/medias')
      .data(media)
      .future();
  }

  async updateVehicleInspectionSiteCheckItemMedia(
    media: VehicleInspectionSiteCheckItemMedia,
  ): Promise<VehicleInspectionSiteCheckItemMedia> {
    const id = media.itemId;
    if (!id) {
      throw new Error('media.itemId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-inspection-site-check-items/:id/medias')
      .data(media)
      .future();
  }

  async deleteVehicleInspectionSiteCheckItemMedia(
    itemId: number | VehicleInspectionSiteCheckItemMedia,
    mediaId?: number,
  ): Promise<void> {
    if (itemId && typeof itemId === 'object') {
      mediaId = itemId.id;
      if (!itemId.itemId) {
        throw new Error('itemId cannot be null or undefined');
      }
      itemId = itemId.itemId;
    }
    if (!itemId || !mediaId) {
      throw new Error('itemId and mediaId are both required');
    }
    return void await this.api
      .delete()
      .url('/vehicle-inspection-site-check-items/:itemId/medias/:mediaId', {
        itemId,
        mediaId,
      })
      .future();
  }

  async getOptionsByItemId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionSiteCheckItemOption[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-site-check-items/:id/options', {
        id,
        recursive,
      })
      .future();
  }

  async createVehicleInspectionSiteCheckItemOption(
    option: Partial<VehicleInspectionSiteCheckItemOption>,
  ): Promise<VehicleInspectionSiteCheckItemOption> {
    const id = option.itemId;
    if (!id) {
      throw new Error('option.itemId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-inspection-site-check-items/:id/options')
      .data(option)
      .future();
  }

  async updateVehicleInspectionSiteCheckItemOption(
    option: VehicleInspectionSiteCheckItemOption,
  ): Promise<VehicleInspectionSiteCheckItemOption> {
    const id = option.itemId;
    if (!id) {
      throw new Error('option.itemId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-inspection-site-check-items/:id/options')
      .data(option)
      .future();
  }

  async deleteVehicleInspectionSiteCheckItemOption(
    itemId: number | VehicleInspectionSiteCheckItemOption,
    optionId?: number,
  ): Promise<void> {
    if (itemId && typeof itemId === 'object') {
      optionId = itemId.id;
      if (!itemId.itemId) {
        throw new Error('itemId cannot be null or undefined');
      }
      itemId = itemId.itemId;
    }
    if (!itemId || !optionId) {
      throw new Error('itemId and optionId are both required');
    }
    return void await this.api
      .delete()
      .url('/vehicle-inspection-site-check-items/:itemId/options/:optionId', {
        itemId,
        optionId,
      })
      .future();
  }
}
