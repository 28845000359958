import React, { Component } from 'react';

import { MenuItem } from './MenuItem';
import { MenuSection } from './MenuSection';

interface Props {
  children?: any;
}

export class Menu extends Component<Props> {
  static Item = MenuItem;
  static Section = MenuSection;

  render() {
    return (
      <div className="m-menu__nav  m-menu__nav--submenu-arrow">
        {this.props.children}
      </div>
    );
  }
}