import React, { Component, CSSProperties } from 'react';
import {
  withLocalize,
  LocalizeContextProps,
  Translate,
} from 'react-localize-redux';
import { Column, DataTable } from 'shared/metronic/components';
import { RecentStores } from './duck/states';
import { Store } from 'model';
import { formatTime } from 'utils';
import { Areas } from 'app/duck/states';
import { OrgInfoView, StringLabel } from 'shared/components';

interface Props extends LocalizeContextProps {
  stores: RecentStores;
  areas: Areas;
}

export class RecentStoreListComponent extends Component<Props> {
  private columns: Array<Column<Store>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { stores } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="id"
        selModel="none"
        data={stores.result}
        isLoading={stores.isLoading}
        minHeight={0}
        error={stores.error}
      />
    );
  }

  private init() {
    this.columns = [
      {
        prop: 'logoImgUrl',
        width: 200,
        text: 'store.col.store',
        render: ({ orgId }) => (
          <OrgInfoView orgId={orgId}>
            {org => (
              <img
                className="org-info__logo"
                style={{ width: 200 }}
                src={org?.logoImgUrl || '/img/org-placeholder.png'}
              />
            )}
          </OrgInfoView>
        ),
      },
      {
        prop: 'name',
        width: 200,
        text: 'col.name',
        render: store => {
          return (
            <div className="org-info__detail">
              <p>{store.name}</p>
              <OrgInfoView orgId={store.orgId}>
                {org => (
                  <>
                    <dl>
                      <dt>
                        <Translate id="store.org_info.label.org_name" />
                      </dt>
                      <dd>
                        {org?.name} ({org?.shortName})
                      </dd>
                    </dl>
                    <dl>
                      <dt>
                        <Translate id="store.org_info.label.brand_name" />
                      </dt>
                      <dd>{org?.brandName}</dd>
                    </dl>
                  </>
                )}
              </OrgInfoView>
            </div>
          );
        },
      },
      {
        prop: 'address',
        width: 200,
        text: 'col.address',
        render: entity => {
          const props = this.props;
          if (!entity.provinceId) return '-';
          if (!props.areas?.result || props.areas.isLoading) {
            return '...';
          }
          const province = props.areas.result.getNodeById(entity.provinceId);
          const city = props.areas.result.getNodeById(entity.cityId!);
          const district = props.areas.result.getNodeById(entity.districtId!);
          const parts: string[] = [];
          if (province.children.length > 1) {
            parts.push(province.area.name);
          }
          parts.push(city.area.name, district.area.name);
          return (
            <div>
              <p style={{ marginBottom: 4 }}>{parts.join('/')}</p>
              <p>{entity.address}</p>
            </div>
          );
        },
      },
      {
        prop: 'contact',
        width: 200,
        text: 'col.contact_name',
        render: entity => {
          const style: CSSProperties = {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          };
          return (
            <div>
              {entity.contactName && (
                <div style={style}>
                  <StringLabel value="contact_label.name" />
                  {entity.contactName}
                </div>
              )}
              {entity.contactPhone && (
                <div style={style}>
                  <StringLabel value="contact_label.phone" />
                  {entity.contactPhone}
                </div>
              )}
              {entity.contactMobile && (
                <div style={style}>
                  <StringLabel value="contact_label.mobile" />
                  {entity.contactMobile}
                </div>
              )}
              {entity.contactFax && (
                <div style={style}>
                  <StringLabel value="contact_label.fax" />
                  {entity.contactFax}
                </div>
              )}
              {entity.contactEmail && (
                <div style={style}>
                  <StringLabel value="contact_label.email" />
                  {entity.contactEmail}
                </div>
              )}
            </div>
          );
        },
      },
      {
        prop: 'createdAt',
        text: 'col.created_at',
        width: 150,
        align: 'center',
        render: ({ createdAt }) => formatTime(createdAt),
      },
    ];
  }
}

export const RecentStoreList = withLocalize(RecentStoreListComponent);
