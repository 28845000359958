import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleReport } from 'model';
import { reportService } from 'services';

const actionCreators = createListAsyncActionCreators<AppState, VehicleReport>(
  'dashboard.recent_inspection_reports',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      return await reportService.searchReports(
        state.dashboard.recentInspectionReports.filter || {},
        state.dashboard.recentInspectionReports.sorts && state.dashboard.recentInspectionReports.sorts[0] as any,
        null,
        state.dashboard.recentInspectionReports.offset || 0,
        state.dashboard.recentInspectionReports.limit || 10
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentInspectionReports,
  invalidate: invalidateDashboardRecentInspectionReports
} = actionCreators;