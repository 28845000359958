/**
 * @file: template-detail.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionTemplateConf } from 'model/viewmodel/InspectionTemplateConf';
import { ActionThunk, StandardAction } from 'lib/duck/interfaces';
import { createStandardAction } from 'lib/duck/actions';
import { ActionTypes } from '../types';
import { AppState } from 'app/duck/states';
import { VehicleInspectionTemplate } from 'model';
import { vehicleInspectionTemplateService } from 'services';
import { inspectionTemplateActions } from './templates';
import { showAppLoading } from 'app/duck/actions';
import { InspectionTemplateDetailSiteListSortType } from '../states';

export class TemplateDetailActionCreators {
  ready(templateId: number, conf: InspectionTemplateConf) {
    return createStandardAction(ActionTypes.TemplateDetailReady, {
      templateId,
      conf,
    });
  }

  update(conf: InspectionTemplateConf): StandardAction<InspectionTemplateConf> {
    return createStandardAction(ActionTypes.TemplateDetailUpdated, conf);
  }

  addCategory(): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailAddCategory);
  }

  editCategory(id: string): StandardAction<string> {
    return createStandardAction(ActionTypes.TemplateDetailEditCategory, id);
  }

  editCategoryChanged(name: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.TemplateDetailEditCategoryChanged,
      name,
    );
  }

  editCategoryCommitted(name?: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.TemplateDetailEditCategoryCommitted,
      name,
    );
  }

  editCategoryCancelled(): StandardAction<any> {
    return createStandardAction(
      ActionTypes.TemplateDetailEditCategoryCancelled,
    );
  }

  removeCategory(id: string): StandardAction<string> {
    return createStandardAction(ActionTypes.TemplateDetailRemoveCategory, id);
  }

  commitRemoveCategory(): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailCommitRemoveCategory);
  }

  cancelRemoveCategory(): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailCancelRemoveCategory);
  }

  expandCategory(id: string): StandardAction<string> {
    return createStandardAction(ActionTypes.TemplateDetailExpandCategory, id);
  }

  collapseCategory(id: string): StandardAction<string> {
    return createStandardAction(ActionTypes.TemplateDetailCollapseCategory, id);
  }

  categoryMoved(id: string, from: number, to: number): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailCategoryMoved, {
      id,
      from,
      to,
    });
  }

  addGroup(categoryId: string): StandardAction<string> {
    return createStandardAction(ActionTypes.TemplateDetailAddGroup, categoryId);
  }

  editGroup(id: string, name: string): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailEditGroup, {
      id,
      name,
    });
  }

  editGroupChanged(name: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.TemplateDetailEditGroupChanged,
      name,
    );
  }

  editGroupCommitted(name?: string): StandardAction<any> {
    return createStandardAction(
      ActionTypes.TemplateDetailEditGroupCommitted,
      name,
    );
  }

  editGroupCancelled(): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailEditGroupCancelled);
  }

  removeGroup(groupId: string): StandardAction<string> {
    return createStandardAction(ActionTypes.TemplateDetailRemoveGroup, groupId);
  }

  commitRemoveGroup(): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailCommitRemoveGroup);
  }

  cancelRemoveGroup(): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailCancelRemoveGroup);
  }

  groupMoved(id: string, from: number, to: number): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailGroupMoved, {
      id,
      from,
      to,
    });
  }

  groupSelected(id: string): StandardAction<string> {
    return createStandardAction(ActionTypes.TemplateDetailGroupSelected, id);
  }

  allGroupsSelected(categoryId: string): StandardAction<string> {
    return createStandardAction(
      ActionTypes.TemplateDetailAllGroupsSelected,
      categoryId,
    );
  }

  addSite(groupId: string, siteId: number) {
    return createStandardAction(ActionTypes.TemplateDetailAddSite, {
      groupId,
      siteId,
    });
  }

  removeSite(groupId: string, siteId: number) {
    return createStandardAction(ActionTypes.TemplateDetailRemoveSite, {
      groupId,
      siteId,
    });
  }

  addSites(groupId: string, siteIds: number[]) {
    return createStandardAction(ActionTypes.TemplateDetailAddSites, {
      groupId,
      siteIds,
    });
  }

  removeSites(groupId: string, siteIds: number[], confirm?: boolean) {
    return createStandardAction(ActionTypes.TemplateDetailRemoveSites, {
      groupId,
      siteIds,
      confirm,
    });
  }

  commitSitesBeingRemoved() {
    return createStandardAction(ActionTypes.TemplateDetailRemoveSitesCommitted);
  }

  cancelSitesBeingRemoved() {
    return createStandardAction(ActionTypes.TemplateDetailRemoveSitesCancelled);
  }

  siteSelected(siteId: number) {
    return createStandardAction(ActionTypes.TemplateDetailSiteSelected, siteId);
  }

  siteDeselected(siteId: number) {
    return createStandardAction(
      ActionTypes.TemplateDetailSiteDeselected,
      siteId,
    );
  }

  toggleSelectAllSites() {
    return createStandardAction(ActionTypes.TemplateDetailToggleSelectAll);
  }

  siteMoved(
    groupId: string,
    from: number,
    to: number,
    siteId?: number,
  ): StandardAction<any> {
    return createStandardAction(ActionTypes.TemplateDetailSiteMoved, {
      groupId,
      siteId,
      from,
      to,
    });
  }

  showSiteList() {
    return createStandardAction(ActionTypes.TemplateDetailShowSiteList);
  }

  hideSiteList() {
    return createStandardAction(ActionTypes.TemplateDetailHideSiteList);
  }

  showPreview() {
    return createStandardAction(ActionTypes.TemplateDetailShowPreview);
  }

  hidePreview() {
    return createStandardAction(ActionTypes.TemplateDetailHidePreview);
  }

  enableSite(groupId: string, siteId: number) {
    return createStandardAction(ActionTypes.TemplateDetailSiteEnabled, {
      groupId,
      siteId,
    });
  }

  disableSite(groupId: string, siteId: number) {
    return createStandardAction(ActionTypes.TemplateDetailSiteDisabled, {
      groupId,
      siteId,
    });
  }

  setSiteDefaultHidden(
    groupId: string,
    siteId: number,
    isDefaultHidden: boolean,
  ) {
    return createStandardAction(
      ActionTypes.TemplateDetailSiteDefaultHiddenSet,
      { groupId, siteId, isDefaultHidden },
    );
  }

  setSiteAsRequired(groupId: string, siteId: number, required: boolean) {
    return createStandardAction(ActionTypes.TemplateDetailSiteRequiredSet, {
      groupId,
      siteId,
      required,
    });
  }

  siteListKeywordChanged(keyword: string) {
    return createStandardAction(
      ActionTypes.TemplateDetailSiteListKeywordChange,
      keyword,
    );
  }

  editSiteRels(siteId: number) {
    return createStandardAction(ActionTypes.TemplateDetailEditSiteRels, siteId);
  }

  endEditSiteRels(siteId: number) {
    return createStandardAction(
      ActionTypes.TemplateDetailEndEditSiteRels,
      siteId,
    );
  }

  siteRelsChanged(siteId: number, rels: number[] | undefined) {
    return createStandardAction(ActionTypes.TemplateDetailSiteRelsChanged, {
      siteId,
      rels,
    });
  }

  removeSiteRels(siteId: number, rels: number[]) {
    return createStandardAction(ActionTypes.TemplateDetailRemoveSiteRels, {
      siteId,
      rels,
    });
  }

  siteRelMoved(siteId: number, from: number, to: number) {
    return createStandardAction(ActionTypes.TemplateDetailSiteRelMoved, {
      siteId,
      from,
      to,
    });
  }

  save(
    template: VehicleInspectionTemplate,
    conf: InspectionTemplateConf,
    siteCount: number,
    itemCount: number,
  ): ActionThunk<AppState> {
    return dispatch => {
      const copy = JSON.parse(JSON.stringify(conf));

      for (const category of conf.categories) {
        category.expanded = undefined;
        category.keyword = undefined;
        for (const group of category.groups) {
          group.selection = undefined;
          group.keyword = undefined;
        }
      }
      const currentTemplate = {
        ...template,
        siteCount,
        itemCount,
        conf: JSON.stringify(conf),
      };
      dispatch(
        showAppLoading({ message: 'inspection_template.detail.saving' }),
      );
      dispatch(createStandardAction(ActionTypes.TemplateDetailSave));
      vehicleInspectionTemplateService
        .update(currentTemplate)
        .then(res => {
          dispatch(
            createStandardAction(ActionTypes.TemplateDetailSaveSuccess, copy),
          );
          dispatch(inspectionTemplateActions.updateSuccess(template, res));
        })
        .catch(err => {
          dispatch(
            createStandardAction(ActionTypes.TemplateDetailSaveFailed, err),
          );
        });
    };
  }

  siteListSortTypeChanged(sortType: InspectionTemplateDetailSiteListSortType) {
    return createStandardAction(
      ActionTypes.TemplateDetailSiteListSortTypeChanged,
      sortType,
    );
  }
}

export const templateDetailActions = new TemplateDetailActionCreators();
