/**
 * @file: categories.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import {
  VehicleInspectionSiteCategory,
  VehicleInspectionSiteCategoryListFilter,
} from 'model';
import { vehicleInspectionSiteCategoryService } from 'services';

export const inspectionSiteCategoryActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionSiteCategory,
  VehicleInspectionSiteCategoryListFilter,
  number
>('inspection.categories', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.categories.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.categories.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.categories.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleInspectionSiteCategoryService.list(
      Object.assign({}, state.inspection.categories.filter, {
        orgId: null,
        storeId: null,
      }),
      state.inspection.categories.sorts?.[0],
      state.inspection.categories.offset,
      state.inspection.categories.limit,
      { count: true, recursive: true },
    );
  },
  async create(category: Partial<VehicleInspectionSiteCategory>) {
    return await vehicleInspectionSiteCategoryService.create(category);
  },
  async update(category: VehicleInspectionSiteCategory) {
    return await vehicleInspectionSiteCategoryService.update(category);
  },
  async delete(category: VehicleInspectionSiteCategory) {
    await vehicleInspectionSiteCategoryService.delete(category.id);
  },
  async deleteMulti(categories: VehicleInspectionSiteCategory[]) {
    if (categories.length === 1) {
      await vehicleInspectionSiteCategoryService.delete(categories[0].id);
      return;
    }
    throw new Error(
      'delete multi inspection site categories is not supported. ',
    );
  },
});
