import styled from 'styled-components';

export const Input = styled.input`
  margin-top: 1.5rem !important;
`;

export const ShopNickInput = styled.input`
  border: none !important;
  border-radius: 40px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 1.5rem 1.5rem !important;
  margin-top: 0 !important;
  background-color: #fff !important;
  z-index: 1 !important;
`;