/**
 * @file: QuotationPartCatalogService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { QuotationPartCatalogServiceBase } from './base/QuotationPartCatalogService';

export class QuotationPartCatalogService extends QuotationPartCatalogServiceBase {
  // add custom service implementation here
  async import(fileDataUri: string): Promise<number> {
    return await this.api
      .post()
      .url('/quotation-part-catalogs/import')
      .data({ data: fileDataUri })
      .future();
  }
}
