/**
 * @file: GeoLocation.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum CoordsType {
  GCJ02 = 'gcj02',
  WGS84 = 'wgs84',
  BD09 = 'bd09',
  BD09MC = 'bd09mc'
}

export interface GeoLocation {
  latitude: number;
  longitude: number;
}