/**
 * @file: OfficialAccountMenuItemTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OfficialAccountMenuItemType, Option } from 'model';

export const OfficialAccountMenuItemTypeOptions: Array<
  Option<OfficialAccountMenuItemType>
> = [
  {
    value: OfficialAccountMenuItemType.Click,
    label: 'official_account_menu_item_type.click',
  },
  {
    value: OfficialAccountMenuItemType.View,
    label: 'official_account_menu_item_type.view',
  },
  {
    value: OfficialAccountMenuItemType.ScanCodePush,
    label: 'official_account_menu_item_type.scancode_push',
  },
  {
    value: OfficialAccountMenuItemType.ScanCodeWaitMsg,
    label: 'official_account_menu_item_type.scancode_waitmsg',
  },
  {
    value: OfficialAccountMenuItemType.PicSysPhoto,
    label: 'official_account_menu_item_type.pic_sysphoto',
  },
  {
    value: OfficialAccountMenuItemType.PicPhotoOrAlbum,
    label: 'official_account_menu_item_type.pic_photo_or_album',
  },
  {
    value: OfficialAccountMenuItemType.PicWeixin,
    label: 'official_account_menu_item_type.pic_weixin',
  },
  {
    value: OfficialAccountMenuItemType.LocationSelect,
    label: 'official_account_menu_item_type.location_select',
  },
  {
    value: OfficialAccountMenuItemType.Miniprogram,
    label: 'official_account_menu_item_type.miniprogram',
  },
];

export const OfficialAccountMenuItemTypeOptionsWithDefault: Array<
  Option<OfficialAccountMenuItemType | null>
> = [
  { value: null, label: 'official_account_menu_item_type.__default__' },
  {
    value: OfficialAccountMenuItemType.Click,
    label: 'official_account_menu_item_type.click',
  },
  {
    value: OfficialAccountMenuItemType.View,
    label: 'official_account_menu_item_type.view',
  },
  {
    value: OfficialAccountMenuItemType.ScanCodePush,
    label: 'official_account_menu_item_type.scancode_push',
  },
  {
    value: OfficialAccountMenuItemType.ScanCodeWaitMsg,
    label: 'official_account_menu_item_type.scancode_waitmsg',
  },
  {
    value: OfficialAccountMenuItemType.PicSysPhoto,
    label: 'official_account_menu_item_type.pic_sysphoto',
  },
  {
    value: OfficialAccountMenuItemType.PicPhotoOrAlbum,
    label: 'official_account_menu_item_type.pic_photo_or_album',
  },
  {
    value: OfficialAccountMenuItemType.PicWeixin,
    label: 'official_account_menu_item_type.pic_weixin',
  },
  {
    value: OfficialAccountMenuItemType.LocationSelect,
    label: 'official_account_menu_item_type.location_select',
  },
  {
    value: OfficialAccountMenuItemType.Miniprogram,
    label: 'official_account_menu_item_type.miniprogram',
  },
];
