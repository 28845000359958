/**
 * @file: OrgUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OrgUserServiceBase } from './base/OrgUserService';

export class OrgUserService extends OrgUserServiceBase {
  // add custom service implementation here
  async renderUserAuthQrcode(
    orgId: number,
    storeId: number,
    userId: number,
    options?: { size?: number; margin?: number },
  ): Promise<{
    dataURI: string;
    ttl: number;
  }> {
    return await this.api
      .get()
      .url('/org-users/auth/qrcode', {
        orgid: orgId,
        storeid: storeId,
        userid: userId,
        ...options,
      })
      .future();
  }
}
