import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import {
  VehicleInspectionTemplate,
  VehicleInspectionTemplateListFilter,
} from 'model';
import { vehicleInspectionTemplateService } from 'services';

export const inspectionTemplateActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionTemplate,
  VehicleInspectionTemplateListFilter,
  number
>('inspection.templates', {
  shouldInvalidateForFilter: false,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.templates.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.templates.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.templates.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleInspectionTemplateService.list(
      {
        ...state.inspection.templates.filter,
        orgId: null,
        storeId: null,
        predefinedType: undefined,
        sceneType: undefined,
      },
      state.inspection.templates.sorts?.[0],
      state.inspection.templates.offset,
      state.inspection.templates.limit,
      { count: true, recursive: true },
    );
  },
  async create(template: Partial<VehicleInspectionTemplate>) {
    const entity = { ...template, agentId: template.agentId ?? null };
    if (entity.predefinedType) {
      entity.isPredefined = true;
    }
    return await vehicleInspectionTemplateService.create(entity);
  },
  async update(template: VehicleInspectionTemplate) {
    return await vehicleInspectionTemplateService.update({
      ...template,
      agentId: template.agentId ?? null,
    });
  },
  async delete(template: VehicleInspectionTemplate) {
    await vehicleInspectionTemplateService.delete(template.id);
  },
  async deleteMulti(templates: VehicleInspectionTemplate[]) {
    if (templates.length === 1) {
      await vehicleInspectionTemplateService.delete(templates[0].id);
      return;
    }
    throw new Error('delete multi inspection template is not supported. ');
  },
});

export function duplicateTemplate(
  template: VehicleInspectionTemplate,
): ActionThunk<AppState> {
  return async dispatch => {
    const duplicate: Partial<VehicleInspectionTemplate> = {
      ...template,
      id: undefined,
      disabled: true,
      createdAt: undefined,
      updatedAt: undefined,
      isSystemDefault: false,
      cid: undefined,
    };
    try {
      const res = await vehicleInspectionTemplateService.create(duplicate);
      dispatch(inspectionTemplateActions.createSuccess(duplicate, res));
    } catch (e) {
      dispatch(inspectionTemplateActions.createFailed(duplicate, e));
    }
  };
}
