import { ProductAgentInfoCell } from 'app/customers/product-agents/ProductAgentInfoCell';
import { TxHistory } from 'app/customers/product-agents/TxHistory';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { config } from 'config';
import { AclObjectList, ProductAgent, ProductAgentListFilter } from 'model';
import { MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { organizationService, systemService } from 'services';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import { formatTime } from 'utils';
import { isMobile } from 'utils/validators';
import { productAgentActions } from '../duck/actions';

interface Props extends EntityListProps<ProductAgent, ProductAgentListFilter> {
  activeOrgForQrcodeDownloader: ProductAgent | null | undefined;
}

type State = object;

const componentClassBuilder = new EntityListComponentClassBuilder<
  ProductAgent,
  ProductAgentListFilter,
  number,
  Props,
  State
>();

export const ProductAgentList = componentClassBuilder
  .i18nPrefix('product_agent')
  .accessRights({
    full: AclObjectList.ProductAgentFullAccess,
    readonly: AclObjectList.ProductAgentReadonlyAccess,
  })
  .withInitialState({ agentToSendStatsRemind: undefined })
  .breadcrumbs([
    { text: <Translate id="customer.breadcrumb.it" /> },
    { text: <Translate id="customer.breadcrumb.product_agents" /> },
  ])
  .entities(state => state.customers.productAgents)
  .actions(productAgentActions)
  .requireAreas()
  .editor(builder =>
    builder
      .productAgent({
        prop: 'parentAgentId',
        label: 'product_agent.editor.label.parent_agent',
        placeholder: 'product_agent.editor.placeholder.parent_agent',
      })
      .reactSelect({
        prop: 'ownOrgId',
        label: 'product_agent.editor.label.master_org',
        placeholder: 'product_agent.editor.placeholder.master_org',
        valueProp: 'id',
        labelProp: 'name',
        async: true,
        stateId: 'agent-master-organizations',
        values: [],
        defaultValues: true,
        noOptionsMessage: ({ inputValue }) => {
          return getString(
            inputValue
              ? 'store.org_picker.no_values'
              : 'store.org_picker.no_values_hint',
          );
        },
        onLoadValues: async (keyword, entity) => {
          if (!keyword) {
            if (entity.ownOrgId) {
              const org = await organizationService.get(entity.ownOrgId);
              if (org != null) {
                return [{ id: org.id, name: org.name }];
              }
            }
            return [];
          }
          const listResult = await organizationService.list(
            { keyword },
            null,
            0,
            0,
          );
          return listResult as any;
        },
      })
      .text({
        prop: 'name',
        label: 'product_agent.editor.label.name',
        placeholder: 'product_agent.editor.placeholder.name',
        helpText: 'product_agent.editor.help_text.name',
      })
      .text({
        prop: 'shortName',
        label: 'product_agent.editor.label.short_name',
        placeholder: 'product_agent.editor.placeholder.short_name',
        helpText: 'product_agent.editor.help_text.short_name',
      })
      .text({
        prop: 'adminMobile',
        label: 'product_agent.editor.label.admin_mobile',
        placeholder: 'product_agent.editor.placeholder.admin_mobile',
        helpText: 'product_agent.editor.help_text.admin_mobile',
      })
      .text({
        prop: 'credit',
        type: 'number',
        label: 'product_agent.editor.label.credit',
        placeholder: 'product_agent.editor.placeholder.credit',
        helpText: 'product_agent.editor.help_text.credit',
      })
      .area({
        label: 'product_agent.editor.label.city',
      })
      .text({
        prop: 'servicePhone',
        label: 'product_agent.editor.label.service_phone',
        placeholder: 'product_agent.editor.placeholder.service_phone',
      })
      .text({
        prop: 'address',
        label: 'product_agent.editor.label.address',
        placeholder: 'product_agent.editor.placeholder.address',
      })
      .text({
        prop: 'website',
        label: 'product_agent.editor.label.website',
        placeholder: 'product_agent.editor.placeholder.website',
      })
      .image({
        prop: 'logoUrl',
        label: 'product_agent.editor.label.logo',
        filePicker: {
          cover: false,
          realm: 'agent/logos',
          accept: 'image/png, image/jpeg',
        },
        helpText: 'product_agent.editor.help_text.logo',
      })
      .text({
        prop: 'contactName',
        label: 'product_agent.editor.label.contact.name',
        placeholder: 'product_agent.editor.placeholder.contact.name',
      })
      .text({
        prop: 'contactPhone',
        label: 'product_agent.editor.label.contact.phone',
        placeholder: 'product_agent.editor.placeholder.contact.phone',
      })
      .textArea({
        prop: 'description',
        label: 'product_agent.editor.label.description',
        placeholder: 'product_agent.editor.placeholder.description',
      })
      .textArea({
        prop: 'remark',
        label: 'product_agent.editor.label.remark',
        placeholder: 'product_agent.editor.placeholder.remark',
      }),
  )
  .toolbarItems(builder => {
    builder
      .text({
        prop: 'name',
        label: 'product_agent.toolbar.label.name',
        placeholder: 'product_agent.toolbar.placeholder.name',
        width: 250,
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(productAgentActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'logoUrl',
      width: 100,
      text: 'product_agent.col.logo',
      render: ({ logoUrl }) => (
        <img
          className="agent-info__logo"
          style={{ width: 100, height: 100 }}
          src={logoUrl || '/img/org-placeholder.png'}
        />
      ),
    },
    {
      prop: 'name',
      width: 250,
      text: 'product_agent.col.name',
      render(item, extra: Props) {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          extra.dispatch(
            productAgentActions.itemBeingUpdated({
              ...item,
              credit: Number((item.credit / 100).toFixed(2)),
            }),
          );
        };

        const onNavigateToPaDashboard = (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();

          extra.dispatch(showAppLoading());
          const win = window.open()!;
          win.document.title = 'Please wait... ';
          systemService
            .getOtcLoginCode(item.id)
            .then(code => {
              const url = new URL(
                (window as any).__paDashboardUrl__ ?? config.paDashboardUrl,
              );
              url.pathname = '/otc-login';
              url.searchParams.set('code', code);
              win.location.href = url.toString();
            })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .catch(e => {
              alert(e.message);
            })
            .finally(() => {
              extra.dispatch(hideAppLoading());
            });
        };

        const renderInfo = (label: string, value: any) =>
          value == null ? null : (
            <dl>
              <dt>
                <Translate id={`product_agent.info.label.${label}`} />
              </dt>
              <dd>{value}</dd>
            </dl>
          );

        return (
          <div className="agent-info__detail">
            <p>
              <a href="#" onClick={onClick}>
                <strong>{item.name}</strong>
              </a>
              <a
                href={'#'}
                target="_blank"
                style={{ marginLeft: '0.35rem' }}
                onClick={onNavigateToPaDashboard}
                rel="noreferrer"
              >
                <i className="la la-external-link" />
              </a>
            </p>
            {renderInfo('short_name', item.shortName)}
            {renderInfo('admin_mobile', item.adminMobile)}
            {renderInfo('service_phone', item.servicePhone)}
            {renderInfo('contact', item.contactName)}
            {renderInfo('contact_phone', item.contactPhone)}
            {renderInfo(
              'website',
              item.website ? (
                <a href={item.website} target="_blank" rel="noreferrer">
                  {item.website}
                </a>
              ) : null,
            )}
          </div>
        );
      },
    },
    {
      prop: 'account' as any,
      width: 200,
      text: 'product_agent.col.account_info',
      render: ({ id, credit, updatedAt }) => (
        <ProductAgentInfoCell
          agentId={id}
          credit={credit}
          key={updatedAt ? formatTime(updatedAt) : ''}
        />
      ),
    },
    {
      prop: 'address',
      width: 200,
      text: 'col.address',
      render: (entity, props: Props) => {
        if (!entity.provinceId) return '-';
        if (!props.areas?.result || props.areas.isLoading) {
          return '...';
        }
        const province = props.areas.result.getNodeById(entity.provinceId);
        const city = props.areas.result.getNodeById(entity.cityId!);
        const district = props.areas.result.getNodeById(entity.districtId!);
        const parts: string[] = [];
        if (province.children.length > 1) {
          parts.push(province.area.name);
        }
        parts.push(city.area.name, district.area.name);
        return (
          <div>
            <p style={{ marginBottom: 4 }}>{parts.join('/')}</p>
            <p>{entity.address}</p>
          </div>
        );
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons(['edit'])
  .onEdit(entity => {
    return {
      ...entity,
      credit: Number((entity.credit / 100).toFixed(2)),
    };
  })
  .onRender(() => {
    return <TxHistory />;
  })
  .validate(entity => {
    const name = entity.name?.trim();
    const shortName = entity.shortName?.trim();
    const address = entity.address?.trim();
    const servicePhone = entity.servicePhone?.trim();
    const adminMobile = entity.adminMobile?.trim();
    const credit = entity.credit ?? 0;

    if (!adminMobile) {
      throw new Error(
        getString('product_agent.editor.error.admin_mobile_required'),
      );
    }
    if (!isMobile(adminMobile)) {
      throw new Error(
        getString('product_agent.editor.error.invalid_admin_mobile_format'),
      );
    }
    if (!name) {
      throw new Error(getString('product_agent.editor.error.name_required'));
    }
    if (!shortName) {
      throw new Error(
        getString('product_agent.editor.error.short_name_required'),
      );
    }
    if (!address) {
      throw new Error(getString('product_agent.editor.error.address_required'));
    }
    if (!servicePhone) {
      throw new Error(
        getString('product_agent.editor.error.service_phone_required'),
      );
    }
    if (!entity.provinceId || !entity.cityId || !entity.districtId) {
      throw new Error(getString('product_agent.editor.error.city_required'));
    }
    if (credit < 0) {
      throw new Error(getString('product_agent.editor.error.negative_credit'));
    }
  })
  .getClass();
