/**
 * @file: VehicleInspectionTaskJobDetailService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskJobDetail,
  VehicleInspectionTaskJobDetailListFilter,
} from 'model';

export class VehicleInspectionTaskJobDetailServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskJobDetailListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskJobDetail>
      : VehicleInspectionTaskJobDetail[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-job-details',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskJobDetail | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-job-details/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionTaskJobDetail: Partial<VehicleInspectionTaskJobDetail>,
  ): Promise<VehicleInspectionTaskJobDetail> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-job-details')
      .data(vehicleInspectionTaskJobDetail)
      .future();
  }

  async update(
    vehicleInspectionTaskJobDetail: Partial<VehicleInspectionTaskJobDetail>,
  ): Promise<VehicleInspectionTaskJobDetail> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-job-details/:id', {
        id: vehicleInspectionTaskJobDetail.id,
      })
      .data(vehicleInspectionTaskJobDetail)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-job-details/:id', { id })
      .future();
  }
}
