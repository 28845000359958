import React, { Component, MouseEvent, ReactNode } from 'react';

interface Props {
  title: ReactNode;
  onRefresh?: () => void;
}

export class PageTitle extends Component<Props> {
  render() {
    const { title, onRefresh } = this.props;
    return (
      <span className="page-title-text">
        {title}
        {onRefresh && (
          <a href="#" className="refresh-btn" onClick={this.onRefresh}>
            <i className="flaticon-refresh" />
          </a>
        )}
      </span>
    );
  }

  onRefresh = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.onRefresh && this.props.onRefresh();
  };
}
