/**
 * @file: report-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators, createStandardAction } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleReport, VehicleReportFilter } from 'model';
import { reportService } from 'services';
import { ActionTypes } from '../types';

export const reportListActions =
  createListAsyncActionCreators<AppState, VehicleReport, VehicleReportFilter, string>(
  'reports.report_list',
  {
    shouldInvalidateForFilter: true,
    shouldFetchOnInvalidate: true,

    async fetchHandler(state: AppState) {
      return await reportService.searchReportsWithTotal(
        state.reports.reportList.filter,
        state.reports.reportList.sorts && state.reports.reportList.sorts[0] as any,
        null,
        state.reports.reportList.offset || 0,
        state.reports.reportList.limit || 10
        );
    }
  }
);

export function setReportListActiveReportId(reportId: string | undefined) {
  return createStandardAction(ActionTypes.SetActiveReport, reportId);
}