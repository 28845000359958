/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  TranslateFunction,
  TranslateOptions,
  TranslatePlaceholderData,
  TranslateValue,
} from 'react-localize-redux';

import { enUS, zhCN } from 'date-fns/locale';

import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

export type { AppState } from './duck/states';

export type TransFunction = (
  value: TranslateValue,
  data?: TranslatePlaceholderData,
  options?: TranslateOptions,
) => string;

interface Range {
  startDate: Date;
  endDate: Date;
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range: Range) {
    const definedRange = (this as any).range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

interface RangeInfo {
  label: string;
  range: () => Range;
}

export function getScopedTransFunction(
  translate: TranslateFunction,
  scope: string,
): TransFunction {
  return (
    value: string,
    data?: TranslatePlaceholderData,
    options?: TranslateOptions,
  ) => {
    if (scope) {
      if (value.startsWith('!')) {
        value = value.substr(1);
      } else {
        value = scope + '.' + value;
      }
    }
    return translate(value, data, options) as string;
  };
}

export function createStaticRanges(ranges: RangeInfo[]) {
  return ranges.map(range => ({ ...staticRangeHandler, ...range }));
}

export function getDefaultStaticRanges(trans: TransFunction) {
  return createStaticRanges([
    {
      label: trans('date_range.today'),
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      label: trans('date_range.yesterday'),
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },

    {
      label: trans('date_range.this_week'),
      range: () => ({
        startDate: defineds.startOfWeek,
        endDate: defineds.endOfWeek,
      }),
    },
    {
      label: trans('date_range.last_week'),
      range: () => ({
        startDate: defineds.startOfLastWeek,
        endDate: defineds.endOfLastWeek,
      }),
    },
    {
      label: trans('date_range.this_month'),
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      label: trans('date_range.last_month'),
      range: () => ({
        startDate: defineds.startOfLastMonth,
        endDate: defineds.endOfLastMonth,
      }),
    },
  ]);
}

export function getDefaultInputRanges(trans: TransFunction) {
  return [
    {
      label: trans('date_range.days_up_to_today'),
      range(value: any) {
        return {
          startDate: addDays(
            defineds.startOfToday,
            (Math.max(Number(value), 1) - 1) * -1,
          ),
          endDate: defineds.endOfToday,
        };
      },
      getCurrentValue(range: Range) {
        if (!isSameDay(range.endDate, defineds.endOfToday)) return '-';
        if (!range.startDate) return '∞';
        return (
          differenceInCalendarDays(defineds.endOfToday, range.startDate) + 1
        );
      },
    },
    {
      label: trans('date_range.days_starting_today'),
      range(value: any) {
        const today = new Date();
        return {
          startDate: today,
          endDate: addDays(today, Math.max(Number(value), 1) - 1),
        };
      },
      getCurrentValue(range: Range) {
        if (!isSameDay(range.startDate, defineds.startOfToday)) return '-';
        if (!range.endDate) return '∞';
        return (
          differenceInCalendarDays(range.endDate, defineds.startOfToday) + 1
        );
      },
    },
  ];
}

const locales: Record<string, object> = {
  en: enUS,
  zh: zhCN,
};

export function getDateRangePickerLocale(lang: string): object {
  return locales[lang];
}

export type MainNavStatus = 'open' | 'minimized';
