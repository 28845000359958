/**
 * @file: VehicleInspectionSubjectService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionSubjectServiceBase } from './base/VehicleInspectionSubjectService';

export class VehicleInspectionSubjectService extends VehicleInspectionSubjectServiceBase {
  // add custom service implementation here
}
