import { useItemStagedRef } from 'app/inspection/quotation-template-config/hooks/refs';
import { ItemMaterialList } from 'app/inspection/quotation-template-config/items/editor/MaterialList';
import { keyOfMaterial } from 'app/inspection/quotation-template-config/util';
import { produce } from 'immer';
import {
  QuotationTemplateItemStaged,
  QuotationTemplateMaterialStaged,
} from 'model';
import {
  forwardRef,
  memo,
  MouseEvent,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { commonService } from 'services';
import { EntityEditorForm, EntityEditorFormBuilder } from 'shared/components';
import { makeUniqueIdAlphabetic } from 'utils/id';
import { usePersistFn } from 'utils/usePersistFn';

type Props = {
  item: QuotationTemplateItemStaged;
};

export type QuotationItemEditorRef = {
  getStaged: () => QuotationTemplateItemStaged;
  validate: () => boolean;
  setError: (name: string, error: any) => void;
  get isDirty(): boolean;
};

export const QuotationItemEditor = memo(
  forwardRef<QuotationItemEditorRef, Props>((props, ref) => {
    const isDirty = useRef(false);

    const itemRef = useItemStagedRef(props.item);

    const [showErrorOnChange, setShowErrorOnChange] = useState(false);
    const [staged, setStaged] = useState(props.item);
    const [errors, setErrors] = useState<Record<string, ReactNode>>({});
    const [expandedMaterialIds, setExpandedMaterialIds] = useState(
      new Set<string>(),
    );
    const [materialErrors, setMaterialErrors] = useState<
      Record<string, Record<string, ReactNode>>
    >({});

    const isAllExpanded = expandedMaterialIds.size === staged.materials?.length;

    const validate = usePersistFn(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const errors: Record<string, ReactNode> = {};

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const materialErrors: Record<string, Record<string, ReactNode>> = {};

      if (!staged.name?.trim()) {
        errors['name'] = (
          <Translate id="quotation_tpl.item.error.name_required" />
        );
      }

      if (!staged.pyInitial?.trim()) {
        errors['pyInitial'] = (
          <Translate id="quotation_tpl.item.error.py_initials_required" />
        );
      }

      if (staged.manHourCost != null && staged.manHourCost < 0) {
        errors['manHourCost'] = (
          <Translate id="quotation_tpl.item.error.invalid_man_hour_cost" />
        );
      }

      if (staged.discountManHourCost != null) {
        if (staged.discountManHourCost < 0) {
          errors['discountManHourCost'] = (
            <Translate id="quotation_tpl.item.error.invalid_man_hour_cost" />
          );
        } else if (staged.manHourCost == null) {
          errors['manHourCost'] = (
            <Translate id="quotation_tpl.item.error.man_hour_cost_required" />
          );
        } else if (staged.discountManHourCost >= staged.manHourCost) {
          errors['discountManHourCost'] = (
            <Translate id="quotation_tpl.item.error.invalid_discount_man_hour_cost" />
          );
        }
      }

      if (staged.materials?.length) {
        const existingKeys = new Set<string>();
        for (const material of staged.materials) {
          const name = material.name?.trim();
          if (!name) {
            materialErrors[material.id] = {
              ...materialErrors[material.id],
              name: (
                <Translate id="quotation_tpl.material.error.name_required" />
              ),
            };
          } else {
            const key = keyOfMaterial(material);
            if (existingKeys.has(key)) {
              materialErrors[material.id] = {
                ...materialErrors[material.id],
                name: (
                  <Translate
                    id="quotation_tpl.material.error.duplicate_material"
                    data={{ name: material.name }}
                  />
                ),
              };
            } else {
              existingKeys.add(key);
            }
          }

          if (material.price != null && material.price < 0) {
            materialErrors[material.id] = {
              ...materialErrors[material.id],
              price: (
                <Translate id="quotation_tpl.material.error.invalid_price" />
              ),
            };
          }

          if (material.discountPrice != null) {
            if (material.discountPrice < 0) {
              materialErrors[material.id] = {
                ...materialErrors[material.id],
                discountPrice: (
                  <Translate id="quotation_tpl.material.error.invalid_price" />
                ),
              };
            } else if (material.price == null) {
              materialErrors[material.id] = {
                ...materialErrors[material.id],
                price: (
                  <Translate id="quotation_tpl.material.error.price_required" />
                ),
              };
            } else if (material.discountPrice >= material.price) {
              materialErrors[material.id] = {
                ...materialErrors[material.id],
                discountPrice: (
                  <Translate id="quotation_tpl.material.error.invalid_discount_price" />
                ),
              };
            }
          }
        }
      }

      const hasErrors = Boolean(
        Object.keys(errors).length || Object.keys(materialErrors).length,
      );

      if (!showErrorOnChange && hasErrors) {
        setShowErrorOnChange(true);
      }

      setErrors(errors);
      setMaterialErrors(materialErrors);

      if (hasErrors) {
        return false;
      }

      return true;
    });

    useImperativeHandle(ref, () => ({
      getStaged: () => ({
        ...staged,
        name: staged.name.trim(),
      }),
      validate,
      setError: (name, error) => {
        setErrors(x => ({ ...x, [name]: error }));
      },
      get isDirty() {
        return isDirty.current;
      },
    }));

    const onMaterialErrorStateChange = usePersistFn(
      (materialId: string, field: string, error: ReactNode | undefined) => {
        const updated = produce(materialErrors, draft => {
          draft[materialId] = {
            ...draft[materialId],
            [field]: error,
          };
        });
        setMaterialErrors(updated);
      },
    );

    const onAddMaterial = usePersistFn((e: MouseEvent) => {
      e.preventDefault();
      const updated = produce(staged, draft => {
        if (draft.materials == null) {
          draft.materials = [];
        }
        draft.materials.push({
          id: makeUniqueIdAlphabetic(),
          name: '',
          itemRef,
        });
      });
      setStaged(updated);
    });

    const handleChange = usePersistFn((values: QuotationTemplateItemStaged) => {
      setStaged((x: any) => ({ ...x, ...values }) as any);
      isDirty.current = true;
    });

    const onExpandAll = usePersistFn(() => {
      setExpandedMaterialIds(new Set(staged.materials?.map(x => x.id) ?? []));
    });

    const onCollapseAll = useCallback(() => {
      setExpandedMaterialIds(new Set());
    }, []);

    const form = useMemo(() => {
      const builder =
        new EntityEditorFormBuilder<QuotationTemplateItemStaged>();

      builder
        .text({
          prop: 'name',
          label: 'quotation_tpl.item.label.name',
          placeholder: 'quotation_tpl.item.placeholder.name',
          controlled: false,
          required: true,
          error: errors['name'],
          onChange: async changes => {
            if (changes.name) {
              try {
                const pyInitial = await commonService.getPyInitial(
                  changes.name,
                );
                setStaged(x => ({ ...x, pyInitial }));
              } catch (e) {
                console.error(e);
              }
            } else {
              setStaged(x => ({ ...x, pyInitial: '' }));
            }
            if (showErrorOnChange) {
              setTimeout(() => {
                validate();
              }, 0);
            }
          },
        })
        .text({
          prop: 'pyInitial',
          label: 'quotation_tpl.item.label.py_initial',
          placeholder: 'quotation_tpl.item.placeholder.py_initial',
          error: errors['pyInitial'],
          required: true,
          onChange: changes => {
            changes.pyInitial = changes.pyInitial?.toLowerCase();
            if (showErrorOnChange) {
              setTimeout(() => {
                validate();
              }, 0);
            }
          },
        })
        .text({
          prop: 'code',
          label: 'quotation_tpl.item.label.code',
          placeholder: 'quotation_tpl.item.placeholder.code',
          controlled: false,
          error: errors['code'],
        })
        .text({
          prop: 'manHourCost',
          type: 'number',
          label: 'quotation_tpl.item.label.man_hour_cost',
          placeholder: 'quotation_tpl.item.placeholder.man_hour_cost',
          controlled: false,
          error: errors['manHourCost'],
          onChange: () => {
            if (showErrorOnChange) {
              setTimeout(() => {
                validate();
              }, 0);
            }
          },
        })
        .text({
          prop: 'discountManHourCost',
          type: 'number',
          label: 'quotation_tpl.item.label.discount_man_hour_cost',
          placeholder: 'quotation_tpl.item.placeholder.discount_man_hour_cost',
          error: errors['discountManHourCost'],
          onChange: () => {
            if (showErrorOnChange) {
              setTimeout(() => {
                validate();
              }, 0);
            }
          },
        })
        .text({
          prop: 'discountRemark',
          label: 'quotation_tpl.item.label.discount_remark',
          placeholder: 'quotation_tpl.item.placeholder.discount_remark',
        })
        .text({
          prop: 'remark',
          label: 'quotation_tpl.item.label.remark',
          placeholder: 'quotation_tpl.item.placeholder.remark',
        })
        .checkbox({
          prop: 'included',
          label: 'quotation_tpl.item.label.included',
        })
        .custom({
          key: 'materials',
          className: 'quotation-item-editor__materials-form-group',
          unwrapLabel: true,
          label: (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '0.5rem',
              }}
            >
              <Translate id="quotation_tpl.item.label.materials" />
              <a
                href="#"
                onClick={onAddMaterial}
                style={{ marginLeft: '0.5rem' }}
              >
                <i
                  className="fa fa-plus-square"
                  style={{
                    fontSize: '1.2rem',
                    verticalAlign: 'middle',
                  }}
                />
              </a>
              <div style={{ textAlign: 'right', flex: 1 }}>
                {(staged.materials ?? []).length > 1 && (
                  <a
                    href="#"
                    style={{ fontSize: '0.9rem' }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isAllExpanded) {
                        onCollapseAll();
                      } else {
                        onExpandAll();
                      }
                    }}
                  >
                    <Translate
                      id={`quotation_tpl.item.modal.editor.${isAllExpanded ? 'collapse_all' : 'expand_all'}`}
                    />
                  </a>
                )}
              </div>
            </div>
          ),
          render: (_extra, entity) => {
            const onMaterialsChange = (
              materials: QuotationTemplateMaterialStaged[],
            ) => {
              const updated = produce(entity, draft => {
                draft.materials = materials;
              });
              handleChange(updated as any);
              if (showErrorOnChange) {
                setTimeout(() => {
                  validate();
                }, 0);
              }
            };
            return (
              <>
                <ItemMaterialList
                  materials={entity.materials ?? []}
                  onChange={onMaterialsChange}
                  error={errors['materials']}
                  errors={materialErrors}
                  expandedMaterialIds={expandedMaterialIds}
                  onExpandedMaterialIdsChange={setExpandedMaterialIds}
                  showErrorOnChange={showErrorOnChange}
                  onErrorStateChange={onMaterialErrorStateChange}
                />
              </>
            );
          },
        });
      return builder.build();
    }, [
      errors,
      expandedMaterialIds,
      handleChange,
      isAllExpanded,
      materialErrors,
      onAddMaterial,
      onCollapseAll,
      onExpandAll,
      onMaterialErrorStateChange,
      showErrorOnChange,
      staged.materials,
      validate,
    ]);

    return (
      <div className="quotation_tpl-item-editor">
        <form className="entity-editor-form m-form">
          <div className="m-portlet__body">
            <div className="m-form__section m-form__section--first">
              <EntityEditorForm
                entity={staged}
                onChange={handleChange}
                elements={form.elements as any}
                autocomplete={form.autocomplete}
                useUncontrolled={form.useUncontrolled}
                helpTextPlacement="before"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }),
);
