/**
 * @file: StoreObdDeviceService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StoreObdDeviceServiceBase } from './base/StoreObdDeviceService';

export class StoreObdDeviceService extends StoreObdDeviceServiceBase {
  // add custom service implementation here
}
