/**
 * @file: OpenApiAppService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  OpenApiApp,
  OpenApiAppAuthorizedStore,
  OpenApiAppListFilter,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class OpenApiAppServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<OpenApiAppListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<OpenApiApp>
      : OpenApiApp[]
  > {
    return await this.api
      .get()
      .url(
        '/open-api-apps',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<OpenApiApp | null> {
    return await this.api.get().url('/open-api-apps/:id', { id }).future();
  }

  async getAppByAppKey(appKey: string): Promise<OpenApiApp | null> {
    if (!appKey) {
      throw new Error('missing appKey parameter');
    }
    return await this.api
      .get()
      .url('/open-api-apps/query/appkey', { appKey })
      .future();
  }

  async create(openApiApp: Partial<OpenApiApp>): Promise<OpenApiApp> {
    return await this.api
      .post()
      .url('/open-api-apps')
      .data(openApiApp)
      .future();
  }

  async update(openApiApp: Partial<OpenApiApp>): Promise<OpenApiApp> {
    return await this.api
      .put()
      .url('/open-api-apps/:id', { id: openApiApp.id })
      .data(openApiApp)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete().url('/open-api-apps/:id', { id }).future();
  }

  async getAuthorizedStoresByAppId(
    id: number,
    recursive = false,
  ): Promise<OpenApiAppAuthorizedStore[]> {
    return await this.api
      .get()
      .url('/open-api-apps/:id/authorized-stores', { id, recursive })
      .future();
  }

  async createOpenApiAppAuthorizedStore(
    store: Partial<OpenApiAppAuthorizedStore>,
  ): Promise<OpenApiAppAuthorizedStore> {
    const id = store.appId;
    if (!id) {
      throw new Error('store.appId is required. ');
    }
    return await this.api
      .post()
      .url('/open-api-apps/:id/authorized-stores')
      .data(store)
      .future();
  }

  async updateOpenApiAppAuthorizedStore(
    store: OpenApiAppAuthorizedStore,
  ): Promise<OpenApiAppAuthorizedStore> {
    const id = store.appId;
    if (!id) {
      throw new Error('store.appId is required. ');
    }
    return await this.api
      .put()
      .url('/open-api-apps/:id/authorized-stores')
      .data(store)
      .future();
  }

  async deleteOpenApiAppAuthorizedStore(
    appId: number | OpenApiAppAuthorizedStore,
    storeId?: number,
  ): Promise<void> {
    if (appId && typeof appId === 'object') {
      storeId = appId.id;
      if (!appId.appId) {
        throw new Error('appId cannot be null or undefined');
      }
      appId = appId.appId;
    }
    if (!appId || !storeId) {
      throw new Error('appId and storeId are both required');
    }
    return void await this.api
      .delete()
      .url('/open-api-apps/:appId/authorized-stores/:storeId', {
        appId,
        storeId,
      })
      .future();
  }
}
