import classNames from 'classnames';
import { AreaTree } from 'model';
import React, { PureComponent } from 'react';
import { FormElementGroup } from '../types';
import { FormElementRenderer } from './ElementRenderer';

interface Props<T> {
  element: FormElementGroup<T>;
  disabled?: boolean;
  value: any;
  autocomplete?: boolean;
  useUncontrolled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  onGetEntity: () => T | Partial<T>;
  onGetAreas: () => AreaTree | null | undefined;
  onChange: (values: Partial<T>) => void;
}

export class FormGroup<T> extends PureComponent<Props<T>> {
  render() {
    const { element: group, disabled } = this.props;
    const entity = this.props.onGetEntity();
    return (
      <div
        className={classNames('entity-editor-form__element-group', group.className)}
        style={{ width: group.width }}
        >
        {group.elements.map((elem, i) => (
          <FormElementRenderer
            key={i}
            element={elem}
            disabled={disabled}
            value={entity[elem.prop]}
            autocomplete={this.props.autocomplete}
            useUncontrolled={this.props.useUncontrolled}
            onGetExtraInfo={this.props.onGetExtraInfo}
            onGetEntity={this.props.onGetEntity}
            onGetAreas={this.props.onGetAreas}
            onChange={this.props.onChange}
            />
        ))}
      </div>
    );
  }
}