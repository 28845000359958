/**
 * @file: VehicleServiceSubjectTagOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, VehicleServiceSubjectTag } from 'model';

export const VehicleServiceSubjectTagOptions: Array<
  Option<VehicleServiceSubjectTag>
> = [
  {
    value: VehicleServiceSubjectTag.None,
    label: 'vehicle_service_subject_tag.none',
  },
];

export const VehicleServiceSubjectTagOptionsWithDefault: Array<
  Option<VehicleServiceSubjectTag | null>
> = [
  { value: null, label: 'vehicle_service_subject_tag.__default__' },
  {
    value: VehicleServiceSubjectTag.None,
    label: 'vehicle_service_subject_tag.none',
  },
];
