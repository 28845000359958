/**
 * @file: ServiceDurationUnitOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ServiceDurationUnit } from 'model';

export const ServiceDurationUnitOptions: Array<Option<ServiceDurationUnit>> = [
  { value: ServiceDurationUnit.None, label: 'service_duration_unit.none' },
  { value: ServiceDurationUnit.Day, label: 'service_duration_unit.day' },
  { value: ServiceDurationUnit.Month, label: 'service_duration_unit.month' },
  { value: ServiceDurationUnit.Year, label: 'service_duration_unit.year' },
];

export const ServiceDurationUnitOptionsWithDefault: Array<
  Option<ServiceDurationUnit | null>
> = [
  { value: null, label: 'service_duration_unit.__default__' },
  { value: ServiceDurationUnit.None, label: 'service_duration_unit.none' },
  { value: ServiceDurationUnit.Day, label: 'service_duration_unit.day' },
  { value: ServiceDurationUnit.Month, label: 'service_duration_unit.month' },
  { value: ServiceDurationUnit.Year, label: 'service_duration_unit.year' },
];
