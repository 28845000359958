import { AppState } from 'app/duck/states';
import { getCategoryNodeDecendants } from 'app/inspection/common/helpers';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import { buildSimpleTreeModel } from 'lib/helpers';
import {
  InspectionSiteType,
  VehicleInspectionSite,
  VehicleInspectionSiteListFilter,
} from 'model';
import { vehicleInspectionSiteService } from 'services';
import { arr2map } from 'utils';
import { ActionTypes } from '../types';

export const inspectionSiteActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionSite,
  VehicleInspectionSiteListFilter,
  number
>('inspection.sites', {
  shouldInvalidateForFilter: false,
  shouldFetchOnInvalidate: true,
  mapItemKey: x => x.id,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.sites.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.sites.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.sites.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleInspectionSiteService.list(
      Object.assign({}, state.inspection.sites.filter, {
        orgId: null,
        storeId: null,
        keyword: '',
      }),
      state.inspection.sites.sorts?.[0],
      state.inspection.sites.offset,
      state.inspection.sites.limit,
      { count: true, recursive: true },
    );
  },
  async create(site: Partial<VehicleInspectionSite>) {
    return await vehicleInspectionSiteService.create(site);
  },
  async update(site: VehicleInspectionSite) {
    return await vehicleInspectionSiteService.update({
      ...site,
      checkItems: undefined,
    });
  },
  async delete(site: VehicleInspectionSite) {
    await vehicleInspectionSiteService.delete(site.id);
  },
  async deleteMulti(sites: VehicleInspectionSite[]) {
    if (sites.length === 1) {
      await vehicleInspectionSiteService.delete(sites[0].id);
      return;
    }
    throw new Error('delete multi inspection sites is not supported. ');
  },
  buildAllGroupValues(state: AppState) {
    const { categories, sites } = state.inspection;
    const activeGroupKey = sites.activeGroupKey;
    if (activeGroupKey === 'all') {
      return (
        categories.result?.filter(x => !x.parentCategoryId).map(x => x.id) || []
      );
    } else if (activeGroupKey === 'hardware') {
      const nodes = buildSimpleTreeModel(
        categories.result || [],
        x => x.id,
        x => x.parentCategoryId,
      );
      const list = sites.result?.filter(x => x.supportsIdevice) || [];
      const res = new Set<number>();
      const setMap = arr2map(
        nodes,
        x => x.data.id,
        x => new Set(getCategoryNodeDecendants(x).map(t => t.data.id)),
      );
      for (const site of list) {
        for (const node of nodes) {
          if (site.categoryId === node.data.id) {
            res.add(node.data.id);
            break;
          } else {
            const set = setMap[node.data.id];
            if (set.has(site.categoryId)) {
              res.add(node.data.id);
              break;
            }
          }
        }
      }
      return [...res];
    } else if (activeGroupKey?.startsWith('category')) {
      const activeCategoryId = sites.activeGroupContext!;
      return categories
        .result!.filter(x => x.parentCategoryId === activeCategoryId)
        .map(x => x.id);
    }

    return [];
  },
});

export function inspectionSiteKeywordFilterChanged(
  keyword: string | undefined | null,
) {
  return createStandardAction(ActionTypes.SiteKeywordFilterChanged, keyword);
}

export function inventoryManagerSetNewSiteTargetCategoryId(
  categoryId: number | null | undefined,
) {
  return createStandardAction(
    ActionTypes.InventoryManagerSetTargetCategoryId,
    categoryId,
  );
}

export function batchSetSitesType(siteIds: number[] | undefined) {
  return createStandardAction(ActionTypes.BatchSetType, siteIds);
}

export function requestBatchSetSitesType(
  type: InspectionSiteType,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    const siteIds = state.inspection.sites.siteIdsForBatchSetType;
    if (!siteIds?.length) return;
    dispatch(createStandardAction(ActionTypes.RequestBatchSetType, type));
    vehicleInspectionSiteService
      .batchUpdateSitesType(siteIds, type)
      .then(() => {
        dispatch(createStandardAction(ActionTypes.BatchSetTypeSuccess, type));
      })
      .catch(err => {
        dispatch(createStandardAction(ActionTypes.BatchSetTypeError, err));
      });
  };
}

export function batchSetSitesModule(siteIds: number[] | undefined) {
  return createStandardAction(ActionTypes.BatchSetModule, siteIds);
}

export function requestBatchSetSitesModule(
  primaryModuleId: number | null | undefined,
  secondaryModuleId: number | null | undefined,
): ActionThunk<AppState> {
  return (dispatch, getState) => {
    const state = getState();
    const siteIds = state.inspection.sites.siteIdsForBatchSetModule;
    if (!siteIds?.length) return;
    dispatch(
      createStandardAction(ActionTypes.RequestBatchSetModule, {
        primaryModuleId,
        secondaryModuleId,
      }),
    );
    vehicleInspectionSiteService
      .batchUpdateSitesModule(siteIds, primaryModuleId, secondaryModuleId)
      .then(() => {
        dispatch(
          createStandardAction(ActionTypes.BatchSetModuleSuccess, {
            primaryModuleId,
            secondaryModuleId,
          }),
        );
      })
      .catch(err => {
        dispatch(createStandardAction(ActionTypes.BatchSetModuleError, err));
      });
  };
}
