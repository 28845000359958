/**
 * @file: set.ts
 * @copyright: (c) 2019-2021 sichuan zhichetech co., ltd.
 */
export function setAdded<T>(set: Set<T>, element: T) {
  return new Set([...set, element]);
}

export function setRemoved<T>(set: Set<T>, element: T) {
  const newSet = new Set(set);
  newSet.delete(element);
  return newSet;
}
