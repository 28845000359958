import { Component, MouseEvent, ReactNode } from 'react';
import './Clear.scss';

interface Props {
  visible?: boolean;
  children?: ReactNode;
  onClear?: () => void;
}

export class Clear extends Component<Props> {
  render() {
    const { visible } = this.props;
    return (
      <div className="clear-wrapper" style={{ position: 'relative' }}>
        {this.props.children}
        {visible && (
          <a
            href="#"
            onClick={this.onClear}
            className="clear-button"
            style={{
              textDecoration: 'none',
              fontSize: 20,
              position: 'absolute',
              right: 0,
              top: 0,
              bottom: 0,
              display: 'flex',
              width: 32,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <i className="la la-times-circle-o" />
          </a>
        )}
      </div>
    );
  }

  onClear = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    this.props.onClear && this.props.onClear();
  };
}
