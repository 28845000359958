import { buildSimpleTreeModel } from 'lib/helpers';
import { InspectionSiteTypeOptions, VehicleInspectionSite } from 'model';
import { ChangeEvent } from 'react';
import { getString, withEntityEditorSidebarBuilder } from 'shared/components';
import { inspectionSiteActions } from '../../duck/actions';
import { InventoryManagerProps } from '../manager';

export const SiteEditorV2 =
  withEntityEditorSidebarBuilder<VehicleInspectionSite>()
    .withI18nPrefix('inspection_site')
    .withForm(builder =>
      builder
        .uncontrolled()
        .custom({
          label: 'inspection_site.editor.label.category',
          placeholder: 'inspection_site.editor.placeholder.category',
          render({ props }: { props: InventoryManagerProps }) {
            const site =
              props.sites.itemBeingCreated || props.sites.itemBeingUpdated;
            const targetCategoryId: number | null | undefined =
              props.sites.targetCategoryId;
            const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
              const categoryId = e.target.value
                ? Number(e.target.value)
                : undefined;
              if (props.sites.itemBeingCreated) {
                props.dispatch(
                  inspectionSiteActions.itemBeingCreatedChanged!({
                    categoryId,
                  }),
                );
              } else {
                props.dispatch(
                  inspectionSiteActions.itemBeingUpdatedChanged!({
                    categoryId,
                  }),
                );
              }
            };
            const nodes = buildSimpleTreeModel(
              props.categories.result!,
              x => x.id,
              x => x.parentCategoryId,
            );

            if (targetCategoryId) {
              const node = nodes.find(x => x.id === targetCategoryId)!;
              return (
                <select
                  className="form-control"
                  value={site!.categoryId || ''}
                  onChange={onChange}
                >
                  <option value="">
                    {getString('inspection_site.editor.placeholder.category')}
                  </option>
                  <optgroup label={node.data.name}>
                    {(node.children || []).map(childNode => (
                      <option value={childNode.id} key={childNode.id}>
                        {node.data.name}
                      </option>
                    ))}
                  </optgroup>
                </select>
              );
            }

            return (
              <select
                className="form-control"
                value={site!.categoryId || ''}
                onChange={onChange}
              >
                <option value="">
                  {getString('inspection_site.editor.placeholder.category')}
                </option>
                {nodes.map(node => (
                  <optgroup label={node.data.name} key={node.id}>
                    {(node.children || []).map(childNode => (
                      <option value={childNode.id} key={childNode.id}>
                        {childNode.data.name}
                      </option>
                    ))}
                  </optgroup>
                ))}
              </select>
            );
          },
        })
        .select({
          prop: 'type',
          options: InspectionSiteTypeOptions,
          label: 'inspection_site.editor.label.type',
          placeholder: 'inspection_site.editor.placeholder.type',
          helpText: 'inspection_site.editor.help_text.type',
        })
        .text({
          prop: 'code',
          label: 'inspection_site.editor.label.code',
          placeholder: 'inspection_site.editor.placeholder.code',
          helpText: 'inspection_site.editor.help_text.code',
        })
        .text({
          prop: 'name',
          label: 'inspection_site.editor.label.name',
          placeholder: 'inspection_site.editor.placeholder.name',
        })
        .text({
          prop: 'pyInitial',
          label: 'inspection_site.editor.label.py_initial',
          placeholder: 'inspection_site.editor.placeholder.py_initial',
          onChange: changes => {
            changes.pyInitial = changes.pyInitial?.toLowerCase();
          },
        })
        .text({
          type: 'number',
          prop: 'influenceFactor',
          label: 'inspection_site.editor.label.influence_factor',
          placeholder: 'inspection_site.editor.placeholder.influence_factor',
          helpText: 'inspection_site.editor.help_text.influence_factor',
        })
        .image({
          prop: 'iconUrl',
          label: 'inspection_site.editor.label.icon',
          filePicker: {
            cover: false,
            realm: 'site/icons',
            accept: 'image/png, image/jpeg',
          },
        })
        .image({
          prop: 'imgUrl',
          label: 'inspection_site.editor.label.img',
          filePicker: {
            cover: false,
            realm: 'site/img',
            accept: 'image/png, image/jpeg',
          },
        })
        .checkbox({
          prop: 'supportsIdevice',
          label: 'inspection_site.editor.label.supports_idevice',
        })
        .text({
          type: 'number',
          prop: 'ideviceSortOrder',
          label: 'inspection_site.editor.label.idevice_sort_order',
          placeholder: 'inspection_site.editor.placeholder.idevice_sort_order',
          helpText: 'inspection_site.editor.help_text.idevice_sort_order',
        })
        .text({
          prop: 'positionCode',
          label: 'inspection_site.editor.label.position_code',
          placeholder: 'inspection_site.editor.placeholder.position_code',
          helpText: 'inspection_site.editor.help_text.position_code',
        })
        .textArea({
          prop: 'description',
          label: 'inspection_site.editor.label.description',
          placeholder: 'inspection_site.editor.placeholder.description',
        }),
    )
    .withValidator(site => {
      let errCode: string | undefined = undefined;
      const name = site.name?.trim();
      const code = site.code?.trim();
      const pyInitial = site.pyInitial?.trim();
      if (!site.categoryId) {
        errCode = 'category_required';
      } else if (!site.type) {
        errCode = 'type_required';
      } else if (!name) {
        errCode = 'name_required';
      } else if (!code) {
        errCode = 'code_required';
      } else if (!pyInitial) {
        errCode = 'py_initial_required';
      } else if (!site.influenceFactor) {
        errCode = 'influence_factor_required';
      } else if (site.influenceFactor < 1 || site.influenceFactor > 10) {
        errCode = 'invalid_influence_factor';
      }
      if (errCode) {
        const errMsg = getString('inspection_site.editor.error.' + errCode);
        throw new Error(errMsg);
      }
    })
    .getClass();
