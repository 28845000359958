import classNames from 'classnames';
import { ProductAgent } from 'model';
import { Component, CSSProperties, ReactNode } from 'react';
import { FormatOptionLabelMeta, Options } from 'react-select';
import { productAgentService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  agentId?: number | null;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  onChange: (
    agentId: string | number | undefined,
    agent: ProductAgent | null,
  ) => void;
}

interface State {
  agents: ProductAgent[] | null;
  isLoading: boolean;
}

export class ProductAgentPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      agents: null,
      isLoading: false,
    };
  }

  render() {
    const { agents, isLoading } = this.state;
    const { agentId, className, disabled, style, placeholder } = this.props;

    const selectedAgent = agents?.find(x => x.id === agentId) ?? null;

    return (
      <div
        className={classNames('product-agent-picker', className)}
        style={style}
      >
        <Select<ProductAgent>
          key={`product-agents`}
          values={[]}
          valueProp="id"
          labelProp="name"
          disabled={disabled}
          className="product-agent-picker__component agent-picker__agent"
          selectedValue={selectedAgent}
          placeholder={getString(
            placeholder ?? 'product_agent_picker.placeholder.select_agent',
          )}
          isLoading={isLoading}
          isClearable
          noOptionsMessage={getString(
            'product_agent_picker.no_values_msg.agent',
          )}
          async
          defaultValues
          onLoadValues={this.onLoadAgents}
          onChange={this.onAgentChange}
          onFormatOptionLabel={this.onFormatOptionLabel}
        />
      </div>
    );
  }

  onLoadAgents = async (keyword: string): Promise<ProductAgent[]> => {
    this.setState({ isLoading: true });
    try {
      const agents = (await productAgentService.list(
        { keyword },
        null,
        0,
        0,
      )) as any;
      this.setState({ isLoading: false, agents });
      return agents;
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, agents: null });
    }

    return [];
  };

  onAgentChange = async (value: Options<ProductAgent>) => {
    if (Array.isArray(value)) {
      return;
    }

    if (!value) {
      this.props.onChange(undefined, null);
      return;
    }

    const agent = value as unknown as ProductAgent;

    this.props.onChange(agent.id, agent);
  };

  onFormatOptionLabel = (
    agent: ProductAgent | null,
    meta: FormatOptionLabelMeta<ProductAgent>,
  ): ReactNode => {
    const selected = meta.selectValue
      ? Array.isArray(meta.selectValue)
        ? meta.selectValue[0]
        : meta.selectValue
      : null;
    return (
      <div>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: meta.context === 'menu' ? '1.1rem' : '0.85rem',
          }}
        >
          {agent?.shortName}
        </div>
        <div
          style={{
            fontSize: meta.context === 'menu' ? '0.75rem' : '0.7rem',
            marginTop: meta.context === 'menu' ? 0 : -2,
            color:
              meta.context === 'menu' && selected == agent ? '#eee' : 'gray',
          }}
        >
          {agent?.name}
        </div>
      </div>
    );
  };
}
