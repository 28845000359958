import { ChangeEvent, ReactNode, useCallback } from 'react';

interface RadioButtonProps {
  label: string | ReactNode;
  value: any;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: any, checked: boolean) => void;
}

export function RadioButton(props: RadioButtonProps) {
  const { value, checked, label, disabled } = props;
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      props.onChange(value, e.target.checked);
    },
    [props, value],
  );

  return (
    <label className="m-radio m-radio--solid m-radio--brand">
      <input
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      {label}
      <span />
    </label>
  );
}
