/**
 * @file: AccountTxCategory.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum AccountTxCategory {
  In = 'In',
  Out = 'Out',
}

export const AccountTxCategoryValueSet = new Set([
  AccountTxCategory.In,
  AccountTxCategory.Out,
]);
