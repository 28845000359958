/**
 * @file: VehicleReceptionOrderService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleReceptionOrderServiceBase } from './base/VehicleReceptionOrderService';

export class VehicleReceptionOrderService extends VehicleReceptionOrderServiceBase {
  async delete() {
    throw new Error('Not supported');
  }

  async deletePermanently(orderNo: string): Promise<void> {
    return void (await this.api
      .delete()
      .url('/vehicle-reception-orders/:orderNo/delete-permanently', { orderNo })
      .future());
  }
}
