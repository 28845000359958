import classNames from 'classnames';
import { ServiceEdition } from 'model';
import { Component, CSSProperties, ReactNode } from 'react';
import { FormatOptionLabelMeta, Options } from 'react-select';
import { serviceEditionService } from 'services';
import { Select } from '../Select';
import { getString } from '../StringLabel';

interface Props {
  placeholder?: string;
  serviceEditionId?: string | null;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
  onChange: (serviceEdition: ServiceEdition | null) => void;
}

interface State {
  serviceEditions: ServiceEdition[] | null;
  isLoading: boolean;
}

export class ServiceEditionPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      serviceEditions: null,
      isLoading: false,
    };
  }

  render() {
    const { serviceEditions, isLoading } = this.state;
    const { serviceEditionId, className, disabled, style, placeholder } =
      this.props;

    const selectedServiceEdition =
      serviceEditions?.find(x => x.sku === serviceEditionId) ?? null;

    return (
      <div
        className={classNames('service-edition-picker', className)}
        style={style}
      >
        <Select<ServiceEdition>
          key={`serviceEditions`}
          values={[]}
          valueProp="sku"
          labelProp="name"
          disabled={disabled}
          className="service-edition-picker__component service-edition-picker__serviceEdition"
          selectedValue={selectedServiceEdition}
          placeholder={getString(
            placeholder ??
              'service_edition_picker.placeholder.select_service_edition',
          )}
          isLoading={isLoading}
          isClearable
          isSearchable={false}
          noOptionsMessage={getString(
            'service_edition_picker.no_values_msg.service_edition',
          )}
          async
          defaultValues
          onLoadValues={this.onLoadServiceEditions}
          onChange={this.onServiceEditionChange}
          onFormatOptionLabel={this.onFormatOptionLabel}
        />
      </div>
    );
  }

  onLoadServiceEditions = async (): Promise<ServiceEdition[]> => {
    this.setState({ isLoading: true });
    try {
      const serviceEditions = (await serviceEditionService.list(
        {},
        null,
        0,
        0,
      )) as any;
      this.setState({ isLoading: false, serviceEditions });
      return serviceEditions;
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false, serviceEditions: null });
    }

    return [];
  };

  onServiceEditionChange = async (value: Options<ServiceEdition>) => {
    if (Array.isArray(value)) {
      return;
    }

    if (!value) {
      this.props.onChange(null);
      return;
    }

    const serviceEdition = value as unknown as ServiceEdition;

    this.props.onChange(serviceEdition);
  };

  onFormatOptionLabel = (
    serviceEdition: ServiceEdition | null,
    meta: FormatOptionLabelMeta<ServiceEdition>,
  ): ReactNode => {
    return (
      <div>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: meta.context === 'menu' ? '1.1rem' : '0.85rem',
          }}
        >
          {serviceEdition?.name}
        </div>
      </div>
    );
  };
}
