/**
 * @file: InspectionTemplateSceneTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionTemplateSceneType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: InspectionTemplateSceneType | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

export const InspectionTemplateSceneTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  const labelColor =
    value && LabelColors.InspectionTemplateSceneType?.[value]
      ? LabelColors.InspectionTemplateSceneType[value]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`inspection_template_scene_type.${value}`} />
    </Badge>
  );
});
