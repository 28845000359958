/**
 * @file: BatchJobStatusOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { BatchJobStatus, Option } from 'model';

export const BatchJobStatusOptions: Array<Option<BatchJobStatus>> = [
  { value: BatchJobStatus.Queued, label: 'batch_job_status.queued' },
  { value: BatchJobStatus.Running, label: 'batch_job_status.running' },
  { value: BatchJobStatus.Error, label: 'batch_job_status.error' },
  { value: BatchJobStatus.Finished, label: 'batch_job_status.finished' },
];

export const BatchJobStatusOptionsWithDefault: Array<
  Option<BatchJobStatus | null>
> = [
  { value: null, label: 'batch_job_status.__default__' },
  { value: BatchJobStatus.Queued, label: 'batch_job_status.queued' },
  { value: BatchJobStatus.Running, label: 'batch_job_status.running' },
  { value: BatchJobStatus.Error, label: 'batch_job_status.error' },
  { value: BatchJobStatus.Finished, label: 'batch_job_status.finished' },
];
