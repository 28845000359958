import { OrgUserPrincipal } from 'app/customers/duck/states';
import React, { useRef , memo, useCallback, useEffect, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { orgUserService } from 'services';
import { AsideRight } from 'shared/components';

export const UserAuthQrcodeAside = memo(
  ({ user, onClose }: { user?: OrgUserPrincipal; onClose?: () => void }) => {
    return (
      <AsideRight
        open={user != null}
        onClose={onClose}
        // width={350}
      >
        <ul
          className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
          style={{ marginBottom: 15 }}
        >
          <li className="nav-item m-tabs__item">
            <a
              className="nav-link m-tabs__link active"
              style={{ fontWeight: 'bold', fontSize: '120%' }}
            >
              <Translate id="org_user.qrcode_auth.title" />
            </a>
          </li>
        </ul>
        {user && <Content user={user} />}
      </AsideRight>
    );
  },
);

type Data = { dataURI: string; ttl: number };

const Content = memo(({ user }: { user: OrgUserPrincipal }) => {
  const [data, setData] = useState<Data>();
  const [expired, setExpired] = useState(false);
  const timer = useRef<any>();
  const refresh = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      timer.current = null;
    }
    orgUserService
      .renderUserAuthQrcode(user.orgId, user.storeId, user.userId)
      .then(res => {
        setData(res);
        setExpired(false);
        timer.current = setTimeout(() => {
          timer.current = null;
          setExpired(true);
        }, (res.ttl - 3) * 1000);
      })
      .catch(err => {
        alert('Error get qrcode: ' + err.message);
      });
  }, [user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (data == null) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '85vh',
      }}
    >
      <div style={{ width: 200, height: 200, position: 'relative' }}>
        <img src={data.dataURI} style={{ width: 200, height: 200 }} />
        {expired ? (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.85)',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.preventDefault();
              refresh();
            }}
          >
            <span style={{ color: 'red' }}>
              <Translate id="org_user.qrcode_auth.expired" />
            </span>
          </div>
        ) : null}
      </div>
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          refresh();
        }}
        style={{ marginTop: '1rem' }}
      >
        <Translate id="org_user.qrcode_auth.refresh_btn" />
      </a>
    </div>
  );
});
