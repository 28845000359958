import { ChangeEvent, FocusEvent, KeyboardEvent, PureComponent } from 'react';
import { getString } from 'shared/components/StringLabel';
import { randstr } from 'utils';
import { FormElementTextArea } from '../types';

interface Props<T> {
  element: FormElementTextArea<T>;
  value: any;
  disabled?: boolean;
  readOnly?: boolean;
  autocomplete?: boolean;
  useUncontrolled?: boolean;
  onGetExtraInfo: (() => any) | undefined | null;
  valueToString?: (value: any) => string;
  valueFromString?: (s: string) => any;
  onChange: (values: Partial<T>) => void;
}

export class FormTextArea<T> extends PureComponent<Props<T>> {
  render() {
    const {
      disabled,
      readOnly,
      autocomplete,
      useUncontrolled,
      element,
      value,
      valueToString,
    } = this.props;
    if (
      (useUncontrolled === true && element.controlled !== true) ||
      (!useUncontrolled && element.controlled === false)
    ) {
      return (
        <textarea
          className="form-control m-input"
          autoComplete={
            autocomplete === false || element.autocomplete === false
              ? 'new-password'
              : undefined
          }
          name={randstr(16)}
          defaultValue={
            value === null || value === undefined
              ? ''
              : valueToString?.(value) ?? value
          }
          placeholder={getString(element.placeholder)}
          disabled={disabled}
          readOnly={readOnly}
          rows={element.rows}
          style={{ width: element.width }}
          onBlur={this.onBlur}
          onKeyDown={element.onKeyDown ? this.onKeyDown : undefined}
          onKeyUp={element.onKeyUp ? this.onKeyUp : undefined}
        />
      );
    }

    return (
      <textarea
        className="form-control m-input"
        autoComplete={
          autocomplete === false || element.autocomplete === false
            ? 'new-password'
            : undefined
        }
        name={randstr(16)}
        defaultValue={
          value === null || value === undefined
            ? ''
            : valueToString?.(value) ?? value
        }
        placeholder={getString(element.placeholder)}
        disabled={disabled}
        readOnly={readOnly}
        rows={element.rows}
        style={{ width: element.width }}
        onChange={this.onChange}
        onKeyDown={element.onKeyDown ? this.onKeyDown : undefined}
        onKeyUp={element.onKeyUp ? this.onKeyUp : undefined}
      />
    );
  }

  handleValueChange(value: any) {
    const { element, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = this.props.valueFromString?.(value) ?? value;
    void element.onChange?.(changes, extra);
    if (this.props.value === changes[element.prop]) return;
    this.props.onChange(changes);
  }

  onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.handleValueChange(e.target.value as any);
  };

  onBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    this.handleValueChange(e.target.value as any);
  };

  onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { element } = this.props;
    element.onKeyDown && element.onKeyDown(e);
  };

  onKeyUp = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const { element } = this.props;
    element.onKeyUp && element.onKeyUp(e);
  };
}
