import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleInspectionTask } from 'model';
import { vehicleInspectionTaskService } from 'services';

const actionCreators = createListAsyncActionCreators<AppState, VehicleInspectionTask>(
  'dashboard.recent_inspection_tasks',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      return await vehicleInspectionTaskService.list(
        state.dashboard.recentInspectionTasks.filter || {},
        state.dashboard.recentInspectionTasks.sorts?.[0],
        state.dashboard.recentInspectionTasks.offset,
        state.dashboard.recentInspectionTasks.limit,
        { count: false, recursive: true }
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentInspectionTasks,
  invalidate: invalidateDashboardRecentInspectionTasks
} = actionCreators;