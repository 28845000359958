import { TransFunction } from 'app';
import { showAppModal } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import { AclContextProps, withAcl } from 'lib/decorators/acl';
import { buildSimpleTreeModel, imgproxy } from 'lib/helpers';
import {
  AclObjectList,
  InspectionSiteType,
  VehicleInspectionSite,
  VehicleInspectionSiteCategory,
  VehicleInspectionSiteCheckItem,
  VehicleInspectionSiteCheckItemOption,
  VehicleInspectionSiteListFilter,
} from 'model';
import { InspectionSiteInventoryImportResult } from 'model/viewmodel/InspectionSiteInventoryImportResult';
import { Component, MouseEvent } from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ActionButtonCls } from 'shared';
import {
  authenticate,
  BreadcrumbItem,
  CommonEntityListProps,
  ConfirmDeleteModal,
  getString,
  InlineSvg,
  InspectionSiteTypeLabel,
  Page,
} from 'shared/components';
import { Column, DataListGroup, DataTable } from 'shared/metronic/components';
import { arr2map, loadAsyncList } from 'utils';
import { getCategoryNodeDecendants } from '../common/helpers';
import {
  batchSetSitesModule,
  batchSetSitesType,
  importInspectionSiteInventoryFailed,
  importInspectionSiteInventorySuccess,
  inspectionSiteActions,
  inspectionSiteCategoryActions,
  inspectionSiteItemActions,
  inspectionSiteItemOptionActions,
  inspectionToolActions,
  inventoryManagerSetNewSiteTargetCategoryId,
  requestBatchSetSitesModule,
  requestBatchSetSitesType,
  startImportingInspectionSiteInventory,
  vehicleSystemModuleActions,
} from '../duck/actions';
import {
  InspectionSiteCategories,
  InspectionSiteInventoryUIState,
  InspectionSiteItemOptions,
  InspectionSiteItems,
  InspectionSites,
  InspectionTools,
  VehicleSystemModules,
} from '../duck/states';
import { ItemEditorV2 } from './editors-v2/ItemEditor';
import { OptionEditorV2 } from './editors-v2/OptionEditor';
import { SiteEditorV2 } from './editors-v2/SiteEditor';
import { SiteDetail } from './SiteDetail';
import { InspectionSiteToolbarV2 } from './Toolbar-v2';

type SiteListColumn = Column<VehicleInspectionSite>;

import { inventoryImportResultToFormatData } from 'app/inspection/inventory/helper';
import { SetSiteSystemModuleModal } from 'app/inspection/inventory/SetSystemModuleModal';
import { SetSiteTypeModal } from 'app/inspection/inventory/SetTypeModal';
import './index.scss';

export interface InventoryManagerProps
  extends CommonEntityListProps,
    LocalizeContextProps,
    AclContextProps {
  categories: InspectionSiteCategories;
  sites: InspectionSites;
  items: InspectionSiteItems;
  options: InspectionSiteItemOptions;
  uiState: InspectionSiteInventoryUIState;
  filter: VehicleInspectionSiteListFilter;
  tools: InspectionTools;
  systemModules: VehicleSystemModules;
  activeGroupKey: string;
  activeCategoryId: number | null | undefined;
}

@withAcl()
class InventoryManagerImpl extends Component<InventoryManagerProps> {
  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.inventory_manager" /> },
  ];

  lastI18nPrefixForDeleteConfirmModal: string | undefined;

  componentDidMount() {
    const {
      dispatch,
      categories,
      sites,
      items,
      options,
      tools,
      systemModules,
    } = this.props;
    loadAsyncList(categories, () =>
      dispatch(inspectionSiteCategoryActions.fetch()),
    );
    loadAsyncList(sites, () => dispatch(inspectionSiteActions.fetch()));
    loadAsyncList(items, () => dispatch(inspectionSiteItemActions.fetch()));
    loadAsyncList(options, () =>
      dispatch(inspectionSiteItemOptionActions.fetch()),
    );
    loadAsyncList(tools, () => dispatch(inspectionToolActions.fetch()));
    loadAsyncList(systemModules, () =>
      dispatch(vehicleSystemModuleActions.fetch()),
    );
  }

  render() {
    const { trans, sites } = this.props;
    return (
      <Page
        title={trans('inspection_site.manager.title')}
        breadcrumbs={this.breadcrumbs}
        fullAccessRight={AclObjectList.VehicleInspectionSiteFullAccess}
        readonlyAccessRight={AclObjectList.VehicleInspectionSiteReadonlyAccess}
        error={sites.error}
        onAdd={this.onAdd}
        onRefresh={this.onRefresh}
        compact
      >
        {this.renderToolbar()}
        {this.renderDataList()}
        {this.renderSidebar()}
        {this.renderConfirmDeleteModal()}
        {this.renderSiteTypeModal()}
        {this.renderSiteModuleModal()}
      </Page>
    );
  }

  renderToolbar() {
    const { sites, categories, uiState, activeGroupKey, activeCategoryId } =
      this.props;
    return (
      <InspectionSiteToolbarV2
        selection={sites.selection || []}
        filter={sites.filter || {}}
        activeGroupKey={activeGroupKey}
        activeCategoryId={activeCategoryId}
        categories={categories}
        isImporting={uiState.isImporting || false}
        onImport={this.onImport}
        onImportSuccess={this.onImportSuccess}
        onImportFailed={this.onImportFailed}
        onExpandAll={this.onExpandAll}
        onCollapseAll={this.onCollapseAll}
        onFilterChange={this.onSiteFilterChange}
        onActiveGroupChange={this.onActiveGroupChange}
        onDelete={this.onDeleteSites}
        onBatchSetType={this.onBatchSetType}
        onBatchSetModule={this.onBatchSetModule}
      />
    );
  }

  renderDataList() {
    const { filter, sites, categories } = this.props;
    const data = this.filteredInspectionSites();
    const useGroup = Boolean(categories.result && !filter.categoryIds?.length);
    const groupListKey = this.getListGroupKey();
    const columns = this.buildColumns();
    this.addActionButtons(columns);
    return (
      <DataTable<VehicleInspectionSite, number>
        columns={columns}
        idProp="id"
        selModel="check"
        data={data}
        isLoading={sites.isLoading}
        minHeight={400}
        selection={sites.selection}
        enableItemDetails
        groupBy={useGroup ? this.getSiteGroupValue : undefined}
        groupListKey={groupListKey}
        expandedGroups={sites.expandedGroups?.get(groupListKey)}
        collapsedGroups={sites.collapsedGroups?.get(groupListKey)}
        onFormatGroupHeader={this.formatSiteGroupHeader}
        onRenderListGroupActions={this.renderSiteGroupActions}
        onListGroupExpand={this.onListGroupExpand}
        onListGroupCollapse={this.onListGroupCollapse}
        expandedItemDetailIds={sites.expandedDetailItemIds?.get(groupListKey)}
        collapsedItemDetailIds={sites.collpasedDetailItemIds?.get(groupListKey)}
        onItemDetailExpand={this.onSiteDetailExpand}
        onItemDetailCollapse={this.onSiteDetailCollapse}
        onRenderItemDetail={this.onRenderSiteDetail}
        onToggleAllSelection={this.onToggleAllSelection}
        onItemSelect={this.onSelectChange}
      />
    );
  }

  renderSidebar() {
    const { dispatch, sites, items, options } = this.props;
    const extra = { props: this.props };
    return (
      <>
        <SiteEditorV2
          entities={sites}
          actions={inspectionSiteActions}
          dispatch={dispatch}
          extra={extra}
        />
        <ItemEditorV2
          entities={items}
          actions={inspectionSiteItemActions}
          dispatch={dispatch}
          extra={extra}
        />
        <OptionEditorV2
          entities={options}
          actions={inspectionSiteItemOptionActions}
          dispatch={dispatch}
          extra={extra}
        />
      </>
    );
  }

  renderConfirmDeleteModal() {
    const { sites, items, options } = this.props;
    let i18nPrefix: string = '';
    // eslint-disable-next-line @typescript-eslint/init-declarations
    let itemBeingDeleted: any;
    let isDeleting: boolean | undefined = undefined;
    let lastDeleteError: Error | undefined | null = null;
    let onConfirm: (() => void) | undefined = undefined;
    let onCancel: (() => void) | undefined = undefined;
    if (sites.itemsBeingDeleted?.length) {
      i18nPrefix = 'inspection_site';
      itemBeingDeleted = sites.itemsBeingDeleted[0];
      isDeleting = sites.isDeleting;
      lastDeleteError = sites.lastDeleteError;
      onConfirm = this.onConfirmDeleteSite;
      onCancel = this.onCancelDeleteSite;
    } else if (items.itemsBeingDeleted?.length) {
      i18nPrefix = 'inspection_site_item';
      itemBeingDeleted = items.itemsBeingDeleted[0];
      isDeleting = items.isDeleting;
      lastDeleteError = items.lastDeleteError;
      onConfirm = this.onConfirmDeleteItem;
      onCancel = this.onCancelDeleteItem;
    } else if (options.itemsBeingDeleted?.length) {
      i18nPrefix = 'inspection_site_item_option';
      itemBeingDeleted = options.itemsBeingDeleted[0];
      isDeleting = options.isDeleting;
      lastDeleteError = options.lastDeleteError;
      onConfirm = this.onConfirmDeleteOption;
      onCancel = this.onCancelDeleteOption;
    } else {
      i18nPrefix = this.lastI18nPrefixForDeleteConfirmModal || '';
    }
    this.lastI18nPrefixForDeleteConfirmModal = i18nPrefix;
    return (
      <ConfirmDeleteModal
        localeSegment={i18nPrefix}
        isOpen={Boolean(itemBeingDeleted)}
        isDeleting={isDeleting}
        error={lastDeleteError}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    );
  }

  renderSiteTypeModal() {
    const {
      dispatch,
      sites: {
        siteIdsForBatchSetType,
        isBatchSettingType,
        batchSettingTypeError,
      },
    } = this.props;

    const onConfirm = (type: InspectionSiteType) => {
      dispatch(requestBatchSetSitesType(type));
    };

    const onClose = () => {
      dispatch(batchSetSitesType(undefined));
    };

    return (
      <SetSiteTypeModal
        visible={siteIdsForBatchSetType != null}
        loading={isBatchSettingType}
        error={batchSettingTypeError}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    );
  }

  renderSiteModuleModal() {
    const {
      dispatch,
      sites: {
        siteIdsForBatchSetModule,
        isBatchSettingModule,
        batchSettingModuleError,
      },
    } = this.props;

    const onConfirm = (
      primaryModuleId: number | null | undefined,
      secondaryModuleId: number | null | undefined,
    ) => {
      dispatch(requestBatchSetSitesModule(primaryModuleId, secondaryModuleId));
    };

    const onClose = () => {
      dispatch(batchSetSitesModule(undefined));
    };

    return (
      <SetSiteSystemModuleModal
        systemModules={this.props.systemModules}
        visible={siteIdsForBatchSetModule != null}
        loading={isBatchSettingModule}
        error={batchSettingModuleError}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    );
  }

  onSiteFilterChange = (filter: Partial<VehicleInspectionSiteListFilter>) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.updateFilter(filter));
  };

  onConfirmDeleteSite = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.commitItemsBeingDeleted());
  };

  onCancelDeleteSite = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.cancelItemsBeingDeleted());
  };

  onConfirmDeleteItem = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.commitItemsBeingDeleted());
  };

  onCancelDeleteItem = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.cancelItemsBeingDeleted());
  };

  onConfirmDeleteOption = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.commitItemsBeingDeleted());
  };

  onCancelDeleteOption = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.cancelItemsBeingDeleted());
  };

  onAdd = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.itemBeingCreated({}));
  };

  onAddUnderGroup = (group: DataListGroup<VehicleInspectionSite>) => {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch, categories } = this.props;
      const categoryId = group.groupValue as number;
      const category = categories.result!.find(x => x.id === categoryId)!;
      if (!category.parentCategoryId) {
        dispatch(inventoryManagerSetNewSiteTargetCategoryId(categoryId));
        dispatch(inspectionSiteActions.itemBeingCreated({}));
      } else {
        dispatch(inventoryManagerSetNewSiteTargetCategoryId(null));
        dispatch(inspectionSiteActions.itemBeingCreated({ categoryId }));
      }
    };
  };

  onAddItem = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.itemBeingCreated({ siteId: site.id }));
  };

  onAddOption = (
    _site: VehicleInspectionSite,
    item: VehicleInspectionSiteCheckItem,
  ) => {
    const { dispatch } = this.props;
    dispatch(
      inspectionSiteItemOptionActions.itemBeingCreated({
        itemId: item.id,
        lowerInclusive: false,
        upperInclusive: false,
      }),
    );
  };

  onRefresh = () => {
    const { dispatch, categories, sites, items, options, tools } = this.props;
    loadAsyncList(
      categories,
      () => dispatch(inspectionSiteCategoryActions.fetch()),
      true,
    );
    loadAsyncList(sites, () => dispatch(inspectionSiteActions.fetch()), true);
    loadAsyncList(
      items,
      () => dispatch(inspectionSiteItemActions.fetch()),
      true,
    );
    loadAsyncList(
      options,
      () => dispatch(inspectionSiteItemOptionActions.fetch()),
      true,
    );
    loadAsyncList(tools, () => dispatch(inspectionToolActions.fetch()), true);
  };

  onExpandAll = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.expandAllListGroup());
  };

  onCollapseAll = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.collapseAllListGroup());
  };

  onActiveGroupChange = (key: string, categoryId?: number | null) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.activeListGroupKeyChanged(key, categoryId));
  };

  onListGroupExpand = (value: any) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.expandListGroup(value));
  };

  onListGroupCollapse = (value: any) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.collapseListGroup(value));
  };

  onSiteDetailExpand = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    const key = this.getListGroupKey();
    dispatch(inspectionSiteActions.expandItemDetail(site, key));
  };

  onSiteDetailCollapse = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    const key = this.getListGroupKey();
    dispatch(inspectionSiteActions.collapseItemDetail(site, key));
  };

  onRenderSiteDetail = (site: VehicleInspectionSite) => {
    const { items, options, tools, translate, $has } = this.props;
    return (
      <SiteDetail
        site={site}
        items={items}
        options={options}
        tools={tools}
        translate={translate}
        hasSiteFullAccessRight={$has(
          AclObjectList.VehicleInspectionSiteFullAccess,
        )}
        hasItemFullAccessRight={$has(
          AclObjectList.VehicleInspectionSiteCheckItemFullAccess,
        )}
        hasOptionFullAccessRight={$has(
          AclObjectList.VehicleInspectionSiteCheckItemOptionFullAccess,
        )}
        onAddItem={this.onAddItem}
        onAddOption={this.onAddOption}
        onEditItem={this.onEditInspectionSiteItem}
        onDeleteItem={this.onDeleteInspectionSiteItem}
        onEditOption={this.onEditInspectionSiteItemOption}
        onDeleteOption={this.onDeleteInspectionSiteItemOption}
      />
    );
  };

  onToggleAllSelection = () => {
    const { dispatch } = this.props;
    dispatch(
      inspectionSiteActions.toggleAllSelection(() => {
        return this.filteredInspectionSites() || [];
      }),
    );
  };

  onSelectChange = (site: VehicleInspectionSite, selected: boolean) => {
    const { dispatch } = this.props;
    if (selected) {
      dispatch(inspectionSiteActions.itemSelected(site));
    } else {
      dispatch(inspectionSiteActions.itemDeselected(site));
    }
  };

  onEditInspectionSite = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.itemBeingUpdated(site));
  };

  onDeleteInspectionSite = (site: VehicleInspectionSite) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteActions.itemsBeingDeleted([site]));
  };

  onDeleteSites = () => {
    const { dispatch } = this.props;
    const set = new Set(this.props.sites.selection!);
    const sites = this.props.sites.result!.filter(x => set.has(x.id));
    dispatch(inspectionSiteActions.itemsBeingDeleted(sites));
  };

  onBatchSetType = () => {
    const { dispatch } = this.props;
    dispatch(batchSetSitesType(this.props.sites.selection!));
  };

  onBatchSetModule = () => {
    const { dispatch } = this.props;
    dispatch(batchSetSitesModule(this.props.sites.selection!));
  };

  onEditInspectionSiteItem = (item: VehicleInspectionSiteCheckItem) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.itemBeingUpdated(item));
  };

  onDeleteInspectionSiteItem = (item: VehicleInspectionSiteCheckItem) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemActions.itemsBeingDeleted([item]));
  };

  onEditInspectionSiteItemOption = (
    option: VehicleInspectionSiteCheckItemOption,
  ) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.itemBeingUpdated(option));
  };

  onDeleteInspectionSiteItemOption = (
    option: VehicleInspectionSiteCheckItemOption,
  ) => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteItemOptionActions.itemsBeingDeleted([option]));
  };

  onImport = () => {
    const { dispatch } = this.props;
    dispatch(startImportingInspectionSiteInventory());
  };

  onImportSuccess = (result: InspectionSiteInventoryImportResult) => {
    const { dispatch } = this.props;
    const formatData = inventoryImportResultToFormatData(result);
    dispatch(importInspectionSiteInventorySuccess());
    dispatch(
      showAppModal(
        'inspection_site.import_sites.success_title',
        getString('inspection_site.import_sites.success_msg', formatData),
      ),
    );
    this.onRefresh();
  };

  onImportFailed = (error: Error) => {
    const { dispatch } = this.props;
    console.log(error);
    dispatch(importInspectionSiteInventoryFailed());
    dispatch(
      showAppModal(
        'inspection_site.import_sites.error_title',
        getString('inspection_site.import_sites.error_msg', {
          errorMsg: error.message,
        }),
      ),
    );
  };

  filteredInspectionSites() {
    const { sites, filter, activeGroupKey, activeCategoryId, categories } =
      this.props;
    const list = sites.result;
    if (!list) return list;
    const filters: Array<(site: VehicleInspectionSite) => boolean> = [];
    if (activeGroupKey.startsWith('category')) {
      const tree = buildSimpleTreeModel(
        categories.result!,
        x => x.id,
        x => x.parentCategoryId,
      );
      const node = tree.find(x => x.data.id === activeCategoryId!)!;
      const decendants = getCategoryNodeDecendants(node);
      const set = new Set(decendants.map(x => x.data.id));
      filters.push(site => {
        return set.has(site.categoryId);
      });
    } else if (activeGroupKey === 'hardware') {
      filters.push(site => site.supportsIdevice);
    }

    const keyword = filter.keyword?.trim();
    if (keyword) {
      filters.push(site => {
        return (
          site.name.includes(keyword) ||
          Boolean(site.pyInitial && site.pyInitial.includes(keyword))
        );
      });
    }

    if (!filters.length) return list;

    return list.filter(site => filters.every(x => x(site)));
  }

  getSiteGroupValue = (site: VehicleInspectionSite) => {
    const { categories, activeGroupKey, activeCategoryId } = this.props;
    // const list = activeGroupKey === 'category' ?
    //   categories.result!.filter(x => x.parentCategoryId === activeCategoryId!) :
    //   categories.result!.filter(x => !x.parentCategoryId);
    const map = arr2map(categories.result!, x => x.id);
    let category: VehicleInspectionSiteCategory | null | undefined =
      map[site.categoryId];
    const targetCategoryId = activeGroupKey.startsWith('category')
      ? activeCategoryId
      : null;
    while (
      category &&
      (category.parentCategoryId || null) !== targetCategoryId
    ) {
      category =
        (category.parentCategoryId && map[category.parentCategoryId]) || null;
    }
    return category ? category.id : 0;
  };

  onGroupCheckBoxClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const groupValue = Number(e.currentTarget.getAttribute('data-group-value'));
    const { sites, dispatch, activeGroupKey, categories } = this.props;
    const { selection } = sites;

    if (selection != null && selection?.length > 0) {
      dispatch(inspectionSiteActions.updateSelection([]));
    } else {
      if (categories.result == null || sites.result == null) return;

      const targetCategory = categories.result?.find(x => x.id === groupValue);
      if (!targetCategory) return;

      // select the sites in this group.
      if (activeGroupKey.startsWith('category')) {
        // group value is child categogory id.
        const targetSites = sites.result?.filter(
          x => x.categoryId === targetCategory.id,
        );
        dispatch(inspectionSiteActions.updateSelection(targetSites ?? []));
      } else {
        // group value is primary categogory id.
        const childCategoryIds = new Set(
          categories.result
            .filter(x => x.parentCategoryId === targetCategory.id)
            .map(x => x.id),
        );
        const targetSites = sites.result?.filter(x =>
          childCategoryIds.has(x.categoryId),
        );
        dispatch(inspectionSiteActions.updateSelection(targetSites ?? []));
      }
    }
  };

  formatSiteGroupHeader = (group: DataListGroup<VehicleInspectionSite>) => {
    const { categories, activeGroupKey, activeCategoryId, trans } = this.props;
    let label: string = '';
    if (group.groupValue === 0) {
      label = trans('inspection_site.unknown_group_header_text');
    } else {
      if (activeGroupKey.startsWith('category')) {
        const children = this.props.categories.result!.filter(
          x => x.parentCategoryId === activeCategoryId!,
        );
        label = children.find(x => x.id === group.groupValue)!.name;
      } else {
        label = categories
          .result!.filter(x => !x.parentCategoryId)
          .find(x => x.id === group.groupValue)!.name;
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexWrap: 'nowrap',
          flexDirection: 'row',
        }}
      >
        <span>{`${label} (${group.items.length})`}</span>
        <a
          href="#"
          data-group-value={group.groupValue}
          onClick={this.onGroupCheckBoxClick}
          style={{
            color: '#666',
            marginLeft: 10,
            fontSize: '0.85rem',
          }}
        >
          全选/取消全选
        </a>
      </div>
    );
  };

  renderSiteGroupActions = (group: DataListGroup<VehicleInspectionSite>) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <a href="#" onClick={this.onAddUnderGroup(group)}>
          <i className="la la-plus" />
          <Translate id="inspection_site.node_decoration.add_site" />
        </a>
      </div>
    );
  };

  getListGroupKey() {
    const { activeGroupKey } = this.props;
    return activeGroupKey;
  }

  buildColumns(): SiteListColumn[] {
    const { trans } = this.props;
    return [
      {
        prop: 'iconUrl',
        text: trans('inspection_site.col.icon'),
        width: 40,
        align: 'center',
        render: site => {
          if (!site.iconUrl) {
            return (
              <InlineSvg
                className="inspection-site-icon"
                src="img/default-site-icon.svg"
              />
            );
          }
          const url = new URL(site.iconUrl);
          if (/\.svg$/i.exec(url.pathname)) {
            return (
              <InlineSvg
                className="inspection-site-icon"
                src={imgproxy(site.iconUrl)}
              />
            );
          }
          return (
            <span className="inspection-site-icon">
              <img src={site.iconUrl} />
            </span>
          );
        },
      },
      {
        prop: 'source',
        text: trans('inspection_site.col.source'),
        width: 50,
        align: 'center',
        render: site => {
          const source = site.storeId ? 'store' : site.orgId ? 'org' : 'system';
          return <Translate id={`inspection_site.source.${source}`} />;
        },
      },
      {
        prop: 'type',
        text: trans('inspection_site.col.type'),
        width: 50,
        align: 'center',
        render: site => {
          return <InspectionSiteTypeLabel value={site.type} />;
        },
      },
      {
        prop: 'name',
        text: trans('inspection_site.col.name'),
        width: 80,
      },
      {
        prop: 'code',
        text: trans('inspection_site.col.code'),
        width: 80,
      },
      {
        prop: 'pyInitial',
        text: trans('inspection_site.col.py_initial'),
        width: 50,
      },
      {
        prop: 'primarySysModId',
        text: trans('inspection_site.col.system_module'),
        width: 100,
        render: ({ primarySysModId, secondarySysModId }) => {
          if (this.props.systemModules.result == null) {
            return '-';
          }
          const [primaryMod, secondaryMod] = [
            this.props.systemModules.result.find(x => x.id === primarySysModId),
            this.props.systemModules.result.find(
              x => x.id === secondarySysModId,
            ),
          ];
          if (primaryMod == null) return '-';
          return [primaryMod.name, secondaryMod?.name].filter(x => x).join('/');
        },
      },
      {
        prop: 'influenceFactor',
        text: trans('inspection_site.col.influence_factor'),
        width: 50,
        align: 'center',
      },
      {
        prop: 'positionCode',
        text: trans('inspection_site.col.position_code'),
        width: 60,
        align: 'center',
        render: ({ positionCode }) => positionCode || '/',
      },
      {
        prop: 'description',
        text: trans('inspection_site.col.description'),
        width: 300,
      },
      {
        prop: 'nextCheckMileageInterval',
        text: trans('inspection_site.col.next_check_mileage_interval'),
        width: 80,
        align: 'center',
        render: site => {
          return site.nextCheckMileageInterval || '/';
        },
      },
      {
        prop: 'supportsIdevice',
        text: trans('inspection_site.col.supports_idevice'),
        width: 50,
        align: 'center',
        render: site => {
          return site.supportsIdevice ? (
            <i
              className="la la-check-circle m--font-success"
              style={{ fontWeight: 'normal', fontSize: '2rem' }}
            />
          ) : (
            '/'
          );
        },
      },
      {
        prop: 'ideviceSortOrder',
        text: trans('inspection_site.col.idevice_sort_order'),
        width: 80,
        align: 'center',
      },
    ];
  }

  addActionButtons(columns: SiteListColumn[]) {
    const fullAccessRight = AclObjectList.VehicleInspectionSiteFullAccess;
    if (!this.props.$has(fullAccessRight)) {
      return;
    }
    columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: 80,
      render: (site: VehicleInspectionSite) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.onEditInspectionSite(site);
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          this.onDeleteInspectionSite(site);
        };

        return (
          <>
            <button className={ActionButtonCls} onClick={onEdit}>
              <i className="la la-edit" />
            </button>
            <button className={ActionButtonCls} onClick={onDelete}>
              <i className="la la-trash" />
            </button>
          </>
        );
      },
    });
  }
}

function mapStateToProps(state: AppState) {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    categories: state.inspection.categories,
    systemModules: state.inspection.vehicleSystemModules,
    sites: state.inspection.sites,
    items: state.inspection.items,
    options: state.inspection.options,
    uiState: state.inspection.uiState,
    filter: state.inspection.sites.filter,
    tools: state.inspection.tools,
    activeGroupKey: state.inspection.sites.activeGroupKey,
    activeCategoryId: state.inspection.sites.activeGroupContext,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

export const InventoryManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<InventoryManagerProps, InventoryManagerImpl>(
    withLocalize<InventoryManagerProps>(InventoryManagerImpl) as any,
  ),
);
