/**
 * @file: AutoPartNameNgramService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  AutoPartNameNgram,
  AutoPartNameNgramListFilter,
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class AutoPartNameNgramServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<AutoPartNameNgramListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<AutoPartNameNgram>
      : AutoPartNameNgram[]
  > {
    return await this.api
      .get()
      .url(
        '/auto-part-name-ngrams',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(unit: number): Promise<AutoPartNameNgram | null> {
    return await this.api
      .get()
      .url('/auto-part-name-ngrams/:unit', { unit })
      .future();
  }

  async create(
    autoPartNameNgram: Partial<AutoPartNameNgram>,
  ): Promise<AutoPartNameNgram> {
    return await this.api
      .post()
      .url('/auto-part-name-ngrams')
      .data(autoPartNameNgram)
      .future();
  }

  async update(
    autoPartNameNgram: Partial<AutoPartNameNgram>,
  ): Promise<AutoPartNameNgram> {
    return await this.api
      .put()
      .url('/auto-part-name-ngrams/:unit', { unit: autoPartNameNgram.unit })
      .data(autoPartNameNgram)
      .future();
  }

  async delete(unit: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/auto-part-name-ngrams/:unit', { unit })
      .future();
  }
}
