import { StatsStateByTime, StatAspect } from './duck/states';
import { AppState } from 'app/duck/states';
import { ActionThunk, StandardAction } from 'lib/duck/interfaces';
import { DatePartitionType, SummaryStats } from 'model';
import React, { ReactNode } from 'react';

import {
  fetchOrgStatsByTime,
  invalidateOrgStatsByTime,
  fetchStoreStatsByTime,
  invalidateStoreStatsByTime,
  fetchUserStatsByTime,
  invalidateUserStatsByTime,
  fetchVehicleInfoStatsByTime,
  invalidateVehicleInfoStatsByTime,
  fetchReceptionOrderStatsByTime,
  invalidateReceptionOrderStatsByTime,
  fetchInspectionTaskStatsByTime,
  invalidateInspectionTaskStatsByTime,
  fetchInspectionReportStatsByTime,
  invalidateInspectionReportStatsByTime,
  fetchInspectionCommentStatsByTime,
  invalidateInspectionCommentStatsByTime
} from './duck/actions';

export interface StatsByTimeProps {
  orgStatsByTime: StatsStateByTime;
  storeStatsByTime: StatsStateByTime;
  userStatsByTime: StatsStateByTime;
  receptionOrderStatsByTime: StatsStateByTime;
  inspectionTaskStatsByTime: StatsStateByTime;
  vehicleStatsByTime: StatsStateByTime;
  inspectionReportStatsByTime: StatsStateByTime;
  inspectionCommentStatsByTime: StatsStateByTime;
}

export interface StatAspectConfig {
  disabled?: boolean;
  statAspect: StatAspect;
  getStatsByTime(props: StatsByTimeProps): StatsStateByTime;
  fetchAction(partition: DatePartitionType): ActionThunk<AppState>;
  invalidateAction(partition: DatePartitionType): ActionThunk<AppState> | StandardAction<any>;
  value(stats: SummaryStats): number;
  format(value: number): ReactNode | string;
}

export const StatAspectsConfigurations: StatAspectConfig[] = [{
  disabled: true,
  statAspect: StatAspect.Organization,
  getStatsByTime: props => props.orgStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchOrgStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateOrgStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalOrgCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}, {
  statAspect: StatAspect.Store,
  getStatsByTime: props => props.storeStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchStoreStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateStoreStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalStoreCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}, {
  statAspect: StatAspect.User,
  getStatsByTime: props => props.userStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchUserStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateUserStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalUserCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}, {
  statAspect: StatAspect.VehicleInfo,
  getStatsByTime: props => props.vehicleStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchVehicleInfoStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateVehicleInfoStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalVehicleCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}, {
  statAspect: StatAspect.ReceptionOrder,
  getStatsByTime: props => props.receptionOrderStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchReceptionOrderStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateReceptionOrderStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalReceptionOrderCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}, {
  statAspect: StatAspect.InspectionTask,
  getStatsByTime: props => props.inspectionTaskStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchInspectionTaskStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateInspectionTaskStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalTaskCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}, {
  statAspect: StatAspect.InspectionReport,
  getStatsByTime: props => props.inspectionReportStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchInspectionReportStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateInspectionReportStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalReportCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}, {
  statAspect: StatAspect.InspectionComment,
  getStatsByTime: props => props.inspectionCommentStatsByTime,
  fetchAction: (partition: DatePartitionType) => fetchInspectionCommentStatsByTime(partition),
  invalidateAction: (partition: DatePartitionType) => invalidateInspectionCommentStatsByTime(partition),
  value: (stats: SummaryStats) => stats.totalCommentCount,
  format: (value: number) => <span className="m--font-brand">{value}</span>
}];

const map = new Map<StatAspect, StatAspectConfig>();
for (const configuration of StatAspectsConfigurations) {
  map.set(configuration.statAspect, configuration);
}

export function getStatAspectConfig(statAspect: StatAspect): StatAspectConfig {
  return map.get(statAspect)!;
}

export function getStatsByTimeByStatAspect(
  props: StatsByTimeProps, statAspect: StatAspect
): StatsStateByTime {
  const config = map.get(statAspect)!;
  return config.getStatsByTime(props);
}

export function fetchStatAspectStatsByTime(
  props: StatsByTimeProps, statAspect: StatAspect
): ActionThunk<AppState> {
  const config = map.get(statAspect)!;
  const statsByTime = config.getStatsByTime(props);
  return config.fetchAction(statsByTime.partition);
}

export function invalidateStatAspectStatsByTime(
  props: StatsByTimeProps, statAspect: StatAspect
): StandardAction<any> | ActionThunk<AppState> {
  const config = map.get(statAspect)!;
  const statsByTime = config.getStatsByTime(props);
  return config.invalidateAction(statsByTime.partition);
}