import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app.settings', {
  UpdateActionLogFilters: '',
  SetCurrentAdminUserAcl: '',
  ImportAutoPartNames: '',
  ImportAutoPartNamesSuccess: '',
  ImportAutoPartNamesFailed: '',
  ImportQuotationPartCatalogs: '',
  ImportQuotationPartCatalogsSuccess: '',
  ImportQuotationPartCatalogsFailed: '',
  ImportProductBrands: '',
  ImportProductBrandsSuccess: '',
  ImportProductBrandsFailed: '',
});
