/**
 * @file: OrgUserWeixinBindingService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OrgUserWeixinBindingServiceBase } from './base/OrgUserWeixinBindingService';

export class OrgUserWeixinBindingService extends OrgUserWeixinBindingServiceBase {
  // add custom service implementation here
}
