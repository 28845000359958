/**
 * @file: VehicleInspectionTaskCheckSiteItemDataService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskCheckSiteItemData,
  VehicleInspectionTaskCheckSiteItemDataListFilter,
} from 'model';

export class VehicleInspectionTaskCheckSiteItemDataServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskCheckSiteItemDataListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskCheckSiteItemData>
      : VehicleInspectionTaskCheckSiteItemData[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-check-site-item-data',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(
    id: number,
  ): Promise<VehicleInspectionTaskCheckSiteItemData | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-check-site-item-data/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionTaskCheckSiteItemData: Partial<VehicleInspectionTaskCheckSiteItemData>,
  ): Promise<VehicleInspectionTaskCheckSiteItemData> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-check-site-item-data')
      .data(vehicleInspectionTaskCheckSiteItemData)
      .future();
  }

  async update(
    vehicleInspectionTaskCheckSiteItemData: Partial<VehicleInspectionTaskCheckSiteItemData>,
  ): Promise<VehicleInspectionTaskCheckSiteItemData> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-check-site-item-data/:id', {
        id: vehicleInspectionTaskCheckSiteItemData.id,
      })
      .data(vehicleInspectionTaskCheckSiteItemData)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-check-site-item-data/:id', { id })
      .future();
  }
}
