/**
 * @file: org-users.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { OrgUser, OrgUserListFilter } from 'model';
import { orgUserService } from 'services';
import { sha256 } from 'utils';
import { ActionTypes } from '../types';
import { OrgUserPrincipal } from 'app/customers/duck/states';

export const orgUserActions = createListAsyncActionCreators<
  AppState,
  OrgUser,
  OrgUserListFilter,
  number
>('customers.org_users', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,

  getItemBeingUpdated(state: AppState) {
    return state.customers.orgUsers.itemBeingUpdated;
  },

  async fetchHandler(state: AppState) {
    return await orgUserService.list(
      state.customers.orgUsers.filter,
      state.customers.orgUsers.sorts?.[0],
      state.customers.orgUsers.offset,
      state.customers.orgUsers.limit,
      { count: true, recursive: true },
    );
  },

  async update(orgUser: OrgUser) {
    const password = orgUser.password?.trim();
    if (!password) return orgUser;
    orgUser.password = sha256(password)!;
    const res = await orgUserService.update(orgUser);
    res.password = '';
    return res;
  },
});

export function setCurrentUserQrcodeAuth(user: OrgUserPrincipal | undefined) {
  return createStandardAction(ActionTypes.OrgUserQrcodeAuth, user);
}
