/**
 * @file: PaymentAppType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum PaymentAppType {
  Mobile = 'mobile',
}

export const PaymentAppTypeValueSet = new Set([PaymentAppType.Mobile]);
