/**
 * @file: autoPartNames.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { AutoPartName } from 'model';
import { AutoPartNames } from '../states';
import { ActionTypes } from '../types';

const initialState: AutoPartNames = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
  isImporting: false,
};

const defaultReducer = createAsyncListActionReducers<
  AutoPartName,
  AutoPartNames
>('app.settings.auto_part_names', initialState, {
  mapItemKey: x => x.id,
});

export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AutoPartNames = initialState,
  action: StandardAction<any>,
): AutoPartNames {
  switch (action.type) {
    case ActionTypes.ImportAutoPartNames:
      return { ...state, isImporting: true, importError: undefined };
    case ActionTypes.ImportAutoPartNamesSuccess: {
      return { ...state, isImporting: false, importError: undefined };
    }
    case ActionTypes.ImportAutoPartNamesFailed: {
      return { ...state, isImporting: false, importError: action.payload };
    }
    default:
      return defaultReducer(state, action);
  }
}
