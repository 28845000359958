import { AppState } from 'app/duck/states';
import {
  AsyncListState,
  EntityListActions,
  ObjectKeyType,
} from 'lib/duck/interfaces';
import React, { Component, ReactNode } from 'react';
import { Column, DataListGroup, EntityNode } from 'shared/metronic/components';
import { EntityEditorFormBuilder } from '../EntityEditorForm/builder';
import { ToolbarItemsBuilder } from '../ListToolbar';
import { BreadcrumbItem } from '../Page';
import { entityListComponentClassFactory } from './factory';
import {
  ActionButtonSpecifiers,
  EntityListFeatures,
  EntityListProps,
  ShouldDisableActionButtonPredict,
} from './types';

export class EntityListComponentClassBuilder<
  T extends object,
  TFilter,
  TKey extends ObjectKeyType,
  TProps extends EntityListProps<T, TFilter, TKey, TState>,
  TState = any,
> {
  private readonly props: TProps = {} as any;
  private onMapStateToProps?: (state: AppState) => Partial<TProps>;
  private onComponentDidMount?: (state: AppState, props: TProps) => void;
  private onComponentDidUpdate?: (props: TProps, prevProps: TProps) => void;
  private entitiesGetter?: (
    state: AppState,
  ) => AsyncListState<T, TFilter, TKey>;
  private shouldFetchAreas = false;

  withInitialState(state: TState): this {
    this.props.initialState = state;
    return this;
  }

  pageTitle(value: string): this {
    this.props.pageTitle = value;
    return this;
  }

  accessRights(rights: { full: string; readonly: string }): this {
    this.props.fullAccessRight = rights.full;
    this.props.readonlyAccessRight = rights.readonly;
    return this;
  }

  idProp(value: keyof T & ObjectKeyType): this {
    this.props.idProp = value;
    return this;
  }

  filter(filter: (items: T[], props: TProps) => T[]): this {
    this.props.onFilter = filter;
    return this;
  }

  entities(
    entitiesGetter: (state: AppState) => AsyncListState<T, TFilter, TKey>,
  ): this {
    this.entitiesGetter = entitiesGetter;
    return this;
  }

  loadStateExternally(): this {
    this.props.loadStateExternally = true;
    return this;
  }

  treeBuilder(
    builder: (entities: T[], extra: any) => Array<EntityNode<T>>,
  ): this {
    this.props.treeBuilder = builder;
    return this;
  }

  i18nPrefix(value: string): this {
    this.props.i18nPrefix = value;
    if (!this.props.pageTitle) {
      this.props.pageTitle = `${value}.page.title`;
    }
    return this;
  }

  breadcrumbs(items: BreadcrumbItem[]): this {
    this.props.breadcrumbs = items;
    return this;
  }

  toolbar(value: () => ReactNode | null): this {
    this.props.toolbar = value;
    return this;
  }

  toolbarItems(build: (builder: ToolbarItemsBuilder<TFilter>) => void): this {
    const builder = new ToolbarItemsBuilder<TFilter>();
    build(builder);
    this.props.toolbarItems = builder.build();
    return this;
  }

  actions(actions: EntityListActions<AppState, T, TFilter>): this {
    this.props.actions = actions;
    return this;
  }

  shouldDisableActionButton(
    shouldDisableActionButton?: ShouldDisableActionButtonPredict<T>,
  ): this {
    this.props.shouldDisableActionButton = shouldDisableActionButton;
    return this;
  }

  editor(build: (builder: EntityEditorFormBuilder<T>) => void): this {
    const builder = new EntityEditorFormBuilder<T>();
    build(builder);
    const { elements, autocomplete, useUncontrolled } = builder.build();
    this.props.editorFormElements = elements;
    this.props.editorFormAutocompletion = autocomplete;
    this.props.editorFormUseUncontrolled = useUncontrolled;
    return this;
  }

  columns(columns: Array<Column<T>>): this {
    this.props.columns = columns;
    return this;
  }

  addActionButtons(actionButtons: ActionButtonSpecifiers<T>): this {
    this.props.actionButtons = actionButtons;
    return this;
  }

  noPaging(): this {
    this.props.noPaging = true;
    return this;
  }

  onAdd(callback: (entity: Partial<T>, extra: any) => void): this {
    this.props.onAdd = callback as any;
    return this;
  }

  onEdit(callback: (entity: T, extra: any) => T): this {
    this.props.onEdit = callback;
    return this;
  }

  canEdit(callback: (entity: T, extra: any) => boolean): this {
    this.props.canEdit = callback;
    return this;
  }

  canDelete(callback: (entity: T, extra: any) => boolean): this {
    this.props.canDelete = callback;
    return this;
  }

  onRender(
    callback: (
      props: TProps,
      component: Component<TProps, TState>,
    ) => ReactNode,
  ): this {
    this.props.onRender = callback;
    return this;
  }

  validate(
    validator: (entity: T | Partial<T>, isCreating: boolean) => void,
  ): this {
    this.props.onValidate = validator;
    return this;
  }

  mapStateToProps(mapStateToProps: (state: AppState) => Partial<TProps>): this {
    this.onMapStateToProps = mapStateToProps;
    return this;
  }

  componentDidMount(callback: (state: AppState, props: TProps) => void): this {
    this.onComponentDidMount = callback;
    return this;
  }

  componentDidUpdate(
    callback: (props: TProps, prevProps: TProps) => void,
  ): this {
    this.onComponentDidUpdate = callback;
    return this;
  }

  requireAreas(): this {
    this.shouldFetchAreas = true;
    return this;
  }

  features(features: EntityListFeatures): this {
    this.props.features = features;
    return this;
  }

  withEmptyCellPlaceholder(
    emptyCellPlaceholder: ReactNode | ((item: T, extra?: any) => ReactNode),
  ): this {
    this.props.emptyCellPlaceholder = emptyCellPlaceholder;
    return this;
  }

  withGroupListKey(key?: any): this {
    this.props.groupListKey = key;
    return this;
  }

  groupBy(groupBy: keyof T | ((item: T, extra?: any) => any)): this {
    this.props.groupBy = groupBy;
    return this;
  }

  onFormatGroupHeader(
    onFormatGroupHeader?: (
      group: DataListGroup<T>,
      key?: any,
      extra?: any,
    ) => string | ReactNode,
  ): this {
    this.props.onFormatGroupHeader = onFormatGroupHeader;
    return this;
  }

  onListGroupExpand(
    onListGroupExpand?: (value: any, key?: any, extra?: any) => void,
  ): this {
    this.props.onListGroupExpand = onListGroupExpand;
    return this;
  }
  onListGroupCollapse(
    onListGroupCollapse?: (value: any, key?: any, extra?: any) => void,
  ): this {
    this.props.onListGroupCollapse = onListGroupCollapse;
    return this;
  }

  onRenderListGroupActions(
    onRenderListGroupActions?: (
      group: DataListGroup<T>,
      key?: any,
      extra?: any,
    ) => ReactNode,
  ): this {
    this.props.onRenderListGroupActions = onRenderListGroupActions;
    return this;
  }

  getClass(): React.ComponentType<TProps> {
    if (!this.entitiesGetter) {
      throw new Error('no entities getter specified');
    }
    return entityListComponentClassFactory<T, TFilter, TKey, TProps>({
      shouldFetchAreas: this.shouldFetchAreas,
      mapStateToProps: (state: AppState) => ({
        // @ts-ignore
        entities: this.entitiesGetter!(state),
        ...this.props,
        ...(this.onMapStateToProps ? this.onMapStateToProps(state) : {}),
      }),
      componentDidMount: (state: AppState, props: TProps) => {
        this.onComponentDidMount && this.onComponentDidMount(state, props);
      },
      componentDidUpdate: (props: TProps, prevProps: TProps) => {
        this.onComponentDidUpdate &&
          this.onComponentDidUpdate(props, prevProps);
      },
    });
  }
}
