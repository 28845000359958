/**
 * @file: ServiceEditionService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  ServiceEdition,
  ServiceEditionListFilter,
  SortInfo,
} from 'model';

export class ServiceEditionServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<ServiceEditionListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<ServiceEdition>
      : ServiceEdition[]
  > {
    return await this.api
      .get()
      .url(
        '/service-editions',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<ServiceEdition | null> {
    return await this.api.get().url('/service-editions/:id', { id }).future();
  }

  async getEditionBySku(sku: string): Promise<ServiceEdition | null> {
    if (!sku) {
      throw new Error('missing sku parameter');
    }
    return await this.api
      .get()
      .url('/service-editions/query/sku', { sku })
      .future();
  }

  async create(
    serviceEdition: Partial<ServiceEdition>,
  ): Promise<ServiceEdition> {
    return await this.api
      .post()
      .url('/service-editions')
      .data(serviceEdition)
      .future();
  }

  async update(
    serviceEdition: Partial<ServiceEdition>,
  ): Promise<ServiceEdition> {
    return await this.api
      .put()
      .url('/service-editions/:id', { id: serviceEdition.id })
      .data(serviceEdition)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/service-editions/:id', { id })
      .future();
  }
}
