/**
 * @file: BatchJobStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum BatchJobStatus {
  Queued = 'queued',
  Running = 'running',
  Error = 'error',
  Finished = 'finished',
}

export const BatchJobStatusValueSet = new Set([
  BatchJobStatus.Queued,
  BatchJobStatus.Running,
  BatchJobStatus.Error,
  BatchJobStatus.Finished,
]);
