import React, { Component, CSSProperties } from 'react';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { Column, DataTable } from 'shared/metronic/components';
import { RecentOrganizations } from './duck/states';
import { Organization } from 'model';
import { formatTime } from 'utils';
import { Areas } from 'app/duck/states';
import { StringLabel } from 'shared/components';

interface Props extends LocalizeContextProps {
  organizations: RecentOrganizations;
  areas: Areas;
}

export class RecentOrganizationListComponent extends Component<Props> {
  private columns: Array<Column<Organization>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { organizations } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="id"
        selModel="none"
        data={organizations.result}
        isLoading={organizations.isLoading}
        minHeight={0}
        error={organizations.error}
        />
    );
  }

  private init() {
    this.columns = [{
      prop: 'logoImgUrl',
      width: 200,
      text: 'org.col.organization',
      render: ({ logoImgUrl }) =>
        <img
          className="org-info__logo"
          style={{ width: 200 }}
          src={logoImgUrl || '/img/org-placeholder.png'}
          />
    }, {
      prop: 'name',
      width: 200,
      text: 'col.name',
      render: org => {
        return (
          <div className="org-info__detail">
            <p>{org.name}</p>
            <dl>
              <dt><Translate id="org.org_info.label.short_name" /></dt>
              <dd>{org.shortName}</dd>
            </dl>
            <dl>
              <dt><Translate id="org.org_info.label.brand_name" /></dt>
              <dd>{org.brandName}</dd>
            </dl>
            <dl>
              <dt><Translate id="org.org_info.label.is_chain_brand" /></dt>
              <dd><Translate id={`org.is_chain_brand.${org.isChainBrand ? 'yes' : 'no'}`} /></dd>
            </dl>
            {org.wechatMpAccount && <dl>
              <dt><Translate id="org.org_info.label.wechat_mp_account" /></dt>
              <dd>{org.wechatMpAccount}</dd>
            </dl>}
          </div>
        );
      }
    }, {
      prop: 'address',
      width: 200,
      text: 'col.address',
      render: (entity) => {
        const props = this.props;
        if (!entity.provinceId) return '-';
        if (!props.areas?.result || props.areas.isLoading) {
          return '...';
        }
        const province = props.areas.result.getNodeById(entity.provinceId);
        const city = props.areas.result.getNodeById(entity.cityId!);
        const district = props.areas.result.getNodeById(entity.districtId!);
        const parts: string[] = [];
        if (province.children.length > 1) {
          parts.push(province.area.name);
        }
        parts.push(city.area.name, district.area.name);
        return (
          <div>
            <p style={{ marginBottom: 4 }}>{parts.join('/')}</p>
            <p>{entity.address}</p>
          </div>
        );
      }
    }, {
      prop: 'contact',
      width: 200,
      text: 'col.contact_name',
      render: entity => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        };
        return (
          <div>
            {entity.contactName &&
            <div style={style}>
              <StringLabel value="contact_label.name" />
              {entity.contactName}
            </div>}
            {entity.contactPhone &&
            <div style={style}>
              <StringLabel value="contact_label.phone" />
              {entity.contactPhone}
            </div>}
            {entity.contactMobile &&
            <div style={style}>
              <StringLabel value="contact_label.mobile" />
              {entity.contactMobile}
            </div>}
            {entity.contactFax &&
            <div style={style}>
              <StringLabel value="contact_label.fax" />
              {entity.contactFax}
            </div>}
            {entity.contactEmail &&
            <div style={style}>
              <StringLabel value="contact_label.email" />
              {entity.contactEmail}
            </div>}
          </div>
        );
      }
    }, {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt)
    }];
  }
}

export const RecentOrganizationList = withLocalize(RecentOrganizationListComponent);