/**
 * @file: stats.service.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  SummaryStats,
  DatePartitionType,
  StatItem,
  StoreSummaryStats,
} from 'model';

export class StatsService {
  constructor(private readonly client: APIService) {}

  async getSummaryStats(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
  ): Promise<SummaryStats> {
    return await this.client
      .get()
      .url('/stats/summary', { startDate, endDate })
      .future();
  }

  async getOrganizationStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/organizations', { startDate, endDate, partition })
      .future();
  }

  async getOrgStoreStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/organizations/stores', { startDate, endDate, partition })
      .future();
  }

  async getUserStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/users', { startDate, endDate, partition })
      .future();
  }

  async getVehicleReceptionOrderStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-reception-orders', { startDate, endDate, partition })
      .future();
  }

  async getVehicleInspectionTaskStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-inspection-tasks', { startDate, endDate, partition })
      .future();
  }

  async getVehicleInfoStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-infos', { startDate, endDate, partition })
      .future();
  }

  async getVehicleInspectionReportStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-inspection-reports', {
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getVehicleInspectionCommentStatsByTime(
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/vehicle-inspection-comments', {
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getStoreSummaryStats(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
  ): Promise<StoreSummaryStats> {
    return await this.client
      .get()
      .url('/stats/store/summary', { orgId, storeId, startDate, endDate })
      .future();
  }

  async getStoreVehicleReceptionOrderStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/store/vehicle-reception-orders', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getStoreVehicleInspectionTaskStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/store/vehicle-inspection-tasks', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getStoreVehicleInspectionReportStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/store/vehicle-inspection-reports', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getStoreCustomerStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/store/customers', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }

  async getStoreVehicleInspectionCommentStatsByTime(
    orgId: number,
    storeId: number | null | undefined,
    startDate: string | Date | null | undefined,
    endDate: string | Date | null | undefined,
    partition: DatePartitionType,
  ): Promise<StatItem[]> {
    return await this.client
      .get()
      .url('/stats/store/vehicle-inspection-comments', {
        orgId,
        storeId,
        startDate,
        endDate,
        partition,
      })
      .future();
  }
}
