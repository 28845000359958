/**
 * @file: StoreMediaCategory.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum StoreMediaCategory {
  Default = 0,
  HeadImage = 1,
}

export const StoreMediaCategoryValueSet = new Set([
  StoreMediaCategory.Default,
  StoreMediaCategory.HeadImage,
]);
