import { Organization, Store } from 'model';
import { organizationService, storeService } from 'services';
import { MutualExclusiveCall } from 'utils';

const cache = new Map<string, any>();

export const orgCacheKey = (id: number) => `org:${id}`;
export const storeCacheKey = (id: number) => `store:${id}`;

export const getCachedOrgInfo = (id: number): Organization | undefined =>
  cache.get(orgCacheKey(id));

export const getCachedStoreInfo = (id: number): Store | undefined =>
  cache.get(storeCacheKey(id));

export const queryOrgInfo = async (
  id: number,
): Promise<Organization | null> => {
  const key = `org:${id}:call`;
  let call = cache.get(key) as
    | MutualExclusiveCall<Organization | null>
    | undefined;
  if (!call) {
    call = new MutualExclusiveCall<Organization | null>(() =>
      organizationService.get(id).then(res => {
        if (res) {
          cache.set(orgCacheKey(id), res);
        }
        return res ?? null;
      }),
    );
    cache.set(key, call);
  }
  return await call.invoke();
};

export const queryStoreInfo = async (id: number): Promise<Store | null> => {
  const key = `store:${id}:call`;
  let call = cache.get(key) as MutualExclusiveCall<Store | null> | undefined;
  if (!call) {
    call = new MutualExclusiveCall<Store | null>(() =>
      storeService.get(id).then(res => {
        if (res) {
          cache.set(storeCacheKey(id), res);
        }
        return res ?? null;
      }),
    );
    cache.set(key, call);
  }
  return await call.invoke();
};
