import React from 'react';
import { Translate } from 'react-localize-redux';
import { Checkmark, EntityListComponentClassBuilder, EntityListProps, getString, VehicleServiceSubjectTagLabel } from 'shared/components';
import { AclObjectList, VehicleServiceSubject, VehicleServiceSubjectListFilter, VehicleServiceSubjectTag, VehicleServiceSubjectTagOptions } from 'model';
import { serviceSubjectActions } from '../duck/actions';
import { commonService } from 'services';

interface Props extends EntityListProps<VehicleServiceSubject, VehicleServiceSubjectListFilter> {}

const componentClassBuilder = new EntityListComponentClassBuilder<
VehicleServiceSubject,
VehicleServiceSubjectListFilter,
  number,
  Props
  >();

export const ServiceSubjectList = componentClassBuilder
  .i18nPrefix('service_subject')
  .accessRights({
    full: AclObjectList.VehicleServiceSubjectFullAccess,
    readonly: AclObjectList.VehicleServiceSubjectReadonlyAccess
  })
  .breadcrumbs([
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.service_subjects" /> }
  ])
  .entities(state => state.inspection.serviceSubjects)
  .actions(serviceSubjectActions)
  .editor(builder => builder.uncontrolled().text({
    prop: 'name',
    label: 'service_subject.editor.label.name',
    placeholder: 'service_subject.editor.placeholder.name',
    onChange: async (changes, props: Props) => {
      const { dispatch } = props;
      const updatePyInitial = (pyInitial: string) => {
        if (props.entities.itemBeingCreated) {
          dispatch(serviceSubjectActions.itemBeingCreatedChanged({ pyInitial }));
        } else {
          dispatch(serviceSubjectActions.itemBeingUpdatedChanged({ pyInitial }));
        }
      };
      if (changes.name) {
        try {
          const pyInitial = await commonService.getPyInitial(changes.name);
          updatePyInitial(pyInitial);
        } catch (e) {
          console.error(e);
          updatePyInitial('');
        }
      } else {
        updatePyInitial('');
      }
    }
  }).text({
    prop: 'pyInitial',
    label: 'service_subject.editor.label.py_initial',
    placeholder: 'service_subject.editor.placeholder.py_initial'
  }).select({
    prop: 'tag',
    label: 'service_subject.editor.label.tag',
    placeholder: 'service_subject.editor.placeholder.tag',
    options: VehicleServiceSubjectTagOptions
  }).checkbox({
    prop: 'isDefault',
    label: 'service_subject.editor.label.is_default'
  }))
  .toolbarItems(builder => builder.text({
    prop: 'name',
    label: 'service_subject.toolbar.label.keyword',
    placeholder: 'service_subject.toolbar.placeholder.keyword'
  }))
  .columns([{
    prop: 'name',
    width: 200,
    text: 'service_subject.col.name'
  }, {
    prop: 'pyInitial',
    width: 100,
    text: 'service_subject.col.py_initial'
  }, {
    prop: 'tag',
    width: 80,
    text: 'service_subject.col.tag',
    align: 'center',
    render: ({ tag }) => (
      typeof tag === 'number' ?
        <VehicleServiceSubjectTagLabel value={tag} /> :
        '-'
      )
  }, {
    prop: 'isSystemDefined',
    width: 100,
    text: 'service_subject.col.is_system_defined',
    align: 'center',
    render: ({ isSystemDefined }) => <Checkmark value={isSystemDefined} />
  }, {
    prop: 'isDefault',
    width: 80,
    text: 'service_subject.col.is_default',
    align: 'center',
    render: ({ isDefault }) => <Checkmark value={isDefault} />
  }]
)
.addActionButtons(['edit', 'remove'])
.filter((items, props) => {
  const keyword = props.entities.filter?.name?.trim();
  if (keyword) {
    return items.filter(x => x.name.includes(keyword) || x.pyInitial?.includes(keyword));
  }
  return items;
})
.validate(entity => {
  const name = entity.name?.trim();
  const pyInitial = entity.pyInitial?.trim();
  let errCode: string = '';
  if (!name) {
    errCode = 'name_required';
  } else if (!pyInitial) {
    errCode = 'py_initial_required';
  }
  if (errCode) {
    const errMsg = getString('service_subject.editor.error.' + errCode);
    throw new Error(errMsg);
  }
}).onAdd(entity => {
  entity.isSystemDefined = false;
  entity.tag = VehicleServiceSubjectTag.None;
}).getClass();
