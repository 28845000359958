/**
 * @file: VehicleInspectionTaskIssueRefService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskIssueRefServiceBase } from './base/VehicleInspectionTaskIssueRefService';

export class VehicleInspectionTaskIssueRefService extends VehicleInspectionTaskIssueRefServiceBase {
  // add custom service implementation here
}
