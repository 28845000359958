/**
 * @file: InspectionTemplatePredefinedTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionTemplatePredefinedType, Option } from 'model';

export const InspectionTemplatePredefinedTypeOptions: Array<
  Option<InspectionTemplatePredefinedType>
> = [
  {
    value: InspectionTemplatePredefinedType.FullInspection,
    label: 'inspection_template_predefined_type.full_inspection',
  },
  {
    value: InspectionTemplatePredefinedType.AirConditionerInspection,
    label: 'inspection_template_predefined_type.air_conditioner_inspection',
  },
  {
    value: InspectionTemplatePredefinedType.CarWashBeauty,
    label: 'inspection_template_predefined_type.car_wash_beauty',
  },
  {
    value: InspectionTemplatePredefinedType.Other,
    label: 'inspection_template_predefined_type.other',
  },
];

export const InspectionTemplatePredefinedTypeOptionsWithDefault: Array<
  Option<InspectionTemplatePredefinedType | null>
> = [
  { value: null, label: 'inspection_template_predefined_type.__default__' },
  {
    value: InspectionTemplatePredefinedType.FullInspection,
    label: 'inspection_template_predefined_type.full_inspection',
  },
  {
    value: InspectionTemplatePredefinedType.AirConditionerInspection,
    label: 'inspection_template_predefined_type.air_conditioner_inspection',
  },
  {
    value: InspectionTemplatePredefinedType.CarWashBeauty,
    label: 'inspection_template_predefined_type.car_wash_beauty',
  },
  {
    value: InspectionTemplatePredefinedType.Other,
    label: 'inspection_template_predefined_type.other',
  },
];
