/**
 * @file: VehicleReceptionOrderService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleReceptionOrder,
  VehicleReceptionOrderListFilter,
} from 'model';

export class VehicleReceptionOrderServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleReceptionOrderListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleReceptionOrder>
      : VehicleReceptionOrder[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-reception-orders',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleReceptionOrder | null> {
    return await this.api
      .get()
      .url('/vehicle-reception-orders/:id', { id })
      .future();
  }

  async getOrderByOrderNo(
    orderNo: string,
  ): Promise<VehicleReceptionOrder | null> {
    if (!orderNo) {
      throw new Error('missing orderNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-reception-orders/query/orderno', { orderNo })
      .future();
  }

  async getOrderByReportNo(
    reportNo: string,
  ): Promise<VehicleReceptionOrder | null> {
    if (!reportNo) {
      throw new Error('missing reportNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-reception-orders/query/reportno', { reportNo })
      .future();
  }

  async create(
    vehicleReceptionOrder: Partial<VehicleReceptionOrder>,
  ): Promise<VehicleReceptionOrder> {
    return await this.api
      .post()
      .url('/vehicle-reception-orders')
      .data(vehicleReceptionOrder)
      .future();
  }

  async update(
    vehicleReceptionOrder: Partial<VehicleReceptionOrder>,
  ): Promise<VehicleReceptionOrder> {
    return await this.api
      .put()
      .url('/vehicle-reception-orders/:id', { id: vehicleReceptionOrder.id })
      .data(vehicleReceptionOrder)
      .future();
  }

  async delete(id: number): Promise<void> {
    await this.api
      .delete()
      .url('/vehicle-reception-orders/:id', { id })
      .future();
  }
}
