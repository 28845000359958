import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { vehicleListActions, performSelfCheckOfVehicleInfos } from './duck/actions';
import { VehicleInfo, VehicleInfoListFilter, AclObjectList } from 'model';
import { EntityListProps, EntityListComponentClassBuilder } from 'shared/components';

import './index.scss';
import { VehicleInfos } from './duck/states';

interface Props extends EntityListProps<VehicleInfo, VehicleInfoListFilter> {}

const componentClassBuilder =
  new EntityListComponentClassBuilder<VehicleInfo, VehicleInfoListFilter, number, Props>();

export const VehicleInfoList = componentClassBuilder
  .i18nPrefix('vehicle')
  .accessRights({
    full: AclObjectList.VehicleInfoFullAccess,
    readonly: AclObjectList.VehicleInfoReadonlyAccess
  })
  .breadcrumbs([
    { text: <Translate id="vehicle.breadcrumb.it" /> },
    { text: <Translate id="vehicle.breadcrumb.vehicle_list" /> }
  ])
  .features({ addEntity: false })
  .entities(state => state.vehicles.vehicleList)
  .actions(vehicleListActions)
  .toolbarItems(builder => {
    builder.text({
      prop: 'vin',
      label: 'vehicle.toolbar.label.vin',
      placeholder: 'vehicle.toolbar.placeholder.vin',
      width: 170
    }).text({
      prop: 'brand',
      width: 150,
      label: 'vehicle.toolbar.label.brand',
      placeholder: 'vehicle.toolbar.placeholder.brand'
    }).text({
      prop: 'name',
      width: 150,
      label: 'vehicle.toolbar.label.name',
      placeholder: 'vehicle.toolbar.placeholder.name'
    }).text({
      prop: 'carType',
      width: 150,
      label: 'vehicle.toolbar.label.car_type',
      placeholder: 'vehicle.toolbar.placeholder.car_type'
    }).button({
      text: '@string/btn_search',
      onClick: (props: Props) => {
        const { dispatch } = props;
        dispatch(vehicleListActions.invalidate(true));
      }
    }).button({
      text: 'vehicle.toolbar.button.self_check',
      loading: (props: Props) => {
        const state = props.entities as VehicleInfos;
        return state.isSelfChecking || false;
      },
      onClick: (props: Props) => {
        const { dispatch } = props;
        dispatch(performSelfCheckOfVehicleInfos());
      }
    })
  })
  .columns([{
    prop: 'id',
    width: 60,
    text: 'vehicle.col.logo',
    render: (vehicleInfo: VehicleInfo) => {
      return (
        <img
          src={vehicleInfo.brandLogo || ''}
          style={{ width: 60 }}
          />
      );
    }
  }, {
    prop: 'vin',
    width: 100,
    text: 'vehicle.col.vin'
  }, {
    prop: 'name',
    width: 250,
    text: `vehicle.col.name`
  }, {
    prop: 'canonicalName',
    width: 200,
    text: 'vehicle.col.canonical_name'
  }, {
    prop: 'brand',
    width: 100,
    text: 'vehicle.col.brand_name'
  }, {
    prop: 'modelName',
    width: 80,
    text: 'vehicle.col.model_name'
  }, {
    prop: 'seriesName',
    width: 100,
    text: 'vehicle.col.series_name'
  }, {
    prop: 'manufacturer',
    width: 100,
    text: 'vehicle.col.manufacturer'
  }, {
    prop: 'carType',
    width: 100,
    text: 'vehicle.col.car_type'
  }, {
    prop: 'modelYear',
    width: 100,
    text: 'vehicle.col.model_year'
  }, {
    prop: 'emissionStandard',
    width: 100,
    text: 'vehicle.col.emission_standard'
  }, {
    prop: 'fuelConsumption',
    width: 100,
    text: 'vehicle.col.fuel_consumption'
  }, {
    prop: 'engineType',
    width: 100,
    text: 'vehicle.col.engine_type'
  }, {
    prop: 'fuelType',
    width: 100,
    text: 'vehicle.col.fuel_type'
  }, {
    prop: 'fuelLevel',
    width: 100,
    text: 'vehicle.col.fuel_level'
  }, {
    prop: 'transmissionType',
    width: 100,
    text: 'vehicle.col.transmission_type'
  }, {
    prop: 'gearType',
    width: 100,
    text: 'vehicle.col.gear_type'
  }, {
    prop: 'gearbox',
    width: 100,
    text: 'vehicle.col.gearbox'
  }, {
    prop: 'gearNum',
    width: 100,
    text: 'vehicle.col.gear_num'
  }, {
    prop: 'driveMode',
    width: 100,
    text: 'vehicle.col.drive_mode'
  }, {
    prop: 'carBodyType',
    width: 100,
    text: 'vehicle.col.car_body_type'
  }, {
    prop: 'frontTireSize',
    width: 100,
    text: 'vehicle.col.front_tire_size'
  }, {
    prop: 'rearTireSize',
    width: 100,
    text: 'vehicle.col.rear_tire_size'
  }, {
    prop: 'parkingBrakeType',
    width: 100,
    text: 'vehicle.col.parking_brake_type'
  }, {
    prop: 'maxPower',
    width: 100,
    text: 'vehicle.col.max_power'
  }, {
    prop: 'vehicleLevel',
    width: 100,
    text: 'vehicle.col.vehicle_level'
  }, {
    prop: 'seatNum',
    width: 100,
    text: 'vehicle.col.seat_num'
  }, {
    prop: 'bodyStructure',
    width: 100,
    text: 'vehicle.col.body_structure'
  }, {
    prop: 'cylinderType',
    width: 100,
    text: 'vehicle.col.cylinder_type'
  }, {
    prop: 'productionYear',
    width: 100,
    text: 'vehicle.col.production_year'
  }, {
    prop: 'onboardMonth',
    width: 100,
    text: 'vehicle.col.onboard_month'
  }, {
    prop: 'stopTime',
    width: 100,
    text: 'vehicle.col.stop_time'
  }, {
    prop: 'guidePrice',
    width: 100,
    text: 'vehicle.col.guide_price'
  }, {
    prop: 'createdAt',
    text: 'col.created_at',
    width: 150,
    align: 'center',
    render: ({ createdAt }) => formatTime(createdAt)
  }])
  .getClass();