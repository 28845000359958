/**
 * @file: ui.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { MainNavStatus } from 'app';
import { UIState } from 'app/duck/states';
import { storageService } from 'lib';
import { StandardAction } from 'lib/duck/interfaces';
import { ActionTypes } from '../types';

const initialState: UIState = {
  mainNavStatus: storageService.ls_get('mainNavStatus') || 'minimized',
  mainNavOpenInSmallScreen: false,
  asideLeftHover: false,
  asideRightStatus: 'closed',
};

export default function (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: UIState = initialState,
  action: StandardAction<any>,
): UIState {
  switch (action.type) {
    case ActionTypes.ToggleMiminize: {
      const status = action.payload as MainNavStatus | undefined;
      const mainNavStatus =
        status === undefined
          ? state.mainNavStatus === 'open'
            ? 'minimized'
            : 'open'
          : status;
      storageService.ls_set('mainNavStatus', mainNavStatus);
      return {
        ...state,
        mainNavStatus,
      };
    }

    case ActionTypes.OpenMainNavInSmallScreen:
      return { ...state, mainNavOpenInSmallScreen: true };

    case ActionTypes.CloseMainNavInSmallScreen:
      return { ...state, mainNavOpenInSmallScreen: false };

    case ActionTypes.RevealAsideLeft:
      return { ...state, asideLeftHover: true };

    case ActionTypes.UnrevealAsideLeft:
      return { ...state, asideLeftHover: false };

    case ActionTypes.RevealAsideRight:
      return { ...state, asideRightStatus: 'open' };

    case ActionTypes.UnrevealAsideRight:
      return { ...state, asideRightStatus: 'closed' };

    default:
      return state;
  }
}
