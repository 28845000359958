import { AppState } from 'app/duck/states';
import {
  fetchConstructionTemplateConfig,
  makeDefaultConstructionTemplateConfig,
  refreshConstructionTemplateConfig,
} from 'app/inspection/duck/actions';
import { ConstructionTemplateConfigState } from 'app/inspection/duck/states';
import { AclObjectList, ConstructionTemplateConfig } from 'model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { BreadcrumbItem, Page, getString } from 'shared/components';
import { Actions } from './Actions';
import { Content } from './Content';
import { Context } from './Context';

import './index.scss';

export function ConstructionTemplateConfigManager() {
  const [breadcrumbs] = useState<BreadcrumbItem[]>(() => {
    return [
      { text: <Translate id="inspection.breadcrumb.it" /> },
      {
        text: (
          <Translate id="inspection.breadcrumb.construction_template_config" />
        ),
        href: '/inspection/construction-template-config',
      },
    ];
  });

  const dispatch = useDispatch();

  const defaultTemplateConfig = useRef<ConstructionTemplateConfig>(
    makeDefaultConstructionTemplateConfig(),
  ).current;

  useEffect(() => {
    dispatch(fetchConstructionTemplateConfig());
  }, [dispatch]);

  const onRefresh = useCallback(() => {
    dispatch(refreshConstructionTemplateConfig());
  }, [dispatch]);

  const state = useSelector<AppState, ConstructionTemplateConfigState>(
    x => x.inspection.constructionTemplateConfig,
  );

  const hasChanges = state?.templateConfigBeingEdited != null;

  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => {
        return getString('construction_tpl.changes.navigate_away_warn');
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [hasChanges]);

  return (
    <Context.Provider
      value={{
        templateConfig:
          state.templateConfigBeingEdited ??
          state.result ??
          defaultTemplateConfig,
        templateConfigBeingEdited: state.templateConfigBeingEdited,
        selectedSidebarItem: state.selectedSidebarItem,
      }}
    >
      <Prompt
        when={hasChanges}
        message={getString('construction_tpl.changes.navigate_away_warn')}
      />
      <Page
        title={<Translate id="construction_tpl.title" />}
        fullAccessRight={AclObjectList.VehicleConstructionTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleConstructionTemplateReadonlyAccess
        }
        onRefresh={onRefresh}
        breadcrumbs={breadcrumbs}
        noPadding
        bodyClassName="construction-tpl-man"
        portletHeaderComplement={<Actions />}
      >
        <Content />
      </Page>
    </Context.Provider>
  );
}
