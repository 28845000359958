import { memo } from 'react';
import { Translate } from 'react-localize-redux';
import { InlineSvg } from 'shared/components/InlineSvg';

export const NoSubjectSelectedPlaceholder = memo(() => {
  return (
    <div className={`quotation-tpl__item-panel-empty`}>
      <InlineSvg src="img/no-data.svg" />
      <span style={{ textAlign: 'center' }}>
        <Translate id={`quotation_tpl.item.empty_text.select_subject`} />
      </span>
    </div>
  );
});
