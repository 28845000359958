/**
 * @file: VehicleInspectionSiteService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionSiteType } from 'model';
import { InspectionSiteInventoryImportResult } from 'model/viewmodel/InspectionSiteInventoryImportResult';
import { VehicleInspectionSiteServiceBase } from './base/VehicleInspectionSiteService';

export class VehicleInspectionSiteService extends VehicleInspectionSiteServiceBase {
  async importInventory(
    fileDataUri: string,
  ): Promise<InspectionSiteInventoryImportResult> {
    return await this.api
      .post()
      .url('/vehicle-inspection-sites/inventory/import')
      .data({ data: fileDataUri })
      .future();
  }

  async batchUpdateSitesType(siteIds: number[], type: InspectionSiteType) {
    return await this.api
      .post()
      .url('/vehicle-inspection-sites/inventory/batch-update-sites-type')
      .data({ siteIds, type })
      .future();
  }

  async batchUpdateSitesModule(
    siteIds: number[],
    primaryModuleId: number | null | undefined,
    secondaryModuleId: number | null | undefined,
  ) {
    return await this.api
      .post()
      .url('/vehicle-inspection-sites/inventory/batch-update-sites-module')
      .data({ siteIds, primaryModuleId, secondaryModuleId })
      .future();
  }
}
