import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import {
  findQuotationGroupByRef,
  findQuotationSubjectByRef,
} from 'app/inspection/quotation-template-config/util';
import { QuotationGroupRef, QuotationTemplateSubjectStaged } from 'model';
import {
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { commonService } from 'services';
import { EntityEditorForm, EntityEditorFormBuilder } from 'shared/components';
import { Button } from 'shared/metronic/components';
import invariant from 'tiny-invariant';
import { usePersistFn } from 'utils/usePersistFn';

const kDefaultSubject: QuotationTemplateSubjectStaged = {
  id: '',
  name: '',
  items: [],
  groupRef: {} as any,
};

export const SubjectEditor = memo(() => {
  const { state } = useQuotationTemplateConfigContext();
  const { subjectRefBeingEdited } = state;
  const ref = useRef<Ref>(null);

  const dispatch = useDispatch();

  const current = useMemo<
    | { subject: QuotationTemplateSubjectStaged; mode: 'add' | 'edit' }
    | undefined
  >(() => {
    if (subjectRefBeingEdited == null) {
      return undefined;
    }

    const groupRef: QuotationGroupRef = {
      categoryId: subjectRefBeingEdited.categoryId,
      groupId: subjectRefBeingEdited.groupId,
    };

    const group = findQuotationGroupByRef(state.staged, groupRef);
    invariant(group != null);

    const subject = findQuotationSubjectByRef(
      state.staged,
      subjectRefBeingEdited,
    );

    return {
      subject:
        subject ??
        ({
          id: subjectRefBeingEdited.subjectId,
          name: '',
          items: [],
          groupRef,
        } as QuotationTemplateSubjectStaged),
      mode: subject == null ? 'add' : 'edit',
    };
  }, [state.staged, subjectRefBeingEdited]);

  const onConfirm = useCallback(() => {
    invariant(current != null);
    invariant(subjectRefBeingEdited != null);

    if (!ref.current?.validate()) {
      return;
    }

    const groupRef: QuotationGroupRef = {
      categoryId: subjectRefBeingEdited.categoryId,
      groupId: subjectRefBeingEdited.groupId,
    };

    const group = findQuotationGroupByRef(state.staged, groupRef);
    invariant(group != null);

    const staged = ref.current.getStaged();
    if (
      group.subjects.some(
        x =>
          x.name === staged.name &&
          (current.mode === 'add' || x.id !== staged.id),
      )
    ) {
      ref.current.setError(
        'name',
        <Translate
          id="quotation_tpl.subject.error.duplicate_name"
          data={{ name: staged.name }}
        />,
      );
      return;
    }

    invariant(staged != null, 'Staged subject not found');
    dispatch(quotationTplActions.commitSubjectBeingEdited(staged));
  }, [current, dispatch, state.staged, subjectRefBeingEdited]);

  const onCancel = useCallback(() => {
    dispatch(quotationTplActions.cancelSubjectBeingEdited());
  }, [dispatch]);

  return (
    <Modal isOpen={current != null}>
      <ModalHeader>
        {current ? (
          <Translate
            id={`quotation_tpl.subject.modal.editor.title.${current.mode}`}
          />
        ) : (
          ''
        )}
      </ModalHeader>
      <ModalBody>
        <Content
          subject={current?.subject ?? kDefaultSubject}
          key={current?.subject.id ?? ''}
          ref={ref}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          <Translate id="cancel_btn_text" />
        </Button>
        <Button color="primary" onClick={onConfirm}>
          <Translate id="ok_btn_text" />
        </Button>
      </ModalFooter>
    </Modal>
  );
});

type Ref = {
  getStaged: () => QuotationTemplateSubjectStaged;
  validate: () => boolean;
  setError: (name: string, error: any) => void;
};

type ContentProps = {
  subject: QuotationTemplateSubjectStaged;
};

const Content = memo(
  forwardRef<Ref, ContentProps>(({ subject }, ref) => {
    const [staged, setStaged] = useState(subject);

    const [errors, setErrors] = useState<Record<string, any>>({});
    const [showErrorOnChange, setShowErrorOnChange] = useState(false);

    const validate = usePersistFn(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const errors: Record<string, ReactNode> = {};

      if (!staged.name?.trim()) {
        errors['name'] = (
          <Translate id="quotation_tpl.subject.error.name_required" />
        );
      }

      if (!staged.pyInitial?.trim()) {
        errors['pyInitial'] = (
          <Translate id="quotation_tpl.subject.error.py_initials_required" />
        );
      }

      const hasErrors = Boolean(Object.keys(errors).length);

      if (!showErrorOnChange && hasErrors) {
        setShowErrorOnChange(true);
      }

      setErrors(errors);

      if (hasErrors) {
        return false;
      }

      return true;
    });

    const handleChange = useCallback(
      (changes: Partial<QuotationTemplateSubjectStaged>) => {
        setStaged(x => ({ ...x, ...changes }));
      },
      [],
    );

    useImperativeHandle(ref, () => ({
      getStaged: () => ({
        ...staged,
        name: staged.name,
      }),
      validate,
      setError: (name, error) => {
        setErrors(x => ({ ...x, [name]: error }));
      },
    }));

    const form = useMemo(() => {
      const builder =
        new EntityEditorFormBuilder<QuotationTemplateSubjectStaged>();

      builder
        .text({
          prop: 'name',
          label: 'quotation_tpl.subject.label.name',
          placeholder: 'quotation_tpl.subject.placeholder.name',
          controlled: false,
          required: true,
          error: errors['name'],
          onChange: async changes => {
            if (changes.name) {
              try {
                const pyInitial = await commonService.getPyInitial(
                  changes.name,
                );
                setStaged(x => ({ ...x, pyInitial }));
              } catch (e) {
                console.error(e);
              }
            } else {
              setStaged(x => ({ ...x, pyInitial: '' }));
            }
            if (showErrorOnChange) {
              setTimeout(validate, 0);
            }
          },
        })
        .text({
          prop: 'pyInitial',
          label: 'quotation_tpl.subject.label.py_initial',
          placeholder: 'quotation_tpl.subject.placeholder.py_initial',
          required: true,
          error: errors['pyInitial'],
          onChange: changes => {
            changes.pyInitial = changes.pyInitial?.toLowerCase();
            if (showErrorOnChange) {
              setTimeout(validate, 0);
            }
          },
        })
        .text({
          prop: 'code',
          label: 'quotation_tpl.subject.label.code',
          placeholder: 'quotation_tpl.subject.placeholder.code',
          controlled: false,
        })
        .reactSelect({
          prop: 'keywords',
          creatable: true,
          multi: true,
          createOnBlur: true,
          label: 'quotation_tpl.subject.label.keywords',
          placeholder: 'quotation_tpl.subject.placeholder.keywords',
          values: ({ entity }) =>
            entity.keywords?.map(x => ({ label: x, value: x })) ?? [],
          labelProp: 'label',
          valueProp: 'value',
          onGetOptionValue(option) {
            return option.value;
          },
          onGetOptionLabel(option) {
            return option.value;
          },
          createOptionPosition: 'last',
          isValidNewOption(value) {
            return value.trim().length > 0;
          },
          onGetNewOptionData: ((value: string) => {
            return { label: value, value };
          }) as any,
        });
      return builder.build();
    }, [errors, showErrorOnChange, validate]);

    return (
      <EntityEditorForm
        entity={staged}
        onChange={handleChange}
        elements={form.elements as any}
        autocomplete={form.autocomplete}
        useUncontrolled={form.useUncontrolled}
      />
    );
  }),
);
