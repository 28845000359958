import { Option, VehicleInspectionSiteCheckItemOption } from 'model';
import { AbnormalLevelOptions, SeverityLevelOptions } from 'model/EnumOptions';
import { Translate } from 'react-localize-redux';
import {
  FormElementCustom,
  getString,
  withEntityEditorSidebarBuilder,
} from 'shared/components';
import { getValueUnitByValueType } from '../../common/helpers';
import { InventoryManagerProps } from '../manager';

const LowerOps: Array<Option<boolean>> = [
  { value: false, label: 'inspection_site_item_option.lower_ops.gt' },
  { value: true, label: 'inspection_site_item_option.lower_ops.gte' },
];

const UpperOps: Array<Option<boolean>> = [
  { value: false, label: 'inspection_site_item_option.upper_ops.lt' },
  { value: true, label: 'inspection_site_item_option.upper_ops.lte' },
];

type InspectionOptionType = VehicleInspectionSiteCheckItemOption;

const valueUnitElement: FormElementCustom<InspectionOptionType> = {
  type: 'custom',
  prop: '' as any,
  label: '',
  render: (extra: {
    props: InventoryManagerProps;
    entity: InspectionOptionType | Partial<InspectionOptionType>;
  }) => {
    const { props, entity } = extra;
    // find the corresponding inspection site item.
    const item = props.items.result!.find(x => x.id === entity.itemId);
    if (item?.valueType) {
      return getValueUnitByValueType(item.valueType);
    }
    return null;
  },
};

export const OptionEditorV2 =
  withEntityEditorSidebarBuilder<VehicleInspectionSiteCheckItemOption>()
    .withI18nPrefix('inspection_site_item_option')
    .withForm(builder =>
      builder
        .uncontrolled()
        .text({
          prop: 'label',
          label: 'inspection_site_item_option.editor.label.label',
          placeholder: 'inspection_site_item_option.editor.placeholder.label',
          helpText: 'inspection_site_item_option.editor.help_text.label',
        })
        .text({
          prop: 'labelFormat',
          label: 'inspection_site_item_option.editor.label.label_format',
          placeholder:
            'inspection_site_item_option.editor.placeholder.label_format',
          helpText: 'inspection_site_item_option.editor.help_text.label_format',
        })
        .group(group =>
          group
            .withLabel('inspection_site_item_option.editor.label.value_range')
            .text({
              prop: 'lower',
              type: 'number',
              label: '',
              defaultValue: null,
              width: 80,
            })
            .custom(valueUnitElement)
            .select({
              prop: 'lowerInclusive',
              label: '',
              options: LowerOps,
              width: 60,
              style: { padding: '.35rem 0.75rem' },
              convertValue: x => x.includes('true'),
            })
            .custom({
              label: '',
              render: () => (
                <span className="option-editor__value-label">
                  <Translate id="inspection_site_item_option.editor.label.value_range_value" />
                </span>
              ),
            })
            .select({
              prop: 'upperInclusive',
              label: '',
              options: UpperOps,
              width: 60,
              style: { padding: '.35rem 0.75rem' },
              convertValue: x => x.includes('true'),
            })
            .text({
              prop: 'upper',
              type: 'number',
              defaultValue: null,
              label: '',
              width: 80,
            })
            .custom(valueUnitElement),
        )
        .select({
          prop: 'abnormalLevel',
          options: AbnormalLevelOptions,
          label: 'inspection_site_item_option.editor.label.abnormal_level',
          placeholder:
            'inspection_site_item_option.editor.placeholder.abnormal_level',
        })
        .select({
          prop: 'severityLevel',
          options: SeverityLevelOptions,
          label: 'inspection_site_item_option.editor.label.severity_level',
          placeholder:
            'inspection_site_item_option.editor.placeholder.severity_level',
        })
        .text({
          prop: 'maintenanceAdvice',
          label: 'inspection_site_item_option.editor.label.maintenance_advice',
          placeholder:
            'inspection_site_item_option.editor.placeholder.maintenance_advice',
        })
        .checkbox({
          prop: 'isPicPreferred',
          label: 'inspection_site_item.editor.label.is_pic_preferred',
          helpText: 'inspection_site_item.editor.help_text.is_pic_preferred',
        })
        .medias({
          prop: 'imgList',
          label: 'inspection_site_item.editor.label.image_list',
          serialize: medias => medias.map(x => x.url).join(','),
          deserialize(value: string) {
            const idFromUrl = (url: string) => {
              const pathname = new URL(url).pathname;
              const m = /\/([^./]+)[^/]+$/.exec(pathname);
              return m?.[1] ?? pathname;
            };
            return (
              value
                ?.split(',')
                .map(x => x.trim())
                .filter(x => x) ?? []
            ).map(url => ({
              url,
              id: idFromUrl(url),
              type: 'image',
            }));
          },
          previewWidth: 80,
          previewHeight: 80,
          filePicker: {
            cover: false,
            realm: 'site/img',
            accept: 'image/png, image/jpeg',
          },
        })
        .textArea({
          prop: 'description',
          label: 'inspection_site_item_option.editor.label.description',
          placeholder:
            'inspection_site_item_option.editor.placeholder.description',
        }),
    )
    .withValidator(option => {
      let errCode: string | undefined = undefined;
      const label = option.label?.trim();
      if (!label) {
        errCode = 'label_required';
      }
      if (errCode) {
        const errMsg = getString(
          'inspection_site_item_option.editor.error.' + errCode,
        );
        throw new Error(errMsg);
      }
    })
    .getClass();
