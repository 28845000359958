/**
 * @file: AdminUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  AdminUser,
  AdminUserListFilter,
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class AdminUserServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<AdminUserListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions ? ListResult<AdminUser> : AdminUser[]
  > {
    return await this.api
      .get()
      .url(
        '/admin-users',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<AdminUser | null> {
    return await this.api.get().url('/admin-users/:id', { id }).future();
  }

  async getUserByUserName(userName: string): Promise<AdminUser | null> {
    if (!userName) {
      throw new Error('missing userName parameter');
    }
    return await this.api
      .get()
      .url('/admin-users/query/username', { userName })
      .future();
  }

  async getUserByEmail(email: string): Promise<AdminUser | null> {
    if (!email) {
      throw new Error('missing email parameter');
    }
    return await this.api
      .get()
      .url('/admin-users/query/email', { email })
      .future();
  }

  async create(adminUser: Partial<AdminUser>): Promise<AdminUser> {
    return await this.api.post().url('/admin-users').data(adminUser).future();
  }

  async update(adminUser: Partial<AdminUser>): Promise<AdminUser> {
    return await this.api
      .put()
      .url('/admin-users/:id', { id: adminUser.id })
      .data(adminUser)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete().url('/admin-users/:id', { id }).future();
  }
}
