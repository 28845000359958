/**
 * @file: OfficialAccountGrantedPermissionTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OfficialAccountGrantedPermissionType, Option } from 'model';

export const OfficialAccountGrantedPermissionTypeOptions: Array<
  Option<OfficialAccountGrantedPermissionType>
> = [
  {
    value: OfficialAccountGrantedPermissionType.Message,
    label: 'official_account_granted_permission_type.message',
  },
  {
    value: OfficialAccountGrantedPermissionType.User,
    label: 'official_account_granted_permission_type.user',
  },
  {
    value: OfficialAccountGrantedPermissionType.Account,
    label: 'official_account_granted_permission_type.account',
  },
  {
    value: OfficialAccountGrantedPermissionType.WebPage,
    label: 'official_account_granted_permission_type.web_page',
  },
  {
    value: OfficialAccountGrantedPermissionType.Store,
    label: 'official_account_granted_permission_type.store',
  },
  {
    value: OfficialAccountGrantedPermissionType.MultiServiceSupport,
    label: 'official_account_granted_permission_type.multi_service_support',
  },
  {
    value: OfficialAccountGrantedPermissionType.MassMessageAndNotification,
    label:
      'official_account_granted_permission_type.mass_message_and_notification',
  },
  {
    value: OfficialAccountGrantedPermissionType.Card,
    label: 'official_account_granted_permission_type.card',
  },
  {
    value: OfficialAccountGrantedPermissionType.Scan,
    label: 'official_account_granted_permission_type.scan',
  },
  {
    value: OfficialAccountGrantedPermissionType.WifiConnect,
    label: 'official_account_granted_permission_type.wifi_connect',
  },
  {
    value: OfficialAccountGrantedPermissionType.Media,
    label: 'official_account_granted_permission_type.media',
  },
  {
    value: OfficialAccountGrantedPermissionType.Shake,
    label: 'official_account_granted_permission_type.shake',
  },
  {
    value: OfficialAccountGrantedPermissionType.StoreMiniprogram,
    label: 'official_account_granted_permission_type.store_miniprogram',
  },
  {
    value: OfficialAccountGrantedPermissionType.Menu,
    label: 'official_account_granted_permission_type.menu',
  },
  {
    value: OfficialAccountGrantedPermissionType.CertificationStatus,
    label: 'official_account_granted_permission_type.certification_status',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramAccount,
    label: 'official_account_granted_permission_type.miniprogram_account',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramDevAnalysis,
    label: 'official_account_granted_permission_type.miniprogram_dev_analysis',
  },
  {
    value:
      OfficialAccountGrantedPermissionType.MiniprogramServiceSupportMessage,
    label:
      'official_account_granted_permission_type.miniprogram_service_support_message',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramWeixinLogin,
    label: 'official_account_granted_permission_type.miniprogram_weixin_login',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramAnalysis,
    label: 'official_account_granted_permission_type.miniprogram_analysis',
  },
  {
    value: OfficialAccountGrantedPermissionType.CityServiceApi,
    label: 'official_account_granted_permission_type.city_service_api',
  },
  {
    value: OfficialAccountGrantedPermissionType.Ads,
    label: 'official_account_granted_permission_type.ads',
  },
  {
    value: OfficialAccountGrantedPermissionType.OpenPlatformAccount,
    label: 'official_account_granted_permission_type.open_platform_account',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramOpenPlatformAccount,
    label:
      'official_account_granted_permission_type.miniprogram_open_platform_account',
  },
  {
    value: OfficialAccountGrantedPermissionType.Invoice,
    label: 'official_account_granted_permission_type.invoice',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramBasicInfo,
    label: 'official_account_granted_permission_type.miniprogram_basic_info',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramNearlyPlaces,
    label: 'official_account_granted_permission_type.miniprogram_nearly_places',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramPlugin,
    label: 'official_account_granted_permission_type.miniprogram_plugin',
  },
  {
    value: OfficialAccountGrantedPermissionType.WidgetSearch,
    label: 'official_account_granted_permission_type.widget_search',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramCloudDev,
    label: 'official_account_granted_permission_type.miniprogram_cloud_dev',
  },
  {
    value: OfficialAccountGrantedPermissionType.ServiceAccountConversation,
    label:
      'official_account_granted_permission_type.service_account_conversation',
  },
];

export const OfficialAccountGrantedPermissionTypeOptionsWithDefault: Array<
  Option<OfficialAccountGrantedPermissionType | null>
> = [
  {
    value: null,
    label: 'official_account_granted_permission_type.__default__',
  },
  {
    value: OfficialAccountGrantedPermissionType.Message,
    label: 'official_account_granted_permission_type.message',
  },
  {
    value: OfficialAccountGrantedPermissionType.User,
    label: 'official_account_granted_permission_type.user',
  },
  {
    value: OfficialAccountGrantedPermissionType.Account,
    label: 'official_account_granted_permission_type.account',
  },
  {
    value: OfficialAccountGrantedPermissionType.WebPage,
    label: 'official_account_granted_permission_type.web_page',
  },
  {
    value: OfficialAccountGrantedPermissionType.Store,
    label: 'official_account_granted_permission_type.store',
  },
  {
    value: OfficialAccountGrantedPermissionType.MultiServiceSupport,
    label: 'official_account_granted_permission_type.multi_service_support',
  },
  {
    value: OfficialAccountGrantedPermissionType.MassMessageAndNotification,
    label:
      'official_account_granted_permission_type.mass_message_and_notification',
  },
  {
    value: OfficialAccountGrantedPermissionType.Card,
    label: 'official_account_granted_permission_type.card',
  },
  {
    value: OfficialAccountGrantedPermissionType.Scan,
    label: 'official_account_granted_permission_type.scan',
  },
  {
    value: OfficialAccountGrantedPermissionType.WifiConnect,
    label: 'official_account_granted_permission_type.wifi_connect',
  },
  {
    value: OfficialAccountGrantedPermissionType.Media,
    label: 'official_account_granted_permission_type.media',
  },
  {
    value: OfficialAccountGrantedPermissionType.Shake,
    label: 'official_account_granted_permission_type.shake',
  },
  {
    value: OfficialAccountGrantedPermissionType.StoreMiniprogram,
    label: 'official_account_granted_permission_type.store_miniprogram',
  },
  {
    value: OfficialAccountGrantedPermissionType.Menu,
    label: 'official_account_granted_permission_type.menu',
  },
  {
    value: OfficialAccountGrantedPermissionType.CertificationStatus,
    label: 'official_account_granted_permission_type.certification_status',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramAccount,
    label: 'official_account_granted_permission_type.miniprogram_account',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramDevAnalysis,
    label: 'official_account_granted_permission_type.miniprogram_dev_analysis',
  },
  {
    value:
      OfficialAccountGrantedPermissionType.MiniprogramServiceSupportMessage,
    label:
      'official_account_granted_permission_type.miniprogram_service_support_message',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramWeixinLogin,
    label: 'official_account_granted_permission_type.miniprogram_weixin_login',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramAnalysis,
    label: 'official_account_granted_permission_type.miniprogram_analysis',
  },
  {
    value: OfficialAccountGrantedPermissionType.CityServiceApi,
    label: 'official_account_granted_permission_type.city_service_api',
  },
  {
    value: OfficialAccountGrantedPermissionType.Ads,
    label: 'official_account_granted_permission_type.ads',
  },
  {
    value: OfficialAccountGrantedPermissionType.OpenPlatformAccount,
    label: 'official_account_granted_permission_type.open_platform_account',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramOpenPlatformAccount,
    label:
      'official_account_granted_permission_type.miniprogram_open_platform_account',
  },
  {
    value: OfficialAccountGrantedPermissionType.Invoice,
    label: 'official_account_granted_permission_type.invoice',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramBasicInfo,
    label: 'official_account_granted_permission_type.miniprogram_basic_info',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramNearlyPlaces,
    label: 'official_account_granted_permission_type.miniprogram_nearly_places',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramPlugin,
    label: 'official_account_granted_permission_type.miniprogram_plugin',
  },
  {
    value: OfficialAccountGrantedPermissionType.WidgetSearch,
    label: 'official_account_granted_permission_type.widget_search',
  },
  {
    value: OfficialAccountGrantedPermissionType.MiniprogramCloudDev,
    label: 'official_account_granted_permission_type.miniprogram_cloud_dev',
  },
  {
    value: OfficialAccountGrantedPermissionType.ServiceAccountConversation,
    label:
      'official_account_granted_permission_type.service_account_conversation',
  },
];
