import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { DataExportTask, DataExportTaskOrigin } from 'model';
import { DataExportTasks } from '../states';
import { ActionTypes } from '../types';

const initialState: DataExportTasks = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: { origin: [DataExportTaskOrigin.Boss] },
  sorts: [{ property: 'id', dir: 'desc' }],
};

const defaultReducer = createAsyncListActionReducers<
  DataExportTask,
  DataExportTasks
>('dataExportTasks', initialState, {
  mapItemKey: x => x.id,
  insertItemCreated(item, items) {
    items.unshift(item);
  },
});

export default function dataExportTaskReducer(
  state: DataExportTasks,
  action: StandardAction<any>,
) {
  switch (action.type) {
    case ActionTypes.ShowAddDataExportTask:
      return {
        ...state,
        isAddJobModalOpen: action.payload.show,
        addJobFromExisting: action.payload.existing,
      };
    default:
      return defaultReducer(state, action);
  }
}
