/**
 * @file: OfficialAccountCertificateTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OfficialAccountCertificateType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: OfficialAccountCertificateType | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

const Values = {
  [OfficialAccountCertificateType.Uncertified]: 'uncertified',
  [OfficialAccountCertificateType.WeixinCertified]: 'weixin_certified',
  [OfficialAccountCertificateType.SinaWeiboCertified]: 'sina_weibo_certified',
  [OfficialAccountCertificateType.TencentWeiboCertified]:
    'tencent_weibo_certified',
  [OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertified]:
    'qualification_certified_without_name_certified',
  [OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertifiedWithSinaWeiboCertified]:
    'qualification_certified_without_name_certified_with_sina_weibo_certified',
  [OfficialAccountCertificateType.QualificationCertifiedWithoutNameCertifiedWithTencentWeiboCertified]:
    'qualification_certified_without_name_certified_with_tencent_weibo_certified',
};

export const OfficialAccountCertificateTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.OfficialAccountCertificateType?.[enumValue]
      ? LabelColors.OfficialAccountCertificateType[enumValue]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`official_account_certificate_type.${enumValue}`} />
    </Badge>
  );
});
