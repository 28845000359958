/**
 * @file: AgentService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AgentServiceBase } from './base/AgentService';

export class AgentService extends AgentServiceBase {
  // add custom service implementation here
  async sendStoreStatsRemind(agentId: number, targetUserIds?: number[]) {
    return await this.api
      .post()
      .url('/agents/:agentId/store-stats-remind', { agentId })
      .data({ targetUserIds })
      .future();
  }
}
