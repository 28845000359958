import { api, tokenService } from 'lib';
import React, { PureComponent } from 'react';
import { Translate } from 'react-localize-redux';

interface Props {
  orgId: number;
  storeId?: number;
}

type QrcodeFormat = 'png' | 'jpg' | 'pdf';

interface State {
  format: QrcodeFormat;
}

const Formats: QrcodeFormat[] = ['jpg', 'png'];

// 18

interface QrcodeConfig {
  /** @property {number} sizeInCM -  size of the qrcode in cm */
  sizeInCm: number;

  /** @property {number} sizeInPixel -  size of the qrcode in pixel */
  sizeInPixel: number;

  /** @property {number} paddingInM -  padding of the qrcode in pixel */
  suggestedScanDistanceInM: number;

  /** @property {number} paddingInM -  padding of the qrcode in pixel */
  paddingInPixel: number;
}

const ConfigList: QrcodeConfig[] = [
  {
    sizeInCm: 8,
    sizeInPixel: 258,
    suggestedScanDistanceInM: 0.5,
    // paddingInPixel: 18,
    paddingInPixel: 1,
  },
  {
    sizeInCm: 12,
    sizeInPixel: 344,
    suggestedScanDistanceInM: 0.8,
    // paddingInPixel: 24,
    paddingInPixel: 2,
  },
  {
    sizeInCm: 15,
    sizeInPixel: 430,
    suggestedScanDistanceInM: 1,
    // paddingInPixel: 30,
    paddingInPixel: 3,
  },
  {
    sizeInCm: 30,
    sizeInPixel: 860,
    suggestedScanDistanceInM: 1.5,
    // paddingInPixel: 59,
    paddingInPixel: 4,
  },
  {
    sizeInCm: 50,
    sizeInPixel: 1280,
    suggestedScanDistanceInM: 2.5,
    // paddingInPixel: 86,
    paddingInPixel: 5,
  },
];

export class QrcodeDownloader extends PureComponent<Props, State> {
  state: State = { format: 'jpg' };

  render() {
    const { storeId } = this.props;
    return (
      <div>
        <ul
          className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
          style={{ marginBottom: 15 }}
        >
          <li className="nav-item m-tabs__item">
            <a
              className="nav-link m-tabs__link active"
              style={{ fontWeight: 'bold', fontSize: '120%' }}
            >
              <Translate
                id={`org.qrcode_downloader.btn_tooltip.${
                  storeId ? 'store' : 'org'
                }`}
              />
            </a>
          </li>
        </ul>
        <table className="table table-striped- table-bordered no-footer dtr-inline">
          <thead>
            <tr>
              <th>
                <Translate id="org.qrcode_downloader.col.size" />
              </th>
              <th>
                <Translate id="org.qrcode_downloader.col.scan_distance" />
              </th>
              <th>
                <Translate id="org.qrcode_downloader.col.download_link" />
              </th>
            </tr>
          </thead>
          <tbody>
            {ConfigList.map(x => (
              <tr key={x.sizeInCm}>
                <td style={{ textAlign: 'center' }}>{x.sizeInCm}cm</td>
                <td style={{ textAlign: 'center' }}>
                  {x.suggestedScanDistanceInM}m
                </td>
                <td style={{ textAlign: 'center' }}>
                  <a href={this.getQrcodeUrl(x)} target="_blank" rel="noreferrer">
                    <i className="la la-download" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          <Translate id="org.qrcode_downloader.tip" />
        </p>
        <ul>
          {Formats.map(format => (
            <li key={format}>
              <label>
                <input
                  type="radio"
                  name="format"
                  value={format}
                  style={{ marginRight: 8 }}
                  checked={this.state.format === format}
                  onChange={this.onFormatChange(format)}
                />
                <Translate id={`org.qrcode_downloader.format.${format}`} />
              </label>
            </li>
          ))}
        </ul>
        <p style={{ textAlign: 'center' }}>
          <img src={this.getQrcodeUrl(ConfigList[0])} />
        </p>
      </div>
    );
  }

  onFormatChange = (format: QrcodeFormat) => {
    return () => {
      this.setState({ format });
    };
  };

  getQrcodeUrl(config: QrcodeConfig) {
    const token = tokenService.getToken();
    const url = api.url('/medias/mp/qrcode', {
      orgid: this.props.orgId,
      storeid: this.props.storeId || undefined,
      size: config.sizeInPixel,
      margin: config.paddingInPixel,
      format: this.state.format,
      token,
      _: Date.now(),
    });
    return url;
  }
}
