/**
 * @file: MaintenanceTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { MaintenanceType, Option } from 'model';

export const MaintenanceTypeOptions: Array<Option<MaintenanceType>> = [
  { value: MaintenanceType.None, label: 'maintenance_type.none' },
  { value: MaintenanceType.Basic, label: 'maintenance_type.basic' },
  { value: MaintenanceType.Watch, label: 'maintenance_type.watch' },
  { value: MaintenanceType.Replace, label: 'maintenance_type.replace' },
];

export const MaintenanceTypeOptionsWithDefault: Array<
  Option<MaintenanceType | null>
> = [
  { value: null, label: 'maintenance_type.__default__' },
  { value: MaintenanceType.None, label: 'maintenance_type.none' },
  { value: MaintenanceType.Basic, label: 'maintenance_type.basic' },
  { value: MaintenanceType.Watch, label: 'maintenance_type.watch' },
  { value: MaintenanceType.Replace, label: 'maintenance_type.replace' },
];
