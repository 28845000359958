import {
  getDateRangePickerLocale,
  getDefaultInputRanges,
  getDefaultStaticRanges,
} from 'app';
import {
  Agent,
  DataExportTask,
  DataExportTaskJobType,
  DataExportTaskJobTypeOptions,
  Organization,
  Store,
} from 'model';
import {
  DataExportTaskRequest,
  DataExportTaskRequestTarget,
} from 'model/DataExportTaskRequest';
import moment from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { Translate } from 'react-localize-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap/lib';
import { agentService, organizationService, storeService } from 'services';
import { getString, Select, StorePicker } from 'shared/components';
import { AgentPicker } from 'shared/components/AgentPicker';
import { Button } from 'shared/metronic/components';
import { usePersistFn } from 'utils/usePersistFn';

type Target =
  | {
      type: 'agent';
      agent?: Agent;
    }
  | {
      type: 'org';
      org?: Organization;
    }
  | {
      type: 'store';
      org?: Organization;
      store?: Store;
    };

type TargetType = Target['type'];

const kTargetTypes: TargetType[] = ['agent', 'org', 'store'];

const NonAgentTargetedJobTypes = new Set<DataExportTaskJobType>([
  DataExportTaskJobType.StatsSummaryReport,
  DataExportTaskJobType.ServiceAgentStats,
  DataExportTaskJobType.OrderComments,
  DataExportTaskJobType.OrderRemarks,
  DataExportTaskJobType.PreInspectionDetails,
  DataExportTaskJobType.ProductReferralDetails,
]);

export const AddJobModal = memo(
  ({
    fromExisting,
    isOpen,
    onCancel,
    onConfirm,
  }: {
    isOpen: boolean;
    fromExisting?: DataExportTask;
    onCancel: () => void;
    onConfirm: (
      jobType: DataExportTaskJobType,
      target: DataExportTaskRequestTarget,
      dateRange: { from: string; to: string },
    ) => void;
  }) => {
    const requestId = fromExisting?.taskId;
    const [jobType, setJobType] = useState<DataExportTaskJobType | undefined>(
      fromExisting?.jobType ?? undefined,
    );
    const [target, setTarget] = useState<Target | undefined>();
    const [startDate, setStartDate] = useState<string>(
      moment().format('YYYY-MM-DD'),
    );
    const [endDate, setEndDate] = useState<string>(
      moment().format('YYYY-MM-DD'),
    );

    useEffect(() => {
      setJobType(fromExisting?.jobType);
      setTarget(undefined);

      if (!fromExisting) return;

      const request: DataExportTaskRequest = JSON.parse(fromExisting.request);
      if (request.target.type === 'agent') {
        agentService
          .get(request.target.agentId)
          .then(agent => {
            agent && setTarget({ type: 'agent', agent });
          })
          .catch(err => {
            alert(err.message);
          });
      } else if (request.target.type === 'org') {
        organizationService
          .get(request.target.orgId)
          .then(org => {
            org && setTarget({ type: 'org', org });
          })
          .catch(err => {
            alert(err.message);
          });
      } else {
        Promise.all([
          organizationService.get(request.target.orgId),
          storeService.get(request.target.storeId),
        ])
          .then(([org, store]) => {
            org &&
              store &&
              setTarget({
                type: 'store',
                org,
                store,
              });
          })
          .catch(err => {
            alert(err.message);
          });
      }
    }, [fromExisting]);

    const onTargetTypeClick = useCallback(
      (type: TargetType) => {
        if (type !== target?.type) {
          setTarget({ type });
        }
      },
      [target],
    );

    const onDateRangeChange = useCallback((ranges: RangeKeyDict) => {
      const key = Object.keys(ranges)[0];
      if (!key) return;
      const range = ranges[key];
      setStartDate(moment(range.startDate).format('YYYY-MM-DD'));
      setEndDate(moment(range.endDate).format('YYYY-MM-DD'));
    }, []);

    const disabled =
      jobType == null ||
      target == null ||
      (target.type === 'agent' && target.agent == null) ||
      (target.type === 'org' && target.org == null) ||
      (target.type === 'store' && (target.org == null || target.store == null));

    const onConfirmPress = usePersistFn(() => {
      if (target == null || jobType == null) return;
      const targetInfo: DataExportTaskRequestTarget =
        target.type === 'agent'
          ? {
              type: 'agent',
              agentId: target.agent!.id,
              agentName: target.agent!.name,
            }
          : target.type === 'org'
            ? {
                type: 'org',
                orgId: target.org!.id,
                orgName: target.org!.name,
              }
            : {
                type: 'store',
                orgId: target.org!.id,
                orgName: target.org!.name,
                storeId: target.store!.id,
                storeName: target.store!.name,
              };
      onConfirm(jobType, targetInfo, { from: startDate, to: endDate });
    });

    return (
      <Modal isOpen={isOpen} modalClassName="add-data-export-job-modal">
        <ModalHeader>
          <Translate id={`data_export.add_job.title`} />
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'stretch',
              marginBottom: '1rem',
            }}
          >
            <div style={{ width: 226, paddingRight: '1rem' }}>
              <p style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                <Translate id="data_export.add_job.label.job_type" />
              </p>
              <Select
                valueProp="value"
                labelProp="label"
                placeholder={getString(
                  'data_export.add_job.placeholder.job_type',
                )}
                isClearable
                selectedValue={DataExportTaskJobTypeOptions.find(
                  x => x.value === jobType,
                )}
                values={DataExportTaskJobTypeOptions}
                onChange={option => {
                  setJobType(option.value);
                  if (
                    option.value === DataExportTaskJobType.StatsSummaryReport &&
                    target?.type === 'agent'
                  ) {
                    setTarget(undefined);
                  }
                }}
                onFormatOptionLabel={option => getString(option.label)}
              />
            </div>

            <div
              style={{
                flex: 1,
              }}
            >
              <p style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>
                <Translate id="data_export.add_job.label.target" />
              </p>
              <div
                style={{
                  height: '38px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {kTargetTypes
                  .filter(
                    x =>
                      jobType == null ||
                      !NonAgentTargetedJobTypes.has(jobType) ||
                      x !== 'agent',
                  )
                  .map(type => (
                    <label
                      onClick={() => onTargetTypeClick(type)}
                      key={type}
                      style={{
                        marginRight: '1rem',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        type="radio"
                        checked={type === target?.type}
                        onChange={() => null}
                        style={{ marginRight: '0.5rem' }}
                      />
                      <span>
                        <Translate id={`data_export.target.${type}`} />
                      </span>
                    </label>
                  ))}
              </div>
            </div>
          </div>
          {target?.type === 'agent' ? (
            <AgentPicker
              agentId={target.agent?.id}
              className="add-data-export-job__agent-picker"
              key={requestId}
              onChange={(_, agent) => {
                setTarget({
                  type: 'agent',
                  agent: agent ?? undefined,
                });
              }}
            />
          ) : target?.type === 'org' ? (
            <StorePicker
              key={requestId}
              orgId={target?.org?.id}
              orgOnly
              className="add-data-export-job__org-picker"
              onChange={(_a, _b, org) => {
                setTarget({
                  type: 'org',
                  org: org ?? undefined,
                });
              }}
            />
          ) : target?.type === 'store' ? (
            <StorePicker
              key={requestId}
              orgId={target.org?.id}
              storeId={target.store?.id}
              className="add-data-export-job__store-picker"
              onChange={(_a, _b, org, store) => {
                setTarget({
                  type: 'store',
                  org: org ?? undefined,
                  store: store ?? undefined,
                });
              }}
            />
          ) : null}
          <p style={{ marginTop: '1rem', fontWeight: 'bold' }}>
            <Translate id="data_export.add_job.label.date_range" />
          </p>
          <div>
            <Translate>
              {({ activeLanguage, translate }) => (
                <DateRangePicker
                  locale={getDateRangePickerLocale(activeLanguage.code)}
                  dateDisplayFormat={translate('date_range.date_format') as any}
                  ranges={[
                    {
                      startDate: moment(startDate).toDate(),
                      endDate: moment(endDate).toDate(),
                    },
                  ]}
                  onChange={onDateRangeChange as any}
                  staticRanges={getDefaultStaticRanges(translate as any)}
                  inputRanges={getDefaultInputRanges(translate as any)}
                />
              )}
            </Translate>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={onConfirmPress} disabled={disabled}>
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
