/**
 * @file: VehicleInspectionSiteMaintenancePeriodVarianceService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionSiteMaintenancePeriodVarianceServiceBase } from './base/VehicleInspectionSiteMaintenancePeriodVarianceService';

export class VehicleInspectionSiteMaintenancePeriodVarianceService extends VehicleInspectionSiteMaintenancePeriodVarianceServiceBase {
  // add custom service implementation here
}
