/**
 * @file: VehicleModelService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleModelServiceBase } from './base/VehicleModelService';

export class VehicleModelService extends VehicleModelServiceBase {
  // add custom service implementation here
}
