/**
 * @file: InspectionTaskEventTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionTaskEventType, Option } from 'model';

export const InspectionTaskEventTypeOptions: Array<
  Option<InspectionTaskEventType>
> = [
  {
    value: InspectionTaskEventType.System,
    label: 'inspection_task_event_type.system',
  },
  {
    value: InspectionTaskEventType.Creation,
    label: 'inspection_task_event_type.creation',
  },
  {
    value: InspectionTaskEventType.Inspection,
    label: 'inspection_task_event_type.inspection',
  },
  {
    value: InspectionTaskEventType.Quotation,
    label: 'inspection_task_event_type.quotation',
  },
  {
    value: InspectionTaskEventType.Construction,
    label: 'inspection_task_event_type.construction',
  },
  {
    value: InspectionTaskEventType.Completion,
    label: 'inspection_task_event_type.completion',
  },
  {
    value: InspectionTaskEventType.PreInspection,
    label: 'inspection_task_event_type.pre_inspection',
  },
  {
    value: InspectionTaskEventType.DeliveryCheck,
    label: 'inspection_task_event_type.delivery_check',
  },
];

export const InspectionTaskEventTypeOptionsWithDefault: Array<
  Option<InspectionTaskEventType | null>
> = [
  { value: null, label: 'inspection_task_event_type.__default__' },
  {
    value: InspectionTaskEventType.System,
    label: 'inspection_task_event_type.system',
  },
  {
    value: InspectionTaskEventType.Creation,
    label: 'inspection_task_event_type.creation',
  },
  {
    value: InspectionTaskEventType.Inspection,
    label: 'inspection_task_event_type.inspection',
  },
  {
    value: InspectionTaskEventType.Quotation,
    label: 'inspection_task_event_type.quotation',
  },
  {
    value: InspectionTaskEventType.Construction,
    label: 'inspection_task_event_type.construction',
  },
  {
    value: InspectionTaskEventType.Completion,
    label: 'inspection_task_event_type.completion',
  },
  {
    value: InspectionTaskEventType.PreInspection,
    label: 'inspection_task_event_type.pre_inspection',
  },
  {
    value: InspectionTaskEventType.DeliveryCheck,
    label: 'inspection_task_event_type.delivery_check',
  },
];
