import classNames from 'classnames';
import { ColorType } from 'model';
import { Component, CSSProperties, ReactNode } from 'react';
import { PortletBody } from './PortletBody';
import { PortletFooter } from './PortletFooter';
import { PortletHeader } from './PortletHeader';
import { PortletNav } from './PortletNav';

interface Props {
  color?: ColorType;
  border?: boolean;
  tabs?: boolean;
  solid?: boolean;
  size?: 'sm' | 'lg';
  style?: CSSProperties;
  children?: ReactNode;
}

export class Portlet extends Component<Props> {
  static Header = PortletHeader;
  static Body = PortletBody;
  static Footer = PortletFooter;
  static Nav = PortletNav;

  render() {
    const { color, border, tabs, solid, size, ...props } = this.props;
    return (
      <div
        className={classNames('m-portlet', {
          'm-portlet--tabs': tabs,
          'm-portlet--bordered': border,
          [`m-portlet--${color}`]: color,
          'm-portlet--head-solid-bg': solid,
          [`m-portlet--head-${size}`]: size,
        })}
        {...props}
      >
        {this.props.children}
      </div>
    );
  }
}
