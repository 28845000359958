import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { Store } from 'model';
import { storeService } from 'services';

const actionCreators = createListAsyncActionCreators<AppState, Store>(
  'dashboard.recent_stores',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      return await storeService.list(
        state.dashboard.recentStores.filter || {},
        state.dashboard.recentStores.sorts?.[0],
        state.dashboard.recentStores.offset,
        state.dashboard.recentStores.limit,
        { count: false, recursive: true }
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentStores,
  invalidate: invalidateDashboardRecentStores
} = actionCreators;