import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { MaintenanceServiceSubject } from 'model';
import { MaintenanceServiceSubjects } from '../states';

const initialState: MaintenanceServiceSubjects = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {},
};

export default createAsyncListActionReducers<
  MaintenanceServiceSubject,
  MaintenanceServiceSubjects
>('inspection.maintenance_service_subjects', initialState, {
  mapItemKey: x => x.id,
});
