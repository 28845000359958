import { VehicleSystemModules } from 'app/inspection/duck/states';
import { VehicleSystemModule } from 'model';
import { ChangeEvent, Fragment, memo, useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { getString } from 'shared/components';
import { Alert, Button } from 'shared/metronic/components';

export const SetSiteSystemModuleModal = memo(
  ({
    visible,
    loading,
    error,
    systemModules,
    onClose,
    onConfirm,
  }: {
    systemModules: VehicleSystemModules;
    visible?: boolean;
    loading?: boolean;
    error?: Error | null;
    onClose?: () => void;
    onConfirm?: (
      primaryModuleId: number | null | undefined,
      secondaryModuleId: number | null | undefined,
    ) => void;
  }) => {
    const [systemModule, setSystemModule] = useState<VehicleSystemModule>();

    const onConfirmClick = useCallback(async () => {
      onConfirm?.(systemModule?.id, systemModule?.parentModId || null);
    }, [systemModule, onConfirm]);

    const onChange = useCallback(
      (e: ChangeEvent<HTMLSelectElement>) => {
        if (!systemModules.result) return;
        const value = Number(e.target.value);
        const mod = systemModules.result.find(x => x.id === value);
        setSystemModule(mod);
      },
      [systemModules.result],
    );

    return (
      <Modal isOpen={visible}>
        <ModalHeader>
          <Translate id={`inspection_site.modal.set_module.title`} />
        </ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error.message}</Alert>}
          <ReactMarkdown>
            {getString(`inspection_site.modal.set_module.msg`)}
          </ReactMarkdown>
          <select
            value={systemModule?.id}
            className="form-control"
            onChange={onChange}
          >
            {(systemModules.result ?? [])
              .filter(x => !x.parentModId)
              .map(mod => (
                <Fragment key={mod.id}>
                  <option value={mod.id} style={{ fontWeight: 'bold' }}>
                    {mod.name}
                  </option>
                  {(systemModules.result ?? [])
                    .filter(x => x.parentModId === mod.id)
                    .map(subMod => (
                      <option
                        key={subMod.id}
                        value={subMod.id}
                      >{`  |- ${subMod.name}`}</option>
                    ))}
                </Fragment>
              ))}
          </select>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose} disabled={loading}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button
            color="primary"
            onClick={onConfirmClick}
            disabled={loading}
            loader={loading}
          >
            <Translate id="ok_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  },
);
