import classNames from 'classnames';
import React, { CSSProperties } from 'react';
import { ColorValue } from './LabelColors';
import { ClassNameType } from 'model';

interface Props {
  color?: ColorValue | null;
  children?: any;
  noWide?: boolean;
  noRounded?: boolean;
  style?: CSSProperties;
  className?: ClassNameType;
}

export function Badge(props: Props) {
  const { color, children, noWide, noRounded, className } = props;
  const classList: ClassNameType[] = ['m-badge'];
  const style: CSSProperties = props.style || {};

  if (!noWide) classList.push('m-badge--wide');
  if (!noRounded) classList.push('m-badge--rounded');
  if (className) classList.push(className);

  if (color) {
    if (typeof color === 'string') {
      classList.push(`m-badge--${color}`);
    } else if (color.cls) {
      if (Array.isArray(color.cls)) {
        classList.push(...color.cls);
      } else {
        classList.push(color.cls);
      }
    } else if (color.value) {
      style.color = color.value;
    }
  }

  return (
    <span className={classNames(classList)} style={style}>
      {children}
    </span>
  );
}