import React from 'react';
import { Translate } from 'react-localize-redux';
import {
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
} from 'shared/components';
import {
  AclObjectList,
  MaintenanceServiceSubject,
  MaintenanceServiceSubjectListFilter,
} from 'model';
import { maintenanceServiceSubjectActions } from '../duck/actions';
import { formatTime } from 'utils';

interface Props
  extends EntityListProps<
    MaintenanceServiceSubject,
    MaintenanceServiceSubjectListFilter
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  MaintenanceServiceSubject,
  MaintenanceServiceSubjectListFilter,
  number,
  Props
>();

export const MaintenanceServiceSubjectList = componentClassBuilder
  .i18nPrefix('maintenance_service_subject')
  .accessRights({
    full: AclObjectList.MaintenanceServiceSubjectFullAccess,
    readonly: AclObjectList.MaintenanceServiceSubjectReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="inspection.breadcrumb.it" /> },
    {
      text: (
        <Translate id="inspection.breadcrumb.maintenance_service_subjects" />
      ),
    },
  ])
  .entities(state => state.inspection.maintenanceServiceSubjects)
  .actions(maintenanceServiceSubjectActions)
  .editor(builder =>
    builder
      .uncontrolled()
      .text({
        prop: 'name',
        label: 'maintenance_service_subject.editor.label.name',
        placeholder: 'maintenance_service_subject.editor.placeholder.name',
      })
      .image({
        prop: 'iconUrl',
        label: 'maintenance_service_subject.editor.label.icon',
        filePicker: {
          cover: false,
          realm: 'system/assets/icons',
          accept: 'image/png, image/jpeg',
        },
      })
      .image({
        prop: 'defaultImageUrl',
        label: 'maintenance_service_subject.editor.label.default_image',
        filePicker: {
          cover: false,
          realm: 'system/assets/icons',
          accept: 'image/png, image/jpeg',
        },
      })
      .medias({
        prop: 'medias',
        label: 'maintenance_service_subject.editor.label.medias',
        serialize: medias => JSON.stringify(medias),
        deserialize: value => JSON.parse(value || '[]'),
        previewWidth: 80,
        previewHeight: 80,
        filePicker: {
          cover: true,
          coverSize: 600,
          realm: 'system/assets/medias',
          accept: 'image/png, image/jpeg, video/mp4',
        },
      })
      .textArea({
        prop: 'description',
        label: 'maintenance_service_subject.editor.label.description',
        rows: 10,
      }),
  )
  .toolbarItems(builder =>
    builder.text({
      prop: 'name',
      label: 'maintenance_service_subject.toolbar.label.keyword',
      placeholder: 'maintenance_service_subject.toolbar.placeholder.keyword',
    }),
  )
  .columns([
    {
      prop: 'name',
      width: 200,
      text: 'maintenance_service_subject.col.name',
      render: ({ name, iconUrl }) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <div
              style={{
                backgroundImage: `url(${iconUrl || 'img/default-image.png'})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                width: 40,
                height: 40,
                borderRadius: 20,
              }}
            />
            <div
              style={{
                fontWeight: 'bold',
                marginLeft: 8,
                fontSize: '1.3rem',
              }}
            >
              {name}
            </div>
          </div>
        );
      },
    },
    {
      prop: 'description',
      width: 800,
      text: 'maintenance_service_subject.col.description',
      render: ({ description }) => description?.substring(0, 200),
    },
    {
      prop: 'createdAt',
      width: 80,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons(['edit', 'remove'])
  .filter((items, props) => {
    const keyword = props.entities.filter?.name?.trim();
    if (keyword) {
      return items.filter(x => x.name.includes(keyword));
    }
    return items;
  })
  .validate(entity => {
    const name = entity.name?.trim();
    let errCode: string = '';
    if (!name) {
      errCode = 'name_required';
    }
    if (errCode) {
      const errMsg = getString(
        'maintenance_service_subject.editor.error.' + errCode,
      );
      throw new Error(errMsg);
    }
  })
  .getClass();
