import { Areas, AppState } from 'app/duck/states';
import React, { ReactNode } from 'react';
import { RecentOrganizationList } from './RecentOrganizationList';
import { RecentStoreList } from './RecentStoreList';
import { RecentUserList } from './RecentUserList';
import { RecentVehicleReceptionOrderList } from './RecentReceptionOrderList';
import { RecentVehicleInspectionTaskList } from './RecentInspectionTaskList';
import { RecentVehicleInspectionReportList } from './RecentInspectionReportList';
import { RecentVehicleInspectionCommentList } from './RecentInspectionCommentList';
import { StandardAction, ActionThunk } from 'lib/duck/interfaces';
import {
  RecentOrganizations,
  RecentStores,
  RecentUsers,
  RecentReceptionOrders,
  RecentInspectionTasks,
  RecentInspectionReports,
  RecentInspectionComments,
  StatAspect,
  RecentVehicleInfos
} from './duck/states';

import {
  fetchDashboardRecentOrganizations,
  invalidateDashboardRecentOrganizations,
  fetchDashboardRecentStores,
  invalidateDashboardRecentStores,
  fetchDashboardRecentUsers,
  invalidateDashboardRecentUsers,
  fetchDashboardRecentReceptionOrders,
  invalidateDashboardRecentReceptionOrders,
  invalidateDashboardRecentInspectionTasks,
  fetchDashboardRecentInspectionTasks,
  fetchDashboardRecentInspectionReports,
  invalidateDashboardRecentInspectionReports,
  fetchDashboardRecentInspectionComments,
  invalidateDashboardRecentInspectionComments,
  fetchDashboardRecentVehicleInfos,
  invalidateDashboardRecentVehicleInfos
} from './duck/actions';
import { RecentVehicleInfoList } from './RecentVehicleList';

export const enum RecentListType {
  Organizations = 'organizations',
  Stores = 'stores',
  Users = 'users',
  ReceptionOrders = 'reception_orders',
  InspectionTasks = 'inspection_tasks',
  InspectionReports = 'inspection_reports',
  InspectionComments = 'inspection_comments',
  Vehicles = 'vehicles'
}

export interface RecentListsProps {
  areas: Areas;
  recentOrganizations: RecentOrganizations;
  recentStores: RecentStores;
  recentUsers: RecentUsers;
  recentReceptionOrders: RecentReceptionOrders;
  recentInspectionTasks: RecentInspectionTasks;
  recentInspectionReports: RecentInspectionReports;
  recentInspectionComments: RecentInspectionComments;
  recentVehicleInfos: RecentVehicleInfos;
}

export interface RecentListConfig {
  disabled?: boolean;
  statAspect: StatAspect;
  listType: RecentListType;
  fetchAction(): StandardAction<any> | ActionThunk<AppState>;
  invalidateAction(): StandardAction<any> | ActionThunk<AppState>;
  render(props: RecentListsProps): ReactNode;
}

export const RecentListsConfiguations: RecentListConfig[] = [{
  statAspect: StatAspect.Organization,
  listType: RecentListType.Organizations,
  fetchAction: () => fetchDashboardRecentOrganizations(),
  invalidateAction: () => invalidateDashboardRecentOrganizations(),
  render: (props: RecentListsProps) =>
    <RecentOrganizationList
      areas={props.areas}
      organizations={props.recentOrganizations}
      />
}, {
  statAspect: StatAspect.Store,
  listType: RecentListType.Stores,
  fetchAction: () => fetchDashboardRecentStores(),
  invalidateAction: () => invalidateDashboardRecentStores(),
  render: (props: RecentListsProps) =>
    <RecentStoreList
      areas={props.areas}
      stores={props.recentStores}
      />
}, {
  statAspect: StatAspect.User,
  listType: RecentListType.Users,
  fetchAction: () => fetchDashboardRecentUsers(),
  invalidateAction: () => invalidateDashboardRecentUsers(),
  render: (props: RecentListsProps) =>
    <RecentUserList users={props.recentUsers} />
}, {
  statAspect: StatAspect.ReceptionOrder,
  listType: RecentListType.ReceptionOrders,
  fetchAction: () => fetchDashboardRecentReceptionOrders(),
  invalidateAction: () => invalidateDashboardRecentReceptionOrders(),
  render: (props: RecentListsProps) =>
    <RecentVehicleReceptionOrderList
      orders={props.recentReceptionOrders}
      />
}, {
  statAspect: StatAspect.InspectionTask,
  listType: RecentListType.InspectionTasks,
  fetchAction: () => fetchDashboardRecentInspectionTasks(),
  invalidateAction: () => invalidateDashboardRecentInspectionTasks(),
  render: (props: RecentListsProps) =>
    <RecentVehicleInspectionTaskList
      tasks={props.recentInspectionTasks}
      />
}, {
  statAspect: StatAspect.InspectionReport,
  listType: RecentListType.InspectionReports,
  fetchAction: () => fetchDashboardRecentInspectionReports(),
  invalidateAction: () => invalidateDashboardRecentInspectionReports(),
  render: (props: RecentListsProps) =>
    <RecentVehicleInspectionReportList
      reports={props.recentInspectionReports}
      />
}, {
  statAspect: StatAspect.InspectionComment,
  listType: RecentListType.InspectionComments,
  fetchAction: () => fetchDashboardRecentInspectionComments(),
  invalidateAction: () => invalidateDashboardRecentInspectionComments(),
  render: (props: RecentListsProps) =>
    <RecentVehicleInspectionCommentList
      comments={props.recentInspectionComments}
      />
}, {
  statAspect: StatAspect.VehicleInfo,
  listType: RecentListType.Vehicles,
  fetchAction: () => fetchDashboardRecentVehicleInfos(),
  invalidateAction: () => invalidateDashboardRecentVehicleInfos(),
  render: (props: RecentListsProps) =>
    <RecentVehicleInfoList
      vehicleInfos={props.recentVehicleInfos}
      />
}];