/**
 * @file: ProductAgentService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  ProductAgent,
  ProductAgentListFilter,
  SortInfo,
} from 'model';

export class ProductAgentServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<ProductAgentListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<ProductAgent>
      : ProductAgent[]
  > {
    return await this.api
      .get()
      .url(
        '/product-agents',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<ProductAgent | null> {
    return await this.api.get().url('/product-agents/:id', { id }).future();
  }

  async getAgentByAdminMobile(
    adminMobile: string,
  ): Promise<ProductAgent | null> {
    if (!adminMobile) {
      throw new Error('missing adminMobile parameter');
    }
    return await this.api
      .get()
      .url('/product-agents/query/adminmobile', { adminMobile })
      .future();
  }

  async getAgentByName(name: string): Promise<ProductAgent | null> {
    if (!name) {
      throw new Error('missing name parameter');
    }
    return await this.api
      .get()
      .url('/product-agents/query/name', { name })
      .future();
  }

  async create(productAgent: Partial<ProductAgent>): Promise<ProductAgent> {
    return await this.api
      .post()
      .url('/product-agents')
      .data(productAgent)
      .future();
  }

  async update(productAgent: Partial<ProductAgent>): Promise<ProductAgent> {
    return await this.api
      .put()
      .url('/product-agents/:id', { id: productAgent.id })
      .data(productAgent)
      .future();
  }

  async delete(id: string | number): Promise<void> {
    return void await this.api.delete().url('/product-agents/:id', { id }).future();
  }
}
