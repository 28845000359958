import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { Organization } from 'model';
import { organizationService } from 'services';

const actionCreators = createListAsyncActionCreators<AppState, Organization>(
  'dashboard.recent_organizations',
  {
    shouldFetchOnInvalidate: true,
    shouldInvalidateForFilter: true,

    async fetchHandler(state: AppState) {
      return await organizationService.list(
        state.dashboard.recentOrganizations.filter || {},
        state.dashboard.recentOrganizations.sorts?.[0],
        state.dashboard.recentOrganizations.offset,
        state.dashboard.recentOrganizations.limit
      );
    }
  }
);

export const {
  fetch: fetchDashboardRecentOrganizations,
  invalidate: invalidateDashboardRecentOrganizations
} = actionCreators;