/**
 * @file: user.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AsyncState } from 'lib/duck/interfaces';
import { AdminUser } from 'model';
import { createAsyncActionReducers } from 'lib/duck/reducers';

const initialState: AsyncState<AdminUser> = {
  isLoading: false,
  error: null,
  result: null,
};

const defaultReducer = createAsyncActionReducers<
  AdminUser,
  AsyncState<AdminUser>
>('app.current_user', initialState);

export default defaultReducer;
