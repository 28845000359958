import classNames from 'classnames';
import { Component, ReactNode } from 'react';
import { TimelineItem } from './TimelineItem';

interface Props {
  skin?: 'light';
  children?: ReactNode;
}

export class Timeline extends Component<Props> {
  static Item = TimelineItem;
  render() {
    const { skin, children } = this.props;
    return (
      <div
        className={classNames(
          'm-list-timeline',
          `m-list-timeline--skin-${skin || 'light'}`,
        )}
      >
        <div className="m-list-timeline__items">{children}</div>
      </div>
    );
  }
}
