import {
  AclObjectList,
  ServiceEdition,
  ServiceEnrollmentStatus,
  Store,
  StoreListFilter,
  StoreMedia,
} from 'model';
import { MouseEvent, useCallback, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { lbsService, organizationService, systemService } from 'services';
import {
  AsideRight,
  Checkmark,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  OrgInfoView,
  QrcodeDownloader,
  ServiceEnrollmentStatusLabel,
  StringLabel,
} from 'shared/components';
import { CSSProperties } from 'styled-components';
import { formatTime, isNotNull } from 'utils';
import {
  downloadQrcodeForStore,
  renewStore,
  storeActions,
} from '../duck/actions';

import { RenewAside } from 'app/customers/stores/RenewAside';
import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { Areas } from 'app/duck/states';
import { config } from 'config';
import moment from 'moment';
import { queryOrgInfo } from 'shared/utils';
import { durationFromString } from 'utils/serviceDurationUtil';
import { isMobile } from 'utils/validators';
import './index.scss';
import { StoreMediaEditor } from './MediaEditor';

interface AugmentedStore extends Store {
  serviceExpiryExtensionStr?: string | null;
}

interface Props extends EntityListProps<AugmentedStore, StoreListFilter> {
  activeStoreForQrcodeDownload: Store | null | undefined;
  activeStoreForRenew: Store | null | undefined;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  AugmentedStore,
  StoreListFilter,
  number,
  Props
>();

export const StoreList = componentClassBuilder
  .i18nPrefix('store')
  .accessRights({
    full: AclObjectList.StoreFullAccess,
    readonly: AclObjectList.StoreReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="customer.breadcrumb.it" /> },
    { text: <Translate id="customer.breadcrumb.stores" /> },
  ])
  .mapStateToProps(state => ({
    activeStoreForQrcodeDownload:
      state.customers.stores.activeStoreForQrcodeDownloader,
    activeStoreForRenew: state.customers.stores.activeStoreForRenew,
  }))
  .entities(state => state.customers.stores)
  .actions(storeActions)
  .requireAreas()
  .editor(builder =>
    builder
      .reactSelect({
        prop: 'orgId',
        label: 'store.editor.label.organization',
        placeholder: 'store.editor.placeholder.organization',
        valueProp: 'id',
        labelProp: 'name',
        async: true,
        stateId: 'organizations',
        values: [],
        defaultValues: true,
        noOptionsMessage: ({ inputValue }) => {
          return getString(
            inputValue
              ? 'store.org_picker.no_values'
              : 'store.org_picker.no_values_hint',
          );
        },
        onLoadValues: async (keyword, entity) => {
          if (!keyword) {
            if (entity.orgId) {
              const org = await queryOrgInfo(entity.orgId);
              return [org].filter(isNotNull);
            } else {
              return [];
            }
          }
          const listResult = await organizationService.list(
            { keyword },
            null,
            0,
            0,
          );
          return listResult as any;
        },
      })
      .text({
        prop: 'name',
        label: 'store.editor.label.name',
        placeholder: 'store.editor.placeholder.name',
      })
      .checkbox({
        prop: 'enabled',
        label: 'store.editor.label.enabled',
      })
      .checkbox({
        prop: 'isFree',
        label: 'store.editor.label.is_free',
      })
      .serviceEdition({
        prop: 'editionId',
        label: 'store.editor.label.service_edition',
        helpText: 'store.editor.help_text.service_edition',
        placeholder: 'store.editor.placeholder.service_edition',
        disabled(entity) {
          return !!entity.id;
        },
        onChange(changes, ...args) {
          const edition = args[1] as ServiceEdition | undefined;
          if (edition != null) {
            changes.editionId = edition.sku;
            changes.editionName = edition.name;
            changes.editionType = edition.type;
            changes.editionVariant = edition.variant;
            changes.isTrialEdition = edition.isTrialEdition;
            changes.isTvEditionEnabled = edition.isTvVersionEnabled;
            changes.isDataV1Enabled = edition.isDataV1Enabled;
            changes.isQuotationEnabled = edition.isQuotationEnabled;
            changes.isNewBiEnabled = edition.isNewBiEnabled;
            changes.isNewMarketingEnabled = edition.isNewMarketingEnabled;
            changes.userAccountLimit = edition.userAccountLimit;
          }
        },
      })
      .checkbox({
        prop: 'isTrialEdition',
        label: 'store.editor.label.trial_edition',
      })
      .text({
        type: 'number',
        prop: 'userAccountLimit',
        label: 'service_edition.editor.label.user_account_limit',
        placeholder: 'service_edition.editor.placeholder.user_account_limit',
        helpText: 'service_edition.editor.help_text.user_account_limit',
      })
      .serviceDuration({
        prop: 'serviceRenewCycle',
        label: 'store.editor.label.renew_cycle',
        placeholder: 'store.editor.placeholder.renew_cycle',
        required: true,
      })
      .text({
        type: 'datetime-local',
        prop: 'serviceRenewTime',
        label: 'store.editor.label.expire_time',
        placeholder: 'store.editor.placeholder.expire_time',
        hidden(entity) {
          return !entity.id;
        },
      })
      .checkbox({
        prop: 'isTvEditionEnabled',
        label: 'service_edition.editor.label.is_tv_version_enabled',
      })
      .checkbox({
        prop: 'isDataV1Enabled',
        label: 'service_edition.editor.label.is_data_v1_enabled',
      })
      .checkbox({
        prop: 'isQuotationEnabled',
        label: 'service_edition.editor.label.is_quotation_enabled',
      })
      .checkbox({
        prop: 'isNewBiEnabled',
        label: 'service_edition.editor.label.is_new_bi_enabled',
      })
      .checkbox({
        prop: 'isNewMarketingEnabled',
        label: 'service_edition.editor.label.is_new_marketing_enabled',
      })
      .text({
        prop: 'serviceExpiryExtensionStr',
        label: 'store.editor.label.service_expiry_extension',
        placeholder: 'store.editor.placeholder.service_expiry_extension',
        helpText: 'store.editor.help_text.service_expiry_extension',
        mask: '@string/store.editor.label.service_expiry_extension_mask',
        maskChar: '0',
      })
      .text({
        prop: 'appliedBy',
        label: 'store.editor.label.applier_name',
        placeholder: 'store.editor.placeholder.applier_name',
      })
      .text({
        prop: 'salesName',
        label: 'store.editor.label.sales_name',
        placeholder: 'store.editor.placeholder.sales_name',
      })
      .text({
        prop: 'salesMobile',
        label: 'store.editor.label.sales_mobile',
        placeholder: 'store.editor.placeholder.sales_mobile',
      })
      .text({
        prop: 'referralName',
        label: 'store.editor.label.referral_name',
        placeholder: 'store.editor.placeholder.referral_name',
      })
      .checkbox({
        prop: 'serviceRemindEnabled',
        label: 'store.editor.label.service_remind_enabled',
      })
      .text({
        prop: 'serviceRemindMobile',
        label: 'store.editor.label.service_remind_mobile',
        placeholder: 'store.editor.placeholder.service_remind_mobile',
        helpText: 'store.editor.help_text.service_remind_mobile',
      })
      .area({
        label: 'store.editor.label.city',
      })
      .text({
        prop: 'address',
        label: 'store.editor.label.address',
        placeholder: 'store.editor.placeholder.address',
        helpText: (_, props: Props) => {
          return <FetchGeoCodingLink {...props} />;
        },
        onBlur: async (_, props: Props) => {
          const entity =
            props.entities.itemBeingCreated || props.entities.itemBeingUpdated;

          if (!entity?.address || (entity.latitude && entity.longitude)) {
            return;
          }

          await execGeocoding(props);
        },
      })
      .text({
        type: 'number',
        prop: 'latitude',
        label: 'store.editor.label.latitude',
        placeholder: 'store.editor.placeholder.latitude',
      })
      .text({
        type: 'number',
        prop: 'longitude',
        label: 'store.editor.label.longitude',
        placeholder: 'store.editor.placeholder.longitude',
      })
      .custom({
        label: 'store.editor.label.medias',
        render: (props: Props) => {
          const entity =
            props.entities.itemBeingCreated || props.entities.itemBeingUpdated;
          const onChange = (medias: StoreMedia[]) => {
            if (props.entities.itemBeingCreated) {
              props.dispatch(
                props.actions.itemBeingCreatedChanged!({ medias }),
              );
            } else {
              props.dispatch(
                props.actions.itemBeingUpdatedChanged!({ medias }),
              );
            }
          };
          return (
            <StoreMediaEditor medias={entity?.medias} onChange={onChange} />
          );
        },
      })
      .text({
        prop: 'businessHours',
        label: 'store.editor.label.business_hours',
        placeholder: 'store.editor.placeholder.business_hours',
      })
      .text({
        prop: 'contactName',
        label: 'store.editor.label.contact.name',
        placeholder: 'store.editor.placeholder.contact.name',
      })
      .text({
        prop: 'contactPhone',
        label: 'store.editor.label.contact.phone',
        placeholder: 'store.editor.placeholder.contact.phone',
      })
      .text({
        prop: 'contactMobile',
        label: 'store.editor.label.contact.mobile',
        placeholder: 'store.editor.placeholder.contact.mobile',
      })
      .text({
        prop: 'contactFax',
        label: 'store.editor.label.contact.fax',
        placeholder: 'store.editor.placeholder.contact.fax',
      })
      .text({
        prop: 'contactEmail',
        label: 'store.editor.label.contact.email',
        placeholder: 'store.editor.placeholder.contact.email',
      })
      .text({
        prop: 'reservationPhone',
        label: 'store.editor.label.reservation_phone',
        placeholder: 'store.editor.placeholder.reservation_phone',
      })
      .text({
        prop: 'rescuePhone',
        label: 'store.editor.label.rescue_phone',
        placeholder: 'store.editor.placeholder.rescue_phone',
      })
      .text({
        prop: 'complainPhone',
        label: 'store.editor.label.complain_phone',
        placeholder: 'store.editor.placeholder.complain_phone',
      })
      .text({
        prop: 'supervisionPhone',
        label: 'store.editor.label.supervision_phone',
        placeholder: 'store.editor.placeholder.supervision_phone',
      })
      .text({
        prop: 'wifiSsid',
        label: 'store.editor.label.wifi_ssid',
        placeholder: 'store.editor.placeholder.wifi_ssid',
      })
      .text({
        prop: 'wifiPassword',
        label: 'store.editor.label.wifi_password',
        placeholder: 'store.editor.placeholder.wifi_password',
      })
      .textArea({
        prop: 'introduction',
        label: 'store.editor.label.introduction',
        placeholder: 'store.editor.placeholder.introduction',
        rows: 6,
      })
      .textArea({
        prop: 'remark',
        label: 'store.editor.label.remark',
        placeholder: 'store.editor.placeholder.remark',
      }),
  )
  .toolbarItems(builder => {
    builder
      .agentPicker({
        prop: 'agentId',
        width: 175,
        placeholder: 'store.toolbar.placeholder.agent',
      })
      .storePicker({
        width: 175,
        orgOnly: true,
        orgPlaceholder: 'store.toolbar.placeholder.organization',
        containerStyle: {
          minWidth: '175px !important',
          width: '175px !important',
        },
      })
      .serviceEditionPicker({
        prop: 'editionId',
        width: 175,
        placeholder: 'store.toolbar.placeholder.edition',
      })
      .select({
        prop: 'isTrialEdition',
        placeholder: 'store.toolbar.placeholder.edition_type',
        clearable: true,
        width: 135,
        values: [
          {
            label: '@string/service_edition_type.normal',
            value: false,
          },
          {
            label: '@string/service_edition_type.trial',
            value: true,
          },
        ],
        onGetOptionLabel(option) {
          return getString(option.label);
        },
      })
      .select({
        prop: 'serviceStatus',
        placeholder: 'store.toolbar.placeholder.service_status',
        clearable: true,
        width: 135,
        values: [
          {
            label: '@string/service_status_type.active',
            value: ServiceEnrollmentStatus.Active,
          },
          {
            label: '@string/service_status_type.expiring',
            value: '__expiring__',
          },
          {
            label: '@string/service_status_type.finished',
            value: ServiceEnrollmentStatus.Finished,
          },
          {
            label: '@string/service_status_type.disabled',
            value: '__disabled__',
          },
        ],
        onGetOptionLabel(option) {
          return getString(option.label);
        },
        onApplyChanges(
          filter,
          value:
            | '__expiring__'
            | '__disabled__'
            | ServiceEnrollmentStatus
            | null
            | undefined,
        ) {
          filter.serviceStatus = undefined;
          filter.isExpiring = undefined;
          filter.enabled = undefined;
          if (value === '__expiring__') {
            filter.isExpiring = true;
          } else if (value === '__disabled__') {
            filter.enabled = false;
          } else if (value) {
            filter.serviceStatus = [value];
          }
        },
      })
      .text({
        prop: 'name',
        placeholder: 'store.toolbar.placeholder.name',
        width: 120,
        placement: 'right',
      })
      .button({
        text: '@string/btn_search',
        placement: 'right',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(storeActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'logoImgUrl',
      width: 150,
      text: 'store.col.store',
      render: (store, extra: Props) => {
        const { orgId } = store;
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          extra.dispatch(storeActions.itemBeingUpdated(store));
        };
        return (
          <OrgInfoView orgId={orgId}>
            {org => (
              <a href="#" onClick={onClick}>
                <img
                  className="org-info__logo"
                  style={{ width: 150 }}
                  src={org?.logoImgUrl || '/img/org-placeholder.png'}
                />
              </a>
            )}
          </OrgInfoView>
        );
      },
    },
    {
      prop: 'name',
      width: 300,
      text: 'col.name',
      render: (store, extra: Props) => {
        const onClick = (e: MouseEvent) => {
          e.preventDefault();
          extra.dispatch(storeActions.itemBeingUpdated(store));
        };
        const onOpenStorefrontClick = (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();

          extra.dispatch(showAppLoading());
          const win = window.open()!;
          win.document.title = 'Please wait... ';
          Promise.all([
            organizationService.get(store.orgId),
            systemService.getOtcLoginCode(store.orgId),
          ])
            .then(([org, code]) => {
              if (org == null) return;
              const urlTemplate: string =
                (window as any).__storeFrontUrl__ ?? config.storeFrontUrl;
              const url = new URL(urlTemplate.replace('%cid%', org.cid));
              url.pathname = '/otc-login';
              url.searchParams.set('code', code);
              win.location.href = url.toString();
            })
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .catch(e => {
              alert(e.message);
            })
            .finally(() => {
              extra.dispatch(hideAppLoading());
            });
        };
        return (
          <div className="org-info__detail">
            <p>
              <a href="#" onClick={onClick}>
                <strong>{store.name}</strong>
                {store.isTrialEdition ? (
                  <span style={{ marginLeft: '0.25rem' }} className="text-info">
                    [<Translate id="store.service_info.label.trial" />]
                  </span>
                ) : null}
              </a>
              <a
                href="#"
                target="_blank"
                style={{ marginLeft: '0.35rem' }}
                onClick={onOpenStorefrontClick}
              >
                <i className="la la-external-link" />
              </a>
            </p>
            <OrgInfoView orgId={store.orgId}>
              {org => (
                <>
                  <dl>
                    <dt>
                      <Translate id="store.org_info.label.org_name" />
                    </dt>
                    <dd>
                      {org?.name} ({org?.shortName})
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <Translate id="store.org_info.label.brand_name" />
                    </dt>
                    <dd>{org?.brandName}</dd>
                  </dl>
                </>
              )}
            </OrgInfoView>
            <AddressInfo entity={store} areas={extra.areas} />
            <ContactInfo entity={store} />
          </div>
        );
      },
    },
    {
      prop: 'editionId',
      width: 250,
      text: 'store.col.service_info',
      render: (store, props: Props) => {
        const onRenewClick = (e: MouseEvent) => {
          e.preventDefault();
          props.dispatch(renewStore(store));
        };
        const renderInfo = (label: string, value: any) =>
          value == null ? null : (
            <dl>
              <dt>
                <Translate id={`store.service_info.label.${label}`} />
              </dt>
              <dd>{value}</dd>
            </dl>
          );
        return (
          <div className="org-info__detail">
            <dl style={{ alignItems: 'center' }}>
              <dt>
                <Translate id="store.service_info.label.service_status" />
              </dt>
              <dd
                style={{
                  display: 'inline-flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {store.isExpiring && store.serviceRenewTime != null ? (
                  <span className="text-danger">
                    <Translate
                      id="store.service_info.label.is_expiring"
                      data={{
                        days: moment(store.serviceRenewTime).diff(
                          moment(),
                          'days',
                        ),
                      }}
                    />
                  </span>
                ) : (
                  <ServiceEnrollmentStatusLabel
                    value={store.serviceStatus}
                    noRounded
                  />
                )}
                <span style={{ marginLeft: '0.3rem', fontWeight: 'bold' }}>
                  [
                  <a
                    href="#"
                    style={{ fontWeight: 'bold' }}
                    onClick={onRenewClick}
                  >
                    <Translate id="store.service_info.label.renew" />
                  </a>
                  ]
                </span>
              </dd>
            </dl>
            {renderInfo(
              'service_enabled',
              <span
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Checkmark
                  value={store.enabled}
                  showFalseIcon
                  style={{
                    fontSize: '0.9rem',
                  }}
                />
                <span
                  className={`text-${store.enabled ? 'success' : 'danger'}`}
                  style={{
                    marginLeft: '0.15rem',
                  }}
                >
                  <Translate
                    id={`store.service_info.label.${
                      store.enabled ? 'enabled' : 'disabled'
                    }`}
                  />
                </span>
              </span>,
            )}
            {renderInfo(
              'edition_name',
              store.editionName ??
                getString('store.service_info.label.default_edition'),
            )}
            {renderInfo('create_time', formatTime(store.createdAt))}
            {renderInfo(
              'service_start_time',
              store.serviceStartTime
                ? formatTime(store.serviceStartTime)
                : null,
            )}
            {renderInfo(
              'renew_time',
              store.serviceRenewTime
                ? formatTime(store.serviceRenewTime)
                : getString('store.service_info.label.no_duration_limit'),
            )}
            {renderInfo(
              'next_service_remind_time',
              !store.serviceRemindEnabled ? (
                <span style={{ color: 'red' }}>
                  {getString(
                    'store.service_info.label.service_remind_disabled',
                  )}
                </span>
              ) : store.nextServiceRenewRemindTime ? (
                formatTime(store.nextServiceRenewRemindTime)
              ) : null,
            )}
            {renderInfo(
              'service_expiry_extension',
              store.serviceExpiryExtension
                ? expiryExtensionValueToString(
                    store.serviceExpiryExtension,
                    false,
                  )
                : null,
            )}
            {renderInfo(
              'user_account_limit',
              store.userAccountLimit ||
                getString('store.service_info.label.no_user_account_limit'),
            )}
            {renderInfo(
              'enabled_services',
              [
                'core',
                store.isTvEditionEnabled ? 'tv' : null,
                store.isDataV1Enabled ? 'data_v1' : null,
                store.isQuotationEnabled ? 'quotation' : null,
                store.isNewBiEnabled ? 'new_bi' : null,
                store.isNewMarketingEnabled ? 'new_marketing' : null,
              ]
                .filter(x => x)
                .map(x => getString('store.service_info.label.service.' + x))
                .join('/'),
            )}
            {renderInfo(
              'sales',
              store.salesName && store.salesMobile
                ? `${store.salesName} (${store.salesMobile})`
                : [store.salesName, store.salesMobile]
                    .filter(x => x?.trim())
                    .join('') || undefined,
            )}
            {renderInfo(
              'service_remind_mobile',
              store.serviceRemindMobile ? (
                <>
                  {store.serviceRemindMobile.split(/[,，]/g).map(mobile => (
                    <span
                      key={mobile}
                      style={{
                        border: '1px dotted #ccc',
                        marginRight: '0.35rem',
                        padding: '0 0.25rem',
                        whiteSpace: 'nowrap',
                        borderRadius: '0.15rem',
                      }}
                    >
                      {mobile}
                    </span>
                  ))}
                </>
              ) : null,
            )}
          </div>
        );
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      hidden: true,
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons([
    'edit',
    'remove',
    {
      key: 'qrcode',
      icon: 'la la-qrcode',
      tooltip: getString('org.qrcode_downloader.btn_tooltip.store'),
      onClick: (item, props: Props) => {
        props.dispatch(downloadQrcodeForStore(item));
      },
    },
    {
      key: 'stats-remind',
      icon: 'la la-pie-chart',
      tooltip: getString('store.send_stats_remind.tooltip'),
      onClick: (item, props: Props) => {
        props.dispatch(
          showAppLoading({
            message: getString('store.send_stats_remind.loading'),
          }),
        );
        organizationService
          .sendStoreStatsRemindNotification(item.orgId, item.id)
          .then(() => {
            props.dispatch(
              showAppLoading({
                message: getString('store.send_stats_remind.success'),
                status: 'success',
                timeout: 5000,
              }),
            );
          })
          .catch(err => {
            console.error(err);
            props.dispatch(
              showAppLoading({
                message:
                  getString('store.send_stats_remind.failed') +
                  ` (${err.message})`,
                status: 'error',
                timeout: 5000,
              }),
            );
          });
      },
    },
  ])
  .onAdd(entity => {
    entity.isFree = false;
    entity.serviceRenewCycle = '1y';
    entity.serviceExpiryExtensionStr = expiryExtensionValueToString(
      entity.serviceExpiryExtension,
      true,
    );
  })
  .onEdit(entity => {
    return {
      ...entity,
      serviceExpiryExtensionStr: expiryExtensionValueToString(
        entity.serviceExpiryExtension,
        true,
      ),
    };
  })
  .validate(entity => {
    if (!entity.editionId || !entity.editionName) {
      throw new Error(getString('store.editor.error.service_edition_required'));
    }

    const name = entity.name?.trim();

    if (!name) {
      throw new Error(getString('store.editor.error.name_required'));
    }

    if (!entity.provinceId || !entity.cityId || !entity.districtId) {
      throw new Error(getString('store.editor.error.city_required'));
    }

    if (!entity.address) {
      throw new Error(getString('store.editor.error.addr_required'));
    }

    if (!entity.latitude || !entity.longitude) {
      throw new Error(getString('store.editor.error.coords_required'));
    }

    const [v, u] = durationFromString(entity.serviceRenewCycle);
    if (!v || !u) {
      throw new Error(getString('store.editor.error.renew_cycle_required'));
    }

    const serviceRemindMobiles = [
      ...new Set(
        (entity.serviceRemindMobile?.trim() ?? '')
          .split(/[,，]/g)
          .map(x => x.trim())
          .filter(x => x),
      ),
    ];

    if (serviceRemindMobiles.some(x => !isMobile(x))) {
      throw new Error(
        getString('store.editor.error.invalid_service_remind_mobile'),
      );
    }

    entity.serviceExpiryExtension = expiryExtensionStringToValue(
      entity.serviceExpiryExtensionStr,
    );
  })
  .onRender((props: Props) => {
    const { activeStoreForQrcodeDownload, activeStoreForRenew } = props;
    return (
      <>
        <AsideRight
          open={Boolean(activeStoreForQrcodeDownload)}
          onClose={() => {
            props.dispatch(downloadQrcodeForStore(null));
          }}
        >
          {(activeStoreForQrcodeDownload && (
            <QrcodeDownloader
              orgId={activeStoreForQrcodeDownload.orgId}
              storeId={activeStoreForQrcodeDownload.id}
            />
          )) ||
            null}
        </AsideRight>
        <RenewAside
          store={activeStoreForRenew ?? undefined}
          onClose={() => props.dispatch(renewStore(null))}
          onRenewed={store => {
            props.dispatch(
              storeActions.updateSuccess(activeStoreForRenew!, store),
            );
            props.dispatch(renewStore(null));
          }}
        />
      </>
    );
  })
  .getClass();

async function execGeocoding(props: Props) {
  const entity =
    props.entities.itemBeingCreated || props.entities.itemBeingUpdated;

  if (!entity) return;

  if (!entity.provinceId || !entity.cityId || !entity.districtId) {
    alert(getString('store.editor.error.city_required'));
    return;
  }

  if (!entity.address) {
    alert(getString('store.editor.error.addr_required'));
    return;
  }

  const province = props.areas?.result?.getNodeById(entity.provinceId!);
  const city = props.areas?.result?.getNodeById(entity.cityId!);
  const address: string[] = [];
  if (
    province?.area.name != null &&
    !entity.address.includes(province.area.name)
  ) {
    address.push(province.area.name);
  }
  if (city?.area.name != null && !entity.address.includes(city.area.name)) {
    address.push(city.area.name);
  }
  address.push(entity.address);

  try {
    const location = await lbsService.geocoding(address.join(''));
    if (props.entities.itemBeingCreated) {
      props.dispatch(
        props.actions.itemBeingCreatedChanged!({
          latitude: location.latitude,
          longitude: location.longitude,
        }),
      );
    } else if (props.entities.itemBeingUpdated) {
      props.dispatch(
        props.actions.itemBeingUpdatedChanged!({
          latitude: location.latitude,
          longitude: location.longitude,
        }),
      );
    }
  } catch (e) {
    alert('geocoding failed: ' + (e as Error).message);
  }
}

function FetchGeoCodingLink(props: Props) {
  const [loading, setLoading] = useState(false);
  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (loading) return;
      setLoading(true);
      execGeocoding(props)
        .catch(() => null)
        .finally(() => setLoading(false));
    },
    [props, loading],
  );
  return (
    <div style={{ marginTop: 8 }}>
      <a href="#" onClick={onClick}>
        {getString('store.editor.help_text.city')}
        {loading && (
          <i
            className="fa fa-spinner fa-pulse"
            style={{ marginLeft: '0.25rem' }}
          />
        )}
      </a>
    </div>
  );
}

function AddressInfo({
  entity,
  areas,
}: {
  entity: Store;
  areas: Areas | null | undefined;
}) {
  if (!entity.provinceId) return null;
  if (!areas?.result || areas?.isLoading) {
    return <>...</>;
  }
  const province = areas.result.getNodeById(entity.provinceId);
  const city = areas.result.getNodeById(entity.cityId!);
  const district = areas.result.getNodeById(entity.districtId!);
  const parts: string[] = [];
  if (province.children.length > 1) {
    parts.push(province.area.name);
  }
  parts.push(city.area.name, district.area.name);
  return (
    <>
      <dl>
        <dt>
          <StringLabel value="store.org_info.label.city" />
        </dt>
        <dd>{parts.join('/')}</dd>
      </dl>
      <dl>
        <dt>
          <StringLabel value="store.org_info.label.address" />
        </dt>
        <dd>{entity.address}</dd>
      </dl>
    </>
  );
}

function ContactInfo({ entity }: { entity: Store }) {
  const style: CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };
  return (
    <>
      {entity.contactName && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.name" />
          </dt>
          <dd>{entity.contactName}</dd>
        </dl>
      )}
      {entity.contactPhone && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.phone" />
          </dt>
          <dd>{entity.contactPhone}</dd>
        </dl>
      )}
      {entity.contactMobile && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.mobile" />
          </dt>
          <dd>{entity.contactMobile}</dd>
        </dl>
      )}
      {entity.contactFax && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.fax" />
          </dt>
          <dd>{entity.contactFax}</dd>
        </dl>
      )}
      {entity.contactEmail && (
        <dl style={style}>
          <dt>
            <StringLabel value="contact_label.email" />
          </dt>
          <dd>{entity.contactEmail}</dd>
        </dl>
      )}
      {entity.reservationPhone && (
        <dl style={style}>
          <dt>
            <StringLabel value="store.editor.label.reservation_phone" />
          </dt>
          <dd>{entity.reservationPhone}</dd>
        </dl>
      )}
      {entity.rescuePhone && (
        <dl style={style}>
          <dt>
            <StringLabel value="store.editor.label.rescue_phone" />
          </dt>
          <dd>{entity.rescuePhone}</dd>
        </dl>
      )}
      {entity.complainPhone && (
        <dl style={style}>
          <dt>
            <StringLabel value="store.editor.label.complain_phone" />
          </dt>
          <dd>{entity.complainPhone}</dd>
        </dl>
      )}
      {entity.supervisionPhone && (
        <dl style={style}>
          <dt>
            <StringLabel value="store.editor.label.supervision_phone" />
          </dt>
          <dd>{entity.supervisionPhone}</dd>
        </dl>
      )}
    </>
  );
}

function expiryExtensionValueToString(
  value: number | null | undefined,
  asInputValue: boolean,
) {
  if (!value) return null;
  let s = value;
  const d = Math.floor(s / (24 * 3600));
  s -= d * 24 * 3600;
  const h = Math.floor(s / 3600);
  s -= h * 3600;
  const m = Math.floor(s / 60);
  s -= m * 60;
  if (asInputValue) {
    const l = [d, h, m, s];
    let i = 0;
    return getString(
      'store.editor.label.service_expiry_extension_mask',
    ).replace(/\d{2}/g, () => l[i++].toString().padStart(2, '0'));
  }
  return getString('store.service_info.label.service_expiry_extension_format', {
    d,
    h,
    m,
    s,
  });
}

function expiryExtensionStringToValue(s: string | null | undefined) {
  if (!s) return null;
  const l: number[] = [];
  let m: RegExpMatchArray | null | undefined = undefined;
  const r = /\d+/g;
  while ((m = r.exec(s))) {
    l.push(Number(m[0]));
  }
  if (l.length === 4) {
    return l[0] * 24 * 3600 + l[1] * 3600 + l[2] * 60 + l[3];
  }
  return null;
}
