/**
 * @file: VehicleReportOpenLogService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleReportOpenLog,
  VehicleReportOpenLogListFilter,
} from 'model';

export class VehicleReportOpenLogServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleReportOpenLogListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleReportOpenLog>
      : VehicleReportOpenLog[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-report-open-logs',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleReportOpenLog | null> {
    return await this.api
      .get()
      .url('/vehicle-report-open-logs/:id', { id })
      .future();
  }

  async create(
    vehicleReportOpenLog: Partial<VehicleReportOpenLog>,
  ): Promise<VehicleReportOpenLog> {
    return await this.api
      .post()
      .url('/vehicle-report-open-logs')
      .data(vehicleReportOpenLog)
      .future();
  }

  async update(
    vehicleReportOpenLog: Partial<VehicleReportOpenLog>,
  ): Promise<VehicleReportOpenLog> {
    return await this.api
      .put()
      .url('/vehicle-report-open-logs/:id', { id: vehicleReportOpenLog.id })
      .data(vehicleReportOpenLog)
      .future();
  }

  async delete(id: number): Promise<void> {
    await this.api
      .delete()
      .url('/vehicle-report-open-logs/:id', { id })
      .future();
  }
}
