import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import {
  VehicleInspectionSiteCheckItem,
  VehicleInspectionSiteCheckItemListFilter,
} from 'model';
import { vehicleInspectionSiteCheckItemService as checkItemService } from 'services';

export const inspectionSiteItemActions = createListAsyncActionCreators<
  AppState,
  VehicleInspectionSiteCheckItem,
  VehicleInspectionSiteCheckItemListFilter,
  number
>('inspection.items', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.items.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.items.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.items.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await checkItemService.list(
      // Object.assign({}, state.inspection.items.filter, { orgId: null, storeId: null }),
      {}, // we perform client site filtering instead
      state.inspection.items.sorts?.[0],
      state.inspection.items.offset,
      state.inspection.items.limit,
      { count: true, recursive: true },
    );
  },
  async create(item: Partial<VehicleInspectionSiteCheckItem>) {
    return await checkItemService.create(item);
  },
  async update(item: VehicleInspectionSiteCheckItem) {
    return await checkItemService.update({
      ...item,
      options: undefined,
    });
  },
  async delete(item: VehicleInspectionSiteCheckItem) {
    await checkItemService.delete(item.id);
  },
  async deleteMulti(items: VehicleInspectionSiteCheckItem[]) {
    if (items.length === 1) {
      await checkItemService.delete(items[0].id);
      return;
    }
    throw new Error('delete multi items is not supported. ');
  },
});
