import { ConstructionTemplateConfig } from 'model';
import { createContext, useContext } from 'react';

type ContextProps = {
  templateConfig: ConstructionTemplateConfig;
  templateConfigBeingEdited: ConstructionTemplateConfig | undefined;
  selectedSidebarItem:
    | { type: 'category'; categoryId: string }
    | { type: 'template'; templateId: string }
    | undefined;
};

export const Context = createContext<ContextProps>(null as any);

export const useConstructionTemplateConfigContext = () => useContext(Context);
