/**
 * @file: VehicleMaintenanceServicePlanService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleMaintenanceServicePlan,
  VehicleMaintenanceServicePlanDetail,
  VehicleMaintenanceServicePlanListFilter,
} from 'model';

export class VehicleMaintenanceServicePlanServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleMaintenanceServicePlanListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleMaintenanceServicePlan>
      : VehicleMaintenanceServicePlan[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-maintenance-service-plans',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleMaintenanceServicePlan | null> {
    return await this.api
      .get()
      .url('/vehicle-maintenance-service-plans/:id', { id })
      .future();
  }

  async getPlanByPlanNo(
    planNo: string,
  ): Promise<VehicleMaintenanceServicePlan | null> {
    if (!planNo) {
      throw new Error('missing planNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-maintenance-service-plans/query/planno', { planNo })
      .future();
  }

  async getPlanByTaskNo(
    taskNo: string,
  ): Promise<VehicleMaintenanceServicePlan | null> {
    if (!taskNo) {
      throw new Error('missing taskNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-maintenance-service-plans/query/taskno', { taskNo })
      .future();
  }

  async getPlanByOrderNo(
    orderNo: string,
  ): Promise<VehicleMaintenanceServicePlan | null> {
    if (!orderNo) {
      throw new Error('missing orderNo parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-maintenance-service-plans/query/orderno', { orderNo })
      .future();
  }

  async create(
    vehicleMaintenanceServicePlan: Partial<VehicleMaintenanceServicePlan>,
  ): Promise<VehicleMaintenanceServicePlan> {
    return await this.api
      .post()
      .url('/vehicle-maintenance-service-plans')
      .data(vehicleMaintenanceServicePlan)
      .future();
  }

  async update(
    vehicleMaintenanceServicePlan: Partial<VehicleMaintenanceServicePlan>,
  ): Promise<VehicleMaintenanceServicePlan> {
    return await this.api
      .put()
      .url('/vehicle-maintenance-service-plans/:id', {
        id: vehicleMaintenanceServicePlan.id,
      })
      .data(vehicleMaintenanceServicePlan)
      .future();
  }

  async delete(id: number): Promise<void> {
    await this.api
      .delete()
      .url('/vehicle-maintenance-service-plans/:id', { id })
      .future();
  }

  async getDetailsByPlanId(
    id: number,
    recursive = false,
  ): Promise<VehicleMaintenanceServicePlanDetail[]> {
    return await this.api
      .get()
      .url('/vehicle-maintenance-service-plans/:id/details', { id, recursive })
      .future();
  }

  async createVehicleMaintenanceServicePlanDetail(
    detail: Partial<VehicleMaintenanceServicePlanDetail>,
  ): Promise<VehicleMaintenanceServicePlanDetail> {
    const id = detail.planId;
    if (!id) {
      throw new Error('detail.planId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-maintenance-service-plans/:id/details')
      .data(detail)
      .future();
  }

  async updateVehicleMaintenanceServicePlanDetail(
    detail: VehicleMaintenanceServicePlanDetail,
  ): Promise<VehicleMaintenanceServicePlanDetail> {
    const id = detail.planId;
    if (!id) {
      throw new Error('detail.planId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-maintenance-service-plans/:id/details')
      .data(detail)
      .future();
  }

  async deleteVehicleMaintenanceServicePlanDetail(
    planId: number | VehicleMaintenanceServicePlanDetail,
    detailId?: number,
  ): Promise<void> {
    if (planId && typeof planId === 'object') {
      detailId = planId.id;
      if (!planId.planId) {
        throw new Error('planId cannot be null or undefined');
      }
      planId = planId.planId;
    }
    if (!planId || !detailId) {
      throw new Error('planId and detailId are both required');
    }
    await this.api
      .delete()
      .url('/vehicle-maintenance-service-plans/:planId/details/:detailId', {
        planId,
        detailId,
      })
      .future();
  }
}
