import { combineReducers } from 'redux';

import adminUsers from './adminUsers';
import adminUsersAcl from './adminUsersAcl';
import configurations from './configurations';
import autoPartNames from './autoPartNames';
import quotationPartCatalogs from './quotationPartCatalogs';
import serviceEditions from './serviceEditions';
import productBrands from './productBrands';

export default combineReducers({
  adminUsers,
  adminUsersAcl,
  configurations,
  autoPartNames,
  quotationPartCatalogs,
  serviceEditions,
  productBrands,
});
