/**
 * @file: CheckResultDataTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { CheckResultDataType, Option } from 'model';

export const CheckResultDataTypeOptions: Array<Option<CheckResultDataType>> = [
  { value: CheckResultDataType.Text, label: 'check_result_data_type.text' },
  { value: CheckResultDataType.Json, label: 'check_result_data_type.json' },
  { value: CheckResultDataType.Xml, label: 'check_result_data_type.xml' },
  { value: CheckResultDataType.Yaml, label: 'check_result_data_type.yaml' },
  { value: CheckResultDataType.Csv, label: 'check_result_data_type.csv' },
  { value: CheckResultDataType.Blob, label: 'check_result_data_type.blob' },
  { value: CheckResultDataType.Custom, label: 'check_result_data_type.custom' },
];

export const CheckResultDataTypeOptionsWithDefault: Array<
  Option<CheckResultDataType | null>
> = [
  { value: null, label: 'check_result_data_type.__default__' },
  { value: CheckResultDataType.Text, label: 'check_result_data_type.text' },
  { value: CheckResultDataType.Json, label: 'check_result_data_type.json' },
  { value: CheckResultDataType.Xml, label: 'check_result_data_type.xml' },
  { value: CheckResultDataType.Yaml, label: 'check_result_data_type.yaml' },
  { value: CheckResultDataType.Csv, label: 'check_result_data_type.csv' },
  { value: CheckResultDataType.Blob, label: 'check_result_data_type.blob' },
  { value: CheckResultDataType.Custom, label: 'check_result_data_type.custom' },
];
