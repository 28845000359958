/**
 * @file: VehicleDeliveryCheckTemplateService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleDeliveryCheckTemplate,
  VehicleDeliveryCheckTemplateListFilter,
} from 'model';

export class VehicleDeliveryCheckTemplateServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleDeliveryCheckTemplateListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleDeliveryCheckTemplate>
      : VehicleDeliveryCheckTemplate[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-delivery-check-templates',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleDeliveryCheckTemplate | null> {
    return await this.api
      .get()
      .url('/vehicle-delivery-check-templates/:id', { id })
      .future();
  }

  async create(
    vehicleDeliveryCheckTemplate: Partial<VehicleDeliveryCheckTemplate>,
  ): Promise<VehicleDeliveryCheckTemplate> {
    return await this.api
      .post()
      .url('/vehicle-delivery-check-templates')
      .data(vehicleDeliveryCheckTemplate)
      .future();
  }

  async update(
    vehicleDeliveryCheckTemplate: Partial<VehicleDeliveryCheckTemplate>,
  ): Promise<VehicleDeliveryCheckTemplate> {
    return await this.api
      .put()
      .url('/vehicle-delivery-check-templates/:id', {
        id: vehicleDeliveryCheckTemplate.id,
      })
      .data(vehicleDeliveryCheckTemplate)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-delivery-check-templates/:id', { id })
      .future();
  }
}
