/**
 * @file: ProductAgentAccountTxCategoryOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ProductAgentAccountTxCategory } from 'model';

export const ProductAgentAccountTxCategoryOptions: Array<
  Option<ProductAgentAccountTxCategory>
> = [
  {
    value: ProductAgentAccountTxCategory.In,
    label: 'product_agent_account_tx_category.in',
  },
  {
    value: ProductAgentAccountTxCategory.Out,
    label: 'product_agent_account_tx_category.out',
  },
];

export const ProductAgentAccountTxCategoryOptionsWithDefault: Array<
  Option<ProductAgentAccountTxCategory | null>
> = [
  { value: null, label: 'product_agent_account_tx_category.__default__' },
  {
    value: ProductAgentAccountTxCategory.In,
    label: 'product_agent_account_tx_category.in',
  },
  {
    value: ProductAgentAccountTxCategory.Out,
    label: 'product_agent_account_tx_category.out',
  },
];
