/**
 * @file: serviceEditions.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { ServiceEdition } from 'model';
import { serviceEditionService } from 'services';

export const serviceEditionActions = createListAsyncActionCreators<
  AppState,
  ServiceEdition
>('app.settings.service_editions', {
  getItemBeingCreated: (state: AppState) =>
    state.settings.serviceEditions.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.settings.serviceEditions.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.settings.serviceEditions.itemsBeingDeleted,

  fetchHandler: (state: AppState) => {
    return serviceEditionService.list(
      state.settings.serviceEditions.filter,
      state.settings.serviceEditions.sorts?.[0],
      state.settings.serviceEditions.offset,
      state.settings.serviceEditions.limit,
      { count: true, recursive: false },
    );
  },
  create: (serviceEdition: ServiceEdition) => {
    return serviceEditionService.create(serviceEdition);
  },
  update: (serviceEdition: ServiceEdition) => {
    return serviceEditionService.update(serviceEdition);
  },
  delete: (serviceEdition: ServiceEdition) => {
    return serviceEditionService.delete(serviceEdition.id);
  },
  deleteMulti: (serviceEditions: ServiceEdition[]) => {
    if (!serviceEditions.length) return Promise.resolve();
    if (serviceEditions.length === 1) {
      return serviceEditionService.delete(serviceEditions[0].id);
    }
    return Promise.reject(
      new Error('delete multi service editions is not supported for now. '),
    );
  },
});
