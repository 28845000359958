/**
 * @file: UserVehicleCertificateTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, UserVehicleCertificateType } from 'model';

export const UserVehicleCertificateTypeOptions: Array<
  Option<UserVehicleCertificateType>
> = [
  {
    value: UserVehicleCertificateType.VehicleLicense,
    label: 'user_vehicle_certificate_type.vehicle_license',
  },
];

export const UserVehicleCertificateTypeOptionsWithDefault: Array<
  Option<UserVehicleCertificateType | null>
> = [
  { value: null, label: 'user_vehicle_certificate_type.__default__' },
  {
    value: UserVehicleCertificateType.VehicleLicense,
    label: 'user_vehicle_certificate_type.vehicle_license',
  },
];
