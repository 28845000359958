/**
 * @file: BatchJobType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum BatchJobType {
  FinishTasks = 'finish-tasks',
}

export const BatchJobTypeValueSet = new Set([BatchJobType.FinishTasks]);
