/**
 * @file: InspectionTaskEventTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionTaskEventType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: InspectionTaskEventType | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

const Values = {
  [InspectionTaskEventType.System]: 'system',
  [InspectionTaskEventType.Creation]: 'creation',
  [InspectionTaskEventType.Inspection]: 'inspection',
  [InspectionTaskEventType.Quotation]: 'quotation',
  [InspectionTaskEventType.Construction]: 'construction',
  [InspectionTaskEventType.Completion]: 'completion',
  [InspectionTaskEventType.PreInspection]: 'pre_inspection',
  [InspectionTaskEventType.DeliveryCheck]: 'delivery_check',
};

export const InspectionTaskEventTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.InspectionTaskEventType?.[enumValue]
      ? LabelColors.InspectionTaskEventType[enumValue]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`inspection_task_event_type.${enumValue}`} />
    </Badge>
  );
});
