import React, { Component } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { Column, DataTable } from 'shared/metronic/components';
import { getScopedTransFunction } from 'app';
import { RecentVehicleInfos } from './duck/states';
import { VehicleInfo } from 'model';
import { formatTime } from 'utils';

interface Props extends LocalizeContextProps {
  vehicleInfos: RecentVehicleInfos;
}

export class RecentVehicleInfoListComponent extends Component<Props> {
  private columns: Array<Column<VehicleInfo>>;

  constructor(props: Props, context: any) {
    super(props, context);
    this.init();
  }

  render() {
    const { vehicleInfos } = this.props;
    return (
      <DataTable
        columns={this.columns}
        idProp="id"
        selModel="none"
        data={vehicleInfos.result}
        isLoading={vehicleInfos.isLoading}
        minHeight={0}
        error={vehicleInfos.error}
        />
    );
  }

  private init() {
    const { translate } = this.props;
    const trans = getScopedTransFunction(translate, '');
    this.columns = [{
      prop: 'id',
      width: 60,
      text: '',
      render: (vehicleInfo: VehicleInfo) => {
        return (
          <img
            src={vehicleInfo.brandLogo || ''}
            style={{ width: 60 }}
            />
        );
      }
    }, {
      prop: 'vin',
      width: 100,
      text: trans('vehicle.col.vin')
    }, {
      prop: 'name',
      width: 250,
      text: trans(`vehicle.col.name`)
    }, {
      prop: 'brand',
      width: 100,
      text: trans('vehicle.col.brand_name')
    }, {
      prop: 'modelName',
      width: 80,
      text: trans('vehicle.col.model_name')
    }, {
      prop: 'seriesName',
      width: 100,
      text: trans('vehicle.col.series_name')
    }, {
      prop: 'manufacturer',
      width: 100,
      text: trans('vehicle.col.manufacturer')
    }, {
      prop: 'carType',
      width: 100,
      text: trans('vehicle.col.car_type')
    }, {
      prop: 'modelYear',
      width: 100,
      text: trans('vehicle.col.model_year')
    }, {
      prop: 'emissionStandard',
      width: 100,
      text: trans('vehicle.col.emission_standard')
    }, {
      prop: 'fuelConsumption',
      width: 100,
      text: trans('vehicle.col.fuel_consumption')
    }, {
      prop: 'engineType',
      width: 100,
      text: trans('vehicle.col.engine_type')
    }, {
      prop: 'fuelType',
      width: 100,
      text: trans('vehicle.col.fuel_type')
    }, {
      prop: 'fuelLevel',
      width: 100,
      text: trans('vehicle.col.fuel_level')
    }, {
      prop: 'transmissionType',
      width: 100,
      text: trans('vehicle.col.transmission_type')
    }, {
      prop: 'gearType',
      width: 100,
      text: trans('vehicle.col.gear_type')
    }, {
      prop: 'gearbox',
      width: 100,
      text: trans('vehicle.col.gearbox')
    }, {
      prop: 'gearNum',
      width: 100,
      text: trans('vehicle.col.gear_num')
    }, {
      prop: 'driveMode',
      width: 100,
      text: trans('vehicle.col.drive_mode')
    }, {
      prop: 'carBodyType',
      width: 100,
      text: trans('vehicle.col.car_body_type')
    }, {
      prop: 'frontTireSize',
      width: 100,
      text: trans('vehicle.col.front_tire_size')
    }, {
      prop: 'rearTireSize',
      width: 100,
      text: trans('vehicle.col.rear_tire_size')
    }, {
      prop: 'parkingBrakeType',
      width: 100,
      text: trans('vehicle.col.parking_brake_type')
    }, {
      prop: 'maxPower',
      width: 100,
      text: trans('vehicle.col.max_power')
    }, {
      prop: 'vehicleLevel',
      width: 100,
      text: trans('vehicle.col.vehicle_level')
    }, {
      prop: 'seatNum',
      width: 100,
      text: trans('vehicle.col.seat_num')
    }, {
      prop: 'bodyStructure',
      width: 100,
      text: trans('vehicle.col.body_structure')
    }, {
      prop: 'cylinderType',
      width: 100,
      text: trans('vehicle.col.cylinder_type')
    }, {
      prop: 'productionYear',
      width: 100,
      text: trans('vehicle.col.production_year')
    }, {
      prop: 'onboardMonth',
      width: 100,
      text: trans('vehicle.col.onboard_month')
    }, {
      prop: 'stopTime',
      width: 100,
      text: trans('vehicle.col.stop_time')
    }, {
      prop: 'guidePrice',
      width: 100,
      text: trans('vehicle.col.guide_price')
    }, {
      prop: 'createdAt',
      text: trans('col.created_at'),
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt)
    }];
  }
}

export const RecentVehicleInfoList = withLocalize(RecentVehicleInfoListComponent);