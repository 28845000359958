import { Component } from 'react';

interface Props {
  title: string;
}

export class DocumentTitle extends Component<Props> {
  private prevTitle: string;

  componentDidMount() {
    this.prevTitle = document.title;
    document.title = this.props.title;
  }

  componentWillUnmount() {
    document.title = this.prevTitle;
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.title !== this.props.title) {
      document.title = nextProps.title;
    }
  }

  render() {
    return null;
  }
}