import { APIService } from 'lib/restful-client/api.service';
import { ConstructionTemplateConfig } from 'model';

export class ConstructionTemplateConfigService {
  constructor(private readonly api: APIService) {}

  async getConstructionTemplateConfig(): Promise<ConstructionTemplateConfig> {
    return await this.api.get().url('/construction-template-config').future();
  }

  async saveConstructionTemplateConfig(config: ConstructionTemplateConfig) {
    return await this.api
      .post()
      .url('/construction-template-config')
      .data({ config })
      .future();
  }
}
