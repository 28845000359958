import classNames from 'classnames';
import {
  FileUploadResult,
  ImageFileExtraInfo,
  StoreMedia,
  StoreMediaCategory,
  VideoFileExtraInfo,
} from 'model';
import { ChangeEvent, Component, FocusEvent, MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { commonService, mediaService } from 'services';
import { CheckButton, getString } from 'shared/components';
import { Button } from 'shared/metronic/components';

import './MediaEditor.scss';

interface Props {
  medias?: StoreMedia[];
  storeId?: number;
  onChange?: (medias: StoreMedia[]) => void;
}

interface State {
  selectedMediaUrl: string | undefined;
  thumbnailsMap: Map<string, string[]>;
  isUploading: boolean;
  uploadError: Error | null;
  isGeneratingThumbnails: boolean;
  generateThumbnailsError: Error | null;
}

const Accepts: string[] = ['image/jpeg', 'image/png', 'video/mp4'];

export class StoreMediaEditor extends Component<Props, State> {
  state: State = {
    selectedMediaUrl: undefined,
    thumbnailsMap: new Map(),
    isUploading: false,
    uploadError: null,
    isGeneratingThumbnails: false,
    generateThumbnailsError: null,
  };

  render() {
    return <div className="store-media-editor">{this.renderContents()}</div>;
  }

  renderContents() {
    const { medias } = this.props;
    const { selectedMediaUrl, isUploading, uploadError } = this.state;

    if (!medias?.length) {
      const add_btn = (
        <Button file link accepts={Accepts} onFileChange={this.onFileChange}>
          <Translate id="store.media_editor.add_btn" />
        </Button>
      );
      return (
        <div className="store-media-editor__empty">
          {isUploading && (
            <span className="store-media-editor__empty-loading">
              <i className="la la-spinner fa-pulse" />
              <Translate id="store.media_editor.uploading" />
            </span>
          )}
          {uploadError && (
            <span className="store-media-editor__empty-error">
              <Translate
                id="store.media_editor.upload_error"
                data={{
                  message: uploadError.message,
                }}
              />
            </span>
          )}
          {!isUploading && (
            <Translate id="store.media_editor.empty" data={{ add_btn }} />
          )}
        </div>
      );
    }

    return (
      <>
        {uploadError && (
          <div className="store-media-editor__empty-error">
            <Translate
              id="store.media_editor.upload_error"
              data={{
                message: uploadError.message,
              }}
            />
          </div>
        )}
        <div className="store-media-editor__list">
          {medias.map(media => (
            <a
              href="#"
              key={media.url}
              data-url={media.url}
              className={classNames('store-media-editor__item', {
                'store-media-editor__item--selected':
                  media.url === selectedMediaUrl,
                'store-media-editor__item--image':
                  media.type.startsWith('image/'),
                'store-media-editor__item--video':
                  media.type.startsWith('video/'),
              })}
              onClick={this.onMediaClick}
            >
              <span
                style={{
                  backgroundImage: `url("${media.coverUrl!}")`,
                }}
              />
              {media.type.startsWith('video/') && (
                <strong>
                  <i className="fa fa-play" />
                </strong>
              )}
              <em onClick={this.onRemove} data-url={media.url}>
                <i className="fa fa-times" />
              </em>
            </a>
          ))}
          <Button
            file
            accepts={Accepts}
            onFileChange={this.onFileChange}
            className="store-media-editor__item store-media-editor__add-btn"
            disabled={isUploading}
          >
            <span>
              {isUploading ? (
                <i className="la la-spinner fa-pulse" />
              ) : (
                <i className="la la-plus" />
              )}
            </span>
          </Button>
        </div>
        {selectedMediaUrl && this.renderMediaDetail()}
      </>
    );
  }

  renderMediaDetail() {
    const { selectedMediaUrl, thumbnailsMap } = this.state;
    const media = this.props.medias!.find(x => x.url === selectedMediaUrl);
    if (!media) return null;
    const thumbnails = thumbnailsMap.get(media.url) || [];
    return (
      <div className="store-media-editor__media-detail">
        {media.type.startsWith('image/') && (
          <img
            src={media.url}
            className="store-media-editor__media-object"
            key={media.url}
          />
        )}
        {media.type.startsWith('video/') && (
          <video
            poster={media.coverUrl!}
            controls
            key={media.url}
            preload="none"
          >
            <source type={media.type} src={media.url} />
          </video>
        )}
        <div className="store-media-editor__media-extra">
          <div className="store-media-editor__media-extra-row">
            <label htmlFor={`mediatitle.${media.url}`}>
              <Translate id="store.media_editor.label.title" />
            </label>
            <input
              type="text"
              name="title"
              id={`mediatitle.${media.url}`}
              className="form-control form-control-sm"
              defaultValue={media.title || ''}
              key={media.url}
              data-url={media.url}
              data-prop="title"
              onBlur={this.onMediaTitleOrDescBlur}
            />
          </div>
          <div className="store-media-editor__media-extra-row">
            <label htmlFor={`mediadesc.${media.url}`}>
              <Translate id="store.media_editor.label.desc" />
            </label>
            <input
              type="text"
              name="title"
              id={`mediadesc.${media.url}`}
              className="form-control form-control-sm"
              defaultValue={media.description || ''}
              key={media.url}
              data-url={media.url}
              data-prop="description"
              onBlur={this.onMediaTitleOrDescBlur}
            />
          </div>
          {media.type.startsWith('video/') && (
            <div className="store-media-editor__media-extra-row">
              <label className="store-media-editor__thumbnail-label">
                <Translate
                  id="store.media_editor.label.thumbnail"
                  data={{
                    upload_btn: (
                      <Button
                        file
                        link
                        accepts={Accepts.filter(x => x.startsWith('image/'))}
                        onFileChange={this.onThumbnailChange}
                      >
                        <Translate id="store.media_editor.upload_thumbnail_btn" />
                      </Button>
                    ),
                  }}
                />
              </label>
              {!thumbnails.length && !this.state.isGeneratingThumbnails && (
                <div className="store-media-editor__empty">
                  <Translate
                    id="store.media_editor.empty_thumbnails"
                    data={{
                      generate_btn: (
                        <a href="#" onClick={this.onGenerateThumbnails}>
                          <Translate id="store.media_editor.generate_thumbnail_btn" />
                        </a>
                      ),
                    }}
                  />
                </div>
              )}
              {!thumbnails.length && this.state.isGeneratingThumbnails && (
                <div className="store-media-editor__empty-loading">
                  <i className="la la-spinner fa-pulse" />
                  <Translate id="store.media_editor.generating_thumbnails" />
                </div>
              )}
              {!thumbnails.length && this.state.generateThumbnailsError && (
                <div className="store-media-editor__empty-error">
                  <Translate
                    id="store.media_editor.generating_thumbnails_error"
                    data={{
                      message: this.state.generateThumbnailsError.message,
                    }}
                  />
                </div>
              )}
              <div className="store-media-editor__video-thumbnails">
                {thumbnails.map(thumbnail => (
                  <a
                    href="#"
                    key={thumbnail}
                    data-url={thumbnail}
                    onClick={this.onThumbnailClick}
                    className={classNames({
                      selected: thumbnail === media.coverUrl,
                    })}
                    style={{
                      width: 60,
                      height: (media.height / media.width) * 60,
                      backgroundImage: `url("${thumbnail}")`,
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {media.type.startsWith('image/') && (
            <div className="store-media-editor__media-extra-row">
              <CheckButton
                label={getString('store.media_editor.label.set_as_head_img')}
                data-url={media.url}
                checked={media.category === StoreMediaCategory.HeadImage}
                onChange={this.onMediaCategoryChange}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = this.acceptFile(e, Accepts, 'invalid_file_type');
    if (!file) return;

    this.setState({ isUploading: true, uploadError: null });
    let uploadResult: FileUploadResult | undefined = undefined;
    try {
      uploadResult = await this.uploadFile(file, true);
      this.setState({ isUploading: false });
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (e) {
      this.setState({ isUploading: false, uploadError: e });
    }

    if (!uploadResult) return;

    console.log(uploadResult);
    const extra = /^image\//i.test(file.type)
      ? (uploadResult.extra! as ImageFileExtraInfo)
      : (uploadResult.extra! as VideoFileExtraInfo);

    const media: Partial<StoreMedia> = {
      storeId: this.props.storeId,
      category: StoreMediaCategory.Default,
      type: file.type,
      coverUrl: extra.cover,
      url: uploadResult.url,
      width: extra.width,
      height: extra.height,
      duration: 0,
    };

    if (/^video\//i.test(file.type)) {
      const videoExtra = uploadResult.extra! as VideoFileExtraInfo;
      media.duration = videoExtra.duration;
      const thumbnailsMap = new Map(this.state.thumbnailsMap);
      thumbnailsMap.set(uploadResult.url, videoExtra.thumbnails || []);
      this.setState({ thumbnailsMap });
    }

    const medias = [...(this.props.medias || []), media as any];

    this.props.onChange && this.props.onChange(medias);
  };

  onGenerateThumbnails = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const medias = this.props.medias!;
    const media = medias.find(x => x.url === this.state.selectedMediaUrl)!;
    try {
      this.setState({
        isGeneratingThumbnails: true,
        generateThumbnailsError: null,
      });
      const thumbnails = await mediaService.generateVideoTumbnails(
        media.url,
        10,
        320,
        'store',
      );
      const thumbnailsMap = new Map(this.state.thumbnailsMap);
      thumbnailsMap.set(media.url, thumbnails);
      this.setState({ isGeneratingThumbnails: false, thumbnailsMap });
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (e) {
      this.setState({
        isGeneratingThumbnails: false,
        generateThumbnailsError: e,
      });
    }
  };

  onThumbnailChange = async (e: ChangeEvent<HTMLInputElement>) => {
    // upload thumbnail
    const file = this.acceptFile(e, Accepts, 'invalid_thumbnail_file_type');
    if (!file) return;

    try {
      const result = await this.uploadFile(file, false);
      this.updateVideoThumbnail(result.url);
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (e) {
      alert(e.message);
    }
  };

  onMediaClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const url = e.currentTarget.getAttribute('data-url')!;
    if (this.state.selectedMediaUrl === url) return;
    if (this.state.isUploading || this.state.isGeneratingThumbnails) {
      if (this.state.selectedMediaUrl) return;
    }

    this.setState({ selectedMediaUrl: url });
  };

  onThumbnailClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute('data-url')!;
    this.updateVideoThumbnail(url);
  };

  onMediaTitleOrDescBlur = (e: FocusEvent<HTMLInputElement>) => {
    const url = e.target.getAttribute('data-url')!;
    const prop = e.target.getAttribute('data-prop') as keyof StoreMedia;
    const value = e.target.value;
    let medias = this.props.medias!;
    const index = medias.findIndex(x => x.url === url);
    const media = medias[index];
    const oldValue = media[prop];
    if (oldValue === value) return;
    medias = medias.map((x, i) => (i === index ? { ...x, [prop]: value } : x));
    this.props.onChange && this.props.onChange(medias);
  };

  onMediaCategoryChange = (
    _value: any,
    checked: boolean,
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const url = e.target.getAttribute('data-url')!;
    let medias = this.props.medias!;
    const index = medias.findIndex(x => x.url === url);
    medias = medias.map((x, i) => ({
      ...x,
      category:
        i === index && checked
          ? StoreMediaCategory.HeadImage
          : StoreMediaCategory.Default,
    }));
    this.props.onChange && this.props.onChange(medias);
  };

  onRemove = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const url = e.currentTarget.getAttribute('data-url')!;
    const medias = [...this.props.medias!];
    const index = medias.findIndex(x => x.url === url);
    medias.splice(index, 1);
    if (this.state.selectedMediaUrl === url) {
      this.setState({ selectedMediaUrl: undefined });
    }
    this.props.onChange && this.props.onChange(medias);
  };

  acceptFile(
    e: ChangeEvent<HTMLInputElement>,
    accepts: string[],
    error: string,
  ): File | false {
    if (!e.target.files?.length) return false;
    const file = e.target.files[0];
    if (!accepts.includes(file.type)) {
      alert(getString(`store.media_editor.${error}`));
      return false;
    }
    return file;
  }

  async uploadFile(file: File, cover: boolean): Promise<FileUploadResult> {
    const form = new FormData();
    form.append('realm', 'store');
    form.append('filename', file.name);
    form.append('size', String(file.size));
    form.append('type', file.type);
    form.append('blob', file);
    form.append('cover', cover ? '1' : '0');
    return await commonService.uploadFile(form);
  }

  updateVideoThumbnail(url: string) {
    let medias = this.props.medias!;
    const index = medias.findIndex(x => x.url === this.state.selectedMediaUrl)!;
    medias = medias.map((x, i) => (i === index ? { ...x, coverUrl: url } : x));
    this.props.onChange && this.props.onChange(medias);
  }
}
