import { quotationTplActions } from 'app/inspection/duck/actions';
import { useSelectedSubject } from 'app/inspection/quotation-template-config/hooks/useSelectedSubject';
import { QuotationTemplateItemView } from 'app/inspection/quotation-template-config/items/ItemView';
import { NoSubjectSelectedPlaceholder } from 'app/inspection/quotation-template-config/items/NoSubjectSelected';
import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import { makeItemRef } from 'app/inspection/quotation-template-config/util';
import { QuotationSubjectRef, QuotationTemplateItemStaged } from 'model';
import { FC, memo, ReactNode, useCallback, useMemo } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  SortEndHandler,
} from 'react-sortable-hoc';
import { EmptyItems } from './Empty';

export const ItemPanel = memo(({ editable }: { editable: boolean }) => {
  const dispatch = useDispatch();

  const selectedSubject = useSelectedSubject();
  const subjectRef = useMemo<QuotationSubjectRef | undefined>(
    () =>
      selectedSubject == null
        ? undefined
        : {
            ...selectedSubject.groupRef,
            subjectId: selectedSubject.id,
          },
    [selectedSubject],
  );

  const onAddItem = useCallback(() => {
    if (subjectRef == null) return;
    dispatch(quotationTplActions.addItem(subjectRef));
  }, [dispatch, subjectRef]);

  const onEditItem = useCallback(
    (item: QuotationTemplateItemStaged) => {
      if (subjectRef == null) return;
      const itemRef = makeItemRef(subjectRef, item.id);
      dispatch(quotationTplActions.editItem(itemRef));
    },
    [dispatch, subjectRef],
  );

  const onRemoveItem = useCallback(
    (item: QuotationTemplateItemStaged) => {
      if (subjectRef == null) return;
      const itemRef = makeItemRef(subjectRef, item.id);
      dispatch(quotationTplActions.removeItem(itemRef));
    },
    [dispatch, subjectRef],
  );

  const onCheckChange = useCallback(
    (item: QuotationTemplateItemStaged, checked: boolean) => {
      if (subjectRef == null) return;
      const itemRef = makeItemRef(subjectRef, item.id);
      dispatch(quotationTplActions.itemCheckChanged(itemRef, checked));
    },
    [dispatch, subjectRef],
  );

  const onItemSortEnd: SortEndHandler = useCallback(
    e => {
      if (subjectRef == null) return;
      const { newIndex, oldIndex } = e;
      if (newIndex === oldIndex) return;
      dispatch(quotationTplActions.itemMoved(subjectRef, oldIndex, newIndex));
    },
    [dispatch, subjectRef],
  );

  const onAddMaterial = useCallback(
    (item: QuotationTemplateItemStaged) => {
      if (subjectRef == null) return;
      const itemRef = makeItemRef(subjectRef, item.id);
      dispatch(quotationTplActions.addMaterial(itemRef));
    },
    [dispatch, subjectRef],
  );

  return (
    <>
      <div className="quotation-tpl__item-panel">
        {selectedSubject == null ? (
          <NoSubjectSelectedPlaceholder />
        ) : selectedSubject.items?.length ? (
          <>
            <div className="quotation-tpl__item-panel-hd">
              <h3>
                <Translate id="quotation_tpl.item.list.title" />
                {editable && (
                  <LinkButton onClick={onAddItem}>
                    <i className={`la la-plus`} />
                  </LinkButton>
                )}
              </h3>
            </div>
            <div className="quotation-tpl__item-panel-body">
              <SortableItemList
                helperClass="quotation-tpl__item--being-dragged"
                onSortEnd={onItemSortEnd}
                lockAxis="y"
                useWindowAsScrollContainer={true}
                distance={4}
                useDragHandle
              >
                {selectedSubject.items.map((item, index) => (
                  <SortableItemListItem
                    key={item.id}
                    index={index}
                    item={item}
                    editable={editable}
                    disabled={!editable}
                    onAddMaterial={onAddMaterial}
                    onEdit={onEditItem}
                    onRemove={onRemoveItem}
                    onCheckChange={onCheckChange}
                  />
                ))}
              </SortableItemList>
            </div>
          </>
        ) : (
          <EmptyItems onAddItem={onAddItem} />
        )}
      </div>
    </>
  );
});

const ItemListContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="quotation-tpl__item-list-sortable">{children}</div>;
};

export const SortableItemList = SortableContainer(ItemListContainer);
export const SortableItemListItem = SortableElement(QuotationTemplateItemView);
