import { api, tokenService } from 'lib';
import { buildSimpleTreeModel } from 'lib/helpers';
import {
  ColorType,
  InspectionSiteNodeFilter,
  VehicleInspectionSiteListFilter,
} from 'model';
import { InspectionSiteInventoryImportResult } from 'model/viewmodel/InspectionSiteInventoryImportResult';
import { ChangeEvent, Component } from 'react';
import { vehicleInspectionSiteService } from 'services';
import {
  ListToolbar,
  ToolbarItem,
  ToolbarItemsBuilder,
  getString,
} from 'shared/components';
import { InspectionSiteCategories } from '../duck/states';

const supportedInventoryDataFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/gzip',
  'application/x-tar',
  'application/x-gzip',
  'application/json',
];

interface Props {
  selection: number[];
  filter: Partial<VehicleInspectionSiteListFilter>;
  categories: InspectionSiteCategories;
  isImporting: boolean;
  activeGroupKey: string;
  activeCategoryId?: number | null;
  onImport: () => void;
  onImportSuccess: (result: InspectionSiteInventoryImportResult) => void;
  onImportFailed: (error: Error) => void;
  onFilterChange: (filter: Partial<VehicleInspectionSiteListFilter>) => void;
  onKeywordChange?: (keyword: string) => void;
  onActiveGroupChange?: (key: string, categoryId?: number | null) => void;
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onDelete: () => void;
  onBatchSetType: () => void;
  onBatchSetModule: () => void;
}

export class InspectionSiteToolbarV2 extends Component<Props> {
  render() {
    return (
      <ListToolbar
        filter={this.props.filter}
        items={this.buildToolbarItems()}
        onFilterChange={this.onFilterChange}
        onGetExtraInfo={this.onGetExtraInfo}
      />
    );
  }

  onFilterChange = (filter: Partial<VehicleInspectionSiteListFilter>) => {
    this.props.onFilterChange(filter);
  };

  onGetExtraInfo = () => this.props;

  buildToolbarItems(): Array<ToolbarItem<any>> {
    const {
      categories,
      activeGroupKey,
      activeCategoryId,
      selection,
      onDelete,
      onBatchSetType,
      onBatchSetModule,
    } = this.props;

    const builder = new ToolbarItemsBuilder<InspectionSiteNodeFilter>();
    const activeColor = 'brand';
    const inactiveColor = 'secondary';
    const color = (active: boolean): ColorType => {
      return active ? activeColor : inactiveColor;
    };
    const nodes = buildSimpleTreeModel(
      categories.result || [],
      x => x.id,
      x => x.parentCategoryId,
    );
    return builder
      .buttonGroup(group => {
        group.withPlacement('left');
        group.button({
          key: 'all',
          text: 'inspection_site.toolbar.button.all_categories',
          color: color(activeGroupKey === 'all'),
          onClick: () => {
            if (activeGroupKey === 'all') return;
            this.props.onActiveGroupChange &&
              this.props.onActiveGroupChange('all');
          },
        });
        group.button({
          key: 'hardware_capable',
          text: 'inspection_site.toolbar.button.hardware_capable',
          color: color(activeGroupKey === 'hardware'),
          onClick: () => {
            if (activeGroupKey === 'hardware') return;
            this.props.onActiveGroupChange &&
              this.props.onActiveGroupChange('hardware');
          },
        });
        for (const node of nodes) {
          const active =
            activeGroupKey.startsWith('category') &&
            activeCategoryId === node.data.id;
          group.button({
            key: String(node.data.id),
            text: node.data.name,
            color: color(active),
            onClick: () => {
              if (active) return;
              this.props.onActiveGroupChange &&
                this.props.onActiveGroupChange(
                  `category:${node.data.id}`,
                  node.data.id,
                );
            },
          });
        }
      })
      .button({
        text: () => {
          if (this.props.isImporting) {
            return 'inspection_site.import_sites.progress_msg';
          }
          return 'inspection_site.toolbar.button.import';
        },
        loading: () => this.props.isImporting,
        shouldDisable: () => this.props.isImporting,
        color: 'info',
        file: true,
        accepts: supportedInventoryDataFileTypes,
        onFileChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (!e.target.files?.length) return;
          const file = e.target.files[0];
          console.log(file.type);
          if (
            (file.type &&
              !supportedInventoryDataFileTypes.includes(file.type)) ||
            (file.name &&
              !file.name.toLowerCase().endsWith('.xlsx') &&
              !file.name.toLowerCase().endsWith('.tar.gz') &&
              !file.name.toLowerCase().endsWith('.json'))
          ) {
            alert(getString('inspection_site.import_sites.invalid_file'));
            return;
          }
          const reader = new FileReader();
          reader.addEventListener('load', async () => {
            const dataUri = reader.result as string;
            this.props.onImport();
            try {
              const result =
                await vehicleInspectionSiteService.importInventory(dataUri);
              this.props.onImportSuccess(result);
              // eslint-disable-next-line @typescript-eslint/no-shadow
            } catch (e) {
              this.props.onImportFailed(e);
            }
          });
          reader.readAsDataURL(file);
        },
      })
      .button({
        text: 'inspection_site.toolbar.button.export',
        onClick: () => {
          const token = tokenService.getToken();
          const url = api.url('/vehicle-inspection-sites/inventory/export', {
            token,
          });
          window.open(url);
        },
      })
      .button({
        key: 'delete',
        text: '@string/delete_btn_text',
        color: 'danger',
        onClick: onDelete,
        disabled: selection.length === 0,
        hidden: selection.length === 0,
      })
      .button({
        key: 'batch_set_type',
        text: 'inspection_site.toolbar.button.batch_set_type',
        color: 'info',
        onClick: onBatchSetType,
        disabled: selection.length === 0,
        hidden: selection.length === 0,
      })
      .button({
        key: 'batch_set_module',
        text: 'inspection_site.toolbar.button.batch_set_module',
        color: 'info',
        onClick: onBatchSetModule,
        disabled: selection.length === 0,
        hidden: selection.length === 0,
      })
      .text({
        prop: 'keyword',
        placement: 'right',
        // label: 'inspection_site.toolbar.label.keyword',
        placeholder: 'inspection_site.toolbar.placeholder.keyword',
        immediate: true,
        onChange: this.props.onKeywordChange,
      })
      .buttonGroup(group =>
        group
          .withPlacement('right')
          .button({
            key: 'expand_all',
            text: 'inspection_site.toolbar.button.expand_all',
            color: 'secondary',
            onClick: () => {
              this.props.onExpandAll();
            },
          })
          .button({
            key: 'collapse_all',
            text: 'inspection_site.toolbar.button.collapse_all',
            color: 'secondary',
            onClick: () => {
              this.props.onCollapseAll();
            },
          }),
      )
      .build();
  }
}
