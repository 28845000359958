import { AbnormalLevel, InspectionReportItem, VehicleReport } from 'model';
import { Component } from 'react';
import {
  InspectionOrderTypeLabel,
  ScopedTranslate,
  StringLabel,
} from 'shared/components';
import { formatTime } from 'utils';
import { ReportResultItemList } from './ItemList';

interface Props {
  report: VehicleReport | null | undefined;
}

export class ReportDetailView extends Component<Props> {
  render() {
    const { report } = this.props;

    if (!report) return null;

    const normals: InspectionReportItem[] = [];
    const abnormals: InspectionReportItem[] = [];
    const urgents: InspectionReportItem[] = [];

    if (report.inspection?.items) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const detail of report.inspection?.items) {
        if (detail.abnormalLevel === AbnormalLevel.Fine) {
          normals.push(detail);
        } else if (detail.abnormalLevel !== AbnormalLevel.Urgent) {
          urgents.push(detail);
        } else {
          abnormals.push(detail);
        }
      }
    }

    return (
      <ScopedTranslate scope="reports.detail.label">
        {({ trans }) => (
          <div className="report-detail">
            <dl>
              <dt>{trans('org_name')}</dt>
              <dd>{report.orgName}</dd>
            </dl>
            <dl>
              <dt>{trans('store_name')}</dt>
              <dd>{report.storeName}</dd>
            </dl>
            <dl>
              <dt>{trans('report_no')}</dt>
              <dd>{report._id}</dd>
            </dl>
            <dl>
              <dt>{trans('order_no')}</dt>
              <dd>{report.orderNo}</dd>
            </dl>
            <dl>
              <dt>{trans('order_type')}</dt>
              <dd>
                <InspectionOrderTypeLabel value={report.orderType} />
              </dd>
            </dl>
            <dl>
              <dt>{trans('vin')}</dt>
              <dd>{report.vin}</dd>
            </dl>
            <dl>
              <dt>{trans('license_plate_no')}</dt>
              <dd>{report.licensePlateNo}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_name')}</dt>
              <dd>{report.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_brand')}</dt>
              <dd>{report.vehicleBrandName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_model')}</dt>
              <dd>{report.vehicleName}</dd>
            </dl>
            <dl>
              <dt>{trans('vehicle_mileage')}</dt>
              {report.vehicleMileage! < 1
                ? report.vehicleMileage! * 10000
                : report.vehicleMileage}{' '}
              <StringLabel
                value={
                  report.vehicleMileage! < 1
                    ? 'value_unit.km'
                    : 'value_unit.ten_thousands_km'
                }
              />
            </dl>
            <dl>
              <dt>{trans('sa_name')}</dt>
              <dd>{report.serviceAgentName}</dd>
            </dl>
            <dl>
              <dt>{trans('created_at')}</dt>
              <dd>{formatTime(report.createdAt)}</dd>
            </dl>

            <div>
              <ReportResultItemList cls="urgent" items={urgents} />
              <ReportResultItemList cls="abnormal" items={abnormals} />
              <ReportResultItemList cls="normal" items={normals} />
            </div>
          </div>
        )}
      </ScopedTranslate>
    );
  }
}
