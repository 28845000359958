/**
 * @file: org-users.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { OrgUser } from 'model';
import { OrgUsers } from '../states';
import { ActionTypes } from '../types';

const initialState: OrgUsers = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<OrgUser, OrgUsers>(
  'customers.org_users',
  initialState,
  {
    mapItemKey: x => x.id,
  },
);

export default function userReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: OrgUsers = initialState,
  action: StandardAction<any>,
): OrgUsers {
  switch (action.type) {
    case ActionTypes.OrgUserQrcodeAuth: {
      return Object.assign({}, state, {
        currentQrcodeAuthUserPrincipal: action.payload,
      });
    }
    default: {
      return defaultReducer(state, action);
    }
  }
}
