import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { VehicleSystemModule, VehicleSystemModuleListFilter } from 'model';
import { vehicleSystemModuleService } from 'services';

export const vehicleSystemModuleActions = createListAsyncActionCreators<
  AppState,
  VehicleSystemModule,
  VehicleSystemModuleListFilter,
  number
>('inspection.vehicle_system_modules', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.vehicleSystemModules.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.vehicleSystemModules.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.vehicleSystemModules.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleSystemModuleService.list(
      Object.assign({}, state.inspection.vehicleSystemModules.filter, {
        orgId: null,
        storeId: null,
      }),
      state.inspection.vehicleSystemModules.sorts?.[0],
      state.inspection.vehicleSystemModules.offset,
      state.inspection.vehicleSystemModules.limit,
      { count: true, recursive: true },
    );
  },
  async create(vehicleSystemModule: Partial<VehicleSystemModule>) {
    vehicleSystemModule = {
      ...vehicleSystemModule,
      parentModId: vehicleSystemModule.parentModId ?? 0,
    };
    return await vehicleSystemModuleService.create(vehicleSystemModule);
  },
  async update(vehicleSystemModule: VehicleSystemModule) {
    vehicleSystemModule = {
      ...vehicleSystemModule,
      parentModId: vehicleSystemModule.parentModId ?? 0,
    };
    return await vehicleSystemModuleService.update(vehicleSystemModule);
  },
  async delete(vehicleSystemModule: VehicleSystemModule) {
    await vehicleSystemModuleService.delete(vehicleSystemModule.id);
  },
  async deleteMulti(vehicleSystemModules: VehicleSystemModule[]) {
    if (vehicleSystemModules.length === 1) {
      await vehicleSystemModuleService.delete(vehicleSystemModules[0].id);
      return;
    }
    throw new Error('delete multi vehicleSystemModules is not supported. ');
  },
});
