import classNames from 'classnames';
import { Component, ReactNode } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #1a102a;
  background-image: url('vendors/metronic/base/images/bg/bg-2.jpg');
`;

const Container = styled.div`
  width: 430px !important;
  margin: 0 auto;
`;

interface Props {
  className?: string;
  title: string;
  desc?: string;
  children?: ReactNode;
}

export class Layout extends Component<Props> {
  render() {
    const { children, className, title, desc } = this.props;
    const cls = classNames(
      'm-grid__item m-grid__item--fluid m-grid m-grid--hor',
      'm-login m-login--2 m-login-2--skin-2',
      className,
    );
    return (
      <Wrapper className={cls}>
        <div className="m-grid__item m-grid__item--fluid m-login__wrapper">
          <Container className="m-login__container">
            <div className={className}>
              <div className="m-login__head">
                <h3 className="m-login__title" style={{ fontSize: '2rem' }}>
                  {title}
                </h3>
                <div className="m-login__desc">{desc}</div>
              </div>
              {children}
            </div>
          </Container>
        </div>
      </Wrapper>
    );
  }
}
