import { quotationTplActions } from 'app/inspection/duck/actions';
import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmModal } from '../../../../shared/components/ConfirmModal';
import { useQuotationTemplateConfigContext } from '../../Context';

export const ConfirmRemoveMaterialModal = memo(() => {
  const { state } = useQuotationTemplateConfigContext();
  const dispatch = useDispatch();

  const onConfirmRemove = useCallback(() => {
    dispatch(quotationTplActions.commitRemoveMaterial());
  }, [dispatch]);

  const onCancelRemove = useCallback(() => {
    dispatch(quotationTplActions.cancelRemoveMaterial());
  }, [dispatch]);

  return (
    <ConfirmModal
      open={state.materialRefBeingRemoved != null}
      title="quotation_tpl.material.modal.confirm_remove.title"
      content="quotation_tpl.material.modal.confirm_remove.msg"
      onConfirm={onConfirmRemove}
      onCancel={onCancelRemove}
    />
  );
});
