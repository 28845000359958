import { FileUploadResult, ImageFileExtraInfo, MediaObject } from 'model';
import React, { MouseEvent, PureComponent } from 'react';
import { FilePicker } from 'shared/components/FilePicker';
import { renderFilePreviewer } from '../helpers';
import { FormElementMedias } from '../types';
import { nanoid } from 'nanoid/non-secure';

interface Props<T> {
  element: FormElementMedias<T>;
  disabled?: boolean;
  medias: MediaObject[];
  previewWidth: number;
  previewHeight: number;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (medias: MediaObject[], extra: any) => void;
}

export class FormMediasPicker<T> extends PureComponent<Props<T>> {
  render() {
    const { element, medias, disabled, previewWidth, previewHeight } =
      this.props;
    const pickerInfo = element.filePicker || {};
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {medias.map(media => (
          <div
            key={media.id}
            style={{
              marginRight: 8,
              position: 'relative',
            }}
          >
            {renderFilePreviewer({
              type: media.type,
              url: media.url,
              width: previewWidth,
              height: previewHeight,
              posterUrl: media.cover,
              style: {
                border: '1px solid #ebedf2',
                borderRadius: 5,
                backgroundColor: '#eee',
              },
              coverStyle: {
                backgroundSize: 'cover',
              },
            })}
            <a
              href="#"
              data-media-id={media.id}
              onClick={this.onRemoveClick}
              style={{
                display: 'flex',
                width: 14,
                height: 14,
                borderRadius: 7,
                backgroundColor: 'red',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                right: -5,
                top: -5,
              }}
            >
              <i
                className="la la-remove"
                style={{ fontSize: 12, color: '#fff' }}
              />
            </a>
          </div>
        ))}
        <FilePicker
          disabled={disabled}
          cover={pickerInfo.cover}
          imageSize={pickerInfo.mediaSize}
          imageWidth={pickerInfo.mediaWidth}
          imageHeight={pickerInfo.mediaHeight}
          coverSize={pickerInfo.coverSize}
          coverWidth={pickerInfo.coverWidth}
          coverHeight={pickerInfo.coverHeight}
          accept={pickerInfo.accept}
          realm={pickerInfo.realm}
          onChange={this.onChange}
          noUploadingMsg={true}
          style={{
            width: previewWidth,
            height: previewHeight,
            overflow: 'hidden',
            borderRadius: 5,
          }}
          uploadingMsgStyle={{
            background: 'none',
            boxShadow: 'none',
          }}
          placeholder={false}
        >
          {() =>
            renderFilePreviewer({
              type: 'image',
              url: '',
              width: previewWidth,
              height: previewHeight,
            })
          }
        </FilePicker>
      </div>
    );
  }

  onRemoveClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const mediaId = e.currentTarget.getAttribute('data-media-id');
    const index = this.props.medias.findIndex(x => x.id === mediaId);
    if (index < 0) return;
    const medias = [...this.props.medias];
    medias.splice(index, 1);
    const { onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    this.props.onChange(medias, extra);
  };

  onChange = (file: FileUploadResult) => {
    const { element, onChange, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const pickerInfo = element.filePicker || {};
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = file.url as any;
    const media: MediaObject = {
      id: nanoid(),
      url: file.url,
      type: file.type.includes('video') ? 'video' : 'image',
    };
    if (pickerInfo.cover && file.extra) {
      const coverUrl = (file.extra as ImageFileExtraInfo).cover;
      media.cover = coverUrl as any;
    }
    onChange([...this.props.medias, media], extra);
  };
}
