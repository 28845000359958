/**
 * @file: isMobile.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

const REGEX_MOBILE = /^1\d{10}$/;

export function isMobile(s: string): boolean {
  return REGEX_MOBILE.test(s);
}
