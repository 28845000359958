import { AppState } from 'app';
import { showAppLoading } from 'app/duck/actions';
import classNames from 'classnames';
import { DispatchFn } from 'lib/duck/interfaces';
import {
  AclObjectList,
  OpenApiApp,
  OpenApiAppAuthorizedStore,
  OpenApiAppListFilter,
  OpenApiUser,
  Organization,
  Store,
} from 'model';
import {
  CSSProperties,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import {
  openApiAppAuthorizedStoreService,
  openApiService,
  openApiUserService,
} from 'services';
import {
  AsideRight,
  Checkmark,
  EntityListComponentClassBuilder,
  EntityListProps,
  getString,
  Select,
  StorePicker,
} from 'shared/components';
import { Alert, Button, Column, DataTable } from 'shared/metronic/components';
import { formatTime } from 'utils';
import { isEmail } from 'utils/validators';
import {
  manageOpenapiAppAuthorizedStores,
  openApiAppActions,
} from '../duck/actions';

import './index.scss';

interface Props extends EntityListProps<OpenApiApp, OpenApiAppListFilter> {
  appForManageAuthorizedStores?: OpenApiApp | null;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OpenApiApp,
  OpenApiAppListFilter,
  number,
  Props
>();

export const OpenApiAppList = componentClassBuilder
  .i18nPrefix('openapi_apps')
  .accessRights({
    full: AclObjectList.OpenApiAppFullAccess,
    readonly: AclObjectList.OpenApiAppReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="openapi.breadcrumb.it" /> },
    { text: <Translate id="openapi.breadcrumb.apps" /> },
  ])
  .entities(state => state.openapi.apps)
  .actions(openApiAppActions)
  .mapStateToProps(state => ({
    appForManageAuthorizedStores:
      state.openapi.apps.appForManageAuthorizedStores,
  }))
  .editor(builder =>
    builder
      .reactSelect({
        prop: 'userId',
        label: 'openapi_apps.editor.label.app_user',
        placeholder: 'openapi_apps.editor.placeholder.app_user',
        async: true,
        defaultValues: true,
        valueProp: 'id',
        labelProp: 'name',
        values: [],
        stateId: 'openapi_app_users',
        onLoadValues: async () => {
          return await openApiUserService.list({}, null, 0, 0, {
            recursive: false,
          });
        },
      })
      .text({
        prop: 'name',
        label: 'openapi_apps.editor.label.name',
        placeholder: 'openapi_apps.editor.placeholder.name',
        helpText: 'openapi_apps.editor.help_text.name',
        autocomplete: false,
      })
      .text({
        type: 'text',
        prop: 'appKey',
        label: 'openapi_apps.editor.label.app_key',
        placeholder: 'openapi_apps.editor.placeholder.app_key',
        helpText: 'openapi_apps.editor.help_text.app_key',
        readonly: true,
      })
      .textArea({
        prop: 'description',
        label: 'openapi_apps.editor.label.description',
        placeholder: 'openapi_apps.editor.placeholder.description',
        autocomplete: false,
      })
      .checkbox({
        prop: 'enabled',
        label: 'openapi_apps.editor.label.enabled',
      })
      .checkbox({
        prop: 'isEventWebhookEnabled',
        label: 'openapi_apps.editor.label.is_event_webhook_enabled',
      })
      .text({
        type: 'text',
        prop: 'eventWebhookUrl',
        label: 'openapi_apps.editor.label.event_webhook_url',
        placeholder: 'openapi_apps.editor.placeholder.event_webhook_url',
        helpText: 'openapi_apps.editor.help_text.event_webhook_url',
      }),
  )
  .toolbarItems(builder =>
    builder
      .custom({
        prop: 'userId',
        render: (_filter, applyChanges) => {
          const onChange = (value: OpenApiUser | undefined) => {
            applyChanges(changes => {
              changes.userId = value?.id;
            });
          };
          return <AppUserPicker onChange={onChange} />;
        },
      })
      .text({
        prop: 'name',
        label: 'openapi_apps.toolbar.label.app_name',
        placeholder: 'openapi_apps.toolbar.placeholder.app_name',
      })
      .text({
        prop: 'appKey',
        label: 'openapi_apps.toolbar.label.app_key',
        placeholder: 'openapi_apps.toolbar.placeholder.app_key',
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(openApiAppActions.invalidate(true));
        },
      }),
  )
  .addActionButtons(['edit', 'remove'])
  .columns([
    {
      prop: 'userDisplayName',
      width: 150,
      text: 'openapi_apps.col.user_name',
    },
    {
      prop: 'name',
      width: 120,
      text: 'openapi_apps.col.name',
    },
    {
      prop: 'appKey',
      width: 250,
      text: 'openapi_apps.col.app_key',
    },
    {
      prop: 'appSecret',
      width: 150,
      text: 'openapi_apps.col.app_secret',
      align: 'center',
      render: (entity: OpenApiApp, props: Props) => (
        <AppSecretCell appKey={entity.appKey} dispatch={props.dispatch} />
      ),
    },
    {
      prop: 'enabled',
      width: 80,
      text: 'col.enabled',
      align: 'center',
      render: ({ enabled }) => (
        <i
          className={classNames({
            'fa fa-user-check': enabled,
            'fa fa-user-times': !enabled,
            'm--font-success': enabled,
            'm--font-danger': !enabled,
          })}
        />
      ),
    },
    {
      prop: 'isEventWebhookEnabled',
      width: 80,
      text: 'openapi_apps.col.is_event_webhook_enabled',
      align: 'center',
      render: ({ isEventWebhookEnabled }) => (
        <Checkmark value={isEventWebhookEnabled} />
      ),
    },
    {
      prop: 'authorizedStores',
      width: 120,
      text: 'openapi_apps.col.authorized_store_count',
      align: 'center',
      render: (entity: OpenApiApp) => <AuthorizedStoreCountCell app={entity} />,
    },
    {
      prop: 'createdAt',
      width: 150,
      text: 'col.created_at',
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt!),
    },
  ])
  .onAdd(app => {
    app.enabled = true;
  })
  .validate(entity => {
    const userId = entity.userId;
    const name = entity.name?.trim();

    let msg = '';

    if (!userId) {
      msg = 'app_user_required';
    } else if (!name) {
      msg = 'name_required';
    }

    if (entity.isEventWebhookEnabled && !entity.eventWebhookUrl) {
      msg = 'webhook_url_required';
    }

    if (msg) {
      throw new Error(getString(`openapi_apps.editor.error.${msg}`));
    }
  })
  .onRender(props => {
    const app = props.appForManageAuthorizedStores;
    return (
      <AsideRight
        open={Boolean(app)}
        width={1024}
        onClose={() => {
          props.dispatch(manageOpenapiAppAuthorizedStores(null));
        }}
      >
        {app && <AuthorizedStoresEditor app={app} />}
      </AsideRight>
    );
  })
  .getClass();

function AppUserPicker(props: {
  onChange: (user: OpenApiUser | undefined) => void;
}) {
  const [users, setUsers] = useState<OpenApiUser[]>([]);
  const [selected, setSelected] = useState<OpenApiUser | null | undefined>(
    null,
  );

  useEffect(() => {
    openApiUserService
      .list({}, null, 0, 0, { recursive: false })
      .then(results => {
        setUsers(results);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const onChange = useCallback(
    (user: OpenApiUser | undefined) => {
      setSelected(user);
      props.onChange(user);
    },
    [props],
  );

  return (
    <Select<OpenApiUser>
      valueProp="id"
      labelProp="name"
      placeholder={getString('openapi_apps.toolbar.placeholder.app_user')}
      isClearable
      selectedValue={selected}
      values={users}
      noOptionsMessage={getString('openapi_apps.no_app_users')}
      onChange={onChange}
      width={200}
    />
  );
}

function AppSecretCell(props: {
  appKey: string;
  dispatch: DispatchFn<AppState>;
}) {
  const onSend = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      const email = prompt(
        getString('openapi_apps.app_secret_email.msg'),
        '',
      )?.trim();
      if (!email) return;
      if (!isEmail(email)) {
        alert(getString('openapi_users.editor.error.invalid_email'));
        return;
      }
      props.dispatch(
        showAppLoading({
          status: 'loading',
          message: getString('openapi_apps.app_secret_email.loading'),
        }),
      );
      openApiService
        .sendAppSecretEmail(props.appKey, email)
        .then(() => {
          props.dispatch(
            showAppLoading({
              status: 'success',
              message: getString('openapi_apps.app_secret_email.success'),
              timeout: 3000,
            }),
          );
        })
        .catch(err => {
          props.dispatch(
            showAppLoading({
              status: 'error',
              message: getString('openapi_apps.app_secret_email.fail', {
                msg: err.message,
              }),
              timeout: 3000,
            }),
          );
        });
    },
    [props],
  );

  return (
    <a href="#" onClick={onSend}>
      <Translate id="openapi_apps.app_secret_email.btn_text" />
    </a>
  );
}

function AuthorizedStoreCountCell({ app }: { app: OpenApiApp }) {
  const dispatch = useDispatch();
  const onClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      dispatch(manageOpenapiAppAuthorizedStores(app));
    },
    [app, dispatch],
  );
  return (
    <a href="#" onClick={onClick}>
      <Translate
        id="openapi_apps.cell.authorized_store_count"
        data={{ count: app.authorizedStores!.length }}
      />
    </a>
  );
}

function AuthorizedStoresEditor({ app }: { app: OpenApiApp }) {
  const dispatch = useDispatch();

  const [error, setError] = useState<Error | undefined>();

  const getIdFromEvent = useCallback((e: MouseEvent) => {
    return Number(e.currentTarget.getAttribute('data-id'));
  }, []);

  const commitUpdate = useCallback(
    (authorizedStore: OpenApiAppAuthorizedStore) => {
      setError(undefined);
      openApiAppAuthorizedStoreService
        .update(authorizedStore)
        .then(result => {
          const authorizedStores = app.authorizedStores?.map(x =>
            x.id === authorizedStore.id ? result : x,
          );
          dispatch(
            openApiAppActions.updateSuccess(app, { ...app, authorizedStores }),
          );
          dispatch(
            manageOpenapiAppAuthorizedStores({ ...app, authorizedStores }),
          );
        })
        .catch(err => {
          setError(err);
        });
    },
    [app, dispatch],
  );

  const onEnable = useCallback(
    (e: MouseEvent) => {
      const id = getIdFromEvent(e);
      const authorizedStore = {
        ...app.authorizedStores!.find(x => x.id === id)!,
      };
      authorizedStore.enabled = true;
      commitUpdate(authorizedStore);
    },
    [app.authorizedStores, commitUpdate, getIdFromEvent],
  );

  const onDisable = useCallback(
    (e: MouseEvent) => {
      const id = getIdFromEvent(e);
      const authorizedStore = {
        ...app.authorizedStores!.find(x => x.id === id)!,
      };
      authorizedStore.enabled = false;
      commitUpdate(authorizedStore);
    },
    [app.authorizedStores, commitUpdate, getIdFromEvent],
  );

  const onDelete = useCallback(
    (e: MouseEvent) => {
      if (!confirm(getString('openapi_apps.authorized_stores.delete.msg'))) {
        return;
      }
      const id = getIdFromEvent(e);
      setError(undefined);
      openApiAppAuthorizedStoreService
        .delete(id)
        .then(() => {
          const authorizedStores = app.authorizedStores?.filter(
            x => x.id !== id,
          );
          dispatch(
            openApiAppActions.updateSuccess(app, { ...app, authorizedStores }),
          );
          dispatch(
            manageOpenapiAppAuthorizedStores({ ...app, authorizedStores }),
          );
        })
        .catch(err => {
          setError(err);
        });
    },
    [app, dispatch, getIdFromEvent],
  );

  const [org, setOrg] = useState<Organization | null | undefined>();
  const [store, setStore] = useState<Store | null | undefined>();

  const onStoreChange = useCallback(
    (
      _orgId: number | undefined,
      _storeId: number | undefined,
      selectedOrg: Organization | null,
      selectedStore: Store | null,
    ) => {
      setOrg(selectedOrg);
      setStore(selectedStore);
    },
    [],
  );

  const onAuthorize = useCallback(() => {
    const authorizedStore: Partial<OpenApiAppAuthorizedStore> = {
      appId: app.id,
      orgId: org?.id,
      storeId: store?.id,
      orgName: org?.name,
      storeName: store?.name,
      authorizedAt: new Date(),
      enabled: true,
    };
    setError(undefined);
    openApiAppAuthorizedStoreService
      .create(authorizedStore)
      .then(result => {
        const authorizedStores = [...app.authorizedStores!, result];
        dispatch(
          openApiAppActions.updateSuccess(app, { ...app, authorizedStores }),
        );
        dispatch(
          manageOpenapiAppAuthorizedStores({ ...app, authorizedStores }),
        );
        setStore(null);
      })
      .catch(err => {
        setError(err);
      });
  }, [app, org, store, dispatch]);

  const onCancel = useCallback(() => {
    dispatch(manageOpenapiAppAuthorizedStores(null));
  }, [dispatch]);

  const columns = useMemo<Array<Column<OpenApiAppAuthorizedStore>>>(() => {
    return [
      {
        prop: 'orgName',
        text: getString('col.org_name'),
        width: 100,
      },
      {
        prop: 'storeName',
        text: getString('col.store_name'),
        width: 100,
      },
      {
        prop: 'accessKey',
        text: getString('openapi_apps.authorized_stores.col.access_key'),
        width: 300,
        render: ({ accessKey }) => (
          <span
            style={{
              fontSize: '0.8rem',
              fontFamily: 'monospace',
            }}
          >
            {accessKey}
          </span>
        ),
      },
      {
        prop: 'authorizedStoreId',
        text: getString('openapi_apps.authorized_stores.col.store_id'),
        width: 150,
        render: ({ authorizedStoreId }) => (
          <span
            style={{
              fontSize: '0.8rem',
              fontFamily: 'monospace',
            }}
          >
            {authorizedStoreId}
          </span>
        ),
      },
      {
        prop: 'authorizedAt',
        text: getString('openapi_apps.authorized_stores.col.authorized_at'),
        width: 80,
        align: 'center',
        render: ({ authorizedAt }) => formatTime(authorizedAt, 'YYYY/MM/DD'),
      },
      {
        prop: 'id',
        text: getString('col.actions'),
        width: 130,
        align: 'center',
        render: (authorizedStore: OpenApiAppAuthorizedStore) => {
          const btnStyle: CSSProperties = {
            padding: '0.2rem 0.35rem',
            fontSize: '0.8rem',
            margin: '0 2px',
          };
          return (
            <span style={{ whiteSpace: 'nowrap' }}>
              <button
                className={`btn btn-sm ${
                  authorizedStore.enabled ? 'btn-warning' : 'btn-success'
                }`}
                onClick={authorizedStore.enabled ? onDisable : onEnable}
                data-id={authorizedStore.id}
                style={btnStyle}
              >
                <Translate
                  id={`openapi_apps.authorized_stores.btn.${
                    authorizedStore.enabled ? 'disable' : 'enable'
                  }`}
                />
              </button>
              <button
                className="btn btn-sm btn-danger"
                onClick={onDelete}
                data-id={authorizedStore.id}
                style={btnStyle}
              >
                <Translate id={`openapi_apps.authorized_stores.btn.delete`} />
              </button>
            </span>
          );
        },
      },
    ];
  }, [onDelete, onDisable, onEnable]);

  return (
    <div className="entity-editor-sidebar openapi-app-authorized-stores">
      <ul
        className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
        style={{ marginBottom: 15 }}
      >
        <li className="nav-item m-tabs__item">
          <a
            className="nav-link m-tabs__link active"
            style={{ fontWeight: 'bold', fontSize: '120%' }}
          >
            <Translate id="openapi_apps.authorized_stores.title" />
          </a>
        </li>
      </ul>
      {error && (
        <Alert color="danger" icon="fa fa-exclamation-triangle">
          {error.message}
        </Alert>
      )}
      <div>
        <Translate id="openapi_apps.authorized_stores.label.store" />
      </div>
      <div className="openapi-app-authorized-stores__store-picker">
        <StorePicker
          orgId={org?.id}
          storeId={store?.id}
          onChange={onStoreChange}
        />
        <Button color="success" disabled={!org || !store} onClick={onAuthorize}>
          <Translate id="openapi_apps.authorized_stores.btn.authorize" />
        </Button>
      </div>
      <DataTable<OpenApiAppAuthorizedStore, number>
        data={app.authorizedStores!}
        columns={columns}
        selModel="none"
        idProp="id"
        tableStyle={{ marginBottom: 60 }}
      />
      <div
        className="m-portlet__foot m-portlet__foot--fit mt-5"
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          className="m-form__actions m-form__actions text-center"
          style={{
            backgroundColor: '#f9f9f9',
            padding: '8px',
          }}
        >
          <Button color="default" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
        </div>
      </div>
    </div>
  );
}
