import { AppState } from 'app/duck/states';
import {
  createListAsyncActionCreators,
  createStandardAction,
} from 'lib/duck/actions';
import { SysBatchJob, SysBatchJobListFilter } from 'model';
import { sysBatchJobService } from 'services';
import { ActionTypes } from '../types';

export const sysBatchJobActions = createListAsyncActionCreators<
  AppState,
  SysBatchJob,
  SysBatchJobListFilter,
  number
>('batch_jobs', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) => state.sysBatchJobs.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) => state.sysBatchJobs.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.sysBatchJobs.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await sysBatchJobService.list(
      state.sysBatchJobs.filter,
      state.sysBatchJobs.sorts?.[0],
      state.sysBatchJobs.offset,
      state.sysBatchJobs.limit,
      { count: true, recursive: true },
    );
  },
  async create(sysBatchJob: Partial<SysBatchJob>) {
    return await sysBatchJobService.create(sysBatchJob);
  },
  update() {
    throw new Error('Update batch job is not supported');
  },
  async delete(sysBatchJob: SysBatchJob) {
    await sysBatchJobService.delete(sysBatchJob.id);
  },
  async deleteMulti(orgs: SysBatchJob[]) {
    if (orgs.length === 1) {
      await sysBatchJobService.delete(orgs[0].id);
      return;
    }
    throw new Error('delete multi batch jobs is not supported. ');
  },
});

export function showAddBatchJob(show: boolean, existing?: SysBatchJob) {
  return createStandardAction(ActionTypes.ShowAddBatchJob, {
    show,
    existing: show ? existing : undefined,
  });
}
