/**
 * @file: VehicleSubscriptionConnectionType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum VehicleSubscriptionConnectionType {
  OrderCreation = 1,
  OrderQrcodeScan = 2,
  OpenApi = 3,
}

export const VehicleSubscriptionConnectionTypeValueSet = new Set([
  VehicleSubscriptionConnectionType.OrderCreation,
  VehicleSubscriptionConnectionType.OrderQrcodeScan,
  VehicleSubscriptionConnectionType.OpenApi,
]);
