/**
 * @file: VehicleInspectionSiteMaintenancePeriodService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionSiteMaintenancePeriod,
  VehicleInspectionSiteMaintenancePeriodListFilter,
} from 'model';

export class VehicleInspectionSiteMaintenancePeriodServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionSiteMaintenancePeriodListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionSiteMaintenancePeriod>
      : VehicleInspectionSiteMaintenancePeriod[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-site-maintenance-periods',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(
    id: number,
  ): Promise<VehicleInspectionSiteMaintenancePeriod | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-site-maintenance-periods/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionSiteMaintenancePeriod: Partial<VehicleInspectionSiteMaintenancePeriod>,
  ): Promise<VehicleInspectionSiteMaintenancePeriod> {
    return await this.api
      .post()
      .url('/vehicle-inspection-site-maintenance-periods')
      .data(vehicleInspectionSiteMaintenancePeriod)
      .future();
  }

  async update(
    vehicleInspectionSiteMaintenancePeriod: Partial<VehicleInspectionSiteMaintenancePeriod>,
  ): Promise<VehicleInspectionSiteMaintenancePeriod> {
    return await this.api
      .put()
      .url('/vehicle-inspection-site-maintenance-periods/:id', {
        id: vehicleInspectionSiteMaintenancePeriod.id,
      })
      .data(vehicleInspectionSiteMaintenancePeriod)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-site-maintenance-periods/:id', { id })
      .future();
  }
}
