/**
 * @file: StoreServiceEnrollmentService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StoreServiceEnrollmentServiceBase } from './base/StoreServiceEnrollmentService';

export class StoreServiceEnrollmentService extends StoreServiceEnrollmentServiceBase {
  // add custom service implementation here
}
