/**
 * @file: VehicleInspectionTaskCheckSiteItemMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskCheckSiteItemMediaServiceBase } from './base/VehicleInspectionTaskCheckSiteItemMediaService';

export class VehicleInspectionTaskCheckSiteItemMediaService extends VehicleInspectionTaskCheckSiteItemMediaServiceBase {
  // add custom service implementation here
}
