import React, { Component } from 'react';
import { withAcl, AclContextProps } from 'lib/decorators/acl';
import { Identity } from 'model';
import { Translate } from 'react-localize-redux';

interface Props {
  rights: string | string[];
  accessDeniedView?: any;
  silent?: boolean;
  children?: ((identity: Identity, hasRights: boolean) => any) | any;
}

@withAcl()
export class Restricted extends Component<Props> {
  render() {
    const {
      rights,
      $has,
      children,
      identity,
      accessDeniedView,
      silent
    } = (this.props as Props & AclContextProps);

    const hasRights = Array.isArray(rights) ?
      $has(...rights) :
      $has(rights);

    if (typeof children === 'function') {
      return (children as any)(identity, hasRights);
    }

    if (!hasRights) {
      if (silent) return null;

      return (
        <div className="restricted-no-rights">
        {accessDeniedView ||
          <div className="restricted-no-rights--default-content">
            <i className="la la-lightbulb-o icon" />
            <Translate id="access_denied_placeholder" />
          </div>}
        </div>
      );
    }

    const count = React.Children.count(children);

    if (count === 1) return children;

    return (<>{children}</>);
  }
}