/**
 * @file: VehicleMaintenanceServicePlanService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleMaintenanceServicePlanServiceBase } from './base/VehicleMaintenanceServicePlanService';

export class VehicleMaintenanceServicePlanService extends VehicleMaintenanceServicePlanServiceBase {
  // add custom service implementation here
}
