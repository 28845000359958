/**
 * @file: QuantitativeTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, QuantitativeType } from 'model';

export const QuantitativeTypeOptions: Array<Option<QuantitativeType>> = [
  {
    value: QuantitativeType.Quantitative,
    label: 'quantitative_type.quantitative',
  },
  {
    value: QuantitativeType.NonQuantitative,
    label: 'quantitative_type.non_quantitative',
  },
];

export const QuantitativeTypeOptionsWithDefault: Array<
  Option<QuantitativeType | null>
> = [
  { value: null, label: 'quantitative_type.__default__' },
  {
    value: QuantitativeType.Quantitative,
    label: 'quantitative_type.quantitative',
  },
  {
    value: QuantitativeType.NonQuantitative,
    label: 'quantitative_type.non_quantitative',
  },
];
