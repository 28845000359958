import { defineActionTypes } from 'lib';

export const ActionTypes = defineActionTypes('app/customers', {
  DownloadQrcodeForStore: '',
  DownloadQrcodeForOrg: '',
  RenewStore: '',
  OrgUserQrcodeAuth: '',
  OrgMemberQrcodeAuth: '',
  SetActiveProductAgentForTxHistory: '',
});
