/**
 * @file: InspectionSiteTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionSiteType, Option } from 'model';

export const InspectionSiteTypeOptions: Array<Option<InspectionSiteType>> = [
  { value: InspectionSiteType.Default, label: 'inspection_site_type.default' },
  { value: InspectionSiteType.Facade, label: 'inspection_site_type.facade' },
  {
    value: InspectionSiteType.Dashboard,
    label: 'inspection_site_type.dashboard',
  },
];

export const InspectionSiteTypeOptionsWithDefault: Array<
  Option<InspectionSiteType | null>
> = [
  { value: null, label: 'inspection_site_type.__default__' },
  { value: InspectionSiteType.Default, label: 'inspection_site_type.default' },
  { value: InspectionSiteType.Facade, label: 'inspection_site_type.facade' },
  {
    value: InspectionSiteType.Dashboard,
    label: 'inspection_site_type.dashboard',
  },
];
