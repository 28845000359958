/**
 * @file: VehicleInspectionTaskDiagnosticJobMediaService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskDiagnosticJobMedia,
  VehicleInspectionTaskDiagnosticJobMediaListFilter,
} from 'model';

export class VehicleInspectionTaskDiagnosticJobMediaServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskDiagnosticJobMediaListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskDiagnosticJobMedia>
      : VehicleInspectionTaskDiagnosticJobMedia[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-diagnostic-job-medias',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(
    id: number,
  ): Promise<VehicleInspectionTaskDiagnosticJobMedia | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-diagnostic-job-medias/:id', { id })
      .future();
  }

  async getMediaByCid(
    cid: string,
  ): Promise<VehicleInspectionTaskDiagnosticJobMedia | null> {
    if (!cid) {
      throw new Error('missing cid parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-task-diagnostic-job-medias/query/cid', { cid })
      .future();
  }

  async create(
    vehicleInspectionTaskDiagnosticJobMedia: Partial<VehicleInspectionTaskDiagnosticJobMedia>,
  ): Promise<VehicleInspectionTaskDiagnosticJobMedia> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-diagnostic-job-medias')
      .data(vehicleInspectionTaskDiagnosticJobMedia)
      .future();
  }

  async update(
    vehicleInspectionTaskDiagnosticJobMedia: Partial<VehicleInspectionTaskDiagnosticJobMedia>,
  ): Promise<VehicleInspectionTaskDiagnosticJobMedia> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-diagnostic-job-medias/:id', {
        id: vehicleInspectionTaskDiagnosticJobMedia.id,
      })
      .data(vehicleInspectionTaskDiagnosticJobMedia)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-diagnostic-job-medias/:id', { id })
      .future();
  }
}
