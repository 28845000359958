import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { InspectionTool, InspectionToolListFilter } from 'model';
import { inspectionToolService } from 'services';

export const inspectionToolActions = createListAsyncActionCreators<
  AppState,
  InspectionTool,
  InspectionToolListFilter,
  number
>('inspection.tools', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.tools.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.tools.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.tools.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await inspectionToolService.list(
      state.inspection.tools.filter,
      state.inspection.tools.sorts?.[0],
      state.inspection.tools.offset,
      state.inspection.tools.limit,
      { count: true, recursive: false },
    );
  },
  async create(site: Partial<InspectionTool>) {
    return await inspectionToolService.create(site);
  },
  async update(site: InspectionTool) {
    return await inspectionToolService.update(site);
  },
  async delete(site: InspectionTool) {
    await inspectionToolService.delete(site.id);
  },
  async deleteMulti(sites: InspectionTool[]) {
    if (sites.length === 1) {
      await inspectionToolService.delete(sites[0].id);
      return;
    }
    throw new Error('delete multi inspection tools is not supported. ');
  },
});
