/**
 * @file: VehicleInspectionTaskFlowService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskFlowServiceBase } from './base/VehicleInspectionTaskFlowService';

export class VehicleInspectionTaskFlowService extends VehicleInspectionTaskFlowServiceBase {
  // add custom service implementation here
}
