/**
 * @file: VehicleInspectionSiteRelService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionSiteRelServiceBase } from './base/VehicleInspectionSiteRelService';

export class VehicleInspectionSiteRelService extends VehicleInspectionSiteRelServiceBase {
  // add custom service implementation here
}
