import { TransFunction } from 'app';
import { hideAppLoading, showAppLoading, showAppModal } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import classNames from 'classnames';
import { History } from 'history';
import { RouteViewProps } from 'lib';
import { withAcl } from 'lib/decorators/acl';
import {
  AclObjectList,
  Agent,
  DeliveryCheckTemplateMatchType,
  VehicleDeliveryCheckTemplate,
} from 'model';
import { DeliveryCheckTemplateConf } from 'model/viewmodel/DeliveryCheckTemplateConf';
import moment from 'moment';
import {
  ChangeEvent,
  Component,
  CSSProperties,
  memo,
  MouseEvent,
  useEffect,
  useState,
} from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { agentService, vehicleDeliveryCheckTemplateService } from 'services';
import { ActionButtonCls } from 'shared';
import {
  authenticate,
  Badge,
  BreadcrumbItem,
  CommonEntityListProps,
  ConfirmDeleteModal,
  getString,
  Page,
  Restricted,
} from 'shared/components';
import { AgentPicker } from 'shared/components/AgentPicker';
import { Button, Column, DataTable } from 'shared/metronic/components';
import { loadAsyncList } from 'utils';
import { deliveryCheckTemplateActions } from '../duck/actions';
import { duplicateDeliveryCheckTemplate } from '../duck/actions/delivery-check-templates';
import { DeliveryCheckTemplates } from '../duck/states';
import { DeliveryCheckTemplateEditor } from './editor';

type TemplateListColumn = Column<VehicleDeliveryCheckTemplate>;

interface Props extends CommonEntityListProps, LocalizeContextProps {
  templates: DeliveryCheckTemplates;
  history: History;
}

export type DeliveryCheckTemplateManagerProps = Props;

const AgentLabel = memo(({ agentId }: { agentId: number }) => {
  const [agent, setAgent] = useState<Agent | null>();
  const [error, setError] = useState<Error | null>();
  useEffect(() => {
    agentService.get(agentId).then(setAgent).catch(setError);
  }, [agentId]);
  if (agent) {
    return <span>{agent.shortName}</span>;
  }
  if (error) {
    return <span style={{ color: 'red' }}>error</span>;
  }
  return <span>loading... </span>;
});

function mapStateToProps(
  state: AppState,
  ownProps: RouteViewProps,
): Partial<Props> {
  return {
    history: ownProps.history,
    templates: state.inspection.deliveryCheckTemplates,
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

@withAcl()
class DeliveryCheckTemplateManagerImpl extends Component<Props> {
  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.delivery_check_templates" /> },
  ];

  componentDidMount() {
    this.loadTemplates();
  }

  render() {
    const { trans, templates } = this.props;
    return (
      <Page
        title={trans('delivery_check_template.manager.title')}
        breadcrumbs={this.breadcrumbs}
        fullAccessRight={AclObjectList.VehicleDeliveryCheckTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleDeliveryCheckTemplateReadonlyAccess
        }
        error={templates.error}
        onAdd={this.onAdd}
        onRefresh={this.onRefresh}
        headerComplement={this.renderHeaderComplement()}
        compact
        bodyClassName="delivery-check-template-manager"
      >
        {this.renderDataList()}
        {this.renderSidebar()}
        {this.renderConfirmDeleteModal()}
      </Page>
    );
  }

  renderHeaderComplement() {
    return (
      <>
        <AgentPicker
          agentId={this.props.templates.filter?.agentId}
          onChange={agentId => {
            this.props.dispatch(
              deliveryCheckTemplateActions.updateFilter({
                agentId,
              }),
            );
            this.props.dispatch(deliveryCheckTemplateActions.invalidate(true));
          }}
          style={{ width: 250, order: -1, marginRight: 10, marginLeft: 10 }}
        />
        <Restricted
          rights={AclObjectList.VehicleDeliveryCheckTemplateFullAccess}
          silent
        >
          <div className="delivery-check-template-manager-header-actions">
            <Button
              icon
              iconOnly
              air
              large
              pill
              color="info"
              file
              onFileChange={this.onImportTemplates}
              style={{ marginLeft: '0.5rem' }}
              data-toggle="tooltip"
              data-container="body"
              title={getString('delivery_check_template.tooltip.import')}
            >
              <i className="la la-upload" />
            </Button>
          </div>
        </Restricted>
      </>
    );
  }

  renderSidebar() {
    const { dispatch, templates } = this.props;
    return (
      <DeliveryCheckTemplateEditor
        entities={templates}
        actions={deliveryCheckTemplateActions}
        dispatch={dispatch}
        extra={{ props: this.props }}
      />
    );
  }

  renderConfirmDeleteModal() {
    const { templates } = this.props;
    return (
      <ConfirmDeleteModal
        localeSegment={'delivery_check_template'}
        isOpen={Boolean(templates.itemsBeingDeleted?.[0])}
        isDeleting={templates.isDeleting}
        error={templates.lastDeleteError}
        onConfirm={this.onConfirmDelete}
        onCancel={this.onCancelDelete}
      />
    );
  }

  renderDataList() {
    const { templates } = this.props;
    const columns = this.buildColumns();
    this.addActionButtons(columns);
    return (
      <DataTable<VehicleDeliveryCheckTemplate, number>
        columns={columns}
        idProp="id"
        selModel="none"
        data={templates.result}
        isLoading={templates.isLoading}
        minHeight={400}
        selection={templates.selection}
      />
    );
  }

  onRefresh = () => {
    this.loadTemplates(true);
  };

  onAdd = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateActions.itemBeingCreated({}));
  };

  onConfirmDelete = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateActions.commitItemsBeingDeleted());
  };

  onCancelDelete = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateActions.cancelItemsBeingDeleted());
  };

  onImportTemplates = async (e: ChangeEvent<HTMLInputElement>) => {
    const accepts = ['application/json'];
    const file = this.acceptFile(e, accepts, 'invalid_file');
    if (!file) return;
    const { dispatch } = this.props;
    dispatch(
      showAppLoading({
        message: getString('delivery_check_template.manager.importing'),
        status: 'loading',
      }),
    );
    try {
      await vehicleDeliveryCheckTemplateService.importTemplates(file);
      dispatch(deliveryCheckTemplateActions.invalidate(true));
      dispatch(
        showAppLoading({
          message: getString('delivery_check_template.manager.import_success'),
          status: 'success',
          timeout: 2000,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (e) {
      dispatch(hideAppLoading());
      dispatch(
        showAppModal(
          getString('delivery_check_template.modal.import_error.title'),
          getString('delivery_check_template.modal.import_error.msg'),
        ),
      );
    }
  };

  acceptFile(
    e: ChangeEvent<HTMLInputElement>,
    accepts: string[],
    error: string,
  ): File | false {
    if (!e.target.files?.length) return false;
    const file = e.target.files[0];
    if (!accepts.includes(file.type)) {
      alert(getString(`delivery_check_template.manager.${error}`));
      return false;
    }
    return file;
  }

  loadTemplates(force?: boolean) {
    const { dispatch, templates } = this.props;
    loadAsyncList(
      templates,
      () => dispatch(deliveryCheckTemplateActions.fetch()),
      force,
    );
  }

  async exportTemplates(templates: VehicleDeliveryCheckTemplate[]) {
    const ids = templates.map(x => x.id);
    const url = vehicleDeliveryCheckTemplateService.getExportTemplatesUrl(ids);
    console.log('download url is: %s', url);
    const link = document.createElement('a');
    const time = moment().format('YYYYMMDD');
    link.download =
      templates.length === 1
        ? `${templates[0].name}-exported-${time}.json`
        : `exported-templates-${time}.json`;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  buildColumns(): TemplateListColumn[] {
    const { trans } = this.props;
    return [
      {
        prop: 'source',
        text: trans('delivery_check_template.col.source'),
        width: 50,
        align: 'center',
        render: template => {
          const source = template.agentId
            ? 'agent'
            : template.storeId
              ? 'store'
              : template.orgId
                ? 'org'
                : 'system';
          return <Translate id={`delivery_check_template.source.${source}`} />;
        },
      },
      {
        prop: 'name',
        text: trans('delivery_check_template.col.name'),
        width: 300,
        render(item) {
          return (
            <div>
              <p style={{ marginBottom: '0.25rem' }}>
                <strong>{item.name}</strong>
              </p>
              <div>
                {item.withInspectionResults ===
                DeliveryCheckTemplateMatchType.Yes ? (
                  <MatchTypeLabel
                    label="with_inspection_results"
                    style={{
                      borderColor: 'var(--teal)',
                      color: 'var(--teal)',
                    }}
                  />
                ) : item.withInspectionResults ===
                  DeliveryCheckTemplateMatchType.No ? (
                  <MatchTypeLabel
                    label="without_inspection_results"
                    style={{
                      borderColor: 'var(--danger)',
                      color: 'var(--danger)',
                    }}
                  />
                ) : null}
                {item.withConstructionJobs ===
                DeliveryCheckTemplateMatchType.Yes ? (
                  <MatchTypeLabel
                    label="with_construction_jobs"
                    style={{
                      borderColor: 'var(--cyan)',
                      color: 'var(--cyan)',
                    }}
                  />
                ) : item.withConstructionJobs ===
                  DeliveryCheckTemplateMatchType.No ? (
                  <MatchTypeLabel
                    label="without_construction_jobs"
                    style={{
                      borderColor: 'var(--orange)',
                      color: 'var(--orange)',
                    }}
                  />
                ) : null}
                {item.withOrderType ? (
                  <MatchTypeLabel
                    label="with_order_type"
                    suffix={item.withOrderType}
                    style={{
                      borderColor: 'var(--purple)',
                      color: 'var(--purple)',
                    }}
                  />
                ) : null}
              </div>
            </div>
          );
        },
      },
      {
        prop: 'agentId',
        text: trans('delivery_check_template.col.agent'),
        width: 80,
        render: template => {
          if (!template.agentId) return '-';
          return <AgentLabel agentId={template.agentId} />;
        },
      },
      {
        prop: 'isSystemDefault',
        text: trans('delivery_check_template.col.is_system_default'),
        width: 80,
        align: 'center',
        render: ({ isSystemDefault }) => {
          return isSystemDefault ? (
            <Badge color="success">
              <Translate id="delivery_check_template.label.default" />
            </Badge>
          ) : (
            '/'
          );
        },
      },
      {
        prop: 'description',
        text: trans('delivery_check_template.col.description'),
        width: 150,
      },
      {
        prop: 'enabled',
        text: trans('delivery_check_template.col.enabled'),
        width: 80,
        align: 'center',
        render: ({ disabled }) => {
          return (
            <i
              className={classNames('la la-check-circle', {
                'm--font-success': !disabled,
                'm--font-metal': disabled,
              })}
            />
          );
        },
      },
      {
        prop: 'conf',
        text: trans('delivery_check_template.col.conf'),
        width: 120,
        render: site => {
          const conf = site.conf
            ? (JSON.parse(site.conf) as DeliveryCheckTemplateConf)
            : null;
          if (!conf?.items.length) {
            return (
              <span className="m--font-warning">
                <Translate id="delivery_check_template.label.no_conf" />
              </span>
            );
          }
          return (
            <Translate
              id="delivery_check_template.label.conf"
              data={{
                itemCount: conf.items.length,
              }}
            />
          );
        },
      },
    ];
  }

  addActionButtons(columns: TemplateListColumn[]) {
    const fullAccessRight = AclObjectList.VehicleInspectionSiteFullAccess;
    if (!this.props.$has(fullAccessRight)) {
      columns.push({
        prop: 'actions',
        text: <Translate id="col.actions" />,
        align: 'center',
        width: 135,
        render: (template: VehicleDeliveryCheckTemplate) => {
          const onViewDetail = (e: MouseEvent<HTMLElement>) => {
            e.preventDefault();
            const { history } = this.props;
            setTimeout(() => {
              history.push(
                '/inspection/delivery-check-templates/detail?id=' + template.id,
              );
            }, 0);
          };
          return (
            <>
              <button
                className={ActionButtonCls}
                onClick={onViewDetail}
                data-toggle="tooltip"
                data-container=".delivery-check-template-manager"
                title={getString('delivery_check_template.tooltip.view_detail')}
              >
                <i className="la la-eye" />
              </button>
            </>
          );
        },
      });
      return;
    }
    columns.push({
      prop: 'actions',
      text: <Translate id="col.actions" />,
      align: 'center',
      width: 175,
      render: (template: VehicleDeliveryCheckTemplate) => {
        const onEdit = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(deliveryCheckTemplateActions.itemBeingUpdated(template));
        };
        const onDelete = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(deliveryCheckTemplateActions.itemsBeingDeleted([template]));
        };
        const onConfig = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { history } = this.props;
          setTimeout(() => {
            history.push(
              '/inspection/delivery-check-templates/detail?id=' + template.id,
            );
          }, 0);
        };
        const onDuplicate = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          const { dispatch } = this.props;
          dispatch(duplicateDeliveryCheckTemplate(template));
        };
        const onExport = (e: MouseEvent<HTMLElement>) => {
          e.preventDefault();
          void this.exportTemplates([template]);
        };

        return (
          <>
            <button
              className={ActionButtonCls}
              onClick={onEdit}
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.edit')}
            >
              <i className="la la-edit" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onConfig}
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.config')}
            >
              <i className="la la-cog" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onDuplicate}
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.duplicate')}
            >
              <i className="la la-copy" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onExport}
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.export')}
            >
              <i className="la la-download" />
            </button>
            <button
              className={ActionButtonCls}
              onClick={onDelete}
              data-toggle="tooltip"
              data-container=".delivery-check-template-manager"
              title={getString('delivery_check_template.tooltip.delete')}
            >
              <i className="la la-trash" />
            </button>
          </>
        );
      },
    });
  }
}

export const DeliveryCheckTemplateManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, DeliveryCheckTemplateManagerImpl>(
    withLocalize<Props>(DeliveryCheckTemplateManagerImpl) as any,
  ),
);

const MatchTypeLabel = ({
  label,
  suffix,
  style,
}: {
  label: string;
  suffix?: string;
  style?: CSSProperties;
}) => {
  return (
    <span
      style={{
        borderRadius: '3px',
        fontSize: '0.9rem',
        border: '1px solid var(--blue)',
        padding: '0 0.25rem',
        marginTop: '0.25rem',
        marginRight: '0.25rem',
        whiteSpace: 'nowrap',
        ...style,
      }}
    >
      <Translate id={`delivery_check_template.match_label.${label}`} />
      {suffix ? `: ${suffix}` : null}
    </span>
  );
};
