import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { AppStatus } from 'shared/types';
import { getString } from '../StringLabel';

import './index.scss';

interface Props {
  show: boolean;
  message?: ReactNode;
  status?: AppStatus;
}

export class AppLoading extends Component<Props> {
  private readonly ref = React.createRef<HTMLDivElement>();
  render() {
    const { message, status } = this.props;
    return (
      <div ref={this.ref} className={classNames('app-loading', {
        'app-loading--info': status === 'info',
        'app-loading--success': status === 'success',
        'app-loading--warning': status === 'warning',
        'app-loading--error': status === 'error',
      })}>
        <div className="app-loading__message">
          {message === null ||
          message === undefined ||
          typeof message === 'string' ?
          getString(message || '@string/loading') :
          message}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.ref.current) return;
    if (this.props.show && !prevProps.show) {
      // show the loading
      this.ref.current.style.display = 'flex';
      this.ref.current.classList.remove('app-loading--close');
      this.ref.current.classList.add('app-loading--show-enter');
      this.ref.current.addEventListener('transitionend', this.onShowTransitionEnd);
    } else if (!this.props.show && prevProps.show) {
      // hide the loading
      this.ref.current.classList.remove('app-loading--show');
      this.ref.current.classList.add('app-loading--close-enter');
      this.ref.current.addEventListener('transitionend', this.onHideTransitionEnd);
    }
  }

  onShowTransitionEnd = () => {
    if (!this.ref.current) return;
    this.ref.current.removeEventListener('transitionend', this.onShowTransitionEnd);
    this.ref.current.classList.remove('app-loading--show-enter');
    this.ref.current.classList.add('app-loading--show');
  }

  onHideTransitionEnd = () => {
    if (!this.ref.current) return;
    this.ref.current.removeEventListener('transitionend', this.onHideTransitionEnd);
    this.ref.current.classList.remove('app-loading--close-enter');
    this.ref.current.classList.add('app-loading--close');
    this.ref.current.style.display = 'none';
  }
}