/**
 * @file: OrganizationService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ScheduledApplyWeixinTemplateSettingsJobInfo } from 'model';
import { OrganizationServiceBase } from './base/OrganizationService';

export class OrganizationService extends OrganizationServiceBase {
  // add custom service implementation here
  async sendStoreStatsRemindNotification(
    orgId: number,
    storeId: number,
  ): Promise<any> {
    return await this.api
      .post()
      .url('/organizations/:orgId/stores/:storeId/store-stats-remind', {
        orgId,
        storeId,
      })
      .data({ orgId, storeId })
      .future();
  }

  async getAutoApplyWeixinOpenTemplateSettingsScheduledStatus(
    orgId: number,
  ): Promise<ScheduledApplyWeixinTemplateSettingsJobInfo | undefined> {
    return await this.api
      .get()
      .url(
        '/organizations/:orgId/auto-apply-weixin-open-template-settings/schedule/status',
        {
          orgId,
        },
      )
      .future();
  }

  async scheduleAutoApplyWeixinOpenTemplateSettings(
    orgId: number,
    time: string,
  ): Promise<ScheduledApplyWeixinTemplateSettingsJobInfo> {
    return await this.api
      .post()
      .url(
        '/organizations/:orgId/auto-apply-weixin-open-template-settings/schedule',
        {
          orgId,
        },
      )
      .data({ time })
      .future();
  }

  async unscheduleAutoApplyWeixinOpenTemplateSettings(
    orgId: number,
  ): Promise<void> {
    await this.api
      .delete()
      .url(
        '/organizations/:orgId/auto-apply-weixin-open-template-settings/schedule',
        {
          orgId,
        },
      )
      .future();
  }
}
