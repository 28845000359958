/**
 * @file: OpenApiUserService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OpenApiUserServiceBase } from './base/OpenApiUserService';

export class OpenApiUserService extends OpenApiUserServiceBase {
  // add custom service implementation here
}
