/**
 * @file: VehicleInspectionSiteVersionService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionSiteVersionServiceBase } from './base/VehicleInspectionSiteVersionService';

export class VehicleInspectionSiteVersionService extends VehicleInspectionSiteVersionServiceBase {
  // add custom service implementation here
}
