import { Translate } from 'react-localize-redux';

interface Props {
  value: number | null | undefined;
}

export function Mileage(props: Props) {
  let mileage = props.value;
  if (!mileage) return <>{'-'}</>;

  const unit = mileage < 1 ? 'value_unit.km' : 'value_unit.ten_thousands_km';
  if (mileage < 1) mileage *= 10000;
  return (
    <>
      {mileage}
      <Translate id="mileage_spacer" />
      <Translate id={unit} />
    </>
  );
}
