/**
 * @file: PaymentMethod.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum PaymentMethod {
  None = 'none',
  WechatPay = 'wechat_pay',
  WireTransfer = 'wire_transfer',
}

export const PaymentMethodValueSet = new Set([
  PaymentMethod.None,
  PaymentMethod.WechatPay,
  PaymentMethod.WireTransfer,
]);
