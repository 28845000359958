/**
 * @file: VehicleIssueService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleIssueServiceBase } from './base/VehicleIssueService';

export class VehicleIssueService extends VehicleIssueServiceBase {
  // add custom service implementation here
}
