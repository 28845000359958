import classNames from 'classnames';
import { ColorType } from 'model';
import React, { ChangeEvent, Component, HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLButtonElement | HTMLAnchorElement> {
  color?: ColorType;
  labelColor?: ColorType;
  gradient?: [ColorType, ColorType];
  small?: boolean;
  large?: boolean;
  link?: boolean;
  wide?: boolean;
  outline?: boolean;
  outline2x?: boolean;
  custom?: boolean;
  uppercase?: boolean;
  block?: boolean;
  square?: boolean;
  pill?: boolean;
  air?: boolean;
  bolder?: boolean;
  loader?: boolean;
  icon?: boolean;
  iconOnly?: boolean;
  file?: boolean;
  accepts?: string[];
  loaderPosition?: 'left' | 'right';
  tag?: 'a' | 'button';
  onFileChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export class Button extends Component<Props> {
  static defaultProps: Partial<Props> = {
    tag: 'button',
    loaderPosition: 'left',
  };

  private readonly inputFileRef = React.createRef<HTMLInputElement>();

  render() {
    const {
      className,
      color,
      labelColor,
      small,
      large,
      gradient,
      link,
      wide,
      outline,
      outline2x,
      custom,
      uppercase,
      block,
      square,
      pill,
      air,
      bolder,
      loader,
      icon,
      iconOnly,
      loaderPosition,
      tag,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      children,
      file,
      accepts,
      onFileChange,
      style,
      ...props
    } = this.props;

    const cls = classNames(
      'btn',
      {
        [`btn-${color}`]: !outline && !outline2x && color,
        [`btn-outline-${color}`]: (outline || outline2x) && color,
        'btn-sm': small && !large,
        'btn-lg': large && !small,
        'btn-link': link,
        'btn-block': block,
        'm-btn': outline2x,
        [`m-btn--outline-2x`]: outline2x,
        [`m-btn--label-${labelColor}`]: labelColor,
        [`m-btn--gradient-from-${gradient?.[0]}`]: gradient,
        [`m-btn--gradient-to-${gradient?.[1]}`]: gradient,
        'm-btn--wide': wide,
        'm-btn--custom': custom,
        'm-btn--uppercase': uppercase,
        'm-btn--square': square,
        'm-btn--pill': pill,
        'm-btn--air': air,
        'm-btn--bolder': bolder,
        'm-btn--icon': icon,
        'm-btn--icon-only': iconOnly,
        'm-loader': loader,
        [`m-loader--${loaderPosition}`]: loaderPosition,
      },
      className,
    );

    const contents = React.Children.toArray(this.props.children);
    let customStyle = style;
    if (file && !props.disabled) {
      if (!customStyle) customStyle = {};
      customStyle.position = 'relative';
      const fileInput = (
        <input
          type="file"
          ref={this.inputFileRef}
          accept={accepts ? accepts.join(',') : undefined}
          onChange={onFileChange}
          onClick={() => {
            this.inputFileRef.current && (this.inputFileRef.current.value = '');
          }}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0,
            zIndex: 2,
            left: 0,
            top: 0,
            margin: 0,
          }}
        />
      );
      contents.unshift(fileInput);
    }
    return React.createElement(
      tag!,
      {
        className: cls,
        style: customStyle,
        type: tag === 'button' && !props.type ? 'button' : void 0,
        ...props,
      },
      ...contents,
    );
  }
}
