/**
 * @file: OpenApiAppService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { OpenApiAppServiceBase } from './base/OpenApiAppService';

export class OpenApiAppService extends OpenApiAppServiceBase {
  // add custom service implementation here
}
