/**
 * @file: VehicleInspectionTaskFlowService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskFlow,
  VehicleInspectionTaskFlowListFilter,
} from 'model';

export class VehicleInspectionTaskFlowServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleInspectionTaskFlowListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskFlow>
      : VehicleInspectionTaskFlow[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-flows',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskFlow | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-flows/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionTaskFlow: Partial<VehicleInspectionTaskFlow>,
  ): Promise<VehicleInspectionTaskFlow> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-flows')
      .data(vehicleInspectionTaskFlow)
      .future();
  }

  async update(
    vehicleInspectionTaskFlow: Partial<VehicleInspectionTaskFlow>,
  ): Promise<VehicleInspectionTaskFlow> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-flows/:id', {
        id: vehicleInspectionTaskFlow.id,
      })
      .data(vehicleInspectionTaskFlow)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-flows/:id', { id })
      .future();
  }
}
