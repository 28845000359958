import { createAsyncActionReducers } from 'lib/duck/reducers';
import { SummaryStats } from 'model';
import { SummaryStatsState } from '../states';

const initialState: SummaryStatsState = {
  isLoading: false,
  result: null,
  error: null
};

export default createAsyncActionReducers<SummaryStats, SummaryStatsState>(
  'dashboard.companion_summary_stats',
  initialState
);