import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import {
  MaintenanceServiceSubject,
  MaintenanceServiceSubjectListFilter,
} from 'model';
import { maintenanceServiceSubjectService } from 'services';

export const maintenanceServiceSubjectActions = createListAsyncActionCreators<
  AppState,
  MaintenanceServiceSubject,
  MaintenanceServiceSubjectListFilter,
  number
>('inspection.maintenance_service_subjects', {
  shouldInvalidateForFilter: false,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.maintenanceServiceSubjects.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.maintenanceServiceSubjects.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.maintenanceServiceSubjects.itemsBeingDeleted,
  async fetchHandler(_state: AppState) {
    return await maintenanceServiceSubjectService.list({}, null, 0, 0, {
      count: false,
      recursive: false,
    });
  },
  async create(site: Partial<MaintenanceServiceSubject>) {
    return await maintenanceServiceSubjectService.create(site);
  },
  async update(site: MaintenanceServiceSubject) {
    return await maintenanceServiceSubjectService.update(site);
  },
  async delete(site: MaintenanceServiceSubject) {
    await maintenanceServiceSubjectService.delete(site.id);
  },
  async deleteMulti(sites: MaintenanceServiceSubject[]) {
    if (sites.length === 1) {
      await maintenanceServiceSubjectService.delete(sites[0].id);
      return;
    }
    throw new Error('delete multi vehicle service subjects is not supported. ');
  },
});
