import classNames from 'classnames';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { CommonEntityListProps, Node, BreadcrumbItem, Page } from 'shared/components';
import { InspectionSiteCategories, InspectionSites, InspectionSiteItems, InspectionSiteItemOptions, InspectionTools } from '../duck/states';
import { AppState, TransFunction } from 'app';
import { getTranslate, Translate } from 'react-localize-redux';
import { ThunkDispatch } from 'redux-thunk';
import { loadAsyncList } from 'utils';
import { inspectionSiteCategoryActions, inspectionSiteActions, inspectionSiteItemActions, inspectionSiteItemOptionActions, inspectionToolActions } from '../duck/actions';
import { mapStateToTree, isSiteNode, isCategoryNode, isOptionNode, isItemNode, getNodeDataAsSite, getNodeDataAsItem, getNodeDataAsOption } from '../common/helpers';
import { InspectionTool, QuantitativeType, AclObjectList, AbnormalLevelOptions } from 'model';
import { showAppLoading, hideAppLoading } from 'app/duck/actions';

interface Props extends CommonEntityListProps {
  categories: InspectionSiteCategories;
  sites: InspectionSites;
  items: InspectionSiteItems;
  options: InspectionSiteItemOptions;
  tools: InspectionTools;
  toolMap: Map<number, InspectionTool>;
  nodes: Node[];
}

function mapTools(tools: InspectionTools) {
  const map = new Map<number, InspectionTool>();
  if (tools.result) {
    for (const tool of tools.result) {
      map.set(tool.id, tool);
    }
  }
  return map;
}

function mapStateToProps(state: AppState): Partial<Props> {
  return {
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
    categories: state.inspection.categories,
    sites: state.inspection.sites,
    items: state.inspection.items,
    options: state.inspection.options,
    tools: state.inspection.tools,
    toolMap: mapTools(state.inspection.tools),
    nodes: mapStateToTree(state)
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

interface Col {
  key: string;
  content: ReactNode | string;
  cls?: string;
  rowSpan: number;
}

interface Row {
  cols: Col[];

}

interface RowInfo extends Row {
  childRows: RowInfo[];
  rowSpan: number;
}

interface Table {
  headers: Row;
  rows: Row[];
}

function calcRowSpan(row: RowInfo) {
  if (row.rowSpan) return row.rowSpan;
  if (!row.childRows.length) return 1;
  return row.childRows.reduce((rowSpan, childRow) => {
    rowSpan += calcRowSpan(childRow);
    return rowSpan;
  }, 0);
}

interface HeaderCol {
  cls: string;
  key?: string;
  name: string;
}

const Headers: HeaderCol[] = [
  { cls: 'inventory-summary__category-name', name: '分类' },
  { cls: 'inventory-summary__category-name', name: '子分类', key: 'inventory-summary__category-sub-name' },
  { cls: 'inventory-summary__site-icon', name: '图标' },
  { cls: 'inventory-summary__site-name', name: '部位名称' },
  { cls: 'inventory-summary__site-code', name: '编码' },
  { cls: 'inventory-summary__site-desc', name: '部位说明' },
  { cls: 'inventory-summary__item-name', name: '检测指标' },
  { cls: 'inventory-summary__tool-name', name: '检测工具' },
  { cls: 'inventory-summary__tool-quantitative-type', name: '检测性质' },
  { cls: 'inventory-summary__tool-protocol', name: '通信方式' },
  { cls: 'inventory-summary__option-name', name: '选项' },
  { cls: 'inventory-summary__option-result', name: '状态' },
  { cls: 'inventory-summary__option-desc', name: '结果说明' }
];

class InventorySummaryComponent extends Component<Props> {
  private readonly breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    { text: <Translate id="inspection.breadcrumb.summary" /> }
  ];

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch((_, getState) => {
      const state = getState();
      loadAsyncList(
        state.inspection.categories,
        () => dispatch(inspectionSiteCategoryActions.fetch())
        );
      loadAsyncList(
        state.inspection.sites,
        () => dispatch(inspectionSiteActions.fetch())
        );
      loadAsyncList(
        state.inspection.items,
        () => dispatch(inspectionSiteItemActions.fetch())
        );
      loadAsyncList(
        state.inspection.options,
        () => dispatch(inspectionSiteItemOptionActions.fetch())
        );
      loadAsyncList(
        state.inspection.tools,
        () => dispatch(inspectionToolActions.fetch())
      );
    });
  }

  render() {
    const { trans } = this.props;
    return (
      <Page
        title={trans('inspection_site.summary.page.title')}
        breadcrumbs={this.breadcrumbs}
        fullAccessRight={AclObjectList.VehicleInspectionSiteFullAccess}
        readonlyAccessRight={AclObjectList.VehicleInspectionSiteReadonlyAccess}
        onRefresh={this.onRefresh}
        bodyClassName="inventory-summary__page-body"
        compact
        >
        {this.renderTable()}
      </Page>
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { dispatch } = this.props;
    if (this.isLoading(this.props) && !this.isLoading(prevProps)) {
      dispatch(showAppLoading());
    } else if (!this.isLoading(this.props) && this.isLoading(prevProps)) {
      dispatch(hideAppLoading());
    }
  }

  renderTable() {
    if (this.isLoading(this.props, true)) {
      return (<div className="inventory-summary__loading">Loading... </div>);
    }

    const table = this.buildTable();
    return (
      <table className="inventory-summary__table">
        <thead>
          <tr>
            {table.headers.cols.map(x => (
              <th
                key={x.key}
                className={classNames('inventory-summary__col-header', x.cls)}
                >
                {x.content}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table.rows.map((row, i) => (
            <tr key={i}>
              {row.cols.map(col => (
                <td
                  rowSpan={col.rowSpan}
                  key={col.key}
                  className={classNames('inventory-summary__cell', col.cls)}
                  >
                  {col.content}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  onRefresh = () => {
    const { dispatch } = this.props;
    dispatch(inspectionSiteCategoryActions.invalidate(true));
    dispatch(inspectionSiteActions.invalidate(true));
    dispatch(inspectionSiteItemActions.invalidate(true));
    dispatch(inspectionSiteItemOptionActions.invalidate(true));
    dispatch(inspectionToolActions.invalidate(true));
  }

  private buildRowInfo(node: Node): RowInfo {
    const { toolMap } = this.props;

    const row: RowInfo = {
      rowSpan: 0,
      cols: [],
      childRows: []
    };

    if (isCategoryNode(node)) {
      row.cols.push({
        key: node.childType === 'category' ? `${node.id}-name` : `${node.id}-sub-name`,
        content: node.text,
        cls: 'inventory-summary__category-name',
        rowSpan: 1
      });
    } else if (isSiteNode(node)) {
      const site = getNodeDataAsSite(node);
      row.cols.push({
        key: `${node.id}-site-icon`,
        content: site.iconUrl ? <img src={site.iconUrl} /> : '',
        cls: 'inventory-summary__site-icon',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-name`,
        content: site.name,
        cls: 'inventory-summary__site-name',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-code`,
        content: site.code,
        cls: 'inventory-summary__site-code',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-desc`,
        content: site.description || '',
        cls: 'inventory-summary__site-desc',
        rowSpan: 1
      });
    } else if (isItemNode(node)) {
      const item = getNodeDataAsItem(node);
      const tool = item.toolId ? toolMap.get(item.toolId) : null;
      row.cols.push({
        key: `${node.id}-name`,
        content: item.name,
        cls: 'inventory-summary__item-name',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-tool-name`,
        content: tool ? tool.name : '',
        cls: 'inventory-summary__tool-name',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-tool-quantitative-type`,
        content: tool && tool.quantitativeType === QuantitativeType.Quantitative ? '定量' : '定性',
        cls: 'inventory-summary__tool-quantitative-type',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-tool-protocol`,
        content: tool?.protocol || '',
        cls: 'inventory-summary__tool-protocol',
        rowSpan: 1
      });
    } else if (isOptionNode(node)) {
      const option = getNodeDataAsOption(node);
      row.cols.push({
        key: `${node.id}-option-label`,
        content: option.label,
        cls: 'inventory-summary__option-name',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-option-result`,
        content: <Translate id={AbnormalLevelOptions.find(x => x.value === option.abnormalLevel)?.label} />,
        cls: 'inventory-summary__option-result',
        rowSpan: 1
      });
      row.cols.push({
        key: `${node.id}-option-desc`,
        content: option.description || '',
        cls: 'inventory-summary__option-desc',
        rowSpan: 1
      });
    }

    if (node.children) {
      for (const childNode of node.children) {
        const childRow = this.buildRowInfo(childNode);
        row.childRows.push(childRow);
      }
      // update row span of the cols
      const rowSpan = calcRowSpan(row);
      row.rowSpan = rowSpan;
      for (const col of row.cols) {
        col.rowSpan = rowSpan;
      }
    }

    return row;
  }

  private buildTable(): Table {
    const table: Table = {
      headers: {
        cols: Headers.map(x => ({
          key: x.key || x.cls,
          content: x.name,
          cls: x.cls,
          rowSpan: 1
        }))
      },
      rows: []
    };

    if (!this.props.nodes.length) return table;

    const rowInfoList = this.props.nodes.map(x => this.buildRowInfo(x));

    // structure rows to table
    let current: Row | null = null;

    function buildRows(row: RowInfo) {
      if (!current) {
        current = { cols: [] };
        table.rows.push(current);
      }

      current.cols.push(...row.cols);

      if (!row.childRows.length) {
        current = null;
      } else {
        for (const childRow of row.childRows) {
          buildRows(childRow);
        }
      }
    }

    for (const row of rowInfoList) {
      buildRows(row);
    }

    return table;
  }

  private isLoading(props: Props, firstTime = false) {
    const { categories, sites, items, options } = props;

    if (firstTime) {
      return Boolean(
        !categories.result && categories.isLoading ||
        !sites.result && sites.isLoading ||
        !items.result && items.isLoading ||
        !options.result && options.isLoading
      );
    }

    return (
      categories.isLoading ||
      sites.isLoading ||
      items.isLoading ||
      options.isLoading
    );
  }
}

export const InventorySummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(InventorySummaryComponent);