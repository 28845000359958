import classNames from 'classnames';
import { ChangeEvent, CSSProperties, ReactNode, useCallback } from 'react';

interface CheckButtonProps {
  label: string | ReactNode;
  value?: any;
  checked: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
  onChange: (
    value: any,
    checked: boolean,
    e: ChangeEvent<HTMLInputElement>,
  ) => void;
}

export function CheckButton(props: CheckButtonProps) {
  const {
    value,
    checked,
    label,
    disabled,
    onChange,
    style,
    className,
    ...others
  } = props;
  const handler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(value, e.target.checked, e);
    },
    [onChange, value],
  );
  return (
    <label
      className={classNames(
        'm-checkbox m-checkbox--solid m-checkbox--brand',
        className,
      )}
      style={style}
    >
      <input
        type="checkbox"
        checked={checked || false}
        disabled={disabled}
        onChange={handler}
        {...others}
      />
      {label}
      <span />
    </label>
  );
}
