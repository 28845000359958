/**
 * @file: ProductAgentAccountTxStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductAgentAccountTxStatus {
  Pending = 'pending',
  PendingEntry = 'pending_entry',
  DoneEntry = 'done_entry',
  Closed = 'closed',
  Finished = 'finished',
}

export const ProductAgentAccountTxStatusValueSet = new Set([
  ProductAgentAccountTxStatus.Pending,
  ProductAgentAccountTxStatus.PendingEntry,
  ProductAgentAccountTxStatus.DoneEntry,
  ProductAgentAccountTxStatus.Closed,
  ProductAgentAccountTxStatus.Finished,
]);
