import { setActiveProductAgentForTxHistory } from 'app/customers/duck/actions';
import { IdType, ProductAgentAccount } from 'model';
import {
  CSSProperties,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { productAgentService } from 'services';

export const ProductAgentInfoCell = memo(
  ({ agentId, credit }: { agentId: IdType; credit: number }) => {
    const [account, setAccount] = useState<ProductAgentAccount>();
    const [error, setError] = useState<Error>();
    const dispatch = useDispatch();

    useEffect(() => {
      productAgentService
        .getAccountInfo(agentId)
        .then(setAccount)
        .catch(err => {
          console.error(err);
          setError(err);
        });
    }, [agentId]);

    const onViewTxHistoryClick = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();
        dispatch(setActiveProductAgentForTxHistory(agentId));
      },
      [agentId, dispatch],
    );

    const renderInfo = (label: string, value: any, style?: CSSProperties) =>
      value == null ? null : (
        <dl>
          <dt>
            <Translate id={`product_agent.info.label.${label}`} />
          </dt>
          <dd style={style}>{value}</dd>
        </dl>
      );

    if (account == null) {
      return (
        <span
          style={{
            color: error ? 'red' : undefined,
          }}
        >
          {error ? 'error' : <i className="fa fa-spinner fa-pulse" />}
        </span>
      );
    }

    return (
      <div className="agent-info__detail">
        {renderInfo(
          'account.credit',
          (credit / 100).toFixed(2),
          credit > 0
            ? {
                color: '#50b850',
                fontWeight: 'bold',
                textDecoration: 'underline',
              }
            : undefined,
        )}
        {renderInfo('account.balance', (account.balance / 100).toFixed(2))}
        {renderInfo(
          'account.pending_balance',
          (account.pendingBalance / 100).toFixed(2),
          account.pendingBalance > 0
            ? { color: 'red', fontWeight: 'bold', textDecoration: 'underline' }
            : undefined,
        )}
        <a
          onClick={onViewTxHistoryClick}
          href="#"
          style={{
            fontSize: '0.85rem',
            lineHeight: '1.2rem',
            fontWeight: 'bold',
          }}
        >
          <Translate id="product_agent.info.label.account.view_tx_history" />
          <span
            style={{
              marginLeft: '0.1rem',
              fontSize: '1.2rem',
              verticalAlign: 'middle',
            }}
          >
            &gt;
          </span>
        </a>
      </div>
    );
  },
);
