/**
 * @file: task-list.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createListAsyncActionCreators, createStandardAction } from 'lib/duck/actions';
import { AppState } from 'app/duck/states';
import { VehicleInfo, VehicleInfoListFilter } from 'model';
import { vehicleInfoService } from 'services';
import { ActionThunk } from 'lib/duck/interfaces';
import { ActionTypes } from '../types';

export const vehicleListActions =
  createListAsyncActionCreators<AppState, VehicleInfo, VehicleInfoListFilter, number>(
  'vehicles.vehicle_list',
  {
    shouldInvalidateForFilter: true,
    shouldFetchOnInvalidate: true,
    async fetchHandler(state: AppState) {
      return await vehicleInfoService.list(
        state.vehicles.vehicleList.filter,
        state.vehicles.vehicleList.sorts?.[0],
        state.vehicles.vehicleList.offset,
        state.vehicles.vehicleList.limit,
        { count: true, recursive: true }
        );
    }
  }
);

export function performSelfCheckOfVehicleInfos(): ActionThunk<AppState> {
  return async (dispatch) => {
    dispatch(createStandardAction(ActionTypes.PerformSelfCheck));
    try {
      await vehicleInfoService.selfCheck();
      dispatch(createStandardAction(ActionTypes.PerformSelfCheckSuccess));
      dispatch(vehicleListActions.invalidate(true));
    } catch (e) {
      dispatch(createStandardAction(ActionTypes.PerformSelfCheckFailed, e));
    }
  };
}