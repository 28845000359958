import React, { Component, ChangeEvent } from 'react';
import { Translate } from 'react-localize-redux';
import { Button, Alert } from 'shared/metronic/components';

interface Props {
  isSuperUser: boolean;
  error?: Error | null;
  selected: string[];
  acl: string[];
  onChange: (values: string[]) => void;
  onSave?: () => void;
}

export class AclEditor extends Component<Props> {
  render() {
    const { acl, selected, error, isSuperUser } = this.props;
    return (
      <div className="acl-editor">
        <h5><Translate id="admin_user.acl.label.grant_access" /></h5>
        <div className="m-checkbox-list" style={{
          overflowY: 'auto',
          position: 'fixed',
          left: 32,
          right: 32,
          top: 60,
          bottom: 68
          }}>
          {error &&
          <Alert color="danger">
            {error.message}
          </Alert>}
          {acl.map(x => (
          <label key={x} className="m-checkbox">
            <input
              type="checkbox"
              value={x}
              disabled={isSuperUser}
              checked={isSuperUser || (selected || []).includes(x)}
              onChange={this.onChange(x)}
              />
            &nbsp;{x}
            <span />
          </label>
          ))}
        </div>
        <div style={{
          position: 'fixed',
          bottom: 0,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          borderTop: '1px solid #ddd',
          left: 32,
          right: 32
        }}>
          <Button color="brand" onClick={this.onSave} disabled={isSuperUser}>
            <Translate id="save_btn_text" />
          </Button>
        </div>
      </div>
    );
  }

  onChange(value: string) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      let { selected } = this.props;
      if (e.target.checked) {
        selected = [...selected, value];
      } else {
        selected = selected.filter(x => x !== value);
      }
      this.props.onChange(selected);
    };
  }

  onSave = () => {
    this.props.onSave && this.props.onSave();
  }
}