/**
 * @file: ProductAgentAccountTxCategory.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ProductAgentAccountTxCategory {
  In = 'in',
  Out = 'out',
}

export const ProductAgentAccountTxCategoryValueSet = new Set([
  ProductAgentAccountTxCategory.In,
  ProductAgentAccountTxCategory.Out,
]);
