import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { OpenApiApp } from 'model';
import { OpenApiApps } from '../states';
import { ActionTypes } from '../types';

const initialState: OpenApiApps = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<OpenApiApp, OpenApiApps>(
  'openapi.apps',
  initialState,
  {
    mapItemKey: x => x.id,
    onItemBeingUpdated: state => {
      return Object.assign({}, state, {
        itemBeingUpdated: Object.assign({}, state.itemBeingUpdated, {
          password: '',
        }),
      });
    },
  },
);

export default function openApiAppsReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: OpenApiApps = initialState,
  action: StandardAction<any>,
): OpenApiApps {
  switch (action.type) {
    case ActionTypes.ManageOpenapiAppAuthorizedStores: {
      return {
        ...state,
        appForManageAuthorizedStores: action.payload,
      };
    }
    default:
      return defaultReducer(state, action);
  }
}
