/**
 * @file: BatchJobTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { BatchJobType, Option } from 'model';

export const BatchJobTypeOptions: Array<Option<BatchJobType>> = [
  { value: BatchJobType.FinishTasks, label: 'batch_job_type.finish-tasks' },
];

export const BatchJobTypeOptionsWithDefault: Array<
  Option<BatchJobType | null>
> = [
  { value: null, label: 'batch_job_type.__default__' },
  { value: BatchJobType.FinishTasks, label: 'batch_job_type.finish-tasks' },
];
