/**
 * @file: OfficialAccountBusinessInfoType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum OfficialAccountBusinessInfoType {
  OpenStore = 'open_store',
  OpenScan = 'open_scan',
  OpenPay = 'open_pay',
  OpenCard = 'open_card',
  OpenShake = 'open_shake',
}

export const OfficialAccountBusinessInfoTypeValueSet = new Set([
  OfficialAccountBusinessInfoType.OpenStore,
  OfficialAccountBusinessInfoType.OpenScan,
  OfficialAccountBusinessInfoType.OpenPay,
  OfficialAccountBusinessInfoType.OpenCard,
  OfficialAccountBusinessInfoType.OpenShake,
]);
