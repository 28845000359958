import classNames from 'classnames';
import { Component } from 'react';
import { NavItem } from './NavItem';
import { NavSection } from './NavSection';
import { NavSeparator } from './NavSeparator';

interface Props {
  [name: string]: any;
  className?: string;
  children?: any;
  inline?: boolean;
  skin?: 'light';
}

export class Nav extends Component<Props> {
  static Item = NavItem;
  static Section = NavSection;
  static Separator = NavSeparator;
  render() {
    const { className, children, inline, skin, ...otherProps } = this.props;
    return (
      <ul
        className={classNames('m-nav', className, {
          'm-nav--inline': inline,
          [`m-nav--skin-${skin}`]: skin,
        })}
        {...otherProps}
      >
        {children}
      </ul>
    );
  }
}
