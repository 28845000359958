/**
 * @file: ReportOpenUserType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ReportOpenUserType {
  Customer = 1,
  ServiceAgent = 2,
  Shared = 3,
  Other = 4,
}

export const ReportOpenUserTypeValueSet = new Set([
  ReportOpenUserType.Customer,
  ReportOpenUserType.ServiceAgent,
  ReportOpenUserType.Shared,
  ReportOpenUserType.Other,
]);
