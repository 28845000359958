import { TransFunction } from 'app';
import { showAppLoading, showAppModal } from 'app/duck/actions';
import { AppState } from 'app/duck/states';
import classNames from 'classnames';
import { RouteViewProps } from 'lib';
import { AclContextProps, withAcl } from 'lib/decorators/acl';
import { AclObjectList, ColorType, VehicleDeliveryCheckTemplate } from 'model';
import { DeliveryCheckTemplateConf } from 'model/viewmodel/DeliveryCheckTemplateConf';
import qs from 'qs';
import React, { ChangeEvent, Component, FocusEvent, MouseEvent } from 'react';
import {
  getTranslate,
  LocalizeContextProps,
  Translate,
  withLocalize,
} from 'react-localize-redux';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ThunkDispatch } from 'redux-thunk';
import {
  authenticate,
  Badge,
  BreadcrumbItem,
  CommonEntityListProps,
  getString,
  InlineSvg,
  Page,
  Restricted,
  Switch,
} from 'shared/components';
import { Button } from 'shared/metronic/components';
import { loadAsyncList } from 'utils';
import {
  deliveryCheckTemplateActions,
  deliveryCheckTemplateDetailActions,
} from '../duck/actions';
import { DefaultDeliveryCheckTemplateItemOptions } from '../duck/reducers/delivery-check-template-detail';
import {
  DeliveryCheckTemplateDetail,
  DeliveryCheckTemplates,
} from '../duck/states';
import { defaultDeliveryCheckTemplateConf } from './default-template-conf';

import './detail.scss';

interface Props
  extends CommonEntityListProps,
    LocalizeContextProps,
    RouteViewProps,
    AclContextProps {
  templateId: number;
  template: VehicleDeliveryCheckTemplate | null;
  templates: DeliveryCheckTemplates;
  detail: DeliveryCheckTemplateDetail;
}

function mapStateToProps(
  state: AppState,
  routeProps: RouteViewProps,
): Partial<Props> {
  const query = qs.parse(routeProps.location.search.substr(1));
  const templateId = Number(query.id);
  const template =
    state.inspection.deliveryCheckTemplates.result?.find(
      x => x.id === templateId,
    ) || null;
  return {
    templateId,
    template,
    templates: state.inspection.deliveryCheckTemplates,
    detail: state.inspection.deliveryCheckTemplateDetail,
    trans: getTranslate(state.localize) as TransFunction,
    translate: getTranslate(state.localize),
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<AppState, any, any>) {
  return { dispatch };
}

function ItemList({ children }: any) {
  return <div className="dc-tpl-detail__item-list">{children}</div>;
}

function ItemListItem({ children, key, readonly }: any) {
  return (
    <div
      className={classNames('dc-tpl-detail__item', {
        noselect: true,
        'dc-tpl-detail__item--disabled': readonly,
      })}
      key={key}
    >
      {children}
    </div>
  );
}

const SortableItemList = SortableContainer(ItemList);
const SortableItemListItem = SortableElement(ItemListItem);
const SortableItemListItemDragHandle = SortableHandle(() => (
  <InlineSvg src="img/icon-sort.svg" />
));

@withAcl()
class DeliveryCheckTemplateDetailManagerImpl extends Component<Props> {
  detailViewRef = React.createRef<HTMLDivElement>();
  isTemplateNotFoundAlerted = false;

  breadcrumbs: BreadcrumbItem[] = [
    { text: <Translate id="inspection.breadcrumb.it" /> },
    {
      text: <Translate id="inspection.breadcrumb.delivery_check_templates" />,
      href: '/inspection/delivery-check-templates/list',
    },
  ];

  componentDidMount() {
    const { dispatch, template, templates } = this.props;
    loadAsyncList(templates, () =>
      dispatch(deliveryCheckTemplateActions.fetch()),
    );

    if (this.props.templates.result && !this.props.template) {
      this.onTemplateNotFound();
      return;
    }

    if (template) {
      this.ready();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.templates.result && !this.props.template) {
      this.onTemplateNotFound();
      return;
    }
    if (!prevProps.template && this.props.template) {
      this.ready();
    }
    if (prevProps.detail.isSaving && !this.props.detail.isSaving) {
      const { dispatch } = this.props;
      const error = this.props.detail.saveError;
      error && console.error(error);
      const prefix = `delivery_check_template.detail.save_result.${
        error ? 'failure' : 'success'
      }`;
      dispatch(
        showAppLoading({
          message: `${prefix}.msg`,
          status: error ? 'error' : 'success',
          timeout: 3000,
        }),
      );
    }
  }

  render() {
    const { trans, template, templateId, templates } = this.props;
    const breadcrumbs = [...this.breadcrumbs];
    if (template) {
      breadcrumbs.push({ text: template.name });
    }
    return (
      <Page
        title={trans('delivery_check_template.manager.title')}
        bodyClassName="delivery-check-template-detail-manager"
        bodyStyle={{ padding: 0 }}
        breadcrumbs={breadcrumbs}
        fullAccessRight={AclObjectList.VehicleDeliveryCheckTemplateFullAccess}
        readonlyAccessRight={
          AclObjectList.VehicleDeliveryCheckTemplateReadonlyAccess
        }
        headerComplement={this.renderPageActions}
        error={templates.error}
        compact
        key={templateId}
      >
        {this.renderContent()}
      </Page>
    );
  }

  renderPageActions = () => {
    const { detail } = this.props;
    return (
      <Restricted
        rights={AclObjectList.VehicleDeliveryCheckTemplateFullAccess}
        silent
      >
        <Button color="primary" pill onClick={this.onAddItem}>
          <Translate id="delivery_check_template.detail.btn.add" />
        </Button>
        <Button
          color="brand"
          pill
          loader={detail.isSaving === true}
          disabled={detail.isSaving === true}
          onClick={this.onSave}
          style={{ marginLeft: '0.75rem' }}
        >
          <Translate id="delivery_check_template.detail.btn.save" />
        </Button>
      </Restricted>
    );
  };

  renderContent() {
    const { detail, template } = this.props;
    if (template && detail.templateId !== template.id) {
      return null;
    }
    return (
      <Restricted rights={AclObjectList.VehicleDeliveryCheckTemplateFullAccess}>
        {(_: any, hasRights: boolean) => (
          <div
            className="dc-tpl-detail delivery-check-template-detail"
            ref={this.detailViewRef}
          >
            {this.renderItemList(hasRights)}
            {this.renderConfirmRemoveModal(
              'item',
              Boolean(detail.itemIdBeingRemoved),
              this.onCancelRemoveItem,
              this.onConfirmRemoveItem,
            )}
            {this.renderConfirmRemoveModal(
              'option',
              Boolean(detail.optionIdBeingRemoved),
              this.onCancelRemoveOption,
              this.onConfirmRemoveOption,
            )}
          </div>
        )}
      </Restricted>
    );
  }

  renderItemList(hasRights: boolean) {
    const readonly = !hasRights;

    const { detail } = this.props;

    if (!detail.conf) {
      return (
        <div className="dc-tpl-detail__item-list--empty">
          <span>Loading... </span>
        </div>
      );
    }

    const vr = detail.validationResult;

    if (!detail.conf.items.length) {
      return (
        <div className="dc-tpl-detail__item-list--empty">
          {readonly && (
            <Translate id="delivery_check_template.detail.no_items_readonly" />
          )}
          {!readonly && (
            <Translate
              id="delivery_check_template.detail.no_items"
              data={{
                add_item_btn: (
                  <a href="#" onClick={this.onAddItem}>
                    <Translate id="delivery_check_template.detail.btn.add_item" />
                  </a>
                ),
                add_common_items_btn: (
                  <a href="#" onClick={this.onAddCommonItems}>
                    <Translate id="delivery_check_template.detail.btn.add_common_items" />
                  </a>
                ),
              }}
            />
          )}
        </div>
      );
    }

    return (
      <SortableItemList
        helperClass="dc-tpl-detail__item--being-dragged"
        onSortEnd={this.onItemSorted}
        useDragHandle
        lock
        lockAxis="y"
        useWindowAsScrollContainer={true}
      >
        {detail.conf!.items.map((item, i) => (
          <SortableItemListItem key={item.id} disabled={readonly} index={i}>
            <div className="dc-tpl-detail__item-hd">
              <div className="dc-tpl-detail__item-sort">
                <SortableItemListItemDragHandle />
              </div>
              <div className="dc-tpl-detail__item-subject">
                <input
                  type="text"
                  readOnly={readonly}
                  className={classNames('form-control form-control-sm', {
                    'form-control--error':
                      !readonly && vr[`item.${item.id}.subject.required`],
                  })}
                  defaultValue={item.subject || ''}
                  placeholder={getString(
                    'delivery_check_template.detail.placeholder.item.subject',
                  )}
                  onBlur={this.onItemSubjectBlur(item.id)}
                />
              </div>
              {!readonly && (
                <div className="dc-tpl-detail__item-requires-photo d-flex align-items-center">
                  <Switch
                    size="small"
                    color="brand"
                    on={item.requiresPhoto}
                    onChange={this.onItemRequiresPhotoCheckChange(item.id)}
                  />
                  <span style={{ marginLeft: '0.5rem' }}>
                    <Translate id="delivery_check_template.detail.label.requires_photo" />
                  </span>
                </div>
              )}
              {readonly && item.requiresPhoto && (
                <Badge style={{ marginLeft: '0.5rem' }} color="success">
                  <i className="la la-check" />
                  <Translate id="delivery_check_template.detail.label.requires_photo" />
                </Badge>
              )}
              {!readonly && (
                <div className="dc-tpl-detail__item-actions">
                  <a href="#" onClick={this.onRemoveItem(item.id)}>
                    <i className="la la-trash-o" />
                  </a>
                </div>
              )}
            </div>
            <div
              className={classNames('dc-tpl-detail__option-list', {
                'dc-tpl-detail__option-list--readonly': readonly,
              })}
            >
              <table>
                <thead>
                  <tr>
                    <th className="dc-tpl-detail__option-col-title">
                      <Translate id="delivery_check_template.detail.col.title" />
                      {!readonly && (
                        <a href="#" onClick={this.onAddOption(item.id)}>
                          <i className="la la-plus" />
                        </a>
                      )}
                    </th>
                    <th className="dc-tpl-detail__option-col-remark">
                      <Translate id="delivery_check_template.detail.col.remark" />
                    </th>
                    <th className="dc-tpl-detail__option-col-default-check">
                      <Translate id="delivery_check_template.detail.col.defaultChecked" />
                    </th>
                    <th className="dc-tpl-detail__option-col-is-expected">
                      <Translate id="delivery_check_template.detail.col.isExpected" />
                    </th>
                    {!readonly && (
                      <th className="dc-tpl-detail__option-col-actions">
                        &nbsp;
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {!item.options.length ? (
                    <tr>
                      <td
                        colSpan={readonly ? 4 : 5}
                        className={classNames(
                          'dc-tpl-detail__option-list--empty',
                          {
                            'dc-tpl-detail__option-list--empty-with-error':
                              vr[`item.${item.id}.options.required`],
                          },
                        )}
                      >
                        <div>
                          {readonly && (
                            <Translate id="delivery_check_template.detail.no_options_readonly" />
                          )}
                          {!readonly && (
                            <Translate
                              id="delivery_check_template.detail.no_options"
                              data={{
                                add_option_btn: (
                                  <a
                                    href="#"
                                    onClick={this.onAddOption(item.id)}
                                  >
                                    <Translate id="delivery_check_template.detail.btn.add_option" />
                                  </a>
                                ),
                                add_common_options_btn: (
                                  <a
                                    href="#"
                                    onClick={this.onAddCommonOptionsClick(
                                      item.id,
                                    )}
                                  >
                                    <Translate id="delivery_check_template.detail.btn.add_common_options" />
                                  </a>
                                ),
                              }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                  {item.options.map(option => (
                    <tr key={option.id}>
                      <td className="dc-tpl-detail__option-col-title">
                        <input
                          type="text"
                          readOnly={readonly}
                          className={classNames(
                            'form-control form-control-sm',
                            {
                              'form-control--error':
                                !readonly &&
                                vr[`option.${option.id}.title.required`],
                            },
                          )}
                          defaultValue={option.title || ''}
                          onBlur={this.onOptionTitleBlur(item.id, option.id)}
                          placeholder={getString(
                            'delivery_check_template.detail.placeholder.option.title',
                          )}
                        />
                      </td>
                      <td className="dc-tpl-detail__option-col-remark">
                        <input
                          type="text"
                          readOnly={readonly}
                          className={classNames(
                            'form-control form-control-sm',
                            {
                              'form-control--error':
                                !readonly &&
                                vr[`option.${option.id}.remark.required`],
                            },
                          )}
                          defaultValue={option.remark || ''}
                          onBlur={this.onOptionRemarkBlur(item.id, option.id)}
                          placeholder={getString(
                            'delivery_check_template.detail.placeholder.option.remark',
                          )}
                        />
                      </td>
                      <td className="dc-tpl-detail__option-col-default-check">
                        {readonly && option.isDefaultChecked && (
                          <i className="la la-check" />
                        )}
                        {!readonly &&
                          this.renderCheck({
                            checked: option.isDefaultChecked,
                            value: true,
                            onChange: this.onOptionDefaultCheckChange(
                              item.id,
                              option.id,
                            ),
                            color: 'brand',
                          })}
                      </td>
                      <td className="dc-tpl-detail__option-col-is-expected">
                        {readonly && option.isExpected === true && (
                          <i className="la la-check" />
                        )}
                        {!readonly &&
                          this.renderCheck({
                            checked: option.isExpected === true,
                            value: true,
                            onChange: this.onOptionIsExpectedChange(
                              item.id,
                              option.id,
                            ),
                            color: 'brand',
                          })}
                      </td>
                      {!readonly && (
                        <td className="dc-tpl-detail__option-col-actions">
                          <a href="#" onClick={this.onRemoveOption(option.id)}>
                            <i className="la la-close" />
                          </a>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              {!readonly &&
                item.options.length > 0 &&
                vr[`item.${item.id}.options.required`] && (
                  <div
                    className={classNames([
                      'dc-tpl-detail__option-list--empty',
                      'dc-tpl-detail__option-list--empty-with-error',
                    ])}
                  >
                    <div style={{ paddingTop: '1rem', paddingBottom: 0 }}>
                      <span>
                        <Translate id="delivery_check_template.detail.error.item.options" />
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </SortableItemListItem>
        ))}
      </SortableItemList>
    );
  }

  renderConfirmRemoveModal(
    type: 'item' | 'option',
    isOpen: boolean,
    onCancel: () => void,
    onConfirm: () => void,
  ) {
    const localeSegment = 'delivery_check_template.detail';
    return (
      <Modal isOpen={isOpen}>
        <ModalHeader>
          <Translate id={`${localeSegment}.modal.remove_${type}.title`} />
        </ModalHeader>
        <ModalBody>
          <ReactMarkdown>
            {getString(`${localeSegment}.modal.remove_${type}.msg`)}
          </ReactMarkdown>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            <Translate id="cancel_btn_text" />
          </Button>
          <Button color="primary" onClick={onConfirm}>
            <Translate id="yes_btn_text" />
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderCheck(props: {
    checked: boolean;
    value: any;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    label?: string;
    color?: ColorType;
  }) {
    const cls = classNames([
      'm-checkbox',
      'm-checkbox--single',
      'm-checkbox--all',
      'm-checkbox--solid',
      `m-checkbox--${props.color || 'brand'}`,
    ]);
    return (
      <div className="dc-tpl-detail__check">
        <label className={cls}>
          <input
            type="checkbox"
            value={props.value}
            checked={props.checked}
            disabled={props.disabled}
            onChange={props.onChange}
          />
          {props.label || ' '}
          <span />
        </label>
      </div>
    );
  }

  onTemplateNotFound() {
    if (this.isTemplateNotFoundAlerted) return;
    const { dispatch, history } = this.props;
    this.isTemplateNotFoundAlerted = true;
    dispatch(
      showAppModal(
        '@string/oops',
        'delivery_check_template.detail.template_not_found',
        () => {
          history.replace('/inspection/delivery-check-templates/list');
        },
      ),
    );
  }

  onAddItem = (e: MouseEvent) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateDetailActions.addItem());
  };

  onItemSubjectBlur(itemId: string) {
    return (e: FocusEvent) => {
      const { dispatch, detail } = this.props;
      const el = e.target as HTMLInputElement;
      const subject = el.value.trim();
      const item = detail.conf!.items.find(x => x.id === itemId)!;
      if (item.subject === subject) return;
      dispatch(
        deliveryCheckTemplateDetailActions.itemChanged(itemId, { subject }),
      );
    };
  }

  onItemRequiresPhotoCheckChange(itemId: string) {
    return (checked: boolean) => {
      const { dispatch } = this.props;
      dispatch(
        deliveryCheckTemplateDetailActions.itemChanged(itemId, {
          requiresPhoto: checked,
        }),
      );
    };
  }

  onAddCommonItems = (e: MouseEvent) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(
      deliveryCheckTemplateDetailActions.applyDefaultConfiguration(
        defaultDeliveryCheckTemplateConf,
      ),
    );
  };

  onRemoveItem(itemId: string) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch } = this.props;
      dispatch(deliveryCheckTemplateDetailActions.removeItem(itemId));
    };
  }

  onConfirmRemoveItem = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateDetailActions.confirmRemoveItem());
  };

  onCancelRemoveItem = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateDetailActions.cancelRemoveItem());
  };

  onAddOption(itemId: string) {
    return (e: MouseEvent) => {
      e.preventDefault();
      const { dispatch } = this.props;
      dispatch(deliveryCheckTemplateDetailActions.addOption(itemId));
    };
  }

  onOptionTitleBlur(itemId: string, optionId: string) {
    return (e: FocusEvent) => {
      const { dispatch, detail } = this.props;
      const el = e.target as HTMLInputElement;
      const title = el.value.trim();
      const item = detail.conf!.items.find(x => x.id === itemId)!;
      const option = item.options.find(x => x.id === optionId)!;
      if (option.title === title) return;
      dispatch(
        deliveryCheckTemplateDetailActions.optionChanged(itemId, optionId, {
          title,
        }),
      );
    };
  }

  onOptionRemarkBlur(itemId: string, optionId: string) {
    return (e: FocusEvent) => {
      const { dispatch, detail } = this.props;
      const el = e.target as HTMLInputElement;
      const remark = el.value.trim();
      const item = detail.conf!.items.find(x => x.id === itemId)!;
      const option = item.options.find(x => x.id === optionId)!;
      if ((option.remark || '') === (remark || '')) return;
      dispatch(
        deliveryCheckTemplateDetailActions.optionChanged(itemId, optionId, {
          remark,
        }),
      );
    };
  }

  onOptionDefaultCheckChange(itemId: string, optionId: string) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { dispatch } = this.props;
      dispatch(
        deliveryCheckTemplateDetailActions.optionDefaultCheckChanged(
          itemId,
          optionId,
          e.currentTarget.checked,
        ),
      );
    };
  }

  onOptionIsExpectedChange(itemId: string, optionId: string) {
    return (e: ChangeEvent<HTMLInputElement>) => {
      const { dispatch } = this.props;
      dispatch(
        deliveryCheckTemplateDetailActions.optionIsExpectedChanged(
          itemId,
          optionId,
          e.currentTarget.checked,
        ),
      );
    };
  }

  onAddCommonOptionsClick(itemId: string) {
    return (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      const { dispatch } = this.props;
      dispatch(
        deliveryCheckTemplateDetailActions.itemChanged(itemId, {
          options: DefaultDeliveryCheckTemplateItemOptions,
        }),
      );
    };
  }

  onRemoveOption(optionId: string) {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      const { dispatch } = this.props;
      dispatch(deliveryCheckTemplateDetailActions.removeOption(optionId));
    };
  }

  onConfirmRemoveOption = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateDetailActions.confirmRemoveOption());
  };

  onCancelRemoveOption = () => {
    const { dispatch } = this.props;
    dispatch(deliveryCheckTemplateDetailActions.cancelRemoveOption());
  };

  onItemSorted = (e: { oldIndex: number; newIndex: number }) => {
    const { dispatch } = this.props;
    const { newIndex, oldIndex } = e;
    if (newIndex === oldIndex) return;
    dispatch(deliveryCheckTemplateDetailActions.itemMoved(oldIndex, newIndex));
  };

  onSave = () => {
    const { dispatch, template } = this.props;
    dispatch(
      deliveryCheckTemplateDetailActions.validate(result => {
        if (!Object.keys(result).length) {
          dispatch(deliveryCheckTemplateDetailActions.save(template!));
        }
      }),
    );
  };

  ready() {
    const { template } = this.props;
    if (!template) return;

    const { dispatch } = this.props;
    try {
      const conf = JSON.parse(
        template.conf || '{}',
      ) as DeliveryCheckTemplateConf;
      if (!conf.items) conf.items = [];
      dispatch(deliveryCheckTemplateDetailActions.ready(template.id, conf));
    } catch (e) {
      console.error(e);
      dispatch(
        deliveryCheckTemplateDetailActions.ready(template.id, {
          items: [],
        }),
      );
    }
  }
}

export const DeliveryCheckTemplateDetailManager = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  authenticate<Props, DeliveryCheckTemplateDetailManagerImpl>(
    withLocalize<Props>(DeliveryCheckTemplateDetailManagerImpl) as any,
  ),
);
