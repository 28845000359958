/**
 * @file: OpenApiAppAuthorizedStoreService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  OpenApiAppAuthorizedStore,
  OpenApiAppAuthorizedStoreListFilter,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class OpenApiAppAuthorizedStoreServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<OpenApiAppAuthorizedStoreListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<OpenApiAppAuthorizedStore>
      : OpenApiAppAuthorizedStore[]
  > {
    return await this.api
      .get()
      .url(
        '/open-api-app-authorized-stores',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<OpenApiAppAuthorizedStore | null> {
    return await this.api
      .get()
      .url('/open-api-app-authorized-stores/:id', { id })
      .future();
  }

  async getStoreByAccessKey(
    accessKey: string,
  ): Promise<OpenApiAppAuthorizedStore | null> {
    if (!accessKey) {
      throw new Error('missing accessKey parameter');
    }
    return await this.api
      .get()
      .url('/open-api-app-authorized-stores/query/accesskey', { accessKey })
      .future();
  }

  async create(
    openApiAppAuthorizedStore: Partial<OpenApiAppAuthorizedStore>,
  ): Promise<OpenApiAppAuthorizedStore> {
    return await this.api
      .post()
      .url('/open-api-app-authorized-stores')
      .data(openApiAppAuthorizedStore)
      .future();
  }

  async update(
    openApiAppAuthorizedStore: Partial<OpenApiAppAuthorizedStore>,
  ): Promise<OpenApiAppAuthorizedStore> {
    return await this.api
      .put()
      .url('/open-api-app-authorized-stores/:id', {
        id: openApiAppAuthorizedStore.id,
      })
      .data(openApiAppAuthorizedStore)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/open-api-app-authorized-stores/:id', { id })
      .future();
  }
}
