/**
 * @file: ServiceEditionTypeOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { Option, ServiceEditionType } from 'model';

export const ServiceEditionTypeOptions: Array<Option<ServiceEditionType>> = [
  { value: ServiceEditionType.Qf, label: '@string/service_edition_type.QF' },
  { value: ServiceEditionType.S4, label: '@string/service_edition_type.4S' },
  { value: ServiceEditionType.Yz, label: '@string/service_edition_type.YZ' },
];

export const ServiceEditionTypeOptionsWithDefault: Array<
  Option<ServiceEditionType | null>
> = [
  { value: null, label: 'service_edition_type.__default__' },
  { value: ServiceEditionType.Qf, label: '@string/service_edition_type.QF' },
  { value: ServiceEditionType.S4, label: '@string/service_edition_type.4S' },
  { value: ServiceEditionType.Yz, label: '@string/service_edition_type.YZ' },
];
