/**
 * @file: VehicleMaintenanceServicePlanDetailService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleMaintenanceServicePlanDetailServiceBase } from './base/VehicleMaintenanceServicePlanDetailService';

export class VehicleMaintenanceServicePlanDetailService extends VehicleMaintenanceServicePlanDetailServiceBase {
  // add custom service implementation here
}
