/**
 * @file: ReportContentType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum ReportContentType {
  Default = 0,
  PreInspection = 1,
  Inspection = 2,
  Construction = 3,
  DeliveryCheck = 4,
}

export const ReportContentTypeValueSet = new Set([
  ReportContentType.Default,
  ReportContentType.PreInspection,
  ReportContentType.Inspection,
  ReportContentType.Construction,
  ReportContentType.DeliveryCheck,
]);
