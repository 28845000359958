/**
 * @file: user.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { createAsyncActionCreators } from 'lib/duck/actions';
import { AdminUser } from 'model';
import { authService } from 'services';
import { AppState } from 'app/duck/states';

const actionCreators = createAsyncActionCreators<AppState, AdminUser>(
  'app.current_user',
  { fetchHandler: () => authService.getUserInfo() },
);

export const { fetch: fetchUserInfo } = actionCreators;
