/**
 * @file: constants.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export const LOAD = 'load';
export const LOAD_FROM_CACHE = 'load_from_cache';
export const LOAD_SUCCESS = 'load.success';
export const LOAD_FAILED = 'load.failed';
export const INVALIDATE = 'invalidate';
export const UPDATE = 'update';
export const UPDATE_SUCCESS = 'update.success';
export const UPDATE_FAILED = 'update.failed';
export const OBJECT_BEING_UPDATED = 'object_being_updated';
export const OBJECT_BEING_UPDATED_CHANGED = 'object_being_updated.changed';
export const OBJECT_BEING_UPDATED_COMMIT = 'object_being_updated.commit';
export const OBJECT_BEING_UPDATED_CANCEL = 'object_being_updated.cancel';
export const LOAD_MORE = 'load_more';
export const LOAD_MORE_SUCCESS = 'load_more.success';
export const LOAD_MORE_FAILED = 'load_more.failed';
export const UPDATE_TOTAL = 'update_total';
export const UPDATE_OFFSET = 'update_offset';
export const UPDATE_LIMIT = 'update_limit';
export const TOGGLE_SORT_PROPERTY = 'toggle_sort_property';
export const REMOVE_SORT_PROPERTY = 'remove_sort_property';
export const SET_SORT_PROPERTY = 'set_sort_property';
export const UPDATE_SELECTION = 'update_selection';
export const ITEM_SELECTED = 'item_selected';
export const ITEM_DESELECTED = 'item_deselected';
export const TOGGLE_ALL_SELECTION = 'toggle_all_selection';
export const CLEAR_SELECTION = 'clear_selection';
export const CREATE_ITEM = 'create_item';
export const CREATE_ITEM_SUCCESS = 'create_item.success';
export const CREATE_ITEM_FAILED = 'create_item.failed';
export const UPDATE_ITEM = 'update_item';
export const UPDATE_ITEM_SUCCESS = 'update_item.success';
export const UPDATE_ITEM_FAILED = 'update_item.failed';
export const DELETE_ITEM = 'delete_item';
export const DELETE_ITEM_SUCCESS = 'delete_item.success';
export const DELETE_ITEM_FAILED = 'delete_item.failed';
export const DELETE_MULTI = 'delete_multi';
export const DELETE_MULTI_SUCCESS = 'delete_multi.success';
export const DELETE_MULTI_FAILED = 'delete_multi.failed';
export const ITEM_BEING_CREATED = 'item_being_created';
export const ITEM_BEING_CREATED_CHANGED = 'item_being_created.changed';
export const ITEM_BEING_CREATED_COMMIT = 'item_being_created.commit';
export const ITEM_BEING_CREATED_CANCEL = 'item_being_created.cancel';
export const ITEM_BEING_UPDATED = 'item_being_updated';
export const ITEM_BEING_UPDATED_CHANGED = 'item_being_updated.changed';
export const ITEM_BEING_UPDATED_COMMIT = 'item_being_updated.commit';
export const ITEM_BEING_UPDATED_CANCEL = 'item_being_updated.cancel';
export const ITEMS_BEING_DELETED = 'items_being_deleted';
export const ITEMS_BEING_DELETED_COMMIT = 'items_being_deleted.commit';
export const ITEMS_BEING_DELETED_CANCEL = 'items_being_deleted.cancel';
export const UPDATE_FILTER = 'update_filter';
export const LOAD_OBJECT_BY_KEY = 'load_object_by_key';
export const LOAD_OBJECT_BY_KEY_FROM_CACHE = 'load_object_by_key_from_cache';
export const LOAD_OBJECT_BY_KEY_SUCCESS = 'load_object_by_key.success';
export const LOAD_OBJECT_BY_KEY_FAILED = 'load_object_by_key.failed';
export const SET_CURRENT_KEY_OF_OBJECT_BY_KEY = 'set_current_key_of_object_key';
export const INVALIDATE_OBJECT_BY_KEY = 'invalidate_object_by_key';
export const UPDATE_OBJECT_BY_KEY = 'update_object_by_key';
export const REQUEST_UPDATE_OBJECT_BY_KEY = 'request_update_object_by_key';
export const UPDATE_OBJECT_BY_KEY_SUCCESS = 'update_object_by_key.success';
export const UPDATE_OBJECT_BY_KEY_FAILED = 'update_object_by_key.failed';
export const OBJECT_BY_KEY_BEING_UPDATED = 'object_by_key_being_updated';
export const OBJECT_BY_KEY_BEING_UPDATED_CHANGED =
  'object_by_key_being_updated.changed';
export const COMMIT_OBJECT_BY_KEY_BEING_UPDATED =
  'commit_object_by_key_being_updated';
export const CANCEL_OBJECT_BY_KEY_BEING_UPDATED =
  'cancel_object_by_key_being_updated';
export const REMOVE_OBJECT_BY_KEY = 'remove_object_by_key';
export const LOAD_KEYED_LIST = 'load_keyed_list';
export const LOAD_KEYED_LIST_FROM_CACHE = 'load_keyed_list_from_cache';
export const LOAD_KEYED_LIST_SUCCESS = 'load_keyed_list.success';
export const LOAD_KEYED_LIST_FAILED = 'load_keyed_list.failed';
export const INVALIDATE_KEYED_LIST = 'invalidate_keyed_list';
export const LOAD_MORE_KEYED_LIST = 'load_more_keyed_list';
export const LOAD_MORE_KEYED_LIST_SUCCESS = 'load_more_keyed_list.success';
export const LOAD_MORE_KEYED_LIST_FAILED = 'load_more_keyed_list.failed';
export const UPDATE_KEYED_LIST_FILTER = 'update_keyed_list_filter';
export const SET_CURRENT_KEYED_LIST_KEY = 'set_current_keyed_list_key';
export const UPDATE_KEYED_LIST_TOTAL = 'update_keyed_list_total';
export const UPDATE_KEYED_LIST_OFFSET = 'update_keyed_list_offset';
export const UPDATE_KEYED_LIST_LIMIT = 'update_keyed_list_limit';
export const KEYED_LIST_ITEM_SELECTED = 'keyed_list_item_selected';
export const KEYED_LIST_ITEM_DESELECTED = 'keyed_list_item_deselected';
export const KEYED_LIST_TOGGLE_ALL_SELECTION =
  'keyed_list_toggle_all_selection';
export const KEYED_LIST_CLEAR_SELECTION = 'keyed_list_clear_selection';
export const EXPAND_LIST_ITEM_NODE = 'expand_list_item_node';
export const COLLAPSE_LIST_ITEM_NODE = 'collpase_list_item_node';
export const ACTIVE_LIST_GROUP_KEY_CHANGED = 'active_list_group_key_changed';
export const EXPAND_LIST_GROUP = 'expand_list_group';
export const COLLAPSE_LIST_GROUP = 'collapse_list_group';
export const EXPAND_ALL_LIST_GROUP = 'expand_all_list_group';
export const COLLAPSE_ALL_LIST_GROUP = 'collapse_all_list_group';
export const EXPAND_ITEM_DETAIL = 'expand_item_detail';
export const COLLAPSE_ITEM_DETAIL = 'collapse_item_detail';
export const EXPAND_ALL_ITEM_DETAIL = 'expand_all_item_detail';
export const COLLAPSE_ALL_ITEM_DETAIL = 'collapse_all_item_detail';
