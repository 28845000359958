/**
 * @file: AccountTxType.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum AccountTxType {
  ProductReferring = 'product_referring',
  ProductAdoption = 'product_adoption',
  Withdraw = 'withdraw',
  Refund = 'refund',
}

export const AccountTxTypeValueSet = new Set([
  AccountTxType.ProductReferring,
  AccountTxType.ProductAdoption,
  AccountTxType.Withdraw,
  AccountTxType.Refund,
]);
