/**
 * @file: config.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

interface MenuSection {
  key: string;
  title: string;
  items: MenuItem[];
}

interface MenuItem {
  id: string;
  text: string;
  icon: string;
  href: string;
  hidden?: boolean;
}

export const MenuConfig: MenuSection[] = require('./config.yaml');