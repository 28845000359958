/**
 * @file: DeliveryCheckTemplateTypeLabel.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { DeliveryCheckTemplateType } from 'model';
import React from 'react';
import { Translate } from 'react-localize-redux';
import { Badge } from '../Badge';
import { ColorValue, LabelColors } from '../LabelColors';

interface Props {
  value: DeliveryCheckTemplateType | null | undefined;
  noWide?: boolean;
  noRounded?: boolean;
  color?: ColorValue;
}

const Values = {
  [DeliveryCheckTemplateType.WithInspectionResults]: 'with_inspection_results',
  [DeliveryCheckTemplateType.WithoutInspectionResults]:
    'without_inspection_results',
};

export const DeliveryCheckTemplateTypeLabel = React.memo<Props>(props => {
  const { value, color, ...otherProps } = props;

  let enumValue = '';
  if (value !== null && value !== undefined) {
    enumValue = Values[value];
  }

  const labelColor =
    value && LabelColors.DeliveryCheckTemplateType?.[enumValue]
      ? LabelColors.DeliveryCheckTemplateType[enumValue]
      : color;

  return (
    <Badge color={labelColor} {...otherProps}>
      <Translate id={`delivery_check_template_type.${enumValue}`} />
    </Badge>
  );
});
