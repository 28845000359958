/**
 * @file: TransferBatchStatus.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export enum TransferBatchStatus {
  Pending = 'WAIT_PAY',
  Accepted = 'ACCEPTED',
  Processing = 'PROCESSING',
  Finished = 'FINISHED',
  Closed = 'CLOSED',
}

export const TransferBatchStatusValueSet = new Set([
  TransferBatchStatus.Pending,
  TransferBatchStatus.Accepted,
  TransferBatchStatus.Processing,
  TransferBatchStatus.Finished,
  TransferBatchStatus.Closed,
]);
