import classNames from 'classnames';
import React, { Component, CSSProperties, ReactNode } from 'react';
import {
  Alert,
  Breadcrumb,
  Button,
  Nav,
  Portal,
  Portlet,
} from 'shared/metronic/components';
import { PageTitle } from '../PageTitle';
import { Restricted } from '../Restricted';

interface Props {
  title: ReactNode;
  breadcrumbs?: BreadcrumbItem[];
  fullAccessRight: string;
  readonlyAccessRight: string;
  compact?: boolean;
  noPadding?: boolean;
  error?: Error | null;
  noBreadcrumb?: boolean;
  headerComplement?: ReactNode | string | (() => ReactNode | string);
  portletHeaderComplement?: ReactNode | string | (() => ReactNode | string);
  onAdd?: () => void;
  onRefresh?: () => void;
  bodyClassName?: string;
  bodyStyle?: CSSProperties;
  children?: ReactNode;
}

export interface BreadcrumbItem {
  href?: string;
  text?: string | ReactNode;
  icon?: string;
  className?: string;
}

export class Page extends Component<Props> {
  render() {
    const {
      title,
      breadcrumbs,
      fullAccessRight,
      readonlyAccessRight,
      compact,
      noPadding,
      noBreadcrumb,
      headerComplement,
      portletHeaderComplement,
      bodyClassName,
      bodyStyle,
      onAdd,
      onRefresh,
    } = this.props;

    const bcItems: BreadcrumbItem[] = [
      {
        href: '/',
        text: '',
        icon: 'la la-home',
        className: 'm-nav__item--home',
      },
      ...(breadcrumbs || []),
    ];

    const addButton = onAdd ? (
      <Restricted rights={fullAccessRight} silent>
        <Button icon iconOnly air large pill color="brand" onClick={onAdd}>
          <i className="la la-plus" />
        </Button>
      </Restricted>
    ) : null;

    // eslint-disable-next-line @typescript-eslint/init-declarations
    let complement: ReactNode | string;
    if (headerComplement) {
      if (typeof headerComplement === 'function') {
        complement = headerComplement();
      } else {
        complement = headerComplement;
      }
    }

    return (
      <Portal>
        <Portal.Header
          title={title}
          onRefresh={compact ? onRefresh : undefined}
        >
          {noBreadcrumb !== true && (
            <Breadcrumb>
              {bcItems.map(({ text, href, icon, className }, i) => {
                return (
                  <React.Fragment key={i}>
                    {i > 1 && <Nav.Separator>/</Nav.Separator>}
                    <Nav.Item
                      className={className}
                      icon={icon}
                      href={href}
                      text={text}
                    />
                  </React.Fragment>
                );
              })}
            </Breadcrumb>
          )}
          {compact && <div>{addButton}</div>}
          {complement}
        </Portal.Header>
        <Portal.Content>
          <Restricted rights={[fullAccessRight, readonlyAccessRight]}>
            <Portlet>
              {!compact && (
                <Portlet.Header
                  title={<PageTitle title={title} onRefresh={onRefresh} />}
                >
                  {addButton}
                  {typeof portletHeaderComplement === 'function'
                    ? portletHeaderComplement()
                    : portletHeaderComplement}
                </Portlet.Header>
              )}
              <Portlet.Body
                noPadding={noPadding}
                style={bodyStyle}
                className={classNames(bodyClassName)}
              >
                {this.renderErrors()}
                {this.props.children}
              </Portlet.Body>
            </Portlet>
          </Restricted>
        </Portal.Content>
      </Portal>
    );
  }

  renderErrors() {
    const { error } = this.props;
    if (!error) return null;
    return <Alert color="danger">{error.message}</Alert>;
  }
}
