/**
 * @file: randstr.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

export const NUMBER_CHARACTERS = '0123456789';
export const NUMBER_CHARACTERS_NON_LEADING_ZERO = NUMBER_CHARACTERS.substr(1);
export const ALPHABETIC_LOWER_CHARACTERS = 'abcdefghijklmnopqrstuvwxyz';
export const ALPHABETIC_UPPER_CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const ALPHABETIC_CHARACTERS_ALL = ALPHABETIC_LOWER_CHARACTERS + ALPHABETIC_UPPER_CHARACTERS;
export const HEXADECIMAL_LOWER_CHARACTERS = '0123456789abcdef';
export const HEXADECIMAL_UPPER_CHARACTERS = '0123456789ABCDEF';
export const NUMERIC_ALPHABETIC_CHARACTERS_ALL = NUMBER_CHARACTERS + ALPHABETIC_CHARACTERS_ALL;
export const NUMERIC_ALPHABETIC_LOWER_CHARACTERS = NUMBER_CHARACTERS + ALPHABETIC_LOWER_CHARACTERS;
export const NUMERIC_ALPHABETIC_UPPER_CHARACTERS = NUMBER_CHARACTERS + ALPHABETIC_UPPER_CHARACTERS;

export function randstr(len: number, characters: string = NUMERIC_ALPHABETIC_CHARACTERS_ALL) {
  const str = Array(len);
  for (let i = 0; i < len; i++) {
    str[i] = characters.charAt(Math.floor(characters.length * Math.random()));
  }
  return str.join('');
}