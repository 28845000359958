/**
 * @file: VehicleInspectionTaskTemplateSnapshotService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskTemplateSnapshotServiceBase } from './base/VehicleInspectionTaskTemplateSnapshotService';

export class VehicleInspectionTaskTemplateSnapshotService extends VehicleInspectionTaskTemplateSnapshotServiceBase {
  // add custom service implementation here
}
