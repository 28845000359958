import { produce } from 'immer';
import { QuotationTemplateMaterialStaged } from 'model';
import { ReactNode, memo } from 'react';
import { Translate } from 'react-localize-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { array_move, setAdded, setRemoved } from 'utils';
import { usePersistFn } from 'utils/usePersistFn';
import { ItemMaterialEditor } from './MaterialEditor';

const MaterialList = memo(({ children }: { children?: ReactNode }) => {
  return (
    <div className="quotation-tpl-item-editor__material-list-sortable noselect">
      {children}
    </div>
  );
});

const MaterialListItem = memo(({ children }: { children?: ReactNode }) => {
  return (
    <div className="quotation-tpl-item-editor__material-list-item">
      {children}
    </div>
  );
});

const SortableMaterialList = SortableContainer(MaterialList);
const SortableMaterialListItem = SortableElement(MaterialListItem);

export const ItemMaterialList = memo(
  ({
    materials,
    error,
    errors,
    expandedMaterialIds,
    onExpandedMaterialIdsChange,
    showErrorOnChange,
    onChange,
    onErrorStateChange,
  }: {
    materials: QuotationTemplateMaterialStaged[];
    error?: any;
    errors?: Record<string, Record<string, ReactNode>>;
    showErrorOnChange?: boolean;
    expandedMaterialIds: Set<string>;
    onExpandedMaterialIdsChange: (expandedMaterialIds: Set<string>) => void;
    onChange?: (options: QuotationTemplateMaterialStaged[]) => void;
    onErrorStateChange?: (
      materialId: string,
      field: string,
      error: ReactNode | undefined,
    ) => void;
  }) => {
    const onMaterialChange = usePersistFn(
      (material: QuotationTemplateMaterialStaged) => {
        const updated = produce(materials, draft => {
          const index = draft.findIndex(x => x.id === material.id);
          if (index < 0) return;
          Object.assign(draft[index], material);
        });
        onChange?.(updated);
      },
    );

    const onRemove = usePersistFn(
      (material: QuotationTemplateMaterialStaged) => {
        const updated = produce(materials, draft => {
          const index = draft.findIndex(x => x.id === material.id);
          if (index >= 0) {
            draft.splice(index, 1);
          }
        });
        onChange?.(updated);
      },
    );

    const onToggleExpand = usePersistFn(
      (material: QuotationTemplateMaterialStaged) => {
        const set = expandedMaterialIds;
        onExpandedMaterialIdsChange(
          set.has(material.id)
            ? setRemoved(set, material.id)
            : setAdded(set, material.id),
        );
      },
    );

    const onMaterialSorted = usePersistFn(
      (e: { oldIndex: number; newIndex: number }) => {
        const { newIndex, oldIndex } = e;
        if (newIndex === oldIndex) return;
        const updated = produce(materials, draft => {
          array_move(draft, oldIndex, newIndex);
        });
        onChange?.(updated);
      },
    );

    return (
      <div className="quotation-tpl-item-editor__material-list">
        <SortableMaterialList
          helperClass="quotation-tpl-item-editor__material-list-item--being-dragged"
          lockAxis="y"
          distance={3}
          onSortEnd={onMaterialSorted}
        >
          {materials.map((material, index) => (
            <SortableMaterialListItem key={material.id} index={index}>
              <ItemMaterialEditor
                material={material}
                showErrorOnChange={showErrorOnChange}
                errors={errors?.[material.id]}
                expanded={expandedMaterialIds.has(material.id)}
                onChange={onMaterialChange}
                onRemove={onRemove}
                onToggleExpand={onToggleExpand}
                onErrorStateChange={onErrorStateChange}
              />
            </SortableMaterialListItem>
          ))}
        </SortableMaterialList>
        {materials.length === 0 && (
          <div
            style={{
              padding: '1rem 0',
              fontStyle: 'italic',
              fontSize: '0.9rem',
              color: '#999',
            }}
          >
            <Translate id="quotation_tpl.item.editor.no_materials" />
          </div>
        )}
        {materials.length === 0 && error ? (
          <div
            className="invalid-feedback"
            style={{
              display: 'block',
            }}
          >
            {error}
          </div>
        ) : null}
      </div>
    );
  },
);
