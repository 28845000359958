import { showAppLoading } from 'app/duck/actions';
import { quotationTplActions } from 'app/inspection/duck/actions';
import { useQuotationTemplateConfigContext } from 'app/inspection/quotation-template-config/Context';
import { LinkButton } from 'app/inspection/quotation-template-config/LinkButton';
import {
  isQuotationTemplateConfigValid,
  quotationTemplateConfigFromStaged,
} from 'app/inspection/quotation-template-config/util';
import { ChangeEvent, memo, useCallback, useRef } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { quotationTemplateConfigService } from 'services';
import { getString } from 'shared/components';
import { Button } from 'shared/metronic/components';
import { usePersistFn } from 'utils/usePersistFn';

export const Actions = memo(
  ({
    onCreateFromConstructionTemplate,
  }: {
    onCreateFromConstructionTemplate: () => void;
  }) => {
    const { state } = useQuotationTemplateConfigContext();
    const dispatch = useDispatch();

    const onReset = useCallback(() => {
      if (confirm(getString('quotation_tpl.changes.reset.confirm'))) {
        dispatch(quotationTplActions.resetChanges());
      }
    }, [dispatch]);

    const isSaving = useRef(false);

    const onSave = usePersistFn(async () => {
      if (isSaving.current) {
        return;
      }
      try {
        isSaving.current = true;
        dispatch(
          showAppLoading({
            message: getString('quotation_tpl.changes.saving'),
            status: 'loading',
          }),
        );
        const quotationTemplateConfig = quotationTemplateConfigFromStaged(
          state.staged,
        );
        await quotationTemplateConfigService.saveQuotationTemplateConfig(
          quotationTemplateConfig,
        );
        dispatch(quotationTplActions.saveSuccessful(quotationTemplateConfig));
        dispatch(
          showAppLoading({
            message: getString('quotation_tpl.changes.save_success'),
            status: 'success',
            timeout: 3000,
          }),
        );
      } catch (e) {
        console.error(e);
        dispatch(
          showAppLoading({
            message: getString('quotation_tpl.changes.save_error', {
              msg: e.message,
            }),
            status: 'error',
            timeout: 3000,
          }),
        );
      } finally {
        isSaving.current = false;
      }
    });

    const onExport = useCallback(async () => {
      const data = JSON.stringify(
        quotationTemplateConfigFromStaged(state.staged),
        null,
        2,
      );
      const file = new Blob([data], { type: 'application/json' });
      const url = URL.createObjectURL(file);

      const link = document.createElement('a');
      link.download = `quotation-template.json`;
      link.href = url;
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 0);
    }, [state.staged]);

    const onImport = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length !== 1) {
          return;
        }

        if (!confirm(getString('quotation_tpl.modal.confirm_import.msg'))) {
          return;
        }

        const file = e.target.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', async () => {
          const config = JSON.parse(reader.result as string);

          console.log('config: ', config);

          dispatch(quotationTplActions.resetChanges(config));
        });
        reader.readAsText(file);
      },
      [dispatch],
    );

    return (
      <div className="quotation-tpl__actions">
        <LinkButton onClick={onCreateFromConstructionTemplate}>
          <i className="la la-plus" />
          <Translate id="quotation_tpl.button.create_from_construction_template" />
        </LinkButton>
        <Button
          color="secondary"
          small
          pill
          onClick={onExport}
          style={{ marginLeft: '1rem' }}
        >
          <Translate id="quotation_tpl.button.export" />
        </Button>
        <Button color="secondary" small file pill onFileChange={onImport}>
          <Translate id="quotation_tpl.button.import" />
        </Button>
        <Button
          color="danger"
          pill
          small
          disabled={!state.dirty}
          onClick={onReset}
          style={{ marginLeft: '1rem' }}
        >
          <Translate id="quotation_tpl.changes.btn.reset" />
        </Button>
        <Button
          color="primary"
          pill
          small
          disabled={
            !isQuotationTemplateConfigValid(state.staged) || !state.dirty
          }
          onClick={onSave}
        >
          <Translate id="quotation_tpl.changes.btn.save" />
        </Button>
      </div>
    );
  },
);
