/**
 * @file: AgentService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  Agent,
  AgentListFilter,
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
} from 'model';

export class AgentServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<AgentListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions ? ListResult<Agent> : Agent[]
  > {
    return await this.api
      .get()
      .url(
        '/agents',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<Agent | null> {
    return await this.api.get().url('/agents/:id', { id }).future();
  }

  async getAgentByName(name: string): Promise<Agent | null> {
    if (!name) {
      throw new Error('missing name parameter');
    }
    return await this.api.get().url('/agents/query/name', { name }).future();
  }

  async getAgentByShortName(shortName: string): Promise<Agent | null> {
    if (!shortName) {
      throw new Error('missing shortName parameter');
    }
    return await this.api
      .get()
      .url('/agents/query/shortname', { shortName })
      .future();
  }

  async create(agent: Partial<Agent>): Promise<Agent> {
    return await this.api.post().url('/agents').data(agent).future();
  }

  async update(agent: Partial<Agent>): Promise<Agent> {
    return await this.api
      .put()
      .url('/agents/:id', { id: agent.id })
      .data(agent)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api.delete().url('/agents/:id', { id }).future();
  }
}
