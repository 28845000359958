/**
 * @file: serviceEditions.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { ServiceEdition } from 'model';
import { ServiceEditions } from '../states';

const initialState: ServiceEditions = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<
  ServiceEdition,
  ServiceEditions
>('app.settings.service_editions', initialState, {
  mapItemKey: x => x.id,
});

export default function serviceEditionsReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: ServiceEditions = initialState,
  action: StandardAction<any>,
): ServiceEditions {
  switch (action.type) {
    default:
      return defaultReducer(state, action);
  }
}
