/**
 * @file: users.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { AdminUser } from 'model';
import { AdminUsers } from '../states';
import { ActionTypes } from '../types';

const initialState: AdminUsers = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 50,
  filter: {},
};

const defaultReducer = createAsyncListActionReducers<AdminUser, AdminUsers>(
  'app.settings.admin_users',
  initialState,
  {
    mapItemKey: x => x.id,
    onItemBeingUpdated: state => {
      return Object.assign({}, state, {
        itemBeingUpdated: Object.assign({}, state.itemBeingUpdated, {
          password: '',
        }),
      });
    },
  },
);

export default function adminUsersReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: AdminUsers = initialState,
  action: StandardAction<any>,
): AdminUsers {
  switch (action.type) {
    case ActionTypes.SetCurrentAdminUserAcl: {
      return Object.assign({}, state, { currentAcl: action.payload });
    }
    default:
      return defaultReducer(state, action);
  }
}
