import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { ActionThunk } from 'lib/duck/interfaces';
import {
  VehicleDeliveryCheckTemplate,
  VehicleDeliveryCheckTemplateListFilter,
} from 'model';
import { vehicleDeliveryCheckTemplateService } from 'services';

export const deliveryCheckTemplateActions = createListAsyncActionCreators<
  AppState,
  VehicleDeliveryCheckTemplate,
  VehicleDeliveryCheckTemplateListFilter,
  number
>('inspection.delivery_check_templates', {
  shouldInvalidateForFilter: false,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.inspection.deliveryCheckTemplates.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.inspection.deliveryCheckTemplates.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.inspection.deliveryCheckTemplates.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await vehicleDeliveryCheckTemplateService.list(
      {
        ...state.inspection.deliveryCheckTemplates.filter,
        orgId: null,
        storeId: null,
      },
      state.inspection.deliveryCheckTemplates.sorts?.[0],
      state.inspection.deliveryCheckTemplates.offset,
      state.inspection.deliveryCheckTemplates.limit,
      { count: true, recursive: true },
    );
  },
  async create(template: Partial<VehicleDeliveryCheckTemplate>) {
    const entity = {
      ...template,
      agentId: template.agentId ?? null,
      withOrderType: template.withOrderType ?? '',
    };
    return await vehicleDeliveryCheckTemplateService.create(entity);
  },
  async update(template: VehicleDeliveryCheckTemplate) {
    return await vehicleDeliveryCheckTemplateService.update({
      ...template,
      agentId: template.agentId ?? null,
      withOrderType: template.withOrderType ?? '',
    });
  },
  async delete(template: VehicleDeliveryCheckTemplate) {
    await vehicleDeliveryCheckTemplateService.delete(template.id);
  },
  async deleteMulti(sites: VehicleDeliveryCheckTemplate[]) {
    if (sites.length === 1) {
      await vehicleDeliveryCheckTemplateService.delete(sites[0].id);
      return;
    }
    throw new Error('delete multi delivery check template is not supported. ');
  },
});

export function duplicateDeliveryCheckTemplate(
  template: VehicleDeliveryCheckTemplate,
): ActionThunk<AppState> {
  return async dispatch => {
    const duplicate: Partial<VehicleDeliveryCheckTemplate> = {
      ...template,
      id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      isSystemDefault: false,
    };
    try {
      const res = await vehicleDeliveryCheckTemplateService.create(duplicate);
      dispatch(deliveryCheckTemplateActions.createSuccess(duplicate, res));
    } catch (e) {
      dispatch(deliveryCheckTemplateActions.createFailed(duplicate, e));
    }
  };
}
