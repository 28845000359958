import {
  AclObjectList,
  VehicleInspectionTask,
  VehicleInspectionTaskListFilter,
} from 'model';
import {
  InspectionOrderTypeOptionsWithDefault,
  InspectionTaskStatusOptionsWithDefault,
} from 'model/EnumOptions';
import { MouseEvent } from 'react';
import { Translate } from 'react-localize-redux';
import {
  AsideRight,
  EntityListComponentClassBuilder,
  EntityListProps,
  InspectionOrderPriorityLabel,
  InspectionOrderTypeLabel,
  InspectionTaskStatusLabel,
  StoreName,
  StorePicker,
  StringLabel,
} from 'shared/components';
import { CSSProperties } from 'styled-components';
import { formatTime } from 'utils';
import { TaskDetailView } from './Detail';
import { setTaskListActiveTaskId, taskListActions } from './duck/actions';
import { VehicleInspectionTasks } from './duck/states';

import './index.scss';

interface Props
  extends EntityListProps<
    VehicleInspectionTask,
    VehicleInspectionTaskListFilter
  > {}

const componentClassBuilder = new EntityListComponentClassBuilder<
  VehicleInspectionTask,
  VehicleInspectionTaskListFilter,
  number,
  Props
>();

export const VehicleInspectionTaskList = componentClassBuilder
  .i18nPrefix('tasks')
  .accessRights({
    full: AclObjectList.VehicleInspectionTaskFullAccess,
    readonly: AclObjectList.VehicleInspectionTaskReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="tasks.breadcrumb.it" /> },
    { text: <Translate id="tasks.breadcrumb.task_list" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.tasks.taskList)
  .actions(taskListActions)
  .toolbarItems(builder => {
    builder
      .custom({
        prop: 'orgId',
        render: (filter, applyChanges) => {
          const onChange = (
            orgId: number | undefined,
            storeId: number | undefined,
          ) => {
            applyChanges(changes => {
              changes.orgId = orgId;
              changes.storeId = storeId;
            });
          };
          return (
            <div className="task-list-toolbar__store-picker-wrapper">
              <StorePicker
                orgId={filter.orgId}
                storeId={filter.storeId}
                onChange={onChange}
              />
            </div>
          );
        },
      })
      .text({
        prop: 'taskNo',
        label: 'tasks.task_list.toolbar.label.task_no',
        placeholder: 'tasks.task_list.toolbar.placeholder.task_no',
        width: 170,
      })
      .select({
        prop: 'status',
        width: 150,
        array: true,
        clearable: true,
        label: 'tasks.task_list.toolbar.label.status',
        placeholder: 'tasks.task_list.toolbar.placeholder.status',
        values: InspectionTaskStatusOptionsWithDefault.map(x => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
      })
      .select({
        prop: 'orderType',
        width: 150,
        array: true,
        clearable: true,
        label: 'tasks.task_list.toolbar.label.task_type',
        placeholder: 'tasks.task_list.toolbar.placeholder.task_type',
        values: InspectionOrderTypeOptionsWithDefault.map(x => ({
          ...x,
          label: <Translate id={x.label} />,
        })),
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(taskListActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'taskId',
      width: 100,
      align: 'center',
      text: '',
      render: (task: VehicleInspectionTask) => {
        return (
          <img
            src={task.vehicleBrandLogo!}
            alt={task.vehicleBrandName!}
            style={{ width: 100 }}
          />
        );
      },
    },
    {
      prop: 'taskNo',
      width: 200,
      text: 'col.task_no',
      render: (task: VehicleInspectionTask, props: Props) => {
        const onClick = (e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          const { dispatch } = props;
          dispatch(setTaskListActiveTaskId(task.id));
        };
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StoreName orgId={task.orgId} mode="org" />
            </div>
            <div style={style}>
              <StoreName storeId={task.storeId} mode="store" />
            </div>
            <div style={{ margin: '5px 0' }}>
              <a
                href="#"
                style={{ textDecoration: 'underline', color: '#222' }}
                onClick={onClick}
              >
                {task.taskNo}
              </a>
            </div>
            <div>
              <InspectionOrderTypeLabel value={task.orderType} />
            </div>
          </div>
        );
      },
    },
    {
      prop: 'vehicleName',
      width: 250,
      text: 'col.vehicle_info',
      render: entity => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="vehicle_label.name" />
              {entity.vehicleName}
            </div>
            <div style={style}>
              <StringLabel value="vehicle_label.brand" />
              {entity.vehicleBrandName}
            </div>
            <div style={style}>
              <StringLabel value="vehicle_label.mileage" />
              {entity.vehicleMileage! < 1
                ? entity.vehicleMileage! * 10000
                : entity.vehicleMileage}
              <StringLabel value="value_unit.km" />
            </div>
          </div>
        );
      },
    },
    {
      prop: 'status',
      width: 80,
      align: 'center',
      text: 'col.status',
      render: ({ status }) => <InspectionTaskStatusLabel value={status} />,
    },
    {
      prop: 'priority',
      width: 80,
      align: 'center',
      text: 'tasks.task_list.col.priority',
      render: ({ priority }) => (
        <InspectionOrderPriorityLabel value={priority} />
      ),
    },
    {
      prop: 'totalSiteCount',
      width: 200,
      text: 'tasks.task_list.col.stats',
      render: task => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="tasks.detail.label.total_site_count" />
              {task.totalSiteCount}
            </div>
            <div style={style}>
              <StringLabel value="tasks.detail.label.abnormal_site_count" />
              {task.abnormalSiteCount}
            </div>
            <div style={style}>
              <StringLabel value="tasks.detail.label.normal_site_count" />
              {task.normalSiteCount}
            </div>
            <div style={style}>
              <StringLabel value="tasks.detail.label.urgent_site_count" />
              {task.urgentSiteCount}
            </div>
          </div>
        );
      },
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 200,
      render: task => {
        const style: CSSProperties = {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        };
        return (
          <div>
            <div style={style}>
              <StringLabel value="tasks.detail.label.created_at" />
              {formatTime(task.createdAt)}
            </div>
            <div style={style}>
              <StringLabel value="tasks.detail.label.started_at" />
              {task.startedAt ? formatTime(task.startedAt) : '-'}
            </div>
            <div style={style}>
              <StringLabel value="tasks.detail.label.finished_at" />
              {task.finishedAt ? formatTime(task.finishedAt) : '-'}
            </div>
            {task.canceledAt && (
              <div style={style}>
                <StringLabel value="tasks.detail.label.suspended_at" />
                {task.canceledAt ? formatTime(task.canceledAt) : '-'}
              </div>
            )}
          </div>
        );
      },
    },
  ])
  .onRender((props: Props) => {
    const { dispatch } = props;
    const entities = props.entities as VehicleInspectionTasks;
    const onClose = () => {
      dispatch(setTaskListActiveTaskId(undefined));
    };
    return (
      <AsideRight open={Boolean(entities.activeTaskId)} onClose={onClose}>
        {entities.result && entities.activeTaskId && (
          <TaskDetailView
            task={entities.result.find(x => x.id === entities.activeTaskId)}
          />
        )}
      </AsideRight>
    );
  })
  .getClass();
