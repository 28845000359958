/**
 * @file: VehicleModelService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleModel,
  VehicleModelListFilter,
} from 'model';

export class VehicleModelServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?: Partial<VehicleModelListFilter> | null | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleModel>
      : VehicleModel[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-models',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleModel | null> {
    return await this.api.get().url('/vehicle-models/:id', { id }).future();
  }

  async create(vehicleModel: Partial<VehicleModel>): Promise<VehicleModel> {
    return await this.api
      .post()
      .url('/vehicle-models')
      .data(vehicleModel)
      .future();
  }

  async update(vehicleModel: Partial<VehicleModel>): Promise<VehicleModel> {
    return await this.api
      .put()
      .url('/vehicle-models/:id', { id: vehicleModel.id })
      .data(vehicleModel)
      .future();
  }

  async delete(id: number): Promise<void> {
    await this.api.delete().url('/vehicle-models/:id', { id }).future();
  }
}
