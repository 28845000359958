import React, { useCallback , memo, useEffect, useState } from 'react';
import { Agent, Organization, OrgMember, OrgUserRoleType } from 'model';
import { Translate } from 'react-localize-redux';
import { organizationService, orgMemberService } from 'services';
import { AsideRight, getString } from 'shared/components';
import { Alert } from 'shared/metronic/components';

export const SendStatsRemind = memo(
  ({
    agent,
    onSend,
    onClose,
  }: {
    agent?: Agent;
    onSend?: (targetUserIds: number[]) => void;
    onClose?: () => void;
  }) => {
    return (
      <AsideRight open={Boolean(agent)} onClose={onClose}>
        {agent ? (
          <Content agent={agent} onSend={onSend} onClose={onClose} />
        ) : null}
      </AsideRight>
    );
  },
);

const Content = memo(
  ({
    agent,
    onSend,
    onClose,
  }: {
    agent: Agent;
    onSend?: (targetUserIds: number[]) => void;
    onClose?: () => void;
  }) => {
    const [users, setUsers] = useState<OrgMember[]>();
    const [error, setError] = useState<string>();
    const [organization, setOrganization] = useState<Organization | null>();
    const [selected, setSelected] = useState<number[]>([]);

    const onClick = useCallback((user: OrgMember) => {
      const userId = user.userId;
      setSelected(values => {
        const set = new Set(values);
        if (set.has(userId)) {
          set.delete(userId);
        } else {
          set.add(userId);
        }
        return [...set];
      });
    }, []);

    const onSendClick = useCallback(async () => {
      onSend?.(selected);
    }, [selected, onSend]);

    // list users
    useEffect(() => {
      if (!agent.masterOrgId) {
        setError(
          getString('agent.send_stats_remind.modal.error.master_org_not_set'),
        );
        return;
      }
      organizationService
        .get(agent.masterOrgId)
        .then(async org => {
          setOrganization(org);

          if (org == null) {
            setError(
              getString(
                'agent.send_stats_remind.modal.error.master_org_not_found',
              ),
            );
            return;
          }

          if (!org.isWeixinOpenAuthorized || !org.wechatMpAppid) {
            setError(
              getString(
                'agent.send_stats_remind.modal.error.master_org_not_set',
              ),
            );
            return;
          }

          const res = await orgMemberService.list(
            { orgId: agent.masterOrgId },
            null,
            0,
            0,
            { recursive: true },
          );
          setUsers(res);
        })
        .catch(err => {
          setError(err.message);
        });
    }, [agent]);

    return (
      <div>
        <ul
          className="nav nav-tabs m-tabs m-tabs-line m-tabs-line--brand"
          style={{ marginBottom: 15 }}
        >
          <li className="nav-item m-tabs__item">
            <a
              className="nav-link m-tabs__link active"
              style={{ fontWeight: 'bold', fontSize: '120%' }}
            >
              <Translate id={`agent.send_stats_remind.modal.title`} />
            </a>
          </li>
        </ul>

        {organization != null ? (
          <h4
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              marginTop: '2rem',
              marginBottom: '1rem',
              padding: '1rem 0.75rem',
              borderBottom: '1px solid #ccc',
              backgroundColor: '#ddd',
            }}
          >
            {organization.name}
          </h4>
        ) : null}

        <p>
          <Translate id="agent.send_stats_remind.modal.msg" />
        </p>

        {error ? (
          <Alert color="danger">{error}</Alert>
        ) : (
          users?.map(user => (
            <UserCell
              user={user}
              key={user.userId}
              checked={selected.includes(user.userId)}
              onClick={onClick}
            />
          ))
        )}

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            marginTop: '1rem',
          }}
        >
          <button
            onClick={onSendClick}
            style={{
              padding: '0.5rem 1rem',
            }}
          >
            <Translate id="agent.send_stats_remind.modal.btn.send" />
          </button>
          <button
            onClick={onClose}
            style={{ marginLeft: '1rem', padding: '0.5rem 1rem' }}
          >
            <Translate id="agent.send_stats_remind.modal.btn.cancel" />
          </button>
        </div>
      </div>
    );
  },
);

const UserCell = memo(
  ({
    user,
    checked,
    onClick,
  }: {
    user: OrgMember;
    checked?: boolean;
    onClick?: (user: OrgMember) => void;
  }) => {
    const handleClick = useCallback(() => {
      onClick?.(user);
    }, [user, onClick]);
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '0.5rem',
          borderBottom: '1px solid #ddd',
          cursor: 'default',
        }}
        onClick={handleClick}
      >
        <input
          type="checkbox"
          checked={Boolean(checked)}
          style={{
            marginRight: '0.75rem',
          }}
        />
        <div>{user.name ?? user.userName}</div>
        {user.role?.includes(OrgUserRoleType.AgentManager) ? (
          <div
            style={{
              marginLeft: '0.75rem',
              backgroundColor: '#eee',
              padding: '0.1rem 0.5rem',
              fontSize: '0.9rem',
              color: '#666',
              borderRadius: 3,
            }}
          >
            <Translate id="agent.send_stats_remind.modal.label.agent_manager" />
          </div>
        ) : null}
      </div>
    );
  },
);
