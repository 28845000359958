import { AppState } from 'app/duck/states';
import { createListAsyncActionCreators } from 'lib/duck/actions';
import { OpenApiUser, OpenApiUserListFilter } from 'model';
import { openApiUserService } from 'services';
import { sha256 } from 'utils';

export const openApiUserActions = createListAsyncActionCreators<
  AppState,
  OpenApiUser,
  OpenApiUserListFilter,
  number
>('openapi.users', {
  shouldInvalidateForFilter: true,
  shouldFetchOnInvalidate: true,
  getItemBeingCreated: (state: AppState) =>
    state.openapi.users.itemBeingCreated,
  getItemBeingUpdated: (state: AppState) =>
    state.openapi.users.itemBeingUpdated,
  getItemsBeingDeleted: (state: AppState) =>
    state.openapi.users.itemsBeingDeleted,
  async fetchHandler(state: AppState) {
    return await openApiUserService.list(
      state.openapi.users.filter,
      state.openapi.users.sorts?.[0],
      state.openapi.users.offset,
      state.openapi.users.limit,
      { count: true, recursive: true },
    );
  },
  async create(user: Partial<OpenApiUser>) {
    user = Object.assign({}, user, { password: sha256(user.password!) });
    return await openApiUserService.create(user);
  },
  async update(user: OpenApiUser) {
    if (user.password) {
      user = Object.assign({}, user, { password: sha256(user.password) });
    }
    return await openApiUserService.update(user);
  },
  async delete(user: OpenApiUser) {
    await openApiUserService.delete(user.id);
  },
  async deleteMulti(orgs: OpenApiUser[]) {
    if (orgs.length === 1) {
      await openApiUserService.delete(orgs[0].id);
      return;
    }
    throw new Error('delete multi openapi users is not supported. ');
  },
});
