/**
 * @file: VehicleInspectionTaskTroubleCodeService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskTroubleCodeServiceBase } from './base/VehicleInspectionTaskTroubleCodeService';

export class VehicleInspectionTaskTroubleCodeService extends VehicleInspectionTaskTroubleCodeServiceBase {
  // add custom service implementation here
}
