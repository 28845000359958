/**
 * @file: VehicleInfoService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInfoServiceBase } from './base/VehicleInfoService';

export class VehicleInfoService extends VehicleInfoServiceBase {
  // add custom service implementation here
  async selfCheck() {
    await this.api.post().url('/vehicle-infos/self-check').future();
  }
}
