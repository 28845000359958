import React, { Component } from 'react';

export class NotFound extends Component {
  render() {
    return (
      <div
        className="m-grid__item m-grid__item--fluid m-grid m-error-1"
        style={{
          backgroundImage: 'url(vendors/metronic/base/images/error/bg1.jpg)'
        }}
        >
        <div className="m-error_container">
          <span className="m-error_number">
            <h1>404</h1>
          </span>
          <p className="m-error_desc">
            OOPS! Something went wrong here
          </p>
        </div>
      </div>
    );
  }
}