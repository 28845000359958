/**
 * @file: VehicleInspectionTaskCustomIssueService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskCustomIssue,
  VehicleInspectionTaskCustomIssueListFilter,
  VehicleInspectionTaskCustomIssueMedia,
} from 'model';

export class VehicleInspectionTaskCustomIssueServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskCustomIssueListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskCustomIssue>
      : VehicleInspectionTaskCustomIssue[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-custom-issues',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskCustomIssue | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-custom-issues/:id', { id })
      .future();
  }

  async create(
    vehicleInspectionTaskCustomIssue: Partial<VehicleInspectionTaskCustomIssue>,
  ): Promise<VehicleInspectionTaskCustomIssue> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-custom-issues')
      .data(vehicleInspectionTaskCustomIssue)
      .future();
  }

  async update(
    vehicleInspectionTaskCustomIssue: Partial<VehicleInspectionTaskCustomIssue>,
  ): Promise<VehicleInspectionTaskCustomIssue> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-custom-issues/:id', {
        id: vehicleInspectionTaskCustomIssue.id,
      })
      .data(vehicleInspectionTaskCustomIssue)
      .future();
  }

  async delete(id: number): Promise<void> {
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-custom-issues/:id', { id })
      .future();
  }

  async getMediasByIssueId(
    id: number,
    recursive = false,
  ): Promise<VehicleInspectionTaskCustomIssueMedia[]> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-custom-issues/:id/medias', {
        id,
        recursive,
      })
      .future();
  }

  async createVehicleInspectionTaskCustomIssueMedia(
    media: Partial<VehicleInspectionTaskCustomIssueMedia>,
  ): Promise<VehicleInspectionTaskCustomIssueMedia> {
    const id = media.issueId;
    if (!id) {
      throw new Error('media.issueId is required. ');
    }
    return await this.api
      .post()
      .url('/vehicle-inspection-task-custom-issues/:id/medias')
      .data(media)
      .future();
  }

  async updateVehicleInspectionTaskCustomIssueMedia(
    media: VehicleInspectionTaskCustomIssueMedia,
  ): Promise<VehicleInspectionTaskCustomIssueMedia> {
    const id = media.issueId;
    if (!id) {
      throw new Error('media.issueId is required. ');
    }
    return await this.api
      .put()
      .url('/vehicle-inspection-task-custom-issues/:id/medias')
      .data(media)
      .future();
  }

  async deleteVehicleInspectionTaskCustomIssueMedia(
    issueId: number | VehicleInspectionTaskCustomIssueMedia,
    mediaId?: number,
  ): Promise<void> {
    if (issueId && typeof issueId === 'object') {
      mediaId = issueId.id;
      if (!issueId.issueId) {
        throw new Error('issueId cannot be null or undefined');
      }
      issueId = issueId.issueId;
    }
    if (!issueId || !mediaId) {
      throw new Error('issueId and mediaId are both required');
    }
    return void await this.api
      .delete()
      .url('/vehicle-inspection-task-custom-issues/:issueId/medias/:mediaId', {
        issueId,
        mediaId,
      })
      .future();
  }
}
