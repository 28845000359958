/**
 * @file: StoreCustomerService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { StoreCustomerServiceBase } from './base/StoreCustomerService';

export class StoreCustomerService extends StoreCustomerServiceBase {
  // add custom service implementation here
}
