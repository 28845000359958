import classNames from 'classnames';
import React, {
  Component,
  MouseEvent,
  ReactChild,
  ReactNode,
  createRef,
} from 'react';
import { DocumentTitle } from 'shared/components';

interface Props {
  noText?: boolean;
  title?: ReactNode;
  titleSeparator?: boolean;
  children?: ReactNode;
  onRefresh?: () => void;
}

type State = { title?: string };

export class SubHeader extends Component<Props, State> {
  state: State = { title: '' };

  private readonly titleRef = createRef<HTMLDivElement>();

  componentDidMount(): void {
    this.setState({ title: this.titleRef.current?.innerText });
  }

  componentDidUpdate(): void {
    const title = this.titleRef.current?.innerText;
    if (this.state.title !== title) {
      this.setState({ title });
    }
  }

  render() {
    const { title, noText, children, onRefresh } = this.props;
    let { titleSeparator } = this.props;

    const childComponents: ReactChild[] = React.Children.toArray(
      children,
    ) as any;
    let breadcrumb: ReactChild | undefined = undefined;
    for (let i = childComponents.length - 1; i >= 0; i--) {
      const child = childComponents[i];
      if (
        typeof child !== 'string' &&
        typeof child !== 'number' &&
        typeof child.type !== 'string' &&
        (child.type as any).displayName === 'Breadcrumb'
      ) {
        breadcrumb = child;
        childComponents.splice(i, 1);
        if (titleSeparator === void 0) titleSeparator = true;
      }
    }

    const onRefreshEventHandler = (e: MouseEvent) => {
      e.preventDefault();
      onRefresh && onRefresh();
    };

    return (
      <div className="m-subheader">
        <div style={{ display: 'none' }} ref={this.titleRef}>
          {title}
        </div>
        <DocumentTitle title={this.state.title || ''} />
        <div className="d-flex align-items-center">
          <div className="mr-auto">
            {title && !noText && (
              <h3
                className={classNames('m-subheader__title', {
                  'm-subheader__title--separator': titleSeparator,
                })}
              >
                {title}
                {onRefresh && (
                  <a
                    href="#"
                    className="refresh-btn"
                    onClick={onRefreshEventHandler}
                    style={{ marginLeft: 10 }}
                  >
                    <i className="flaticon-refresh" />
                  </a>
                )}
              </h3>
            )}
            {breadcrumb}
          </div>
          <div className="m-subheader__complements">{childComponents}</div>
        </div>
      </div>
    );
  }
}
