/**
 * @file: AdminUserRoleOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { AdminUserRole, Option } from 'model';

export const AdminUserRoleOptions: Array<Option<AdminUserRole>> = [
  { value: AdminUserRole.SuperUsers, label: 'admin_user_role.superusers' },
  {
    value: AdminUserRole.Administrators,
    label: 'admin_user_role.administrators',
  },
  {
    value: AdminUserRole.CustomerSupport,
    label: 'admin_user_role.customersupport',
  },
  { value: AdminUserRole.Sales, label: 'admin_user_role.sales' },
];

export const AdminUserRoleOptionsWithDefault: Array<
  Option<AdminUserRole | null>
> = [
  { value: null, label: 'admin_user_role.__default__' },
  { value: AdminUserRole.SuperUsers, label: 'admin_user_role.superusers' },
  {
    value: AdminUserRole.Administrators,
    label: 'admin_user_role.administrators',
  },
  {
    value: AdminUserRole.CustomerSupport,
    label: 'admin_user_role.customersupport',
  },
  { value: AdminUserRole.Sales, label: 'admin_user_role.sales' },
];
