/**
 * @file: init.js
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

// eslint-disable-next-line no-undef
$(document.body).tooltip({
  trigger: 'hover',
  selector: '[data-toggle="tooltip"]',
});
