import { StandardAction } from 'lib/duck/interfaces';
import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { InspectionSiteType, VehicleInspectionSite } from 'model';
import { InspectionSites } from '../states';
import { ActionTypes } from '../types';

const initialState: InspectionSites = {
  isLoading: false,
  error: null,
  result: null,
  offset: 0,
  limit: 0,
  filter: {},
  activeGroupKey: 'all',
};

const defaultReducer = createAsyncListActionReducers<
  VehicleInspectionSite,
  InspectionSites
>('inspection.sites', initialState, {
  mapItemKey: x => x.id,
});

export default function (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: InspectionSites | undefined = initialState,
  action: StandardAction<any>,
): InspectionSites {
  switch (action.type) {
    case ActionTypes.SiteKeywordFilterChanged:
      return {
        ...state,
        keyword: action.payload,
      };
    case ActionTypes.InventoryManagerSetTargetCategoryId:
      return {
        ...state,
        targetCategoryId: action.payload,
      };
    case ActionTypes.BatchSetType: {
      return {
        ...state,
        siteIdsForBatchSetType: action.payload,
        isBatchSettingType: false,
        batchSettingTypeError: undefined,
      };
    }
    case ActionTypes.RequestBatchSetType: {
      return {
        ...state,
        isBatchSettingType: true,
        batchSettingTypeError: undefined,
      };
    }
    case ActionTypes.BatchSetTypeSuccess: {
      const type = action.payload as InspectionSiteType;
      const siteIds = state.siteIdsForBatchSetType!;
      const siteIdSet = new Set(siteIds);
      return {
        ...state,
        siteIdsForBatchSetType: undefined,
        isBatchSettingType: false,
        result:
          state.result?.map(site => {
            if (!siteIdSet.has(site.id)) {
              return site;
            }
            return { ...site, type };
          }) ?? null,
      };
    }
    case ActionTypes.BatchSetTypeError: {
      return {
        ...state,
        isBatchSettingType: false,
        batchSettingTypeError: action.payload,
      };
    }

    case ActionTypes.BatchSetModule: {
      return {
        ...state,
        siteIdsForBatchSetModule: action.payload,
        isBatchSettingModule: false,
        batchSettingModuleError: undefined,
      };
    }
    case ActionTypes.RequestBatchSetModule: {
      return {
        ...state,
        isBatchSettingModule: true,
        batchSettingModuleError: undefined,
      };
    }
    case ActionTypes.BatchSetModuleSuccess: {
      const { primaryModuleId, secondaryModuleId } = action.payload as {
        primaryModuleId: number | null | undefined;
        secondaryModuleId: number | null | undefined;
      };
      const siteIds = state.siteIdsForBatchSetModule!;
      const siteIdSet = new Set(siteIds);
      return {
        ...state,
        siteIdsForBatchSetModule: undefined,
        isBatchSettingModule: false,
        result:
          state.result?.map(site => {
            if (!siteIdSet.has(site.id)) {
              return site;
            }
            return {
              ...site,
              primarySysModId: primaryModuleId,
              secondarySysModId: secondaryModuleId,
            };
          }) ?? null,
      };
    }
    case ActionTypes.BatchSetModuleError: {
      return {
        ...state,
        isBatchSettingModule: false,
        batchSettingModuleError: action.payload,
      };
    }

    default:
      return defaultReducer(state, action);
  }
}
