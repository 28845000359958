/**
 * @file: index.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import {
  AsyncListState,
  AsyncObjectMapState,
  AsyncState,
} from 'lib/duck/interfaces';
import {
  DatePartitionType,
  DateRange,
  Organization,
  OrganizationListFilter,
  StatItem,
  Store,
  StoreListFilter,
  SummaryStats,
  User,
  UserListFilter,
  VehicleInfo,
  VehicleInfoListFilter,
  VehicleInspectionComment,
  VehicleInspectionCommentListFilter,
  VehicleInspectionTask,
  VehicleInspectionTaskListFilter,
  VehicleReceptionOrder,
  VehicleReceptionOrderListFilter,
  VehicleReport,
  VehicleReportFilter,
} from 'model';

export const enum StatAspect {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Organization = 'organization',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  Store = 'store',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  User = 'user',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  VehicleInfo = 'vehicle',
  ReceptionOrder = 'reception-order',
  InspectionTask = 'inspection-task',
  InspectionReport = 'inspection-report',
  InspectionComment = 'inspection-comment',
}

export interface SummaryStatsState extends AsyncState<SummaryStats> {}

export interface StatsStateByTime
  extends AsyncObjectMapState<DatePartitionType, StatItem[]> {
  partition: DatePartitionType;
}

export interface RecentOrganizations
  extends AsyncListState<Organization, OrganizationListFilter, number> {}

export interface RecentStores
  extends AsyncListState<Store, StoreListFilter, number> {}

export interface RecentUsers
  extends AsyncListState<User, UserListFilter, number> {}

export interface RecentReceptionOrders
  extends AsyncListState<
    VehicleReceptionOrder,
    VehicleReceptionOrderListFilter,
    number
  > {}

export interface RecentInspectionTasks
  extends AsyncListState<
    VehicleInspectionTask,
    VehicleInspectionTaskListFilter,
    number
  > {}

export interface RecentInspectionReports
  extends AsyncListState<VehicleReport, VehicleReportFilter, number> {}

export interface RecentInspectionComments
  extends AsyncListState<
    VehicleInspectionComment,
    VehicleInspectionCommentListFilter,
    number
  > {}

export interface RecentVehicleInfos
  extends AsyncListState<VehicleInfo, VehicleInfoListFilter, number> {}

export interface DashboardState {
  // date range and current stats item options state.
  dateRange: DateRange;
  activeStatAspect: StatAspect;

  // summary stats
  summaryStats: SummaryStatsState;
  companionSummaryStats: SummaryStatsState; // for chain relative (or year on year) ratio

  // detailed stats by time
  orgStatsByTime: StatsStateByTime;
  storeStatsByTime: StatsStateByTime;
  userStatsByTime: StatsStateByTime;
  receptionOrderStatsByTime: StatsStateByTime;
  inspectionTaskStatsByTime: StatsStateByTime;
  vehicleStatsByTime: StatsStateByTime;
  inspectionReportStatsByTime: StatsStateByTime;
  inspectionCommentStatsByTime: StatsStateByTime;

  // recent stuffs
  recentOrganizations: RecentOrganizations;
  recentStores: RecentStores;
  recentUsers: RecentUsers;
  recentReceptionOrders: RecentReceptionOrders;
  recentInspectionTasks: RecentInspectionTasks;
  recentInspectionReports: RecentInspectionReports;
  recentInspectionComments: RecentInspectionComments;
  recentVehicleInfos: RecentVehicleInfos;
}
