/**
 * @file: VehicleInspectionTaskCheckSiteItemService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { VehicleInspectionTaskCheckSiteItemServiceBase } from './base/VehicleInspectionTaskCheckSiteItemService';

export class VehicleInspectionTaskCheckSiteItemService extends VehicleInspectionTaskCheckSiteItemServiceBase {
  // add custom service implementation here
}
