import { FileUploadResult, ImageFileExtraInfo } from 'model';
import { PureComponent } from 'react';
import { FilePicker } from 'shared/components/FilePicker';
import { getString } from 'shared/components/StringLabel';
import { renderFilePreviewer } from '../helpers';
import { FormElementFile } from '../types';

const DEFAULT_MEDIA_WIDTH = 244;
const DEFAULT_MEDIA_HEIGHT = 88;

interface Props<T> {
  element: FormElementFile<T>;
  disabled?: boolean;
  value: any;
  onGetExtraInfo: (() => any) | undefined | null;
  onChange: (values: Partial<T>) => void;
}

export class FormFilePicker<T> extends PureComponent<Props<T>> {
  render() {
    const { element, value, disabled } = this.props;
    const pickerInfo = element.filePicker || {};
    return (
      <FilePicker
        disabled={disabled}
        cover={pickerInfo.cover}
        imageSize={pickerInfo.mediaSize}
        imageWidth={pickerInfo.mediaWidth}
        imageHeight={pickerInfo.mediaHeight}
        coverSize={pickerInfo.coverSize}
        coverWidth={pickerInfo.coverWidth}
        coverHeight={pickerInfo.coverHeight}
        accept={
          pickerInfo.accept ||
          (element.type === 'image' ? 'image/png, image/jpeg' : 'video/mp4')
        }
        realm={pickerInfo.realm}
        value={value || ''}
        onChange={this.onChange}
      >
        {this.renderFilePickerContents}
      </FilePicker>
    );
  }

  onChange = (file: FileUploadResult) => {
    const { element, onChange, onGetExtraInfo } = this.props;
    const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
    const pickerInfo = element.filePicker || {};
    const changes: { [K in keyof T]?: T[K] } = {};
    changes[element.prop] = file.url as any;
    if (element.type === 'image') {
      if (pickerInfo.cover && pickerInfo.coverProp && file.extra) {
        const coverUrl = (file.extra as ImageFileExtraInfo).cover;
        changes[pickerInfo.coverProp] = coverUrl as any;
      }
    }
    void element.onChange?.(changes, extra);
    onChange(changes);
  };

  getSize() {
    const { element } = this.props;
    const pickerInfo = element.filePicker || {};
    const width = DEFAULT_MEDIA_WIDTH;
    let height = DEFAULT_MEDIA_HEIGHT;
    if (
      pickerInfo.mediaWidth &&
      pickerInfo.mediaWidth > 0 &&
      pickerInfo.mediaHeight &&
      pickerInfo.mediaHeight > 0
    ) {
      const aspectRatio = pickerInfo.mediaHeight / pickerInfo.mediaWidth;
      height = width * aspectRatio;
    }
    return { width, height };
  }

  renderFilePickerContents = (url: string) => {
    const { element, onChange, onGetExtraInfo } = this.props;
    const pickerInfo = element.filePicker || {};
    const { width, height } = this.getSize();
    return renderFilePreviewer({
      type: element.type as any,
      url,
      width,
      height,
      posterUrl: pickerInfo.posterUrl,
      onRemoveClick: e => {
        e.preventDefault();
        e.stopPropagation();
        if (!confirm(getString('@string/confirm_remove_file_picker_object'))) {
          return;
        }
        const extra = onGetExtraInfo ? onGetExtraInfo() : undefined;
        const changes: { [K in keyof T]?: T[K] } = {};
        changes[element.prop] = null as any;
        void element.onChange?.(changes, extra);
        onChange(changes);
      },
    });
  };
}
