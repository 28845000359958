import { hideAppLoading, showAppLoading } from 'app/duck/actions';
import { useConstructionTemplateConfigContext } from 'app/inspection/construction-template-config/Context';
import { isConstructionTemplateConfigValid } from 'app/inspection/construction-template-config/util';
import { constructionTplActions } from 'app/inspection/duck/actions';
import React, { memo, useCallback, useRef } from 'react';
import { Translate } from 'react-localize-redux';
import { useDispatch } from 'react-redux';
import { constructionTemplateConfigService } from 'services';
import { getString } from 'shared/components';
import { Button } from 'shared/metronic/components';
import { usePersistFn } from 'utils/usePersistFn';

export const Actions = memo(() => {
  const { templateConfigBeingEdited } = useConstructionTemplateConfigContext();
  const dispatch = useDispatch();

  const onReset = useCallback(() => {
    dispatch(constructionTplActions.resetChanges());
  }, [dispatch]);

  const isSaving = useRef(false);

  const onSave = usePersistFn(async () => {
    if (templateConfigBeingEdited == null || isSaving.current) {
      return;
    }
    try {
      isSaving.current = true;
      dispatch(
        showAppLoading({
          message: getString('construction_tpl.changes.saving'),
          status: 'loading',
        }),
      );
      await constructionTemplateConfigService.saveConstructionTemplateConfig(
        templateConfigBeingEdited,
      );
      dispatch(constructionTplActions.resetChanges(templateConfigBeingEdited));
      dispatch(
        showAppLoading({
          message: getString('construction_tpl.changes.save_success'),
          status: 'success',
          timeout: 3000,
        }),
      );
    } catch (e) {
      alert(e.message);
    } finally {
      isSaving.current = false;
      dispatch(hideAppLoading());
    }
  });

  if (templateConfigBeingEdited == null) {
    return null;
  }

  return (
    <div className="construction-tpl-man__actions">
      <div className="construction-tpl-man__actions-msg">
        <i className="fa fa-info-circle" />
        <Translate id="construction_tpl.changes.tip" />
      </div>
      <Button color="secondary" small onClick={onReset}>
        <Translate id="construction_tpl.changes.btn.reset" />
      </Button>
      <Button
        color="primary"
        small
        disabled={!isConstructionTemplateConfigValid(templateConfigBeingEdited)}
        onClick={onSave}
      >
        <Translate id="construction_tpl.changes.btn.save" />
      </Button>
    </div>
  );
});
