import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { orgUserActions, setCurrentUserQrcodeAuth } from '../duck/actions';
import { OrgUser, OrgUserListFilter, AclObjectList } from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  StorePicker,
  getString,
  Checkmark,
  OrgInfoView,
  StoreName,
} from 'shared/components';
import { Block } from 'shared/metronic/components';
import { UserAuthQrcodeAside } from 'app/customers/org-users/UserAuthQrcodeAside';
import { OrgUserPrincipal } from 'app/customers/duck/states';

interface Props extends EntityListProps<OrgUser, OrgUserListFilter> {
  currentQrcodeAuthUserPrincipal?: OrgUserPrincipal | undefined;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OrgUser,
  OrgUserListFilter,
  number,
  Props
>();

export const OrgUserList = componentClassBuilder
  .i18nPrefix('org_user')
  .accessRights({
    full: AclObjectList.OrgUserFullAccess,
    readonly: AclObjectList.OrgUserReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="customer.breadcrumb.it" /> },
    { text: <Translate id="customer.breadcrumb.org_users" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.customers.orgUsers)
  .actions(orgUserActions)
  .editor(builder =>
    builder.text({
      prop: 'password',
      type: 'password',
      label: 'org_user.editor.label.password',
      placeholder: 'org_user.editor.placeholder.password',
    }),
  )
  .toolbarItems(builder => {
    builder
      .custom({
        prop: 'orgId',
        render: (filter, applyChanges) => {
          const onChange = (
            orgId: number | undefined,
            storeId: number | undefined,
          ) => {
            applyChanges(changes => {
              changes.orgId = orgId;
              changes.storeId = storeId;
            });
          };
          return (
            <StorePicker
              orgId={filter.orgId}
              storeId={filter.storeId}
              onChange={onChange}
            />
          );
        },
      })
      .text({
        prop: 'userName',
        label: 'org_user.toolbar.label.user_name',
        placeholder: 'org_user.toolbar.placeholder.user_name',
        width: 250,
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(orgUserActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'storeName',
      width: 200,
      text: 'col.store_name',
      render: ({ storeId, orgId }) => (
        <StoreName orgId={orgId} storeId={storeId} />
      ),
    },
    {
      prop: 'userName',
      width: 100,
      text: 'col.user_name',
      render: ({ orgId, userName }) => (
        <OrgInfoView orgId={orgId}>
          {org => `${org?.cid}/${userName}`}
        </OrgInfoView>
      ),
    },
    {
      prop: 'nick',
      width: 100,
      text: 'col.nick',
    },
    {
      prop: 'isOrgRootUser',
      width: 60,
      align: 'center',
      text: 'org_user.col.is_org_root_user',
      render: ({ isOrgRootUser }) => <Checkmark value={isOrgRootUser} />,
    },
    {
      prop: 'isServiceNotificationEnabled',
      width: 60,
      align: 'center',
      text: 'org_user.col.is_service_notification_enabled',
      render: ({ isServiceNotificationEnabled }) => (
        <Checkmark value={isServiceNotificationEnabled} />
      ),
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
    {
      prop: 'lastLoginAt',
      text: 'org_user.col.last_login_at',
      width: 150,
      align: 'center',
      render: ({ lastLoginAt }) =>
        lastLoginAt ? formatTime(lastLoginAt) : '-',
    },
  ])
  .addActionButtons([
    {
      key: 'auth-qrcode',
      icon: 'la la-qrcode',
      tooltip: 'org_user.qrcode_auth.tooltip',
      disabled: x => Boolean(!x.storeId),
      onClick: (item, props: Props) => {
        props.dispatch(
          setCurrentUserQrcodeAuth({
            orgId: item.orgId,
            storeId: item.storeId!,
            userId: item.id,
          }),
        );
      },
    },
  ])
  .validate((entity: OrgUser) => {
    if (entity.password) {
      const password = entity.password.trim();
      if (password.length < 6 || password.length > 20) {
        throw new Error(
          getString('org_user.editor.error.password_too_short_or_too_long'),
        );
      }
    }
  })
  .mapStateToProps(state => ({
    currentQrcodeAuthUserPrincipal:
      state.customers.orgUsers.currentQrcodeAuthUserPrincipal,
  }))
  .onRender((props: Props) => {
    return (
      <Block>
        <UserAuthQrcodeAside
          user={props.currentQrcodeAuthUserPrincipal}
          onClose={() => {
            props.dispatch(setCurrentUserQrcodeAuth(undefined));
          }}
        />
      </Block>
    );
  })
  .getClass();
