/**
 * @file: VehicleInspectionTaskTemplateSnapshotService.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { APIService } from 'lib/restful-client/api.service';
import {
  ListQueryOptions,
  ListResult,
  PagedListQueryOptions,
  SortInfo,
  VehicleInspectionTaskTemplateSnapshot,
  VehicleInspectionTaskTemplateSnapshotListFilter,
} from 'model';

export class VehicleInspectionTaskTemplateSnapshotServiceBase {
  constructor(protected api: APIService) {}

  async list<TOptions extends ListQueryOptions | PagedListQueryOptions>(
    filter?:
      | Partial<VehicleInspectionTaskTemplateSnapshotListFilter>
      | null
      | undefined,
    sorter?: SortInfo | null | undefined,
    offset?: number,
    limit?: number,
    options?: TOptions,
  ): Promise<
    TOptions extends PagedListQueryOptions
      ? ListResult<VehicleInspectionTaskTemplateSnapshot>
      : VehicleInspectionTaskTemplateSnapshot[]
  > {
    return await this.api
      .get()
      .url(
        '/vehicle-inspection-task-template-snapshots',
        Object.assign(
          {
            filter: (filter && JSON.stringify(filter)) || undefined,
            sorter: (sorter && JSON.stringify(sorter)) || undefined,
            offset,
            limit,
          },
          options,
        ),
      )
      .future();
  }

  async get(id: number): Promise<VehicleInspectionTaskTemplateSnapshot | null> {
    return await this.api
      .get()
      .url('/vehicle-inspection-task-template-snapshots/:id', { id })
      .future();
  }

  async getSnapshotByKey(
    key: string,
  ): Promise<VehicleInspectionTaskTemplateSnapshot | null> {
    if (!key) {
      throw new Error('missing key parameter');
    }
    return await this.api
      .get()
      .url('/vehicle-inspection-task-template-snapshots/query/key', { key })
      .future();
  }

  async create(
    vehicleInspectionTaskTemplateSnapshot: Partial<VehicleInspectionTaskTemplateSnapshot>,
  ): Promise<VehicleInspectionTaskTemplateSnapshot> {
    return await this.api
      .post()
      .url('/vehicle-inspection-task-template-snapshots')
      .data(vehicleInspectionTaskTemplateSnapshot)
      .future();
  }

  async update(
    vehicleInspectionTaskTemplateSnapshot: Partial<VehicleInspectionTaskTemplateSnapshot>,
  ): Promise<VehicleInspectionTaskTemplateSnapshot> {
    return await this.api
      .put()
      .url('/vehicle-inspection-task-template-snapshots/:id', {
        id: vehicleInspectionTaskTemplateSnapshot.id,
      })
      .data(vehicleInspectionTaskTemplateSnapshot)
      .future();
  }

  async delete(id: number): Promise<void> {
    await this.api
      .delete()
      .url('/vehicle-inspection-task-template-snapshots/:id', { id })
      .future();
  }
}
