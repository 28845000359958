import classNames from 'classnames';
import { Component, HTMLProps, MouseEvent, ReactNode } from 'react';
import { Dropdown } from 'shared/metronic/components/Dropdown';
import { DropdownButton } from 'shared/metronic/components/DropdownButton';
import { Nav } from 'shared/metronic/components/Nav';

const DEFAULT_LIMITS = [1, 3, 5, 10, 15, 20, 25, 30, 35, 40, 50, 100];

interface Props extends HTMLProps<HTMLDivElement> {
  offset: number;
  limit: number;
  total: number;
  maxPagerItems: number;
  limits?: number[];
  onOffsetChange?: (offset: number) => void;
  onLimitChange?: (limit: number) => void;
  renderRecordInfo?: (
    start: number,
    end: number,
    total: number,
  ) => string | ReactNode;
}

interface PagerItem {
  type:
    | 'first'
    | 'prev'
    | 'more-prev'
    | 'pageno'
    | 'more-next'
    | 'next'
    | 'last';
  text?: string;
  title?: string;
  icon?: string;
  active?: boolean;
  disabled?: boolean;
  offset: () => number;
}

interface PagerLinkProps {
  item: PagerItem;
  onClick: (item: PagerItem) => void;
}

const PagerLink = (props: PagerLinkProps) => {
  const { item } = props;

  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.onClick && props.onClick(item);
  };

  return (
    <a
      title={item.title}
      className={classNames({
        'm-datatable__pager-link': true,
        'm-datatable__pager-link--first': item.type === 'first',
        'm-datatable__pager-link--prev': item.type === 'prev',
        'm-datatable__pager-link--more-prev': item.type === 'more-prev',
        'm-datatable__pager-link-number': item.type === 'pageno',
        'm-datatable__pager-link--active': item.active,
        'm-datatable__pager-link--more-next': item.type === 'more-next',
        'm-datatable__pager-link--next': item.type === 'next',
        'm-datatable__pager-link--last': item.type === 'last',
        'm-datatable__pager-link--disabled': item.disabled,
      })}
      onClick={onClick}
    >
      {item.icon ? <i className={item.icon} /> : item.text}
    </a>
  );
};

interface PageSizeNavItemProps {
  value: number;
  active: boolean;
  onClick: (value: number) => void;
}
const PageSizeNavItem = (props: PageSizeNavItemProps) => {
  const { value, active } = props;
  const onClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    props.onClick(value);
  };
  return (
    <Nav.Item key={value} text={value} active={active} onClick={onClick} />
  );
};

export class Pager extends Component<Props> {
  constructor(props: Props) {
    super(props);
    if (props.limit <= 0 || props.limit % 1 !== 0) {
      throw new Error('pager limit should be positive integer. ');
    }
    if (props.maxPagerItems <= 0 || props.maxPagerItems % 1 !== 0) {
      throw new Error('max page items limit should be positive integer. ');
    }
  }

  render() {
    const {
      total,
      offset,
      limit,
      className,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      maxPagerItems,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onOffsetChange,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onLimitChange,
      renderRecordInfo,
      ...props
    } = this.props;
    const pagerItems = this.calcPagerItems();
    const limits = this.props.limits || DEFAULT_LIMITS;
    return (
      <div
        className={classNames(
          'm-datatable__pager m-datatable--paging-loaded clearfix',
          className,
        )}
        {...props}
      >
        <ul className="m-datatable__pager-nav">
          {pagerItems.map((item, i) => {
            return (
              <li key={i}>
                <PagerLink item={item} onClick={this.onPagerLinkClick} />
              </li>
            );
          })}
        </ul>
        <div className="m-datatable__pager-info">
          <DropdownButton
            dropup
            color="light"
            text={limit}
            className="d-inline-block m-datatable__pager-size"
            dropDownSize="auto"
            closeDropdownOnClick
          >
            <Dropdown noPadding>
              <Nav>
                {limits.map(value => (
                  <PageSizeNavItem
                    key={value}
                    value={value}
                    active={value === limit}
                    onClick={this.onPageSizeClick}
                  />
                ))}
              </Nav>
            </Dropdown>
          </DropdownButton>
          &nbsp;
          <span className="m-datatable__pager-detail">
            {renderRecordInfo
              ? renderRecordInfo(offset + 1, offset + limit, total)
              : this.renderRecordInfoDefault(offset + 1, offset + limit, total)}
          </span>
        </div>
      </div>
    );
  }

  renderRecordInfoDefault(start: number, end: number, total: number): string {
    return `Displaying ${start}-${end} of ${total} records`;
  }

  onPagerLinkClick = (item: PagerItem) => {
    if (item.disabled || item.active) return;
    const offset = item.offset();
    this.props.onOffsetChange && this.props.onOffsetChange(offset);
  };

  onPageSizeClick = (value: number) => {
    if (value !== this.props.limit) {
      this.props.onLimitChange && this.props.onLimitChange(value);
    }
  };

  calcPagerItems(): PagerItem[] {
    const { total, offset, limit, maxPagerItems } = this.props;
    const pageIndex = Math.floor(offset / limit);
    const pageCount = Math.ceil(total / limit);
    const k = Math.floor(pageIndex / maxPagerItems);
    const start = k * maxPagerItems;
    const end = Math.min((k + 1) * maxPagerItems, pageCount);

    const items: PagerItem[] = [];

    items.push(
      {
        type: 'first',
        disabled: pageIndex === 0,
        icon: 'la la-angle-double-left',
        title: 'First',
        offset: () => 0,
      },
      {
        type: 'prev',
        disabled: pageIndex === 0,
        icon: 'la la-angle-left',
        title: 'Previous',
        offset: () => offset - limit,
      },
    );

    if (pageIndex >= maxPagerItems) {
      items.push({
        type: 'more-prev',
        icon: 'la la-ellipsis-h',
        title: 'More pages',
        offset: () => (start - 1) * limit,
      });
    }

    // page numbers.
    for (let i = start; i < end; i++) {
      items.push({
        type: 'pageno',
        text: `${i + 1}`,
        title: `${i + 1}`,
        active: pageIndex === i,
        offset: () => i * limit,
      });
    }

    if (pageIndex + maxPagerItems < pageCount) {
      items.push({
        type: 'more-next',
        icon: 'la la-ellipsis-h',
        title: 'More pages',
        offset: () => end * limit,
      });
    }

    items.push(
      {
        type: 'next',
        disabled: pageIndex === pageCount - 1,
        icon: 'la la-angle-right',
        title: 'Next',
        offset: () => offset + limit,
      },
      {
        type: 'last',
        disabled: pageIndex === pageCount - 1,
        icon: 'la la-angle-double-right',
        title: 'Last',
        offset: () => (pageCount - 1) * limit,
      },
    );

    return items;
  }
}
