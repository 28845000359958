/**
 * @file: InspectionCategoryOptions.tsx
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { InspectionCategory, Option } from 'model';

export const InspectionCategoryOptions: Array<Option<InspectionCategory>> = [
  { value: InspectionCategory.Pre, label: 'inspection_category.pre' },
  { value: InspectionCategory.Normal, label: 'inspection_category.normal' },
];

export const InspectionCategoryOptionsWithDefault: Array<
  Option<InspectionCategory | null>
> = [
  { value: null, label: 'inspection_category.__default__' },
  { value: InspectionCategory.Pre, label: 'inspection_category.pre' },
  { value: InspectionCategory.Normal, label: 'inspection_category.normal' },
];
