import '@babel/polyfill';
import moment from 'moment';
import momentDurationSetup from 'moment-duration-format';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

require('!file-loader?name=css/icons/style.css!./assets/icons/style.css');
require('!file-loader?name=fonts/opensans/[name].[ext]!./assets/fonts/opensans/opensans.css');
require('assets/css/index.scss');

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function dummy(p) {
  require(
    '!file-loader?name=css/icons/fonts/[name].[ext]!./assets/icons/fonts/' + p,
  );
  require('!file-loader?name=img/[name].[ext]!./assets/img/' + p);
  require('!file-loader?name=img/bg-img/[name].[ext]!./assets/bg-img/' + p);
  require(
    '!file-loader?name=img/profile-photos/[name].[ext]!./assets/profile-photos/' +
      p,
  );
  require(
    '!file-loader?name=fonts/opensans/fonts/[name].[ext]!./assets/fonts/opensans/fonts' +
      p,
  );
  // require('!file-loader?name=img/emotions/Default/[name].[ext]!./assets/emotions/Default/' + p);
  // require('!file-loader?name=img/emotions/NewQianNiu/[name].[ext]!./assets/emotions/NewQianNiu/' + p);
  // require('!file-loader?name=img/emotions/QianNiu/[name].[ext]!./assets/emotions/QianNiu/' + p);
  // require('!file-loader?name=img/emotions/TaoDoll/[name].[ext]!./assets/emotions/TaoDoll/' + p);
  // require('!file-loader?name=img/emotions/TMall/[name].[ext]!./assets/emotions/TMall/' + p);
  // require('!file-loader?name=img/emojis/[name].[ext]!./assets/emojis/' + p);
  // require('!file-loader?name=sounds/[name].[ext]!./assets/sound/' + p);
}

momentDurationSetup(moment as any);
