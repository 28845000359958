type Entry<T> = (err: Error | undefined, result?: T) => void;

export class MutualExclusiveCall<T> {
  private isInvoking = false;
  private readonly callbackQueue: Entry<T>[] = [];

  constructor(private readonly targetCall: () => Promise<T>) {}

  async invoke(): Promise<T> {
    // eslint-disable-next-line no-async-promise-executor
    return await new Promise(async (resolve, reject) => {
      this.callbackQueue.push((err: Error | undefined, result?: T) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result!);
      });

      if (this.isInvoking) return;

      this.isInvoking = true;

      try {
        const res = await this.targetCall();
        this.drain(undefined, res!);
      } catch (e) {
        this.drain(e);
      } finally {
        this.isInvoking = false;
      }
    });
  }

  private drain(error: Error | undefined, result?: T) {
    for (const callback of this.callbackQueue) {
      try {
        callback(error, result);
      } catch (e) {
        console.error('error invoke callback: ', e);
      }
    }
    this.callbackQueue.length = 0;
  }
}
