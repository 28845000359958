/**
 * @file: ui-state.ts
 * @author: eric <xuxiang@zhichetech.com>
 * @copyright: (c) 2019-2020 sichuan zhichetech co., ltd.
 */

import { ActionTypes } from '../types';
import { createStandardAction } from 'lib/duck/actions';

export function inspectionSiteInventoryNodeSelected(nodeId: string) {
  return createStandardAction(ActionTypes.NodeSelected, nodeId);
}

export function inspectionSiteInventoryNodeExpanded(
  nodeId: string,
  expandAllByDefault: boolean,
) {
  return createStandardAction(ActionTypes.NodeExpanded, {
    nodeId,
    expandAllByDefault,
  });
}

export function inspectionSiteInventoryNodeCollapsed(
  nodeId: string,
  expandAllByDefault: boolean,
) {
  return createStandardAction(ActionTypes.NodeCollapsed, {
    nodeId,
    expandAllByDefault,
  });
}

export function inspectionSiteInventorySetExpandedCollapsedNodes(payload: {
  expandedNodes: Set<string>;
  collapsedNodes: Set<string>;
}) {
  return createStandardAction(ActionTypes.SetExpandedCollapsedNodes, payload);
}

export function inspectionSiteInventoryNodeMouseEnter(nodeId: string) {
  return createStandardAction(ActionTypes.NodeMouseEnter, nodeId);
}

export function inspectionSiteInventoryNodeMouseLeave(nodeId: string) {
  return createStandardAction(ActionTypes.NodeMouseLeave, nodeId);
}

export function startImportingInspectionSiteInventory() {
  return createStandardAction(ActionTypes.ImportInventory);
}

export function importInspectionSiteInventorySuccess() {
  return createStandardAction(ActionTypes.ImportInventorySuccess);
}

export function importInspectionSiteInventoryFailed() {
  return createStandardAction(ActionTypes.ImportInventoryFailed);
}
