import { createAsyncListActionReducers } from 'lib/duck/reducers';
import { Store } from 'model';
import { RecentStores } from '../states';

const initialState: RecentStores = {
  isLoading: false,
  error: null,
  result: null,
  filter: {},
  sorts: [{ property: 'createdAt', dir: 'desc' }],
  offset: 0,
  limit: 15
};

export default createAsyncListActionReducers<Store, RecentStores>(
  'dashboard.recent_stores',
  initialState,
  {
    mapItemKey: x => x.id
  }
);