import React from 'react';
import { Translate } from 'react-localize-redux';
import { formatTime } from 'utils';
import { orgMemberActions, setCurrentMemberQrcodeAuth } from '../duck/actions';
import { OrgMember, OrgMemberListFilter, AclObjectList } from 'model';
import {
  EntityListProps,
  EntityListComponentClassBuilder,
  StorePicker,
  OrgInfoView,
  StoreName,
} from 'shared/components';
import { Block } from 'shared/metronic/components';
import { UserAuthQrcodeAside } from 'app/customers/org-users/UserAuthQrcodeAside';
import { OrgUserPrincipal } from 'app/customers/duck/states';

interface Props extends EntityListProps<OrgMember, OrgMemberListFilter> {
  currentQrcodeAuthUserPrincipal?: OrgUserPrincipal | undefined;
}

const componentClassBuilder = new EntityListComponentClassBuilder<
  OrgMember,
  OrgMemberListFilter,
  number,
  Props
>();

export const OrgMemberList = componentClassBuilder
  .i18nPrefix('org_member')
  .accessRights({
    full: AclObjectList.OrgMemberFullAccess,
    readonly: AclObjectList.OrgMemberReadonlyAccess,
  })
  .breadcrumbs([
    { text: <Translate id="customer.breadcrumb.it" /> },
    { text: <Translate id="customer.breadcrumb.org_members" /> },
  ])
  .features({ addEntity: false })
  .entities(state => state.customers.orgMembers)
  .actions(orgMemberActions)
  .toolbarItems(builder => {
    builder
      .custom({
        prop: 'orgId',
        render: (filter, applyChanges) => {
          const onChange = (
            orgId: number | undefined,
            storeId: number | undefined,
          ) => {
            applyChanges(changes => {
              changes.orgId = orgId;
              changes.storeId = storeId;
            });
          };
          return (
            <StorePicker
              orgId={filter.orgId}
              storeId={filter.storeId}
              onChange={onChange}
            />
          );
        },
      })
      .text({
        prop: 'userName',
        label: 'org_member.toolbar.label.user_name',
        placeholder: 'org_member.toolbar.placeholder.user_name',
        width: 150,
      })
      .text({
        prop: 'name',
        label: 'org_member.toolbar.label.name',
        placeholder: 'org_member.toolbar.placeholder.name',
        width: 150,
      })
      .button({
        text: '@string/btn_search',
        onClick: (props: Props) => {
          const { dispatch } = props;
          dispatch(orgMemberActions.invalidate(true));
        },
      });
  })
  .columns([
    {
      prop: 'storeName',
      width: 200,
      text: 'col.store_name',
      render: ({ storeId, orgId }) => (
        <StoreName orgId={orgId} storeId={storeId} />
      ),
    },
    {
      prop: 'name',
      width: 100,
      text: 'col.name',
    },
    {
      prop: 'jobNo',
      width: 100,
      align: 'center',
      text: 'org_member.col.job_no',
    },
    {
      prop: 'jobTitle',
      width: 100,
      align: 'center',
      text: 'org_member.col.job_title',
    },
    {
      prop: 'managerName',
      width: 100,
      align: 'center',
      text: 'org_member.col.manager_name',
    },
    {
      prop: 'userName',
      width: 100,
      text: 'col.user_name',
      render: ({ orgId, userName }) => (
        <OrgInfoView orgId={orgId}>
          {org => `${org?.cid}/${userName}`}
        </OrgInfoView>
      ),
    },
    {
      prop: 'mobile',
      width: 200,
      align: 'center',
      text: 'col.mobile',
    },
    {
      prop: 'createdAt',
      text: 'col.created_at',
      width: 150,
      align: 'center',
      render: ({ createdAt }) => formatTime(createdAt),
    },
  ])
  .addActionButtons([
    {
      key: 'auth-qrcode',
      icon: 'la la-qrcode',
      tooltip: 'org_user.qrcode_auth.tooltip',
      disabled: x => Boolean(!x.storeId),
      onClick: (item, props: Props) => {
        props.dispatch(
          setCurrentMemberQrcodeAuth({
            orgId: item.orgId,
            storeId: item.storeId!,
            userId: item.userId,
          }),
        );
      },
    },
  ])
  .mapStateToProps(state => ({
    currentQrcodeAuthUserPrincipal:
      state.customers.orgMembers.currentQrcodeAuthUserPrincipal,
  }))
  .onRender((props: Props) => {
    return (
      <Block>
        <UserAuthQrcodeAside
          user={props.currentQrcodeAuthUserPrincipal}
          onClose={() => {
            props.dispatch(setCurrentMemberQrcodeAuth(undefined));
          }}
        />
      </Block>
    );
  })
  .getClass();
